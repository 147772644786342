import React from 'react'
import { LabelDiv } from './elements.css'

const ProviderLabel = (props) => {
    const { name, condition, theme } = props

    return (
        <LabelDiv color={theme.checkData.amountColor} bg={theme.provider.labelColor} style={{ display: condition ? '' : 'none' }}>
            {name}
        </LabelDiv>
    )
}

export default ProviderLabel
