import React from 'react'
import classes from './style.module.css'
import { Buttons } from './Buttons.css'

const ProcentButtons = (props) => {
    const { handler, balance, checked, setButton, setLoad, minMax, theme } = props

    return (
        <>
            {minMax ? (
                <div className={classes.wrapper__min}>
                    <div
                        className={checked === 'MIN' ? classes.item__btn__min__inverse : classes.item__btn__min}
                        onClick={() => handler('MIN', balance, setButton, setLoad)}>
                        MIN
                    </div>
                    <div
                        className={checked === 'MAX' ? classes.item__btn__min__inverse : classes.item__btn__min}
                        onClick={() => handler('MAX', balance, setButton, setLoad)}>
                        MAX
                    </div>
                </div>
            ) : (
                <Buttons textColor={theme.topNav.color} borderColor={theme.topNav.colorIcon} textInverse={theme.main.bg}>
                    <div className={checked === 0.25 ? 'item__btn__inverse' : 'item__btn'} onClick={() => handler(0.25, balance, setButton)}>
                        25%
                    </div>
                    <div className={checked === 0.5 ? 'item__btn__inverse' : 'item__btn'} onClick={() => handler(0.5, balance, setButton)}>
                        50%
                    </div>
                    <div className={checked === 0.75 ? 'item__btn__inverse' : 'item__btn'} onClick={() => handler(0.75, balance, setButton)}>
                        75%
                    </div>
                    <div className={checked === 1 ? 'item__btn__inverse' : 'item__btn'} onClick={() => handler(1, balance, setButton, setLoad)}>
                        100%
                    </div>
                </Buttons>
            )}
        </>
    )
}

export default ProcentButtons
