export default {
    CARD: 'Card',
    MOBILE_PHONE: 'Mobile',
    QIWI: 'Qiwi',
    ADVCASH: 'ADVCash',
    PAYEER: 'Payeer',
    PERFECT_MONEY: 'Perfect money',
    CAPITALIST: 'Capitalist',
    WEBMONEY: 'WebMoney',
    YANDEX_MONEY: 'YooMoney',
    BANK_TRANSFER: 'Bank transfer',
    CASH: 'Cash'
}
