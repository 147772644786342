import React, { useCallback, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from 'notistack'

import Button from 'components/Button/Button'
import Icon from 'components/Icon/Icon'
import { filterItemVariants } from 'components/providerItem/helper'

import { strings } from 'i18n'

import {
    activeExchangeWayAction,
    providerAction,
    variantsAction,
    providerErrorAction,
    providerLoaderAction,
    amountAction,
    amountSideAction,
    providerEquivalentTimeAction
} from 'redux/AppStores/MobileMarket/action'
import {
    getAllExchangeWays,
    getExchangeWaysArray,
    getOutDestination,
    getPaymentsDetails,
    getSign,
    getUserSettings
} from 'redux/AppStores/MobileMarket/selectors'
import { showModal } from 'redux/AppStores/ModalStore/action'

import getProviders from 'services/getProviders'
import Log from 'services/sentry/Log'
import limitScan from 'services/limitScan'
import minMaxLimits from 'services/minMaxLimit'
import utilsService from 'services/utilsService'

import LoaderUniverse from 'img/icon/loaderWhite.svg'

import { checkCard } from 'modules/mobileMarket/firstScreen/helper'

import BottomBtnsDiv from './style.css'

const BottomBtns = (props) => {
    const { theme, userCurrencies, amountData, requestId, count, setCount, focus } = props

    const paymentData = useSelector(getPaymentsDetails)
    const sign = useSelector(getSign)
    const destinationData = useSelector(getOutDestination)
    const settings = useSelector(getUserSettings)
    const allExchangeWays = useSelector(getAllExchangeWays)
    const exchangeWaysArray = useSelector(getExchangeWaysArray)

    const { inCurrency, outCurrency, payway, cashbackToken } = userCurrencies

    const history = useHistory()
    const dispatch = useDispatch()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const [loading, setLoading] = useState(false)

    const urlPath = useCallback((url, type) => {
        if (type) {
            history.push({
                pathname: `/mobile-market/${url}`,
                state: { type: type }
            })
        } else {
            history.push('/mobile-market/' + url)
        }
    }, [])

    const handleAdvancedSettings = useCallback(() => {
        if (inCurrency && outCurrency) {
            urlPath('settings', 'page')
        }
    }, [])

    const filterIsActive = (variantsArray) => {
        if (!variantsArray || !variantsArray.length) return []

        const idWays = allExchangeWays.map((way) => way.exchangeWayId)

        const newVariants = variantsArray.filter((way) => idWays.includes(way.exchangeWayId))
        // const commingSoon = variantsArray.filter(way => way.equivalentData ? way.equivalentData.errorCode === 'COMING_SOON' : false)
        return newVariants
    }

    const handleBtn = async (e, amount = null, sideAmount = null) => {
        e.preventDefault()

        if (e?.detail > 1) return

        const valueAmount = amount || amountData.amount

        if (!valueAmount || !inCurrency || !outCurrency) return

        if ((inCurrency.type === 'FIAT' || outCurrency.type === 'FIAT') && !payway) return

        if (!allExchangeWays || !allExchangeWays.length) return

        setLoading(true)
        setCount(1)
        if ((inCurrency.type === 'FIAT' || outCurrency.type === 'FIAT') && paymentData) {
            const validStatus = checkCard()

            if (validStatus && validStatus.length === 1 && validStatus[0] === false) {
                setLoading(false)
                setCount(0)

                showModal({
                    type: 'INFO_MODAL',
                    icon: 'WARNING',
                    header: strings('mobileMarket.modal.attention'),
                    description: strings(`mobileMarket.providerErrors.${payway === 'CARD' ? 'notValidCard' : 'notValidAccount'}`)
                })
                return
            }
        }

        let limits = await limitScan(allExchangeWays)
        limits = minMaxLimits(allExchangeWays, exchangeWaysArray, limits || [])

        const minError = valueAmount * 1 < limits.min * 1
        const maxError = limits.max * 1 > 0 && valueAmount * 1 > limits.max * 1
        const side = limits.currencySide

        if ((minError || maxError) && side === (sideAmount || amountData.amountSide)) {
            Log.info(`V3.mainScreen.BottomBtns.handleBtn error limits ${JSON.stringify({ limits, amountData })}`)
            Log.captureError(`V3.mainScreen.BottomBtns.handleBtn error limits`)
            setLoading(false)
            setCount(0)

            showModal(
                {
                    type: 'YES_NO_MODAL',
                    icon: 'WARNING',
                    header: strings('mobileMarket.modal.attention'),
                    description: strings('mobileMarket.modal.noLimit', {
                        value: `${minError ? limits.min : limits.max} ${side === 'OUT' ? outCurrency.symbol : inCurrency.symbol}`
                    })
                },
                async (e) => {
                    dispatch(amountAction(minError ? limits.min : limits.max))
                    dispatch(amountSideAction(side))
                    await handleBtn(e, minError ? limits.min : limits.max, side)
                }
            )

            return
        }

        dispatch(providerLoaderAction(true))
        setLoading(false)
        urlPath('pre-check', 'page')

        dispatch(activeExchangeWayAction(null))

        const state = {
            inCurrency,
            outCurrency,
            payway,
            cashbackToken,
            sign,
            paymentData,
            outDestination: destinationData.outDestination
        }

        const arrayProviders = await getProviders(
            state,
            requestId,
            valueAmount,
            sideAmount || amountData.amountSide,
            settings.tester,
            enqueueSnackbar
        )

        let finalArray = arrayProviders !== null ? filterIsActive(arrayProviders.variants) : []
        finalArray = finalArray.map((item) => filterItemVariants(item))

        Log.info(`V3.firstScreen.findButtonHandler getProviders result ${JSON.stringify(finalArray)}`)

        dispatch(variantsAction(finalArray))

        localStorage.removeItem('amountData')
        if (finalArray && finalArray.length && finalArray[0]?.equivalentData && finalArray[0]?.equivalentData?.status === 'SUCCESS') {
            dispatch(providerAction(finalArray[0], allExchangeWays[0].exchangeWayType))
            // urlPath('pre-check', 'page')
            setCount(0)
            dispatch(providerLoaderAction(false))
            dispatch(providerEquivalentTimeAction(new Date().getTime()))
            return
        }

        window.history.back()
        setCount(0)
        dispatch(providerErrorAction(finalArray[0]?.equivalentData))
        dispatch(providerEquivalentTimeAction(null))
        setTimeout(() => {
            dispatch(providerLoaderAction(false))
        }, 500)

        Log.info(`V3.mainScreen.BottomBtns.handleBtn error ${JSON.stringify(finalArray[0]?.equivalentData)}`)
        Log.info(`V3.mainScreen.BottomBtns.handleBtn error all data ${JSON.stringify(finalArray)}`)
        Log.captureError(`V3.mainScreen.BottomBtns.handleBtn error`)

        showModal({
            type: 'INFO_MODAL',
            icon: 'WARNING',
            header: strings(!finalArray[0]?.equivalentData?.errorCode ? 'mobileMarket.modal.sorry' : 'mobileMarket.modal.attention'),
            description: handleErrorCode(finalArray[0]?.equivalentData)
        })
        return
    }

    const condition = useMemo(() => {
        return (
            count === 0 &&
            amountData.amount &&
            Number(amountData.amount > 0) &&
            inCurrency &&
            outCurrency &&
            (inCurrency.type === 'FIAT' || outCurrency.type === 'FIAT' ? payway : true) &&
            !loading
        )
    }, [count, amountData, inCurrency, outCurrency, payway, loading])

    const handleErrorCode = (data) => {
        if (!data?.errorCode) {
            return strings('mobileMarket.providerErrors.providerError2')
        }

        switch (data.errorCode) {
            case 'EXCEEDING_LIMITS':
                return (
                    (data.limit &&
                        strings(`mobileMarket.${data.limit.type.toLowerCase()}`) + ' ' + strings('mobileMarket.providerErrors.providerLimits')) +
                    ' ' +
                    `${utilsService.cutNumber(data.limit?.amount || 0, inCurrency.type === 'FIAT' || outCurrency.type === 'FIAT' ? 2 : 6)} ${
                        outCurrency.type !== 'FIAT' ? inCurrency.symbol : outCurrency.symbol
                    }`
                )
            case 'PROVIDER_ERROR':
                return strings('mobileMarket.providerErrors.providerUnavailable')
            default:
                return strings('mobileMarket.noTempWay')
        }
    }

    return (
        <BottomBtnsDiv>
            <div className={focus ? 'focus-btn' : 'buttons'}>
                {outCurrency && outCurrency.type !== 'FIAT' && (
                    <div className='button-back' onClick={handleAdvancedSettings}>
                        <Icon icon='SETTINGS' color={theme.topNav.colorIcon} size={24} />
                    </div>
                )}
                <Button id='mainModalBtn' condition={condition} style={{ width: '100%' }} handler={handleBtn}>
                    {strings('mobileMarket.searchBestOffer')}
                    {loading && (
                        <div>
                            <img src={LoaderUniverse} height={45} alt='preloader' style={{ paddingLeft: 5 }} />
                        </div>
                    )}
                </Button>
            </div>
        </BottomBtnsDiv>
    )
}

export default React.memo(BottomBtns)
