import styled from 'styled-components'

const PreCheckScreenDiv = styled.div`
    .precheck-screen {
        background: ${(p) => p.bgColor};
        padding: 20px 16px 16px 16px;
        margin: 0 -16px;
    }

    .provider-data {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
    }

    .line {
        border-bottom: 1px solid ${(p) => p.lineColor};

        &.small {
            width: 68%;
            margin: 0 auto;
        }
    }

    .amount-data {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        padding: 16px 0;
    }

    .amount-side-text {
        font-family: 'Montserrat Medium';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-align: center;

        color: ${(p) => p.textColor};
    }

    .amount-value {
        font-family: 'Montserrat Bold';
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        margin-top: 8px;
        text-align: center;

        color: ${(p) => p.inAmountColor};

        &.out {
            color: ${(p) => p.outAmountColor};
        }
    }

    .check-details {
        margin: 16px 16px 0px 16px;
    }

    .more-details {
        font-family: 'Montserrat Bold';
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;

        text-align: center;
        letter-spacing: 1.5px;
        text-transform: uppercase;

        margin-top: 16px;

        color: ${(p) => p.textColor};
    }

    .details {
        margin: 16px;
    }

    .other-providers-btn {
        font-family: 'Montserrat Bold';
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 12px;
        text-align: center;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        color: ${(p) => p.outAmountColor};

        padding: 32px 0 0 0;
    }

    .duration-operation {
        display: flex;
        justify-content: center;

        font-family: 'Montserrat Medium';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-align: left;
        color: ${(p) => p.durationTimeColor};
    }
`

export default PreCheckScreenDiv
