import axios from 'axios'
import config from '../config/config'
import { strings } from '../i18n'
import { showModal, hideModal } from '../redux/AppStores/ModalStore/action'
import Log from './sentry/Log'

export const axiosRequest = async (link, errorMsg, post, tester, enqueueSnackbar = false) => {
    Log.info(`V3.axiosRequest time before request ${JSON.stringify(new Date())}`)
    let response

    const url = config.backendUrl + link

    const showNotic = tester && config.showStackRequiest && enqueueSnackbar

    if (post) {
        showNotic && enqueueSnackbar(link.slice(0, 20) + ' start ' + JSON.stringify(new Date()))
        response = await axios.post(url, post).catch((error) => {
            console.log(error.message)
            if (error.response) {
                console.log(error.response.data.message)
                Log.info(`V3.axiosRequest time request error time ${JSON.stringify(new Date())}`)
                Log.error(`V3.axiosRequest  error ${error.response.data.message}`)
                Log.captureError('V3.axiosRequest error')
                // alert(error.response.data.message)
                showModal(
                    {
                        type: 'INFO_MODAL',
                        icon: 'WARNING',
                        header: strings('mobileMarket.modal.sorry'),
                        description: config.showError ? JSON.stringify(error.message) : errorMsg,
                        // error.response.data.message.indexOf('Out of time.') !== -1 ? 'hello' : strings('mobileMarket.modal.serverRespond'),
                        close: () => window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ error: 'error' }))
                    },
                    () => {
                        window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ error: 'error' }))
                        hideModal()
                    }
                )
                return
            }
        })
        showNotic && enqueueSnackbar(link.slice(0, 20) + ' finish ' + JSON.stringify(new Date()))
        Log.info(`V3.axiosRequest time after request ${JSON.stringify(new Date())}`)
        return response.data
    } else {
        showNotic && enqueueSnackbar(link.slice(0, 20) + '  start ' + JSON.stringify(new Date()))
        response = await axios.get(url).catch((error) => {
            console.log(error.message)
            if (error.response) {
                console.log(error.response.data.message)
                Log.info(`V3.axiosRequest time request error time ${JSON.stringify(new Date())}`)
                Log.error(`V3.axiosRequest  error ${error.response.data.message}`)
                Log.captureError('V3.axiosRequest error')
                // alert(error.response.data.message)
                showModal(
                    {
                        type: 'INFO_MODAL',
                        icon: 'WARNING',
                        header: strings('mobileMarket.modal.sorry'),
                        description: config.showError ? JSON.stringify(error.message) : errorMsg,
                        // error.response.data.message.indexOf('Out of time.') !== -1 ? 'hello' : strings('mobileMarket.modal.serverRespond'),
                        close: () => window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ error: 'error' }))
                    },
                    () => {
                        window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ error: 'error' }))
                        hideModal()
                    }
                )
                return
            }
        })
        showNotic && enqueueSnackbar(link.slice(0, 20) + ' finish ' + JSON.stringify(new Date()))
        Log.info(`V3.axiosRequest time after request ${JSON.stringify(new Date())}`)
        return response.data
    }
}

export const serverRequest = async (link, errorMsg, type, post = false, tester = false, enqueueSnackbar = false) => {
    Log.info(`V3.serverRequest link ${link}`)
    try {
        let response = await axiosRequest(link, errorMsg, post, tester, enqueueSnackbar)
        if (type === 'DATA_ALL' || type === 'COMING_SOON') {
            let activeExchangeWays = response
            let allExchangeWays = activeExchangeWays ? [...activeExchangeWays.buy, ...activeExchangeWays.sell, ...activeExchangeWays.exchange] : []
            return allExchangeWays
        } else if (type === 'WALLET_DATA') {
            return response
        } else if (type === 'SERVER_TIME') {
            let userTime
            if (response) {
                userTime = Date.now()
                Log.info(`V3.MobileMarketAction serverTime ${Date(response.serverTime)}`)
                Log.info(`V3.MobileMarketAction userTime ${Date(userTime)}`)
            }
            return {
                serverTime: response.serverTime,
                userTime
            }
        }
    } catch (e) {
        console.log(JSON.stringify(e))
        return null
    }
}

const getActualWays = async (inCurrencyCode, outCurrencyCode, payway, cashbackToken, message, messageHash, signature, tester, enqueueSnackbar) => {
    if (!inCurrencyCode || !outCurrencyCode || !cashbackToken || !message || !messageHash || !signature) return { dataAll: [], comingSoon: [] }

    let needDataAllLink = `/data/all?inCurrencyCode=${inCurrencyCode.code}&outCurrencyCode=${outCurrencyCode.code}`
    // &cashbackToken=${cashbackToken}
    // let needDataComingSoonLink = `/data/coming-soon?cashbackToken=${cashbackToken}&signMessage=${message}&signMessageHash=${messageHash}&signature=${signature}&inCurrencyCode=${inCurrencyCode.code}&outCurrencyCode=${outCurrencyCode.code}`
    if ((inCurrencyCode.type === 'FIAT' || outCurrencyCode.type === 'FIAT') && payway) {
        needDataAllLink += `&paywayCode=${payway}`
        // needDataComingSoonLink += `&paywayCode=${payway}`
    }

    let [dataAll] = await Promise.all([
        serverRequest(needDataAllLink, strings('mobileMarket.modal.serverRespond'), 'DATA_ALL', null, tester, enqueueSnackbar)
        // serverRequest(needDataComingSoonLink, strings('mobileMarket.modal.serverRespond'), 'COMING_SOON', null, tester, enqueueSnackbar),
    ])

    dataAll =
        dataAll && dataAll.length
            ? dataAll.map((way) =>
                  way.exchangeWayType === 'BUY' && way.inPaywayCode.indexOf('VISA_MC') !== -1
                      ? { ...way, inPaywayCode: 'CARD' }
                      : way.exchangeWayType === 'SELL' && way.outPaywayCode.indexOf('VISA_MC') !== -1
                      ? { ...way, outPaywayCode: 'CARD' }
                      : way
              )
            : []

    if ((inCurrencyCode.type === 'FIAT' || outCurrencyCode.type === 'FIAT') && !payway) {
        return {
            dataAll: [],
            comingSoon: []
        }
    }

    return {
        dataAll
    }
}

export default getActualWays
