import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'

import Icon from '../Icon/Icon'
import UtilsService from '../../services/utilsService'

import Details from '../providersSettings/additionalData'
import ProviderLabel from '../providersSettings/providerLabel'

import ProviderData from '../providersSettings/provider'

import { strings } from '../../i18n'
import store from '../../redux'

import utilsService from '../../services/utilsService'
import { getTokenNet, isToken } from '../../services/getCurrenciesProperty'

import Label from '../providersSettings/labels'
import ErrorCode from './ErrorCode'

import { filterIsActive, filterItemVariants, handlerLimit, nextStep } from './helper'
import ProviderDiv from './provider.css'
import {
    activeExchangeWayAction,
    variantsAction,
    providerLoaderAction,
    providerAction,
    providerErrorAction,
    providerEquivalentTimeAction
} from '../../redux/AppStores/MobileMarket/action'
import { useSelector } from 'react-redux'
import {
    getAmountData,
    getUserCurrencies,
    getPaymentsDetails,
    getUserSettings,
    getAllExchangeWays,
    getSign,
    getOutDestination,
    getWalletData
} from '../../redux/AppStores/MobileMarket/selectors'
import { checkCard } from '../../modules/mobileMarket/firstScreen/helper'
import { showModal } from '../../redux/AppStores/ModalStore/action'
import getProviders from '../../services/getProviders'
import Log from '../../services/sentry/Log'
import { hideSearchScreenModal } from '../../redux/AppStores/SearchModalScreenStore/action'

const { dispatch } = store

const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
        return (
            <div className='timer' style={{ fontSize: 12, marginTop: -10 }}>
                0
            </div>
        )
    }

    return (
        <div className='timer'>
            <div className='value' style={{ fontSize: remainingTime > 99 ? 10 : 12, marginTop: -10 }}>
                {remainingTime}
            </div>
        </div>
    )
}

//timer
const timer = (provider) => {
    if (provider.rateType === 'FIXED' && provider.equivalentData) {
        let time

        if (
            (provider.equivalentData.status === 'SUCCESS' || provider.equivalentData.status === 'TIMEOUT') &&
            provider.equivalentData.hasOwnProperty('rateData')
        ) {
            time = provider.equivalentData.rateData.expirationTimestamp || 1000
        } else {
            return
        }
        return UtilsService.cutNumber((new Date(time).getTime() - Date.now()) / 1000, 0)
    } else {
        return true
    }
}

const notValid = (item) => {
    const provider = document.getElementById(item.exchangeWayId)
    const state = store.getState().mobileMarket

    if (provider.id === item.exchangeWayId && item.rateType === 'FIXED') {
        provider.style.cssText = `display: flex;`
        // providerMain.style.border = 'none'

        const array = state.exchangeWaysArray
            ? state.exchangeWaysArray.map((way) => {
                  if (way.exchangeWayId === item.exchangeWayId) {
                      return {
                          ...item,
                          equivalentData: {
                              ...item.equivalentData,
                              status: 'TIMEOUT'
                          },
                          valid: false
                      }
                  }
                  return way
              })
            : state.exchangeWaysArray

        item.equivalentData.status = 'TIMEOUT'
        dispatch(variantsAction(array))
    }
}

const ProviderItem = (props) => {
    // const state = store.getState().mobileMarket

    const amountData = useSelector(getAmountData)
    const userCurrencies = useSelector(getUserCurrencies)
    const paymentData = useSelector(getPaymentsDetails)
    const settings = useSelector(getUserSettings)
    const allExchangeWays = useSelector(getAllExchangeWays)
    const sign = useSelector(getSign)
    const destinationData = useSelector(getOutDestination)
    const walletData = useSelector(getWalletData)

    const history = useHistory()

    const { inCurrency, outCurrency, payway, cashbackToken } = userCurrencies

    let {
        item,
        offer,
        rate,
        loader,
        setLoad,
        providersArray,
        findButtonHandler,
        setLoader,
        setVariantsArray,
        setLoading,
        setTooltip,
        theme,
        requestId,
        enqueueSnackbar,
        newFlow
    } = props

    item = item?.equivalentData ? filterItemVariants(item) : item

    const [itemData, setItem] = useState(item)

    const state = {
        inCurrency,
        outCurrency,
        payway,
        cashbackToken,
        sign,
        paymentData,
        outDestination: destinationData.outDestination
    }

    const handleProvider = async (e, amount = null, sideAmount = null) => {
        e && e?.preventDefault()

        if (e?.detail > 1) return

        if (!amountData.amount || !inCurrency || !outCurrency) return

        if ((inCurrency.type === 'FIAT' || outCurrency.type === 'FIAT') && !payway) return

        if ((inCurrency.type === 'FIAT' || outCurrency.type === 'FIAT') && paymentData) {
            const validStatus = checkCard()

            if (validStatus && validStatus.length === 1 && validStatus[0] === false) {
                showModal({
                    type: 'INFO_MODAL',
                    icon: 'WARNING',
                    header: strings('mobileMarket.modal.attention'),
                    description: strings(`mobileMarket.providerErrors.${payway === 'CARD' ? 'notValidCard' : 'notValidAccount'}`)
                })
                return
            }
        }

        dispatch(providerLoaderAction(true))
        // urlPath('pre-check', 'page')
        hideSearchScreenModal()

        dispatch(activeExchangeWayAction(null))

        const arrayProviders = await getProviders(
            state,
            requestId,
            amount || amountData.amount,
            sideAmount || amountData.amountSide,
            settings.tester,
            enqueueSnackbar
        )

        let finalArray = arrayProviders !== null ? filterIsActive(arrayProviders.variants, allExchangeWays) : []
        finalArray = finalArray.map((item) => filterItemVariants(item))

        Log.info(`V3.firstScreen.findButtonHandler getPrvoiders result ${JSON.stringify(finalArray)}`)

        dispatch(variantsAction(finalArray))

        localStorage.removeItem('amountData')

        if (finalArray && finalArray.length && finalArray[0]?.equivalentData && finalArray[0]?.equivalentData?.status === 'SUCCESS') {
            dispatch(providerAction(finalArray[0], allExchangeWays[0].exchangeWayType))
            // urlPath('pre-check', 'page')
            dispatch(providerLoaderAction(false))
            dispatch(providerEquivalentTimeAction(new Date().getTime()))
            return
        }

        dispatch(providerErrorAction(finalArray[0]?.equivalentData))
        dispatch(providerLoaderAction(false))
        dispatch(providerEquivalentTimeAction(null))
        window.history.back()
    }

    if (typeof item === 'undefined' || item === 'undefined' || !item) return null

    let time
    if (typeof item.rateType !== 'undefined' && item.rateType && item.rateType === 'FIXED') {
        time = timer(item)
    }

    const urlPath = (url) => {
        history.push({
            pathname: `/mobile-market/${url}`,
            location: { state: 'fade' }
        })
    }

    const pathUrl = (url) => {
        history.push('/mobile-market/' + url)
    }

    const provider = allExchangeWays.find((way) => way.exchangeWayId === item.exchangeWayId) || null
    // if (!provider) return false

    const labels = provider ? provider.exchangeWayLabelCodes : null
    const providerRating = provider && !provider.equivalentData ? provider.providerRating : null
    let exchangeWayType = provider
        ? provider.exchangeWayType
        : item.outCurrency.type === 'FIAT'
        ? 'SELL'
        : item.inCurrency.type === 'FIAT'
        ? 'BUY'
        : 'EXCHANGE'
    const isComming = provider ? provider.isComingSoonProvider : null

    const valid = item?.valid || false
    // console.log('itemData', itemData)
    // console.log('item', item)

    return (
        <ProviderDiv
            activeProviderBg={theme.provider.activeBg}
            failProviderBg={theme.provider.failBg}
            rateColor={theme.provider.rate}
            amountColor={theme.topNav.colorIcon}
            textColor={theme.topNav.color}
            failTextColor={theme.provider.failText}
            bestColor={theme.main.checked}
            timerBorder={theme.topNav.colorIcon}
            onClick={(event) =>
                item.exchangeRate
                    ? setTooltip && setTooltip(true)
                    : item.equivalentData && item.equivalentData.status === 'TIMEOUT'
                    ? null
                    : item.equivalentData && item.equivalentData.status === 'SUCCESS' && valid
                    ? nextStep(
                          item,
                          urlPath,
                          loader,
                          setLoad,
                          providersArray,
                          setLoader,
                          setVariantsArray,
                          setLoading,
                          pathUrl,
                          newFlow ? handleProvider : findButtonHandler,
                          event,
                          newFlow
                      )
                    : handlerLimit(item, state, exchangeWayType, valid, newFlow ? handleProvider : findButtonHandler, event, newFlow)
            }>
            <div
                className={
                    item.exchangeRate && isComming
                        ? 'provider__fail'
                        : item.exchangeRate
                        ? 'wrapper'
                        : item.equivalentData !== null
                        ? item.equivalentData.status === 'SUCCESS' && valid
                            ? 'provider__item'
                            : item.equivalentData === null || item.equivalentData.status === 'FAIL' || !valid
                            ? 'provider__fail'
                            : 'provider__item'
                        : 'provider__fail'
                }>
                <ProviderData
                    icon={item.providerName || item.provider}
                    name={item.providerName || item.provider}
                    rating={providerRating}
                    iconStyle={{ paddingLeft: '18px' }}
                    theme={theme}
                />
                <div className='main__data'>
                    {item.equivalentData && typeof item.equivalentData.exchangeRate !== 'undefined' ? (
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Icon
                                icon={item.rateType === 'FIXED' ? 'LOCK' : 'UNLOCK'}
                                color={theme.topNav.colorIcon}
                                size={20}
                                style={{ marginRight: 5 }}
                            />
                            <div className='provider__rate'>
                                {`1 ${
                                    item.exchangeRateSide === 'OUT'
                                        ? item.inCurrency.symbol
                                        : item.exchangeRateSide === 'IN'
                                        ? item.outCurrency.symbol
                                        : item.inCurrency.symbol
                                } `}
                                {isToken(
                                    walletData,
                                    item.exchangeRateSide === 'OUT'
                                        ? item.inCurrency.code
                                        : item.exchangeRateSide === 'IN'
                                        ? item.outCurrency.code
                                        : item.inCurrency.code
                                ) && (
                                    <div className='tickersIcon' style={{ height: 14, width: 16 }}>
                                        <Icon
                                            icon={getTokenNet(
                                                walletData,
                                                item.exchangeRateSide === 'OUT'
                                                    ? item.inCurrency.code
                                                    : item.exchangeRateSide === 'IN'
                                                    ? item.outCurrency.code
                                                    : item.inCurrency.code
                                            )}
                                            color='#999999'
                                            size={13}
                                        />
                                    </div>
                                )}
                                {`${item.equivalentData.exchangeRate ? (item.rateType === 'FIXED' ? '=' : '≈') : ''} 
                            ${
                                item.equivalentData.exchangeRate
                                    ? item.equivalentData.exchangeRate
                                    : item.equivalentData.currencySide === 'IN'
                                    ? item.outCurrency.type === 'CRYPTO'
                                        ? '> 0.000001'
                                        : '> 0.01'
                                    : item.outCurrency.type === 'CRYPTO'
                                    ? '< 0.000001'
                                    : '< 0.01'
                            } 
                            ${
                                item.exchangeRateSide === 'OUT'
                                    ? item.outCurrency.symbol
                                    : item.exchangeRateSide === 'IN'
                                    ? item.inCurrency.symbol
                                    : item.inCurrency.symbol
                            }`}
                                {isToken(
                                    walletData,
                                    item.exchangeRateSide === 'OUT'
                                        ? item.outCurrency.code
                                        : item.exchangeRateSide === 'IN'
                                        ? item.inCurrency.code
                                        : item.inCurrency.code
                                ) && (
                                    <div className='tickersIcon' style={{ height: 14, width: 14 }}>
                                        <Icon
                                            icon={getTokenNet(
                                                walletData,
                                                item.exchangeRateSide === 'OUT'
                                                    ? item.outCurrency.code
                                                    : item.exchangeRateSide === 'IN'
                                                    ? item.inCurrency.code
                                                    : item.inCurrency.code
                                            )}
                                            color='#999999'
                                            size={13}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : item.exchangeRate && typeof item.exchangeRate.exchangeRate !== 'undefined' ? (
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Icon
                                icon={item.exchangeRate.rateType === 'FIXED' ? 'LOCK' : 'UNLOCK'}
                                color={theme.provider.rate}
                                size={24}
                                style={{ marginRight: 5 }}
                            />
                            <div className='rate'>
                                {`1 ${
                                    item.exchangeRate.currencySide === 'OUT'
                                        ? item.inCurrency.symbol
                                        : item.exchangeRate.currencySide === 'IN'
                                        ? item.outCurrency.symbol
                                        : item.inCurrency.symbol
                                } `}

                                {isToken(
                                    walletData,
                                    item.exchangeRate.currencySide === 'OUT'
                                        ? item.inCurrency.code
                                        : item.exchangeRate.currencySide === 'IN'
                                        ? item.outCurrency.code
                                        : item.inCurrency.code
                                ) && (
                                    <div className='tickersIcon'>
                                        <Icon
                                            icon={getTokenNet(
                                                walletData,
                                                item.exchangeRate.currencySide === 'OUT'
                                                    ? item.inCurrency.code
                                                    : item.exchangeRate.currencySide === 'IN'
                                                    ? item.outCurrency.code
                                                    : item.inCurrency.code
                                            )}
                                            color={theme.topNav.colorIcon}
                                            size={15}
                                        />
                                    </div>
                                )}

                                {`${item.exchangeRate.exchangeRate ? (item.exchangeRate.rateType === 'FIXED' ? '=' : '≈') : ''}
                                ${
                                    item.exchangeRate.exchangeRate
                                        ? item.exchangeRate.exchangeRate
                                        : item.exchangeRate.currencySide === 'IN'
                                        ? item.outCurrency.type === 'CRYPTO'
                                            ? '> 0.000001'
                                            : '> 0.01'
                                        : item.outCurrency.type === 'CRYPTO'
                                        ? '< 0.000001'
                                        : '< 0.01'
                                } 
                                ${
                                    item.exchangeRate.currencySide === 'OUT'
                                        ? item.outCurrency.symbol
                                        : item.exchangeRate.currencySide === 'IN'
                                        ? item.inCurrency.symbol
                                        : item.outCurrency.symbol
                                }`}

                                {isToken(
                                    walletData,
                                    item.exchangeRate.currencySide === 'OUT'
                                        ? item.outCurrency.code
                                        : item.exchangeRate.currencySide === 'IN'
                                        ? item.inCurrency.code
                                        : item.outCurrency.code
                                ) && (
                                    <div className='tickersIcon'>
                                        <Icon
                                            icon={getTokenNet(
                                                walletData,
                                                item.exchangeRate.currencySide === 'OUT'
                                                    ? item.outCurrency.code
                                                    : item.exchangeRate.currencySide === 'IN'
                                                    ? item.inCurrency.code
                                                    : item.outCurrency.code
                                            )}
                                            color={theme.topNav.colorIcon}
                                            size={15}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : null}
                    {item.limits ? (
                        item.limits.min === null && item.limits.max === null ? null : (
                            <div className='data'>
                                <Details
                                    name={`${strings('mobileMarket.min')}:`}
                                    value={`${utilsService.cutNumber(
                                        item.limits.min,
                                        item.inCurrency.type === 'CRYPTO' && item.outCurrency.type === 'CRYPTO' ? 6 : 2
                                    )} 
                                ${
                                    item.limits.currencySide === 'IN'
                                        ? item.inCurrency.symbol
                                        : item.limits.currencySide === 'OUT'
                                        ? item.outCurrency.symbol
                                        : item.inCurrency.symbol
                                }`}
                                    theme={theme}
                                />
                                <Details
                                    name={`${strings('mobileMarket.max')}:`}
                                    value={`${utilsService.cutNumber(
                                        item.limits.max,
                                        item.inCurrency.type === 'CRYPTO' && item.outCurrency.type === 'CRYPTO' ? 6 : 2
                                    )} 
                                ${
                                    item.limits.currencySide === 'IN'
                                        ? item.inCurrency.symbol
                                        : item.limits.currencySide === 'OUT'
                                        ? item.outCurrency.symbol
                                        : item.inCurrency.symbol
                                }`}
                                    styles={{ marginTop: 5 }}
                                    theme={theme}
                                />
                            </div>
                        )
                    ) : null}
                    {item.equivalentData ? (
                        item.equivalentData.status === 'FAIL' ? (
                            <ErrorCode
                                errorCode={item.equivalentData.errorCode}
                                item={item}
                                exchangeWayType={exchangeWayType}
                                handlerLimit={handlerLimit}
                                theme={theme}
                            />
                        ) : (
                            <>
                                {!valid && (item.inCurrency.type === 'FIAT' || item.outCurrency.type === 'FIAT') ? (
                                    <div className='providerError'>
                                        {strings(`mobileMarket.providerErrors.${payway === 'CARD' ? 'notValidCard' : 'notValidAccount'}`)}
                                    </div>
                                ) : (
                                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 16, width: 'calc(100% - 45px)' }}>
                                        <div className='provider__amount'>
                                            <Icon
                                                icon='ARROW_SEND'
                                                color={theme.topNav.colorIcon}
                                                size={16}
                                                style={{ paddingLeft: 2, paddingRight: 7 }}
                                            />
                                            {item.equivalentData ? UtilsService.cutNumber(item.equivalentData.inAmount, 6) : null}
                                            <div className='provider__code'>{item.inCurrency.symbol}</div>
                                        </div>
                                        <div className='provider__equivalent'>
                                            {item.rateType === 'FIXED' ? '=' : '≈'}
                                            <Icon
                                                icon='ARROW_RECEIVE'
                                                color={theme.topNav.colorIcon}
                                                size={16}
                                                style={{ paddingLeft: 2, paddingRight: 7 }}
                                            />
                                            {item.equivalentData ? UtilsService.cutNumber(item.equivalentData.outAmount, 6) : null}
                                            <div className='provider__code'>{item.outCurrency.symbol}</div>
                                        </div>
                                    </div>
                                )}
                            </>
                        )
                    ) : isComming ? (
                        <ErrorCode errorCode='COMING_SOON' theme={theme} />
                    ) : item.exchangeRate ? (
                        item.isComingSoonProvider ? (
                            <ErrorCode errorCode='COMING_SOON' theme={theme} />
                        ) : null
                    ) : (
                        <ErrorCode errorCode='PROVIDER_ERROR' theme={theme} />
                    )}
                    <div className='label__wrapper'>
                        {labels
                            ? labels.map((item) => {
                                  return <ProviderLabel condition={item} key={item} name={Label[item] || item} theme={theme} />
                              })
                            : null}
                    </div>
                </div>
                {typeof item !== 'undefined' ? (
                    item.rateType === 'FIXED' && item.equivalentData ? (
                        (item.equivalentData.status === 'SUCCESS' || item.equivalentData.status === 'TIMEOUT') &&
                        item.equivalentData.hasOwnProperty('rateData') ? (
                            item.equivalentData.rateData.expirationTimestamp ? (
                                <div className='timer__wrapper' id='timer'>
                                    <CountdownCircleTimer
                                        isPlaying
                                        duration={time}
                                        size={30}
                                        strokeWidth={0}
                                        colors={theme.main.checked}
                                        onComplete={() => [notValid(item), 1000]}>
                                        {renderTime}
                                    </CountdownCircleTimer>
                                </div>
                            ) : null
                        ) : null
                    ) : null
                ) : null}
                {typeof item !== 'undefined' ? (
                    item.rateType === 'FIXED' && item.equivalentData && item.equivalentData.hasOwnProperty('rateData') ? (
                        item.equivalentData.rateData.expirationTimestamp ? (
                            <div id={item.exchangeWayId} className='finishTime' style={{ display: 'none' }}>
                                <div className='textTime'>{strings('mobileMarket.timeOutProvider')}</div>
                            </div>
                        ) : null
                    ) : null
                ) : null}
                {/* {
                    typeof item !== 'undefined' ? ((item.exchangeRate && typeof item.exchangeRate.exchangeRate !== 'undefined') || (item.equivalentData && item.equivalentData.errorCode !== 'COMING_SOON')) ?
                        ((rate ? item.exchangeWayId === rate.exchangeWayId : false) && (rate && offer ? item.exchangeWayId !== rate.exchangeWayId !== offer.exchangeWayId : true)) ?
                            <div className='provider__best'>
                                {strings('mobileMarket.bestRate').toUpperCase()}
                            </div> : null : null : null
                } */}
                {typeof item !== 'undefined' ? (
                    item.equivalentData ? (
                        (item.equivalentData.status === 'SUCCESS' || item.equivalentData.status === 'TIMEOUT') &&
                        (offer !== null ? item.exchangeWayId === offer.exchangeWayId : false) ? (
                            <div className='provider__best'>{strings('mobileMarket.bestOffer').toUpperCase()}</div>
                        ) : null
                    ) : null
                ) : null}
                {/* <div className={classes.footer}> */}
                {/* <div className={classes.info} onClick={() => {
                    urlPath(`provider-info?item=${JSON.stringify(item)}`)
                }}>
                    <Icon icon={'INFO'} size={24} color={'#404040'} />
                </div> */}
                {/* </div> */}
            </div>
        </ProviderDiv>
    )
}

export default React.memo(ProviderItem)
