import React, { useCallback } from 'react'

import Line from 'components/Line/line'
import PaywayListItem from 'components/PaywayModalListItem/paywayItem'
import countries from 'components/json/country-codes.json'

import { hideScreenModal } from 'redux/AppStores/ScreenModalStore/action'

import hideAnimation from 'services/hideAnimation'
import Log from 'services/sentry/Log'

import { BodyWrapper } from '../wrapper.css'

const CountryModal = (props) => {
    const { setCardCountryCode, cardCountryCode, theme, searchValue } = props

    const countrySelectHandler = useCallback((side) => {
        hideAnimation(`selectModal-${side?.toString()?.toLowerCase()}`, hideScreenModal)
        return
    }, [])

    const countryArray = countries.filter((item) => item.countryCode !== 'RUS')

    const country = searchValue.searchValueCountry
        ? countryArray.filter(
              (item) =>
                  item.country.toString().toUpperCase().includes(searchValue.searchValueCountry) ||
                  item.currencyCode.includes(searchValue.searchValueCountry) ||
                  item.countryCode === searchValue.searchValueCountry
          )
        : countryArray

    return (
        <BodyWrapper>
            {country.map((item, index) => {
                return (
                    <>
                        <PaywayListItem
                            key={index}
                            item={item}
                            index={index}
                            side='COUNTRY'
                            checked={cardCountryCode === item.countryCode}
                            active={true}
                            setCardCountryCode={setCardCountryCode}
                            handler={countrySelectHandler}
                            country={true}
                            theme={theme}
                        />
                        {country.length === index + 1 ? <div className='empty'></div> : <Line width='90%' />}
                    </>
                )
            })}
        </BodyWrapper>
    )
}

export default React.memo(CountryModal)
