export default function didMountSell() {
    const componentDidMountSell = `if (true) {
        that.handleState({
            status: 'PENDING'
        });

        that.handleStartAnimation();

        const url = NavStore.getParamWrapper(that, 'url')

        that.handleState({
            link: url,
            dataSend: data
        });

        setTimeout(() => {
            that.handleState({
                status: 'SUCCESS'
            }); 
        }, 15e2);
}`

    return componentDidMountSell
}
