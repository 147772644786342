import styled from 'styled-components'

const ItemHistory = styled.div`
    .iconCurrency {
        position: relative;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 48px;
    }

    .status {
        position: absolute;
        height: 24px;
        width: 24px;

        background: ${(p) => (p.colorStatus ? p.colorStatus : p.statusBg)};
        box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.1);
        border-radius: 50%;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .statusValue {
        font-family: 'Montserrat Bold';
        font-style: normal;
        font-size: 12px;
        line-height: 12px;

        text-align: center;
        letter-spacing: 1.5px;
        text-transform: uppercase;
    }

    .viewStatus {
        position: absolute;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        min-width: 110px;
        height: 30px;

        padding-left: 5px;
        padding-right: 5px;

        /* background: ${(p) => (p.viewStatusColor ? p.viewStatusColor : p.colorStatus ? p.colorStatus : '')}; */
        background: ${(p) => (p.showStatusBg ? p.showStatusBg : p.statusBg)};
        box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;

        color: ${(props) => props.viewStatusColor};
    }

    .dash {
        width: calc(100% - 90px);
        border-top: 2px ${(props) => (props.type ? 'solid' : 'dotted')} ${(p) => p.dashColor};
    }

    .detailsWrap {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        margin-top: 12px;
    }

    .currency {
        font-family: 'Montserrat Medium';
        font-style: normal;
        // font-weight: 600;
        font-size: 17px;
        line-height: 17px;

        color: ${(p) => p.valueColor};

        &.inCurrency {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
        }

        &.outCurrency {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
        }
    }

    .value {
        font-family: 'SF UI Display Medium';
        font-style: normal;
        font-size: 14px;
        line-height: 18px;

        letter-spacing: 1px;

        color: ${(p) => p.subValueColor};

        &.inValue {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
        }
        &.outValue {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
        }
    }

    @media screen and (max-width: 370px) {
        .statusValue {
            font-size: 10px;
            line-height: 13px;
            letter-spacing: 1px;
        }
    }
`

export const WrapperItemDiv = styled.div`
    height: 92px;
    width: calc(100% - 64px);
    display: flex;
    flex-direction: column;
    background: ${(p) => p.bg};
    box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    margin-bottom: 12px;
    margin-left: 16px;

    padding: 16px 16px 17px 16px;
`

export default ItemHistory
