/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'

import { strings } from 'i18n'

import store from 'redux/index'
import {
    validationOutDestinationAction,
    outDestinationTimeAction,
    destinationTagAction,
    outDestinationShowAction,
    outDestinationAction,
    emailAction
} from 'redux/AppStores/MobileMarket/action'
import { showScreenModal } from 'redux/AppStores/ScreenModalStore/action'

import currencyType from 'services/CurrencyType'
import Log from 'services/sentry/Log'
import validation from 'services/ValidationService'
import findOutDestination from 'services/findOutDestination'
import { getStatusKYC } from 'services/getStatusKYC'

import SelectModal from 'modules/ScreenModal/elements/SelectModal'
import ScreenModal from 'modules/ScreenModal/MainModal'

import { Content } from 'components/wrapperScreen/wrapper.css'
import Input from 'components/Input/Input'
import Loader from 'components/Loader/loader'
import Icon from 'components/Icon/Icon'
import Wrapper from 'components/ScreenWrapper/Wrapper'
import TopNavigation from 'components/TopNavigation/TopNavigation'
import Button from 'components/Button/Button'

import { getThemeModeStyles } from 'theme/useDarkMode'
import AdvancedDiv from './wrapper.css'

const { dispatch } = store

const needDestinationTag = (code) => {
    const array = ['XRP', 'XLM', 'BNB']

    if (array.includes(code)) {
        return true
    }

    return false
}

const outDestinationValid = (outCurrency, outDestination) => {
    if (outDestination) {
        if (!validation(currencyType(outCurrency), outDestination)) {
            return true
        }
    } else {
        return true
    }
}

const destinationTagHandler = (event) => {
    Log.info(`V3_EXC.AdvancedScreen destinationTagHandler ${event}`)
    dispatch(destinationTagAction(event))
}

const outDestinationHandler = (outDestination) => {
    dispatch(validationOutDestinationAction(false))
    dispatch(outDestinationTimeAction(outDestination))
}

const validationAdvancedScreen = () => {
    const state = store.getState().mobileMarket

    if (state.outCurrency.code === 'TON') {
        dispatch(validationOutDestinationAction(false))
        return
    }

    if (state.newOutDestination) {
        const localDestination = outDestinationValid(state.outCurrency.code, state.outDestination) || false

        if (localDestination) {
            dispatch(validationOutDestinationAction(localDestination))
        }
    }
}

const close = () => {
    validationAdvancedScreen()

    const state = store.getState().mobileMarket

    try {
        if (state.destinationValid) {
            const { outDestination, walletName } = findOutDestination(state.walletData, state.outCurrency.code, state.cashbackToken)
            dispatch(outDestinationAction(outDestination, walletName))
            window.history.back()
            // dispatch(setScreenAction(1))
        }
    } catch (e) {
        console.log(JSON.stringify(e))
    }

    window.history.back()
    // dispatch(setScreenAction(1))
}

const emailHandler = (value) => {
    dispatch(emailAction(value.toString().trim()))

    try {
        localStorage.removeItem('trusteeEmail')
    } catch (e) {
        console.log(JSON.stringify(e))
    }
}

const AdvancedScreen = () => {
    const state = store.getState().mobileMarket
    const modalScreenState = store.getState().modalScreenStore

    const theme = getThemeModeStyles()

    const [loader, setLoader] = useState(false)
    const [showEmail, setShowEmail] = useState(false)

    useEffect(async () => {
        const email = localStorage.getItem('trusteeEmail') ? localStorage.getItem('trusteeEmail') : null

        setLoader(true)
        if (email) {
            const dataKYC = await getStatusKYC(email)

            Log.info(`V3.AdvancedScreen.getStatusKYC ${JSON.stringify(dataKYC)}`)

            if (
                dataKYC &&
                typeof dataKYC.status !== 'undefined' &&
                dataKYC.status !== 'NOT_REGISTERED' &&
                typeof dataKYC.userId !== 'undefined' &&
                dataKYC.userId
            ) {
                setShowEmail(true)
                dispatch(emailAction(dataKYC.userId))
            } else {
                dispatch(emailAction(null))
            }
        } else {
            dispatch(emailAction(null))
        }

        setLoader(false)
    }, [])

    return (
        <Content
            style={{ zIndex: 1500 }}
            background={theme.main.bg}
            // colorText={theme.topNav.color}
        >
            <Wrapper>
                {loader && (
                    <>
                        <Loader />
                    </>
                )}
                <TopNavigation text={strings('mobileMarket.navigation.settings')} styles={{ zIndex: 1600 }} leftType='back' leftAction={close} />
                <AdvancedDiv
                    textColor={theme.topNav.color}
                    inputBg={theme.input.bg}
                    inputColor={theme.input.textColor}
                    btnBg={theme.main.selector.bg}
                    selectBtn={theme.bottomNav.addBtn}>
                    <div className='give__get' style={{ paddingBottom: 10, paddingLeft: 10, marginTop: 24 }}>
                        {strings('mobileMarket.chooseDestinationAddress')}
                    </div>
                    <div className='input__wrapper'>
                        {state.newOutDestination === true ? (
                            <>
                                <div className={state.destinationValid ? 'newOutDestination__error' : 'newOutDestination'}>
                                    {navigator.userAgent.indexOf('Chrome') !== -1 ? (
                                        <input
                                            type='text'
                                            id='inputDestination'
                                            placeholder={strings('mobileMarket.destinationAddress')}
                                            onChange={(event) => outDestinationHandler(event.target.value)}
                                            onBlur={() => {
                                                const address = document.getElementById('inputDestination')
                                                if (address.value.length > 20) {
                                                    address.value = address.value.toString().slice(0, 6) + '...' + address.value.toString().slice(-6)
                                                } else {
                                                    address.value = address.value.toString()
                                                }
                                                validationAdvancedScreen()
                                            }}
                                            onFocus={() => {
                                                const address = document.getElementById('inputDestination')
                                                address.value = state.outDestination
                                            }}
                                            style={{ height: navigator.userAgent.indexOf('Chrome') !== -1 ? 50 : 40 }}
                                            autoFocus={true}
                                        />
                                    ) : (
                                        <div className={state.destinationValid ? 'input__boxShadow__error' : 'input__boxShadow'}>
                                            <input
                                                type='text'
                                                id='inputDestination'
                                                placeholder={strings('mobileMarket.destinationAddress')}
                                                onChange={(event) => outDestinationHandler(event.target.value)}
                                                onBlur={() => {
                                                    const address = document.getElementById('inputDestination')
                                                    if (address.value.length > 20) {
                                                        address.value =
                                                            address.value.toString().slice(0, 6) + '...' + address.value.toString().slice(-6)
                                                    } else {
                                                        address.value = address.value.toString()
                                                    }
                                                    validationAdvancedScreen()
                                                }}
                                                onFocus={() => {
                                                    const address = document.getElementById('inputDestination')
                                                    address.value = state.outDestination
                                                }}
                                                style={{ height: navigator.userAgent.indexOf('Chrome') !== -1 ? 50 : 40 }}
                                                autoFocus={true}
                                            />
                                        </div>
                                    )}
                                </div>
                            </>
                        ) : (
                            <div className='currency__input'>
                                <div
                                    onClick={() => {
                                        // dispatch(outDestinationShowAction())
                                        showScreenModal({
                                            type: 'SELECT_MODAL',
                                            data: {
                                                side: 'ADDRESS'
                                            }
                                        })
                                        return
                                    }}
                                    className='input__text'>
                                    {state.walletName ? state.walletName : strings('mobileMarket.destinationAddress')}
                                </div>
                            </div>
                        )}
                        <div
                            className='currency__button'
                            onClick={() => {
                                // dispatch(outDestinationShowAction())
                                showScreenModal({
                                    type: 'SELECT_MODAL',
                                    data: {
                                        side: 'ADDRESS'
                                    }
                                })
                                return
                            }}>
                            <Icon
                                icon='ARROW_DOWN'
                                color={theme.main.arrow}
                                size={36}
                                style={{ display: 'flex', marginTop: 6 }}
                                className={state.outDestinationShow ? 'rotate' : null}
                            />
                        </div>
                    </div>
                    {state.newOutDestination === true && needDestinationTag(state.outCurrency.code) ? (
                        <div className='tag__wrapper'>
                            <input
                                type='text'
                                id='destinationTag'
                                placeholder='Destination tag'
                                onChange={(event) => destinationTagHandler(event.target.value)}
                                onBlur={() => {
                                    const tag = document.getElementById('destinationTag')
                                    if (tag.value.length > 20) {
                                        tag.value = tag.value.toString().slice(0, 6) + '...' + tag.value.toString().slice(-6)
                                    } else {
                                        tag.value = tag.value.toString()
                                    }
                                }}
                                onFocus={() => {
                                    const tag = document.getElementById('destinationTag')
                                    tag.value = state.destinationTag
                                }}
                            />
                        </div>
                    ) : null}
                    {state.destinationValid ? (
                        <>
                            <div className='destination__wrong'>{strings('mobileMarket.destinationError')}</div>
                        </>
                    ) : null}
                    {showEmail && (
                        <div className='inputWrapper'>
                            <div className='give__get' style={{ paddingBottom: 10, paddingLeft: 10 }}>
                                {strings('mobileMarket.emailKYC')}
                            </div>
                            <Input
                                value={state?.email?.toLowerCase() || ''}
                                placeholder={strings('mobileMarket.kyc.placeholder')}
                                id='kyc_email'
                                handler={emailHandler}
                                disabled={false}
                                condition={false}
                                style={{ boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.1)' }}
                                theme={theme}
                            />
                        </div>
                    )}
                    {/* <div className='time'>
                        <div>{`ServerTime: ${new Date(state.time.serverTime).toTimeString().slice(0, 8)}`}</div>
                        <div>{`UserTime: ${new Date(state.time.userTime).toTimeString().slice(0, 8)}`}</div>
                        <div>{`UserLocaleTime: ${new Date(state.time.userTime).toLocaleTimeString()}`}</div>
                    </div> */}
                    <Button
                        condition={state.outDestination && !state.destinationValid}
                        handler={close}
                        style={{
                            bottom: '24px',
                            position: 'fixed',
                            width: window.screen.width > 768 ? '436px' : 'calc(100% - 32px)',
                            right: window.screen.width > 768 ? 'calc((100% - 736px) / 2)' : ''
                        }}>
                        {strings('mobileMarket.confirm')}
                    </Button>
                    {state.outDestinationShow && <SelectModal side='ADDRESS' close={() => dispatch(outDestinationShowAction())} />}
                </AdvancedDiv>
            </Wrapper>
            {modalScreenState.show && <ScreenModal />}
        </Content>
    )
}

export default AdvancedScreen
