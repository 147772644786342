import styled from 'styled-components'

const PercentBlockDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    margin: 60px 0 0 0;

    .btn {
        border: 2px solid ${(p) => p.borderColor};
        box-sizing: border-box;
        border-radius: 8px;
        outline: none;
        width: 23%;
        min-height: 34px;
        padding: 10px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Montserrat Bold';
        font-style: normal;
        font-size: 14px;
        line-height: 14px;
        color: ${(p) => p.textColor};
        -webkit-tap-highlight-color: transparent;

        &.inverse {
            background: ${(p) => p.borderColor};
            color: ${(p) => p.textInverse};
            border: 2px solid ${(p) => p.borderColor};
        }
    }
`

export default PercentBlockDiv
