import React from 'react'
import { getThemeModeStyles } from '../../theme/useDarkMode'
import LineDiv from './line.css'

const Line = (props) => {
    const { width } = props

    const theme = getThemeModeStyles()

    return <LineDiv bg={theme.orderHistory.dashColor} style={{ width: width }} />
}

export default Line
