import { createSelector } from 'reselect'

export const getWalletData = createSelector([(state) => state.mobileMarket.walletData], (data) => data)

export const getShowGuide = createSelector([(state) => state.mobileMarket.showGuide], (data) => data)

export const getLoader = createSelector([(state) => state.mobileMarket.showLoader], (data) => data)

export const getUserCurrencies = createSelector([(state) => state.mobileMarket], (data) => {
    return {
        inCurrency: data.inCurrency,
        outCurrency: data.outCurrency,
        payway: data.payway,
        cashbackToken: data.cashbackToken,
        paywayList: data.paywayList,
        useAllFunds: data.useAllFunds
    }
})

export const getPaymentsDetails = createSelector([(state) => state.mobileMarket.paymentData], (data) => data)

export const getAmountData = createSelector([(state) => state.mobileMarket], (data) => {
    return {
        amount: data.amount,
        amountSide: data.amountSide,
        limits: data.limits,
        limitsError: data.limitsError,
        providerError: data.providerError
    }
})

export const getPreRequestExchangeWays = createSelector([(state) => state.mobileMarket.preRequestExchagneWays], (data) => data)

export const getAllExchangeWays = createSelector([(state) => state.mobileMarket.allExchangeWays], (data) => data)

export const getSearchValue = createSelector([(state) => state.mobileMarket], (data) => {
    return {
        searchValueInSide: data.searchValueInSide,
        searchValueOutSide: data.searchValueOutSide,
        searchValuePayway: data.searchValuePayway,
        searchValueCountry: data.searchValueCountry
    }
})

export const getUserSettings = createSelector([(state) => state.mobileMarket], (data) => {
    return {
        tester: data.tester,
        version: data.version,
        versionNumber: data.versionNumber,
        cameraResult: data.cameraResult,
        toOrderHash: data.toOrderHash,
        trusteeWallet: data.trusteeWallet
    }
})

export const getSign = createSelector([(state) => state.mobileMarket.sign], (data) => data)

export const getOutDestination = createSelector([(state) => state.mobileMarket], (data) => {
    return {
        outDestination: data.outDestination,
        walletName: data.walletName
    }
})

export const getActiveCoins = createSelector([(state) => state.mobileMarket], (data) => {
    return {
        activeInCurrency: data.activeInCurrency,
        activeOutCurrency: data.activeOutCurrency
    }
})

export const getExchangeWaysArray = createSelector([(state) => state.mobileMarket.exchangeWaysArray], (data) => data)

export const getRefreshValue = createSelector([(state) => state.mobileMarket.refreshData], (data) => data)

export const getProviderData = createSelector([(state) => state.mobileMarket], (data) => {
    return {
        providerData: data.provider,
        activeExchangeWay: data.activeExchangeWay
    }
})

export const getProviderLoader = createSelector([(state) => state.mobileMarket.providerLoader], (data) => data)

export const getPreCheckData = createSelector([(state) => state.mobileMarket], (data) => {
    return {
        afterKYC: data.afterKYC,
        balanceHodlMsg: data.balanceHodlMsg,
        useCookie: data.useCookie,
        recheckSendAll: data.recheckSendAll,
        coinAllbalance: data.coinAllbalance,
        comment: data.comment,
        originalData: data.originalData,
        selectedFee: data.selectedFee,
        order: data.order,
        preCheck: data.preCheck,
        comeBack: data.comeBack,
        needSearchProviders: data.needSearchProviders
    }
})

export const getPropsModal = createSelector([(state) => state.mobileMarket.propsModal], (data) => data)
