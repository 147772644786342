import React, { useState, useEffect } from 'react'
import queryString from 'query-string'
import { createPortal } from 'react-dom'

import store from '../../redux'

import Carousel from 're-carousel'
import IndicatorDots from './dots'
import Buttons from './buttons'

import ThreeInOneEn from '../../img/slider/3in1_eng.svg'
import CheckEn from '../../img/slider/check_eng.svg'
import SearchEn from '../../img/slider/search_eng.svg'
import SelfEn from '../../img/slider/self_eng.svg'

import ThreeInOneUk from '../../img/slider/3in1_uk.svg'
import CheckUk from '../../img/slider/check_uk.svg'
import SearchUk from '../../img/slider/search_uk.svg'
import SelfUk from '../../img/slider/self_uk.svg'

import ThreeInOneRu from '../../img/slider/3in1_ru.svg'
import CheckRu from '../../img/slider/check_ru.svg'
import SearchRu from '../../img/slider/search_ru.svg'
import SelfRu from '../../img/slider/self_ru.svg'

import ThereInOneEnDark from '../../img/slider/dark/3in1_en_d.svg'
import CheckEnDark from '../../img/slider/dark/chooseYourself_en_d.svg'
import SearchEnDark from '../../img/slider/dark/smartSearch_en_d.svg'
import AllEnDark from '../../img/slider/dark/allOn_en_d.svg'

import ThereInOneUkDark from '../../img/slider/dark/3in1_ua_d.svg'
import CheckUkDark from '../../img/slider/dark/chooseYourself_ua_d.svg'
import SearchUkDark from '../../img/slider/dark/smartSearch_ua_d.svg'
import AllUkDark from '../../img/slider/dark/allOn_ua_d.svg'

import ThereInOneRuDark from '../../img/slider/dark/3in1_ru_d.svg'
import CheckRuDark from '../../img/slider/dark/chooseYourself_ru_d.svg'
import SearchRuDark from '../../img/slider/dark/smartSearch_ru_d.svg'
import AllRuDark from '../../img/slider/dark/allOn_ru_d.svg'

import TrusteeMarket from '../../img/slider/guide_newImg.svg'

import { strings } from '../../i18n'
import { getThemeMode } from '../../theme/useDarkMode'
import SliderDiv from './slyder.css'

const SliderScreen = (props) => {
    const { theme } = props

    const mode = getThemeMode()

    const state = store.getState().mobileMarket

    const locales = localStorage.getItem('i18nextLng') || 'en'

    const [elements, setElements] = useState([])

    useEffect(() => {
        const url = window.location.href
        const urlParamsString = url.slice(url.indexOf('?') + 1)

        const urlParamsObj = queryString.parse(urlParamsString)
        const nameWallet = typeof urlParamsObj.wallet !== 'undefined' ? urlParamsObj.wallet : false

        if (nameWallet) {
            setElements([
                {
                    img: TrusteeMarket,
                    title: strings('mobileMarket.guide.slider0.title'),
                    text: strings('mobileMarket.guide.slider0.description')
                },
                {
                    img:
                        mode === 'light'
                            ? locales === 'ru'
                                ? SearchRu
                                : locales === 'uk'
                                ? SearchUk
                                : SearchUk
                            : locales === 'ru'
                            ? SearchEnDark
                            : locales === 'uk'
                            ? SearchUkDark
                            : SearchRuDark,
                    title: strings('mobileMarket.guide.slider2.title'),
                    text: strings('mobileMarket.guide.slider2.description')
                },
                {
                    id: 'AllIn',
                    img:
                        mode === 'light'
                            ? locales === 'ru'
                                ? CheckRu
                                : locales === 'uk'
                                ? CheckUk
                                : CheckEn
                            : locales === 'ru'
                            ? AllRuDark
                            : locales === 'uk'
                            ? AllUkDark
                            : AllEnDark,
                    title: strings('mobileMarket.guide.slider4.title'),
                    text: strings('mobileMarket.guide.slider4.description')
                }
            ])
        } else {
            setElements([
                {
                    img:
                        mode === 'light'
                            ? locales === 'ru'
                                ? ThreeInOneRu
                                : locales === 'uk'
                                ? ThreeInOneUk
                                : ThreeInOneEn
                            : locales === 'ru'
                            ? ThereInOneRuDark
                            : locales === 'uk'
                            ? ThereInOneUkDark
                            : ThereInOneEnDark,
                    title: strings('mobileMarket.guide.slider1.title'),
                    text: strings('mobileMarket.guide.slider1.description')
                },
                {
                    img:
                        mode === 'light'
                            ? locales === 'ru'
                                ? SearchRu
                                : locales === 'uk'
                                ? SearchUk
                                : SearchUk
                            : locales === 'ru'
                            ? SearchEnDark
                            : locales === 'uk'
                            ? SearchUkDark
                            : SearchRuDark,
                    title: strings('mobileMarket.guide.slider2.title'),
                    text: strings('mobileMarket.guide.slider2.description')
                },
                {
                    img:
                        mode === 'light'
                            ? locales === 'ru'
                                ? SelfRu
                                : locales === 'uk'
                                ? SelfUk
                                : SelfEn
                            : locales === 'ru'
                            ? CheckEnDark
                            : locales === 'uk'
                            ? CheckUkDark
                            : CheckRuDark,
                    title: strings('mobileMarket.guide.slider3.title'),
                    text: strings('mobileMarket.guide.slider3.description')
                },
                {
                    id: 'AllIn',
                    img:
                        mode === 'light'
                            ? locales === 'ru'
                                ? CheckRu
                                : locales === 'uk'
                                ? CheckUk
                                : CheckEn
                            : locales === 'ru'
                            ? AllRuDark
                            : locales === 'uk'
                            ? AllUkDark
                            : AllEnDark,
                    title: strings('mobileMarket.guide.slider4.title'),
                    text: strings('mobileMarket.guide.slider4.description')
                }
            ])
        }

        if (Number(state.versionNumber)) {
            window?.ReactNativeWebView && window?.ReactNativeWebView?.postMessage(JSON.stringify({ homePage: true }))
        }
    }, [])

    return createPortal(
        <>
            <aside role='dialog' tabIndex='-1' aria-modal='true'>
                <SliderDiv id='skip' bg={theme.main.bg} titleColor={theme.topNav.colorIcon} textColor={theme.topNav.color} btn={theme.bottomNav.bg}>
                    <div className='main'>
                        <Carousel widgets={[IndicatorDots, Buttons]}>
                            {elements.map((item, index) => {
                                return (
                                    <>
                                        <div id={item.id || null} className='item__slider'>
                                            <div className='img__wrapper'>
                                                <img src={item.img} alt={'guide'} loading={'lazy'} width={'100%'} />
                                            </div>
                                        </div>
                                        <div className='text'>
                                            <div className='title'>{item.title}</div>
                                            <div className='description'>{item.text}</div>
                                        </div>
                                    </>
                                )
                            })}
                        </Carousel>
                    </div>
                </SliderDiv>
            </aside>
        </>,
        document.body
    )
}

export default SliderScreen
