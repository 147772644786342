import React, { useCallback, useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { amountAction, amountSideAction, sendAllAction, limitErrorAction } from 'redux/AppStores/MobileMarket/action'
import { showModal } from 'redux/AppStores/ModalStore/action'
import { getAllExchangeWays } from 'redux/AppStores/MobileMarket/selectors'

import { strings } from 'i18n'

import { getCurrencyCode } from 'services/getCurrenciesProperty'
import utilsService from 'services/utilsService'
import Log from 'services/sentry/Log'
import findOutDestination from 'services/findOutDestination'
import limitScan from 'services/limitScan'
import minMaxLimits from 'services/minMaxLimit'

import Icon from 'components/Icon/Icon'
import UiColor from 'components/currencies/UiColor'
import Message from 'components/InfoMsg/Message'

import { getThemeMode } from 'theme/useDarkMode'
import Loader from 'img/icon/loaderUniverse.svg'

import PercentBlock from '../PercentBlock/PercentBlock'
import InputBlockDiv from './inputBlock.css'
import { displayInputWidth } from './helper'

const InputBlock = (props) => {
    const { theme, walletData, userCurrencies, amountData, exchangeWaysArray, loading, setLoading, focus, setFocus, allFundsData } = props

    const { inCurrency, outCurrency, cashbackToken, payway, useAllFunds } = userCurrencies

    const allExchangeWays = useSelector(getAllExchangeWays)

    const [button, setButton] = useState(null)

    const dispatch = useDispatch()

    useEffect(() => {
        setButton(null)
        setLoading(false)
    }, [inCurrency, outCurrency, setLoading])

    useEffect(() => {
        if (useAllFunds) setButton(1)
        if (!button && !useAllFunds) setButton(null)
    }, [button, useAllFunds])

    useEffect(() => {
        displayInputWidth(amountData?.amount || '', '40px Montserrat Medium')
    }, [amountData])

    const mode = getThemeMode()

    let inCurrencyUserData = inCurrency
        ? inCurrency.type === 'CRYPTO'
            ? walletData?.wallets
                  .find((wallet) => wallet.cashbackToken === cashbackToken)
                  .accounts.find((account) => account.currencyCode === inCurrency.code)
            : null
        : null

    const balanceData = inCurrency
        ? `${strings('mobileMarket.youHave')} ${utilsService.cutNumber(
              inCurrencyUserData
                  ? (inCurrencyUserData?.prettyBalanceRaw || inCurrencyUserData?.balance) <= 0
                      ? 0
                      : inCurrencyUserData?.prettyBalanceRaw || inCurrencyUserData?.balance
                  : 0,
              8
          )} ${inCurrency?.symbol || getCurrencyCode(inCurrency?.code)}`
        : null

    const sideHandler = (side) => dispatch(amountSideAction(side))

    const handleBtn = useCallback(
        async (part, balance, setButton) => {
            setButton(part)
            setLoading(false)

            let amount = allFundsData.recheckBalance ? balance * part : Number(part) !== 1 && balance * part
            let limit

            Log.info(`V3.mainModal.buttonHandler amount -> ${amount} part -> ${part}`)

            if (part === 1 && !allFundsData.recheckBalance) {
                setLoading(true)

                const obj = findOutDestination(walletData, inCurrency.code, cashbackToken)

                Log.info(`V3.mainModal.buttonHandler sendAll balance ${inCurrency.code} -> ${outCurrency.code}`)

                window.ReactNativeWebView &&
                    window.ReactNativeWebView.postMessage(
                        JSON.stringify({
                            useAllFunds: {
                                currencyCode: inCurrency.code,
                                address: obj.outDestination
                            }
                        })
                    )
                // return
            }

            if (part === 'MIN' || part === 'MAX') {
                setLoading(true)

                const limits = await limitScan(allExchangeWays)

                limit = minMaxLimits(allExchangeWays, exchangeWaysArray, limits || [])
                Log.info(`InputBlock.handleBtn.${part}.minMaxLimits results ${JSON.stringify(limit)}`)
            }

            if (Number(part)) {
                amount = utilsService.cutNumber(amount, 8)
                dispatch(sendAllAction(part === 1 ? true : false))
                dispatch(amountAction(amount))
                sideHandler('IN')
            } else {
                amount = part === 'MIN' ? limit.min : limit.max

                if (amount && Number(amount) > 0) {
                    dispatch(sendAllAction(false))
                    dispatch(amountAction(amount))

                    try {
                        // || activeExchangeWay === 'SELL'
                        sideHandler(exchangeWaysArray[0].exchangeWayType === 'SELL' || outCurrency.type === 'FIAT' ? 'OUT' : 'IN')
                    } catch {
                        sideHandler('IN')
                    }
                    setLoading(false)
                } else {
                    setLoading(false)
                    showModal({
                        type: 'INFO_MODAL',
                        icon: 'WARNING',
                        header: strings('mobileMarket.modal.warning'),
                        description: strings(`mobileMarket.modal.notLimits${part === 'MIN' ? 'Min' : 'Max'}`)
                    })
                    return
                }
            }

            setTimeout(() => {
                displayInputWidth(amount, '40px Montserrat Medium')
            }, 0)
            return
        },
        [userCurrencies, allExchangeWays, exchangeWaysArray, walletData, allFundsData]
    )

    const amountHandler = (amount) => {
        let value = amount.toString().indexOf(',') === -1 ? amount.toString() : amount.toString().replace(',', '.')

        const side = amountData.amountSide

        if (!(allExchangeWays && allExchangeWays.length)) return

        value = value
            .toString()
            .replace(/[^\d.,]/g, '')
            .replace(/(\..*)\./g, '$1')

        if (value.length === 1 && value === '.') {
            value = '0.'
        }

        if (value.indexOf('.') !== -1) {
            let newValue = value.slice(0, value.indexOf('.'))
            newValue = Array.from(new Set(newValue))
            if (newValue[0] === '0') {
                value = '0' + value.slice(value.indexOf('.'), value.length)
            }
            newValue = value.slice(value.indexOf('.') + 1, value.length)
            if (newValue.indexOf('.') !== -1) {
                value = value.slice(0, value.indexOf('.') + 1) + (Number(newValue) ? Number(newValue) : '')
            }
        } else if (value.indexOf('.') === -1 && value.length > 1) {
            value *= 1
        }

        dispatch(sendAllAction(false))
        dispatch(amountAction(value))
        setButton(null)

        if (side === 'IN') {
            if (outCurrency.type === 'FIAT') {
                if (value <= 0) return
            } else {
                if (amountData?.limits && (value < amountData?.limits?.min || value > amountData?.limits?.max)) {
                    dispatch(limitErrorAction(true))
                    return
                }
            }
        } else if (side === 'OUT') {
            if (outCurrency.type === 'FIAT') {
                if (amountData?.limits && (value < amountData?.limits?.min || value > amountData?.limits?.max)) {
                    dispatch(limitErrorAction(true))
                    return
                }
            } else if (value <= 0) return
        }

        dispatch(limitErrorAction(false))
    }

    const handleInput = useCallback(
        (event) => {
            const value = typeof event?.target?.value !== 'undefined' ? event?.target?.value : event || ''

            displayInputWidth(value, '40px Montserrat Medium')
            amountHandler(value)
        },
        [amountData, allExchangeWays]
    )

    const handleSwitch = useCallback(() => {
        sideHandler(amountData.amountSide === 'OUT' ? 'IN' : 'OUT')
    }, [amountData.amountSide])

    // const handleLimit = (value, side) => {
    //     handleInput(value)
    //     sideHandler(side)
    // }

    let text = useMemo(() => {
        if (exchangeWaysArray && exchangeWaysArray.length) return ''

        if (!inCurrency) {
            return strings('mobileMarket.notInCurrency')
        } else if (!outCurrency) {
            return strings('mobileMarket.notOutCurrency')
        } else if (
            (inCurrency.type === 'FIAT' || outCurrency.type === 'FIAT') &&
            inCurrency.type !== 'FIAT' &&
            outCurrency.type !== 'FIAT' &&
            !payway
        ) {
            return strings('mobileMarket.notPayway')
        } else if (
            (inCurrency && typeof inCurrency.notActive !== 'undefined' && inCurrency.notActive) ||
            (outCurrency && typeof outCurrency.notActive !== 'undefined' && outCurrency.notActive)
        ) {
            return strings('mobileMarket.noWay')
        } else if (!exchangeWaysArray || !exchangeWaysArray.length) {
            return strings('mobileMarket.noTempWay')
        } else {
            return ''
        }
    }, [inCurrency, outCurrency, payway, exchangeWaysArray])

    const handleMinLimit = useCallback(() => {
        handleBtn('MIN', inCurrencyUserData ? inCurrencyUserData?.prettyBalanceRaw || inCurrencyUserData?.balance : 0, setButton)
    }, [userCurrencies, allExchangeWays, exchangeWaysArray])
    const handleMaxLimit = useCallback(() => {
        handleBtn('MAX', inCurrencyUserData ? inCurrencyUserData?.prettyBalanceRaw || inCurrencyUserData?.balance : 0, setButton)
    }, [userCurrencies, allExchangeWays, exchangeWaysArray])

    return (
        <InputBlockDiv
            borderColor={theme.inputBlock.borderColor}
            bg={theme.inputBlock.bgColor}
            inputColor={theme.topNav.color}
            errorBg={theme.inputBlock.errorBg}
            errorColor={theme.inputBlock.errorColor}
            limitsBtn={theme.topNav.colorIcon}
            limitLine={theme.main.selector.bg}>
            {text && (
                <div className='message-container'>
                    <Message text={text} theme={theme} />
                </div>
            )}
            {inCurrency && outCurrency ? (
                <>
                    <div className='amount-wrapper'>
                        <div className='min-max-wrapper'>
                            <div className='limit-btn' style={{ letterSpacing: '2px' }} onClick={handleMinLimit}>
                                MIN
                            </div>
                            <div className='line' />
                            <div className='limit-btn' onClick={handleMaxLimit}>
                                MAX
                            </div>
                        </div>
                        <div className='wrapper-amount'>
                            {inCurrency && inCurrency.type === 'CRYPTO' && <div className='currency-balance'>{balanceData}</div>}
                            <div className='input-wrapper'>
                                {loading ? (
                                    <div className='loader'>
                                        <img src={Loader} height={45} alt='preloader' style={{ paddingLeft: 45 }} />
                                    </div>
                                ) : (
                                    <input
                                        id='amountInput'
                                        inputMode='decimal'
                                        placeholder='0.00'
                                        autoFocus={false}
                                        onChange={handleInput}
                                        maxLength={17}
                                        value={amountData.amount ? amountData.amount.toString().replace(',', '.') : ''}
                                        autoComplete='off'
                                        style={{
                                            color: amountData.amount
                                                ? UiColor[inCurrency.code]
                                                    ? UiColor[inCurrency.code].colors[mode === 'light' ? 'mainColor' : 'darkColor']
                                                    : theme.topNav.colorIcon
                                                : theme.topNav.colorIcon
                                        }}
                                        onFocus={(event) => {
                                            const element = event.target
                                            const caret = element.value.length
                                            window.requestAnimationFrame(() => {
                                                element.selectionStart = caret
                                                element.selectionEnd = caret
                                            })
                                            setFocus(true)
                                        }}
                                        onBlur={(e) =>
                                            setTimeout(() => {
                                                setFocus(false)
                                            }, 0)
                                        }
                                    />
                                )}
                                <div className='input-code' onClick={handleSwitch}>
                                    {amountData.amountSide === 'IN'
                                        ? inCurrency.symbol || getCurrencyCode(inCurrency.code)
                                        : outCurrency.symbol || getCurrencyCode(outCurrency.code)}
                                    <Icon icon='SWAP' size={20} color={theme.topNav.colorIcon} style={{ marginLeft: 5 }} />
                                </div>
                                {/* <div style={{ display: 'none' }}>
                                    {amountData.amount ? setTimeout(() => { displayInputWidth(amountData.amount, '40px Montserrat Bold') }, 0) : null}
                                </div> */}
                            </div>
                        </div>
                    </div>
                </>
            ) : null}

            {/* new Design comment */}

            {/* <div className='main-text'>
                {amountData.amountSide === 'OUT' ? strings('mobileMarket.youGet') : strings('mobileMarket.youGive')}:
            </div>
            <div className='input-container'>
                <div className='input-wrapper'>
                    <input
                        id='amountInput'
                        inputMode='decimal'
                        placeholder='0.00'
                        autoFocus={false}
                        maxLength={17}
                        autoComplete='off'
                        onChange={handleInput}
                        value={amountData.amount ? amountData.amount.toString().replace(',', '.') : ''}
                    />
                    {inCurrency && outCurrency &&
                        <div className='input-symbol'>{amountData.amountSide === 'IN' ? inCurrency.symbol : outCurrency.symbol}</div>
                    }
                </div>
                <div className='switch-wrapper'>
                    <SwitchCurrency
                        theme={theme}
                        inCurrency={inCurrency}
                        outCurrency={outCurrency}
                        id='currencySwitch'
                        isOn={amountData.amountSide === 'OUT'}
                        handleToggle={handleSwitch}
                    />
                </div>
            </div>
            <div style={{ display: 'none' }}>
                {amountData.amount ? setTimeout(() => { displayInputWidth(amountData.amount, '40px Montserrat Bold') }, 0) : null}
            </div>
            {inCurrency && inCurrency.type !== 'FIAT' &&
                <div className='main-text balance'>
                    {balanceData}
                </div>}
            <div className='amount-error-container'>
                {allExchangeWays && allExchangeWays.length ?
                    amountData.limitsError ?
                        <div className='amount-input-limits-text'>
                            {amountData.amount < amountData.limits.min ?
                                <>
                                    {strings('mobileMarket.minAmount') + ' '}
                                    <div className='amount-input-limits-value' onClick={() => handleLimit(amountData.limits.min, amountData.limits.currencySid)}>
                                        {`${amountData.limits.min} ${amountData.limits.currencySide === 'IN' ? inCurrency.symbol : outCurrency.symbol}`}
                                    </div>
                                </>
                                : amountData.amount > amountData.limits.max ?
                                    <>
                                        {strings('mobileMarket.maxAmount') + ' '}
                                        <div className='amount-input-limits-value' onClick={() => handleLimit(amountData.limits.max, amountData.limits.currencySid)}>
                                            {`${amountData.limits.max} ${amountData.limits.currencySide === 'IN' ? inCurrency.symbol : outCurrency.symbol}`}
                                        </div>
                                    </>
                                    : null}
                        </div>
                        :
                        amountData.providerError ?
                            <div className='amount-input-limits-text'>
                                {amountData.providerError.type === 'MIN' || amountData.providerError.type === 'MAX' ?
                                    <>
                                        {amountData.providerError.type === 'MIN' ? strings('exchangeForm.minAmount') + ' ' : strings('exchangeForm.maxAmount') + ' '}
                                        <div className='amount-input-limits-value' onClick={() => handleLimit(amountData.providerError.limitValue, amountData.providerError.currencySide)}>
                                            {`${amountData.providerError.limitValue} ${amountData.providerError.currencySide === 'IN' ? inCurrency.symbol : outCurrency.symbol}`}
                                        </div>
                                    </> :
                                    <div className='amount-input-limits-value'>
                                        not equivalnet
                                    </div>
                                }
                            </div>
                            :
                            null :
                    <div className='amount-input-limits-text'>
                        {text}
                    </div>
                }
            </div> */}

            {inCurrencyUserData && Number(inCurrencyUserData?.prettyBalanceRaw || inCurrencyUserData?.balance) > 0 && (
                <PercentBlock
                    theme={theme}
                    checked={button}
                    handleBtn={handleBtn}
                    balance={
                        allFundsData.recheckBalance ? allFundsData?.data?.amount : inCurrencyUserData?.prettyBalanceRaw || inCurrencyUserData?.balance
                    }
                    setButton={setButton}
                    setLoading={setLoading}
                />
            )}
        </InputBlockDiv>
    )
}

export default React.memo(InputBlock)
