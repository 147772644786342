import React, { useCallback, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import AnimateHeight from 'react-animate-height'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { v4 as uuidV4 } from 'uuid'
import { useSnackbar } from 'notistack'

import {
    getProviderData,
    getAllExchangeWays,
    getUserCurrencies,
    getOutDestination,
    getPaymentsDetails,
    getProviderLoader,
    getPreCheckData,
    getWalletData,
    getUserSettings,
    getExchangeWaysArray,
    getSign,
    getAmountData,
    getPreRequestExchangeWays
} from 'redux/AppStores/MobileMarket/selectors'
import {
    activeExchangeWayAction,
    amountAction,
    amountSideAction,
    coinAllbalanceAction,
    comeBackAction,
    emailAction,
    needSearchProvidersAction,
    preCheckAction,
    propsModalAction,
    providerAction,
    providerErrorAction,
    providerLoaderAction,
    recheckSendAllAction,
    sendAllAction,
    setSelectedFee,
    variantsAction,
    providerEquivalentTimeAction
} from 'redux/AppStores/MobileMarket/action'
import { getShowModal } from 'redux/AppStores/ModalStore/selectors'
import { hideSearchScreenModal, showCardScreenModal, showSearchScreenModal } from 'redux/AppStores/SearchModalScreenStore/action'
import { hideModal, showModal } from 'redux/AppStores/ModalStore/action'
import { getSearchShowModalScreen } from 'redux/AppStores/SearchModalScreenStore/selector'
import { hideScreenModal, showScreenModal } from 'redux/AppStores/ScreenModalStore/action'
import { getShowModalScreen } from 'redux/AppStores/ScreenModalStore/selectors'
import store from 'redux/index'

import ProviderData from 'components/providersSettings/provider'
import ProviderLabel from 'components/providersSettings/providerLabel'
import Wrapper from 'components/ScreenWrapper/Wrapper'
import TopNavigation from 'components/TopNavigation/TopNavigation'
import { Content } from 'components/wrapperScreen/wrapper.css'
import Label from 'components/providersSettings/labels'
import Button from 'components/Button/Button'
import Message from 'components/InfoMsg/Message'
import Icon from 'components/Icon/Icon'
import injectScript from 'components/buyScripts/injectScript'
import didMount from 'components/buyScripts/componentDidMount'
import navigationState from 'components/buyScripts/navigationState'
import message from 'components/buyScripts/message'
import mountDid from 'components/buyScripts/anotherWallets/didMount'
import msg from 'components/buyScripts/anotherWallets/msg'
import navState from 'components/buyScripts/anotherWallets/navState'
import didMountSell from 'components/sellScripts/didMount'
import navigationStateSell from 'components/sellScripts/navigationState'
import Loader from 'components/Loader/loader'
import getProviderName from 'components/providersSettings/providerName'
import { filterIsActive, filterItemVariants } from 'components/providerItem/helper'

import { strings } from 'i18n'
import { getThemeModeStyles } from 'theme/useDarkMode'

import CheckData from 'modules/mobileMarket/preCheck/CheckData/CheckData'
import Modal from 'modules/newModal/MainModal'
import SearchScreenModal from 'modules/searchModal/mainModal'
import ScreenModal from 'modules/ScreenModal/MainModal'
import useEventListener from 'modules/newFlow/hooks/useEventListener'

import config from 'config/config'

import utilsService from 'services/utilsService'
import getHashStr from 'services/getHashStr'
import currencyType from 'services/CurrencyType'
import Log from 'services/sentry/Log'
import validation from 'services/ValidationService'
import { getStatusKYC } from 'services/getStatusKYC'
import { getCurrencyCode, getCurrencyName, getTokenNet, isToken } from 'services/getCurrenciesProperty'
import limitScan from 'services/limitScan'
import limitForKsu from 'services/limitForKsu'
import hideAnimation from 'services/hideAnimation'
import getProviders from 'services/getProviders'
import { axiosRequest } from 'services/getActualWays'
import findOutDestination from 'services/findOutDestination'

import {
    createOrder,
    getBankFeeString,
    getNetworkFeeString,
    getTrusteeFeeAmount,
    getTrusteeFeeString,
    handleBuyNativeCoin,
    setAnalytics,
    showModalBalanceHodl,
    statusErrorKYC,
    validValue
} from './helper'
import PreCheckScreenDiv from './style.css'

const mainCoin = ['BTC', 'ETH', 'TRX', 'BNB']

const notActiveCoin = ['XRP', 'XLM']

const allFundsAssets = ['BNB', 'ETH']

const HEIGHT_DURATION = 250

let HANDLE_BALANCE = false

const PreCheckScreen = () => {
    let IS_CONFIRM = false
    let SHOW_TOKEN = false

    // mobileMarket selectors
    const allExchangeWays = useSelector(getAllExchangeWays)
    const exchangeWaysArray = useSelector(getExchangeWaysArray)
    const userCurrencies = useSelector(getUserCurrencies)
    const outDestinationData = useSelector(getOutDestination)
    const paymentData = useSelector(getPaymentsDetails)
    const skeleton = useSelector(getProviderLoader)
    const preCheckData = useSelector(getPreCheckData)
    const walletData = useSelector(getWalletData)
    const userSettings = useSelector(getUserSettings)
    const sign = useSelector(getSign)
    const amountData = useSelector(getAmountData)
    const providerInfo = useSelector(getProviderData)
    const preRequestExchangeWays = useSelector(getPreRequestExchangeWays)

    // screenModalStore selectors
    const modalSearchScreenShow = useSelector(getSearchShowModalScreen)

    // modalStore selectors
    const modalStateShow = useSelector(getShowModal)

    // screenModalStore selectors
    const modalScreenShow = useSelector(getShowModalScreen)

    // local state
    const [showDetails, setShowDetails] = useState(false)
    const [count, setCount] = useState(0)
    const [loader, setLoader] = useState(false)
    const [height, setHeight] = useState(34)
    const [requestId, setRequestId] = useState(uuidV4())

    const dispatch = useDispatch()

    const history = useHistory()

    const { enqueueSnackbar } = useSnackbar()

    const theme = getThemeModeStyles()

    const { providerData, activeExchangeWay } = providerInfo
    const { inCurrency, outCurrency, payway, cashbackToken, useAllFunds } = userCurrencies

    const exchangeWay = allExchangeWays.find((way) => way.exchangeWayId === providerData?.exchangeWayId) || null

    useEffect(() => {
        dispatch(preCheckAction(true))
        HANDLE_BALANCE = false

        return () => {
            dispatch(preCheckAction(false))
            IS_CONFIRM = false
        }
    }, [])

    const urlPath = (url) => {
        history.push({
            pathname: `/mobile-market/${url}`,
            state: { type: 'fade' }
        })
    }

    const pathUrl = (url) => {
        history.push('/mobile-market/' + url)
    }

    const preCheckEventHandler = async (event) => {
        if (typeof event.data === 'undefined' || !event.data || !Object.keys(event.data) || Object.keys(event.data).length === 0) return

        const diffCallback = async () => {
            if (!IS_CONFIRM) {
                dispatch(recheckSendAllAction(true))
                await handleConfirmOrder()
                IS_CONFIRM = true
            }
        }

        const handleEventProvider = async (amount, side) => {
            await handleProvider(null, amount, side)

            dispatch(sendAllAction(true))
            dispatch(amountAction(amount))
            dispatch(amountSideAction(side))
        }

        try {
            // cameraRes,
            const { fees } = JSON.parse(event.data)
            if (typeof fees !== 'undefined' && fees) {
                const tmpState = store.getState().mobileMarket

                const amount = utilsService.cutNumber(fees.amount, 8)
                const side = 'IN'
                Log.info(`V3.preCheckScreen.preCheckEventHandler fees amount ${JSON.stringify(amount)}`)

                const diff = utilsService.cutNumber(tmpState.provider.equivalentData.inAmount, 8) * 1 < amount * 1

                Log.info(`V3.preCheckScreen.preCheckEventHandler diff ${JSON.stringify(diff)}`)
                if (tmpState.comeBack && tmpState.preCheck && tmpState.useAllFunds) {
                    setLoader(false)
                    dispatch(setSelectedFee(fees?.transferData || null))

                    const tmpValue = (Number(amount) / Number(utilsService.cutNumber(tmpState.provider.equivalentData.inAmount, 8))) * 1
                    // Number(utilsService.cutNumber(tmpValue - 0.98, 2)) > 0.02 || Number(utilsService.cutNumber(tmpValue - 0.98, 2)) < -0.02
                    if (tmpValue * 1 !== 1) {
                        dispatch(recheckSendAllAction(false))
                        dispatch(comeBackAction(false))
                        dispatch(providerLoaderAction(false))

                        showModal(
                            {
                                type: 'YES_NO_MODAL',
                                icon: 'WARNING',
                                header: strings('mobileMarket.modal.attention'),
                                description: strings('mobileMarket.modal.recheckSendAll'),
                                noCallback: async () => {
                                    hideAnimation('YesNoModal', hideModal)
                                    await handleConfirmOrder()
                                }
                            },
                            async () => {
                                hideAnimation('YesNoModal', hideModal)
                                await handleEventProvider(amount, side)
                            }
                        )
                        return
                    } else {
                        await diffCallback()
                        return
                    }
                } else if (tmpState.preCheck && tmpState.needSearchProviders) {
                    await handleEventProvider(amount, side)
                } else if (tmpState.preCheck) {
                    setLoader(false)
                    if (diff) {
                        await diffCallback()
                        return
                    } else {
                        showModal(
                            {
                                type: 'YES_NO_MODAL',
                                icon: 'WARNING',
                                header: strings('mobileMarket.modal.attention'),
                                description: strings('mobileMarket.modal.recheckSendAll'),
                                noCallback: () => {
                                    setLoader(false)
                                    hideAnimation('YesNoModal', hideModal)
                                }
                            },
                            async () => {
                                hideAnimation('YesNoModal', hideModal)
                                await handleEventProvider(amount, side)
                            }
                        )
                        return
                    }
                }
            }
        } catch (err) {
            console.log('V3.preCheckScreen.preCheckEventHandler error', err)
            Log.warning(`V3.preCheckScreen.preCheckEventHandler error ${JSON.stringify(err)}`)
            Log.captureError(`V3.preCheckScreen.preCheckEventHandler error`)
        }
    }

    // Add event listener using our hook
    useEventListener('message', preCheckEventHandler)

    const showAttentionModalZeroBalance = async (walletData, outCurrency, cashbackToken, setLoader, handleConfirm) => {
        const tokenBlockchain = getTokenNet(walletData, outCurrency.code)

        const nativeCoin = walletData.wallets
            .find((wallet) => wallet.cashbackToken === cashbackToken)
            .accounts.find((account) => account.currencyCode === tokenBlockchain)

        if (Number(nativeCoin?.prettyBalanceRaw || nativeCoin?.balance) <= 0) {
            setLoader(false)
            showModal(
                {
                    type: 'INFO_MODAL',
                    icon: 'WARNING',
                    header: strings('mobileMarket.modal.attention'),
                    description: strings('mobileMarket.modal.notCoinBalance', {
                        coin: getCurrencyCode(tokenBlockchain),
                        token: outCurrency.symbol
                    }),
                    callback: () => {
                        hideModal()
                        SHOW_TOKEN = true
                        setTimeout(async () => {
                            await handleConfirm()
                        }, 0)
                    }
                },
                async () => {
                    hideModal()
                    SHOW_TOKEN = true
                    setTimeout(async () => {
                        await handleConfirm()
                    }, 0)
                }
            )
            return
        } else {
            SHOW_TOKEN = true
            setTimeout(async () => {
                await handleConfirm()
            }, 0)
        }
    }

    const outDestinationValid = (outCurrency, outDestination) => {
        if (outDestination) {
            if (!validation(currencyType(outCurrency), outDestination)) {
                return true
            }
        } else {
            return true
        }
    }

    const handleConfirmOrder = useCallback(async () => {
        if (activeExchangeWay !== 'EXCHANGE' && !skipPaymentDetailsScreen && paymentData) {
            localStorage.setItem('trusteeHash', getHashStr(paymentData.number))
        }

        if (activeExchangeWay !== 'EXCHANGE' && !skipPaymentDetailsScreen && !paymentData) {
            showModal(
                {
                    type: 'YES_NO_MODAL',
                    icon: 'WARNING',
                    header: strings('mobileMarket.modal.attention'),
                    description: strings('mobileMarket.modal.notCard'),
                    firstBtn: strings('mobileMarket.modal.addDetails')
                    // secondBtn: strings('mobileMarket.modal.late')
                },
                () => {
                    hideModal()
                    window.history.back()
                    setTimeout(() => {
                        pathUrl(`payment-details/management?action=ADD&type=${payway}`)
                    }, 250)
                    return
                }
            )
            return
        }

        const walletOut = walletData.wallets
            .find((way) => way.cashbackToken === cashbackToken)
            .accounts.find((account) => account.currencyCode === outCurrency.code)
        const walletIn = walletData.wallets
            .find((way) => way.cashbackToken === cashbackToken)
            .accounts.find((account) => account.currencyCode === inCurrency.code)

        if (
            userSettings.trusteeWallet &&
            ((inCurrency.type === 'CRYPTO' && typeof inCurrency.maskHidden !== 'undefined' && inCurrency.maskHidden) ||
                (outCurrency.type === 'CRYPTO' &&
                    typeof outCurrency.maskHidden !== 'undefined' &&
                    outCurrency.maskHidden &&
                    !outDestinationData.outDestination))
        ) {
            showModal(
                {
                    type: 'INFO_MODAL',
                    icon: 'WARNING',
                    header: strings('mobileMarket.modal.warning'),
                    description: strings('mobileMarket.modal.coinDisabled')
                }
                //     // , () => {
                //     //     if (state.inCurrency.maskHidden && state.outCurrency.maskHidden) {
                //     //         window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ needActivateCurrency: [state.inCurrency.code, state.outCurrency.code] }))
                //     //     } else if (state.inCurrency.maskHidden) {
                //     //         window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ needActivateCurrency: [state.inCurrency.code] }))
                //     //     } else if (state.outCurrency.maskHidden) {
                //     //         window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ needActivateCurrency: [state.outCurrency.code] }))
                //     //     }
                //     //     localStorage.setItem('amountData', JSON.stringify({ amount: state.amount, side: state.amountSide }))
                //     // }
            )
            return
        }

        if (activeExchangeWay !== 'SELL' && notActiveCoin.includes(outCurrency.code) && !preCheckData.balanceHodlMsg) {
            const balance = walletOut?.prettyBalanceRaw || walletOut?.balance || 0
            const balanceHodl = walletOut?.balanceHodl || 0
            const newBalanceHodl = Math.ceil(balanceHodl - (balanceHodl + balance))

            if (Number(newBalanceHodl) && Number(balance) <= Number(newBalanceHodl)) {
                showModalBalanceHodl(newBalanceHodl, outCurrency, providerData.equivalentData.outAmount, handleConfirmOrder)
                return
            }
        }

        setLoader(true)

        const tmpExchangeWay = allExchangeWays.find((way) => way.exchangeWayId === providerData?.exchangeWayId) || null

        // check version app && available provider
        if (tmpExchangeWay?.minWalletVersion) {
            if (userSettings.versionNumber * 1 < tmpExchangeWay?.minWalletVersion * 1) {
                showModal({
                    type: 'INFO_MODAL',
                    icon: 'WARNING',
                    header: strings('mobileMarket.modal.attention'),
                    description: strings('mobileMarket.modal.disableProvider')
                })
                return
            }
        }

        // check KYC data
        if (isNeedKYC) {
            const email = localStorage.getItem('trusteeEmail') ? localStorage.getItem('trusteeEmail') : null
            if (email) {
                try {
                    const data = await getStatusKYC(email)

                    Log.info(`V3.providerItem.nextStep getStatusKYC data ${JSON.stringify(data)}`)
                    Log.captureInfo(`V3.providerItem.nextStep getStatusKYC`)

                    setLoader(false)

                    const errorStatus = data && data?.status !== 'DONE'

                    Log.info(`V3.providerItem.nextStep getStatusKYC errorStatus ${JSON.stringify(errorStatus)}`)

                    // if (errorStatus) {
                    // statusErrorKYC(preCheckData.afterKYC, errorStatus)
                    // urlPath('verification')
                    // return
                    // }

                    Log.info(`V3.providerItem.nextStep getStatusKYC result ${JSON.stringify(data)}`)

                    if (data && data?.userId && data?.status !== 'NOT_REGISTERED') {
                        dispatch(emailAction(data.userId))
                    }

                    if (data && data?.status) {
                        if (data.status === 'DONE') {
                            hideAnimation('ModalKYC', hideSearchScreenModal)
                            dispatch(propsModalAction(true))
                            if (preCheckData.needSearchProviders && preCheckData.afterKYC) {
                                await handleProvider()
                                return
                            }
                        } else {
                            urlPath('verification')
                            return
                        }
                    }
                } catch (error) {
                    Log.error(`V3.preCheck confirmHandler KYC error ${JSON.stringify({ error })}`)
                    Log.captureError(`V3.preCheck confirmHandler KYC error`)

                    setLoader(false)

                    statusErrorKYC(preCheckData.afterKYC)
                    return
                }
            } else {
                setLoader(false)
                showSearchScreenModal({
                    type: 'KYC_MODAL'
                })
                return
            }
        }

        if (activeExchangeWay === 'BUY') {
            // check cardPhoto
            let verify, verifyStatus

            if (tmpExchangeWay?.isNeedCardVerification) {
                if (payway === 'CARD') {
                    Log.error(`TEST YURA ${JSON.stringify(paymentData)}`)
                    verify = paymentData.cardVerificationJson || null
                    if (typeof verify !== 'undefined' && verify && verify !== 'false' && verify !== 'undefined' && verify !== 'null') {
                        if (JSON.parse(verify).data) {
                            verifyStatus =
                                typeof JSON.parse(verify).data.verificationStatus !== 'undefined'
                                    ? JSON.parse(verify).data.verificationStatus
                                    : 'CANCELED'
                        } else {
                            verifyStatus =
                                typeof JSON.parse(verify).verificationStatus !== 'undefined' ? JSON.parse(verify).verificationStatus : 'CANCELED'
                        }
                        if (
                            (typeof verifyStatus === 'undefined' || verifyStatus === 'undefined' || verifyStatus === undefined) &&
                            JSON.parse(verify).errorMsg
                        ) {
                            verifyStatus = 'CANCELED'
                        }
                    } else {
                        if (verify === null || verify === 'null' || verify === 'undefined' || verify === undefined || !verify || verify === 'false') {
                            verifyStatus = 'CANCELED'
                        } else {
                            verifyStatus = verify
                        }
                    }
                }
                Log.info(
                    `V3.providerItem.nextStep item ${JSON.parse(JSON.stringify(providerData))} -> verifyStatus for paymentDetails ${JSON.stringify(
                        verifyStatus
                    )}`
                )

                if (verifyStatus === 'CANCELED' || verifyStatus === 'WAIT_FOR_PHOTO') {
                    // dispatch(propsModalAction(true))
                    showScreenModal(
                        {
                            type: 'CARD_MODAL',
                            data: paymentData
                        },
                        async () => {
                            // const state = store.getState().mobileMarket
                            // window.ReactNativeWebView.postMessage(JSON.stringify({
                            //     takePhoto: {
                            //         number: state.paymentData.number,
                            //         countryCode: state.paymentData.countryCode
                            //     }
                            // }))
                            pathUrl(`payment-details?showStatus=true&loader=true&camera=${userSettings.cameraResult}`)
                            hideScreenModal()
                        }
                    )
                    setLoader(false)
                    return
                } else if (verifyStatus === 'PENDING') {
                    setLoader(false)
                    dispatch(propsModalAction(true))
                    showModal(
                        {
                            type: 'YES_NO_MODAL',
                            icon: 'WARNING',
                            header: strings('mobileMarket.modal.destinationHeader'),
                            description: strings('mobileMarket.modal.cardStatus'),
                            noCallback: () => {
                                hideAnimation('YesNoModal', hideModal)
                            }
                        },
                        () => {
                            pathUrl(`payment-details?showStatus=true&id=${providerData?.exchangeWayId}`)
                        }
                    )

                    return
                }
            }

            setLoader(true)

            const currencyCode = walletOut && walletOut?.custom ? walletOut.originCode : outCurrency.code
            // const youHave = newState.walletData.wallets.find(wallet => wallet.cashbackToken === newState.cashbackToken).accounts
            //     .find(account => account.currencyCode === newState.outCurrency.code)

            // if (typeof youHave.balanceHodl !== 'undefined' && youHave.balance < youHave.balanceHodl && newState.outCurrency && (Number(newState.provider.equivalentData.outAmount) < youHave.balanceHodl)) {
            //     setLoader(false)
            //     showModal({
            //         type: 'INFO_MODAL',
            //         icon: 'WARNING',
            //         header: strings('mobileMarket.modal.hodlHeader', { coin: `${getCurrencyName(state.outCurrency.code)} (${getCurrencyCode(state.outCurrency.code)})` }),
            //         description: strings('mobileMarket.modal.balanceHodl', { coin: getCurrencyCode(state.outCurrency.code), balance: Math.round(youHave.balanceHodl - Number(youHave.balance)) })
            //     })
            //     return
            // }

            // if (isNeedCameraPermission) {
            //     if (!newState.cameraResult) {
            //         window.ReactNativeWebView.postMessage(JSON.stringify({ checkCamera: true }))
            //         setLoader(false)
            //         return
            //     }
            // }

            if (activeExchangeWay === 'BUY' && !SHOW_TOKEN) {
                if (isToken(walletData, outCurrency.code)) {
                    showAttentionModalZeroBalance(walletData, outCurrency, cashbackToken, setLoader, handleConfirmOrder)
                    return
                }
            }

            const script = paymentData ? injectScript(paymentData) : ' '
            const componentDidMount = !preCheckData.useCookie ? didMount(userSettings.versionNumber) : mountDid()
            const navigation = !preCheckData.useCookie ? navigationState(userSettings.versionNumber) : navState()
            const onMessage = !preCheckData.useCookie ? message(userSettings.versionNumber) : msg()

            if (count === 0) {
                const orderData = await createOrder(activeExchangeWay, count, setCount, loader, setLoader, skipPaymentDetailsScreen, null, isNeedKYC)
                if (orderData) {
                    Log.setTag('orderHash', orderData.orderHash)
                    Log.setTag('provider', providerData.providerName)

                    setAnalytics(exchangeWaysArray, allExchangeWays, providerData)

                    const dataBuy = {
                        orderData: orderData,
                        injectScript: script,
                        currencySelect: currencyCode,
                        didMount: componentDidMount,
                        navigationState: navigation,
                        message: onMessage,
                        limits: JSON.stringify({ limits: null, currencyCode: currencyCode }),
                        trusteeFee: JSON.stringify({
                            trusteeFee: utilsService.cutNumber(providerData.equivalentData.trusteeFee.in, 6),
                            currencyCode: inCurrency.symbol
                        }),
                        payway: payway
                    }

                    window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ ...dataBuy }))

                    setTimeout(async () => {
                        setLoader(false)
                        window.history.back()
                    }, 1e3)

                    const partLink = orderData.url.toString().slice(8, 19)

                    Log.info(
                        `V3.preCheckScreen.confirmHandler partLink ${partLink} -> script\n\n\n ${script
                            .toString()
                            .slice(script.toString().indexOf(partLink), script.length)}`
                    )
                    Log.captureInfo(`V3.preCheckScreen.confirmHandler ${activeExchangeWay} createOrder SUCCESS`)
                }
                setCount(0)
            }
        } else {
            setLoader(true)

            let account = inCurrency && inCurrency.type === 'CRYPTO' ? walletIn : null

            if (tmpExchangeWay?.isNeedApprove) {
                if (isToken(walletData, inCurrency.code)) {
                    setLoader(true)
                    const providerLink = `/${providerData.providerName.toLowerCase()}/check-approve?amount=${
                        providerData.equivalentData.inAmount
                    }&currencyCode=${providerData.inCurrency.code}&tokenOwner=${account.address}&cashbackToken=${cashbackToken}&signMessage=${
                        sign.message
                    }&signMessageHash=${sign.messageHash}&signature=${sign.signature}`
                    Log.info(`V3.providerItem.nextStep checkApprove link ${JSON.stringify(providerLink)}`)

                    let resIsNeedApprove
                    try {
                        resIsNeedApprove = await axiosRequest(providerLink, strings('mobileMarket.modal.serverRespond'))
                        console.log(resIsNeedApprove)
                        Log.info(`V3.providerItem.nextStep checkApprove result ${JSON.stringify(resIsNeedApprove)}`)
                    } catch (e) {
                        setLoader(false)
                    }

                    if (resIsNeedApprove) {
                        setTimeout(() => setLoader(false), 3e2)
                        if (resIsNeedApprove.isNeedApprove) {
                            showModal(
                                {
                                    type: 'YES_NO_MODAL',
                                    icon: 'WARNING',
                                    header: strings('mobileMarket.modal.attention'),
                                    description: strings('mobileMarket.modal.isNeedApprove', {
                                        providerName: getProviderName(providerData.providerName),
                                        currencyCode: providerData.inCurrency.symbol
                                    })
                                },
                                () => {
                                    hideModal()
                                    setLoader(true)
                                    const checkApproveData = {
                                        data: resIsNeedApprove.dexOrderData,
                                        currencyCode: providerData.inCurrency.code,
                                        provider: providerData.providerName
                                    }
                                    window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ checkApproveData }))
                                    setLoader(false)
                                    return
                                }
                            )
                            return
                        }
                    }
                }
            }

            if (activeExchangeWay === 'SELL' || activeExchangeWay === 'EXCHANGE') {
                if (isToken(walletData, inCurrency.code)) {
                    const tokenBlockchain = getTokenNet(walletData, inCurrency.code)

                    const nativeCoin = walletData.wallets
                        .find((wallet) => wallet.cashbackToken === cashbackToken)
                        .accounts.find((account) => account.currencyCode === tokenBlockchain)

                    if (nativeCoin && Number(nativeCoin?.prettyBalanceRaw || nativeCoin?.balance) <= 0) {
                        setLoader(false)
                        showModal(
                            {
                                type: 'YES_NO_MODAL',
                                icon: 'WARNING',
                                header: strings('mobileMarket.modal.attention'),
                                description: strings('mobileMarket.modal.notNativeCoinBalance', {
                                    coin: getCurrencyName(nativeCoin.currencyCode) + ' (' + getCurrencyCode(nativeCoin.currencyCode) + ')'
                                }),
                                firstBtn: strings('mobileMarket.modal.needBuy') + ' ' + getCurrencyCode(nativeCoin.currencyCode)
                            },
                            async () => {
                                await handleBuyNativeCoin({ nativeCoin, walletData, cashbackToken, enqueueSnackbar, preRequestExchangeWays })
                            }
                        )
                        return
                    }
                }
            }

            // if (activeExchangeWay !== 'SELL') {
            //     if (outDestinationValid(outCurrency.code, outDestinationData.outDestination)) {
            //         setLoader(false)
            //         showModal({
            //             type: 'INFO_MODAL',
            //             icon: 'WARNING',
            //             header: strings('mobileMarket.modal.attention'),
            //             description: strings('mobileMarket.destinationError')
            //         })
            //         return
            //     }
            // }

            if (activeExchangeWay !== 'SELL' && !SHOW_TOKEN) {
                if (isToken(walletData, outCurrency.code)) {
                    showAttentionModalZeroBalance(walletData, outCurrency, cashbackToken, setLoader, handleConfirmOrder)
                    return
                }
            }

            const youHaveOriginCode =
                typeof walletIn !== 'undefined' && typeof walletIn.custom !== 'undefined' && walletIn.custom ? walletIn.originCode : inCurrency.code
            const haveCoinOriginCode =
                typeof walletOut !== 'undefined' && typeof walletOut.custom !== 'undefined' && walletOut.custom
                    ? walletOut.originCode
                    : outCurrency.code

            const balance = walletIn ? (Number(walletIn?.balance) + Number(walletIn?.unconfirmed ? walletIn?.unconfirmed : 0)).toString() : 0
            Log.info(`V3.preCheck.confirmHandler balance unconfirmed ${walletIn?.unconfirmed ? walletIn?.unconfirmed : 0}`)
            Log.info(`V3.preCheck.confirmHandler balance ${balance}`)

            if (config.balanceCheck && Number(providerData?.equivalentData?.inAmount) > Number(balance)) {
                setLoader(false)
                showModal({
                    type: 'INFO_MODAL',
                    icon: 'WARNING',
                    header: strings('mobileMarket.modal.sorry'),
                    description: strings('mobileMarket.noMoney', {
                        amount: providerData.equivalentData.inAmount + ' ' + inCurrency.symbol,
                        balance: balance + ' ' + inCurrency.symbol
                    })
                })
                return
            }

            if (
                (activeExchangeWay === 'SELL' || activeExchangeWay === 'EXCHANGE') &&
                !HANDLE_BALANCE &&
                !preCheckData.recheckSendAll &&
                allFundsAssets.includes(inCurrency.code)
            ) {
                HANDLE_BALANCE = true
                const obj = findOutDestination(walletData, inCurrency.code, cashbackToken)
                window.ReactNativeWebView &&
                    window.ReactNativeWebView.postMessage(
                        JSON.stringify({
                            useAllFunds: {
                                currencyCode: inCurrency.code,
                                address: obj.outDestination
                            }
                        })
                    )
                dispatch(comeBackAction(true))
                return
            }

            // if (newState.useAllFunds && state.inCurrency.code === 'ETH') {
            //     const amountFromProvider = toWei(state.provider.equivalentData.inAmount)
            //     Log.info(`V3.preCheckScreen.amountFromProvider ${amountFromProvider}`)
            //     Log.info(`V3.preCheckScreen.newState.selectedFee ${JSON.stringify(newState.selectedFee)}`)
            //     let diff = amountFromProvider - newState.selectedFee.selectedFee.amountForTx
            //     let feeForTx = newState.selectedFee.selectedFee.feeForTx * 1 + diff
            //     let gasPrice = feeForTx / newState.selectedFee.selectedFee.gasLimit
            //     let gasPriceGwei = toGwei(gasPrice)
            //     Log.info(`V3.preCheckScreen.feeForTx ${feeForTx}`)
            //     Log.info(`V3.preCheckScreen.diff ${diff}`)
            //     Log.info(`V3.preCheckScreen.gasPrice ${gasPrice}`)
            //     Log.info(`V3.preCheckScreen.gasPriceGwei ${gasPriceGwei}`)

            //     if (feeForTx * 1 > newState.selectedFee.selectedFee.feeForTx * 1) {
            //         setLoader(false)
            //         showModal({
            //             type: 'INFO_MODAL',
            //             icon: 'WARNING',
            //             header: strings('mobileMarket.modal.attention'),
            //             description: strings('mobileMarket.modal.correctedAmount', { symbol: newState.inCurrency.symbol }),
            //         })
            //         Log.captureError(`V3.preCheckScreen.amount not valid`)
            //         return
            //     }
            // }

            if (
                (activeExchangeWay === 'SELL' || activeExchangeWay === 'EXCHANGE') &&
                useAllFunds &&
                !preCheckData.coinAllbalance &&
                mainCoin.includes(inCurrency.code)
            ) {
                setLoader(false)
                showModal(
                    {
                        type: 'INFO_MODAL',
                        icon: 'WARNING',
                        header: strings('mobileMarket.modal.attention'),
                        description: strings('mobileMarket.modal.coinAllBalance', { coin: inCurrency.code })
                    },
                    async () => {
                        dispatch(coinAllbalanceAction(true))
                        await handleConfirmOrder()
                        return
                    }
                )
                return
            }

            setLoader(true)

            const limits = await limitScan(allExchangeWays)
            const minLimit = limitForKsu(activeExchangeWay, limits || [], providerData)

            Log.info(`V3.preCheckScreen preCheckData ${JSON.stringify(preCheckData)}`)

            const dataSend = {}
            dataSend.useAllFunds = useAllFunds
            dataSend.comment = preCheckData?.comment
            dataSend.providerType = providerData?.rateType
            dataSend.originData = JSON.stringify(preCheckData?.originalData)
            dataSend.limits = JSON.stringify({ limits: minLimit, currencyCode: youHaveOriginCode })
            dataSend.trusteeFee = JSON.stringify({
                trusteeFee: utilsService.cutNumber(providerData.equivalentData.trusteeFee.out, 6),
                currencyCode: getCurrencyCode(haveCoinOriginCode)
            })
            dataSend.outCurrency = haveCoinOriginCode
            dataSend.outDestination = activeExchangeWay === 'SELL' ? paymentData?.number || null : outDestinationData.outDestination
            dataSend.exchangeWayType = activeExchangeWay
            dataSend.payway = payway || null
            if (
                useAllFunds &&
                (allFundsAssets.includes(inCurrency.code) ||
                    (isToken(walletData, inCurrency.code) &&
                        getTokenNet(walletData, inCurrency.code) &&
                        getTokenNet(walletData, inCurrency.code) === 'ETH'))
            ) {
                dataSend.feeData = JSON.stringify(preCheckData.selectedFee) || null
            }

            if (preCheckData.order) {
                Log.setTag('orderHash', preCheckData.order.orderHash)
                Log.setTag('provider', providerData.providerName)

                setAnalytics(exchangeWaysArray, allExchangeWays, providerData)

                Log.captureInfo(`V3.preCheckScreen.confirmHandler ${activeExchangeWay} createOrder SUCCESS`)
                dataSend.memo = preCheckData.order.destinationTag || false
                dataSend.orderHash = preCheckData.order.orderHash
                dataSend.amount = preCheckData.order.amount
                dataSend.currencyCode =
                    preCheckData.order.inCurrencyCode === youHaveOriginCode ? preCheckData.order.inCurrencyCode : youHaveOriginCode
                dataSend.address = preCheckData.order.address
                dataSend.outAmount = providerData.equivalentData.outAmount

                Log.info(`V3.preCheckScreen.confirmHandler orderData\n\n\n ${JSON.stringify(preCheckData.order)}`)
                Log.info(`V3.preCheckScreen.confirmHandler dataSend ${JSON.stringify({ dataSend })}`)
                window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ dataSend }))
                setTimeout(async () => setLoader(false), 3e3)

                if (Number(userSettings.versionNumber) > 836 || userSettings.versionNumber === 'VERSION') {
                    setTimeout(async () => {
                        window.history.back()
                        dispatch(recheckSendAllAction(false))
                        dispatch(comeBackAction(false))
                    }, 1e3)
                }
            } else {
                // if (activeExchangeWay === 'EXCHANGE') {
                //     if (typeof haveCoin.balanceHodl !== 'undefined' && haveCoin.balance === 0 && newState.outCurrency && (Number(newState.provider.equivalentData.outAmount) < haveCoin.balanceHodl)) {
                //         setLoader(false)
                //         showModal({
                //             type: 'INFO_MODAL',
                //             icon: 'WARNING',
                //             header: strings('mobileMarket.modal.hodlHeader', { coin: `${getCurrencyName(state.outCurrency.code)} (${getCurrencyCode(state.outCurrency.code)})` }),
                //             description: strings('mobileMarket.modal.balanceHodl', { coin: getCurrencyCode(state.outCurrency.code), balance: haveCoin.balanceHodl, name: getCurrencyName(state.outCurrency.code) })
                //         })
                //         return
                //     }
                // }

                if (count === 0) {
                    const orderData = await createOrder(
                        activeExchangeWay,
                        count,
                        setCount,
                        loader,
                        setLoader,
                        skipPaymentDetailsScreen,
                        feeTrx,
                        isNeedKYC
                    )
                    if (orderData) {
                        Log.setTag('orderHash', orderData.orderHash)
                        Log.setTag('provider', providerData.providerName)

                        setAnalytics(exchangeWaysArray, allExchangeWays, providerData)

                        Log.captureInfo(`V3.preCheckScreen.confirmHandler ${activeExchangeWay} createOrder SUCCESS`)

                        dataSend.memo = orderData.destinationTag || false
                        dataSend.orderHash = orderData.orderHash
                        dataSend.amount = orderData.amount
                        dataSend.currencyCode = youHaveOriginCode
                        dataSend.address = orderData.address
                        dataSend.outAmount = providerData.equivalentData.outAmount
                        dataSend.apiRaw = typeof orderData.dexOrderData !== 'undefined' ? orderData : null

                        // if (!useAllFunds && inCurrency.code === 'BNB') {
                        //     dataSend.feeData =
                        //         JSON.stringify({
                        //             ...preCheckData.selectedFee,
                        //             transferAllBalance: orderData.amount,
                        //             selectedFee: { ...preCheckData.selectedFee.selectedFee, amountForTx: orderData.amount }
                        //         }) || null
                        // }

                        if (orderData?.url) {
                            dataSend.url = orderData?.url || null
                            dataSend.didMountSell = didMountSell()
                            dataSend.navigationSell = navigationStateSell()
                        }

                        if (!orderData.address && !dataSend.url && !orderData.dexOrderData) {
                            showModal(
                                {
                                    type: 'INFO_MODAL',
                                    icon: 'WARNING',
                                    header: strings('mobileMarket.modal.attention'),
                                    description: config.showError ? 'ERROR_GET_ADDRESS' : strings('mobileMarket.modal.createOrderError')
                                },
                                () => {
                                    hideModal()
                                }
                            )
                            setLoader(false)
                            return
                        }

                        Log.info(`V3.preCheckScreen.confirmHandler orderData\n\n\n ${JSON.stringify(orderData)}`)
                        Log.info(`V3.preCheckScreen.confirmHandler dataSend ${JSON.stringify({ dataSend })}`)
                        window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ dataSend }))
                        setTimeout(async () => {
                            setLoader(false)
                        }, 3e3)

                        if (Number(userSettings.versionNumber) > 836 || userSettings.versionNumber === 'VERSION') {
                            setTimeout(async () => {
                                window.history.back()
                                dispatch(recheckSendAllAction(false))
                                dispatch(comeBackAction(false))
                            }, 1e3)
                        }
                    }
                    setCount(0)
                }
            }
        }
    }, [providerData, count, userCurrencies, allExchangeWays, preCheckData, paymentData])

    const handleMoreDetails = useCallback(() => {
        if (showDetails) {
            setTimeout(() => setShowDetails(!showDetails), HEIGHT_DURATION)
            setHeight(34)
        } else {
            setShowDetails(!showDetails)
            setHeight('auto')
        }
    }, [showDetails, height])

    const skipPaymentDetailsScreen = exchangeWay
        ? typeof exchangeWay?.skipPaymentDetailsScreen !== 'undefined'
            ? exchangeWay?.skipPaymentDetailsScreen
            : false
        : false

    const labels = exchangeWay ? exchangeWay?.exchangeWayLabelCodes : null

    const feeTrx = userCurrencies?.useAllFunds ? exchangeWay?.feeLimit || null : null

    const feeData = exchangeWay?.fees || null
    const isFeesIncludedRate = feeData ? feeData?.isFeesIncludedInRate : null

    const isNeedKYC = exchangeWay ? (typeof exchangeWay?.isNeedKYC !== 'undefined' && exchangeWay?.isNeedKYC ? exchangeWay?.isNeedKYC : false) : false

    const inAmount =
        (feeTrx ? providerData?.equivalentData?.inAmount - feeTrx : providerData?.equivalentData?.inAmount) + ' ' + providerData?.inCurrency?.symbol
    const outAmount =
        (providerData?.rateType === 'FIXED' ? '=' : '≈') + ' ' + providerData?.equivalentData?.outAmount + ' ' + providerData?.outCurrency?.symbol

    const bankFee = getBankFeeString(feeData, providerData)
    const networkFee = getNetworkFeeString(feeData, providerData)
    const trusteeString = getTrusteeFeeString(feeData, activeExchangeWay)
    const trusteeFee = getTrusteeFeeAmount(providerData, activeExchangeWay)

    const handleProvider = async (e, amount = null, sideAmount = null) => {
        e && e?.preventDefault()

        if (e && e?.detail > 1) return

        dispatch(providerLoaderAction(true))
        // urlPath('pre-check', 'page')
        hideSearchScreenModal()
        dispatch(activeExchangeWayAction(null))

        const state = {
            inCurrency,
            outCurrency,
            payway,
            cashbackToken,
            sign,
            paymentData,
            outDestination: outDestinationData.outDestination
        }

        const arrayProviders = await getProviders(
            state,
            requestId,
            amount || amountData.amount,
            sideAmount || amountData.amountSide,
            userSettings.tester
        )

        let finalArray = arrayProviders !== null ? filterIsActive(arrayProviders.variants, allExchangeWays) : []
        finalArray = finalArray.map((item) => filterItemVariants(item))

        Log.info(`V3.firstScreen.findButtonHandler getProviders result ${JSON.stringify(finalArray)}`)

        dispatch(variantsAction(finalArray))
        dispatch(needSearchProvidersAction(false))

        localStorage.removeItem('amountData')

        if (finalArray && finalArray.length && finalArray[0]?.equivalentData && finalArray[0]?.equivalentData?.status === 'SUCCESS') {
            dispatch(providerAction(finalArray[0], allExchangeWays[0].exchangeWayType))
            // urlPath('pre-check', 'page')
            dispatch(providerLoaderAction(false))
            dispatch(providerEquivalentTimeAction(new Date().getTime()))
            return
        }

        dispatch(providerErrorAction(finalArray[0]?.equivalentData))
        dispatch(providerLoaderAction(false))
        dispatch(providerEquivalentTimeAction(null))
        window.history.back()
    }

    const handleListProviders = () => {
        showSearchScreenModal({
            type: 'PROVIDER_LIST_MODAL',
            requestId
        })
    }

    let durationTime = ''

    const paywayForTime = ['CARD', 'BANK_TRANSFER']

    if (!skeleton) {
        if (activeExchangeWay === 'SELL' && paywayForTime.includes(payway)) {
            durationTime = strings(
                'mobileMarket.durationTime.SELL.' + providerData?.providerName + '.' + providerData?.outCurrency?.symbol,
                strings('mobileMarket.durationTime.SELL.' + providerData?.outCurrency?.symbol, strings('mobileMarket.durationTime.SELL.general'))
            )
        } else if (activeExchangeWay === 'BUY' && paywayForTime.includes(payway)) {
            durationTime = strings(
                'mobileMarket.durationTime.BUY.' + providerData?.providerName + '.' + providerData?.inCurrency?.symbol,
                strings('mobileMarket.durationTime.BUY.' + providerData?.inCurrency?.symbol, strings('mobileMarket.durationTime.BUY.general'))
            )
        } else if (activeExchangeWay === 'EXCHANGE') {
            durationTime = strings(
                'mobileMarket.durationTime.EXCHANGE.' + providerData?.providerName + '.' + providerData?.outCurrency?.symbol,
                strings(
                    'mobileMarket.durationTime.EXCHANGE.' + providerData?.outCurrency?.symbol,
                    strings('mobileMarket.durationTime.EXCHANGE.general')
                )
            )
        }
    }

    return (
        <Content background={theme.main.bg} style={{ zIndex: 1500, overflow: 'none' }}>
            <TopNavigation
                text='INFO'
                leftType='back'
                // leftAction={leftHandler}
            />
            <Wrapper contentStyle={{ overflow: 'scroll' }} scrollStyle={{ height: '100%' }}>
                {loader && <Loader />}
                <SkeletonTheme baseColor={theme.provider.failBg} highlightColor={theme.main.skeletonLoader}>
                    <PreCheckScreenDiv
                        bgColor={theme.preCheck.bg}
                        lineColor={theme.preCheck.lineColor}
                        textColor={theme.topNav.color}
                        inAmountColor={theme.topNav.colorIcon}
                        outAmountColor={theme.inputBlock.changeBtn}
                        durationTimeColor={theme.preCheck.durationTimeColor}>
                        <div className='precheck-screen'>
                            <div className='provider-data'>
                                <ProviderData
                                    icon={providerData?.providerName || exchangeWay?.provider || ''}
                                    name={providerData?.providerName || exchangeWay?.provider || ''}
                                    rating={exchangeWay?.providerRating || ''}
                                    theme={theme}
                                    skeleton={skeleton}
                                />
                                {!skeleton ? (
                                    labels && labels.length && !config.showError ? (
                                        labels.map((item) => <ProviderLabel condition={item} key={item} name={Label[item] || item} theme={theme} />)
                                    ) : null
                                ) : (
                                    <div style={{ width: '100%', maxWidth: '40%', marginTop: 8 }}>
                                        <Skeleton count={1} height={28} duration={config.skeletonAnimationDuration} />
                                    </div>
                                )}
                            </div>
                            <div className='line' />
                            <div className='amount-data'>
                                {!skeleton ? (
                                    <>
                                        <div className='amount-side-text'>{strings('mobileMarket.youGive')}:</div>
                                        <div className='amount-value'>{inAmount}</div>
                                    </>
                                ) : (
                                    <div style={{ width: '40%', marginTop: 3 }}>
                                        <Skeleton count={2} height={20} style={{ marginTop: 3 }} duration={config.skeletonAnimationDuration} />
                                    </div>
                                )}
                            </div>
                            <div className='line small' />
                            <div className='check-details'>
                                {!skeleton && durationTime ? (
                                    <div className='duration-operation'>
                                        <div
                                            style={{
                                                background: theme.preCheck.durationTimeBgColor,
                                                padding: '10px',
                                                margin: '0 0 12px 0',
                                                borderRadius: 12,
                                                display: 'flex',
                                                flexDirection: 'row'
                                            }}>
                                            <div style={{ marginRight: '10px' }}>🕑</div>
                                            {durationTime}
                                        </div>
                                    </div>
                                ) : null}
                                {!skeleton ? (
                                    <>
                                        <CheckData
                                            condition={providerData?.equivalentData?.exchangeRate}
                                            name={
                                                strings('mobileMarket.rate') +
                                                ` 1 ${
                                                    providerData?.exchangeRateSide === 'OUT'
                                                        ? providerData?.inCurrency.symbol
                                                        : providerData?.exchangeRateSide === 'IN'
                                                        ? providerData?.outCurrency.symbol
                                                        : providerData?.inCurrency.symbol
                                                }`
                                            }
                                            value={`${providerData?.equivalentData?.exchangeRate} ${
                                                providerData?.exchangeRateSide === 'OUT'
                                                    ? providerData?.outCurrency.symbol
                                                    : providerData?.exchangeRateSide === 'IN'
                                                    ? providerData?.inCurrency.symbol
                                                    : providerData?.outCurrency.symbol
                                            }`}
                                            style={{ paddingBottom: 16 }}
                                        />
                                        <CheckData
                                            condition={activeExchangeWay !== 'SELL' && outDestinationData?.outDestination}
                                            name={strings('mobileMarket.destinationAddress')}
                                            value={
                                                outDestinationData?.outDestination
                                                    ? outDestinationData?.outDestination.slice(0, 6) +
                                                      '...' +
                                                      outDestinationData?.outDestination.slice(-4)
                                                    : null
                                            }
                                            style={{ paddingBottom: 16 }}
                                        />
                                    </>
                                ) : (
                                    <Skeleton count={1} height={20} style={{ marginBottom: 16 }} duration={config.skeletonAnimationDuration} />
                                )}
                                {skipPaymentDetailsScreen ? null : !skeleton ? (
                                    <>
                                        {activeExchangeWay !== 'EXCHANGE' && payway && paymentData && paymentData?.number && (
                                            <CheckData
                                                condition={payway && paymentData && paymentData?.number}
                                                name={
                                                    payway === 'MOBILE_PHONE' || payway === 'QIWI'
                                                        ? strings('mobileMarket.mobilePhone')
                                                        : payway === 'CARD'
                                                        ? strings('mobileMarket.cardNumber')
                                                        : strings('mobileMarket.accountNumber')
                                                }
                                                value={
                                                    paymentData?.cardName && paymentData?.cardName?.toString()?.toLowerCase() !== 'null'
                                                        ? paymentData?.cardName
                                                        : ''
                                                }
                                                secondValue={
                                                    payway && payway === 'CARD'
                                                        ? `**** ${paymentData?.number.slice(-4)}`
                                                        : `${paymentData?.number.slice(0, 4)} **** ${paymentData?.number.slice(-4)}`
                                                }
                                                style={{ paddingBottom: 16 }}
                                            />
                                        )}
                                        {payway === 'ADVCASH' && activeExchangeWay !== 'EXCHANGE' && (
                                            <CheckData
                                                condition={paymentData && paymentData?.cardEmail ? paymentData?.cardEmail : ''}
                                                name={strings('mobileMarket.email')}
                                                value={paymentData?.cardEmail || ''}
                                                style={{ paddingBottom: 16 }}
                                            />
                                        )}
                                    </>
                                ) : (
                                    <Skeleton count={1} height={20} style={{ marginBottom: 16 }} duration={config.skeletonAnimationDuration} />
                                )}
                            </div>
                            <div className='line small' />
                            <div className='amount-data'>
                                {!skeleton ? (
                                    <>
                                        <div className='amount-side-text'>{strings('mobileMarket.youExpect')}:</div>
                                        <div className='amount-value out'>{outAmount}</div>
                                    </>
                                ) : (
                                    <div style={{ width: '40%', marginTop: 3 }}>
                                        <Skeleton count={2} height={20} style={{ marginTop: 3 }} duration={config.skeletonAnimationDuration} />
                                    </div>
                                )}
                            </div>
                            <div className='line' />
                            <AnimateHeight duration={HEIGHT_DURATION} height={height}>
                                <div className='more-details' onClick={handleMoreDetails}>
                                    {!skeleton ? (
                                        <>
                                            {strings('mobileMarket.moreDetails')}
                                            <Icon icon='ARROW_DOWN' size={20} color={theme.topNav.color} style={{ marginTop: -2 }} />
                                        </>
                                    ) : (
                                        <div style={{ width: '40%', margin: '0 auto' }}>
                                            <Skeleton count={1} height={14} duration={config.skeletonAnimationDuration} />
                                        </div>
                                    )}
                                </div>
                                {showDetails ? (
                                    skeleton ? null : (
                                        <div className='details'>
                                            {isFeesIncludedRate === true ? (
                                                <div style={{ marginLeft: -16, marginRight: -16 }}>
                                                    <Message theme={theme} text={strings('mobileMarket.includedFees')} />
                                                </div>
                                            ) : (
                                                <>
                                                    <CheckData
                                                        condition={providerData?.equivalentData?.providerFee?.in}
                                                        name={
                                                            strings(
                                                                activeExchangeWay === 'BUY'
                                                                    ? 'mobileMarket.bankFee'
                                                                    : 'mobileMarket.providerNetworkFee'
                                                            ) +
                                                            ' ' +
                                                            bankFee
                                                        }
                                                        value={
                                                            utilsService.cutNumber(providerData?.equivalentData?.providerFee?.in, 6) +
                                                            ' ' +
                                                            (activeExchangeWay === 'BUY'
                                                                ? providerData?.inCurrency.symbol
                                                                : providerData?.outCurrency.symbol)
                                                        }
                                                        style={{ paddingBottom: 16 }}
                                                    />
                                                    <CheckData
                                                        condition={providerData?.equivalentData?.providerFee?.out}
                                                        name={strings('mobileMarket.providerFee') + ' ' + networkFee}
                                                        value={
                                                            providerData?.equivalentData?.providerFee?.out +
                                                            ' ' +
                                                            (activeExchangeWay === 'BUY'
                                                                ? providerData?.inCurrency.symbol
                                                                : providerData?.outCurrency.symbol)
                                                        }
                                                        style={{ paddingBottom: 16 }}
                                                    />
                                                    <CheckData
                                                        condition={validValue(trusteeFee)}
                                                        name={strings('mobileMarket.trusteeFee') + ' ' + trusteeString}
                                                        value={trusteeFee}
                                                    />
                                                </>
                                            )}
                                        </div>
                                    )
                                ) : null}
                            </AnimateHeight>
                        </div>
                        {skeleton || exchangeWaysArray.length === 1 ? null : (
                            <div className='other-providers-btn' onClick={handleListProviders}>
                                {strings('mobileMarket.otherProviders')}
                                <Icon icon='ARROW_DOWN' size={20} style={{ transform: 'rotate(-90deg)' }} color={theme.inputBlock.changeBtn} />
                            </div>
                        )}
                    </PreCheckScreenDiv>
                    <Button
                        id='confirmBtn'
                        condition={count === 0 && !skeleton ? true : false}
                        style={{ bottom: '24px', position: 'fixed', width: 'calc(100% - 32px)', maxWidth: 736 }}
                        skeleton={skeleton}
                        handler={handleConfirmOrder}>
                        {strings(activeExchangeWay === 'BUY' ? 'mobileMarket.acceptPay' : 'mobileMarket.acceptPrepare')}
                    </Button>
                </SkeletonTheme>
            </Wrapper>
            {modalStateShow && <Modal />}
            {modalScreenShow && <ScreenModal />}
            {modalSearchScreenShow && <SearchScreenModal />}
        </Content>
    )
}

export default React.memo(PreCheckScreen)
