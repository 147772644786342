import axios from 'axios'
import config from '../config/config'
import Log from './sentry/Log'

export default async function limitScan(params) {
    const ways = params?.allExchangeWays || params

    if (ways.length === 0) {
        return
    }

    const exchangeWayIds = ways.map((way) => way.exchangeWayId)

    const body = {
        exchangeWayIds: [...exchangeWayIds]
    }

    try {
        const response = await axios.post(config.backendUrl + '/data/limits', body).catch((error) => {
            Log.error(`V3_limitScan response error message ${JSON.stringify(error.response.data)}`)
            throw new Error('API v.3 error | ' + error.response.data)
        })

        if (response.data.limits.length === 0) {
            Log.error(`V3_limitScan response error | No limits. Check request body`)
            throw new Error('API v.3 error | No limits. Check request body.')
        }

        return response.data.limits
    } catch (error) {
        // console.log(error.message);
        Log.error(`V3_limitScan response error ${JSON.stringify(error)}`)
        Log.captureError(`V3_limitScan response error`)
        return null
    }
}
