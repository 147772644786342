import React from 'react'
import Icon from '../../Icon/Icon'
import CryptoIconsDict from './iconDict'

export const CurrencyMonoIcon = (props) => {
    const { currencyCode, theme } = props

    const uiData = CryptoIconsDict[currencyCode] || false

    if (uiData) {
        return <Icon icon={uiData.icon} viewBox={uiData?.viewBox || '0 0 24 24'} color={theme.main.arrow} size={24} />
    }

    return <Icon icon='DEFAULT_COIN' size={24} color={theme.main.arrow} />
}
