import React from 'react'
import { getThemeModeStyles } from '../../../../theme/useDarkMode'
import { Data, Rate } from './check.css'

const CheckData = (props) => {
    const { condition, name, value, data, codeName, out, secondValue, link, style } = props

    const theme = getThemeModeStyles()

    return (
        <>
            {!data ? (
                <Rate style={{ display: condition ? 'flex' : 'none', ...style }} color={theme.checkData.textColor}>
                    <div className={'wrapper'}>
                        {condition && <div className={'post'}>{name}</div>}
                        <div className={'rate'}>
                            <div className={'post__value'}>
                                {link ? (
                                    secondValue ? (
                                        <div style={{ flexDirection: 'column' }}>
                                            <div>{value}</div>
                                            <div style={{ marginTop: 5 }}>{secondValue}</div>
                                        </div>
                                    ) : (
                                        <a href={link} target='_blank' rel={'noopener noreferrer'}>
                                            {value ? value.slice(0, 5) + '...' + value.slice(-5) : null}
                                        </a>
                                    )
                                ) : secondValue ? (
                                    <div style={{ flexDirection: 'column' }}>
                                        <div>{value}</div>
                                        <div style={{ marginTop: 5 }}>{secondValue}</div>
                                    </div>
                                ) : (
                                    value
                                )}
                            </div>
                        </div>
                    </div>
                </Rate>
            ) : (
                <Data
                    color={theme.checkData.textColor}
                    receive={theme.checkData.receiveColor}
                    amount={theme.checkData.amountColor}
                    out={out}
                    style={{ ...style }}>
                    <div className={'data__name'}>
                        <div className={'data'}>{name}</div>
                        <div className={'data'}>{codeName}</div>
                    </div>
                    <div className={'data__value'}>
                        {secondValue ? (
                            <div style={{ flexDirection: 'column' }}>
                                <div>{value}</div>
                                <div style={{ marginTop: 5 }}>{secondValue}</div>
                            </div>
                        ) : (
                            value
                        )}
                    </div>
                </Data>
            )}
        </>
    )
}

export default CheckData
