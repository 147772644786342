import styled from 'styled-components'

export const Buttons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 60px;

    .item__btn {
        border: 2px solid ${(p) => p.borderColor};
        box-sizing: border-box;
        border-radius: 10px;
        outline: none;
        width: 23%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Montserrat Bold';
        font-style: normal;
        font-size: 16px;
        line-height: 16px;
        color: ${(p) => p.textColor};
        letter-spacing: 0.5px;
        -webkit-tap-highlight-color: transparent;
    }

    .item__btn__inverse {
        background: ${(p) => p.borderColor};
        color: ${(p) => p.textInverse};
        border: 2px solid ${(p) => p.borderColor};
        box-sizing: border-box;
        border-radius: 10px;
        outline: none;
        width: 23%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Montserrat Bold';
        font-style: normal;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0.5px;
        transition-property: all;
        transition-duration: 0.3s;
    }
`
