import styled from 'styled-components'

const InputBlockDiv = styled.div`
    width: 100%;
    /* min-height: 50px; */
    /* border-width: 1px 0; */
    /* border-style: solid; */
    /* border-color: ${(p) => p.borderColor}; */

    /* background: ${(p) => p.bg}; */

    /* padding: 11px 16px 24px 16px; */

    /* margin: 0 -16px; */

    .amount-wrapper {
        display: flex;
        flex-direction: row;
        /* margin-top: 56px; */
        width: 100%;
    }

    .min-max-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 55px;
        align-self: flex-start;
        align-self: center;
        -webkit-tap-highlight-color: transparent;
    }

    .limit-btn {
        font-family: 'Montserrat Bold';
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 14px;
        text-align: center;
        color: ${(p) => p.limitsBtn};
        -webkit-tap-highlight-color: transparent;
    }

    .line {
        border-bottom: 1px solid ${(p) => p.limitLine};
        height: 1px;
        margin: 0 auto;
        width: 80%;
    }

    .wrapper-amount {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 88%;
    }

    .currency-balance {
        font-family: 'SF UI Display SemiBold';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 19px;
        text-align: center;
        letter-spacing: 1.5px;
        color: #999999;
        padding-bottom: 8px;
    }

    .input-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 40px;
        position: relative;
        margin-left: 12px;
        margin-right: 6px;
    }

    .input-wrapper input {
        width: 90px;
        max-width: 70%;
        outline: none;
        border: none;
        background: transparent;
        font-family: 'Montserrat Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 40px;
        line-height: 40px;
        font-feature-settings: 'liga' off, 'kern' off;
        color: ${(p) => p.limitsBtn};
        caret-color: #864dd9;
        -webkit-tap-highlight-color: transparent;
        resize: none;
        text-align: center;
        padding: 0;
        margin-left: 10px;
    }

    .input-wrapper input:focus::placeholder {
        color: transparent;
    }

    .input-wrapper input::-webkit-input-placeholder {
        font-family: 'Montserrat Medium';
        font-style: normal;
        font-size: 40px;
        line-height: 40px;
        letter-spacing: 0.5px;
        color: ${(p) => p.limitsBtn};
    }

    .input-wrapper input:-ms-input-placeholder {
        font-family: 'Montserrat Medium';
        font-style: normal;
        font-size: 40px;
        line-height: 40px;
        letter-spacing: 0.5px;
        color: ${(p) => p.limitsBtn};
    }

    .input-wrapper input::placeholder {
        font-family: 'Montserrat Medium';
        font-style: normal;
        font-size: 40px;
        line-height: 40px;
        letter-spacing: 0.5px;
        color: ${(p) => p.limitsBtn};
    }

    .loader {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 10px;
    }

    .input-code {
        width: 100px;
        padding-right: 5px;
        font-family: 'Montserrat SemiBold';
        font-size: 20px;
        line-height: 20px;
        font-feature-settings: 'liga' off, 'kern' off;
        color: ${(p) => p.limitsBtn};
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;
        align-self: flex-end;
        -webkit-tap-highlight-color: transparent;
        margin-bottom: 4px;
    }

    .message-container {
        padding: 0 0 32px 0;
        margin: -10px -16px 0 -16px;
    }

    /* .main-text {
        font-family: 'SF UI Display Medium';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 19px;

        letter-spacing: 1.5px;

        color: #999999;

        &.balance {
            margin-top: 6px;
        }
    }

    .input-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .input-wrapper {
        display: flex;
        flex-direction: row;

        border-bottom: 1px solid #DADADA;

        width: 75%;

    }

    .input-wrapper input {
        width: 90px;
        max-width: 73%;

        outline: none;
        border: none;
        background: transparent;
        font-family: 'Montserrat Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 40px;
        line-height: 44px;
        font-feature-settings: 'liga' off, 'kern' off;
        caret-color: #864DD9;
        -webkit-tap-highlight-color: transparent;
        resize: none;
        text-align: center;
        padding: 0;
        margin: 0 10px -10px 0;
        padding-left: 6px;

        color: ${(p) => p.inputColor}
    }

    .input-wrapper input:focus::placeholder {
        color: transparent;
    }

    .input-wrapper input::-webkit-input-placeholder {
        font-family: 'Montserrat Medium';
        font-style: normal;
        font-size: 40px;
        line-height: 40px;
        letter-spacing: 0.5px;
        color: ${(p) => p.inputColor};
    }

    .input-wrapper input:-ms-input-placeholder {
        font-family: 'Montserrat Medium';
        font-style: normal;
        font-size: 40px;
        line-height: 40px;
        letter-spacing: 0.5px;
        color: ${(p) => p.inputColor};
    }

    .input-wrapper input::placeholder {
        font-family: 'Montserrat Medium';
        font-style: normal;
        font-size: 40px;
        line-height: 40px;
        letter-spacing: 0.5px;
        color: ${(p) => p.inputColor};
    }

    .input-symbol {
        display: flex;
        align-items: flex-end;

        margin-bottom: 4px;

        font-family: 'Montserrat Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        
        color: ${(p) => p.inputColor};
    }

    .amount-error-container {
        margin-top: 13px;
        margin-bottom: 16px;
    }

    .amount-input-limits-text {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        min-height: 35px;
        border-radius: 10px;
        width: calc(100% - 32px);
        padding: 0 16px;

        font-family: 'Fira Sans';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;

        background: ${(p) => p.errorBg};
        color: ${(p) => p.errorColor};
    }

    .amount-input-limits-value {
        font-family: 'Montserrat Medium';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;

        letter-spacing: 2px;
        text-decoration-line: underline;
        text-transform: uppercase;
    } */
`

export default InputBlockDiv
