import store from '../redux'
import Log from './sentry/Log'

import Cookies from 'js-cookie'

export function getPaymentData(cookie = false) {
    const state = store.getState().mobileMarket

    if (!state.walletData) return []

    if (!state.payway) return null

    let wallet, walletName, walletHash

    if (!state.useCookie) {
        wallet = state?.walletData?.wallets.find((way) => way.cashbackToken === state.cashbackToken)
        walletName = wallet?.walletName
        walletHash = wallet?.walletHash
    }

    let data

    if (!state.useCookie) {
        data =
            typeof state.walletData.cards !== 'undefined'
                ? state.walletData.cards.filter((way) =>
                      state.payway === 'CARD'
                          ? way.type === 'visa' || way.type === 'mastercard' || way.type === 'mir' || way.type === 'maestro'
                          : way.type === state.payway.toString()?.toLowerCase() &&
                            (typeof way.walletHash !== 'undefined' && way.walletHash
                                ? way.walletHash === walletName ||
                                  way.walletHash === walletHash ||
                                  way.walletHash.toString()?.toLowerCase() === 'null' ||
                                  way.walletHash.toString()?.toLowerCase() === 'undefined'
                                : true)
                  )
                : []

        Log.info(`V3.firstScreen.findButtonHandler paymentDetails ${JSON.stringify(data)}`)
    } else if (cookie) {
        try {
            data = Cookies.getJSON('paymentData')

            if (!data) data = []
        } catch (e) {
            console.log(JSON.stringify(e))
        }
    }

    return data
}
