export default function navigationState(versionNumber) {
    const navigationState = `if (true) {
        const { url } = tradeWebParam

        if (!url) return

        Log.log('Trade.SMSV3CodeScreen.handleWebViewNavigationStateChange url', url)

        that.handleState({
            url
        })

        Log.log('Trade.SMSV3CodeScreen.handleWebViewNavigationStateChange started', url)

        if (url.includes('acs') && url !== 'about:blank') {
            Log.log('Trade.SMSV3CodeScreen.handleWebViewNavigationStateChange timeout0 will be created')
            that.handleState({
                status: 'PENDING'
            })
            setTimeout(() => {
                Log.log('Trade.SMSV3CodeScreen.handleWebViewNavigationStateChange timeout0 finished', state)
                that.handleState({
                    status: 'SUCCESS'
                })
            }, 5000)
        }

        // || (url.includes('mapi.xpay.com') && url !== 'about:blank')
        if (
            (url.includes('pay.receipt-pay.com/cards') && url !== 'about:blank')
            || (url.includes('cardgate.paycore.io') && url !== 'about:blank')
            || (url.includes('easybits.io') && url !== 'about:blank')
            || (url.includes('page.3ds-idcheck.com') && url !== 'about:blank')
            // || (url.includes('online.contact-sys.com') && url !== 'about:blank')
        ) {
            Log.log('Trade.SMSV3CodeScreen.handleWebViewNavigationStateChange timeout1 will be created')
            setTimeout(() => {
                if (that.state.receiptPay) {
                    that.handleState({
                        receiptPay: false,
                        status: 'SUCCESS'
                    })
                }
            }, 4000)
        }

        if (url.includes('sci.any.money') && url !== 'about:blank') {
            if (that.state.additionalData.orderStatus === 'DONE_PAYOUT') {
                const { cryptoCurrencies } = store.getState().currencyStore
                const cryptoCurrencyNew = _.find(cryptoCurrencies, { currencyCode: that.state.currencySelect })
                Log.log('Trade.SMSV3CodeScreen.handleWebViewNavigationStateChange cryptoCurrencyNew' + JSON.stringify(cryptoCurrencyNew))

                that.handleState({
                    additionalData: {
                        ...that.state.additionalData,
                        close: false
                    }
                })

                if (CACHE_IS_ERROR) {
                    if (${Number(versionNumber) > 1004 || versionNumber === 'VERSION'}) {
                        that.setExchangeStatus('', {orderHash: that.state.orderHash, status: 'FAIL'})
                    } else {
                        that.setExchangeStatus('', that.state.orderHash, 'FAIL')
                    }
                    Log.log('Trade.SMSV3CodeScreen setExchangeStatus FAIL')
                    try {
                        if (${Number(versionNumber) > 836 || versionNumber === 'VERSION'}) {
                            NavStore.reset('TabBar')
                        } else {
                            NavStore.reset('DashboardStack')
                        }
                    } catch (e) {
                        Log.err('SMSV3Screen.navigationState error ' + e )
                    }
                } else {
                    if (${Number(versionNumber) > 1004 || versionNumber === 'VERSION'}) {
                        that.setExchangeStatus('', {orderHash: that.state.orderHash, status: 'SUCCESS'})
                    } else {
                        that.setExchangeStatus('', that.state.orderHash, 'SUCCESS')
                    }
                    Log.log('Trade.SMSV3CodeScreen setExchangeStatus SUCCESS')
                    try {
                        if (${Number(versionNumber) > 864 || versionNumber === 'VERSION'}) {
                            Log.log('Trade.SMSV3CodeScreen orderHash ' + that.state.orderHash)
                            NavStore.reset('MarketScreen', { orderHash: that.state.orderHash ? that.state.orderHash : false })
                        } else if (${Number(versionNumber) > 836 || versionNumber === 'VERSION'}) {
                            NavStore.goNext('HomeScreen', { screen: 'AccountTransactionScreen', params: { 
                                txData: {
                                    orderHash: that.state.orderHash
                                }
                            }}) 
                        } else {
                            NavStore.goNext('TransactionScreen', { 
                                txData: {
                                    orderHash: that.state.orderHash
                                }
                            })
                        }
                    } catch (e) {
                        Log.err('SMSV3Screen.navigationState error ' + e )
                    }
                }
            }
        }

        if (
            (
                url.includes('cardgate.paycore.io/hpp/status') || url.includes('cb1.xpay.com.ua/')
                || url.includes('365cash.co/currency/success') 
                || url.includes('365cash.co/currency/failure')
                || url.includes('365cash.co/order/status')
                || (
                    url.includes('trustee.deals')
                    && !url.includes('redirectUrl=https://trustee.deals/') 
                    && !url.includes('successUrl=https://trustee.deals/') 
                    && !url.includes('return_url=https://trustee.deals') 
                    && !url.includes('redirectURL=https%3A%2F%2Ftrustee.deals') 
                    && !url.includes('successUrl=https%3A%2F%2Ftrustee.deals') 
                    && !url.includes('redirectUrl=https%3A%2F%2Ftrustee.deals%2F')
                    && !url.includes('exchange.trustee.deals')
                    && !url.includes('testexchange.trustee.deals')
                    && !url.includes('successUrl=https%3A%2F%2F365cash.co%2F')
                )
                || (
                    url.includes('trusteeglobal.com')
                    && !url.includes('redirect_url_failed=https://trusteeglobal.com')
                    && !url.includes('redirectUrl=https://trusteeglobal.com/') 
                    && !url.includes('successUrl=https://trusteeglobal.com/') 
                    && !url.includes('return_url=https://trusteeglobal.com') 
                    && !url.includes('redirectURL=https%3A%2F%2Ftrusteeglobal.com') 
                    && !url.includes('successUrl=https%3A%2F%2Ftrusteeglobal.com') 
                    && !url.includes('successUrl=https%3A%2F%2Ftrusteeglobal.com%2F') 
                    && !url.includes('redirectUrl=https%3A%2F%2Ftrusteeglobal.com%2F')
                    && !url.includes('successUrl=https%3A%2F%2F365cash.co%2F')
                )
                || (url.includes('https://blocksoftlab.com/') && !url.includes('successUrl=https://blocksoftlab.com/') && !url.includes('redirectUrl=https://blocksoftlab.com/'))
            ) && url !== 'about:blank' && that.state.lastStep
        ) {
            Log.log('Trade.SMSV3CodeScreen.handleWebViewNavigationStateChange timeout2 will be created ' + url + ' CACHE_IS_ERROR ' + (CACHE_IS_ERROR ? 'true' : 'false'))
            that.handleStartAnimation()

            that.handleState({
                status: 'PENDING',
                lastStep: false
            })

            const { cryptoCurrencies } = store.getState().currencyStore
            const cryptoCurrencyNew = _.find(cryptoCurrencies, { currencyCode: that.state.currencySelect })
            Log.log('Trade.SMSV3CodeScreen.handleWebViewNavigationStateChange cryptoCurrencyNew' + JSON.stringify(cryptoCurrencyNew))

            that.handleState({
                additionalData: {
                    ...that.state.additionalData,
                    close: false
                }
            })

            if (CACHE_IS_ERROR || url.includes('365cash.co/currency/failure')) {
                if (${Number(versionNumber) > 1004 || versionNumber === 'VERSION'}) {
                    that.setExchangeStatus('', {orderHash: that.state.orderHash, status: 'FAIL'})
                } else {
                    that.setExchangeStatus('', that.state.orderHash, 'FAIL')
                }
                Log.log('Trade.SMSV3CodeScreen setExchangeStatus FAIL')
                try {
                    if (${Number(versionNumber) > 836 || versionNumber === 'VERSION'}) {
                        NavStore.reset('TabBar')
                    } else {
                        NavStore.reset('DashboardStack')
                    }
                } catch (e) {
                    Log.err('SMSV3Screen.navigationState error ' + e )
                }
            } else {
                if (${Number(versionNumber) > 1004 || versionNumber === 'VERSION'}) {
                    that.setExchangeStatus('', {orderHash: that.state.orderHash, status: 'SUCCESS'})
                } else {
                    that.setExchangeStatus('', that.state.orderHash, 'SUCCESS')
                }
                Log.log('Trade.SMSV3CodeScreen setExchangeStatus SUCCESS')
                try {
                    if (${Number(versionNumber) > 864 || versionNumber === 'VERSION'}) {
                        Log.log('Trade.SMSV3CodeScreen orderHash ' + that.state.orderHash)
                        NavStore.reset('MarketScreen', { orderHash: that.state.orderHash ? that.state.orderHash : false  })
                    } else if (${Number(versionNumber) > 836 || versionNumber === 'VERSION'}) {
                        NavStore.goNext('HomeScreen', { screen: 'AccountTransactionScreen', params: { 
                            txData: {
                                orderHash: that.state.orderHash
                            }
                        }}) 
                    } else {
                        NavStore.goNext('TransactionScreen', { 
                            txData: {
                                orderHash: that.state.orderHash
                            }
                        })
                    }
                } catch (e) {
                    Log.err('SMSV3Screen.navigationState error ' + e )
                }
            }
        }
}`

    return navigationState
}

// && url !== 'about:blank' && state.lastStep
// && !url.includes('return_url=https://trustee.deals')
// url.includes('kuna.io/') ||
