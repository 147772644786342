import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import XHR from 'i18next-xhr-backend'
import EN from './locales/en.json'
import RU from './locales/ru.json'
import UK from './locales/uk.json'

let LNG = localStorage.getItem('i18nextLng') !== null ? localStorage.getItem('i18nextLng') : navigator.language || navigator.userLanguage
LNG = LNG.split('-')[0]

i18n.use(XHR)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: EN,
            ru: RU,
            uk: UK
        },
        lng: LNG,
        fallbackLng: 'en',
        debug: false,
        ns: ['translations'],
        defaultNS: 'translations',
        keySeparator: '.',
        interpolation: {
            escapeValue: false,
            formatSeparator: ','
        },
        react: {
            wait: true,
            useSuspense: false,
            bindI18n: 'languageChanged loaded',
            bindStore: 'added removed',
            nsMode: 'default'
        }
    })

export function strings(name, params = {}) {
    return i18n.t(name, params)
}

export default i18n
