import React, { useEffect, useState } from 'react'
import axios from 'axios'

import { analytics } from 'firebase'

import Wrapper from 'components/ScreenWrapper/Wrapper'
import TopNavigation from 'components/TopNavigation/TopNavigation'
import Button from 'components/Button/Button'
import injectScript from 'components/buyScripts/injectScript'
import didMount from 'components/buyScripts/componentDidMount'
import navigationState from 'components/buyScripts/navigationState'
import message from 'components/buyScripts/message'
import mountDid from 'components/buyScripts/anotherWallets/didMount'
import msg from 'components/buyScripts/anotherWallets/msg'
import navState from 'components/buyScripts/anotherWallets/navState'
import Loader from 'components/Loader/loader'
import { Content } from 'components/wrapperScreen/wrapper.css'
import didMountSell from 'components/sellScripts/didMount'
import navigationStateSell from 'components/sellScripts/navigationState'
import Message from 'components/InfoMsg/Message'
import Line from 'components/Line/line'
import getProviderName from 'components/providersSettings/providerName'
import { getOutDestinationAddress } from 'components/providerItem/helper'
import NotificationMsg from 'components/Notification/Notification'

import Log from 'services/sentry/Log'
import utilsService from 'services/utilsService'
import limitScan from 'services/limitScan'
import limitForKsu from 'services/limitForKsu'
import { getCurrencyName, getCurrencyCode, isToken, getTokenNet } from 'services/getCurrenciesProperty'
import timed from 'services/timed'
import getHashStr from 'services/getHashStr'
import findOutDestination from 'services/findOutDestination'
import hideAnimation from 'services/hideAnimation'
import { getStatusKYC } from 'services/getStatusKYC'

import { strings } from 'i18n'
import config from 'config/config'
import { getThemeModeStyles } from 'theme/useDarkMode'

import {
    nullOrderAction,
    cameraResultAction,
    coinAllbalanceAction,
    balanceHodlMsgAction,
    searchModalAction,
    afterKycAction,
    propsModalAction,
    recheckSendAllAction,
    comeBackAction,
    preCheckAction,
    setSelectedFee,
    outDestinationAction
} from 'redux/AppStores/MobileMarket/action'
import store from 'redux/index'
import { hideModal, showModal } from 'redux/AppStores/ModalStore/action'

import Modal from 'modules/newModal/MainModal'

import classes from './style.module.css'

import CheckData from './CheckData/CheckData'

const mainCoin = ['BTC', 'ETH', 'TRX', 'BNB']

const notActiveCoin = ['XRP', 'XLM']

const { dispatch } = store

const getBankFeeString = (feeData) => {
    const state = store.getState().mobileMarket
    const fixedFee = feeData.providerFee.in.find((fee) => fee.type === 'FIXED')
    const percentFee = feeData.providerFee.in.find((fee) => fee.type === 'PERCENT')

    const bankFee = percentFee.amount + '% + ' + fixedFee.amount + ' ' + state.provider.inCurrency.symbol || state.provider.outCurrency.code

    Log.info(`V3.preCheckScreen. bankFee ${bankFee}`)
    return bankFee
}

const getNetworkFeeString = (feeData) => {
    const state = store.getState().mobileMarket
    const fixedFee = feeData.providerFee.out.find((fee) => fee.type === 'FIXED')
    const percentFee = feeData.providerFee.out.find((fee) => fee.type === 'PERCENT')

    const networkFee = percentFee.amount + '% + ' + fixedFee.amount + ' ' + state.provider.outCurrency.symbol || state.provider.outCurrency.code

    Log.info(`V3.preCheckScreen. networkFee ${networkFee}`)
    return networkFee
}

const getTrusteeFeeString = (feeData) => {
    const state = store.getState().mobileMarket

    switch (state.activeExchangeWay) {
        case 'BUY':
            return feeData.trusteeFee.in.find((fee) => fee.type === 'PERCENT').amount + '%'

        case 'SELL':
            return feeData.trusteeFee.out.find((fee) => fee.type === 'PERCENT').amount + '%'

        case 'EXCHANGE':
            let percent =
                feeData.trusteeFee.out.find((fee) => fee.type === 'PERCENT').amount !== 0
                    ? feeData.trusteeFee.out.find((fee) => fee.type === 'PERCENT').amount
                    : feeData.trusteeFee.in.find((fee) => fee.type === 'PERCENT').amount
            return percent + '%'

        default:
            return ''
    }
}

const getTrusteeFeeAmount = () => {
    const state = store.getState().mobileMarket

    switch (state.activeExchangeWay) {
        case 'BUY':
            return `${utilsService.cutNumber(state.provider.equivalentData.trusteeFee.in, 2)} ${state.provider.inCurrency.symbol}`
        case 'SELL':
            return `${utilsService.cutNumber(state.provider.equivalentData.trusteeFee.out, 6)} ${state.provider.outCurrency.symbol}`
        case 'EXCHANGE':
            let outFee =
                (state.provider.equivalentData.trusteeFee.out !== 0
                    ? state.provider.equivalentData.trusteeFee.out
                    : state.provider.equivalentData.trusteeFee.in) || 0
            return `${utilsService.cutNumber(outFee, 6)} ${state.provider.outCurrency.symbol}`
        default:
            return ''
    }
}

const validValue = (value) => {
    let data = value.split(' ')[0]

    if (Number(data) === 0) {
        return false
    } else {
        return true
    }
}

const deltaTime = () => {
    const state = store.getState().mobileMarket

    const timeNow = new Date().getTime()
    const time = state.equivalentTime

    const delta = timeNow - time

    const equivalentTime = timed(delta)

    return equivalentTime
}

const getRefundAddress = (localState, currency) => {
    const type = localState.outCurrency.code === 'USDT' ? 'LEGACY' : 'SEGWIT'

    let tmpAddress =
        typeof localState?.walletData?.wallets
            ?.find((wallet) => wallet?.cashbackToken === localState?.cashbackToken)
            ?.accounts?.find((account) => account?.currencyCode === currency?.code)?.address === 'string'
            ? localState?.walletData?.wallets
                  ?.find((wallet) => wallet?.cashbackToken === localState?.cashbackToken)
                  ?.accounts?.find((account) => account?.currencyCode === currency?.code)?.address
            : localState?.walletData?.wallets
                  .find((wallet) => wallet?.cashbackToken === localState?.cashbackToken)
                  ?.accounts?.find((account) => account?.currencyCode === currency?.code)
                  ?.address?.find((address) => address?.type === type)?.address

    if (!tmpAddress) {
        const obj = findOutDestination(localState.walletData, currency.code, localState.cashbackToken)
        dispatch(outDestinationAction(obj?.outDestination || null, obj?.walletName || null))

        if (!isToken(localState.walletData, currency.code)) {
            return null
        }

        const network = getTokenNet(localState.walletData, currency.code)
        Log.info(`V3.renderPrecheckScreen.getRefundAddress getOutDestinationAddress network ${network}`)

        if (network) {
            const { outDestination, walletName } = findOutDestination(localState.walletData, network, localState.cashbackToken, type)
            Log.info(`V3.renderPrecheckScreen.getRefundAddress getOutDestinationAddress outDestination ${outDestination}`)
            Log.info(`V3.renderPrecheckScreen.getRefundAddress getOutDestinationAddress walletName ${walletName}`)
            dispatch(outDestinationAction(outDestination, walletName))
            return outDestination
        }
    }

    return tmpAddress
}

const createOrder = async (activeExchangeWay, count, setCount, loader, setLoader, skipPaymentDetailsScreen, feeTrx = null, isNeedKYC = false) => {
    const state = store.getState().mobileMarket

    Log.info(`V3.preCheckScreen.cardValid name ${state.provider.providerName} -> item ${JSON.stringify(state.provider.valid)}`)

    // const account = state.activeExchangeWay === 'BUY' ?
    // typeof state.walletData.wallets.find(wallet => wallet.cashbackToken === state.cashbackToken).accounts.find(account => account.currencyCode === state.outCurrency.code).address === 'string' ?
    //     state.walletData.wallets.find(wallet => wallet.cashbackToken === state.cashbackToken).accounts.find(account => account.currencyCode === state.outCurrency.code) :
    //     state.walletData.wallets.find(wallet => wallet.cashbackToken === state.cashbackToken).accounts.find(account => account.currencyCode === state.outCurrency.code)
    // :
    // typeof state.walletData.wallets.find(wallet => wallet.cashbackToken === state.cashbackToken).accounts.find(account => account.currencyCode === state.inCurrency.code).address === 'string' ?
    //     state.walletData.wallets.find(wallet => wallet.cashbackToken === state.cashbackToken).accounts.find(account => account.currencyCode === state.inCurrency.code) :
    //     state.walletData.wallets.find(wallet => wallet.cashbackToken === state.cashbackToken).accounts.find(account => account.currencyCode === state.inCurrency.code)

    const body = {
        inAmount: feeTrx ? state.provider.equivalentData.inAmount - feeTrx : state.provider.equivalentData.inAmount,
        outAmount: state.provider.equivalentData.outAmount,
        exchangeWayId: state.provider.exchangeWayId,
        amountSide: state.amountSide,
        rateId: state.provider.equivalentData.rateData ? state.provider.equivalentData.rateData.rateId : null,
        outDestination: activeExchangeWay === 'SELL' ? state.paymentData?.number || ' ' : state.outDestination,
        outDestinationTag: state.destinationTag,
        deviceToken: state.walletData.deviceToken,
        locale: state.walletData.locale,
        uniqueParams: {
            refundAddress:
                activeExchangeWay === 'BUY'
                    ? getOutDestinationAddress(state) || getRefundAddress(state, state.outCurrency)
                    : getRefundAddress(state, state.inCurrency)
        },
        cashbackToken: state.cashbackToken,
        comment: state.comment,
        equivalentData: state.provider.equivalentData,
        sign: state.sign,
        paymentDetailsData: {}
    }

    if (state.walletData?.uniqueDeviceId) {
        body.deviceId = state.walletData?.uniqueDeviceId
    }

    if (state.payway && activeExchangeWay && activeExchangeWay !== 'EXCHANGE') {
        if (!skipPaymentDetailsScreen) {
            if (state.payway === 'CARD') {
                body.paymentDetailsData = {
                    ...body.paymentDetailsData,
                    paymentDetails: state?.paymentData?.number,
                    supportedCountries: [state.paymentData.countryCode]
                }
            } else {
                body.paymentDetailsData = {
                    ...body.paymentDetailsData,
                    paymentDetails: state?.paymentData?.number
                }
                if (state.payway === 'ADVCASH') {
                    body.uniqueParams.email = state.paymentData.cardEmail
                }

                if (state.payway === 'CASH') {
                    body.uniqueParams.region = JSON.parse(JSON.parse(state.paymentData.cardDetailsJson).city).country.toUpperCase()
                }
            }
        }
    }

    if (activeExchangeWay && activeExchangeWay !== 'BUY') {
        if (state.newOutDestination && state.useCookie) {
            body.paymentDetailsData = {
                ...body.paymentDetailsData,
                paymentDetails: state.newOutDestination
            }
        } else {
            const { outDestination } = findOutDestination(state.walletData, state.inCurrency.code, state.cashbackToken)
            body.paymentDetailsData = {
                ...body.paymentDetailsData,
                paymentDetails: outDestination
            }
        }
    }

    if (state.newOutDestination) {
        body.invoiceData = {
            isExternalOutDestination: state.newOutDestination
        }
    }

    if (state.provider.providerName === 'KASSA') {
        body.uniqueParams.calculationId = state.provider.equivalentData.rateData.calculationId
    }

    if (state.provider.providerName === 'JUST_SWAP') {
        body.paymentDetailsData = {
            ...body.paymentDetailsData,
            paymentDetails: state.walletData.wallets
                .find((wallet) => wallet.cashbackToken === state.cashbackToken)
                .accounts.find((account) => account.currencyCode === state.inCurrency.code).address
        }
    }

    if (typeof state.provider.equivalentData.dexData !== 'undefined') {
        body.uniqueParams = {
            ...body.uniqueParams,
            dexData: state.provider.equivalentData.dexData
        }
    }

    if (typeof isNeedKYC !== 'undefined' && isNeedKYC) {
        const email = localStorage.getItem('trusteeEmail') ? localStorage.getItem('trusteeEmail') : null
        let status
        Log.info(`V3.preCheckScreen. createOrder email ${JSON.stringify(email)}`)
        if (email) {
            status = await getStatusKYC(email)
            Log.info(`V3.preCheckScreen. createOrder email status ${JSON.stringify(status)}`)
            body.kycUserId = status.userId
        }
    }

    Log.info(`V3.preCheckScreen. createOrder body ${JSON.stringify(body)}`)

    if (activeExchangeWay === 'SELL' && state.paymentData && body.outDestination !== state.paymentData?.number) {
        showModal(
            {
                type: 'INFO_MODAL',
                icon: 'WARNING',
                header: strings('mobileMarket.modal.attention'),
                description: strings('mobileMarket.modal.createOrderError')
            },
            () => {
                hideModal()
            }
        )
        return
    }

    const orderLink = `${config.backendUrl}/order/create-order?cashbackToken=${state.cashbackToken}&createdFrom=WALLET`

    try {
        setCount(1)
        const response = await axios.post(orderLink, body).catch((error) => {
            console.log(error.message)
            console.log(error.response.data.message)
            Log.warning(`V3.preCheckScreen. createOrder error ${JSON.stringify(error.response.data)}`)
            Log.captureError(`V3.preCheckScreen. createOrder error`)

            if (error?.response?.data?.errorCode === 'NOT_PROFITABLE') {
                showModal({
                    type: 'INFO_MODAL',
                    icon: 'WARNING',
                    header: strings('mobileMarket.modal.sorry'),
                    description: strings('mobileMarket.modal.timeOutOne') + deltaTime() + ' ' + strings('mobileMarket.modal.timeOutTwo')
                })
                return false
            } else if (error?.response?.data?.errorCode === 'EXPIRED_RATE') {
                showModal({
                    type: 'INFO_MODAL',
                    icon: 'WARNING',
                    header: strings('mobileMarket.modal.sorry'),
                    description: strings('mobileMarket.modal.rateId')
                })
                return false
            } else {
                throw new Error(error.response.data.message)
            }
        })

        if (count === 0) {
            const orderData = response.data
            Log.info(`V3.preCheckScreen. createOrder response.data ${JSON.stringify(response.data)}`)
            return orderData
        }
    } catch (error) {
        // alert(error.message)
        Log.setTag('provider', state.provider.providerName)
        Log.warning(`V3.preCheckScreen. createOrder error ${JSON.stringify(error)}`)
        Log.captureError(`V3.preCheckScreen. createOrder error`)

        setLoader(false)
        dispatch(recheckSendAllAction(false))

        showModal(
            {
                type: 'INFO_MODAL',
                icon: 'WARNING',
                header: strings('mobileMarket.modal.attention'),
                description: config.showError ? error.message.toString() : strings('mobileMarket.modal.createOrderError')
            },
            () => {
                hideModal()
            }
        )

        return false
    }
}

const leftHandler = () => {
    const state = store.getState().mobileMarket

    SHOW_TOKEN = false

    dispatch(nullOrderAction())
    dispatch(coinAllbalanceAction(false))
    dispatch(balanceHodlMsgAction(false))
    if (state.afterKYC) {
        window.history.go(-2)
    } else {
        window.history.back()
    }

    dispatch(afterKycAction(false))

    if (state.skipProvidersScreen) {
        dispatch(propsModalAction(true))
    }
}

let IS_CONFIRM = false
let SHOW_TOKEN = false

const RenderPreCheckScreen = () => {
    const state = store.getState().mobileMarket
    const modalState = store.getState().modalStore

    const theme = getThemeModeStyles()

    const [count, setCount] = useState(0)
    const [loader, setLoader] = useState(false)

    const way = state.provider ? state.allExchangeWays.find((way) => way.exchangeWayId === state.provider.exchangeWayId) : null
    Log.info(`V3.preCheck way ${JSON.stringify(way)}`)

    const feeData = way?.fees || null
    const isNeedCameraPermission = way ? way.isNeedCameraPermission : false

    const isFeesIncludedRate = feeData ? feeData.isFeesIncludedInRate : null
    const skipPaymentDetailsScreen = way ? (typeof way.skipPaymentDetailsScreen !== 'undefined' ? way.skipPaymentDetailsScreen : false) : false
    const feeTrx = state.useAllFunds ? way.feeLimit || null : null
    const isNeedKYC = way ? (typeof way.isNeedKYC !== 'undefined' && way.isNeedKYC ? way.isNeedKYC : false) : false

    let activeExchangeWay = way ? way.exchangeWayType : 'Murzik'

    if (state.activeExchangeWay !== activeExchangeWay) activeExchangeWay = state.activeExchangeWay

    const eventHandler = (event) => {
        if (typeof event.data === 'undefined' || !event.data || !Object.keys(event.data) || Object.keys(event.data).length === 0) return
        try {
            // cameraRes,
            const { fees } = JSON.parse(event.data)
            if (typeof fees !== 'undefined' && fees) {
                const tmpState = store.getState().mobileMarket
                if (tmpState.comeBack && tmpState.preCheck && tmpState.useAllFunds) {
                    setLoader(false)
                    dispatch(setSelectedFee(fees?.transferData || null))
                    const amount = utilsService.cutNumber(fees.amount, 8)
                    Log.info(`V3.firstScreen.eventHandler fees amount ${JSON.stringify(amount)}`)
                    const tmpValue = (Number(amount) / Number(utilsService.cutNumber(tmpState.provider.equivalentData.inAmount, 8))) * 1
                    if (Number(utilsService.cutNumber(tmpValue - 0.98, 2)) > 0.02 || Number(utilsService.cutNumber(tmpValue - 0.98, 2)) < -0.02) {
                    } else {
                        setTimeout(async () => {
                            if (!IS_CONFIRM) {
                                dispatch(recheckSendAllAction(true))
                                await confirmHandler()
                                IS_CONFIRM = true
                            }
                        }, 0)
                        return
                    }
                } else if (tmpState.preCheck) {
                    setLoader(false)

                    const amount = utilsService.cutNumber(fees.amount, 8)
                    Log.info(`V3.firstScreen.eventHandler fees amount ${JSON.stringify(amount)}`)
                    const diff = utilsService.cutNumber(tmpState.provider.equivalentData.inAmount, 8) * 1 < amount * 1
                    if (diff) {
                        setTimeout(async () => {
                            if (!IS_CONFIRM) {
                                dispatch(recheckSendAllAction(true))
                                await confirmHandler()
                                IS_CONFIRM = true
                            }
                        }, 0)
                        return
                    }
                }
            }

            //         if (typeof cameraRes !== 'undefined' && cameraRes !== null) {
            //             dispatch(cameraResultAction(cameraRes))
            //             if (cameraRes) {
            //                 document.getElementById('confirmBtn').click()
            //             }
            //         }
        } catch (e) {
            Log.info(`V3.preCheckScreen.eventHandler error ${JSON.stringify({ e })}`)
            Log.captureError(`V3.preCheckScreen.eventHandler error`)
        }
    }

    useEffect(() => {
        if (window.navigator.userAgent.indexOf('Chrome') === -1) {
            window.addEventListener('message', (event) => eventHandler(event))
        } else {
            document.addEventListener('message', (event) => eventHandler(event))
        }
        dispatch(preCheckAction(true))

        return () => {
            if (window.navigator.userAgent.indexOf('Chrome') === -1) {
                window.removeEventListener('message', (event) => eventHandler(event), { once: true })
            } else {
                document.removeEventListener('message', (event) => eventHandler(event), { once: true })
            }
            dispatch(preCheckAction(false))
            IS_CONFIRM = false
        }
    }, [])

    const showAttentionModalZeroBalance = async (localState) => {
        const tokenBlockchain = getTokenNet(localState.walletData, localState.outCurrency.code)

        const nativeCoin = localState.walletData.wallets
            .find((wallet) => wallet.cashbackToken === localState.cashbackToken)
            .accounts.find((account) => account.currencyCode === tokenBlockchain)

        if (Number(nativeCoin?.prettyBalanceRaw || nativeCoin?.balance) <= 0) {
            setLoader(false)
            showModal(
                {
                    type: 'INFO_MODAL',
                    icon: 'WARNING',
                    header: strings('mobileMarket.modal.attention'),
                    description: strings('mobileMarket.modal.notCoinBalance', {
                        coin: getCurrencyCode(tokenBlockchain),
                        token: localState.outCurrency.symbol
                    })
                },
                async () => {
                    hideModal()
                    SHOW_TOKEN = true
                    await confirmHandler()
                }
            )
            return
        } else {
            SHOW_TOKEN = true
            await confirmHandler()
        }
    }

    const confirmHandler = async () => {
        const newState = store.getState().mobileMarket

        if (activeExchangeWay !== 'EXCHANGE' && !skipPaymentDetailsScreen && newState.paymentData) {
            localStorage.setItem('trusteeHash', getHashStr(newState?.paymentData?.number))
        }

        if (activeExchangeWay !== 'SELL' && notActiveCoin.includes(newState.outCurrency.code) && !newState.balanceHodlMsg) {
            const wallet = newState.walletData.wallets
                .find((way) => way.cashbackToken === newState.cashbackToken)
                .accounts.find((account) => account.currencyCode === newState.outCurrency.code)
            const balance = wallet?.prettyBalanceRaw || wallet.balance || 0
            const balanceHodl = wallet?.balanceHodl || 0
            const newBalanceHodl = Math.ceil(balanceHodl - (balanceHodl + balance))

            if (Number(newBalanceHodl) && Number(balance) <= Number(newBalanceHodl)) {
                showModal(
                    {
                        type: 'INFO_MODAL',
                        icon: 'WARNING',
                        header: strings('mobileMarket.modal.title'),
                        description: strings('mobileMarket.modal.notActiveAddress', {
                            name: getCurrencyName(newState.outCurrency.code) || newState.outCurrency.name,
                            value: `${newBalanceHodl} ${newState.outCurrency.code}`
                        })
                    },
                    async () => {
                        hideModal()
                        if (Number(newState.provider.equivalentData.outAmount) > newBalanceHodl) {
                            dispatch(balanceHodlMsgAction(true))
                            await confirmHandler()
                        } else {
                            window.history.back()
                            dispatch(searchModalAction())
                        }
                    }
                )
                return
            }
        }

        if (activeExchangeWay === 'BUY') {
            setLoader(true)

            if (isNeedKYC) {
                const email = localStorage.getItem('trusteeEmail') ? localStorage.getItem('trusteeEmail') : null
                if (email) {
                    try {
                        const status = await getStatusKYC(email)
                        if (status && typeof status.status !== 'undefined' && status.status !== 'DONE') {
                            showModal(
                                {
                                    type: 'INFO_MODAL',
                                    icon: 'WARNING',
                                    header: strings('mobileMarket.modal.sorry'),
                                    description: strings('mobileMarket.modal.verificationError')
                                },
                                () => {
                                    hideModal()
                                    if (newState.afterKYC) {
                                        window.history.go(-2)
                                    } else {
                                        window.history.back()
                                    }

                                    dispatch(afterKycAction(false))
                                }
                            )
                            return
                        }
                        if (!status) {
                            setLoader(false)

                            showModal(
                                {
                                    type: 'INFO_MODAL',
                                    icon: 'WARNING',
                                    header: strings('mobileMarket.modal.sorry'),
                                    description: strings('mobileMarket.modal.serverRespond')
                                },
                                () => {
                                    hideModal()
                                    if (newState.afterKYC) {
                                        window.history.go(-2)
                                    } else {
                                        window.history.back()
                                    }

                                    dispatch(afterKycAction(false))
                                }
                            )

                            return
                        }
                    } catch (e) {
                        Log.error(`V3.preCheck confirmHandler error ${JSON.stringify(e)}`)
                        Log.captureError(`V3.preCheck confirmHandler error`)

                        setLoader(false)

                        showModal(
                            {
                                type: 'INFO_MODAL',
                                icon: 'WARNING',
                                header: strings('mobileMarket.modal.sorry'),
                                description: strings('mobileMarket.modal.serverRespond')
                            },
                            () => {
                                hideModal()
                                if (newState.afterKYC) {
                                    window.history.go(-2)
                                } else {
                                    window.history.back()
                                }

                                dispatch(afterKycAction(false))
                            }
                        )

                        return
                    }
                }
            }

            const wallet = newState.walletData.wallets
                .find((way) => way.cashbackToken === newState.cashbackToken)
                .accounts.find((account) => account.currencyCode === newState.outCurrency.code)
            const currencyCode =
                typeof wallet !== 'undefined' && typeof wallet.custom !== 'undefined' && wallet.custom && wallet
                    ? wallet.originCode
                    : newState.outCurrency.code
            // const youHave = newState.walletData.wallets.find(wallet => wallet.cashbackToken === newState.cashbackToken).accounts
            //     .find(account => account.currencyCode === newState.outCurrency.code)

            // if (typeof youHave.balanceHodl !== 'undefined' && youHave.balance < youHave.balanceHodl && newState.outCurrency && (Number(newState.provider.equivalentData.outAmount) < youHave.balanceHodl)) {
            //     setLoader(false)
            //     showModal({
            //         type: 'INFO_MODAL',
            //         icon: 'WARNING',
            //         header: strings('mobileMarket.modal.hodlHeader', { coin: `${getCurrencyName(state.outCurrency.code)} (${getCurrencyCode(state.outCurrency.code)})` }),
            //         description: strings('mobileMarket.modal.balanceHodl', { coin: getCurrencyCode(state.outCurrency.code), balance: Math.round(youHave.balanceHodl - Number(youHave.balance)) })
            //     })
            //     return
            // }

            // if (isNeedCameraPermission) {
            //     if (!newState.cameraResult) {
            //         window.ReactNativeWebView.postMessage(JSON.stringify({ checkCamera: true }))
            //         setLoader(false)
            //         return
            //     }
            // }

            if (activeExchangeWay === 'BUY' && !SHOW_TOKEN) {
                if (isToken(newState.walletData, newState.outCurrency.code)) {
                    showAttentionModalZeroBalance(newState)
                    return
                }
            }

            const script = state.paymentData ? injectScript(state.paymentData) : ' '
            const componentDidMount = !state.useCookie ? didMount(state.versionNumber) : mountDid()
            const navigation = !state.useCookie ? navigationState(state.versionNumber) : navState()
            const onMessage = !state.useCookie ? message(state.versionNumber) : msg()

            if (count === 0) {
                const orderData = await createOrder(activeExchangeWay, count, setCount, loader, setLoader, skipPaymentDetailsScreen, null, isNeedKYC)
                if (orderData) {
                    Log.setTag('orderHash', orderData.orderHash)
                    Log.setTag('provider', newState.provider.providerName)

                    const tmpProvider = newState.exchangeWaysArray.filter((way) => way.equivalentData && way.equivalentData.status === 'SUCCESS')

                    if (tmpProvider.length > 1) {
                        const tmpWay = newState.allExchangeWays.find((way) => way.exchangeWayId === tmpProvider[0].exchangeWayId)
                        const selectWay = newState.allExchangeWays.find((way) => way.exchangeWayId === newState.provider.exchangeWayId)

                        if (tmpProvider[0].exchangeWayId !== newState.provider.exchangeWayId) {
                            Log.info(
                                `V3.preCheckScreen.select_provider ${JSON.stringify({
                                    ...newState.provider,
                                    labels: JSON.stringify(selectWay.exchangeWayLabelCodes)
                                })}`
                            )
                            try {
                                analytics().logEvent('V3.preCheckScreen.select_provider', {
                                    ...newState.provider,
                                    labels: selectWay.exchangeWayLabelCodes
                                })
                            } catch (e) {
                                Log.error(`V3.preCheckScreen. error ${JSON.stringify(e)}`)
                                Log.captureError(`V3.preCheckScreen. ERROR`)
                            }
                        }
                        Log.info(
                            `V3.preCheckScreen.best_provider ${JSON.stringify({
                                ...tmpProvider[0],
                                labels: JSON.stringify(tmpWay.exchangeWayLabelCodes)
                            })}`
                        )
                        try {
                            analytics().logEvent('V3.preCheckScreen.best_provider', {
                                ...tmpProvider[0],
                                labels: tmpWay.exchangeWayLabelCodes
                            })
                        } catch (e) {
                            Log.error(`V3.preCheckScreen. error ${JSON.stringify(e)}`)
                            Log.captureError(`V3.preCheckScreen. ERROR`)
                        }
                    }
                    const dataBuy = {
                        orderData: orderData,
                        injectScript: script,
                        currencySelect: currencyCode,
                        didMount: componentDidMount,
                        navigationState: navigation,
                        message: onMessage,
                        limits: JSON.stringify({ limits: null, currencyCode: currencyCode }),
                        trusteeFee: JSON.stringify({
                            trusteeFee: utilsService.cutNumber(newState.provider.equivalentData.trusteeFee.in, 6),
                            currencyCode: newState.inCurrency.symbol
                        }),
                        payway: state.payway
                    }

                    window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ dataBuy }))

                    window.ReactNativeWebView &&
                        window.ReactNativeWebView.postMessage(
                            JSON.stringify({
                                orderData: orderData,
                                injectScript: script,
                                currencySelect: currencyCode,
                                didMount: componentDidMount,
                                navigationState: navigation,
                                message: onMessage,
                                limits: JSON.stringify({ limits: null, currencyCode: currencyCode }),
                                trusteeFee: JSON.stringify({
                                    trusteeFee: utilsService.cutNumber(newState.provider.equivalentData.trusteeFee.in, 6),
                                    currencyCode: newState.inCurrency.symbol
                                }),
                                payway: state.payway
                            })
                        )

                    setTimeout(async () => {
                        setLoader(false)
                    }, 1e3)

                    const partLink = orderData.url.toString().slice(8, 19)

                    Log.info(
                        `V3.preCheckScreen.confirmHandler partLink ${partLink} -> script\n\n\n ${script
                            .toString()
                            .slice(script.toString().indexOf(partLink), script.length)}`
                    )
                    Log.captureInfo(`V3.preCheckScreen.confirmHandler ${activeExchangeWay} createOrder SUCCESS`)
                }
                setCount(0)
            }
        } else {
            setLoader(true)

            if (activeExchangeWay === 'SELL' || activeExchangeWay === 'EXCHANGE') {
                if (isToken(state.walletData, state.inCurrency.code)) {
                    const tokenBlockchain = getTokenNet(state.walletData, state.inCurrency.code)

                    const nativeCoin = newState.walletData.wallets
                        .find((wallet) => wallet.cashbackToken === newState.cashbackToken)
                        .accounts.find((account) => account.currencyCode === tokenBlockchain)

                    if (nativeCoin && Number(nativeCoin?.prettyBalanceRaw || nativeCoin?.balance) <= 0) {
                        setLoader(false)
                        showModal({
                            type: 'INFO_MODAL',
                            icon: 'WARNING',
                            header: strings('mobileMarket.modal.attention'),
                            description: strings('mobileMarket.modal.notNativeCoinBalance', { coin: getCurrencyCode(nativeCoin.currencyCode) })
                        })
                        return
                    }
                }
            }

            if (activeExchangeWay !== 'SELL' && !SHOW_TOKEN) {
                if (isToken(newState.walletData, newState.outCurrency.code)) {
                    showAttentionModalZeroBalance(newState)
                    return
                }
            }

            const youHave = newState.walletData.wallets
                .find((wallet) => wallet.cashbackToken === newState.cashbackToken)
                .accounts.find((account) => account.currencyCode === newState.inCurrency.code)
            const haveCoin = newState.walletData.wallets
                .find((wallet) => wallet.cashbackToken === newState.cashbackToken)
                .accounts.find((account) => account.currencyCode === newState.outCurrency.code)

            const youHaveOriginCode =
                typeof youHave !== 'undefined' && typeof youHave.custom !== 'undefined' && youHave.custom
                    ? youHave.originCode
                    : newState.inCurrency.code
            const haveCoinOriginCode =
                typeof haveCoin !== 'undefined' && typeof haveCoin.custom !== 'undefined' && haveCoin.custom
                    ? haveCoin.originCode
                    : newState.outCurrency.code

            const balance = youHave ? (Number(youHave.balance) + Number(youHave?.unconfirmed ? youHave?.unconfirmed : 0)).toString() : 0
            Log.info(`V3.preCheck.confirmHandler balance unconfirmed ${youHave?.unconfirmed ? youHave?.unconfirmed : 0}`)
            Log.info(`V3.preCheck.confirmHandler balance ${balance}`)

            if (config.balanceCheck && Number(newState.provider.equivalentData.inAmount) > Number(balance)) {
                setLoader(false)
                showModal({
                    type: 'INFO_MODAL',
                    icon: 'WARNING',
                    header: strings('mobileMarket.modal.sorry'),
                    description: strings('mobileMarket.noMoney', {
                        amount: state.provider.equivalentData.inAmount + ' ' + state.inCurrency.symbol,
                        balance: balance + ' ' + state.inCurrency.symbol
                    })
                })
                return
            }

            if ((activeExchangeWay === 'SELL' || activeExchangeWay === 'EXCHANGE') && !newState.recheckSendAll && state.inCurrency.code === 'ETH') {
                const obj = findOutDestination(newState.walletData, newState.inCurrency.code, newState.cashbackToken)

                window.ReactNativeWebView &&
                    window.ReactNativeWebView.postMessage(
                        JSON.stringify({
                            useAllFunds: {
                                currencyCode: newState.inCurrency.code,
                                address: obj.outDestination
                            }
                        })
                    )
                dispatch(comeBackAction(true))
                return
            }

            // if (newState.useAllFunds && state.inCurrency.code === 'ETH') {
            //     const amountFromProvider = toWei(state.provider.equivalentData.inAmount)
            //     Log.info(`V3.preCheckScreen.amountFromProvider ${amountFromProvider}`)
            //     Log.info(`V3.preCheckScreen.newState.selectedFee ${JSON.stringify(newState.selectedFee)}`)
            //     let diff = amountFromProvider - newState.selectedFee.selectedFee.amountForTx
            //     let feeForTx = newState.selectedFee.selectedFee.feeForTx * 1 + diff
            //     let gasPrice = feeForTx / newState.selectedFee.selectedFee.gasLimit
            //     let gasPriceGwei = toGwei(gasPrice)
            //     Log.info(`V3.preCheckScreen.feeForTx ${feeForTx}`)
            //     Log.info(`V3.preCheckScreen.diff ${diff}`)
            //     Log.info(`V3.preCheckScreen.gasPrice ${gasPrice}`)
            //     Log.info(`V3.preCheckScreen.gasPriceGwei ${gasPriceGwei}`)

            //     if (feeForTx * 1 > newState.selectedFee.selectedFee.feeForTx * 1) {
            //         setLoader(false)
            //         showModal({
            //             type: 'INFO_MODAL',
            //             icon: 'WARNING',
            //             header: strings('mobileMarket.modal.attention'),
            //             description: strings('mobileMarket.modal.correctedAmount', { symbol: newState.inCurrency.symbol }),
            //         })
            //         Log.captureError(`V3.preCheckScreen.amount not valid`)
            //         return
            //     }
            // }

            if (
                (activeExchangeWay === 'SELL' || activeExchangeWay === 'EXCHANGE') &&
                newState.useAllFunds &&
                !newState.coinAllbalance &&
                mainCoin.includes(state.inCurrency.code)
            ) {
                setLoader(false)
                showModal(
                    {
                        type: 'YES_NO_MODAL',
                        icon: 'WARNING',
                        header: strings('mobileMarket.modal.attention'),
                        description: strings('mobileMarket.modal.coinAllBalance', { coin: newState.inCurrency.code }),
                        noCallback: async () => {
                            dispatch(coinAllbalanceAction(false))
                            hideAnimation('YesNoModal', hideModal)
                            await confirmHandler()
                            return
                        }
                    },
                    async () => {
                        dispatch(coinAllbalanceAction(true))
                        await confirmHandler()
                    }
                )
                return
            }

            setLoader(true)

            const limits = await limitScan(state)
            const minLimit = limitForKsu(activeExchangeWay, limits || [], newState.provider)

            const dataSend = {}
            dataSend.useAllFunds = newState.useAllFunds
            dataSend.comment = newState.comment
            dataSend.providerType = newState.provider.rateType
            dataSend.originData = JSON.stringify(newState.originalData)
            dataSend.limits = JSON.stringify({ limits: minLimit, currencyCode: youHaveOriginCode })
            dataSend.trusteeFee = JSON.stringify({
                trusteeFee: utilsService.cutNumber(newState.provider.equivalentData.trusteeFee.out, 6),
                currencyCode: getCurrencyCode(haveCoinOriginCode)
            })
            dataSend.outCurrency = haveCoinOriginCode
            dataSend.outDestination = activeExchangeWay === 'SELL' ? newState.paymentData?.number || null : newState.outDestination
            dataSend.exchangeWayType = activeExchangeWay
            dataSend.payway = state.payway || null
            if (
                newState.useAllFunds &&
                (newState.inCurrency.code === 'ETH' ||
                    (isToken(newState.walletData, newState.inCurrency.code) &&
                        getTokenNet(newState.walletData, newState.inCurrency.code) &&
                        getTokenNet(newState.walletData, newState.inCurrency.code) === 'ETH'))
            ) {
                dataSend.feeData = JSON.stringify(newState.selectedFee) || null
            }

            if (newState.order) {
                Log.setTag('orderHash', newState.order.orderHash)
                Log.setTag('provider', newState.provider.providerName)

                const tmpProvider = newState.exchangeWaysArray.filter((way) => way.equivalentData && way.equivalentData.status === 'SUCCESS')

                if (tmpProvider.length > 1) {
                    const tmpWay = newState.allExchangeWays.find((way) => way.exchangeWayId === tmpProvider[0].exchangeWayId)
                    const selectWay = newState.allExchangeWays.find((way) => way.exchangeWayId === newState.provider.exchangeWayId)

                    if (tmpProvider[0].exchangeWayId !== newState.provider.exchangeWayId) {
                        Log.info(
                            `V3.preCheckScreen.select_provider ${JSON.stringify({
                                ...newState.provider,
                                labels: JSON.stringify(selectWay.exchangeWayLabelCodes)
                            })}`
                        )
                        try {
                            analytics().logEvent('V3.preCheckScreen.select_provider', {
                                ...newState.provider,
                                labels: selectWay.exchangeWayLabelCodes
                            })
                        } catch (e) {
                            Log.error(`V3.preCheckScreen. error ${JSON.stringify(e)}`)
                            Log.captureError(`V3.preCheckScreen. ERROR`)
                        }
                    }
                    Log.info(
                        `V3.preCheckScreen.best_provider ${JSON.stringify({
                            ...tmpProvider[0],
                            labels: JSON.stringify(tmpWay.exchangeWayLabelCodes)
                        })}`
                    )
                    try {
                        analytics().logEvent('V3.preCheckScreen.best_provider', {
                            ...tmpProvider[0],
                            labels: tmpWay.exchangeWayLabelCodes
                        })
                    } catch (e) {
                        Log.error(`V3.preCheckScreen. error ${JSON.stringify(e)}`)
                        Log.captureError(`V3.preCheckScreen. ERROR`)
                    }
                }

                Log.captureInfo(`V3.preCheckScreen.confirmHandler ${activeExchangeWay} createOrder SUCCESS`)
                dataSend.memo = newState.order.destinationTag || false
                dataSend.orderHash = newState.order.orderHash
                dataSend.amount = newState.order.amount
                dataSend.currencyCode = newState.order.inCurrencyCode === youHaveOriginCode ? newState.order.inCurrencyCode : youHaveOriginCode
                dataSend.address = newState.order.address
                dataSend.outAmount = newState.provider.equivalentData.outAmount

                Log.info(`V3.preCheckScreen.confirmHandler orderData\n\n\n ${JSON.stringify(newState.order)}`)
                Log.info(`V3.preCheckScreen.confirmHandler dataSend ${JSON.stringify({ dataSend })}`)
                window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ dataSend }))
                setTimeout(async () => {
                    setLoader(false)
                }, 3e3)

                if (Number(newState.versionNumber) > 836 || newState.versionNumber === 'VERSION') {
                    setTimeout(async () => {
                        window.history.back()
                        dispatch(recheckSendAllAction(false))
                        dispatch(comeBackAction(false))
                    }, 1e3)
                }
            } else {
                // if (activeExchangeWay === 'EXCHANGE') {
                //     if (typeof haveCoin.balanceHodl !== 'undefined' && haveCoin.balance === 0 && newState.outCurrency && (Number(newState.provider.equivalentData.outAmount) < haveCoin.balanceHodl)) {
                //         setLoader(false)
                //         showModal({
                //             type: 'INFO_MODAL',
                //             icon: 'WARNING',
                //             header: strings('mobileMarket.modal.hodlHeader', { coin: `${getCurrencyName(state.outCurrency.code)} (${getCurrencyCode(state.outCurrency.code)})` }),
                //             description: strings('mobileMarket.modal.balanceHodl', { coin: getCurrencyCode(state.outCurrency.code), balance: haveCoin.balanceHodl, name: getCurrencyName(state.outCurrency.code) })
                //         })
                //         return
                //     }
                // }

                if (count === 0) {
                    const orderData = await createOrder(activeExchangeWay, count, setCount, loader, setLoader, skipPaymentDetailsScreen, feeTrx)
                    if (orderData) {
                        Log.setTag('orderHash', orderData.orderHash)
                        Log.setTag('provider', newState.provider.providerName)

                        const tmpProvider = newState.exchangeWaysArray
                            ? newState.exchangeWaysArray.filter((way) => way.equivalentData && way.equivalentData.status === 'SUCCESS')
                            : []

                        if (tmpProvider.length > 1) {
                            const tmpWay = newState.allExchangeWays.find((way) => way.exchangeWayId === tmpProvider[0].exchangeWayId)
                            const selectWay = newState.allExchangeWays.find((way) => way.exchangeWayId === newState.provider.exchangeWayId)

                            if (tmpProvider[0].exchangeWayId !== newState.provider.exchangeWayId) {
                                Log.info(
                                    `V3.preCheckScreen.select_provider ${JSON.stringify({
                                        ...newState.provider,
                                        labels: JSON.stringify(selectWay.exchangeWayLabelCodes)
                                    })}`
                                )
                                try {
                                    analytics().logEvent('V3.preCheckScreen.select_provider', {
                                        ...newState.provider,
                                        labels: selectWay.exchangeWayLabelCodes
                                    })
                                } catch (e) {
                                    Log.error(`V3.preCheckScreen. error ${JSON.stringify(e)}`)
                                    Log.captureError(`V3.preCheckScreen. ERROR`)
                                }
                            }
                            Log.info(
                                `V3.preCheckScreen.best_provider ${JSON.stringify({
                                    ...tmpProvider[0],
                                    labels: JSON.stringify(tmpWay.exchangeWayLabelCodes)
                                })}`
                            )
                            try {
                                analytics().logEvent('V3.preCheckScreen.best_provider', {
                                    ...tmpProvider[0],
                                    labels: tmpWay.exchangeWayLabelCodes
                                })
                            } catch (e) {
                                Log.error(`V3.preCheckScreen. error ${JSON.stringify(e)}`)
                                Log.captureError(`V3.preCheckScreen. ERROR`)
                            }
                        }

                        Log.captureInfo(`V3.preCheckScreen.confirmHandler ${activeExchangeWay} createOrder SUCCESS`)

                        dataSend.memo = orderData.destinationTag || false
                        dataSend.orderHash = orderData.orderHash
                        dataSend.amount = orderData.amount
                        dataSend.currencyCode = youHaveOriginCode
                        dataSend.address = orderData.address
                        dataSend.outAmount = state.provider.equivalentData.outAmount
                        dataSend.apiRaw = typeof orderData.dexOrderData !== 'undefined' ? orderData : null

                        if (orderData?.url) {
                            dataSend.url = orderData?.url || null
                            dataSend.didMountSell = didMountSell()
                            dataSend.navigationSell = navigationStateSell()
                        }

                        if (!orderData.address && !dataSend.url && !orderData.dexOrderData) {
                            showModal(
                                {
                                    type: 'INFO_MODAL',
                                    icon: 'WARNING',
                                    header: strings('mobileMarket.modal.attention'),
                                    description: config.showError ? 'ERROR_GET_ADDRESS' : strings('mobileMarket.modal.createOrderError')
                                },
                                () => {
                                    hideModal()
                                }
                            )
                            setLoader(false)
                            Log.error(`V3.preCheckScreen. createOrder ERROR_GET_ADDRESS`)
                            Log.captureError(`V3.preCheckScreen. createOrder ERROR`)
                            return
                        }

                        Log.info(`V3.preCheckScreen.confirmHandler orderData\n\n\n ${JSON.stringify(orderData)}`)
                        Log.info(`V3.preCheckScreen.confirmHandler dataSend ${JSON.stringify({ dataSend })}`)
                        window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ dataSend }))
                        setTimeout(async () => {
                            setLoader(false)
                        }, 3e3)

                        if (Number(state.versionNumber) > 836 || state.versionNumber === 'VERSION') {
                            setTimeout(async () => {
                                window.history.back()
                                dispatch(recheckSendAllAction(false))
                                dispatch(comeBackAction(false))
                            }, 1e3)
                        }
                    }
                    setCount(0)
                }
            }
        }
    }

    return (
        <Content background={theme.main.bg} style={{ zIndex: 1500, overflow: 'none' }}>
            <TopNavigation text='PRE-CHECK' leftType='back' leftAction={leftHandler} />
            <Wrapper contentStyle={{ overflow: 'scroll' }} scrollStyle={{ height: '100%' }}>
                {loader && <Loader />}
                <div style={{ marginTop: 24 }}>
                    {state.provider.rateType === 'FIXED' ? (
                        <div style={{ marginBottom: 12 }}>
                            <NotificationMsg
                                title={strings('mobileMarket.modal.attention')}
                                description={strings('mobileMarket.fixedTimeNotification')}
                                theme={theme}
                            />
                        </div>
                    ) : null}
                    <CheckData
                        data={true}
                        name={strings('mobileMarket.youGive') + ':'}
                        value={
                            (feeTrx ? state.provider.equivalentData.inAmount - feeTrx : state.provider.equivalentData.inAmount) +
                            ' ' +
                            state.provider.inCurrency.symbol
                        }
                        codeName={getCurrencyName(state.inCurrency.code) || state.inCurrency.name}
                    />
                    <div className={classes.main__wrapper}>
                        <CheckData
                            condition={state.provider.equivalentData.exchangeRate}
                            name={
                                strings('mobileMarket.rate') +
                                ` 1 ${
                                    state.provider.exchangeRateSide === 'OUT'
                                        ? state.provider.inCurrency.symbol
                                        : state.provider.exchangeRateSide === 'IN'
                                        ? state.provider.outCurrency.symbol
                                        : state.provider.inCurrency.symbol
                                }`
                            }
                            value={`${state.provider.equivalentData.exchangeRate} ${
                                state.provider.exchangeRateSide === 'OUT'
                                    ? state.provider.outCurrency.symbol
                                    : state.provider.exchangeRateSide === 'IN'
                                    ? state.provider.inCurrency.symbol
                                    : state.provider.outCurrency.symbol
                            }`}
                            style={{ paddingBottom: 16 }}
                        />
                        <CheckData
                            condition={state.provider.rateType}
                            name={strings('mobileMarket.rateType.type')}
                            value={strings(`mobileMarket.rateType.${state.provider.rateType.toLowerCase()}`)}
                            style={{ paddingBottom: 16 }}
                        />
                        {isFeesIncludedRate === true ? (
                            // <div className={classes.empty}></div>
                            <div style={{ marginLeft: -16, marginRight: -16 }}>
                                <Message theme={theme} text={strings('mobileMarket.includedFees')} />
                            </div>
                        ) : (
                            <>
                                <CheckData
                                    condition={state.provider.equivalentData.providerFee.in}
                                    name={
                                        strings(activeExchangeWay === 'BUY' ? 'mobileMarket.bankFee' : 'mobileMarket.providerNetworkFee') +
                                        ' ' +
                                        getBankFeeString(feeData)
                                    }
                                    value={
                                        utilsService.cutNumber(state.provider.equivalentData.providerFee.in, 6) +
                                        ' ' +
                                        (activeExchangeWay === 'BUY' ? state.provider.inCurrency.symbol : state.provider.outCurrency.symbol)
                                    }
                                    style={{ paddingBottom: 16 }}
                                />
                                <CheckData
                                    condition={state.provider.equivalentData.providerFee.out}
                                    name={strings('mobileMarket.providerFee') + ' ' + getNetworkFeeString(feeData)}
                                    value={
                                        state.provider.equivalentData.providerFee.out +
                                        ' ' +
                                        (activeExchangeWay === 'BUY' ? state.provider.inCurrency.symbol : state.provider.outCurrency.symbol)
                                    }
                                    style={{ paddingBottom: 16 }}
                                />
                                <CheckData
                                    condition={validValue(getTrusteeFeeAmount())}
                                    name={strings('mobileMarket.trusteeFee') + ' ' + getTrusteeFeeString(feeData)}
                                    value={getTrusteeFeeAmount()}
                                    style={{ paddingBottom: 16 }}
                                />
                                {/* {activeExchangeWay === 'SELL' && state.inCurrency.code === 'TRX' && state.useAllFunds && 
                                <CheckData condition={feeTrx} name={strings('mobileMarket.trusteeFeeTrx')} value={feeTrx + ' ' + getCurrencyCode(state.inCurrency.code)} />} */}
                            </>
                        )}
                    </div>
                    <Line width='70%' />
                    <CheckData
                        data={true}
                        out={true}
                        name={strings('mobileMarket.youExpect') + ':'}
                        value={
                            (state.provider.rateType === 'FIXED' ? '=' : '~') +
                            ' ' +
                            state.provider.equivalentData.outAmount +
                            ' ' +
                            state.provider.outCurrency.symbol
                        }
                        codeName={getCurrencyName(state.provider.outCurrency.code) || state.provider.outCurrency.name}
                        style={{ marginTop: 32 }}
                    />
                    <div className={classes.main__wrapper}>
                        <CheckData
                            condition={state.walletName}
                            name={strings('mobileMarket.walletName')}
                            value={state.walletName}
                            style={{ paddingBottom: 16 }}
                        />
                        <CheckData
                            condition={activeExchangeWay !== 'SELL' && state.outDestination}
                            name={strings('mobileMarket.destinationAddress')}
                            value={state.outDestination ? state.outDestination.slice(0, 6) + '...' + state.outDestination.slice(-4) : null}
                            style={{ paddingBottom: 16 }}
                        />
                        {skipPaymentDetailsScreen ? null : (
                            <>
                                {activeExchangeWay !== 'EXCHANGE' && state.payway && state.paymentData && state.paymentData?.number && (
                                    <>
                                        <CheckData
                                            condition={
                                                state.payway &&
                                                state.paymentData &&
                                                state.paymentData.cardName &&
                                                state.paymentData.cardName.toString()?.toLowerCase() !== 'null'
                                                    ? true
                                                    : false
                                            }
                                            name={state.payway === 'CARD' ? strings('mobileMarket.nameCard') : strings('mobileMarket.nameAccount')}
                                            value={state.paymentData.cardName}
                                            style={{ paddingBottom: 16 }}
                                        />
                                        <CheckData
                                            condition={state.payway && state.paymentData && state?.paymentData?.number ? true : false}
                                            name={
                                                state.payway === 'MOBILE_PHONE' || state.payway === 'QIWI'
                                                    ? strings('mobileMarket.mobilePhone')
                                                    : state.payway === 'CARD'
                                                    ? strings('mobileMarket.cardNumber')
                                                    : strings('mobileMarket.accountNumber')
                                            }
                                            value={state?.paymentData?.number.slice(0, 4) + ' **** ' + state?.paymentData?.number.slice(-4)}
                                            style={{ paddingBottom: 16 }}
                                        />
                                    </>
                                )}
                                {state.payway === 'ADVCASH' && activeExchangeWay !== 'EXCHANGE' && (
                                    <CheckData
                                        condition={state.paymentData && state.paymentData.cardEmail ? state.paymentData.cardEmail : ''}
                                        name={strings('mobileMarket.email')}
                                        value={state.paymentData.cardEmail}
                                        style={{ paddingBottom: 16 }}
                                    />
                                )}
                            </>
                        )}
                        <CheckData
                            condition={state.provider}
                            name={strings('mobileMarket.provider')}
                            value={getProviderName(state.provider.providerName)}
                            style={{ paddingBottom: 16 }}
                        />
                    </div>
                </div>
                <Button
                    id='confirmBtn'
                    condition={count === 0 ? true : false}
                    style={{ bottom: '24px', position: 'fixed', width: 'calc(100% - 32px)', maxWidth: 468 }}
                    handler={confirmHandler}>
                    {strings(activeExchangeWay === 'BUY' ? 'mobileMarket.acceptPay' : 'mobileMarket.acceptPrepare')}
                </Button>
                {modalState.show && <Modal />}
            </Wrapper>
        </Content>
    )
}

export default React.memo(RenderPreCheckScreen)
