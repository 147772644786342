import { SHOW_MODAL, HIDE_MODAL, INIT } from '../type'

const INITIAL_STATE = {
    show: false,
    data: null,
    callback: null
}

const modalReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case INIT:
            return {
                ...state
            }
        case SHOW_MODAL:
            return {
                ...state,
                show: true,
                data: action.data,
                callback: action.callback
            }
        case HIDE_MODAL:
            return {
                ...state,
                show: false,
                data: action.data,
                callback: action.callback
            }

        default:
            return {
                ...state
            }
    }
}

export default modalReducer
