import React, { useState, useEffect } from 'react'
import queryString from 'query-string'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import Cookies from 'js-cookie'

import Wrapper from 'components/ScreenWrapper/Wrapper'
import TopNavigation from 'components/TopNavigation/TopNavigation'
import countryArray from 'components/json/country-codes.json'
import regionForCash from 'components/json/regionForCash.json'
import Input from 'components/Input/Input'
import Icon from 'components/Icon/Icon'
import Switch from 'components/Switch/Switch'
import Button from 'components/Button/Button'
import paywayName from 'components/payways/paywayName'
import { Content } from 'components/wrapperScreen/wrapper.css'

import { strings } from 'i18n'

import validation from 'services/ValidationService'
import Log from 'services/sentry/Log'

import store from 'redux/index'
import {
    addPaymentDetailsAction,
    addPaymentDetailsArrayAction,
    paymentDataAction,
    searchValueCountryAction
} from 'redux/AppStores/MobileMarket/action'
import { showScreenModal } from 'redux/AppStores/ScreenModalStore/action'
import { showModal } from 'redux/AppStores/ModalStore/action'

import Modal from 'modules/newModal/MainModal'
import ScreenModal from 'modules/ScreenModal/MainModal'

import { getThemeModeStyles } from 'theme/useDarkMode'
import ManageDiv from './manage.css'
import { getPlaceholder } from './helper'

const getCountryISO3 = require('country-iso-2-to-3')

const { dispatch } = store

let mobileCountry

const cardFormat = (value) => {
    let v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
    let matches = v.match(/\d{4,20}/g)
    let match = (matches && matches[0]) || ''
    let parts = []
    for (let i = 0, len = match.length; i < len; i += 4) {
        parts.push(match.substring(i, i + 4))
    }
    if (parts.length) {
        return parts.join(' ')
    } else {
        return value
    }
}

const ManagementPaymentDetails = () => {
    const state = store.getState().mobileMarket
    const modalState = store.getState().modalStore
    const modalScreenState = store.getState().modalScreenStore

    const theme = getThemeModeStyles()

    const url = window.location.href
    const urlParamsString = url.slice(url.indexOf('?') + 1)
    const urlParamsObj = queryString.parse(urlParamsString)
    let item = urlParamsObj.item || null
    item = JSON.parse(item) || null
    let action = urlParamsObj.action || null
    let index = urlParamsObj.index || null
    let typePayment = urlParamsObj.type || null

    let numberPayment
    if (item) {
        if (item.type.toUpperCase() === 'QIWI' || item.type.toUpperCase() === 'MOBILE_PHONE' || item.type.toUpperCase() === 'CASH') {
            if (item.number.indexOf('+') === -1) {
                numberPayment = '+' + item.number.trim()
            } else {
                numberPayment = item.number
            }
        } else {
            numberPayment = item.number
        }
    }

    const [cardCountryCode, setCardCountryCode] = useState(item ? item.countryCode : null)
    const [name, setName] = useState(item ? item.cardName : null)
    const [number, setNumber] = useState(item ? numberPayment : null)
    const [expirationDate, setExpirationDate] = useState(item ? item.expirationDate : null)
    const [type, setType] = useState(item ? item.type : null)
    const [value, setValue] = useState(item ? (item.walletHash && item.walletHash.toUpperCase() !== 'NULL' ? true : false) : null)
    const [inValid, setValid] = useState(false)
    const [checkP2P, setCheckP2P] = useState(null)

    const [region, setRegion] = useState(null)
    const [city, setCity] = useState(null)

    const [real, setReal] = useState(false)
    const [email, setEmail] = useState(item ? item.cardEmail : null)

    const [dateValid, setDateValid] = useState(true)

    const walletNumber = state?.walletData?.wallets?.length
    const walletName = state?.walletData?.wallets?.find((way) => way?.cashbackToken === state?.cashbackToken)?.walletName

    const paymentHandler = (paymentNumber) => {
        paymentNumber = paymentNumber?.toString().replace(/\s/g, '')
        if (typePayment === 'ADVCASH' || typePayment === 'PERFECT_MONEY' || typePayment === 'CAPITALIST') {
            if ((state.inCurrency.code === 'RUB' || state.outCurrency.code === 'RUB') && paymentNumber && paymentNumber.indexOf('R') === -1) {
                setNumber('R' + paymentNumber)
            } else if ((state.inCurrency.code === 'USD' || state.outCurrency.code === 'USD') && paymentNumber && paymentNumber.indexOf('U') === -1) {
                setNumber('U' + paymentNumber)
            } else if ((state.inCurrency.code === 'EUR' || state.outCurrency.code === 'EUR') && paymentNumber && paymentNumber.indexOf('E') === -1) {
                setNumber('E' + paymentNumber)
            } else if (
                (state.inCurrency.code === 'UAH' || state.outCurrency.code === 'UAH') &&
                paymentNumber &&
                paymentNumber.indexOf('H') === -1 &&
                typePayment === 'ADVCASH'
            ) {
                setNumber('H' + paymentNumber)
            } else {
                setNumber(paymentNumber.toString())
            }
        } else if (typePayment === 'PAYEER' && paymentNumber && paymentNumber.indexOf('P') === -1) {
            setNumber('P' + paymentNumber)
        } else if (typePayment === 'WEBMONEY' && paymentNumber && paymentNumber.indexOf('Z') === -1) {
            setNumber('Z' + paymentNumber)
        } else if (typePayment === 'QIWI') {
            setNumber(paymentNumber.toString().indexOf('+') !== -1 ? paymentNumber.toString() : `+${paymentNumber}`)
        } else {
            setNumber(paymentNumber.toString())
        }
    }

    const mobileHandler = (value, country) => {
        value = value.toString().replace(/\s/g, '')
        setNumber(value.toString().indexOf('+') !== -1 ? value.toString() : `+${value}`)
        mobileCountry = getCountryISO3(country.countryCode ? country.countryCode.toUpperCase() : null)
    }

    const managePayment = () => {
        if (typePayment === 'ADVCASH') {
            if (!validation('EMAIL', email?.toLowerCase())) {
                setReal(true)
                return
            } else {
                setReal(false)
            }
        }

        if (typePayment === 'CARD') {
            if (!dateValid) {
                return
            }
        }

        if (action === 'ADD') {
            const card = ['visa', 'master', 'maestro']

            const cards =
                typeof state.walletData.cards !== 'undefined'
                    ? state.walletData.cards.map((item) => ({ number: item.number, type: card.includes(item.type) ? 'card' : item.type }))
                    : []
            if (cards.map((item) => JSON.stringify(item)).includes(JSON.stringify({ number, type: typePayment.toLowerCase() }))) {
                showModal({
                    type: 'INFO_MODAL',
                    icon: 'WARNING',
                    header: strings('mobileMarket.modal.sorry'),
                    description: strings('mobileMarket.modal.card')
                })
                return
            }
        }

        let paymentDetails = {}

        if (typePayment === 'ADVCASH' || typePayment === 'PERFECT_MONEY' || typePayment === 'CAPITALIST') {
            if (number.indexOf('R') !== -1) {
                paymentDetails.currency = 'RUB'
            } else if (number.indexOf('U') !== -1) {
                paymentDetails.currency = 'USD'
            } else if (number.indexOf('E') !== -1) {
                paymentDetails.currency = 'EUR'
            } else if (number.indexOf('H') !== -1 && typePayment === 'ADVCASH') {
                paymentDetails.currency = 'UAH'
            }
        } else if (typePayment === 'MOBILE_PHONE' || typePayment === 'CASH') {
            paymentDetails.currency = countryArray.find((item) => item.countryCode === mobileCountry).currencyCode
        } else if (typePayment === 'PAYEER' || typePayment === 'WEBMONEY') {
            paymentDetails.currency = 'USD'
        } else if (typePayment === 'YANDEX_MONEY') {
            paymentDetails.currency = 'RUB'
        }

        if (typePayment === 'CARD') {
            paymentDetails.number = number
            paymentDetails.cardName = name || null
            paymentDetails.cardVerificationJson = null
            paymentDetails.countryCode = cardCountryCode
            paymentDetails.currency = countryArray.find((item) => item.iso === cardCountryCode).currencyCode
            paymentDetails.expirationDate = expirationDate
            paymentDetails.type = type
            paymentDetails.walletHash = value
                ? state.walletData.wallets.find((wallet) => wallet.cashbackToken === state.cashbackToken).walletHash
                : null
            paymentDetails.actionType = action || null
            paymentDetails.cardCheckStatus = cardCountryCode === '804' ? checkP2P : null
        } else {
            paymentDetails.countryCode = '804'
            paymentDetails.expirationDate = '12/32'
            paymentDetails.number = paymentDetails.number =
                typePayment === 'MOBILE_PHONE' || typePayment === 'QIWI' || typePayment === 'CASH'
                    ? number.indexOf('+') !== -1
                        ? number
                        : `+${number}`
                    : number
            paymentDetails.cardName = name || null
            paymentDetails.type = typePayment ? typePayment.toString()?.toLowerCase() : ''
            paymentDetails.walletHash = value
                ? state.walletData.wallets.find((wallet) => wallet.cashbackToken === state.cashbackToken).walletHash
                : 'NULL'
            paymentDetails.actionType = action || null
            paymentDetails.cardCheckStatus = null
        }

        if (typePayment === 'ADVCASH') {
            paymentDetails.email = email?.toLowerCase()
        }

        if (typePayment === 'CASH') {
            paymentDetails.cardDetailsJson = JSON.stringify({ region, city })
        }

        if (action === 'UPDATE') {
            paymentDetails.id = item.id
        }

        if (state.oneTimeCard === false) {
            if (action === 'UPDATE') {
                Log.info(`V3.managementPaymentDetails managePayment UPDATE ${JSON.stringify(paymentDetails)}`)
                !state.useCookie && window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ updateCard: paymentDetails }))
            } else {
                Log.info(`V3.managementPaymentDetails managePayment ADD ${JSON.stringify(paymentDetails)}`)
                !state.useCookie && window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ cardData: paymentDetails }))
            }
        }

        const tmpArray = typeof state.walletData.cards !== 'undefined' ? state.walletData.cards : []

        if (action === 'UPDATE') {
            tmpArray[Number(index)] = paymentDetails
        } else {
            tmpArray.push(paymentDetails)
        }
        dispatch(addPaymentDetailsAction(tmpArray))

        if (state.useCookie) {
            Cookies.set('paymentData', JSON.stringify(tmpArray), { expires: 7 })
        }

        if (action === 'ADD') {
            // dispatch(paymentDataAction(paymentDetails))
            dispatch(addPaymentDetailsArrayAction(tmpArray))
        }
        window.history.back()
        return true
    }

    const handlerScanCard = (cardData) => {
        if (!cardData) return
        const _cardData = JSON.parse(cardData)
        Log.info(`V3.managementPaymentDetails handlerScanCard addEventListener data ${JSON.stringify(_cardData)}`)
        if (_cardData?.data) {
            setNumber(data?.number || '')
            setExpirationDate(data?.date || '')
            setCardCountryCode(data?.selectedCountry?.key || '')
            setType(data?.type || '')
        }
    }

    const expirationDateHandler = (value) => {
        setDateValid(true)
        setExpirationDate(value)
        if (value.length === 5) {
            let today, someday
            let exMonth = value.toString().split('/')[0]
            let exYear = value.toString().split('/')[1]
            today = new Date()
            someday = new Date()
            someday.setFullYear(20 + exYear, exMonth, 1)

            if (someday < today) {
                setDateValid(false)
                return false
            }
        }
    }

    useEffect(() => {
        if (window.navigator.userAgent.indexOf('Chrome') === -1) {
            window.addEventListener('message', function (event) {
                handlerScanCard(event.data)
            })
        } else {
            document.addEventListener('message', function (event) {
                handlerScanCard(event.data)
            })
        }

        return () => {
            if (window.navigator.userAgent.indexOf('Chrome') === -1) {
                window.removeEventListener('message', function (event) {
                    handlerScanCard()
                })
            } else {
                document.removeEventListener('message', function (event) {
                    handlerScanCard()
                })
            }
        }
    }, [])

    const countryHandler = () => {
        dispatch(searchValueCountryAction(null))
        showScreenModal({
            type: 'SELECT_MODAL',
            data: {
                side: 'COUNTRY',
                search: true,
                setCardCountryCode: setCardCountryCode,
                cardCountryCode
            }
        })
        return
    }

    const handleRegion = () => {
        showScreenModal({
            type: 'SELECT_MODAL',
            data: {
                side: 'REGION',
                region: region,
                setRegion: setRegion,
                setCity: setCity
            }
        })
        return
    }

    const handleRegionCity = () => {
        showScreenModal({
            type: 'SELECT_MODAL',
            data: {
                side: 'REGION_CITY',
                region: region || null,
                setRegion: setRegion,
                city: city,
                setCity: setCity
            }
        })
        return
    }

    const getCondition = () => {
        if (typePayment === 'CARD') return cardCountryCode && number && expirationDate && type && dateValid
        if (typePayment === 'ADVCASH') return email && number && !inValid && !real
        if (typePayment === 'CASH') return number && region && city

        return number && !inValid
    }

    return (
        <>
            <Content background={theme.main.bg}>
                <Wrapper>
                    <TopNavigation text={`${paywayName[state.payway]}`} leftType='back' />
                    <ManageDiv
                        textColor={theme.topNav.color}
                        circleBg={theme.modal.card.circleBg}
                        walletNameColor={theme.topNav.colorIcon}
                        btnBg={theme.main.selector.bg}
                        selectBtn={theme.bottomNav.addBtn}>
                        <div style={{ marginTop: 32 }}>
                            {typePayment && (
                                <div className='description'>{strings(`mobileMarket.paymentDetailsDescription.${typePayment.toLowerCase()}`)}</div>
                            )}
                            {typePayment === 'CARD' ? (
                                <>
                                    <Input
                                        disabled={action === 'UPDATE' ? true : false}
                                        inputMode='decimal'
                                        placeholder={strings('mobileMarket.card.cardNumber')}
                                        icon='SCAN_CARD'
                                        condition={inValid}
                                        setValid={setValid}
                                        handler={setNumber}
                                        setCardCountryCode={setCardCountryCode}
                                        setType={setType}
                                        type='CARD'
                                        value={number ? cardFormat(number) : ''}
                                        setCheckP2P={setCheckP2P}
                                        theme={theme}
                                    />

                                    <div className='inputItem'>
                                        <div className='item'>
                                            <Input
                                                inputMode='decimal'
                                                placeholder={strings('mobileMarket.card.expirationDate')}
                                                style={{ marginTop: 24 }}
                                                handler={expirationDateHandler}
                                                value={expirationDate || ''}
                                                options={{ date: true, datePattern: ['m', 'y'] }}
                                                condition={!dateValid}
                                                theme={theme}
                                            />
                                        </div>
                                        <div className='select' onClick={countryHandler}>
                                            {cardCountryCode
                                                ? countryArray.find((item) => item.iso === cardCountryCode).countryCode
                                                : strings('mobileMarket.card.country')}
                                            <Icon icon='ARROW_DOWN' color='#F7F7F7' size={26} />
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <Input
                                        inputMode={typePayment === 'BANK_TRANSFER' ? 'text' : 'decimal'}
                                        disabled={action === 'UPDATE' ? true : false}
                                        placeholder={typePayment ? getPlaceholder(typePayment, state) : ''}
                                        condition={inValid}
                                        setValid={setValid}
                                        handler={typePayment === 'MOBILE_PHONE' || typePayment === 'CASH' ? mobileHandler : paymentHandler}
                                        typePayment={typePayment}
                                        outCurrency={state.inCurrency.code}
                                        type='PAYMENT_DETAILS'
                                        value={number || ''}
                                        theme={theme}
                                    />
                                    {typePayment === 'ADVCASH' && (
                                        <Input
                                            inputMode='email'
                                            placeholder={strings('mobileMarket.email')}
                                            style={{ marginTop: 24 }}
                                            condition={real}
                                            setValid={setReal}
                                            handler={(value) => {
                                                setEmail(value)
                                                setReal(false)
                                            }}
                                            value={email?.toLowerCase() || ''}
                                            theme={theme}
                                        />
                                    )}
                                    {typePayment === 'CASH' && (
                                        <>
                                            <div className='inputItem'>
                                                <div className='currency__input'>
                                                    <div onClick={handleRegion} className='input__text'>
                                                        {region
                                                            ? strings(
                                                                  `mobileMarket.cash.${regionForCash
                                                                      .find((item) => item.region === region)
                                                                      .region.toLowerCase()}`
                                                              )
                                                            : strings('mobileMarket.selectRegion')}
                                                    </div>
                                                </div>
                                                <div className='currency__button' onClick={handleRegion}>
                                                    <Icon
                                                        icon='ARROW_DOWN'
                                                        color={theme.main.arrow}
                                                        size={36}
                                                        style={{ display: 'flex', marginTop: 6 }}
                                                    />
                                                </div>
                                            </div>
                                            <div className='inputItem'>
                                                <div className='currency__input'>
                                                    <div onClick={handleRegionCity} className='input__text'>
                                                        {city
                                                            ? strings(`mobileMarket.cash.${JSON.parse(city).country.toLowerCase()}`)
                                                            : strings('mobileMarket.selectCity')}
                                                    </div>
                                                </div>
                                                <div className='currency__button' onClick={handleRegionCity}>
                                                    <Icon
                                                        icon='ARROW_DOWN'
                                                        color={theme.main.arrow}
                                                        size={36}
                                                        style={{ display: 'flex', marginTop: 6 }}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                            <Input
                                placeholder={
                                    typePayment === 'CARD' ? strings('mobileMarket.card.cardName') : strings('mobileMarket.card.accountName')
                                }
                                style={{ marginTop: 24 }}
                                handler={setName}
                                value={name && name !== 'null' ? name : ''}
                                id='cardName'
                                theme={theme}
                            />
                            {walletNumber > 1 && (
                                <TransitionGroup>
                                    {state.oneTimeCard === false && (
                                        <CSSTransition
                                            in={state.oneTimeCard}
                                            timeout={1500}
                                            classNames={{
                                                enter: 'oneTimeSwitchEnter',
                                                enterActive: 'oneTimeSwitchEnterActive',
                                                exit: 'oneTimeSwitchExit',
                                                exitActive: 'oneTimeSwitchExitActive'
                                            }}>
                                            <div className='currentWallet__wrapper'>
                                                <div className='wallet__circle'>
                                                    <Icon icon='WALLET' color={theme.topNav.colorIcon} size={26} />
                                                </div>
                                                <div className='wallet__text'>
                                                    <div className='textName'>{strings('mobileMarket.card.useOnly', { wallet: walletName })}</div>
                                                    <div className='textStatus'>
                                                        {!value ? strings('mobileMarket.card.disable') : strings('mobileMarket.card.enable')}
                                                    </div>
                                                </div>
                                                <div className='toggleSwitch'>
                                                    <Switch id={'walletOne'} isOn={value} handleToggle={() => setValue(!value)} theme={theme} />
                                                </div>
                                            </div>
                                        </CSSTransition>
                                    )}
                                </TransitionGroup>
                            )}
                        </div>
                        <Button
                            condition={getCondition()}
                            style={{ bottom: '24px', position: 'fixed', width: 'calc(100% - 32px)' }}
                            handler={managePayment}>
                            {action === 'ADD'
                                ? strings(typePayment === 'CARD' ? 'mobileMarket.addCard' : 'mobileMarket.addAccount')
                                : strings(typePayment === 'CARD' ? 'mobileMarket.updateCard' : 'mobileMarket.updateAccount')}
                        </Button>
                    </ManageDiv>
                </Wrapper>
            </Content>
            {modalState.show && <Modal />}
            {modalScreenState.show && <ScreenModal />}
        </>
    )
}
export default ManagementPaymentDetails
