import styled from 'styled-components'

const CheckListItemDiv = styled.div`
    .check-list-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;

        padding: 10px 0;

        label {
            width: 100%;
            -webkit-tap-highlight-color: transparent;

            font-family: 'Montserrat SemiBold';
            font-style: normal;
            font-size: 17px;
            line-height: 21px;

            color: ${(p) => p.textColor};

            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }

    .check-icon {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 2px solid ${(p) => p.colorBtn};

        display: flex;
        align-items: center;
        justify-content: center;
    }

    input[type='checkbox'] {
        display: none;
    }

    input[type='checkbox'],
    .check-icon {
        border: 2px solid ${(p) => (p.checked ? p.checkedColor : p.colorBtn)};
        background: ${(p) => (p.checked ? p.checkedColor : 'transparent')};
    }
`

export default CheckListItemDiv
