import styled from 'styled-components'

export const Data = styled.div`
    width: calc(100% - 32px);
    display: inline-flex;
    margin-left: 16px;
    margin-right: 16px;

    @media screen and (max-width: 400px) {
        width: calc(100% - 32px);
        margin-left: 16px;
    }

    @media screen and (max-width: 350px) {
        width: 100%;
        margin: 0;
    }

    .data__name {
        display: flex;
        width: 40%;
        font-family: 'Montserrat SemiBold', sans-serif;
        font-style: normal;
        /* font-weight: 600; */
        font-size: 18px;
        line-height: 24px;
        color: ${(p) => p.color};
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: nowrap;

        @media screen and (max-width: 400px) {
            font-size: 16px;
            line-height: 22px;
        }
    }

    .data {
        display: flex;
        justify-content: flex-end;
    }

    .data__value {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        align-self: flex-end;
        text-align: right;
        width: 60%;
        font-family: 'Montserrat Bold', sans-serif;
        font-style: normal;
        /* font-weight: bold; */
        font-size: 20px;
        line-height: 24px;
        color: ${(p) => (p.out ? p.receive : p.amount)};

        @media screen and (max-width: 400px) {
            font-size: 18px;
            line-height: 22px;
        }
    }
`

export const Rate = styled.div`
    .wrapper {
        display: flex;
        width: 100%;
    }

    .post {
        display: flex;
        flex-wrap: wrap;
        font-family: 'SF UI Display Medium', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 1px;
        color: ${(p) => p.color};
        width: 45%;

        @media screen and (max-width: 400px) {
            font-size: 13px;
            line-height: 14px;
        }
    }

    .post__value {
        display: flex;
        align-self: flex-end;
        text-align: right;
        flex-wrap: wrap;
        font-family: 'SF UI Display Bold', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 1px;
        color: ${(p) => p.color};

        @media screen and (max-width: 400px) {
            font-size: 13px;
            line-height: 13px;
        }
    }

    .post__value a {
        display: flex;
        align-self: flex-end;
        text-align: right;
        flex-wrap: wrap;
        font-family: 'SF UI Display Bold', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 1px;
        color: ${(p) => p.color};
        text-decoration: none;
        -webkit-tap-highlight-color: transparent;
    }

    .rate {
        display: flex;
        float: right;
        justify-content: flex-end;
        text-align: right;
        padding-bottom: 2px;
        width: 55%;
    }
`
