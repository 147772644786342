import styled from 'styled-components'

export const ErrorCodeDiv = styled.div`
    .errorWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-right: 16px;
        width: calc(100% - 16px);
    }

    .limitBtn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;
        border: 2px solid ${(p) => p.borderColor};
        box-sizing: border-box;
        border-radius: 8px;
        min-height: 34px;
        font-family: 'SF UI Display Bold';
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 1px;
        padding-left: 10px;
        padding-right: 10px;
        white-space: wrap;
        margin-top: 18px;
        color: ${(p) => p.textColor};
        width: 100%;
        max-width: 60%;
    }

    .providerLimit {
        font-family: 'SF UI Display Bold';
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 1px;
        color: ${(p) => p.textColor};
        margin-right: 10px;
        margin-top: 16px;
        margin-left: 4px;
        /* display: flex; */
        /* flex-wrap: wrap; */
        width: 100%;
        max-width: 100%;
        min-width: 40%;
    }

    .providerError {
        font-family: 'SF UI Display Bold';
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 1px;
        color: ${(p) => p.textColor};
        margin-right: 10px;
        margin-top: 16px;
        margin-left: 4px;
        flex-wrap: wrap;
        word-wrap: break-word;
    }
`

const ProviderDiv = styled.div`
    .wrapper {
        width: 100%;
        min-height: 100px;
        background: ${(p) => p.activeProviderBg};
        box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.1);
        border-radius: 16px;
        margin-bottom: 12px;
        padding-bottom: 16px;
        position: relative;
    }

    .provider__item {
        position: relative;
        width: 100%;
        min-height: 120px;
        background: ${(p) => p.activeProviderBg};
        box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.1);
        border-radius: 16px;
        margin-bottom: 12px;
        padding-bottom: 16px;
    }

    .provider__fail {
        width: 100%;
        min-height: 120px;
        background: ${(p) => p.failProviderBg};
        border-radius: 16px;
        margin-bottom: 12px;
        padding-bottom: 16px;
        position: relative;
    }

    .main__data {
        margin-left: 16px;
    }

    .provider__rate {
        display: flex;
        font-family: 'SF UI Display Semibold';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 1px;
        color: #999999;
        padding-right: 5px;
        width: 100%;
    }

    .tickersIcon {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 17px;
    }

    .rate {
        display: flex;
        font-family: 'Montserrat Bold';
        font-size: 18px;
        line-height: 18px;
        color: ${(p) => p.rateColor};
    }

    .data {
        display: flex;
        flex-direction: column;
        width: calc(100% - 24px);
        min-height: 24px;
        padding-top: 16px;
        margin-left: 4px;
    }

    .provider__amount {
        font-family: 'Montserrat Bold';
        font-style: normal;
        /* font-weight: 700; */
        font-size: 16px;
        line-height: 16px;
        min-height: 16px;
        color: ${(p) => p.amountColor};
        padding-bottom: 4px;
        display: inline-flex;
        flex-wrap: wrap;
        padding-right: 3px;
    }

    .provider__code {
        font-size: 12px;
        font-family: 'Montserrat ExtraBold';
        font-style: normal;
        /* font-weight: 800; */
        line-height: 14px;
        color: ${(p) => p.amountColor};
        align-self: flex-end;
        margin-left: 3px;
        margin-right: 3px;
    }

    .provider__equivalent {
        font-family: 'Montserrat Bold';
        font-style: normal;
        /* font-weight: 700; */
        font-size: 16px;
        line-height: 16px;
        min-height: 16px;
        color: ${(p) => p.amountColor};
        padding-bottom: 4px;
        display: inline-flex;
        flex-wrap: wrap;
        margin-right: 10px;
    }

    .label__wrapper {
        width: calc(100% - 64px);
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .timer__wrapper {
        width: 24px;
        height: 24px;
        position: absolute;
        bottom: 12px;
        right: 12px;
        border-radius: 50%;
        background: transparent;
        border: 2px solid ${(p) => p.timerBorder};
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        z-index: 200;
    }

    .finishTime {
        margin-bottom: 16px;
        width: 100%;
        /* margin-left: -2px; */
        /* margin-top: -2px; */
        top: 0;
        min-height: 100%;
        background: ${(p) => p.failProviderBg};
        border-radius: 13px;
        border: none;
        box-shadow: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        opacity: 0.9;
        position: absolute;
        z-index: 199;
    }

    .textTime {
        width: 75%;
        margin: 0 auto;
        font-family: 'SF UI Display Bold';
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 1px;
        color: ${(p) => p.failTextColor};
    }

    .provider__best {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        background-color: ${(p) => p.bestColor};
        border-radius: 0px 13px;
        height: 21px;
        width: 84px;
        margin-left: 40px;
        position: absolute;
        right: 0;
        top: 0;
        text-align: center;
        padding: 9px 3px 9px 6px;
        font-family: 'Montserrat ExtraBold';
        font-style: normal;
        /* font-weight: 800; */
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: #f7f7f7;

        @media screen and (max-width: 350px) {
            padding: 6px 3px;
            font-size: 11px;
            line-height: 13px;
            letter-spacing: 1.5px;
        }
    }

    .providerError {
        font-family: 'SF UI Display Bold';
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 1px;
        color: ${(p) => p.textColor};
        margin-right: 10px;
        margin-top: 16px;
        margin-left: 4px;
        flex-wrap: wrap;
        word-wrap: break-word;
    }
`

export default ProviderDiv
