import React from 'react'
import { DotsDiv } from './wrap.css'

const Dots = (props) => {
    const { theme, checked, handler } = props

    return (
        <DotsDiv dotBg={theme.paymentScreen.dotsBg} checked={checked}>
            <div className='wrapper' onClick={handler}>
                <div className='dot' />
                <div className='dot' />
                <div className='dot' />
                <div className='dot' />
            </div>
        </DotsDiv>
    )
}

export default Dots
