export function sortWays(arrayWays) {
    let exchangeWaysArray = arrayWays

    if (typeof arrayWays[0].exchangeRate === 'undefined') {
        return [...exchangeWaysArray]
    }

    const side = typeof arrayWays !== 'undefined' && arrayWays.length ? arrayWays[0].exchangeRate.currencySide : null

    switch (side) {
        case 'OUT':
            exchangeWaysArray = exchangeWaysArray.sort((a, b) => b.exchangeRate.exchangeRate - a.exchangeRate.exchangeRate)
            break

        case 'IN':
            exchangeWaysArray = exchangeWaysArray.sort((a, b) => a.exchangeRate.exchangeRate - b.exchangeRate.exchangeRate)
            break

        default:
            return exchangeWaysArray
    }

    return [...exchangeWaysArray]
}
