import config from '../../config/config'

export default function didMount(version) {
    const componentDidMount = `if (true) {
        Log.log('Trade.SMSV3CodeScreen.componentDidMount started');

        that.handleState({
            scriptLoadEnd: tradeWebParam.injectScript,
            navigation: tradeWebParam.navigationState,
            status: 'PENDING',
            currencySelect: tradeWebParam.currencySelect,
            additionalData: {
                close: true
            }
        });

        that.handleStartAnimation();

        Log.log('Trade.SMSV3CodeScreen.componentDidMount created payin');
        const data = {
            link: tradeWebParam.orderData.url,
            orderHash: tradeWebParam.orderData.orderHash
        };

        MarketingEvent.logEvent('exchange_main_screen_buy_goto', { link: data.link, id: data.orderHash + '' });

        Log.log('Trade.SMSV3CodeScreen.handleSubmit.BUY goto', {
            link: tradeWebParam.orderData.url,
            orderHash: tradeWebParam.orderData.orderHash
        });

        that.handleState(data);

        // if (data.link.includes('sci.any.money') && data.link !== 'about:blank') {
        //         setTimeout(async function getOrderInfo() {
        //             try {
        //                 const orderInfo = (await BlocksoftAxios.get('${
            config.backendUrl
        }/order/order-info?orderHash=' + data.orderHash, false, false)).data
        //                 Log.log('Trade.SMSV3CodeScreen.ANYCASH orderInfo', JSON.stringify(orderInfo))
        //                 that.handleState({
        //                     additionalData: {
        //                         ...that.state.additionalData,
        //                         orderStatus: orderInfo.orderStatus.toUpperCase()
        //                     }
        //                 });
        //                 setTimeout(getOrderInfo, 5000); // 5 sec
        //             } catch (error) {
        //                 Log.log('Trade.SMSV3CodeScreen.handleWebViewNavigationStateChange error', JSON.stringify(error));
        //                 setTimeout(getOrderInfo, 5000); // 5 sec
        //             }
        //         }, 0);
        // }

        try {
            Log.log('Trade.SMSV3CodeScreen.componentDidMount timeout0 will be created')
            setTimeout(() => {
                Log.log('Trade.SMSV3CodeScreen.componentDidMount timeout0 will be finished ' + that.state.url)
                if (!that.state.url.includes('pay.receipt-pay.com/cards')
                    && !that.state.url.includes('easybits.io')
                    && !that.state.url.includes('kuna.io/')
                    && !that.state.url.includes('online.contact-sys.com/')
                    && !that.state.url.includes('localhost:4000/backend-invoice?orderHash=')
                    && !that.state.url.includes('exchange.trustee.deals/backend-invoice?orderHash=')
                    && !that.state.url.includes('testexchange.trustee.deals/backend-invoice?orderHash=')
                    && !that.state.url.includes('odysseq.com/order/pay?orderHash=')
                    && !that.state.url.includes('in.abexchange.net')
                ) {
                    Log.log('Trade.SMSV3CodeScreen.componentDidMount timeout0 will be in1')
                    if (that.state.receiptPay) {
                        Log.log('Trade.SMSV3CodeScreen.componentDidMount timeout0 will be in2 timeout status success set')
                        that.handleState({
                            receiptPay: false,
                            status: 'SUCCESS'
                        })
                        if (${Number(version) > 1004 || version === 'VERSION'}) {
                            setExchangeStatus(tradeWebParam.exchangeStatus, {orderHash: that.state.orderHash, status: 'OPEN'})
                        } else {
                            setExchangeStatus(tradeWebParam.exchangeStatus, that.state.orderHash, 'OPEN')
                        }
                        Log.log('Trade.SMSV3CodeScreen setExchangeStatus OPEN')
                    }
                }
            }, 5000)
        } catch (e) {
            Log.log('Trade.SMSV3CodeScreen.componentDidMount timeout0 error ' + e.message)
        }
}`

    return componentDidMount
}
