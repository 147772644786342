import styled from 'styled-components'

const PaywayDiv = styled.div`
    width: calc(100% - 32px);
    min-height: 44px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: 16px;
    margin-right: 16px;

    .label__item {
        width: 100%;
        display: flex;
        font-family: 'Montserrat SemiBold';
        font-size: 18px;
        line-height: 18px;
        color: ${(p) => p.colorText};
        -webkit-tap-highlight-color: transparent;
    }

    .icon__wrapper {
        width: 20%;
        display: flex;
        justify-content: center;
        align-self: center;
    }

    .currency__name {
        display: flex;
        align-self: center;
        margin-left: 16px;
        width: calc(80% - 14px);
    }
`

export default PaywayDiv
