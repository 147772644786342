import styled from 'styled-components'

const SelectorDiv = styled.div`
    .btn {
        height: 50px;
        width: 100%;
        background: ${(p) => p.bg};
        box-shadow: ${(p) => (p.showError ? `0px 0px 20px ${p.boxShadow}` : '0px 6px 10px rgba(64, 64, 64, 0.2)')};
        border-radius: 10px;
        display: flex;
    }

    .btn__shadow {
        height: 50px;
        width: 100%;
        background: #404040;
        box-shadow: ${(p) => (p.showError ? `0px 0px 20px ${p.boxShadow}` : '0px 6px 10px rgba(64, 64, 64, 0.2)')};
        border-radius: 10px;
        display: flex;
    }

    @keyframes zoominoutsinglefeatured {
        0% {
            box-shadow: 0px 0px 20px ${(p) => p.boxShadow};
        }
        50% {
            box-shadow: 0px 0px 70px ${(p) => p.boxShadow};
        }
        100% {
            box-shadow: 0px 0px 20px ${(p) => p.boxShadow};
        }
    }

    .btn__shadow {
        animation: zoominoutsinglefeatured 0.5s 3;
    }

    .text__wrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 5px;
    }

    .text {
        font-family: 'SF UI Display Medium';
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.5px;
        color: ${(p) => p.colorDescription};
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .content {
        width: 80%;
        margin-left: 12px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    .content__text {
        font-family: 'Montserrat Bold';
        font-style: normal;
        font-size: 16px;
        line-height: 16px;
        color: #f7f7f7;
        margin-left: 12px;
    }

    .arrow {
        width: 20%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-right: 12px;
    }

    .select {
        font-family: 'Montserrat Bold';
        font-style: normal;
        font-size: 13px;
        line-height: 16px;
        text-transform: uppercase;
        color: #f7f7f7;
        display: flex;
        justify-content: flex-end;
        align-self: center;
        flex-wrap: wrap;
        max-width: 100%;
        /* word-break: break-all; */
    }

    .token {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        position: relative;

        width: 28px;
        height: 28px;
    }

    .tokenIcon {
        position: absolute;
        bottom: -5px;
        right: -7px;
    }
`

export default SelectorDiv
