import styled from 'styled-components'

const Main = styled.div`
    position: fixed;
    width: 100%;
    max-width: 768px;
    height: 100%;
    bottom: 0;
    left: 0;
    background-color: #f5f5f5;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    z-index: 301;
    align-self: center;
    display: flex;

    &::-webkit-input-placeholder {
        /* Edge */
        font-family: 'Montserrat SemiBold', sans-serif;
        font-style: normal;
        /* font-weight: 600; */
        font-size: 16px;
        letter-spacing: 0.5px;
        color: #999999;
    }

    &:-ms-input-placeholder {
        /* Internet Explorer */
        font-family: 'Montserrat SemiBold', sans-serif;
        font-style: normal;
        /* font-weight: 600; */
        font-size: 16px;
        letter-spacing: 0.5px;
        color: #999999;
    }

    &::placeholder {
        font-family: 'Montserrat SemiBold', sans-serif;
        font-style: normal;
        /* font-weight: 600; */
        font-size: 16px;
        /* line-height: 16px; */
        letter-spacing: 0.5px;
        color: #999999;
    }

    @media screen and (min-width: 768px) {
        left: calc((100% - 768px) / 2);
    }
`

export const ModalWrapper = styled(Main)`
    overflow: scroll;
    height: 100%;
    width: 100%;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;

    background: ${(p) => p.background};

    &::-webkit-scrollbar {
        display: none;
    }
`

export const BodyWrapper = styled.div`
    margin-top: 120px;
    z-index: 303;
    -ms-overflow-style: none;
    scrollbar-width: none;
    width: 100%;
    overflow: scroll;

    ::-webkit-scrollbar {
        display: none;
    }

    .body__main {
        padding-bottom: 150px;
        -ms-overflow-style: none;
        scrollbar-width: none;
        margin-top: 12px;
    }

    .body__main::-webkit-scrollbar {
        display: none;
    }

    input[type='radio'] {
        display: none;
    }

    .modal__shadow {
        height: 60px;
        padding-top: 15px;
        width: 100%;
        left: 0;
        background: linear-gradient(180deg, rgba(245, 245, 245, 0) 0%, #d1d1d1 100%);
        position: fixed;
        bottom: 0;
    }

    .selectors__wrapper {
        margin-top: 48px;
    }

    .filter__wrapper {
        display: flex;
        flex-direction: row;
        max-width: 768px;
        width: 100%;
        margin-top: 30px;
        justify-content: space-between;
        align-self: center;
    }

    .filter__item {
        width: 50%;
        height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        -webkit-tap-highlight-color: transparent;
    }

    .filter__value {
        font-family: 'Montserrat Bold';
        font-style: normal;
        font-size: 14px;
        line-height: 14px;
        text-align: center;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: ${(p) => p.filterColorTextActive};
        display: flex;
    }

    .filter__notValue {
        font-family: 'Montserrat Bold';
        font-style: normal;
        font-size: 14px;
        line-height: 14px;
        text-align: center;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #999999;
        display: flex;
    }

    .filter__point {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: ${(p) => p.filterColorActive};
        margin-top: 4px;
    }

    .category {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-family: 'Montserrat Bold';
        font-style: normal;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        color: ${(p) => p.filterColorActive};
        margin-left: 24px;
        margin-right: 10px;
        margin-bottom: 6px;
        -webkit-tap-highlight-color: transparent;
    }

    .notCard {
        width: 232px;
        height: 64px;
        background: #f7f7f7;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
        border-radius: 14px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 60px;
    }

    .cardDottedLine {
        width: calc(100% - 16px);
        height: calc(100% - 16px);
        border: 2px dotted #5c5c5c;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Montserrat Bold', sans-serif;
        font-style: normal;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #404040;
    }

    .cardText {
        width: 70%;
        text-align: left;
    }

    .addCardList {
        width: 100%;
        margin-top: 40px;
        display: flex;
        justify-content: center;
        font-family: 'Montserrat Bold', sans-serif;
        font-style: normal;
        font-size: 13px;
        line-height: 13px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #5c5c5c;
        -webkit-tap-highlight-color: transparent;
    }

    .minMaxWrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 55px;
        align-self: flex-start;
        align-self: center;
        -webkit-tap-highlight-color: transparent;
    }

    .limitBtn {
        font-family: 'Montserrat Bold';
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 14px;
        text-align: center;
        color: #404040;
        -webkit-tap-highlight-color: transparent;
    }

    .line {
        border-bottom: 1px solid #dadada;
        height: 1px;
        margin: 0 auto;
        width: 80%;
    }

    .amountWrapper {
        display: flex;
        flex-direction: row;
        margin-top: 56px;
        width: 100%;
    }

    .wrapper__amount {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 88%;
    }

    .currency__balance {
        font-family: 'SF UI Display SemiBold';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 19px;
        text-align: center;
        letter-spacing: 1.5px;
        color: #999999;
        padding-bottom: 8px;
    }

    .input__wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 40px;
        position: relative;
        margin-left: 12px;
        margin-right: 6px;
    }

    .input__wrapper input {
        width: 90px;
        max-width: 70%;
        outline: none;
        border: none;
        background: transparent;
        font-family: 'Montserrat Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 40px;
        line-height: 40px;
        font-feature-settings: 'liga' off, 'kern' off;
        color: #404040;
        caret-color: #864dd9;
        -webkit-tap-highlight-color: transparent;
        resize: none;
        text-align: center;
        padding: 0;
        margin-left: 10px;
    }

    .input__wrapper input:focus::placeholder {
        color: transparent;
    }

    .input__wrapper input::-webkit-input-placeholder {
        font-family: 'Montserrat Medium';
        font-style: normal;
        font-size: 40px;
        line-height: 40px;
        letter-spacing: 0.5px;
        color: #404040;
    }

    .input__wrapper input:-ms-input-placeholder {
        font-family: 'Montserrat Medium';
        font-style: normal;
        font-size: 40px;
        line-height: 40px;
        letter-spacing: 0.5px;
        color: #404040;
    }

    .input__wrapper input::placeholder {
        font-family: 'Montserrat Medium';
        font-style: normal;
        font-size: 40px;
        line-height: 40px;
        letter-spacing: 0.5px;
        color: #404040;
    }

    .input__code {
        width: 100px;
        padding-right: 5px;
        font-family: 'Montserrat SemiBold';
        font-size: 20px;
        line-height: 20px;
        font-feature-settings: 'liga' off, 'kern' off;
        color: #404040;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;
        align-self: flex-end;
        -webkit-tap-highlight-color: transparent;
        margin-bottom: 4px;
    }

    .loader {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 10px;
    }

    .paymentWrapper {
        display: flex;
        flex-direction: row;
        max-width: 768px;
        justify-content: space-between;
        margin-top: 7px;
    }

    .detailsWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 50px;
        width: 56%;
        background: #ebebeb;
        box-shadow: inset 0px 0px 16px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
    }

    .detailsAccount {
        font-family: 'Montserrat Bold';
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 16px;
        color: #5c5c5c;
    }

    .changeBtn {
        display: flex;
        justify-content: space-around;
        align-items: center;
        background: #404040;
        box-shadow: 0px 6px 10px rgba(64, 64, 64, 0.2);
        border-radius: 10px;
        height: 50px;
        width: calc(40% - 32px);
        font-family: 'Montserrat Medium';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.5px;
        color: #f7f7f7;
        text-decoration: none;
        padding-left: 16px;
        padding-right: 16px;

        position: relative;
    }

    .changeArrow {
        width: 20px;
    }

    .buttons {
        display: flex;
        flex-direction: row;
        bottom: 24px;
        position: fixed;
        max-width: 768px;
        justify-content: space-between;
        right: 16px;
        left: 16px;
    }

    .button__back {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 64px;
        height: 50px;
        border: none;
        outline: none;
        background: #ebebeb;
        box-shadow: 0 6px 10px rgba(64, 64, 64, 0.15);
        border-radius: 10px;
        margin-right: 16px;
        -webkit-tap-highlight-color: transparent;
    }

    .currency__item {
        width: calc(100% - 48px);
        margin-left: 16px;
    }

    .label__item {
        margin-top: 18px;
        width: 100%;
        display: flex;
        flex-direction: row;
        font-family: 'Montserrat SemiBold';
        font-style: normal;
        /* font-weight: 600; */
        font-size: 18px;
        line-height: 22px;
        -webkit-tap-highlight-color: transparent;
    }

    .label__item input {
        display: none;
    }

    .label__data {
        display: flex;
        flex-direction: column;
        margin-left: 16px;
        width: calc(100% - 32px);
    }

    .label__address {
        font-family: 'Montserrat SemiBold';
        font-style: normal;
        /* font-weight: 600; */
        font-size: 18px;
        line-height: 22px;
        color: ${(p) => p.labelColor};
    }

    .label__walletName {
        font-family: 'SF UI Display Bold';
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 1.5px;
        color: #999999;
        margin-top: 2px;
    }

    .label__addressType {
        display: flex;
        justify-content: flex-end;
        width: 60px;
    }

    .label__newAddress {
        font-family: 'Montserrat SemiBold';
        font-style: normal;
        /* font-weight: 600; */
        font-size: 18px;
        line-height: 22px;
        color: ${(p) => p.labelColor};
        margin-top: 20px;
        margin-left: 32px;
        padding-bottom: 80px;
    }

    .payment__name {
        font-family: 'SF UI Display Bold';
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 13px;
        letter-spacing: 1.75px;
        color: #999999;
        text-transform: uppercase;
        margin-left: 16px;
    }

    .give__get {
        font-family: 'Montserrat Semibold';
        font-style: normal;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.5px;
        color: #5c5c5c;

        text-align: center;
        padding-bottom: 6px;
    }
`

export const CardModalDiv = styled.div`
    /* cardModal */
    .wrapper {
        padding-top: 74px;
        margin-left: 16px;
        margin-right: 16px;
        overflow: scroll;
        height: calc(100% - 150px);
        -ms-user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        user-select: none;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .wrapperKYC {
        padding-top: 74px;
        margin-left: 16px;
        margin-right: 16px;
        overflow: scroll;
        height: calc(100% - 200px);
        -ms-user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        user-select: none;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .wrapper::-webkit-scrollbar {
        display: none;
    }

    .buttons {
        display: flex;
        flex-direction: row;
        bottom: 24px;
        position: fixed;
        max-width: 768px;
        justify-content: space-between;
        right: 16px;
        left: 16px;

        @media screen and (min-width: 768px) {
            left: calc((100% - 736px) / 2);
            max-width: 736px;
        }
    }

    .header {
        font-family: 'Montserrat Bold';
        font-style: normal;
        font-size: 16px;
        line-height: 20px;
        color: ${(p) => p.text};
        margin-bottom: 8px;
        padding-top: 32px;
    }

    .description {
        font-family: 'SF UI Display Regular';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.5px;
        color: ${(p) => p.text};
        margin: 0 auto;
    }

    .example {
        color: ${(p) => p.text};
        width: 100%;
        height: 50px;
        font-family: 'Montserrat SemiBold';
        font-style: normal;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0.5px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
    }

    .img__wrapper {
        max-width: 768px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 40px;
    }

    .takePhoto {
        background: #404040;
        box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        font-family: 'Montserrat SemiBold';
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.5px;
        color: #f7f7f7;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        -webkit-tap-highlight-color: transparent;
        text-align: center;
        z-index: 300;
        width: 100%;
    }

    .inputWrapper {
        margin-top: 40px;
        margin-left: 3px;
        margin-right: 3px;
    }
`
