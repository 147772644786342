import React, { useState } from 'react'
import { getThemeModeStyles } from '../../theme/useDarkMode'
import SettingListItemDiv from './setting.css'

import Icon from '../Icon/Icon'

const getIcon = (props) => {
    const { icon, color } = props

    switch (icon) {
        case 'category':
            return <Icon icon='CATEGORY' size={20} color={color} />
        case 'date':
            return <Icon icon='DATE_RANGE' size={20} color={color} />
        case 'download':
            return <Icon icon='DOWNLOAD_FILE' size={22} color={color} />
        default:
            return <Icon icon='DELETE' size={20} color={color} />
    }
}

const getRightContent = (props) => {
    const { rightContent, color } = props

    switch (rightContent) {
        case 'arrowDown':
            return <Icon icon='ARROW_DOWN' color={color} />
        default:
            return null
    }
}

const SettingListItem = (props) => {
    const { onPress, title, subTitle, last, icon, rightContent, disabled, type, ExtraView, ExtraViewParams } = props

    const [dropDown, setDropDown] = useState(true)

    const theme = getThemeModeStyles()

    const onClick = () => {
        setDropDown(!dropDown)

        onPress && onPress()
    }

    return (
        <SettingListItemDiv circleBg={theme.orderHistory.filter.bgIcon} lineColor={theme.orderHistory.dashColor} textColor={theme.topNav.colorIcon}>
            <div className='list-item' onClick={disabled ? null : onClick}>
                <div className='list-item-wrapper'>
                    <div className='list-item-icon'>{getIcon({ icon, color: theme.topNav.colorIcon })}</div>
                    <div className='list-item-content'>
                        <div className={`list-item-title ${!subTitle ? 'title' : ''} `}>{title}</div>
                        {subTitle && <div className='list-item-subTitle'>{subTitle}</div>}
                    </div>
                </div>
                {rightContent ? (
                    <div className='list-item-right-content'>{getRightContent({ rightContent, color: theme.topNav.colorIcon })}</div>
                ) : null}
            </div>

            {ExtraView ? (
                <div className={`list-extra-view ${dropDown ? 'active' : null} `}>
                    <ExtraView ExtraViewParams={ExtraViewParams} />
                </div>
            ) : null}

            {!last && <div className='list-item-line' />}
        </SettingListItemDiv>
    )
}

export default SettingListItem
