import { strings } from '../i18n'
import Log from './sentry/Log'

export default function time(ms) {
    var floor = Math.floor,
        secs = floor(ms / 1000),
        mins = floor(secs / 60),
        hrs = floor(mins / 60)

    var buff = ''
    if (hrs > 0) {
        buff = buff.concat(hrs + strings('mobileMarket.time.hours'))
    }
    if (mins > 0) {
        buff = buff.concat((mins % 60) + strings('mobileMarket.time.minutes'))
    }
    buff = buff.concat((secs % 60) + strings('mobileMarket.time.seconds'))
    return buff
}

export const diffMinutes = (dt2, dt1) => {
    try {
        Log.info(`diffMinutes dt2 ${dt2} - dt1 ${dt1}`)
        let diff = (dt2.getTime() - dt1.getTime()) / 1000
        diff /= 60
        return Math.abs(Math.round(diff))
    } catch (e) {
        Log.error(`diffMinures error ${JSON.stringify(e)}`)
        return 0
    }
}
