import Log from '../../services/sentry/Log'

export default function indjectScript(cardData, params = null) {
    let cardNumber = null
    let expirationDate = null
    let uniqueParams = params
    let expirationDateTmp = null
    let easybitsCardNumber = null
    let xPayCardNumber = ''
    let nameToPaste = ''
    let phoneNumber = ''

    if (typeof cardData === 'undefined') {
        return ' '
    }

    Log.info(`V3.injectScripts cardData ${JSON.stringify(cardData)}`)

    if (cardData.type === 'visa' || cardData.type === 'mastercard' || cardData.type === 'mir' || cardData.type === 'maestro') {
        cardNumber = cardData.number
        expirationDate = cardData.expirationDate

        expirationDateTmp = typeof expirationDate !== 'undefined' ? expirationDate.split('/') : null

        if (cardNumber && typeof cardNumber !== 'undefined') {
            easybitsCardNumber = cardNumber.trim().match(/.{1,4}/g)
            xPayCardNumber = easybitsCardNumber.join(' ').trim().toString()
        }

        let firstName, lastName

        if (typeof cardData.cardVerificationJson !== 'undefined' && cardData.cardVerificationJson) {
            let data
            try {
                data = JSON.parse(cardData.cardVerificationJson)

                firstName =
                    typeof data.firstName !== 'undefined' ? data.firstName : typeof data.data.firstName !== 'undefined' ? data.data.firstName : ''
                lastName = typeof data.lastName !== 'undefined' ? data.lastName : typeof data.data.lastName !== 'undefined' ? data.data.lastName : ''

                nameToPaste = `${firstName} ${lastName}`
            } catch (e) {
                nameToPaste = ''
            }
        } else {
            nameToPaste = ''
        }
    } else if (cardData.type === 'qiwi') {
        phoneNumber = typeof cardData !== 'undefined' && typeof cardData.number !== 'undefined' ? cardData.number : ''
    }

    const textLink = 'open link'
    // strings('mobileBuy.confirmScreen.openLink')

    //https://sci.any.money/ru/#token=kMPEJte6DPUKpcgDdl7F3Uqypi1KJR_qwl1Nji8Bqxxk4eS4obGwXmAOTTQQsFVqkJy_ ----> anyCash

    // if (window.location.href.includes('getpaidviap2p')) {
    //     setTimeout(() => {
    //         let event = new Event('input');
    //         const cardInput = document.getElementsByName('cardNumber')[0]
    //         cardInput.value = '${cardNumber}';
    //         cardInput.dispatchEvent(event);
    //     }, 300)
    // }

    const script = `
        try {
          window.ReactNativeWebView.postMessage('WebView Loaded ' + window.location + ' webview ' +  document.body.innerHTML.toString().substr(0, 500));
        } catch(error) {
          window.ReactNativeWebView.postMessage('Debug Error ' + window.location + ' webview ' + error.toString());
        }

        try {
                 if((window.location.href).indexOf('easybits.io') !== -1){
                    setTimeout(function() {
                        var input1 = document.getElementById("pan1");
                        var input2 = document.getElementById("pan2");
                        var input3 = document.getElementById("pan3");
                        var input4 = document.getElementById("pan4");
                        var input5 = document.getElementById("month");
                        var input6 = document.getElementById("year");
               
                        input1.value = '${easybitsCardNumber != null ? easybitsCardNumber[0].toString() : ''}';
                        input2.value = '${easybitsCardNumber != null ? easybitsCardNumber[1].toString() : ''}';
                        input3.value = '${easybitsCardNumber != null ? easybitsCardNumber[2].toString() : ''}';
                        input4.value = '${easybitsCardNumber != null ? easybitsCardNumber[3].toString() : ''}';
                        input5.value = '${expirationDateTmp != null ? expirationDateTmp[0].toString() : ''}';
                        input6.value = '${expirationDateTmp != null ? expirationDateTmp[1].toString() : ''}';
                         
                        var ev = new Event('input');
                        input1.dispatchEvent(ev);
                        input2.dispatchEvent(ev);
                        input3.dispatchEvent(ev);
                        input4.dispatchEvent(ev);
                        input5.dispatchEvent(ev);
                        input6.dispatchEvent(ev);
                        
                    }, 5000)
                 }
                 
                /*if((window.location.href).indexOf('mapi.xpay.com.ua') !== -1 && !((window.location.href).indexOf('/check') !== -1)){
                    document.getElementsByName("create[acc]")[0].value = 'Vadymvad@gmail.com';
                    
                    document.getElementById("xpayAgreed").checked = true;
                    
                    var form = document.getElementsByTagName("form")[0];
                    document.getElementById(form.id).submit();
                 }*/

                 if((window.location.href).includes('cardgate.paycore.io') || window.location.href.includes('payment.kuna.io')){
                    var ev = new Event('input');
                    var input1 = document.getElementsByName("number")[0];
                    if (typeof input1 !== 'undefined') {
                      ${xPayCardNumber.length ? ' input1.disabled = true; ' : ''}
                      input1.value = '${xPayCardNumber.length ? xPayCardNumber : ''}';
                      input1.dispatchEvent(ev);
                    } else {
                      
                      var txt  = document.body.innerHTML.toString().substr(0, 500);
                      var orderInfo = document.getElementsByClassName('form__info_order')[0];
                      if (typeof orderInfo !== 'undefined') {
                           setInterval(() => {
                                  var txt  = document.body.innerHTML.toString().substr(0, 5000);
                                  window.ReactNativeWebView.postMessage('WebView Agent ServerError ' + window.location + ' ' + txt);
                           }, 1000)
                      } else {
                          var s = document.createElement('div');
                          var agent = typeof window.navigator.userAgent !== 'undefined' ? window.navigator.userAgent : 'none';
                          s.innerHTML = '<div style="font-size: 34px; padding:10px;"><a href="#" onClick="goToThis(); return false;">${textLink}</a></div>';
                          document.body.innerHTML = '';
                          document.body.appendChild(s);
                          window.ReactNativeWebView.postMessage('WebView Agent Error ' + window.location + ' agent ' + agent + ' ' + txt);
                          goToThis();
                      }
                      
                    }
                    var input2 = document.getElementsByName("date")[0];
                    if (typeof input2 !== 'undefined') {
                       input2.value = '${expirationDateTmp !== null ? expirationDateTmp[0] : ''} / ${
        expirationDateTmp !== null ? expirationDateTmp[1] : ''
    }';
                       input2.dispatchEvent(ev);
                    }
                 }
                 
                 function goToThis() {
                    window.ReactNativeWebView.postMessage('WebView Agent OPEN=' + window.location.href);
                 }
                 
                //   if((window.location.href).includes('mapi.xpay.com.ua')){
                //     document.getElementById("hdr").remove()
                  
                //     document.getElementsByName("create[n]")[0].value = '${xPayCardNumber}';
                //     document.getElementsByName("create[m]")[0].value = '${expirationDateTmp !== null ? expirationDateTmp[0] : ''}';
                //     document.getElementsByName("create[y]")[0].value = '${expirationDateTmp !== null ? expirationDateTmp[1] : ''}'; 
                    
                //     var elem = document.querySelector(".btnGrp");
                //     elem.removeChild(elem.firstElementChild)
                    
                //     document.getElementById("create[a]").checked = true;
                    
                //  }
                 
                if((window.location.href).includes('wallet.advcash.com')){
                    var title365 = document.getElementsByClassName('welcome__desc');
                    if (typeof title365 !== 'undefined' && typeof title365[0] !== 'undefined' && typeof title365[0].firstElementChild !== 'undefined') {
                      title365[0].firstElementChild.innerHTML = ''
                    }
                    title365 =  document.getElementsByClassName('pay__head-details');
                    if (typeof title365 !== 'undefined' && typeof title365[0] !== 'undefined' && typeof title365[0].firstElementChild !== 'undefined') {
                      title365[0].firstElementChild.innerHTML = ''
                    }
                }
                
                if ((window.location.href).includes('pay.api-pay.net')) {
                    let cardForm = document.getElementsByClassName('form-payment__card-number')
                    let cardInput1 = cardForm[0]
                    let cardInput2 = cardForm[1]
                    let cardInput3 = cardForm[2]
                    let cardInput4 = cardForm[3]
                    
                    cardInput1.value = '${easybitsCardNumber != null ? easybitsCardNumber[0].toString() : ''}';
                    cardInput2.value = '${easybitsCardNumber != null ? easybitsCardNumber[1].toString() : ''}';
                    cardInput3.value = '${easybitsCardNumber != null ? easybitsCardNumber[2].toString() : ''}';
                    cardInput4.value = '${easybitsCardNumber != null ? easybitsCardNumber[3].toString() : ''}';
                    
                    cardInput1.readOnly = true
                    cardInput2.readOnly = true
                    cardInput3.readOnly = true
                    cardInput4.readOnly = true

                    let expirationData = document.getElementsByClassName('form-payment__date')
                    let data1 = expirationData[0]
                    let data2 = expirationData[1]
                    
                    data1.value = '${expirationDateTmp !== null ? expirationDateTmp[0] : ''}';
                    data2.value ='${expirationDateTmp !== null ? expirationDateTmp[1] : ''}';

                    data1.disabled = true
                    data2.disabled = true

                    var ev1 = new Event('input', { bubbles: true});
                    cardInput1.dispatchEvent(ev1);
                    cardInput2.dispatchEvent(ev1);
                    cardInput3.dispatchEvent(ev1);
                    cardInput4.dispatchEvent(ev1);
                    data1.dispatchEvent(ev1);
                    data2.dispatchEvent(ev1);

                }

                if((window.location.href).includes('oplata.qiwi.com')){
                    localStorage.clear();
                
                    setTimeout(function () {
                        if (${cardNumber} !== null) {
                            var cardInput = document.getElementById('CardForm-PanInput')
                            var expirationData = document.getElementById('CardForm-ValidThruInput')
                            var btn = document.getElementById('CardForm-Submit')

                            var ev1 = new Event('input', { bubbles: true});

                            var nativeCardInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set;
                            nativeCardInputValueSetter.call(cardInput, '${cardNumber}');
                            cardInput.readOnly = true

                            cardInput.dispatchEvent(ev1);
                            
                            var nativeExpirationDataInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set;
                            nativeExpirationDataInputValueSetter.call(expirationData, '${expirationDate}');
                            expirationData.readOnly = true
                            
                            expirationData.dispatchEvent(ev1)

                        } else {
                            var inputQIWI = document.getElementById("PhoneForm-Input");
                            var btnQIWI = document.getElementById("PhoneForm-Submit");
                            
                            var nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set;
                            nativeInputValueSetter.call(inputQIWI, '${phoneNumber}');
                            
                            var ev2 = new Event('input', { bubbles: true});
                            inputQIWI.dispatchEvent(ev2);
                            
                            btnQIWI.click();
                            
                            setTimeout(function() {
                                var input1QIWI = document.getElementById("radio-PasscodeForm-SmsRadioButton");
                                input1QIWI.click();
                                typeof window != 'undefined' ? window.scrollTo( 0, 0 ) : null;
                            }, 4000)
                        }
                    }, 2000)
                    
                }
                
                if((window.location.href).includes('online.contact-sys.com/payment-form')){
                    setTimeout(function () {
                        
                        var htmlContactSys = document.getElementsByTagName("html")[0];

                        var input1ContactSys = document.getElementsByName("cardNumber")[0];
                        input1ContactSys.disabled = true;
                        var input2ContactSys = document.getElementsByName("expireDate")[0];
                        var input3ContactSys = document.getElementsByName("offer")[0];
                        input3ContactSys.click();
                        var input4ContactSys = document.getElementsByName("cardHolder")[0];
                        var input5ContactSys = document.getElementsByName("cvv2")[0];
                        input4ContactSys.parentNode.style.cssText = "display: none;";
                        
                        // var nativeInputValueSetter1 = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set;
                        // nativeInputValueSetter1.call(input1ContactSys, '${xPayCardNumber}');
                        //
                        //
                        // var nativeInputValueSetter2 = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set;
                        // nativeInputValueSetter2.call(input2ContactSys, '${expirationDate}');
                        //
                        //
                        // var nativeInputValueSetter4 = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set;
                        // nativeInputValueSetter4.call(input4ContactSys, '${nameToPaste}');
                        
                        var ev1 = new Event('input', { bubbles: true});
                        input1ContactSys.dispatchEvent(ev1);
                        input2ContactSys.dispatchEvent(ev1);
                        input4ContactSys.dispatchEvent(ev1);

                        var inputEventContactSys = new Event('input', { bubbles: true});
                        
                        var nativeInputValueSetterInput1ContactSys = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set;
                        nativeInputValueSetterInput1ContactSys.call(input1ContactSys, '${xPayCardNumber}');
                        input1ContactSys.dispatchEvent(inputEventContactSys);

                        var nativeInputValueSetterInput2ContactSys = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set;
                        nativeInputValueSetterInput2ContactSys.call(input2ContactSys, '${expirationDate}');
                        input2ContactSys.dispatchEvent(inputEventContactSys);

                        var nativeInputValueSetterInput4ContactSys = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set;
                        nativeInputValueSetterInput4ContactSys.call(input4ContactSys, '${nameToPaste}');
                        input4ContactSys.dispatchEvent(inputEventContactSys);
                      
                        htmlContactSys.style = "height: auto;"
                       
                        input5ContactSys.focus();

                        var input6ContactSys = document.getElementById('root')
                        input6ContactSys.style.cssText = "height: 0;";
                        input6ContactSys.style.cssText = "height: auto;";
                         
                    }, 3000)
                }

                if((window.location.href).includes('sci.any.money')){
                    let btn = document.getElementsByClassName('a-button-icon button-icon header__button-cancel mobile-navigation-button')[0]
                    if (typeof btn !== 'undefined' || btn !== undefined){ 
                        btn.style.display = 'none'
                    }

                    let arrowOne = document.getElementsByClassName('a-collapse-all collapse-all collapse-all--exist')[0]
                    let arrowTwo = document.getElementsByClassName('ivu-icon ivu-icon-ios-arrow-forward')[0]
                    
                    if (typeof arrowOne !== 'undefined' || arrowOne !== undefined){ 
                        arrowOne.style.display = 'none'
                    }
                    if (typeof arrowTwo !== 'undefined' || arrowTwo !== undefined){ 
                        arrowTwo.style.display = 'none'
                    }

                    let btnSuccess = document.getElementsByClassName('a-button button primary')[0]
                    if (btnSuccess !== undefined) {
                        btnSuccess.click()
                    }
                }

                if ((window.location.href).includes('sandbox-exchange.mrcr.io')) {
                    window.onload = () => {
                        var rateHeader = document.getElementsByClassName('header__crypto-rate');
                        console.log(rateHeader)
                        rateHeader[0].style.display = 'none';
                    }
                }

                if ((window.location.href).includes('payment.whitebit.com')) {

                    try {
                        const header = document.getElementsByClassName('header-container')[0]
                        header.style.display = 'none'
                    } catch (e) {
                        console.log('e', e)
                    }

                    const inputNumber = document.getElementById('number-input')
                    inputNumber.value = '${cardNumber}';
                    inputNumber.readOnly = true

                    const dateInput = document.getElementById('date-input')
                    dateInput.value = '${expirationDateTmp !== null ? expirationDateTmp[0] : ''}/${
        expirationDateTmp !== null ? expirationDateTmp[1] : ''
    }';
                    dateInput.readOnly = true

                    var ev1 = new Event('input', { bubbles: true });
                    inputNumber.dispatchEvent(ev1);
                    dateInput.dispatchEvent(ev1);
                }

                if (window.location.href.includes('mapi.xpay.com') || window.location.href.includes('mapi.xpaydirect.com')) {
                    try {
                        const header = document.getElementsByClassName('cls')[0]
                        header.style.display = 'none'

                        const orderData = document.getElementsByClassName('pmntInf')[0]
                        orderData.style.display = 'none'

                        const checkbox = document.getElementsByClassName('agreedGroup')[0]
                        checkbox.style.display = 'none'

                        const cookie = document.getElementById('jdprCtx')
                        cookie.style.display = 'none'
                    } catch (e) {
                        console.log('e', e)
                    }

                    const inputNumber = document.getElementById('create[n]')
                    inputNumber.value = '${cardNumber}';
                    inputNumber.readOnly = true

                    const dateInput = document.getElementById('create[m]')
                    dateInput.value = '${expirationDateTmp !== null ? expirationDateTmp[0] : ''}';
                    dateInput.readOnly = true

                    const monthInput = document.getElementById('create[y]')
                    monthInput.value = '${expirationDateTmp !== null ? expirationDateTmp[1] : ''}';
                    monthInput.readOnly = true

                    var ev1 = new Event('input', { bubbles: true });
                    inputNumber.dispatchEvent(ev1);
                    dateInput.dispatchEvent(ev1);
                    monthInput.dispatchEvent(ev1);
                }

                if (window.location.href.includes('g50.geoprotocol.io')) {

                    function setNativeValue (element, value) {
                        const valueSetter = Object.getOwnPropertyDescriptor(element, 'value').set;
                        const prototype = Object.getPrototypeOf(element);
                        const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value').set;
                        
                        if (valueSetter && valueSetter !== prototypeValueSetter) {
                            prototypeValueSetter.call(element, value);
                        } else {
                          valueSetter.call(element, value);
                        }
                      }

                    setTimeout(function () {
                        const cardInput = document.getElementsByName('cardNumber')[0]
                        setNativeValue(cardInput, '${cardNumber}')
                        cardInput.readOnly = true

                        const dateInput = document.getElementsByName('expirationDate')[0]
                        setNativeValue(dateInput, '${expirationDateTmp !== null ? expirationDateTmp[0] : ''}/${
        expirationDateTmp !== null ? expirationDateTmp[1] : ''
    }')
                        dateInput.readOnly = true
                        
                        var ev1 = new Event('input', { bubbles: true });
                        cardInput.dispatchEvent(ev1);
                        dateInput.dispatchEvent(ev1);
                    }, 3000)
                }

                if((window.location.href).includes('cardgate.paymega.io')){

                    const cardForm = document.getElementsByClassName('form__number_field')
                    const cardInput = cardForm[0].getElementsByTagName('input')[0]
                    cardInput.value = '${cardNumber}';
                    cardInput.readOnly = true

                    const expirationDate = document.getElementsByName('cc-exp')[0]
                    expirationDate.value = '${expirationDateTmp !== null ? expirationDateTmp[0] : ''}/${
        expirationDateTmp !== null ? expirationDateTmp[1] : ''
    }';
                    expirationDate.readOnly = true

                    var ev1 = new Event('input', { bubbles: true});
                    cardInput.dispatchEvent(ev1);
                    expirationDate.dispatchEvent(ev1);

                }
                 
                 if((window.location.href).includes('pay.4bill.io/cards')){
                    document.getElementsByName("card_number")[0].value = '${cardNumber}';
                    document.getElementsByName("expire_date")[0].value = '${expirationDateTmp !== null ? expirationDateTmp[0] : ''} / ${
        expirationDateTmp !== null ? expirationDateTmp[1] : ''
    }';
                 }
                 
                 setTimeout(function() {
                    typeof window != 'undefined' ? window.scrollTo( 0, 0 ) : null;
                 }, 2000);
                 
                 function findAndReplace(searchText, replacement, searchNode) {
                    if (!searchText || typeof replacement === 'undefined') {
                        // Throw error here if you want...
                        return;
                    }
                    var regex = typeof searchText === 'string' ?
                                new RegExp(searchText, 'g') : searchText,
                        childNodes = (searchNode || document.body).childNodes,
                        cnLength = childNodes.length,
                        excludes = 'html,head,style,title,link,meta,script,object,iframe';
                    while (cnLength--) {
                        var currentNode = childNodes[cnLength];
                        if (currentNode.nodeType === 1 &&
                            (excludes + ',').indexOf(currentNode.nodeName.toLowerCase() + ',') === -1) {
                            arguments.callee(searchText, replacement, currentNode);
                        }
                        if (currentNode.nodeType !== 3 || !regex.test(currentNode.data) ) {
                            continue;
                        }
                        if (typeof currentNode.parentNode !== 'undefined') {
                         currentNode.parentNode.innerHTML = '';
                        }
                    }
                    
                    var kunaIcon = document.getElementsByClassName('kuna-icon')[0];
                    if(typeof kunaIcon !== 'undefined') {
                        kunaIcon.remove();
                        }
                    }
                    
                    setTimeout(() => {
                        findAndReplace('(kuna|Kuna|KUNA|powered)', '')
                    }, 3000)
                    
                    setTimeout(() => {
                        findAndReplace('(kuna|Kuna|KUNA|powered)', '')
                    }, 1000)
                    
                    setTimeout(() => {
                        findAndReplace('(kuna|Kuna|KUNA|powered)', '')
                    }, 700)
                    
                    setTimeout(() => {
                        findAndReplace('(kuna|Kuna|KUNA|powered)', '')
                    }, 500)
                    
                    setTimeout(() => {
                        findAndReplace('(kuna|Kuna|KUNA|powered)', '')
                    }, 400)
                    
                    setTimeout(() => {
                        findAndReplace('(kuna|Kuna|KUNA|powered)', '')
                    }, 300)
                    
                    setTimeout(() => {
                        findAndReplace('(kuna|Kuna|KUNA|powered)', '')
                    }, 200)
                    
                    setTimeout(() => {
                        findAndReplace('(kuna|Kuna|KUNA|powered)', '')
                    }, 100)
            
                     if((window.location.href).indexOf('pay.receipt-pay.com') !== -1){
                        document.getElementsByName("card_number")[0].value = '${cardNumber}';
                        document.getElementsByName("expire_date")[0].value = '${expirationDateTmp !== null ? expirationDateTmp[0] : ''} / ${
        expirationDateTmp !== null ? expirationDateTmp[1] : ''
    }';
                        //document.getElementsByName("cvv")[0].value = '${'000'}';
                        //var form = document.getElementsByTagName("form")[0];
                        //document.getElementById(form.id).submit();
                     }  
             } catch(error) {
                window.ReactNativeWebView.postMessage('Inner Error ' + window.location + ' webview ' + error.toString());
                console.log(error);
                true;
             }
        `
    return script
}
