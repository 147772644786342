import React from 'react'
import classes from '../styles/currency.module.css'
import Icon from '../../Icon/Icon'
import { getThemeMode } from '../../../theme/useDarkMode'
import CryptoIconsDict from './iconDict'
import { getTokenNet, isToken } from '../../../services/getCurrenciesProperty'
import store from '../../../redux'

const IconCurrency = (props) => {
    const { currencyCode, colorNotActive, style, checked, theme } = props

    const themeMode = getThemeMode()

    const state = store.getState().mobileMarket

    const getCurrencyIcon = (code) => {
        const uiData = CryptoIconsDict[code] || false

        const mainColor = uiData[themeMode === 'light' ? 'mainColor' : 'darkColor']

        if (uiData) {
            if (uiData.isToken) {
                const blockchainColor = CryptoIconsDict[uiData.blockchainSymbol][themeMode === 'light' ? 'mainColor' : 'darkColor']
                return (
                    <>
                        <div
                            className={classes.currency__wrapper}
                            style={{ borderColor: colorNotActive ? colorNotActive : checked ? theme.main.checked : mainColor }}>
                            <Icon
                                icon={uiData?.checkedIcon || uiData.icon}
                                size={24}
                                color={colorNotActive ? colorNotActive : checked ? theme.main.checked : mainColor}
                                style={{ marginTop: 7, marginRight: 8 }}
                            />
                        </div>
                        <Icon
                            icon={uiData.blockchainSymbol}
                            size={16}
                            color={colorNotActive ? colorNotActive : blockchainColor}
                            className={classes.little__circle}
                            style={{ background: theme.currency.circleBg }}
                        />
                    </>
                )
            }
            if (code === 'BTG') {
                return (
                    <div
                        className={classes.currency__wrapper}
                        style={{ borderColor: colorNotActive ? colorNotActive : checked ? theme.main.checked : mainColor }}>
                        <div
                            className={classes.btg}
                            style={{ borderColor: colorNotActive ? colorNotActive : checked ? theme.main.checked : '#E1A600' }}>
                            <Icon
                                icon={uiData.icon}
                                size={20}
                                color={colorNotActive ? colorNotActive : checked ? theme.main.checked : mainColor}
                                style={style ? style : { marginTop: 0, marginLeft: 2 }}
                            />
                        </div>
                    </div>
                )
            }
            return (
                <div
                    className={classes.currency__wrapper}
                    style={{ borderColor: colorNotActive ? colorNotActive : checked ? theme.main.checked : mainColor }}>
                    <Icon
                        icon={uiData?.checkedIcon || uiData.icon}
                        size={24}
                        color={colorNotActive ? colorNotActive : checked ? theme.main.checked : mainColor}
                        style={{ marginTop: 7, marginRight: 8 }}
                        viewBox={uiData?.viewBox || '0 0 24 24'}
                    />
                </div>
            )
        }

        if (isToken(state.walletData, code)) {
            const network = getTokenNet(state.walletData, code)
            const blockchainColor = CryptoIconsDict?.[network]?.[themeMode === 'light' ? 'mainColor' : 'darkColor'] || ''

            return (
                <>
                    <div
                        className={classes.currency__wrapper}
                        style={{ borderColor: colorNotActive ? colorNotActive : checked ? theme.main.checked : theme.topNav.colorIcon }}>
                        <Icon
                            icon='DEFAULT_COIN'
                            size={24}
                            color={colorNotActive ? colorNotActive : checked ? theme.main.checked : theme.topNav.colorIcon}
                            style={{ marginTop: 7, marginRight: 8 }}
                        />
                    </div>
                    <Icon
                        icon={network}
                        size={16}
                        color={colorNotActive ? colorNotActive : blockchainColor}
                        className={classes.little__circle}
                        style={{ background: theme.currency.circleBg }}
                    />
                </>
            )
        }

        return (
            <div
                className={classes.currency__wrapper}
                style={{ borderColor: colorNotActive ? colorNotActive : checked ? theme.main.checked : theme.topNav.colorIcon }}>
                <Icon
                    icon='DEFAULT_COIN'
                    size={24}
                    color={colorNotActive ? colorNotActive : checked ? theme.main.checked : theme.topNav.colorIcon}
                    style={{ marginTop: 7, marginRight: 8 }}
                />
            </div>
        )
    }

    return <>{getCurrencyIcon(currencyCode)}</>
}

export default React.memo(IconCurrency)
