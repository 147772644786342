import axios from 'axios'
import config from '../config/config'
import Log from './sentry/Log'

import { showModal } from '../redux/AppStores/ModalStore/action'
import { strings } from '../i18n'

export default async function getProviders(state, requestId, amount, amountSide, tester, enqueueSnackbar = false) {
    Log.info(`V3.getProviders input data ${JSON.stringify(requestId, amount, amountSide)}`)

    if (!parseFloat(amount)) return []

    const body = {
        inCurrencyCode: state.inCurrency.code,
        outCurrencyCode: state.outCurrency.code,
        paywayCode: state.inCurrency.type === 'CRYPTO' && state.outCurrency.type === 'CRYPTO' ? state.inCurrency.code : state.payway,
        amountData: {
            amount: parseFloat(amount),
            side: amountSide
        },
        cashbackToken: state.cashbackToken,
        requestId: requestId,
        sign: state.sign
    }

    if (state.payway === 'CARD' && state.outCurrency.type === 'FIAT' && state.paymentData && state.paymentData?.number) {
        body.outDestination = state?.paymentData?.number
    }

    if (state.inCurrency.type === 'FIAT') {
        if (state?.paymentData && state?.paymentData?.number) {
            body.fromPaymentDetails = state?.paymentData?.number
        }

        body.outDestination = state?.outDestination
    }

    Log.info(`V3.getProviders body ${JSON.stringify(body)}`)

    config.showStackRequiest && tester && enqueueSnackbar && enqueueSnackbar('/provider/get-profit-providers start ' + JSON.stringify(new Date()))

    const link = `${config.backendUrl}/provider/get-profit-providers?cashbackToken=${state.cashbackToken}`

    const response = await axios.post(link, body).catch((error) => {
        console.log(JSON.stringify(error.response))
        console.log(JSON.stringify(error.response?.data))
        Log.warning(`V3.getProfitProviders error.response.data ${JSON.stringify(error.response?.data)}`)
        Log.captureError(`V3.getProfitProviders error`)
        if (error?.response?.data?.errorCode === 'INSUFFICIENT_LIQUIDITY') {
            showModal({
                type: 'INFO_MODAL',
                icon: 'WARNING',
                header: strings('mobileMarket.modal.sorry'),
                description: strings('modal.insufficientLiquidityError')
            })
            return false
        }
        // console.log(error.response.data);
        // alert(error.message);
    })
    config.showStackRequiest && tester && enqueueSnackbar && enqueueSnackbar('/provider/get-profit-providers finish ' + JSON.stringify(new Date()))
    const providers = response ? response.data : null
    return providers
}
