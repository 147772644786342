import React from 'react'
import NotificationDiv from './style.css'

import Icon from '../Icon/Icon'

const NotificationMsg = (props) => {
    const { title, description, theme } = props

    return (
        <NotificationDiv bg={theme.notification.bg} colorTitle={theme.main.checked} colorDescription={theme.notification.colorDescription}>
            <div>
                <Icon icon='INFO' size={22} color={theme.main.checked} />
            </div>
            <div className='main'>
                <div className='title'>{title}</div>
                <div className='description'>{description}</div>
            </div>
        </NotificationDiv>
    )
}

export default NotificationMsg
