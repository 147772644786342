import React from 'react'

import { strings } from 'i18n'

import { getTokenNet } from 'services/getCurrenciesProperty'

import CryptoCurrencies from 'components/currencies/crypto/CurrencyDict'
import { CurrencyMonoIcon } from 'components/currencies/crypto/currencyIcon'
import { CurrencyFiatMonoIcon } from 'components/currencies/fiat/currencyIcon'
// import fiatCurrencyCode from 'components/currencies/fiat/fiatCurrencyCode'
import Icon from 'components/Icon/Icon'

import SelectorDiv from './Selector.css'

const Selector = (props) => {
    const { width, description, descriptionIcon, mainIcon, onClick, name, select, type, showError, showErrorBtn, isToken, code, theme, walletData } =
        props

    return (
        <SelectorDiv
            style={{ width: width }}
            colorDescription={theme.topNav.colorIcon}
            boxShadow={theme.main.selector.errorShadow}
            showError={showError}
            bg={theme.main.selector.bg}>
            {description && (
                <div className='text__wrapper'>
                    {descriptionIcon && (
                        <div>
                            <Icon icon={descriptionIcon} size={window.screen.width > 360 ? 16 : 13} color={theme.topNav.colorIcon} />
                        </div>
                    )}
                    <div className='text' style={{ marginLeft: descriptionIcon ? 7 : 0 }}>
                        {description}
                    </div>
                </div>
            )}
            <div className={showErrorBtn ? 'btn__shadow' : 'btn'} onClick={onClick}>
                <div className='content'>
                    {!select ? (
                        <div>
                            {type === 'CRYPTO' ? (
                                isToken ? (
                                    <div className={'token'}>
                                        <CurrencyMonoIcon currencyCode={mainIcon} theme={theme} />
                                        <div className={'tokenIcon'}>
                                            <Icon icon={getTokenNet(walletData, code)} color={theme.main.arrow} size={14} />
                                        </div>
                                    </div>
                                ) : (
                                    <CurrencyMonoIcon currencyCode={mainIcon} theme={theme} />
                                )
                            ) : type === 'FIAT' ? (
                                <CurrencyFiatMonoIcon currencyCode={mainIcon} theme={theme} />
                            ) : type === 'PAYWAY' ? (
                                <Icon icon={mainIcon === 'MOBILE' ? 'MOBILESELECT' : mainIcon} color={theme.main.arrow} size={24} />
                            ) : (
                                <Icon icon={mainIcon} color={theme.main.arrow} size={24} />
                            )}
                        </div>
                    ) : (
                        <div className='select'>{strings('mobileMarket.select')}</div>
                    )}
                    <div className='content__text'>{name && (CryptoCurrencies[name] ? CryptoCurrencies[name].currencySymbol : name)}</div>
                </div>
                <div className='arrow'>
                    <Icon icon='ARROW_DOWN' color={theme.main.arrow} size={24} />
                </div>
            </div>
        </SelectorDiv>
    )
}

export default Selector
