import styled from 'styled-components'

const PickDateDiv = styled.div`
    .pick-date-wrap {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        font-family: 'Montserrat SemiBold';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: ${(p) => p.textColor};

        padding-bottom: 16px;
        margin-top: 12px;

        input[type='text'] {
            text-align: center;
        }
    }
`

export default PickDateDiv
