import React from 'react'
import { getThemeMode, getThemeModeStyles } from '../../theme/useDarkMode'
import Icon from '../Icon/Icon'
import Colors from './UiProviders'

const IconProvider = (props) => {
    const provider = props.provider
    const status = props.status
    const theme = getThemeModeStyles()
    const mode = getThemeMode()

    switch (provider.toUpperCase()) {
        case 'CHANGELLY':
            return (
                <Icon icon='CHANGELLY' color={status === 'SUCCESS' ? '' : theme.topNav.colorIcon} size={40} arrayColors={Colors['CHANGELLY'][mode]} />
            )
        case 'SWAPY':
            return status === 'SUCCESS' ? <Icon icon='SWAPY' size={40} arrayColors={Colors['SWAPY'][mode]} /> : <Icon icon='SWAPYDIS' size={40} />
        case 'CHANGE_NOW':
            return (
                <Icon
                    icon='CHANGENOW'
                    color={status === 'SUCCESS' ? '' : theme.topNav.colorIcon}
                    size={40}
                    arrayColors={Colors['CHANGE_NOW'][mode]}
                />
            )
        case '365CASH':
            return <Icon icon='365CASH' color={status === 'SUCCESS' ? '' : theme.topNav.colorIcon} size={40} arrayColors={Colors['365CASH'][mode]} />
        case 'KASSA':
            return <Icon icon='KASSA' color={status === 'SUCCESS' ? '' : theme.topNav.colorIcon} size={40} arrayColors={Colors['KASSA'][mode]} />
        case 'KUNA':
            return status === 'SUCCESS' ? <Icon icon='KUNA' size={40} arrayColors={Colors['KUNA'][mode]} /> : <Icon icon='KUNASELECT' size={40} />
        case 'ANYCASH':
            return <Icon icon='ANYCASH' color={status === 'SUCCESS' ? '' : theme.topNav.colorIcon} size={40} arrayColors={Colors['ANYCASH'][mode]} />
        case 'MERCURYO':
            return (
                <Icon icon='MERCURYO' color={status === 'SUCCESS' ? '' : theme.topNav.colorIcon} size={40} arrayColors={Colors['MERCURYO'][mode]} />
            )
        case 'MINE_EXCHANGE':
            return (
                <Icon
                    icon='SHAHTA'
                    color={status === 'SUCCESS' ? '' : theme.topNav.colorIcon}
                    size={40}
                    arrayColors={Colors['MINE_EXCHANGE'][mode]}
                />
            )
        case 'MOON_PAY':
            return <Icon icon='MOONPAY' color={status === 'SUCCESS' ? '' : theme.topNav.colorIcon} size={40} arrayColors={Colors['MOON_PAY'][mode]} />
        case 'JUST_SWAP':
            return (
                <Icon icon='JUST_SWAP' color={status === 'SUCCESS' ? '' : theme.topNav.colorIcon} size={40} arrayColors={Colors['JUST_SWAP'][mode]} />
            )
        case 'ALICE_BOB':
        case 'ALICE_BOB_UNIVERSAL':
            return (
                <Icon icon='ALICE_BOB' color={status === 'SUCCESS' ? '' : theme.topNav.colorIcon} size={40} arrayColors={Colors['ALICE_BOB'][mode]} />
            )
        case 'COIN_CAT':
            return (
                <Icon icon='COIN_CAT' color={status === 'SUCCESS' ? '' : theme.topNav.colorIcon} size={40} arrayColors={Colors['COIN_CAT'][mode]} />
            )
        case 'EPIC_EXCHANGE':
            return (
                <Icon
                    icon='EPIC_EXCHANGE'
                    color={status === 'SUCCESS' ? '' : theme.topNav.colorIcon}
                    size={40}
                    arrayColors={Colors['EPIC_EXCHANGE'][mode]}
                />
            )
        case 'LETS_EXCHANGE':
            return (
                <Icon
                    icon='LETS_EXCHANGE'
                    color={status === 'SUCCESS' ? '' : theme.topNav.colorIcon}
                    size={40}
                    arrayColors={Colors['LETS_EXCHANGE'][mode]}
                    viewBox='0 0 40 40'
                />
            )
        case 'THE_1INCH':
            return (
                <Icon icon='ETH_1INCH' color={status === 'SUCCESS' ? '' : theme.topNav.colorIcon} size={40} arrayColors={Colors['THE_1INCH'][mode]} />
            )
        case 'PURE_FI':
            return <Icon icon='PURE_FI' color={status === 'SUCCESS' ? '' : theme.topNav.colorIcon} size={40} arrayColors={Colors['PURE_FI'][mode]} />
        case 'SIDE_SHIFT':
            return (
                <Icon
                    icon='SIDE_SHIFT'
                    color={status === 'SUCCESS' ? '' : theme.topNav.colorIcon}
                    size={40}
                    arrayColors={Colors['SIDE_SHIFT'][mode]}
                />
            )
        case 'UTORG':
            return <Icon icon='UTORG' color={status === 'SUCCESS' ? '' : theme.topNav.colorIcon} size={40} arrayColors={Colors['UTORG'][mode]} />
        case 'WHITEBIT':
            return (
                <Icon icon='WHITEBIT' color={status === 'SUCCESS' ? '' : theme.topNav.colorIcon} size={40} arrayColors={Colors['WHITEBIT'][mode]} />
            )

        case 'КЭШБАНК':
            return <Icon icon='CASHBANK' color={status === 'SUCCESS' ? '' : theme.topNav.colorIcon} size={40} arrayColors={Colors['КЭШБАНК'][mode]} />
        case 'NETEX24':
            return <Icon icon='NETEX24' color={status === 'SUCCESS' ? '' : theme.topNav.colorIcon} size={40} arrayColors={Colors['NETEX24'][mode]} />
        case 'XCHANGE':
            return <Icon icon='XCHANGE' color={status === 'SUCCESS' ? '' : theme.topNav.colorIcon} size={40} arrayColors={Colors['XCHANGE'][mode]} />
        case 'WW-PAY':
            return <Icon icon='WWPAY' color={status === 'SUCCESS' ? '' : theme.topNav.colorIcon} size={40} arrayColors={Colors['WW-PAY'][mode]} />
        case 'WM-EXPRESS':
            return (
                <Icon
                    icon='WMEXPRESS'
                    color={status === 'SUCCESS' ? '' : theme.topNav.colorIcon}
                    size={40}
                    arrayColors={Colors['WM-EXPRESS'][mode]}
                />
            )
        case 'I-OBMEN':
            return <Icon icon='IOBMEN' color={status === 'SUCCESS' ? '' : theme.topNav.colorIcon} size={40} arrayColors={Colors['I-OBMEN'][mode]} />

        default:
            return <Icon icon='GENPROVIDER' color={theme.topNav.colorIcon} size={40} />
    }
}

export default IconProvider
