export default function navigationState(versionNumber) {
    const navigationState = `if (true) {
        const { url } = tradeWebParam

        if (!url) return

        that.handleState({
            url
        })

        if (url.includes('acs') && url !== 'about:blank') {
            that.handleState({
                status: 'PENDING'
            })
            setTimeout(() => {
                that.handleState({
                    status: 'SUCCESS'
                })
            }, 5000)
        }

        // || (url.includes('mapi.xpay.com') && url !== 'about:blank')
        if (
            (url.includes('pay.receipt-pay.com/cards') && url !== 'about:blank')
            || (url.includes('cardgate.paycore.io') && url !== 'about:blank')
            || (url.includes('easybits.io') && url !== 'about:blank')
            // || (url.includes('online.contact-sys.com') && url !== 'about:blank')
        ) {
            setTimeout(() => {
                if (that.state.receiptPay) {
                    that.handleState({
                        receiptPay: false,
                        status: 'SUCCESS'
                    })
                }
            }, 4000)
        }

        if (url.includes('sci.any.money') && url !== 'about:blank') {
            if (that.state.additionalData.orderStatus === 'DONE_PAYOUT') {

                that.handleState({
                    additionalData: {
                        ...that.state.additionalData,
                        close: false
                    }
                })

                if (CACHE_IS_ERROR) {
                    // that.setExchangeStatus('', that.state.orderHash, 'FAIL')
                    try {
                        NavStore.reset('MarketScreen')
                    } catch (e) {

                    }
                } else {
                    // that.setExchangeStatus('', that.state.orderHash, 'SUCCESS')
                    try {
                        NavStore.reset('MarketScreen', { orderHash: that.state.orderHash ? that.state.orderHash : false })
                    } catch (e) {
                        console.log(e)
                    }
                }
            }
        }

        if (
            (
                url.includes('kuna.io/') || url.includes('cardgate.paycore.io/hpp/status') || url.includes('cb1.xpay.com.ua/')
                || url.includes('365cash.co/currency/success') 
                || url.includes('365cash.co/currency/failure')
                || url.includes('365cash.co/order/status')
                || (
                    url.includes('trustee.deals')
                    && !url.includes('redirectUrl=https://trustee.deals/') 
                    && !url.includes('successUrl=https://trustee.deals/') 
                    && !url.includes('return_url=https://trustee.deals') 
                    && !url.includes('redirectURL=https%3A%2F%2Ftrustee.deals') 
                    && !url.includes('successUrl=https%3A%2F%2Ftrustee.deals') 
                    && !url.includes('redirectUrl=https%3A%2F%2Ftrustee.deals%2F')
                    && !url.includes('exchange.trustee.deals')
                    && !url.includes('testexchange.trustee.deals')
                    && !url.includes('successUrl=https%3A%2F%2F365cash.co%2F')
                )
                || (
                    url.includes('trusteeglobal.com')
                    && !url.includes('redirectUrl=https://trusteeglobal.com/') 
                    && !url.includes('successUrl=https://trusteeglobal.com/') 
                    && !url.includes('return_url=https://trusteeglobal.com') 
                    && !url.includes('redirectURL=https%3A%2F%2Ftrusteeglobal.com') 
                    && !url.includes('successUrl=https%3A%2F%2Ftrusteeglobal.com') 
                    && !url.includes('successUrl=https%3A%2F%2Ftrusteeglobal.com%2F') 
                    && !url.includes('redirectUrl=https%3A%2F%2Ftrusteeglobal.com%2F')
                    && !url.includes('successUrl=https%3A%2F%2F365cash.co%2F')
                )
                || (url.includes('https://blocksoftlab.com/') && !url.includes('successUrl=https://blocksoftlab.com/') && !url.includes('redirectUrl=https://blocksoftlab.com/'))
            ) && url !== 'about:blank' && that.state.lastStep
        ) {
            that.handleStartAnimation()

            that.handleState({
                status: 'PENDING',
                lastStep: false
            })

            that.handleState({
                additionalData: {
                    ...that.state.additionalData,
                    close: false
                }
            })

            if (CACHE_IS_ERROR || url.includes('365cash.co/currency/failure')) {
                try {
                    NavStore.reset('MarketScreen')
                } catch (e) {
                    Log.err('SMSV3Screen.navigationState error ' + e )
                }
            } else {
                that.setExchangeStatus('', that.state.orderHash, 'SUCCESS')
                try {
                    NavStore.reset('MarketScreen', { orderHash: that.state.orderHash ? that.state.orderHash : false  })
                } catch (e) {
                    console.log(e)
                }
            }
        }
}`

    return navigationState
}
