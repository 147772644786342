import React from 'react'
import { getThemeMode } from '../../theme/useDarkMode'
import Icon from '../Icon/Icon'
import Colors from './colors'

const Payway = (props) => {
    const { paywayCode, selected, checked, fail, theme, size } = props

    const mode = getThemeMode()

    if (!paywayCode) return null

    switch (paywayCode?.toString()?.toUpperCase()) {
        case 'CARD':
            return (
                <div>
                    <Icon
                        icon='CARD'
                        color={checked ? checked : fail ? fail : selected ? theme.topNav.colorIcon : ''}
                        size={size || 36}
                        arrayColors={!checked && !fail && !selected ? Colors['CARD'][mode] : null}
                    />
                </div>
            )
        case 'MOBILE_PHONE':
            return (
                <div>
                    {checked || fail ? (
                        <Icon
                            icon='MOBILESELECT'
                            color={checked ? checked : fail ? fail : selected ? theme.topNav.colorIcon : ''}
                            size={size || 40}
                        />
                    ) : (
                        <Icon
                            icon='MOBILE'
                            color={checked ? checked : fail ? fail : selected ? theme.topNav.colorIcon : ''}
                            size={size || 40}
                            arrayColors={!checked && !fail && !selected ? Colors['MOBILE_PHONE'][mode] : null}
                        />
                    )}
                </div>
            )
        case 'QIWI':
            return (
                <div>
                    <Icon
                        icon='QIWI'
                        color={checked ? checked : fail ? fail : selected ? theme.topNav.colorIcon : ''}
                        size={size || 36}
                        arrayColors={!checked && !fail && !selected ? Colors['QIWI'][mode] : null}
                    />
                </div>
            )
        case 'ADVCASH':
            return (
                <div>
                    <Icon
                        icon='ADVCASH'
                        color={checked ? checked : fail ? fail : selected ? theme.topNav.colorIcon : ''}
                        size={size || 34}
                        arrayColors={!checked && !fail && !selected ? Colors['ADVCASH'][mode] : null}
                    />
                </div>
            )
        case 'PAYEER':
            return (
                <div>
                    <Icon
                        icon='PAYEER'
                        color={checked ? checked : fail ? fail : selected ? theme.topNav.colorIcon : ''}
                        size={size || 36}
                        arrayColors={!checked && !fail && !selected ? Colors['PAYEER'][mode] : null}
                    />
                </div>
            )
        case 'PERFECT_MONEY':
            return (
                <div>
                    <Icon
                        icon='PERFECT_MONEY'
                        color={checked ? checked : fail ? fail : selected ? theme.topNav.colorIcon : ''}
                        size={size || 36}
                        arrayColors={!checked && !fail && !selected ? Colors['PERFECT_MONEY'][mode] : null}
                    />
                </div>
            )
        case 'YANDEX_MONEY':
            return (
                <div>
                    <Icon
                        icon='YANDEX_MONEY'
                        color={checked ? checked : fail ? fail : selected ? theme.topNav.colorIcon : ''}
                        size={size || 40}
                        arrayColors={!checked && !fail && !selected ? Colors['YANDEX_MONEY'][mode] : null}
                    />
                </div>
            )
        case 'WEBMONEY':
            return (
                <div>
                    <Icon
                        icon='WEBMONEY'
                        color={checked ? checked : fail ? fail : selected ? theme.topNav.colorIcon : ''}
                        size={size || 40}
                        arrayColors={!checked && !fail && !selected ? Colors['WEBMONEY'][mode] : null}
                    />
                </div>
            )
        case 'CAPITALIST':
            return (
                <div>
                    <Icon
                        icon='CAPITALIST'
                        color={checked ? checked : fail ? fail : selected ? theme.topNav.colorIcon : ''}
                        size={size || 40}
                        arrayColors={!checked && !fail && !selected ? Colors['CAPITALIST'][mode] : null}
                    />
                </div>
            )
        case 'BANK_TRANSFER':
            return (
                <div>
                    <Icon
                        icon='BANK_TRANSFER'
                        color={checked ? checked : fail ? fail : selected ? theme.topNav.colorIcon : ''}
                        size={size || 34}
                        arrayColors={!checked && !fail && !selected ? Colors['BANK_TRANSFER'][mode] : null}
                    />
                </div>
            )
        case 'CASH':
            return (
                <div>
                    <Icon
                        icon='CASH'
                        color={checked ? checked : fail ? fail : selected ? theme.topNav.colorIcon : ''}
                        size={size || 34}
                        arrayColors={!checked && !fail && !selected ? Colors['CASH'][mode] : null}
                    />
                </div>
            )
        default:
            return (
                <div>
                    <Icon icon='GENERAL_PAYMENT' color={checked ? checked : fail ? fail : selected ? theme.topNav.colorIcon : ''} size={size || 36} />
                </div>
            )
    }
}

export default Payway
