import styled from 'styled-components'

const AdvancedDiv = styled.div`
    ::-webkit-input-placeholder {
        /* Edge */
        font-family: 'Montserrat SemiBold', sans-serif;
        font-style: normal;
        /* font-weight: 600; */
        font-size: 16px;
        letter-spacing: 0.5px;
        color: #999999;
    }

    :-ms-input-placeholder {
        /* Internet Explorer */
        font-family: 'Montserrat SemiBold', sans-serif;
        font-style: normal;
        /* font-weight: 600; */
        font-size: 16px;
        letter-spacing: 0.5px;
        color: #999999;
    }

    ::placeholder {
        font-family: 'Montserrat SemiBold', sans-serif;
        font-style: normal;
        /* font-weight: 600; */
        font-size: 16px;
        /* line-height: 16px; */
        letter-spacing: 0.5px;
        color: #999999;
    }

    .wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        background-color: #f5f5f5;
        overflow: scroll;
        -webkit-appearance: none;
        padding-top: 16px;
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
    }

    .wrapper::-webkit-scrollbar {
        display: none;
    }

    .content {
        /* padding-top: 24px; */
        margin-left: 16px;
        margin-right: 16px;
    }

    .content__scroll {
        height: calc(100% - 101px);
        margin-bottom: 100px;
    }

    /* advanced screen start */

    .give__get {
        font-family: 'SF UI Display Regular', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.5px;
        color: ${(p) => p.textColor};
    }

    .rotate {
        transform: rotate(180deg);
    }

    .currency__button {
        width: 52px;
        /*strange*/
        height: 52px;
        /*strange*/
        color: #f5f5f5;
        background: ${(p) => p.btnBg};
        box-shadow: 0 6px 10px rgba(64, 64, 64, 0.2);
        border-radius: 10px;
        display: flex;
        justify-content: center;
    }

    .input__wrapper {
        display: flex;
        align-self: center;
    }

    .input {
        display: flex;
        align-self: center;
        margin-top: -10px;
        font-family: 'Montserrat Bold', sans-serif;
        font-style: normal;
        /* font-weight: bold; */
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0.5px;
        color: #f7f7f7;
    }

    .currency__input {
        height: 50px;
        width: calc(100% - 64px);
        background: ${(p) => p.selectBtn};
        border-radius: 10px;
        border: none;
        outline: none;
        margin-right: 16px;
        font-family: 'Montserrat Bold', sans-serif;
        font-style: normal;
        /* font-weight: bold; */
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0.5px;
        color: ${(p) => p.textColor};
    }

    .input__text {
        margin-top: 17px;
        margin-left: 16px;
    }

    .newOutDestination {
        width: calc(100% - 56px);
    }

    .newOutDestination__error {
        width: calc(100% - 56px);
    }

    .input__boxShadow {
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        width: calc(100% - 18px);
        padding-top: 3px;
    }

    .input__boxShadow__error {
        box-shadow: 0px 0px 16px rgba(229, 76, 76, 0.15);
        border-radius: 10px;
        width: calc(100% - 18px);
        background: ${(p) => p.inputBg};
        padding-top: 3px;
        /* margin-bottom: 5px; */
    }

    .newOutDestination input {
        border: none;
        outline: none;
        width: calc(100% - 30px);
        font-family: 'SF UI Display Medium', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 1px;
        color: ${(p) => p.inputColor};
        padding-left: 16px;
        height: 40px;
        background: ${(p) => p.inputBg};
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        -webkit-tap-highlight-color: transparent;
    }

    .newOutDestination__error input {
        border: none;
        outline: none;
        width: calc(100% - 30px);
        font-family: 'SF UI Display Medium', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 1px;
        color: #e54c4c;
        padding-left: 16px;
        height: 40px;
        background: ${(p) => p.inputBg};
        box-shadow: 0 0 16px rgba(229, 76, 76, 0.15);
        border-radius: 10px;
        -webkit-tap-highlight-color: transparent;
    }

    .destination__wrong {
        margin-top: 16px;
        margin-left: 10px;
        margin-right: 10px;
        font-family: 'SF UI Display Medium', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.5px;
        color: #e54c4c;
    }

    .tag__wrapper {
        width: 100%;
        display: flex;
        margin-top: 16px;
        border-radius: 10px;
        background: #f9f9f9;
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
    }

    .tag__wrapper input {
        background: #f9f9f9;
        outline: none;
        border: none;
        width: calc(100% - 32px);
        padding-left: 16px;
        height: 50px;
        border-radius: 10px;
        font-family: 'SF UI Display Medium', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 1px;
        color: #404040;
        caret-color: #864dd9;
        -webkit-tap-highlight-color: transparent;
        resize: none;
    }

    .time {
        font-family: 'SF UI Display Regular', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.5px;
        color: ${(p) => p.textColor};
        position: absolute;
        bottom: 100px;
        margin: 0 auto;
        text-align: center;
        left: 0;
        right: 0;
    }

    .inputWrapper {
        margin-top: 30px;
        position: absolute;
        width: calc(100% - 32px);
    }
`

export default AdvancedDiv
