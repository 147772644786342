import React, { useState, useEffect } from 'react'
import axios from 'axios'

import SwipeableViews from 'react-swipeable-views'
import PullRefresh from 'react-pullrefresh'

import { getTxLink } from 'services/getCurrenciesProperty'
import getActualWays, { serverRequest } from 'services/getActualWays'
import Log from 'services/sentry/Log'
import utilsService from 'services/utilsService'
import findOutDestination from 'services/findOutDestination'

import { strings } from 'i18n'
import config from 'config/config'

import store from 'redux/index'
import { getHistoryOrderAction, reloadOrdersAction, setOrderAction } from 'redux/AppStores/OrderHistoryStore/action'
import { hideSearchScreenModal } from 'redux/AppStores/SearchModalScreenStore/action'
import {
    amountAction,
    amountSideAction,
    exchangeWaysAction,
    inCurrencyAction,
    outCurrencyAction,
    propsModalAction,
    repeatOrderAction,
    setShowLoader
} from 'redux/AppStores/MobileMarket/action'

import CurrencyStatus from 'modules/mobileMarket/OrderHistory/OrderHistoryItem/CurrencyStatus'

import OrderInProcess from 'img/orderInProcessOpt.svg'
import OrderWasCanceled from 'img/canceledOrderOpt.svg'

import Wrapper from 'components/ScreenWrapper/Wrapper'
import NotificationMsg from 'components/Notification/Notification'
import getProviderName from 'components/providersSettings/providerName'

import { ModalWrapper } from '../elements/wrapper.css'
import classes from '../styles.module.css'

import OrderDataItem from './OrderDataItem/orderData'
import SearchLayout from './Layout/Layout'

const { dispatch } = store

const getExplorerLink = (currencyCode, txHash) => {
    if (typeof txHash === 'undefined' || !txHash) {
        return false
    }

    const baseUrl = getTxLink(currencyCode)
    const link = `${baseUrl}${txHash}?from=trustee`
    return link
}

const getTime = (date) => {
    let datetime = new Date(date)
    const time = `${datetime.getHours().toString().length === 1 ? '0' + datetime.getHours() : datetime.getHours().toString()}:${
        datetime.getMinutes().toString().length === 1 ? '0' + datetime.getMinutes() : datetime.getMinutes().toString()
    }:${datetime.getSeconds().toString().length === 1 ? '0' + datetime.getSeconds() : datetime.getSeconds().toString()}`
    return time
}

const getData = (date) => {
    let datetime = new Date(date)
    const data = `${datetime.getDate().toString().length === 1 ? '0' + datetime.getDate() : datetime.getDate()}.${
        (datetime.getMonth() + 1).toString().length === 1 ? '0' + (datetime.getMonth() + 1) : datetime.getMonth() + 1
    }.${datetime.getFullYear().toString()}`
    return data
}

const getOutDestinationStringKey = (outPaywayCode) => {
    switch (outPaywayCode) {
        case 'VISA_MC_P2P':
        case 'VISA_MC_WW':
        case 'VISA_MC':
        case 'CARD':
            return 'mobileMarket.cardNumber'

        case 'MOBILE_PHONE':
        case 'QIWI':
            return 'mobileMarket.mobilePhone'

        case 'ADVCASH':
        case 'PAYEER':
        case 'PERFECT_MONEY':
        case 'CAPITALIST':
        case 'WEBMONEY':
        case 'YANDEX_MONEY':
            return 'mobileMarket.accountNumber'

        default:
            return 'mobileMarket.destinationAddress'
    }
}

const getBankFeeString = (data, feeData) => {
    const fixedFee = feeData.providerFee.in.find((fee) => fee.type === 'FIXED')
    const percentFee = feeData.providerFee.in.find((fee) => fee.type === 'PERCENT')

    let fee = percentFee?.amount ? percentFee?.amount + '% ' : ''

    fee += fee && fixedFee?.amount ? '+ ' : ''
    fee += fixedFee?.amount
        ? `${fixedFee?.amount} ${data[(data.exchangeWayType === 'SELL' ? 'in' : data.currencySide.toLowerCase()) + 'CurrencySymbol']}`
        : ''

    return fee
}

const getNetworkFeeString = (data, feeData) => {
    const fixedFee = feeData.providerFee.out.find((fee) => fee.type === 'FIXED')
    const percentFee = feeData.providerFee.out.find((fee) => fee.type === 'PERCENT')

    let fee = percentFee?.amount ? percentFee?.amount + '% ' : ''

    fee += fee && fixedFee?.amount ? '+ ' : ''
    fee += fixedFee?.amount
        ? `${fixedFee?.amount} ${data[(data.exchangeWayType === 'BUY' ? 'out' : data.currencySide.toLowerCase()) + 'CurrencySymbol']}`
        : ''

    return fee
}

const getTrusteeFee = (feeData, side) => {
    const percentFee = feeData.trusteeFee[side.toLowerCase()].find((fee) => fee.type === 'PERCENT')
    return percentFee.amount + '%'
}

const goToTx = (tx) => {
    window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ txHash: tx }))
}

const cleanOrderHash = async () => {
    const mobileMarket = store.getState().mobileMarket
    if (mobileMarket.toOrderHash) {
        dispatch(reloadOrdersAction(true))

        let link = `/order/history-for-bse?cashbackToken=${mobileMarket?.cashbackToken}&signMessage=${mobileMarket?.sign?.message}&signMessageHash=${mobileMarket?.sign?.messageHash}&signature=${mobileMarket?.sign?.signature}`
        const history = await serverRequest(link, strings('mobileMarket.modal.serverRespond'), 'WALLET_DATA')

        if (history) {
            dispatch(getHistoryOrderAction(history?.currentPage || 0, history.orders, history.pages, history.serverTime))
        }
    }
}

const repeatOrder = async (data, inCurrency, outCurrency, marketState) => {
    let obj, getWays, exchangeWaysArray

    await cleanOrderHash()
    dispatch(propsModalAction(false))

    dispatch(inCurrencyAction(inCurrency, false, false, data.exchangeWayType === 'BUY' ? data.inPaywayCode : data.outPaywayCode))

    if (outCurrency.type === 'CRYPTO') {
        obj = findOutDestination(marketState.walletData, outCurrency.code, marketState.cashbackToken)
    }
    dispatch(
        outCurrencyAction(
            outCurrency,
            false,
            false,
            data.exchangeWayType === 'BUY' ? data.inPaywayCode : data.outPaywayCode,
            typeof obj !== 'undefined' ? obj.outDestination : '',
            typeof obj !== 'undefined' ? obj.walletName : marketState.walletName
        )
    )

    try {
        getWays = await getActualWays(
            inCurrency,
            outCurrency,
            data.exchangeWayType === 'BUY' ? data.inPaywayCode : data.outPaywayCode,
            marketState.cashbackToken,
            marketState.sign.message,
            marketState.sign.messageHash,
            marketState.sign.signature
        )
    } catch (e) {
        console.log(JSON.stringify(e))
        getWays = { dataAll: [], comingSoon: [] }
    }

    exchangeWaysArray = getWays.dataAll

    const results = exchangeWaysArray.length ? exchangeWaysArray : []

    dispatch(exchangeWaysAction(exchangeWaysArray, [], results, true, false))

    dispatch(amountAction(data.amountRequested))
    dispatch(amountSideAction('IN'))

    dispatch(repeatOrderAction(true))

    setTimeout(() => {
        window.history.back()
        hideSearchScreenModal()
    }, 100)
}

const OrderDataModal = (props) => {
    const state = store.getState().orderHistoryStore
    const marketState = store.getState().mobileMarket

    const { show, data, callback, theme } = props

    const [index, setIndex] = useState(0)
    const [checkType, setCheckType] = useState(null)

    useEffect(async () => {
        setCheckType(state?.orderData?.preCheck?.checkType || state?.orderData?.finalCheck?.checkType)

        if (state?.orderData?.preCheck?.checkType === 'PRE_CHECK' || state?.orderData?.finalCheck?.checkType === 'PRE_CHECK') {
            setIndex(0)
        } else if (state?.orderData?.preCheck?.checkType === 'FINAL_CHECK' || state?.orderData?.finalCheck?.checkType === 'FINAL_CHECK') {
            setIndex(1)
            const mobileMarket = store.getState().mobileMarket

            try {
                const response = await axios.get(
                    `${config.backendUrl}/order/check?orderHash=${state?.orderData?.finalCheck?.orderHash}&cashbackToken=${mobileMarket?.cashbackToken}&signMessage=${mobileMarket?.sign?.message}&signMessageHash=${mobileMarket?.sign?.messageHash}&signature=${mobileMarket?.sign?.signature}&checkType=PRE_CHECK`
                )

                dispatch(
                    setOrderAction({
                        preCheck: {
                            ...state?.orderData?.finalCheck,
                            feeData: response?.data?.feeData,
                            equivalentData: response?.data?.equivalentData,
                            exchangeRate: response?.data?.exchangeRate,
                            checkType: 'PRE_CHECK'
                        },
                        finalCheck: state?.orderData?.finalCheck
                    })
                )
            } catch (e) {
                console.log(JSON.stringify(e))
                Log.error(`V3.OrderDataModal get PRE_CHECK error`, JSON.stringify(e))
                Log.captureError(`V3.OrderDataModal get PRE_CHECK error`)
            }
        }

        setTimeout(() => {
            dispatch(setShowLoader(false))
        }, 5e2)
    }, [])

    const refreshOrder = async () => {
        const state = store.getState().orderHistoryStore
        const mobileMarket = store.getState().mobileMarket

        const response = await axios.get(
            `${config.backendUrl}/order/check?orderHash=${
                state.orderData.finalCheck?.orderHash || state.orderData.preCheck?.orderHash
            }&cashbackToken=${mobileMarket.cashbackToken}&signMessage=${mobileMarket.sign.message}&signMessageHash=${
                mobileMarket.sign.messageHash
            }&signature=${mobileMarket.sign.signature}`
        )
        if (response.data.checkType === 'FINAL_CHECK') {
            setCheckType('FINAL_CHECK')
            setIndex(1)
        }

        dispatch(
            setOrderAction({
                preCheck:
                    response.data.checkType === 'PRE_CHECK'
                        ? {
                              ...state.orderData?.preCheck,
                              feeData: response.data.feeData,
                              equivalentData: response.data.equivalentData,
                              exchangeRate: response.data.exchangeRate,
                              status: response.data.orderStatus
                          }
                        : {
                              ...state.orderData?.preCheck,
                              feeData: response.data.feeData,
                              equivalentData: response.data.equivalentData,
                              exchangeRate: response.data.exchangeRate,
                              status: response.data.orderStatus
                          },
                finalCheck:
                    response.data.checkType === 'FINAL_CHECK'
                        ? state.orderData?.finalCheck
                            ? {
                                  ...state.orderData?.finalCheck,
                                  feeData: response.data.feeData,
                                  equivalentData: response.data.equivalentData,
                                  exchangeRate: response.data.exchangeRate,
                                  status: response.data.orderStatus,
                                  finishedAt: response.data?.finishedAt || null
                              }
                            : {
                                  ...state.orderData?.preCheck,
                                  feeData: response.data.feeData,
                                  equivalentData: response.data.equivalentData,
                                  exchangeRate: response.data.exchangeRate,
                                  status: response.data.orderStatus,
                                  finishedAt: response.data?.finishedAt || null
                              }
                        : null
            })
        )
    }

    const refreshLoader = async () => {
        return new Promise((resolve, reject) => {
            let loaderRefresh = document.getElementsByClassName('sc-bdVaJa')[1]
            if (loaderRefresh) {
                loaderRefresh.style.top = `calc(${loaderRefresh.style.top} + 30px)`
                loaderRefresh.style.transition = 'all 0.4s'
                setTimeout(() => {
                    loaderRefresh.style.transition = ''
                }, 1000)
            }

            const result = refreshOrder()

            if (result) {
                setTimeout(() => {
                    resolve()
                }, 500)
            }
        })
    }

    let array = [0, 1]

    // const message = ''

    // if (state.orderData.finalCheck?.orderHash) {
    //     const data = state.orderData.finalCheck
    //     message += ''
    // } else if (state.orderData.preCheck?.orderHash) {
    //     const data = state.orderData.preCheck
    //     message += ''
    // }

    return (
        <SearchLayout
            id='orderDataModal'
            header={strings('mobileMarket.navigation.order')}
            show={show}
            leftType='back'
            leftAction={cleanOrderHash}
            // share={config.showError}
            // shareObj={{ title: `${strings('mobileCheck.orderHash')} ${state.orderData.finalCheck?.orderHash || state.orderData.preCheck?.orderHash}\n`, description: 'skfjkl sjflk js' }}
        >
            <ModalWrapper
                background={theme.main.bg}
                filterColorActive={theme.topNav.color}
                filterColorTextActive={theme.topNav.colorIcon}
                textColor={theme.topNav.color}>
                <div style={{ width: '100%' }}>
                    <PullRefresh onRefresh={refreshLoader} color='#999' bgColor={theme.topNav.background}>
                        <Wrapper mainModal={true} contentStyle={{ paddingTop: '16px', paddingRight: 0, paddingLeft: 0 }}>
                            <div style={{ marginTop: 54 }}>
                                <div className='filter__wrapper'>
                                    <div
                                        className='filter__item'
                                        onClick={async () => {
                                            setIndex(0)
                                            setCheckType('PRE_CHECK')
                                        }}>
                                        <div className={checkType === 'PRE_CHECK' ? 'filter__value' : 'filter__notValue'}>
                                            {strings('mobileCheck.preCheck').toUpperCase()}
                                        </div>
                                        <div className={checkType === 'PRE_CHECK' ? 'filter__point' : null}></div>
                                    </div>
                                    <div
                                        className='filter__item'
                                        onClick={async () => {
                                            setIndex(1)
                                            setCheckType('FINAL_CHECK')
                                        }}>
                                        <div className={checkType === 'FINAL_CHECK' ? 'filter__value' : 'filter__notValue'}>
                                            {strings('mobileCheck.check').toUpperCase()}
                                        </div>
                                        <div className={checkType === 'FINAL_CHECK' ? 'filter__point' : null}></div>
                                    </div>
                                </div>
                            </div>
                            <SwipeableViews
                                index={index}
                                onChangeIndex={() => {
                                    index === 1 ? setIndex(0) : setIndex(1)
                                    checkType === 'PRE_CHECK' ? setCheckType('FINAL_CHECK') : setCheckType('PRE_CHECK')
                                }}
                                slideStyle={{
                                    minHeight: '70vh'
                                }}>
                                {array.map((item, index) => {
                                    const data = checkType === 'PRE_CHECK' ? state?.orderData?.preCheck : state?.orderData?.finalCheck

                                    if (!data) {
                                        if (checkType === 'FINAL_CHECK') {
                                            const status = state?.orderData?.preCheck?.status || ''
                                            if (status?.indexOf('CANCELED') !== -1) {
                                                return (
                                                    <div className='orderWrapper' key={index}>
                                                        <div className='orderErrorText'>{strings('mobileCheck.canceled')}</div>
                                                        <div className='img__wrapper'>
                                                            <img src={OrderWasCanceled} alt='OrderWasCanceled' width='100%' />
                                                        </div>
                                                    </div>
                                                )
                                            } else if (status !== 'DONE_PAYOUT') {
                                                return (
                                                    <div className='orderWrapper' key={index}>
                                                        <div className='orderErrorText'>{strings('mobileCheck.process')}</div>
                                                        <div className='img__wrapper'>
                                                            <img src={OrderInProcess} alt='OrderInProcess' width='100%' />
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        }
                                        return (
                                            <div style={{ marginLeft: 16, marginRight: 16 }} key={index}>
                                                {strings('mobileCheck.notFound')}
                                            </div>
                                        )
                                    }

                                    const color = theme.topNav.color

                                    const type = data?.status === 'DONE_PAYOUT' ? true : false

                                    const viewStatusColor = theme.orderHistory.viewStatus

                                    const extraData = []

                                    if (data.equivalentData.providerFee.in) {
                                        extraData.push({
                                            title: strings('mobileMarket.bankFee') + ' ' + getBankFeeString(data, data.feeData),
                                            condition: data.equivalentData.providerFee.in,
                                            description:
                                                utilsService.cutNumber(data.equivalentData.providerFee.in, 6) +
                                                ' ' +
                                                data[data.currencySide.toLowerCase() + 'CurrencySymbol']
                                        })
                                    }
                                    if (data.equivalentData.providerFee.out) {
                                        extraData.push({
                                            title: strings('mobileMarket.providerFee') + ' ' + getNetworkFeeString(data, data.feeData),
                                            condition: data.equivalentData.providerFee.out,
                                            description:
                                                data.equivalentData.providerFee.out + ' ' + data[data.currencySide.toLowerCase() + 'CurrencySymbol']
                                        })
                                    }
                                    if (data.equivalentData.trusteeFee.in) {
                                        extraData.push({
                                            title: strings('mobileMarket.trusteeFee') + ' ' + getTrusteeFee(data.feeData, 'IN'),
                                            condition: data.equivalentData.trusteeFee.in,
                                            description:
                                                utilsService.cutNumber(data.equivalentData.trusteeFee.in, 6) +
                                                ' ' +
                                                data[data.currencySide.toLowerCase() + 'CurrencySymbol']
                                        })
                                    }

                                    if (data.equivalentData.trusteeFee.out) {
                                        extraData.push({
                                            title: strings('mobileMarket.trusteeFee') + ' ' + getTrusteeFee(data.feeData, 'OUT'),
                                            condition: data.equivalentData.trusteeFee.out,
                                            description:
                                                utilsService.cutNumber(data.equivalentData.trusteeFee.out, 6) +
                                                ' ' +
                                                data[data.currencySide.toLowerCase() + 'CurrencySymbol']
                                        })
                                    }

                                    if (data.feeData.isFeesIncludedInRate) {
                                        extraData.push({
                                            isFeesIncludedInRate: true,
                                            title: strings('mobileMarket.includedFees')
                                        })
                                    }

                                    const inCurrency = { code: data.inCurrencyCode, type: data.inCurrencyType, symbol: data.inCurrencySymbol }
                                    const outCurrency = { code: data.outCurrencyCode, type: data.outCurrencyType, symbol: data.outCurrencySymbol }

                                    const needDataTime = checkType === 'FINAL_CHECK' ? data?.finishedAt || data?.createdAt : data.createdAt

                                    return (
                                        <div style={{ marginLeft: 16, marginRight: 16 }} key={index}>
                                            {(data?.finishedAt || data?.createdAt) && (
                                                <div className='date'>
                                                    {getTime(needDataTime)}, {getData(needDataTime)}
                                                </div>
                                            )}
                                            <div className={classes.wrapper}>
                                                <CurrencyStatus
                                                    inCurrency={inCurrency}
                                                    outCurrency={outCurrency}
                                                    inAmount={data.amountRequested}
                                                    outAmount={
                                                        (item === 1 || data.rateType === 'FIXED' ? '' : '~') +
                                                        ' ' +
                                                        utilsService.cutNumber(item === 0 ? data.amountExpected : data.amountWithdrawn, 6)
                                                    }
                                                    orderStatus={data.status}
                                                    color={color}
                                                    type={type}
                                                    viewStatus
                                                    viewStatusColor={viewStatusColor}
                                                    theme={theme}
                                                />
                                            </div>

                                            {data.status === 'NOT_ENTIRE_WITHDRAW' ? (
                                                <NotificationMsg
                                                    title={strings('mobileMarket.modal.attention')}
                                                    description={strings('mobileCheck.supportNotification')}
                                                    theme={theme}
                                                />
                                            ) : null}

                                            <OrderDataItem
                                                title={strings('mobileCheck.orderHash')}
                                                description={data.orderHash}
                                                copyValue={data.orderHash}
                                                iconType='ID'
                                                theme={theme}
                                            />
                                            {data.outDestination && (
                                                <OrderDataItem
                                                    title={strings(getOutDestinationStringKey(data.outPaywayCode))}
                                                    description={data.outDestination}
                                                    // copyValue={data.outDestination}
                                                    iconType={data.exchangeWayType !== 'SELL' ? 'USER' : 'CARD'}
                                                    trusteeAdress={data.exchangeWayType !== 'SELL' ? !data?.isExternalOutDestination || true : false}
                                                    theme={theme}
                                                />
                                            )}
                                            <OrderDataItem
                                                title={strings('mobileCheck.rateFees')}
                                                line={extraData.length ? true : false}
                                                description={`1 ${
                                                    data[(data.currencySide === 'IN' ? 'out' : 'in') + 'CurrencySymbol']
                                                } = ${utilsService.cutNumber(data.exchangeRate, 6)} ${
                                                    data[data.currencySide.toLowerCase() + 'CurrencySymbol']
                                                }`}
                                                extraData={extraData}
                                                iconType='RATE'
                                                typeBtn='arrow'
                                                id='rate'
                                                theme={theme}
                                            />
                                            {data.inTxHash && data.inCurrencyType === 'CRYPTO' && (
                                                <OrderDataItem
                                                    title={strings('mobileCheck.hashTx', { currencyCode: data.inCurrencySymbol })}
                                                    description={`${data.inTxHash.slice(0, 8)}...${data.inTxHash.slice(-8)}`}
                                                    copyValue={getExplorerLink(data.inCurrencyCode, data.inTxHash)}
                                                    typeBtn='btn'
                                                    btnAction={() => goToTx(data.inTxHash)}
                                                    iconType='ID'
                                                    theme={theme}
                                                />
                                            )}
                                            {data.outTxHash && data.outCurrencyType === 'CRYPTO' && (
                                                <OrderDataItem
                                                    title={strings('mobileCheck.hashTx', { currencyCode: data.outCurrencySymbol })}
                                                    description={`${data.outTxHash.slice(0, 8)}...${data.outTxHash.slice(-8)}`}
                                                    typeBtn='btn'
                                                    copyValue={getExplorerLink(data.outCurrencyCode, data.outTxHash)}
                                                    btnAction={() => goToTx(data.outTxHash)}
                                                    iconType='ID'
                                                    theme={theme}
                                                />
                                            )}
                                            <OrderDataItem
                                                title={strings('mobileMarket.provider')}
                                                description={getProviderName(data.providerName)}
                                                iconType='PROVIDER'
                                                theme={theme}
                                            />

                                            <div
                                                className='repeat-pay'
                                                onClick={async () => await repeatOrder(data, inCurrency, outCurrency, marketState)}>
                                                <div className='repeat-btn'>{strings('mobileCheck.repeatOrder')}</div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </SwipeableViews>
                            <div style={{ height: 20 }} />
                        </Wrapper>
                    </PullRefresh>
                </div>
            </ModalWrapper>
        </SearchLayout>
    )
}

export default OrderDataModal
