import styled from 'styled-components'

const OrderDataWrapper = styled.div`
    display: flex;
    flex-direction: column;

    min-height: 70px;
    width: 100%;

    background: ${(p) => p.bg};
    box-shadow: inset 0px 0px 16px rgba(64, 64, 64, 0.08);
    border-radius: 16px;

    margin-top: 12px;

    .wrapper {
        width: calc(100% - 32px);

        margin: 16px;

        display: flex;
        flex-direction: row;
    }

    .data {
        width: 100%;

        display: flex;
        flex-direction: row;

        -webkit-tap-highlight-color: transparent;
    }

    .iconWrapper {
        height: 100%;
        width: 19px;
        padding-right: 13px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .title {
        display: flex;
        flex-direction: row;
        align-items: center;

        font-family: 'Montserrat Medium';
        font-style: normal;
        font-size: 14px;
        line-height: 14px;
        color: #999999;

        -webkit-tap-highlight-color: transparent;
    }

    .mainData {
        font-family: 'SF UI Display SemiBold';
        font-style: normal;
        /* font-weight: 600; */
        font-size: 15px;
        line-height: 19px;

        letter-spacing: 1.5px;
        color: ${(p) => p.valueColor};

        padding-top: 5px;

        -webkit-tap-highlight-color: transparent;
    }

    .buttonWrapper {
        /* background-color: grey; */
        min-width: 84px;
        height: 34px;

        -webkit-tap-highlight-color: transparent;
    }

    .txBtn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        width: 100%;
        height: 100%;

        border: 2px solid ${(p) => p.valueColor};
        box-sizing: border-box;
        border-radius: 8px;

        font-family: 'SF UI Display Bold';
        font-style: normal;
        font-size: 14px;
        line-height: 18px;

        text-align: center;
        letter-spacing: 1px;

        color: ${(p) => p.textColor};

        -webkit-tap-highlight-color: transparent;
    }

    .arrowBtn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;

        width: 100%;
        height: 100%;

        -webkit-tap-highlight-color: transparent;
    }

    .line {
        width: 68%;
        height: 1px;

        margin: 0 auto;

        border-bottom: 1px solid ${(p) => p.lineColor};
    }

    .extraData {
        margin: 12px 33px 0px 40px;
    }
`

export default OrderDataWrapper
