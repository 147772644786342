import React from 'react'
import axios from 'axios'
import Cleave from 'cleave.js/react'
import valid from 'card-validator'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import Log from '../../services/sentry/Log'
import validation from '../../services/ValidationService'

import Icon from '../Icon/Icon'
import config from '../../config/config'
import store from '../../redux'
import InputDiv from './input.css'
import i18n, { strings } from '../../i18n'

const getCountryCodeAndScheme = async (cardNumber) => {
    try {
        cardNumber = cardNumber.split(' ').join('')

        let response

        try {
            response = await axios.get('https://lookup.binlist.net/' + cardNumber)
            Log.info(`V3_inputCard.getCountryCodeAndScheme response.data ${JSON.stringify(response.data)}`)

            if (!response) {
                const numberValidation = valid.number(cardNumber)

                Log.info(`V3_inputCard.getCountryCodeAndScheme numberValidation ${JSON.stringify(numberValidation)}`)

                return {
                    scheme: numberValidation.card.type
                }
            }

            return {
                countryCode:
                    response.data.country.numeric === 'RUS' || response.data.country.numeric === '643' ? false : response.data.country.numeric,
                scheme: response.data.scheme
            }
        } catch (e) {
            console.log(JSON.stringify(e))

            const numberValidation = valid.number(cardNumber)

            Log.info(`V3_inputCard.getCountryCodeAndScheme numberValidation ${JSON.stringify(numberValidation)}`)

            return {
                scheme: numberValidation.card.type
            }
        }
    } catch (error) {
        Log.error(`V3_inputCard.getCountryCodeAndScheme error ${JSON.stringify(error)}`)
        Log.captureError(`V3_inputCard.getCountryCodeAndScheme error`)
        return false
    }
}

const cardNumber = async (value, setCardNumber, setCardCountryCode, setType, setValid, setCheckP2P) => {
    value = value.split(' ').join('')
    setCardNumber(value)

    if (value.length === 16) {
        if (!validation('CARD', value)) {
            setValid(true)
            return
        }

        setValid(false)

        const cardData = await getCountryCodeAndScheme(value)

        setCardCountryCode(cardData.countryCode)
        if (typeof cardData.scheme !== 'undefined' && cardData.scheme) {
            setType(cardData.scheme)
        }

        if (cardData.countryCode === '804') {
            const state = store.getState().mobileMarket
            const checkP2P = await axios.get(
                `${config.backendUrl}/liqpay/check-p2p?cardNumber=${value}&signMessage=${state.sign.message}&signMessageHash=${state.sign.messageHash}&signature=${state.sign.signature}&cashbackToken=${state.cashbackToken}`
            )
            setCheckP2P(checkP2P.data.status)
        }
    } else if (value.length !== 16 && value.length < 18) {
        setCardCountryCode(null)
        setType(null)
        return
    } else {
        if (!validation('CARD', value)) {
            setValid(true)
            return
        }

        setValid(false)

        const cardData = await getCountryCodeAndScheme(value)

        setCardCountryCode(cardData.countryCode !== 'RUS' || cardData.countryCode !== '643' ? cardData.countryCode : null)
        setType(cardData.scheme)
    }
}

const paymentDetailsHandler = (value, setValid, setNumber, typePayment, outCurrency) => {
    value = value.toString().replace(/\s/g, '')
    setNumber(value)
    if (typePayment === 'ADVCASH' || typePayment === 'WEBMONEY') {
        if (value.length === 13) {
            setValid(false)
        } else if (value.length < 13) {
            return
        } else {
            setValid(true)
            return
        }
    } else if (typePayment === 'PAYEER') {
        if (value.length === 11) {
            setValid(false)
        } else if (value.length < 11) {
            return
        } else {
            setValid(true)
            return
        }
    } else if (typePayment === 'PERFECT_MONEY' || typePayment === 'CAPITALIST') {
        if (value.length === 9) {
            setValid(false)
        } else if (value.length < 9) {
            return
        } else {
            setValid(true)
            return
        }
    } else if (typePayment === 'YANDEX_MONEY') {
        if (value.length === 16) {
            setValid(false)
        } else if (value.length < 16) {
            return
        } else {
            setValid(true)
            return
        }
    }
}

const focus = (event) => {
    const element = event.target
    const caret = element.value.length
    window.requestAnimationFrame(() => {
        element.selectionStart = caret
        element.selectionEnd = caret
    })
}

const Input = (props) => {
    const {
        placeholder,
        icon,
        style,
        handler,
        value,
        type,
        setCardCountryCode,
        setType,
        options,
        condition,
        setValid,
        id,
        inputMode,
        typePayment,
        outCurrency,
        setCheckP2P,
        disabled,
        onFocus,
        onBlur,
        theme,
        readOnly
    } = props

    return (
        <InputDiv condition={condition} inputBg={theme.input.bg} colorText={theme.topNav.colorIcon} style={{ ...style }}>
            {/* condition === true ? classes.input__inValid : classes.input__field */}
            {options ? (
                <Cleave
                    placeholder={placeholder}
                    value={value}
                    autoComplete={false}
                    options={options}
                    inputMode={inputMode ? inputMode : 'text'}
                    onChange={(event) => {
                        handler(event.target.value)
                    }}
                    onFocus={(event) => focus(event)}
                />
            ) : typePayment === 'MOBILE_PHONE' || typePayment === 'QIWI' || typePayment === 'CASH' ? (
                <PhoneInput
                    value={value}
                    onChange={(value, country) => {
                        if (typePayment === 'MOBILE_PHONE' || typePayment === 'CASH') {
                            handler(value, country)
                        } else {
                            handler(value)
                        }
                    }}
                    disableDropdown={true}
                    isValid={(value, country) => {
                        if (value && value.length > 9 && !country) {
                            setValid(true)
                            return false
                        }
                        if (value.match(/12345/)) {
                            Log.error(`V3_inputCard.isValid phoneInput Invalid value: ${value} ${country.name}`)
                            setValid(true)
                            return false
                        } else if (value.match(/1234/)) {
                            setValid(true)
                            return false
                        } else {
                            setValid(false)
                            return true
                        }
                    }}
                    placeholder={placeholder}
                    buttonStyle={{ display: 'none' }}
                    containerClass={condition === true ? 'input__inValid' : 'input__field'}
                    inputStyle={{
                        width: '100%',
                        height: '50px',
                        background: theme.input.bg,
                        borderRadius: '10px',
                        border: 'none',
                        outline: 'none',
                        paddinogp: '17px',
                        paddingBottom: '17px',
                        paddingLeft: '16px',
                        fontFamily: 'Montserrat SemiBold',
                        fontStyle: 'normal',
                        fontSize: '16px',
                        lineHeight: '16px',
                        letterSpacing: '.5px',
                        color: condition === false ? theme.topNav.colorIcon : '#E54C4C'
                    }}
                    onFocus={(event) => focus(event)}
                    disabled={disabled}
                />
            ) : (
                <input
                    inputMode={inputMode ? inputMode : 'text'}
                    placeholder={placeholder}
                    value={value.toString()}
                    id={id}
                    autoComplete='off'
                    readOnly={readOnly || false}
                    onChange={(event) => {
                        type === 'CARD'
                            ? cardNumber(event.target.value, handler, setCardCountryCode, setType, setValid, setCheckP2P)
                            : type === 'PAYMENT_DETAILS'
                            ? paymentDetailsHandler(event.target.value, setValid, handler, typePayment, outCurrency)
                            : handler(event.target.value)
                    }}
                    onFocus={(event) => (onFocus ? onFocus(event) : focus(event))}
                    disabled={disabled}
                    onBlur={() => (onBlur ? onBlur() : null)}
                />
            )}
            {icon && (
                <div
                    className={'icon__wrapper'}
                    onClick={() => {
                        if (!disabled) {
                            window.ReactNativeWebView &&
                                window.ReactNativeWebView.postMessage(
                                    JSON.stringify({ scanCard: { msg: strings('mobileMarket.card.instructions'), language: i18n.language } })
                                )
                        }
                    }}>
                    <Icon icon={icon} color={condition ? '#E54C4C' : theme.topNav.colorIcon} size={28} />
                </div>
            )}
        </InputDiv>
    )
}

export default Input
