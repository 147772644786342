import styled from 'styled-components'

const ItemWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: ${(p) => (p.notActive && !p.checked ? 'flex-start' : 'center')};

    position: 'relative';

    background: ${(p) => p.bgColor};
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    border-radius: 16px;

    min-height: 72px;

    padding: ${(p) => (p.notActive ? '16px 16px 0 16px' : '0 16px')};
    margin-bottom: 12px;

    border: ${(p) => (p.checked ? `2px solid ${p.checkedColor}` : '')};
    box-sizing: ${(p) => (p.checked ? 'border-box' : '')};

    background: ${(p) => (p.notActive && !p.checked ? p.bgColor : p.checked ? p.selected : '')};

    box-shadow: ${(p) => (p.checked ? '0px 5px 10px rgba(0, 0, 0, 0.1)' : '')};

    input[type='radio'] {
        display: none;
    }

    .wrapper {
        display: flex;
        flex-direction: row;
        opacity: ${(p) => (p.notActive ? 0.5 : 1)};
    }

    .label-item {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        font-family: 'Montserrat SemiBold';
        font-style: normal;
        /* font-weight: 600; */
        font-size: 16px;
        line-height: 22px;
        color: #404040;
        -webkit-tap-highlight-color: transparent;
        position: 'relative';
    }

    .icon-wrapper {
        width: 18%;
        max-width: 50px;
        display: inline-flex;
        align-items: center;
    }

    .currency-name {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 14px;
        width: calc(100% - 54px);
        font-family: 'SF UI Display SemiBold';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 1px;
        color: #999999;
    }

    .currency-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
    }

    .currency-code {
        font-family: 'Montserrat SemiBold';
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        line-height: 17px;
        color: ${(p) => (p.checked ? p.checkedColor : p.textColor)};
        margin-bottom: 6px;
    }

    .code-wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-bottom: 6px;
    }

    .item-wrapper {
        color: ${(p) => (p.checked ? p.checkedColor : p.textColor)};
    }

    .fiat-wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        font-family: 'SF UI Display SemiBold';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 1px;
        color: ${(p) => (p.checked ? p.checkedColor : '#999999')};
    }

    .balance-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 50%;
    }

    .not-available {
        width: 80%;
        margin: 0 auto;

        padding-top: 10px;
        padding-bottom: 16px;

        font-family: 'SF UI Display SemiBold';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;

        text-align: center;
        letter-spacing: 1px;

        color: ${(p) => p.textColor};
    }
`

export default ItemWrapper
