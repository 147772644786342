import styled from 'styled-components'

const Modal = styled.div`
    position: fixed;
    overflow: hidden;
    top: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    /* opacity: 0; */
    z-index: 301;
    margin: 0 auto;
    transform: translateY(100%);
    transition: all ease-in-out 0.2s;
    &.fade-in {
        top: 0;
        transform: translateY(0);
        transition: all ease-in-out 0.2s;
    }
    &.fade-out {
        top: 0%;
        transform: translateY(100%);
        transition: all ease-in-out 0.3s;
    }

    .background {
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        z-index: 1040;
        display: block;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        outline: 0;
    }
    .box-dialog {
        z-index: 1050;
        max-width: 768px;
        height: 100%;
        background: ${(p) => p.bgColor};
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        position: fixed;
    }
`

export default Modal
