import Log from './sentry/Log'
import utilsService from './utilsService'

const limitForKsu = (branch, limitsArray = [], provider) => {
    Log.info(`V3_limitForKsu init ${JSON.stringify({ branch, limitsArray, provider: JSON.stringify(provider) })}`)

    if (!provider) {
        return null
    }

    let min

    try {
        const limits = limitsArray.find((way) => way.exchangeWayId === provider.exchangeWayId)
        min = limits.min
    } catch (e) {
        Log.error(`V3_limitForKsu error ${JSON.stringify(e)}`)
        min = 0
    }

    if (branch !== 'SELL') {
        return min
    } else {
        min = utilsService.cutNumber(Number(min / provider.equivalentData.exchangeRate), 8)
        return min
    }
}

export default limitForKsu
