import React, { useEffect, useState } from 'react'
import moment from 'moment'
import 'moment/locale/ru'
import 'moment/locale/uk'

import _forEach from 'lodash/forEach'
import InfiniteScroll from 'react-infinite-scroll-component'
import Swipeout from 'rc-swipeout'
import 'rc-swipeout/assets/index.css'
import axios from 'axios'

import PullRefresh from 'react-pullrefresh'
import { Helmet } from 'react-helmet'

import Modal from 'modules/newModal/MainModal'
import SearchScreenModal from 'modules/searchModal/mainModal'

import Loader from 'components/Loader/loader'
import Wrapper from 'components/ScreenWrapper/Wrapper'
import TopNavigation from 'components/TopNavigation/TopNavigation'
import { Content } from 'components/wrapperScreen/wrapper.css'
import Message from 'components/InfoMsg/Message'

import config from 'config/config'
import { strings } from 'i18n'
import { getThemeModeStyles } from 'theme/useDarkMode'

import store from 'redux/index'
import { getHistoryOrderAction, setOrderAction, reloadOrdersAction, historySearchDataAction } from 'redux/AppStores/OrderHistoryStore/action'
import { showSearchScreenModal } from 'redux/AppStores/SearchModalScreenStore/action'

import { serverRequest } from 'services/getActualWays'
import Log from 'services/sentry/Log'

import BlackLoader from 'img/icon/loaderUniverse.svg'
import OrderInProcess from 'img/orderInProcessOpt.svg'
import OrderWasCanceled from 'img/canceledOrderOpt.svg'

import HistoryDiv from './orders.css'
import HistoryItem from './OrderHistoryItem/HistoryItem'

const { dispatch } = store

const swipeoutDeleteBackground = (bg, color) => {
    const data = document.getElementsByClassName('rc-swipeout-content')
    const rightButton = document.getElementsByClassName('rc-swipeout-actions-right')

    if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
            data[i].style.background = 'none'
        }
    }

    if (rightButton.length > 0) {
        const d = `M9.924,11.592v7.267c0,0.364,0.296,0.66,0.66,0.66c0.364,0,0.66-0.296,0.66-0.66v-7.267c0-0.364-0.296-0.66-0.66-0.66 C10.22,10.932,9.924,11.228,9.924,11.592z M12.754,11.592v7.267c0,0.364,0.296,0.66,0.66,0.66s0.66-0.296,0.66-0.66v-7.267 
            c0-0.364-0.296-0.66-0.66-0.66S12.754,11.228,12.754,11.592z M17.524,5.062h-1.867V4.264C15.656,3.016,14.641,2,13.392,2h-2.747 C9.396,2,8.381,3.016,8.381,4.264v0.798H6.477c-1.487,0-2.697,1.21-2.697,2.696v0.102c0,0.921,0.723,1.676,1.632,1.727l1.116,9.966 C6.684,20.949,7.829,22,9.191,22h5.684c1.367,0,2.513-1.055,2.665-2.455l1.077-9.959c0.895-0.065,1.603-0.814,1.603-1.725V7.759 C20.22,6.272,19.01,5.062,17.524,5.062z M17.264,9.607l-1.06,9.79c-0.076,0.709-0.648,1.244-1.329,1.244H9.191 
            c-0.679,0-1.25-0.533-1.329-1.238L6.766,9.607H17.264z M9.723,4.264c0-0.508,0.413-0.922,0.922-0.922h2.747 c0.508,0,0.922,0.413,0.922,0.922v0.798h-4.59L9.723,4.264L9.723,4.264z M18.491,8.248H5.51c-0.213,0-0.387-0.174-0.387-0.387V7.759 c0-0.746,0.608-1.354,1.354-1.354h11.046c0.746,0,1.354,0.607,1.354,1.354v0.102C18.877,8.074,18.704,8.248,18.491,8.248z`
        for (let i = 0; i < rightButton.length; i++) {
            rightButton[i].children[0].innerHTML = `
            <div class="rc-swipeout-btn"
                style="background: ${bg}; -webkit-tap-highlight-color: transparent; 
                width: 50px;
                height: 50px;
                border-radius: 50%;             
                ">
                <div class="rc-swipeout-btn-text">
                <svg class="" viewBox="0 0 24 24" width="24px" height="24px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" style="display: inline-block; vertical-align: middle;">
                <path fill="${color}" d=${d}
                 ></path>
                </svg>
                </div>
            </div>
        `
        }
    }
}

const deleteOrder = async (el, setSections) => {
    const mobileMarket = store.getState().mobileMarket

    try {
        const body = {
            cashbackToken: mobileMarket.cashbackToken,
            orderHash: el.orderHash,
            sign: {
                message: mobileMarket.sign.message,
                messageHash: mobileMarket.sign.messageHash,
                signature: mobileMarket.sign.signature
            }
        }

        let result = await axios.post(`${config.backendUrl}/order/hide-from-history`, body)

        result = result.data

        if (result && result.message && result.message.toUpperCase() === 'SUCCESS') {
            const state = store.getState().orderHistoryStore

            const array = []

            _forEach(state.orders, (data) => {
                if (data.orderHash !== el.orderHash) array.push(data)
            })

            const data = createSection(array)
            setSections(data)

            dispatch(getHistoryOrderAction(state.currentPage, array, state.pages, state.serverTime))
        }
    } catch (e) {
        console.log(JSON.stringify(e))
    }
}

const createSection = (array) => {
    moment.locale(localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'en')

    array.forEach((order, i) => {
        const timestamp = Number(order.createdAt)
        if (!isNaN(timestamp) && timestamp !== 0) {
            let tmp = moment(timestamp).startOf('day').utc(true).format('MMM DD, YYYY')
            // tmp.format('MMM.DD.YYYY')

            order.receivedAtDay = tmp
        } else {
            order.receivedAtDay = null
        }
    })

    const grouped = array.reduce((grouped, order) => {
        if (!grouped[order.receivedAtDay]) grouped[order.receivedAtDay] = []
        grouped[order.receivedAtDay].push(order)
        return grouped
    }, {})

    const data = []

    _forEach(grouped, (arr, date) => {
        if (date !== null && arr.length) {
            data.push({
                title: date,
                data: arr
            })
        }
    })

    return data
}

const main = async (setLoader, setSections, setHasMore, setNotic) => {
    const state = store.getState().orderHistoryStore
    setSections([])
    setNotic(false)

    try {
        let history

        if (!state.orders || (state.orders && !state.orders.length)) {
            history = await serverRequest(getUrlRequest(state), strings('mobileMarket.modal.serverRespond'), 'WALLET_DATA')
        } else {
            history = state
        }

        if (history) {
            if (history?.orders.length) {
                const data = createSection(history.orders)
                setSections(data)

                // setTimeout(() => {
                setLoader(false)
                // }, 5e3)

                dispatch(getHistoryOrderAction(history.currentPage, history.orders, history.pages, history.serverTime))

                if (history.currentPage < history.pages) {
                    setHasMore(true)
                }
            } else {
                setLoader(false)
                setNotic(true)
            }
        } else {
            Log.captureError('ERROR')

            window.history.back()
            setLoader(false)
        }
    } catch (e) {
        console.log(JSON.stringify(e))
        Log.captureError('ERROR')

        window.history.back()
        setLoader(false)
    }
}

const getUrlRequest = (state) => {
    const stateMobile = store.getState().mobileMarket

    let link = `/order/history-for-bse?cashbackToken=${stateMobile.cashbackToken}&signMessage=${stateMobile.sign.message}&signMessageHash=${stateMobile.sign.messageHash}&signature=${stateMobile.sign.signature}`

    if (state.searchData?.amount) {
        link += `&amount=${state.searchData?.amount}`
    }
    if (state.searchData?.exchangeWays) {
        let exhangeWays = ''
        if (state.searchData?.exchangeWays?.buy === 1) exhangeWays += 'BUY,'
        if (state.searchData?.exchangeWays?.sell === 1) exhangeWays += 'SELL,'
        if (state.searchData?.exchangeWays?.exchange === 1) exhangeWays += 'EXCHANGE,'
        link += `&exchangeWayType=${exhangeWays}`
    }

    if (state.searchData?.fromTimestamp) {
        link += `&fromTimestamp=${state.searchData?.fromTimestamp}`
    }

    if (state.searchData?.toTimestamp) {
        link += `&toTimestamp=${state.searchData?.toTimestamp}`
    }

    return link
}

const OrderHistory = () => {
    const mobileMarket = store.getState().mobileMarket
    const modalState = store.getState().modalStore
    const searchModalScreenState = store.getState().searchModalScreenStore
    const state = store.getState().orderHistoryStore
    const theme = getThemeModeStyles()

    const [loader, setLoader] = useState(false)
    const [sections, setSections] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const [notic, setNotic] = useState(false)

    const refreshLoader = async () => {
        return new Promise((resolve, reject) => {
            let loaderRefresh = document.getElementsByClassName('sc-bdVaJa')[0]
            if (loaderRefresh) {
                loaderRefresh.style.top = `calc(${loaderRefresh.style.top} + 30px)`
                loaderRefresh.style.transition = 'all 0.4s'
                setTimeout(() => {
                    loaderRefresh.style.transition = ''
                }, 1000)
            }

            const result = refreshOrders()

            if (result) {
                setTimeout(() => {
                    resolve()
                }, 500)
            }
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        main(setLoader, setSections, setHasMore, setNotic)

        return () => {
            const localState = store.getState().orderHistoryStore
            if (localState.searchData) {
                dispatch(setOrderAction(null))
                dispatch(historySearchDataAction(null))
                dispatch(getHistoryOrderAction(false, false, false, false))
                setSections([])
            }
        }
    }, [])

    const goToFilter = () => {
        const stateMobile = store.getState().mobileMarket

        showSearchScreenModal({
            type: 'FILTER_MODAL',
            data: {
                main: () => main(setLoader, setSections, setHasMore, setNotic),
                url: `cashbackToken=${stateMobile.cashbackToken}&signMessage=${stateMobile.sign.message}&signMessageHash=${stateMobile.sign.messageHash}&signature=${stateMobile.sign.signature}`
            }
        })
    }

    const getMoreOrders = async () => {
        const state = store.getState().orderHistoryStore

        if (state.currentPage === state.pages) {
            setHasMore(false)
        }

        let link = getUrlRequest(state)

        if (state.currentPage < state.pages) {
            link += `&page=${state.currentPage + 1}`
            const moreData = await serverRequest(link, strings('mobileMarket.modal.serverRespond'), 'WALLET_DATA')

            let data
            if (moreData) {
                data = createSection([...state.orders, ...moreData.orders])
                setSections(data)

                dispatch(getHistoryOrderAction(moreData.currentPage, [...state.orders, ...moreData.orders], moreData.pages, moreData.serverTime))

                if (moreData.currentPage === moreData.pages) {
                    setHasMore(false)
                } else {
                    setHasMore(true)
                }
            } else {
                setSections([])
                setHasMore(false)
            }
        }
    }

    const refreshOrders = async () => {
        const state = store.getState().orderHistoryStore

        const history = await serverRequest(getUrlRequest(state), strings('mobileMarket.modal.serverRespond'), 'WALLET_DATA')

        let data
        if (history) {
            data = createSection(history.orders)
            setSections(data)
            dispatch(getHistoryOrderAction(history.currentPage, history.orders, history.pages, history.serverTime))
        } else {
            setSections([])
        }

        dispatch(reloadOrdersAction(false))
        return true
    }

    if (state.reloadOrders) {
        refreshOrders()
    }

    return (
        <>
            <Helmet>
                <link rel='preload' href={OrderInProcess} as='image' type='image/svg+xml' />
                <link rel='preload' href={OrderWasCanceled} as='image' type='image/svg+xml' />
            </Helmet>
            {mobileMarket.showLoader && <Loader />}
            <Content background={theme.main.bg}>
                <TopNavigation
                    text={strings('mobileMarket.navigation.orderHistory')}
                    leftType='back'
                    rightType='filter'
                    rightAction={goToFilter}
                    rightComponentStyle={{
                        background: state.searchData ? theme.main.checked : 'transparent',
                        color: state.searchData ? theme.orderHistory.filter.activeColor : ''
                    }}
                />
                <PullRefresh onRefresh={refreshLoader} color='#999' bgColor={theme.topNav.background}>
                    <Wrapper contentStyle={{ paddingLeft: 0, paddingRight: 0 }}>
                        {loader && (
                            <>
                                <Loader />
                            </>
                        )}
                        <HistoryDiv color={theme.topNav.colorIcon}>
                            <div id='scrollableDiv' className='scroll'>
                                {notic ? (
                                    <div>
                                        <Message theme={theme} text={strings('mobileCheck.filter.notFound')} />
                                    </div>
                                ) : (
                                    <InfiniteScroll
                                        dataLength={state.orders}
                                        next={getMoreOrders}
                                        hasMore={hasMore}
                                        loader={
                                            <div className='loader'>
                                                <img width={54} src={BlackLoader} alt='loader' />
                                            </div>
                                        }
                                        scrollableTarget='scrollableDiv'>
                                        {sections.map((item, index) => {
                                            return (
                                                <div style={{ marginTop: 12, paddingBottom: 24 }} key={index}>
                                                    <div className='title'>{item.title}</div>
                                                    <div style={{ marginTop: 12 }}>
                                                        {item.data
                                                            .filter((order) => order?.isHidden !== true)
                                                            .map((el) => {
                                                                let inCurrency, outCurrency
                                                                inCurrency = {
                                                                    code: el.inCurrencyCode,
                                                                    type: el.inCurrencyType,
                                                                    symbol: el.inCurrencySymbol
                                                                }
                                                                outCurrency = {
                                                                    code: el.outCurrencyCode,
                                                                    type: el.outCurrencyType,
                                                                    symbol: el.outCurrencySymbol
                                                                }

                                                                setTimeout(
                                                                    async () =>
                                                                        swipeoutDeleteBackground(
                                                                            theme.paymentScreen.roundBtn.bg,
                                                                            theme.paymentScreen.roundBtn.color
                                                                        ),
                                                                    0
                                                                )
                                                                return (
                                                                    <Swipeout
                                                                        right={
                                                                            el.canBeDeleted
                                                                                ? [
                                                                                      {
                                                                                          style: {
                                                                                              color: 'black',
                                                                                              background: 'transparent',
                                                                                              webkitTapHighlightColor: 'transparent',
                                                                                              width: 80,
                                                                                              marginRight: 24,
                                                                                              marginBottom: 16
                                                                                          },
                                                                                          onPress: async () => await deleteOrder(el, setSections)
                                                                                      }
                                                                                  ]
                                                                                : []
                                                                        }
                                                                        style={{ marginLeft: 0, paddingBottom: 6 }}
                                                                        autoClose={true}
                                                                        key={el.orderHash}>
                                                                        <HistoryItem
                                                                            inCurrency={inCurrency}
                                                                            outCurrency={outCurrency}
                                                                            orderStatus={el.status}
                                                                            inAmount={el.amountRequested}
                                                                            outAmount={el.amountWithdrawn ? el.amountWithdrawn : el.amountExpected}
                                                                            typeProvider={el.rateType}
                                                                            orderHandler={() => {
                                                                                showSearchScreenModal({
                                                                                    type: 'ORDER_MODAL',
                                                                                    data: {}
                                                                                })
                                                                                dispatch(
                                                                                    setOrderAction({
                                                                                        preCheck: el.checkType === 'PRE_CHECK' ? el : null,
                                                                                        finalCheck: el.checkType === 'FINAL_CHECK' ? el : null
                                                                                    })
                                                                                )
                                                                                return
                                                                            }}
                                                                            theme={theme}
                                                                        />
                                                                    </Swipeout>
                                                                )
                                                            })}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </InfiniteScroll>
                                )}
                            </div>
                        </HistoryDiv>
                    </Wrapper>
                </PullRefresh>
            </Content>
            {modalState.show && <Modal />}
            {searchModalScreenState.searchShow && <SearchScreenModal />}
        </>
    )
}

export default React.memo(OrderHistory)
