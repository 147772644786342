import styled from 'styled-components'

const ScreenDiv = styled.div`
    .percent__wrapper {
        display: flex;
        font-family: 'Montserrat Medium';
        font-size: 22px;
        line-height: 26px;
        letter-spacing: 0.5px;
        color: #5c5c5c;
    }

    .wrapper__percent {
        display: flex;
        flex-direction: row;
        /* justify-content: center; */
        /* align-items: center; */
    }

    @keyframes zoominoutsinglefeatured {
        0% {
            transform: scale(1, 1);
        }
        50% {
            transform: scale(1.3, 1.3);
        }
        100% {
            transform: scale(1, 1);
        }
    }

    .variantWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
    }

    .progressWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        width: 100%;
        padding-bottom: 16px;
    }

    .progress {
        position: absolute;
        width: 75px;
        max-width: 500px;
        transition: margin-left 400ms linear;
    }

    .progressBar {
        height: 16px;
        /* max-width: 500px; */
        background-color: ${(p) => p.loaderBG};
        border-radius: 24px;
        transition: width 400ms linear;
        background-image: ${(p) => p.rocketLoader};
        background-size: 150px 20px;
        background-position: 100% 0;
        animation: loading 0.5s infinite;
    }

    @keyframes loading {
        40% {
            background-position: -100px 0;
        }
        100% {
            background-position: -100px 0;
        }
    }

    .progressBarFull {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 38px;
        /* width: 100%; */
        background-color: ${(p) => p.loaderBG};
        border-radius: 10px;
        animation: moveProgressBarHeight 1s forwards;
    }

    @keyframes moveProgressBarHeight {
        0% {
            padding: 0;
            margin-top: 8px;
            border-radius: 7px;
        }
        100% {
            padding: 6px 0;
            margin-top: 0;
            border-radius: 10px;
        }
    }

    .progressBarText {
        text-align: center;
        font-family: 'Montserrat Bold';
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 1px;
        color: ${(p) => p.loaderColor};
        animation: moveProgressBarText 1s forwards;
    }

    @keyframes moveProgressBarText {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`

export default ScreenDiv
