import React from 'react'
import StarRatings from 'react-star-ratings'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import getProviderName from '../providersSettings/providerName'
import IconProvider from '../providersSettings/providerIcon'

import config from '../../config/config'

import { ProviderDetailsDiv } from './elements.css'

const ProviderData = (props) => {
    const { name, icon, rating, iconStyle, condition, theme, skeleton } = props

    const path =
        'M4.54894 0.927052C4.8483 0.00574136 6.1517 0.0057404 6.45106 0.927051L6.95934 2.49139C7.09321 2.90341 7.47717 3.18237 7.9104 3.18237H9.55524C10.524 3.18237 10.9267 4.42199 10.143 4.99139L8.81232 5.9582C8.46183 6.21285 8.31518 6.66422 8.44905 7.07624L8.95733 8.64058C9.25669 9.56189 8.20221 10.328 7.41849 9.75861L6.08779 8.7918C5.7373 8.53715 5.2627 8.53715 4.91221 8.7918L3.58151 9.75861C2.7978 10.328 1.74331 9.56189 2.04267 8.64058L2.55095 7.07624C2.68483 6.66422 2.53817 6.21285 2.18768 5.9582L0.856976 4.99139C0.0732617 4.42199 0.476037 3.18237 1.44476 3.18237H3.0896C3.52283 3.18237 3.90678 2.90341 4.04066 2.49139L4.54894 0.927052Z'

    return (
        <ProviderDetailsDiv textColor={theme.topNav.color}>
            <div className='icon__wrapper' style={{ ...iconStyle }}>
                {skeleton ? (
                    <Skeleton circle height={40} width={40} duration={config.skeletonAnimationDuration} />
                ) : (
                    <IconProvider
                        provider={icon} // status={item.equivalentData ? item.equivalentData.status : 'FAIL'}
                        status='SUCCESS'
                    />
                )}
            </div>
            <div className='providerDetails'>
                <div className='providerName'>
                    {skeleton ? <Skeleton height={16} style={{ width: 80 }} duration={config.skeletonAnimationDuration} /> : getProviderName(name)}
                </div>
                {skeleton ? (
                    <div style={{ width: 80, marginBottom: -4, marginTop: 4 }}>
                        <Skeleton height={16} duration={config.skeletonAnimationDuration} />
                    </div>
                ) : rating ? (
                    <div className='rating__wrapper'>
                        <StarRatings
                            rating={rating}
                            starRatedColor={theme.main.checked}
                            starEmptyColor={theme.provider.rateEmpty}
                            changeRating={null}
                            numberOfStars={5}
                            name='rating'
                            svgIconPath={path}
                            svgIconViewBox='0 0 11 10'
                            starDimension='15px'
                            starSpacing='2px'
                        />
                        <div className='ratingNumber'>{`${rating}`}</div>
                    </div>
                ) : null}
            </div>
        </ProviderDetailsDiv>
    )
}

export default ProviderData
