import React from 'react'

import ItemHistory from './HistoryItem.css'

import IconCurrency from '../../../../components/currencies/crypto/cryptoCurrency'

import Loader from '../../../../img/icon/loaderBlack.svg'
import { getCurrencyCode } from '../../../../services/getCurrenciesProperty'
import utilsService from '../../../../services/utilsService'
import { strings } from '../../../../i18n'
import IconFiatCurrency from '../../../../components/currencies/fiat/fiatCurrency'
import Icon from '../../../../components/Icon/Icon'

const prettyStatus = (status) => {
    switch (status.toUpperCase()) {
        case 'PENDING_PAYIN':
            return strings('mobileCheck.pending_payin')
        case 'DONE_PAYIN':
            return strings('mobileCheck.done_payin')
        case 'WAIT_TRADE':
        case 'DONE_TRADE':
            return strings('mobileCheck.done_trade')
        case 'WAIT_PAYOUT':
            return strings('mobileCheck.wait_payout')
        case 'DONE_PAYOUT':
            return strings('mobileCheck.done_payout')
        case 'CANCELED_PAYIN':
            return strings('mobileCheck.canceled_payin')
        case 'CANCELED_PAYOUT':
            return strings('mobileCheck.canceled_payout')
        case 'ERROR_ORDER':
            return strings('mobileCheck.error_order')
        case 'WAIT_KYC':
            return strings('mobileCheck.wait_kyc')
        case 'REFUNDED_ORDER':
            return strings('mobileCheck.refunded_order')
        case 'NOT_ENTIRE_WITHDRAW':
            return strings('mobileCheck.not_entire_withdraw')
        default:
            return null
    }
}

const CurrencyStatus = (props) => {
    const { inCurrency, outCurrency, orderStatus, inAmount, outAmount, orderHandler, type, color, viewStatus, viewStatusColor, theme } = props

    return (
        <ItemHistory
            type={type}
            color={orderStatus === 'CANCELED_PAYIN' || orderStatus === 'CANCELED_PAYOUT' || orderStatus === 'ERROR_ORDER' ? null : color}
            viewStatusColor={viewStatusColor}
            onClick={orderHandler}
            colorStatus={orderStatus === 'DONE_PAYOUT' ? color : null}
            dashColor={theme.orderHistory.dashColor}
            valueColor={theme.topNav.color}
            subValueColor={theme.topNav.colorIcon}
            statusBg={theme.orderHistory.statusBg}
            showStatusBg={theme.orderHistory.orderData.bg}>
            <div className='wrapper'>
                <div className='iconCurrency'>
                    {inCurrency.type === 'CRYPTO' ? (
                        <IconCurrency currencyCode={inCurrency.code} theme={theme} />
                    ) : (
                        <IconFiatCurrency currencyCode={inCurrency.code} theme={theme} />
                    )}
                    <div className='dash'></div>
                    {viewStatus ? (
                        <div className='viewStatus'>
                            <div className='statusValue'>{prettyStatus(orderStatus)}</div>
                        </div>
                    ) : (
                        <div className='status'>
                            {orderStatus === 'CANCELED_PAYIN' || orderStatus === 'CANCELED_PAYOUT' || orderStatus === 'ERROR_ORDER' ? (
                                <Icon icon='CLOSE' size={12} />
                            ) : orderStatus === 'DONE_PAYOUT' ? (
                                <Icon icon='CHECK' color={theme.orderHistory.check} />
                            ) : (
                                <img src={Loader} alt='loader' style={{ height: 24 }} />
                            )}
                        </div>
                    )}
                    {outCurrency.type === 'CRYPTO' ? (
                        <IconCurrency currencyCode={outCurrency.code} theme={theme} />
                    ) : (
                        <IconFiatCurrency currencyCode={outCurrency.code} theme={theme} />
                    )}
                </div>
                <div className='detailsWrap'>
                    <div>
                        <div className='currency inCurrency'>{inCurrency.symbol}</div>
                        <div className='value inValue'>{utilsService.cutNumber(inAmount, 8)}</div>
                    </div>
                    <div>
                        <div className='currency outCurrency'>{outCurrency.symbol}</div>
                        <div className='value outValue'>{outAmount}</div>
                    </div>
                </div>
            </div>
        </ItemHistory>
    )
}

export default CurrencyStatus
