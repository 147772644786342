import styled from 'styled-components'

const PaymentBlockDiv = styled.div`
    width: 100%;

    margin-top: 30px;

    .not-card {
        width: 232px;
        height: 64px;
        background: ${(p) => p.notCardBg};
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
        border-radius: 14px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .card-dotted-line {
        width: calc(100% - 16px);
        height: calc(100% - 16px);
        border: 2px dotted ${(p) => p.notCardDash};
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Montserrat Bold', sans-serif;
        font-style: normal;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: ${(p) => p.notCardText};
    }

    .payment-name {
        font-family: 'SF UI Display Bold';
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 13px;
        letter-spacing: 1.75px;
        color: #999999;
        text-transform: uppercase;
        margin: 0 16px 7px 16px;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .details-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        width: calc(100% - 32px);
        padding: 0 16px;
        background: ${(p) => p.paymentBg};
        border-radius: 10px;
    }

    .details-account {
        font-family: 'Montserrat Bold';
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 16px;
        color: ${(p) => p.paymentColor};
    }

    .change-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Montserrat Medium';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        text-decoration: none;
        color: ${(p) => p.changeBtn};
    }

    .change-arrow {
        /* width: 20px; */
    }
`

export default PaymentBlockDiv
