import styled from 'styled-components'

const BottomBtnsDiv = styled.div`
    .buttons {
        display: flex;
        flex-direction: row;
        bottom: 24px;
        position: fixed;
        max-width: 736px;
        justify-content: space-between;
        right: 16px;
        left: 16px;

        @media screen and (min-width: 768px) {
            left: calc((100% - 736px) / 2);
        }
    }

    .button-back {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 64px;
        height: 50px;
        border: none;
        outline: none;
        background: ${(p) => p.btnBg};
        box-shadow: 0 6px 10px rgba(64, 64, 64, 0.15);
        border-radius: 10px;
        margin-right: 16px;
        -webkit-tap-highlight-color: transparent;
    }

    .focus-btn {
        display: flex;
        flex-direction: row;
        margin-top: 150px;
        padding-bottom: ${window.screen.height / 3}px;
        max-width: 768px;
        justify-content: space-between;
    }
`

export default BottomBtnsDiv
