import styled from 'styled-components'

const NotificationDiv = styled.div`
    display: flex;
    flex-direction: row;
    padding: 16px 18px;
    border-radius: 16px;

    background: ${(p) => p.bg};

    .main {
        flex-direction: column;
        justify-content: center;

        padding-left: 10px;
    }

    .title {
        font-family: 'Montserrat SemiBold';
        font-size: 17px;
        line-height: 22px;

        color: ${(p) => p.colorTitle};
    }

    .description {
        font-family: 'SF UI Display Regular';
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.5px;

        color: ${(p) => p.colorDescription};

        padding-top: 6px;
    }
`

export default NotificationDiv
