import styled from 'styled-components'

export const CardItemDiv = styled.div`
    width: 100%;
    height: 82px;

    background: ${(p) => (p.checked ? p.bgChecked : p.bg)};
    border: ${(p) => (p.checked ? `2px solid ${p.checked ? p.checkedColor : !p.country ? p.notActiveColor : p.normColor}` : null)};
    box-sizing: border-box;
    border-radius: 16px;
    box-shadow: ${(p) => (p.checked ? '' : '0px 5px 10px rgba(0, 0, 0, 0.1)')};
    /* 0px 0px 10px rgba(134, 77, 217, 0.2) */

    padding: 16px;
    margin-top: 16px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .main__data {
        display: flex;
        flex-direction: column;
        align-self: center;
        justify-content: space-around;

        /* width: 50%; */
        width: calc(50% - 24px);
        height: 100%;
    }

    .card__name {
        font-family: 'SF UI Display Bold';
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 13px;
        letter-spacing: 1.75px;
        text-transform: uppercase;
        color: #999999;
    }

    .card__number {
        font-family: 'Montserrat Bold';
        font-style: normal;
        /* font-weight: bold; */
        font-size: 16px;
        line-height: 16px;
        color: ${(p) => p.text1};
    }
`

export const DotsDiv = styled.div`
    .wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;

        width: 18px;
        height: 22px;
        -webkit-tap-highlight-color: transparent;
        padding: 0 6px 6px 6px;
    }

    .dot {
        background: ${(p) => p.dotBg};
        /* background: ${(p) => (p.checked ? p.dotBg : '#999')}; */
        width: 7px;
        height: 7px;

        border-radius: 50%;

        margin-top: 4px;
    }
`
