/**
 * @version 0.1
 * @author yura
 */

import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import Helmet from 'react-helmet'
import { Route, Switch } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { useSnackbar } from 'notistack'
import { ThemeProvider } from 'styled-components'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { GlobalStyles } from 'theme/globalStyles'
import { useDarkMode } from 'theme/useDarkMode'

import SFRegular from 'fonts/sf_ui_display_regular.ttf'
import SFBold from 'fonts/SFUIDisplay-Bold.ttf'
import SFHeavy from 'fonts/SFUIDisplay-Heavy.ttf'
import SFMedium from 'fonts/SFUIDisplay-Medium.ttf'
import SFSemibold from 'fonts/SFUIDisplay-Semibold.ttf'
import MBold from 'fonts/Montserrat-Bold.ttf'
import MExtraBold from 'fonts/Montserrat-ExtraBold.ttf'
import MMedium from 'fonts/Montserrat-Medium.ttf'
import MSemibold from 'fonts/Montserrat-SemiBold.ttf'
import loaderBlack from 'img/icon/loaderUniverse.svg'

import KYC from 'img/picVerif.svg'
import ThreeInOneEn from 'img/slider/3in1_eng.svg'
import ThreeInOneUk from 'img/slider/3in1_uk.svg'
import ThreeInOneRu from 'img/slider/3in1_ru.svg'
import ThereInOneEnDark from 'img/slider/dark/3in1_en_d.svg'
import ThereInOneUkDark from 'img/slider/dark/3in1_ua_d.svg'
import ThereInOneRuDark from 'img/slider/dark/3in1_ru_d.svg'

import MobileMarket from 'redux/AppStores/MobileMarket/mobileMarketAction'
import { getUserSettings, getWalletData } from 'redux/AppStores/MobileMarket/selectors'

import mainScreen from 'modules/newFlow/mainScreen/mainScreen'
import preCheckScreen from 'modules/newFlow/preCheckScreen/preCheckScreen'

import RenderFirstScreen from './firstScreen/renderFirstScreen'
import RenderPreCheckScreen from './preCheck/renderPreCheckScreen'
import RenderPaymentDetailsScreen from './paymentDetails/paymentDetails'
import RenderManagementPaymentDetailsScreen from './paymentDetails/managementPaymentDetails'
import RenderAdvancedScreen from './advancedScreen/renderAdvancedScreen'
import SumSub from './KYC/sumSub'
import OrderHistory from './OrderHistory/renderOrderHistory'

const MobileMarketScreen = () => {
    // const state = useSelector((state) => state.mobileMarket)
    const userSettings = useSelector(getUserSettings)
    const walletData = useSelector(getWalletData)

    const [theme, themeMode, themeToggler, mountedComponent] = useDarkMode()

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    useEffect(() => {
        MobileMarket(enqueueSnackbar)
    }, [])

    // console.log('state', state)

    const locales = walletData ? walletData.locale : 'en'

    // if (!mountedComponent) return <div />

    const newFlow = true
    // userSettings.versionNumber === 'VERSION' || userSettings.versionNumber * 1 > 1066

    return (
        <>
            <ThemeProvider theme={themeMode}>
                <GlobalStyles />
                <Helmet>
                    <link rel='preload' href={loaderBlack} as='image' type='image/svg+xml' />
                    <link rel='preload' href={KYC} as='image' type='image/svg+xml' />
                    <link
                        rel='preload'
                        href={
                            theme === 'dark'
                                ? locales === 'ru'
                                    ? ThereInOneRuDark
                                    : locales === 'uk'
                                    ? ThereInOneUkDark
                                    : ThereInOneEnDark
                                : locales === 'ru'
                                ? ThreeInOneRu
                                : locales === 'uk'
                                ? ThreeInOneUk
                                : ThreeInOneEn
                        }
                        as='image'
                        type='image/svg+xml'
                    />
                    <link rel='preload' href={SFRegular} as='font' type='font/ttf' crossOrigin='anonymous' />
                    <link rel='preload' href={SFBold} as='font' type='font/ttf' crossOrigin='anonymous' />
                    <link rel='preload' href={SFHeavy} as='font' type='font/ttf' crossOrigin='anonymous' />
                    <link rel='preload' href={SFSemibold} as='font' type='font/ttf' crossOrigin='anonymous' />
                    <link rel='preload' href={SFMedium} as='font' type='font/ttf' crossOrigin='anonymous' />
                    <link rel='preload' href={MBold} as='font' type='font/ttf' crossOrigin='anonymous' />
                    <link rel='preload' href={MExtraBold} as='font' type='font/ttf' crossOrigin='anonymous' />
                    <link rel='preload' href={MSemibold} as='font' type='font/ttf' crossOrigin='anonymous' />
                    <link rel='preload' href={MMedium} as='font' type='font/ttf' crossOrigin='anonymous' />
                    <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no' />
                </Helmet>
                <div style={{ backgroundColor: themeMode.main.bg, width: '100%', height: '100%', position: 'fixed' }}>
                    <Route
                        render={({ location }) => (
                            <>
                                <TransitionGroup
                                    childFactory={(child) =>
                                        React.cloneElement(child, {
                                            classNames: typeof location.state !== 'undefined' && location.state.type ? location.state.type : 'page',
                                            timeout: 250
                                        })
                                    }>
                                    <CSSTransition key={location.key}>
                                        <Switch location={location}>
                                            <Route exact path='/mobile-market' component={newFlow ? mainScreen : RenderFirstScreen} />
                                            <Route path='/mobile-market/pre-check' component={newFlow ? preCheckScreen : RenderPreCheckScreen} />
                                            {/* <Route path={'/mobile-market/provider-info'} component={RenderProviderScreen} /> */}
                                            <Route exact path='/mobile-market/payment-details' component={RenderPaymentDetailsScreen} />
                                            <Route
                                                path='/mobile-market/payment-details/management'
                                                component={RenderManagementPaymentDetailsScreen}
                                            />
                                            <Route path='/mobile-market/settings' component={RenderAdvancedScreen} />
                                            <Route path='/mobile-market/verification' component={SumSub} />
                                            <Route path='/mobile-market/history' component={OrderHistory} />
                                        </Switch>
                                    </CSSTransition>
                                </TransitionGroup>
                            </>
                        )}
                    />
                </div>
            </ThemeProvider>
            <ToastContainer
                position='top-center'
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover={false}
            />
        </>
    )
}

export default React.memo(MobileMarketScreen)
