import React from 'react'
import { useCallback } from 'react'

import Line from 'components/Line/line'
import PaywayListItem from 'components/PaywayModalListItem/paywayItem'
import regionForCash from 'components/json/regionForCash.json'

import { strings } from 'i18n'

import { hideScreenModal } from 'redux/AppStores/ScreenModalStore/action'

import hideAnimation from 'services/hideAnimation'

import { BodyWrapper } from '../wrapper.css'

const RegionCityModal = (props) => {
    const { theme, side, data } = props

    let cities = []
    regionForCash
        .filter((item) => item.countryCode !== 'RUS')
        .filter((item) => (data.region ? data.region === item.region : true))
        .map((item) => {
            item.cities.map((way) => {
                const city = {
                    country: way,
                    countryCode: item.countryCode,
                    region: item.region
                }
                cities.push(city)
            })
        })

    const countrySelectHandler = useCallback((side) => {
        hideAnimation(`selectModal-${side.toString()?.toLowerCase()}`, hideScreenModal)
        return
    }, [])

    return (
        <>
            <BodyWrapper style={{ marginTop: 54 }} labelColor={theme.topNav.colorIcon}>
                {cities.map((way, index) => {
                    const tmpWay = {
                        ...way,
                        cityRegion: strings(`mobileMarket.cash.${way.country.toLowerCase()}`)
                    }

                    return (
                        <>
                            <PaywayListItem
                                key={index}
                                item={tmpWay}
                                index={index}
                                side={side}
                                checked={JSON.stringify(way) === data.city}
                                active={true}
                                handler={countrySelectHandler}
                                // country={true}
                                city={true}
                                theme={theme}
                                data={data}
                            />
                            {cities.length === index + 1 ? <div className='empty'></div> : <Line width='90%' />}
                        </>
                    )
                })}
            </BodyWrapper>
        </>
    )
}

export default React.memo(RegionCityModal)
