export const darkTheme = {
    topNav: {
        background: '#2E2E2E',
        color: '#B8B8B8',
        colorIcon: '#DADADA'
    },

    bottomNav: {
        bg: '#6B6B6B',
        color: '#F7F7F7',
        addBtn: '#353535'
    },

    toolTips: {
        bg: '#2E2E2E',
        color: '#B6B6B6'
    },

    main: {
        bg: '#141414',
        selector: {
            bg: '#4F4F4F',
            errorShadow: '#864DD9'
        },
        checked: '#9E71E1',
        line: '#303030',
        shadowBg: 'linear-gradient(180deg, rgba(20, 20, 20, 0) 0%, #141414 100%)',
        arrow: '#DADADA',
        rocketLoader: 'linear-gradient( 100deg, rgba(92,92, 92, 0), rgba(92,92, 92, 0.5) 60%, rgba(92,92, 92, 0) 80%)',
        skeletonLoader: 'rgb(92,92,92)'
    },

    provider: {
        activeBg: 'linear-gradient(180deg, #252525 0%, #1E1E1E 100%)',
        failBg: '#2E2E2E',
        rateEmpty: 'rgba(158, 113, 225, 0.5)',
        rate: '#999999',
        labelColor: '#404040',
        equivalent: '#B8B8B8',
        failText: '#DADADA'
    },

    paymentScreen: {
        typeBg: '#252525',
        roundBtn: {
            bg: '#6B6B6B',
            color: '#F7F7F7'
        },
        checkedBg: '#332743',
        notActive: '#2E2E2E',
        dotsBg: '#D2CCDA'
    },

    orderHistory: {
        bg: 'linear-gradient(180deg, #252525 0%, #1E1E1E 100%)',
        check: '#404040',
        dashColor: '#4F4F4F',
        statusBg: '#B8B8B8',
        showStatus: '#252525',
        viewStatus: '#B8B8B8',
        orderData: {
            bg: '#252525'
        },
        filter: {
            bgIcon: '#252525',
            activeColor: '#DADADA'
        }
    },

    input: {
        bg: '#1E1E1E',
        textColor: '#999'
    },

    checkData: {
        textColor: '#B8B8B8',
        receiveColor: '#9E71E1',
        amountColor: '#B8B8B8'
    },

    currency: {
        circleBg: '#303030'
    },

    modal: {
        bg: '#141414',
        colorDescription: '#B8B8B8',
        colorHeader: '#DADADA',
        infoColor: '#9E71E1',
        warningColor: '#D6956F',
        infoShadow: 'rgba(134, 77, 217, 0.3)',
        warningShadow: 'rgba(245, 158, 108, 0.3)',
        select: {
            bg: 'linear-gradient(180deg, #252525 0%, #1E1E1E 100%)',
            selected: '#332743',
            notCardBg: '#6B6B6B'
        },
        card: {
            circleBg: '#252525'
        },
        switch: {
            bg: '#303030',
            color: 'linear-gradient(180deg, #6B6B6B 0%, #404040 100%)'
        }
    },

    notification: {
        bg: 'rgba(134, 77, 217, 0.25)',
        colorDescription: 'rgba(204, 180, 238, 0.75)'
    },

    inputBlock: {
        bgColor: '#515151',
        borderColor: '#777777',
        changeBtn: '#9E71E1',
        bgPayments: '#2F2F2F',
        errorBg: '#E4DAC1',
        errorColor: 'rgba(180, 141, 40, 0.8)'
    },

    preCheck: {
        bg: '#515151',
        lineColor: '#858585',
        durationTimeColor: '#DADADA',
        durationTimeBgColor: '#9E71E14D'
    }
}
