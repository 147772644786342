import styled from 'styled-components'

const PaymentDiv = styled.div`
    .card__header {
        width: 100%;
        display: flex;
        padding-bottom: 12px;
        margin: 0 auto;
        flex-direction: row;
        justify-content: space-between;
    }

    .paywayMethod {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-family: 'Montserrat SemiBold', sans-serif;
        font-style: normal;
        /* font-weight: 600; */
        font-size: 14px;
        line-height: 18px;
        color: ${(p) => p.paywayColor};
        width: 55%;
    }

    .paywayWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        background: ${(p) => p.paywayBg};
        box-shadow: inset 0px 0px 20px rgba(0, 0, 0, 0.05);
    }

    .addCardList {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        text-align: center;

        font-family: 'Montserrat Bold', sans-serif;
        font-style: normal;
        /* font-weight: bold; */
        font-size: 11px;
        line-height: 14px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: ${(p) => p.addPaywayColor};
        -webkit-tap-highlight-color: transparent;

        border: 2px solid ${(p) => p.paywayColor};
        border-radius: 10px;
        padding: 2px 6px;
        min-height: 30px;
    }

    .add-btn {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 35%;
    }

    .selected-card {
        width: 100%;
        font-family: 'SF UI Display Bold', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;

        letter-spacing: 0.5px;

        color: #999999;
    }
`

export default PaymentDiv
