import React from 'react'
import { strings } from '../../i18n'

import utilsService from '../../services/utilsService'
import { ErrorCodeDiv } from './provider.css'

const ErrorCode = (props) => {
    const { errorCode, item, exchangeWayType, handlerLimit, theme } = props

    switch (errorCode) {
        case 'EXCEEDING_LIMITS':
            return (
                <ErrorCodeDiv textColor={theme.topNav.color} borderColor={theme.topNav.colorIcon}>
                    <div className='errorWrapper'>
                        <div className='providerLimit'>
                            {item.equivalentData.limit &&
                                strings(`mobileMarket.${item.equivalentData.limit.type.toLowerCase()}`) +
                                    ' ' +
                                    strings('mobileMarket.providerErrors.providerLimits')}
                        </div>
                        <div
                            className='limitBtn'
                            // onClick={(e) => {
                            //     e.stopPropagation();
                            //     handlerLimit(item)
                            // }}
                        >
                            {`${utilsService.cutNumber(item.equivalentData.limit?.amount || 0, exchangeWayType !== 'EXCHANGE' ? 2 : 6)} ${
                                exchangeWayType !== 'SELL' ? item.inCurrency.symbol : item.outCurrency.symbol
                            }`}
                        </div>
                    </div>
                </ErrorCodeDiv>
            )
        case 'COMING_SOON':
            return (
                <ErrorCodeDiv textColor={theme.topNav.color}>
                    <div className='providerError'>{strings('mobileMarket.providerErrors.providerComingSoon')}</div>
                </ErrorCodeDiv>
            )
        case 'PROVIDER_ERROR':
            return (
                <ErrorCodeDiv textColor={theme.topNav.color}>
                    <div className='providerError'>{strings('mobileMarket.providerErrors.providerUnavailable')}</div>
                </ErrorCodeDiv>
            )
        case 'NOT_SUPPORTED_CARD':
            return (
                <ErrorCodeDiv textColor={theme.topNav.color}>
                    <div className='providerError'>{strings('mobileMarket.providerErrors.notSupportedCard')}</div>
                </ErrorCodeDiv>
            )
        default:
            return (
                <ErrorCodeDiv textColor={theme.topNav.color}>
                    <div className='providerError'>{strings('mobileMarket.providerErrors.providerError')}</div>
                </ErrorCodeDiv>
            )
    }
}

export default ErrorCode
