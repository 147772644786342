import React from 'react'
import SwitchDiv from './switchDiv.css'

const Switch = (props) => {
    const { isOn, handleToggle, id, theme } = props

    return (
        <SwitchDiv switchLabelBtn={theme.modal.switch.color} switchLabelBg={theme.modal.switch.bg}>
            <input checked={isOn} onChange={handleToggle} className='reactSwitchCheckbox' id={id} type='checkbox' />
            <label style={{ background: isOn && '#404040' }} className='reactSwitchLabel' htmlFor={id}>
                <span className='reactSwitchButton' />
            </label>
        </SwitchDiv>
    )
}

export default Switch
