import React, { useState } from 'react'
import Lottie from 'react-lottie'

import LoaderUniverse from '../../img/icon/loaderUniverse.svg'
import LoaderWhite from '../../img/icon/loaderWhite.svg'
import { getThemeMode, getThemeModeStyles } from '../../theme/useDarkMode'
import { LoaderWrapper } from './loader.css'

import loaderBlue from '../../jsons/animation/loaderBlue.json'
import loaderDark from '../../jsons/animation/loaderDark.json'
import { strings } from '../../i18n'

const Loader = (props) => {
    const { styles, animation, main } = props

    const [showMessage, setShowMessage] = useState(false)

    const theme = getThemeModeStyles()
    const themeMode = getThemeMode()

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: themeMode === 'dark' ? loaderDark : loaderBlue,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }

    if (main) {
        setTimeout(() => setShowMessage(true), 3e3)
    }

    return (
        <LoaderWrapper style={{ ...styles }} background={theme.main.bg} textColor={theme.topNav.colorIcon} textBg={theme.provider.failBg}>
            {animation ? (
                <Lottie
                    options={defaultOptions}
                    height='60vh'
                    width='100vw'
                    // speed={2}
                />
            ) : (
                <div className='container'>
                    {showMessage && <pre className='text-load'>{strings('mobileMarket.loadInfo')}</pre>}
                    <img src={themeMode === 'dark' ? LoaderWhite : LoaderUniverse} height={70} alt='preloader' />
                </div>
            )}
        </LoaderWrapper>
    )
}

export default React.memo(Loader)
