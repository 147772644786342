import React, { Component } from 'react'
import classes from '../styles/currency.module.css'
import Icon from '../../Icon/Icon'
import UiColor from '../UiColor'
import { getThemeMode } from '../../../theme/useDarkMode'

export default class IconFiatCurrency extends Component {
    render = () => {
        const { currencyCode, colorNotActive, checked, theme } = this.props

        const themeMode = getThemeMode()

        switch (currencyCode) {
            case 'UAH':
            case 'RUB':
            case 'USD':
            case 'KZT':
            case 'EUR':
            case 'GBP':
            case 'TRY':
            case 'AUD':
            case 'BGN':
            case 'BRL':
            case 'CAD':
            case 'CHF':
            case 'CNY':
            case 'COP':
            case 'CZK':
            case 'DKK':
            case 'DOP':
            case 'EGP':
            case 'HKD':
            case 'HRK':
            case 'IDR':
            case 'ILS':
            case 'JOD':
            case 'JPY':
            case 'KES':
            case 'KRW':
            case 'KWD':
            case 'LKR':
            case 'MAD':
            case 'MXN':
            case 'MYR':
            case 'NGN':
            case 'NOK':
            case 'NZD':
            case 'OMR':
            case 'PEN':
            case 'PKR':
            case 'PLN':
            case 'RON':
            case 'SEK':
            case 'SGD':
            case 'THB':
            case 'TWD':
            case 'VND':
            case 'ZAR':
                return (
                    <>
                        <div
                            className={classes.currency__wrapper}
                            style={{
                                borderColor: colorNotActive
                                    ? colorNotActive
                                    : checked
                                    ? theme.main.checked
                                    : UiColor[currencyCode].colors[themeMode === 'light' ? 'mainColor' : 'darkColor']
                            }}>
                            <Icon
                                icon={currencyCode}
                                size={24}
                                color={
                                    colorNotActive
                                        ? colorNotActive
                                        : checked
                                        ? theme.main.checked
                                        : UiColor[currencyCode].colors[themeMode === 'light' ? 'mainColor' : 'darkColor']
                                }
                                style={{ marginTop: 7, marginRight: 8 }}
                            />
                        </div>
                    </>
                )

            default:
                return (
                    <>
                        <div
                            className={classes.currency__wrapper}
                            style={{ borderColor: colorNotActive ? colorNotActive : checked ? theme.main.checked : theme.topNav.colorIcon }}>
                            <Icon
                                icon='FIAT_DEFAULT'
                                size={24}
                                color={colorNotActive ? colorNotActive : checked ? theme.main.checked : theme.topNav.colorIcon}
                                style={{ marginTop: 7, marginRight: 8 }}
                            />
                        </div>
                    </>
                )
        }
    }
}
