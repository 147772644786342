import styled from 'styled-components'

const MsgDiv = styled.div`
    width: calc(100% - 32px);
    display: inline-flex;
    margin-left: 16px;
    margin-right: 16px;
    animation: ${(p) => (p.move ? 'moveText 0.35s forwards' : '')};

    .wrapper {
        display: flex;
        flex-direction: row;
        height: 40px;
        width: 100%;
        margin-bottom: 10px;
    }

    .icon {
        width: 10%;
        margin-top: 8px;
    }

    .text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        flex-wrap: wrap;
        width: 80%;
        font-family: 'SF UI Display Regular', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.5px;
        color: ${(p) => p.colorText};
    }

    @keyframes moveText {
        0% {
            transform: translateX(0%);
        }
        25% {
            transform: translateX(20px);
        }
        75% {
            transform: translateX(-20px);
        }
        100% {
            transform: translateX(0%);
        }
    }
`

export default MsgDiv
