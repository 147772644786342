export default function navigationStateSell() {
    const navigationStateSell = `if (true) {
        const url = data.url

        if (url) {
            if (url.includes('https://trusteeglobal.com/?transactionId') ||
                url.includes('https://trusteeglobal.com/ru/?transactionId') ||
                url.includes('https://trusteeglobal.com/en/?transactionId') ||
                url.includes('https://trusteeglobal.com/uk/?transactionId') ||
                url.includes('https://trusteeglobal.com/fr/?transactionId') ||
                url.includes('https://trusteeglobal.com/ka/?transactionId')
            ) {

                that.handleState({
                    status: 'PENDING'
                })

                that.handleStartAnimation()

                let data = that.state.dataSend

                const urlParamsString = url.slice((url.indexOf('?')) + 1)
                const urlParamsObj = queryString.parse(urlParamsString)

                data.amount = urlParamsObj.baseCurrencyAmount
                data.address = urlParamsObj.depositWalletAddress

                that.send(data)

                setTimeout(() => {
                    that.webref.goBack()
                    that.handleState({
                        status: 'SUCCESS'
                    })
                }, 5e3)
            }
        }
    }`

    return navigationStateSell
}
