import React from 'react'
import { DetailsDiv } from './elements.css'

const Details = (props) => {
    const { name, value, styles, theme } = props

    return (
        <DetailsDiv textColor={theme.topNav.color} style={{ ...styles }}>
            <div className='name'>{name}</div>
            <div className='name' style={{ marginLeft: 5 }}>
                {value}
            </div>
        </DetailsDiv>
    )
}

export default Details
