import { SCREEN_SHOW_MODAL, SCREEN_HIDE_MODAL, INIT, FADE_TYPE } from '../type'

const INITIAL_STATE = {
    show: false,
    data: null,
    callback: null,
    fadeType: null
}

const modalScreenReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case INIT:
            return {
                ...state,
                show: false
            }
        case SCREEN_SHOW_MODAL:
            return {
                ...state,
                show: true,
                data: action.data,
                callback: action.callback
            }
        case SCREEN_HIDE_MODAL:
            return {
                ...state,
                show: false,
                data: action.data,
                callback: action.callback
            }
        case FADE_TYPE:
            return {
                ...state,
                fadeType: action.fadeType
            }

        default:
            return {
                ...state
            }
    }
}

export default modalScreenReducer
