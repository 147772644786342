import { combineReducers } from 'redux'
import mobileMarketReducer from './AppStores/MobileMarket/mobileMarketReducer'
import modalReducer from './AppStores/ModalStore/modalReducer'
import orderHistoryReducer from './AppStores/OrderHistoryStore/orderHistoryReduser'
import modalScreenReducer from './AppStores/ScreenModalStore/modalScreenReducer'
import searchModalScreenReducer from './AppStores/SearchModalScreenStore/searchModalScreenReducer'

export default combineReducers({
    mobileMarket: mobileMarketReducer,
    modalStore: modalReducer,
    modalScreenStore: modalScreenReducer,
    searchModalScreenStore: searchModalScreenReducer,
    orderHistoryStore: orderHistoryReducer
})
