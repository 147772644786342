export default {
    P2P: 'P2P',
    WORLD_WIDE: 'WORLD WIDE',
    NO_KYC: 'NO KYC',
    KYC: 'KYC',
    CARD_PHOTO: 'PHOTO',
    APPLE_PAY: 'APPLE PAY',
    GOOGLE_PAY: 'GOOGLE PAY',
    SAMSUNG_PAY: 'SAMSUNG PAY',
    AML: 'AML'
}
