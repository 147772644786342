import luhn from 'luhn'
import * as bitcoin from 'bitcoinjs-lib'

const REG_EX = {
    btcAddress: /^(bc1|[1])[a-zA-HJ-NP-Z0-9]{25,39}$/,
    bthAddress: /^(q|p)[a-z0-9]{41}$/,
    ethAddress: /^0x[a-fA-F0-9]{40}$/,
    ltcAddress: /^(ltc1|[LM3])[a-zA-HJ-NP-Z0-9]{26,40}$/,
    xrpAddress: /^r[0-9a-zA-Z]{24,34}$/,
    xlmAddress: /^[A-Z2-7]{56}$/,
    trxAddress: /^T[0-9a-zA-Z]{33}$/,
    dogeAddress: /^D{1}[5-9A-HJ-NP-U]{1}[1-9A-HJ-NP-Za-km-z]{32}$/,
    bnbAddress: /^bnb[a-zA-Z0-9]{39}$/,
    xmrAddress: /^4([0-9]|[A-B])(.){93}$/,
    email: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
    // /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/,

    phoneUA: /^\+38?(0\d{9})$/,
    phoneRU: /^((\+7|7|8)+([0-9]){10})$/
}

export default function validation(type, data) {
    switch (type) {
        case 'BTC_ADDRESS':
            // return REG_EX.btcAddress.test(data);
            let outputs
            try {
                outputs = bitcoin.address.toOutputScript(data, bitcoin.networks.bitcoin)
            } catch (e) {
                console.log('BTC address not valid')
                console.log(JSON.stringify(e))
                outputs = false
            }

            return outputs
        case 'BTH_ADDRESS':
            return REG_EX.bthAddress.test(data)
        case 'ETH_ADDRESS':
            return REG_EX.ethAddress.test(data)
        case 'LTC_ADDRESS':
            return REG_EX.ltcAddress.test(data)
        case 'XRP_ADDRESS':
            return REG_EX.xrpAddress.test(data)
        case 'XLM_ADDRESS':
            return REG_EX.xlmAddress.test(data)
        case 'TRX_ADDRESS':
            return REG_EX.trxAddress.test(data)
        case 'DOGE_ADDRESS':
            return REG_EX.dogeAddress.test(data)
        case 'XMR_ADDRESS':
            return REG_EX.xmrAddress.test(data)
        case 'BNB_ADDRESS':
            return REG_EX.bnbAddress.test(data)
        case 'CARD':
            return luhn.validate(data)
        case 'EMAIL':
            return REG_EX.email.test(data)
        case 'PHONE_UA':
            return REG_EX.phoneUA.test(data)
        case 'PHONE_RU':
            return REG_EX.phoneRU.test(data)
        default:
            return false
    }
}
