import Log from './sentry/Log'

export function getPaymentData(params) {
    if (!params?.walletData) return []

    const { walletData, cashbackToken, payway } = params

    if (!params?.payway) return null

    let wallet, walletName, walletHash

    wallet = walletData?.wallets?.find((way) => way.cashbackToken === cashbackToken)
    walletName = wallet?.walletName
    walletHash = wallet?.walletHash

    let data =
        typeof walletData?.cards !== 'undefined'
            ? walletData?.cards?.filter((way) =>
                  payway === 'CARD'
                      ? way.type === 'visa' || way.type === 'mastercard' || way.type === 'mir' || way.type === 'maestro'
                      : way.type === payway.toString()?.toLowerCase() &&
                        (typeof way.walletHash !== 'undefined' && way.walletHash
                            ? way.walletHash === walletName ||
                              way.walletHash === walletHash ||
                              way.walletHash.toString()?.toLowerCase() === 'null' ||
                              way.walletHash.toString()?.toLowerCase() === 'undefined'
                            : true)
              )
            : []

    Log.info(`V3.firstScreen.findButtonHandler paymentDetails ${JSON.stringify(data)}`)

    return data
}
