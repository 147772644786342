import styled from 'styled-components'

const Modal = styled.div`
    .wrapper {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: opacity 0.25s ease-in-out;
        z-index: 2000;
        width: 100%;
        margin: 0 auto;
        &.fade-in {
            top: 0;
            opacity: 1;
            transition: opacity 0.25s ease-in-out;
        }
        &.fade-out {
            top: 0%;
            opacity: 0;
            transition: opacity 0.25s ease-in-out;
        }
    }
    .background {
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        z-index: 1040;
        display: block;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        outline: 0;
    }
    .box-dialog {
        z-index: 1050;
        width: 100%;
        max-width: 313px;
        min-height: 313px;
        background-color: ${(p) => p.bg};
        border-radius: 16px;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        top: 100%;
        transform: translateY(100%);
        transition: all 0.25s ease-in-out;
        &.fade-in {
            top: 0;
            transform: translateY(0%);
            transition: all 0.25s ease-in-out;
        }
        &.fade-out {
            top: 0%;
            transform: translateY(100%);
            transition: all 0.25s ease-in-out;
        }
    }
`

export default Modal
