import CurrencyList from 'currency-list'
import queryString from 'query-string'

import FiatCurrencyName from '../../../components/currencies/fiat/fiatCurrencyName'
import { strings } from '../../../i18n'

import store from '../../../redux'
import { emailAction, walletDataAction } from '../../../redux/AppStores/MobileMarket/action'
import { hideModal, showModal } from '../../../redux/AppStores/ModalStore/action'

import { fromCustomToken } from '../../../services/fromCustom'
import { serverRequest } from '../../../services/getActualWays'
import Log from '../../../services/sentry/Log'

const { dispatch } = store

export const emailClean = () => {
    localStorage.removeItem('trusteeEmail')
    dispatch(emailAction(null))
}

export const setFiatArray = () => {
    return Object.keys(FiatCurrencyName).map((key) => {
        return {
            code: key,
            value: [CurrencyList.get(key, 'en').name, CurrencyList.get(key, 'ru').name, CurrencyList.get(key, 'uk').name]
        }
    })
}

export const handlerRefresh = async (url) => {
    const urlParamsString = url.slice(url.indexOf('?') + 1)

    const urlParamsObj = queryString.parse(urlParamsString)

    const link = `/data/wallet?message=${urlParamsObj.message}&messageHash=${urlParamsObj.messageHash}&signature=${urlParamsObj.signature}&cashbackToken=${urlParamsObj.cashbackToken}`

    let [walletData] = await Promise.all([serverRequest(link, strings('mobileMarket.modal.serverRespond'), 'WALLET_DATA')])

    // modal if walletData is undefined
    if (walletData === null || typeof walletData === 'undefined') {
        Log.error(`V3.MobileMarketAction walletData null`)
        Log.captureError('V3.MobileMarketAction walletData error')
        showModal(
            {
                type: 'INFO_MODAL',
                icon: 'WARNING',
                header: strings('mobileMarket.modal.sorry'),
                description: strings('mobileMarket.modal.serverRespond'),
                close: () => hideModal()
            },
            () => {
                hideModal()
            }
        )
        return false
    }

    const coins = fromCustomToken(walletData.wallets)

    walletData = {
        ...walletData,
        wallets: coins
    }

    Log.info(`sign handlerRefresh ${JSON.stringify(walletData.sign)}`)

    dispatch(walletDataAction(walletData))

    return true
}
