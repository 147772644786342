import React from 'react'
import Layout from './Layout/Layout'

import Icon from 'components/Icon/Icon'
import { hideModal } from 'redux/AppStores/ModalStore/action'
import hideAnimation from 'services/hideAnimation'

import ModalBody from './wrapper.css'

const InfoModal = (props) => {
    const { show, data, callback, theme } = props

    return (
        <>
            {show && (
                <Layout id='infoModal' close={data.close} bg={theme.modal.bg}>
                    <ModalBody headerColor={theme.modal.colorHeader} description={theme.modal.colorDescription}>
                        <div className='modal__img__wrapper'>
                            <Icon
                                icon={data.icon === 'INFO' || data.icon === 'WARNING' ? 'INFO' : 'WARNING'}
                                size={64}
                                color={data.icon === 'INFO' ? theme.modal.infoColor : theme.modal.warningColor}
                            />
                        </div>
                        {data.header && <div className='modal__header'>{data.header}</div>}
                        <div className='modal__description'>{data.description}</div>
                        <div className='modal__button'>
                            <div
                                className='btn'
                                style={{
                                    background: data.icon === 'INFO' ? theme.modal.infoColor : theme.modal.warningColor,
                                    boxShadow: `0px 6px 10px ${data.icon === 'INFO' ? theme.modal.infoShadow : theme.modal.warningShadow}`
                                }}
                                onClick={() => {
                                    callback && callback()
                                    hideAnimation('infoModal', hideModal)
                                }}>
                                Ok
                            </div>
                        </div>
                    </ModalBody>
                </Layout>
            )}
        </>
    )
}

export default InfoModal
