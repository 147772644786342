import styled from 'styled-components'

const Main = styled.div`
    position: absolute;
    top: 0;
    max-width: 768px;
    margin: 0 auto;
    justify-content: flex-start;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    z-index: 300;
    filter: ${(props) => (props.blur ? 'blur(7px)' : null)};

    &::-webkit-input-placeholder {
        /* Edge */
        font-family: 'Montserrat SemiBold', sans-serif;
        font-style: normal;
        /* font-weight: 600; */
        font-size: 16px;
        letter-spacing: 0.5px;
        color: #999999;
    }

    &:-ms-input-placeholder {
        /* Internet Explorer */
        font-family: 'Montserrat SemiBold', sans-serif;
        font-style: normal;
        /* font-weight: 600; */
        font-size: 16px;
        letter-spacing: 0.5px;
        color: #999999;
    }

    &::placeholder {
        font-family: 'Montserrat SemiBold', sans-serif;
        font-style: normal;
        /* font-weight: 600; */
        font-size: 16px;
        /* line-height: 16px; */
        letter-spacing: 0.5px;
        color: #999999;
    }
`

export const Content = styled(Main)`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: ${(p) => p.background};
    overflow: scroll;
    -webkit-appearance: none;
    -ms-overflow-style: none;
    scrollbar-width: none;

    transform: translateX(0%);
    transition: all ease-in-out 0.25s;

    left: 0%;

    @media screen and (min-width: 768px) {
        left: calc((100% - 768px) / 2);
    }

    &::-webkit-scrollbar {
        display: none;
    }

    &.page-enter {
        left: 100%;
        z-index: 99999;
        transform: translateX(100%);
        transition: all ease-in-out 0.25s;
    }
    &.page-enter.page-enter-active {
        z-index: 99999;
        left: 0;

        @media screen and (min-width: 768px) {
            left: calc((100% - 768px) / 2);
        }

        transform: translateX(0%);
        transition: all ease-in-out 0.25s;
    }
    &.page-exit {
        left: 0%;
        z-index: 99999;
        transform: translateX(0%);
        transition: all ease-in-out 0.5s;
    }
    &.page-exit.page-exit-active {
        left: 100%;
        z-index: 99999;

        transform: translateX(100%);
        transition: all ease-in-out 0.5s;
    }

    &.fade-enter {
        left: 100%;
        z-index: 99999;
        transform: translateX(100%);
        transition: all ease-in-out 0.25s;
    }
    &.fade-enter.fade-enter-active {
        z-index: 99999;
        left: 0;

        @media screen and (min-width: 768px) {
            left: calc((100% - 768px) / 2);
        }

        transform: translateX(0%);
        transition: all ease-in-out 0.25s;
    }

    &.card-enter {
        left: 100%;
        z-index: 99999;
    }
    &.card-enter.card-enter-active {
        left: 0%;
        z-index: 99999;

        @media screen and (min-width: 768px) {
            left: calc((100% - 768px) / 2);
        }

        transform: translateX(0%);
        transition: all ease-in-out 0.5s;
    }
`
