export function fromCustomToken(wallets) {
    let coins = []

    coins = wallets.map((item) => {
        if (!item.accounts || (item.accounts && !item.accounts.length)) {
            return item
        }

        const newCoins = item.accounts.map((coin) => {
            if (coin.currencyCode.toString().indexOf('CUSTOM') !== -1) {
                let originCode = coin.currencyCode
                let code
                let custom = coin.currencyCode.toString().slice(coin.currencyCode.toString().indexOf('_') + 1, coin.currencyCode.toString().length)
                if (coin.tokenBlockchain === 'ETHEREUM') {
                    code = `ETH_${custom}`
                } else if (coin.tokenBlockchain === 'TRON') {
                    code = custom
                } else if (coin.tokenBlockchain === 'BNB') {
                    if (custom.toString().indexOf('BNB_SMART_20_') !== -1) {
                        code = `BNB_SMART_${custom.toString().slice(custom.toString().indexOf('BNB_SMART_20_') + 13, custom.toString().length)}`
                    } else {
                        code = custom
                    }
                } else if (coin.tokenBlockchain === 'MATIC') {
                    if (custom.toString().indexOf('MATIC_ERC_20_') !== -1) {
                        code = `MATIC_${custom.toString().slice(custom.toString().indexOf('MATIC_ERC_20_') + 13, custom.toString().length)}`
                    } else {
                        code = custom
                    }
                } else if (coin.tokenBlockchain === 'SOLANA') {
                    code = custom
                }
                return {
                    ...coin,
                    currencyCode: code,
                    custom: true,
                    originCode
                }
            } else {
                return {
                    ...coin
                }
            }
        })
        return {
            ...item,
            accounts: newCoins
        }
    })

    return coins
}
