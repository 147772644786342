import React from 'react'
import { Provider } from 'react-redux'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import store from './redux/index'
import { withTranslation } from 'react-i18next'

import { SnackbarContent, SnackbarProvider } from 'notistack'
import Fade from '@material-ui/core/Fade'

import classes from './components/styles/errorScreen.module.css'

import { strings } from './i18n'
import Icon from './components/Icon/Icon'

import config from './config/config'

import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import MobileMarket from './modules/mobileMarket/mobileMarket'
import MobileCheck from './modules/mobileCheck/mobileCheck'
import NotificationItem from './components/NotificationItem/Notification'

Sentry.init({
    dsn: config.dsn,

    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.2,

    beforeBreadcrumb(breadcrumb, hint) {
        return breadcrumb.category === 'ui.click' ? null : breadcrumb
    }
})

const ErrorFallback = ({ error }) => {
    return (
        <>
            <div className={classes.wrapper}>
                <div className={classes.title}>{strings('errorScreen.title')}</div>
                <div className={classes.description}>
                    {config.showError ? `${window.location.pathname} ${error.message}` : strings('errorScreen.description')}
                </div>
                <div className={classes.circleWrapper}>
                    <div>
                        <div
                            className={classes.circle}
                            onClick={() => {
                                window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ close: true }))
                            }}>
                            <Icon icon={'ARROW_BACK'} size={30} color={'#F5F5F5'} style={{ marginTop: 12 }} />
                        </div>
                        <div className={classes.btn}>{strings('errorScreen.home')}</div>
                    </div>
                    <div>
                        <div className={classes.circle}>
                            <a href={'https://t.me/trustee_support_bot?start=app'}>
                                <Icon icon={'SUPPORT'} size={30} color={'#F5F5F5'} style={{ marginTop: 12 }} />
                            </a>
                        </div>
                        <div className={classes.btn}>{strings('errorScreen.support')}</div>
                    </div>
                </div>
            </div>
        </>
    )
}

function App() {
    return (
        <Provider store={store}>
            <SnackbarProvider
                maxSnack={config.showStackRequiest ? 15 : 1}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                TransitionComponent={Fade}
                content={(key, message) => {
                    if (!config.showStackRequiest) {
                        return <NotificationItem id={key} message={message} />
                    } else {
                        ;<SnackbarContent id={key} message={message} />
                    }
                }}
                transitionDuration={{ enter: 100, exit: 200 }}
                resumeHideDuration={config.showStackRequiest ? 15 : 0.5}
                autoHideDuration={config.showStackRequiest ? 15000 : 1000}>
                <Router>
                    <Sentry.ErrorBoundary fallback={ErrorFallback}>
                        <Switch>
                            <Route path='/mobile-market' component={MobileMarket} />
                            <Route path='/mobile-check' component={MobileCheck} />
                        </Switch>
                    </Sentry.ErrorBoundary>
                </Router>
            </SnackbarProvider>
        </Provider>
    )
}

export default withTranslation()(App)
