import React from 'react'
import Icon from '../Icon/Icon'
import { strings } from '../../i18n'
import backTo from '../../img/icon/backToOld.svg'

import Logo from '../../img/logoPrecheck.svg'
import { TopNav } from './styles.css'
import { getThemeModeStyles } from '../../theme/useDarkMode'

const handlerBack = () => {
    window.history.back()
}

const handlerClose = () => {
    window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ close: true }))
}

const TopNavigation = (props) => {
    const {
        text,
        search,
        searchInputHandler,
        side,
        searchType,
        logo,
        searchValue,
        styles,
        shareObj,
        rightType,
        rightAction,
        leftType,
        leftAction,
        rightComponentStyle
    } = props

    const android = window.navigator.userAgent.indexOf('Chrome')

    const theme = getThemeModeStyles()

    const getRightAction = () => {
        switch (rightType) {
            case 'close':
                return (
                    <div className='close' onClick={() => (rightAction ? rightAction() : handlerClose())}>
                        <Icon icon='CLOSE' color={theme.topNav.colorIcon} size={20} />
                    </div>
                )
            case 'orderHistory':
                return (
                    <div className='textBtn' onClick={() => (rightAction ? rightAction() : null)} style={{ transform: 'none' }}>
                        {/* <Icon icon='HISTORY' color={theme.topNav.colorIcon} size={20} /> */}
                        {strings('mobileMarket.navigation.history')}
                    </div>
                )
            case 'share':
                return (
                    <div
                        className='close'
                        onClick={() => {
                            window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ share: shareObj }))
                        }}>
                        <Icon icon='SHARE' color={theme.topNav.colorIcon} size={20} />
                    </div>
                )
            case 'filter':
                return (
                    <div className='filter-wrapper' onClick={rightAction}>
                        <div className='filter' style={rightComponentStyle}>
                            <Icon
                                icon='FILTER'
                                color={rightComponentStyle && rightComponentStyle?.color ? rightComponentStyle.color : theme.topNav.colorIcon}
                                size={20}
                            />
                        </div>
                    </div>
                )
            default:
                return (
                    <div className='close'>
                        <Icon icon='CLOSE' color='transparent' size={20} />
                    </div>
                )
        }
    }

    const getLeftAction = () => {
        switch (leftType) {
            case 'emailClean':
                return (
                    <div className='close' onClick={leftAction}>
                        <Icon icon='DELETE' color={theme.topNav.colorIcon} size={20} />
                    </div>
                )
            case 'backToOld':
                return (
                    <div className='toOld' onClick={leftAction}>
                        <img src={backTo} alt={'back'} height={12} />
                        <p>to old</p>
                    </div>
                )
            case 'back':
                return (
                    <div className='back' onClick={() => (leftAction ? leftAction() : handlerBack())}>
                        <Icon icon='ARROW_CONFIRM' color={theme.topNav.colorIcon} size={20} />
                    </div>
                )
            default:
                return (
                    <div className='back'>
                        <Icon icon='ARROW_CONFIRM' color='transparent' size={20} />
                    </div>
                )
        }
    }

    return (
        <>
            <TopNav
                style={{ ...styles }}
                background={theme.topNav.background}
                color={theme.topNav.color}
                inputBg={theme.input.bg}
                inputColor={theme.input.textColor}>
                <div className='name'>
                    {getLeftAction()}
                    {logo ? (
                        <>
                            <img src={Logo} alt='logo' className='logo' />
                            <div className='line'></div>
                            <div className='text'>{text}</div>
                        </>
                    ) : (
                        <div className='text'>{text}</div>
                    )}
                    {getRightAction()}
                </div>
                {search ? (
                    <div className='search__wrapper' style={{ marginTop: 10 }}>
                        <input
                            type='text'
                            placeholder={strings('mobileMarket.modal.search')}
                            onChange={(event) => searchInputHandler(event.target.value, side, searchType)}
                            style={{ paddingLeft: 16, height: android !== -1 ? 50 : 40, paddingTop: android === -1 ? 10 : 0 }}
                            value={searchValue || ''}
                        />
                        <Icon
                            icon='SEARCH'
                            size={26}
                            color={theme.topNav.colorIcon}
                            style={{ marginTop: android === -1 ? 15 : 13, marginRight: 16 }}
                        />
                    </div>
                ) : null}
            </TopNav>
        </>
    )
}

export default TopNavigation
