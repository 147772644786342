import styled from 'styled-components'

const ModalBody = styled.div`
    .modal__img__wrapper {
        width: 100%;
        display: inline-flex;
        justify-content: center;
        margin-top: 40px;
        margin-bottom: 32px;
    }

    .modal__header {
        font-family: 'Montserrat Bold';
        font-style: normal;
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        color: ${(p) => p.headerColor};
        margin-bottom: 8px;
    }

    .modal__description {
        font-family: 'SF UI Display Regular';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        letter-spacing: 0.5px;
        color: ${(p) => p.description};
        width: 80%;
        margin: 0 auto;
    }

    .modal__button {
        margin: 32px 16px 40px 16px;
    }

    .btn {
        border-radius: 10px;
        width: 100%;
        height: 50px;
        border: none;
        outline: none;
        font-family: 'Montserrat SemiBold';
        font-style: normal;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.5px;
        color: #f7f7f7;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .btn__inverse {
        width: 100%;
        height: 50px;
        font-family: 'Montserrat SemiBold';
        font-style: normal;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0.5px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
    }
`

export default ModalBody
