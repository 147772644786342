import React from 'react'
import CurrencyList from 'currency-list'
import i18n, { strings } from 'i18n'
import store from 'redux/index'

import IconCurrency from 'components/currencies/crypto/cryptoCurrency'
import IconFiatCurrency from 'components/currencies/fiat/fiatCurrency'

import { capitalize } from 'services/capitalize'
import { getCurrencyCode, getCurrencyName } from 'services/getCurrenciesProperty'
import utilsService from 'services/utilsService'

import ItemWrapper from './currencyList.css'

const CurrencyListItem = (props) => {
    const { item, index, colorNotActive, side, account, handler, setShowError, setShowErrorBtn, notActive, theme } = props

    const state = store.getState().mobileMarket

    const locale = i18n.language

    const checked = state[side.toLowerCase() + 'Currency'].code === item.code

    return (
        <ItemWrapper
            checked={checked}
            notActive={notActive}
            bgColor={theme.modal.select.bg}
            selected={theme.modal.select.selected}
            checkedColor={theme.main.checked}
            textColor={theme.topNav.color}>
            <label htmlFor={JSON.stringify(item)} className='label-item'>
                <div className='wrapper'>
                    <div className='icon-wrapper'>
                        {item.type === 'CRYPTO' ? (
                            <IconCurrency currencyCode={item.code} checked={checked} theme={theme} />
                        ) : item.type === 'FIAT' ? (
                            <IconFiatCurrency currencyCode={item.code} checked={checked} theme={theme} />
                        ) : null}
                    </div>
                    <div className='currency-name'>
                        <div className='currency-wrapper'>
                            <div className='currency-code'>{item.symbol}</div>
                            <div className='item-wrapper'>
                                {item.type === 'CRYPTO'
                                    ? getCurrencyName(item.code) || item.name
                                    : item.type === 'FIAT'
                                    ? capitalize(CurrencyList.get(item.code, locale).name)
                                    : null}
                            </div>
                        </div>
                        <div className='balance-wrapper'>
                            <div className='code-wrapper currency-code'>
                                <div>
                                    {account && (account?.prettyBalanceRaw || account.balance) > 0
                                        ? utilsService.cutNumber(account?.prettyBalanceRaw || account.balance, 5)
                                        : null}
                                </div>
                            </div>
                            <div className='fiat-wrapper'>
                                <div style={{ marginRight: '5px' }}>
                                    {account && (account?.prettyBalanceRaw || account.balance) > 0
                                        ? utilsService.cutNumber(Number(account.basicCurrencyBalance.toString().replace(/\s+/g, '')), 2)
                                        : null}
                                </div>
                                <div>{account && (account?.prettyBalanceRaw || account.balance) > 0 ? account.basicCurrencyCode : null}</div>
                            </div>
                        </div>
                    </div>
                </div>
                {notActive ? (
                    <div className='not-available'>
                        {strings('mobileMarket.modal.currencyNotAvailable', {
                            currency: side === 'IN' ? state.outCurrency.symbol : state.inCurrency.symbol
                        })}
                    </div>
                ) : null}
            </label>
            <input
                type='radio'
                value={JSON.stringify(item)}
                key={index}
                id={JSON.stringify(item)}
                readOnly
                checked={JSON.stringify(state[side.toLowerCase() + 'Currency']) === JSON.stringify(item)}
                onClick={(event) => (handler ? handler(event, side, setShowError, setShowErrorBtn) : null)}
            />
        </ItemWrapper>
    )
}

export default CurrencyListItem
