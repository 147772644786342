import axios from 'axios'
import config from '../config/config'
import store from '../redux'
import Log from './sentry/Log'

export async function getStatusKYC(email) {
    if (!email) return

    const state = store.getState().mobileMarket

    const url = `${config.backendUrl}/sum-sub/status?userId=${email}&cashbackToken=${state.cashbackToken}&signMessage=${state.sign.message}&signMessageHash=${state.sign.messageHash}&signature=${state.sign.signature}`

    let response

    try {
        response = await axios.get(url)
        Log.info(`V3.getStatusKYC response.data ${JSON.stringify(response.data)}`)

        return response.data
        // return obj {status, userId}
        // userId - hash || email - status === NOT_REGISTERED ? hash : email
    } catch (error) {
        console.log(error.message)
    }
}
