export const displayInputWidth = (text, font) => {
    let canvas = displayInputWidth.canvas || (displayInputWidth.canvas = document.createElement('canvas'))
    let context = canvas.getContext('2d')
    context.font = font

    let metrics = context.measureText(text)

    context.clearRect(0, 0, canvas.width, canvas.height)

    let textarea = document.getElementById('amountInput')

    if (textarea === null) {
        return
    }

    if (metrics.width > 100) {
        textarea.style.width = `${metrics.width * 1.05}px`
        if (textarea.style.width >= textarea.style.maxWidth) {
            const textareaWidth = textarea.clientWidth

            const coefficient = metrics.width / textareaWidth
            if (metrics.width > textareaWidth) {
                textarea.style.fontSize = `${40 / coefficient}px`
            } else {
                textarea.style.fontSize = '40px'
            }
        }
    } else {
        textarea.style.width = '90px'
    }
}
