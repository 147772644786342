import React, { useState, useEffect } from 'react'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PullRefresh from 'react-pullrefresh'
import { useSnackbar } from 'notistack'
import { v4 as uuidV4 } from 'uuid'
import socketIOClient from 'socket.io-client'
import Lottie from 'react-lottie'
import CurrencyList from 'currency-list'

import ProviderItem from '../../../components/providerItem/providerItem'
import Loader from '../../../components/Loader/loader'
import Tooltip from '../../../components/Tooltip/Tooltip'
import TopNavigation from '../../../components/TopNavigation/TopNavigation'
import Button from '../../../components/Button/Button'
import Wrapper from '../../../components/ScreenWrapper/Wrapper'
import { Content } from '../../../components/wrapperScreen/wrapper.css'
import Message from '../../../components/InfoMsg/Message'
import { filterItemVariants, handlerLimit, nextStep } from '../../../components/providerItem/helper'
import FiatCurrencyName from '../../../components/currencies/fiat/fiatCurrencyName'
import Line from '../../../components/Line/line'

import store from '../../../redux'
import {
    searchValueInSideAction,
    searchValueOutSideAction,
    searchValuePaywayAction,
    cameraResultAction,
    activeExchangeWayAction,
    paymentDataAction,
    amountAction,
    amountSideAction,
    sendAllAction,
    variantsAction,
    paywayListAction,
    walletDataAction,
    emailAction,
    refreshData,
    setToOrderHashAction,
    setShowLoader,
    setRefreshTimeAction,
    savePositionAction,
    outDestinationAction,
    setRocketLoaderAction,
    repeatOrderAction,
    recheckSendAllAction,
    comeBackAction,
    setSelectedFee
} from '../../../redux/AppStores/MobileMarket/action'
import { hideModal, showModal } from '../../../redux/AppStores/ModalStore/action'
import { showScreenModal } from '../../../redux/AppStores/ScreenModalStore/action'
import { hideSearchScreenModal, showSearchScreenModal } from '../../../redux/AppStores/SearchModalScreenStore/action'
import { getHistoryOrderAction, setOrderAction } from '../../../redux/AppStores/OrderHistoryStore/action'
import { getUserCurrencies, getWalletData } from '../../../redux/AppStores/MobileMarket/selectors'

import UtilsService from '../../../services/utilsService'
import Log from '../../../services/sentry/Log'
import getProviders from '../../../services/getProviders'
import utilsService from '../../../services/utilsService'
import { serverRequest } from '../../../services/getActualWays'
import { fromCustomToken } from '../../../services/fromCustom'
import isActiveCoin from '../../../services/isActiveCoin'
import { diffMinutes } from '../../../services/timed'
import findOutDestination from '../../../services/findOutDestination'
import { getPaymentData } from '../../../services/getPaymentData'
import hideAnimation from '../../../services/hideAnimation'

import BlackLoader from '../../../img/icon/loaderUniverse.svg'

import { strings } from '../../../i18n'
import config from '../../../config/config'

import ScreenModal from '../../ScreenModal/MainModal'
import CardScreenModal from '../../searchModal/elements/cardModal/mainModal'
import SearchScreenModal from '../../searchModal/mainModal'
import Modal from '../../newModal/MainModal'
import Slider from '../../SliderModule/Slider'
import animationData from '../../../jsons/animation/rocketAngl2.json'
import { getThemeModeStyles } from '../../../theme/useDarkMode'

import ShadowBtn from './elements/ShadowBtn.css'
import ScreenDiv from './screen.css'
import { checkCard } from './helper'
import WayItem from './elements/WayItem/WayItem'
import WrapperSelector from './wrapperSelector/wrapperSelector'

const { dispatch } = store

let CACHE_COUNT = 0

const backToOldModal = () => {
    showModal({
        type: 'SELECT',
        icon: 'INFO',
        header: strings('mobileMarket.modal.warning'),
        description: strings('mobileMarket.modal.selectWay'),
        actions: [
            { name: 'buy', action: () => window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ backToOld: 'BUY' })) },
            {
                name: 'exchange',
                action: () => window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ backToOld: 'EXCHANGE' }))
            },
            { name: 'sell', action: () => window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ backToOld: 'SELL' })) }
        ]
    })
}

const handlerRefresh = async (url) => {
    const urlParamsString = url.slice(url.indexOf('?') + 1)

    const urlParamsObj = queryString.parse(urlParamsString)

    const state = store.getState().mobileMarket

    const link = `/data/wallet?message=${urlParamsObj.message}&messageHash=${urlParamsObj.messageHash}&signature=${urlParamsObj.signature}&cashbackToken=${state.cashbackToken}`

    let [walletData] = await Promise.all([serverRequest(link, strings('mobileMarket.modal.serverRespond'), 'WALLET_DATA')])

    // modal if walletData is undefined
    if (walletData === null || typeof walletData === 'undefined') {
        Log.error(`V3.MobileMarketAction walletData null`)
        Log.captureError('V3.MobileMarketAction walletData error')
        showModal(
            {
                type: 'INFO_MODAL',
                icon: 'WARNING',
                header: strings('mobileMarket.modal.sorry'),
                description: strings('mobileMarket.modal.serverRespond'),
                close: () => hideModal()
            },
            () => {
                hideModal()
            }
        )
        return false
    }

    const coins = fromCustomToken(walletData.wallets)

    walletData = {
        ...walletData,
        wallets: coins
    }

    Log.info(`sign handlerRefresh ${JSON.stringify(walletData.sign)}`)

    dispatch(walletDataAction(walletData))

    return true
}

const emailClean = () => {
    localStorage.removeItem('trusteeEmail')
    dispatch(emailAction(null))
}

const RenderFirstScreen = () => {
    const state = store.getState().mobileMarket
    const modalState = store.getState().modalStore
    const modalScreenState = store.getState().modalScreenStore
    const searchModalScreenState = store.getState().searchModalScreenStore

    const walletData = useSelector(getWalletData)
    const userCurrencies = useSelector(getUserCurrencies)

    const history = useHistory()

    const theme = getThemeModeStyles()

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    let providersArray, skipProviders, nameWallet, useFirebase

    const [loader, setLoader] = useState({
        loaderTotalProviders: 0,
        loaderCounter: 0
    })

    const [load, setLoad] = useState(false)
    const [loading, setLoading] = useState(false)

    const [variantsArray, setVariantsArray] = useState([])
    const [requestId, setRequestId] = useState(uuidV4())

    const [showTooltip, setTooltip] = useState(false)

    const [showError, setShowError] = useState(false)
    const [showErrorBtn, setShowErrorBtn] = useState(false)

    const [move, setMove] = useState(false)

    const [skipScreen, setSkipScreen] = useState(false)
    const [data, setData] = useState(false)
    const [name, setName] = useState(null)
    const [firebase, setFirebase] = useState(true)

    const eventHandler = async (event) => {
        if (!event || typeof event.data === 'undefined' || !event.data || !Object.keys(event.data) || Object.keys(event.data).length === 0) return

        let data
        try {
            Log.info(`V3.firstScreen.eventHandler event.data ${JSON.stringify(event.data)}`)
            data = JSON.parse(event.data)
        } catch (e) {
            console.log(JSON.stringify(e))
            return
        }

        const { fees, cameraRes, serverError, url, walletData } = data

        if (walletData && !state.walletData) {
            let outCurrency = localStorage.getItem('outCurrencyCode')
                ? JSON.parse(localStorage.getItem('outCurrencyCode'))
                : { code: 'BTC', type: 'CRYPTO' }

            let newWalletData

            try {
                const cards = getPaymentData(true)
                newWalletData = walletData
                if (typeof walletData?.cards === 'undefined') {
                    newWalletData.cards = cards
                }
                setTimeout(() => {
                    setData(newWalletData)
                }, 1e3)
            } catch (e) {
                console.log('load walletData error', JSON.stringify(e))
            }

            let obj
            try {
                if (outCurrency && outCurrency.type === 'CRYPTO' && !state.outDestination) {
                    const url = window.location.href
                    const urlParamsString = url.slice(url.indexOf('?') + 1)

                    const urlParamsObj = queryString.parse(urlParamsString)
                    const cashbackToken = urlParamsObj.cashbackToken

                    obj = findOutDestination(walletData, outCurrency.code, cashbackToken)
                    setTimeout(() => {
                        dispatch(outDestinationAction(obj?.outDestination || null, obj?.walletName || null))
                    }, 250)
                }
            } catch (e) {
                console.log('error with adrress', JSON.stringify(e))
            }

            if (skipProviders) {
                setTimeout(() => {
                    searchModalHandler()
                    setSkipScreen(false)

                    setTimeout(() => {
                        dispatch(walletDataAction(newWalletData))
                    }, 250)
                }, 1e3)
            }
        }

        if (typeof cameraRes !== 'undefined') {
            dispatch(cameraResultAction(cameraRes))
        }

        if (fees) {
            const tmpState = store.getState().mobileMarket
            const searchModalScreenState = store.getState().searchModalScreenStore
            const amount = utilsService.cutNumber(fees.amount, 8)
            if (tmpState.provider && tmpState.comeBack && tmpState.preCheck && tmpState.useAllFunds) {
                Log.info(`V3.firstScreen.eventHandler fees amount ${JSON.stringify(amount)}`)
                Log.info(
                    `V3.firstScreen.eventHandler state.provider.equivalentData.inAmount ${JSON.stringify(tmpState.provider.equivalentData.inAmount)}`
                )

                const tmpValue = (Number(amount) / Number(utilsService.cutNumber(tmpState.provider.equivalentData.inAmount, 8))) * 1

                Log.info(`V3.firstScreen.eventHandler amount / state.provider.equivalentData.inAmount ${JSON.stringify(tmpValue)}`)

                if (Number(utilsService.cutNumber(tmpValue - 0.98, 2)) > 0.02 || Number(utilsService.cutNumber(tmpValue - 0.98, 2)) < -0.02) {
                    dispatch(recheckSendAllAction(false))
                    dispatch(comeBackAction(false))
                    dispatch(setSelectedFee(fees?.transferData || null))
                    setLoader(false)

                    showModal(
                        {
                            type: 'YES_NO_MODAL',
                            icon: 'WARNING',
                            header: strings('mobileMarket.modal.attention'),
                            description: strings('mobileMarket.modal.recheckSendAll'),
                            noCallback: () => {
                                setLoader(false)
                                hideAnimation('YesNoModal', hideModal)
                            }
                        },
                        async () => {
                            window.history.back()
                            findButtonHandler(amount, 'IN')
                            dispatch(sendAllAction(true))

                            hideAnimation('YesNoModal', hideModal)
                        }
                    )
                    return
                }
            } else if (tmpState.preCheck) {
                dispatch(recheckSendAllAction(false))
                dispatch(comeBackAction(false))
                setLoader(false)
                const diff = utilsService.cutNumber(tmpState.provider.equivalentData.inAmount, 8) * 1 > amount * 1
                Log.info(`V3.firstScreen.eventHandler tmpState.provider.equivalentData.inAmount / amount ${diff}`)
                if (diff) {
                    showModal(
                        {
                            type: 'YES_NO_MODAL',
                            icon: 'WARNING',
                            header: strings('mobileMarket.modal.attention'),
                            description: strings('mobileMarket.modal.recheckSendAll'),
                            noCallback: () => {
                                setLoader(false)
                                hideAnimation('YesNoModal', hideModal)
                            }
                        },
                        async () => {
                            window.history.back()
                            findButtonHandler(amount, 'IN')
                            dispatch(sendAllAction(true))
                            hideAnimation('YesNoModal', hideModal)
                        }
                    )
                    return
                }
            } else {
                dispatch(amountAction(amount))
                dispatch(amountSideAction('IN'))
                setLoading(false)
                if (searchModalScreenState.searchShow) {
                    return
                } else {
                    findButtonHandler(amount, 'IN')
                }
            }
        }

        if (serverError) {
            dispatch(sendAllAction(false))
            setLoading(false)
            setVariantsArray(() => [])
        }

        if (url) {
            try {
                const result = await handlerRefresh(url)
                if (result) {
                    dispatch(refreshData(false))
                    CACHE_COUNT = 0
                }
            } catch (e) {
                console.log(JSON.stringify(e))
                dispatch(refreshData(false))
                CACHE_COUNT = 0
            }
        }
    }

    const refreshLoader = () => {
        return new Promise((resolve, reject) => {
            if (CACHE_COUNT === 0) {
                let loader = document.getElementsByClassName('sc-bdVaJa')[0]

                if (loader) {
                    loader.style.top = `calc(${loader.style.top} + 30px)`
                    loader.style.transition = 'all 0.4s'
                    setTimeout(() => {
                        loader.style.transition = ''
                    }, 1000)
                }

                window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ refreshControl: true }))
                dispatch(refreshData(true))
                CACHE_COUNT = 1
            }

            setTimeout(function check() {
                const tmpState = store.getState().mobileMarket

                if (!tmpState.refreshData) {
                    dispatch(refreshData(false))
                    CACHE_COUNT = 0
                    resolve()
                    return
                } else {
                    setTimeout(check, 5e2)
                }
            }, 5e2)
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        if (Number(state.versionNumber)) {
            window?.ReactNativeWebView && window?.ReactNativeWebView?.postMessage(JSON.stringify({ homePage: true }))
        }

        const tmpState = store.getState().mobileMarket

        if (tmpState.propsModal) {
            searchModalHandler()
        }

        const url = window.location.href
        const urlParamsString = url.slice(url.indexOf('?') + 1)

        const urlParamsObj = queryString.parse(urlParamsString)
        const toOrderHash = urlParamsObj.orderHash
        const skipProvidersScreen =
            typeof urlParamsObj.skipProvidersScreen !== 'undefined'
                ? urlParamsObj.skipProvidersScreen && state.propsModal
                    ? false
                    : JSON.parse(urlParamsObj.skipProvidersScreen)
                : false
        skipProviders = typeof urlParamsObj.skipProvidersScreen !== 'undefined' ? JSON.parse(urlParamsObj.skipProvidersScreen) : false
        nameWallet = typeof urlParamsObj.wallet !== 'undefined' ? urlParamsObj.wallet : false
        useFirebase = typeof urlParamsObj.useFirebase !== 'undefined' ? JSON.parse(urlParamsObj.useFirebase) : true
        !state.walletData && setFirebase(useFirebase)

        if (!useFirebase && !state.walletData) {
            window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ getWalletdata: true }))
        }

        if (skipProviders) {
            setSkipScreen(skipProvidersScreen)
        } else {
            setSkipScreen(false)
        }

        if (toOrderHash && toOrderHash !== 'false' && !tmpState.toOrderHash) {
            const cashbackToken = urlParamsObj.cashbackToken
            const message = urlParamsObj.message
            const messageHash = urlParamsObj.messageHash
            const signature = urlParamsObj.signature

            dispatch(setShowLoader(true))

            let link = `/order/history-for-bse?cashbackToken=${cashbackToken}&signMessage=${message}&signMessageHash=${messageHash}&signature=${signature}&orderHash=${toOrderHash}`

            try {
                const history = await serverRequest(link, strings('mobileMarket.modal.serverRespond'), 'WALLET_DATA')

                dispatch(getHistoryOrderAction(history.currentPage, history.orders, history.pages, history.serverTime))

                const order = history.orders[0]

                dispatch(
                    setOrderAction({
                        preCheck: order.checkType === 'PRE_CHECK' ? order : null,
                        finalCheck: order.checkType === 'FINAL_CHECK' ? order : null
                    })
                )

                dispatch(setToOrderHashAction(toOrderHash))

                setTimeout(() => {
                    orderHistory()
                    setTimeout(() => {
                        showSearchScreenModal({
                            type: 'ORDER_MODAL'
                        })
                        return
                    }, 5e2)
                }, 1e3)
            } catch (e) {
                console.log(JSON.stringify(e))
                // dispatch(setShowLoader(false))
                // return
            }
        }

        if (window.navigator.userAgent.indexOf('Chrome') === -1) {
            window.addEventListener('message', (event) => eventHandler(event))
        } else {
            document.addEventListener('message', (event) => eventHandler(event))
        }

        const socket = socketIOClient(config.backendUrl + '?requestId=' + requestId, { transports: ['websocket'] })

        socket.on('getProviderData', (providerData) => {
            if (providerData && typeof providerData.exchangeWayId !== 'undefined') {
                let providers = variantFilter(providerData)

                if (providers) {
                    setVariantsArray((variantsArray) => [...variantsArray, providers])
                    setLoader((loader) => {
                        return {
                            ...loader,
                            loaderCounter: loader.loaderCounter + 1
                        }
                    })
                }
            }
        })

        socket.on('setLoaderTotalProviders', (loaderTotalProviders) => {
            setLoader((loader) => {
                return {
                    ...loader,
                    loaderTotalProviders
                }
            })
        })

        if (tmpState.repeatOrder) {
            dispatch(repeatOrderAction(false))
            findButtonHandler()
        }

        setTimeout(function refresh() {
            const newState = store.getState().mobileMarket

            Log.info(`V3.firstScreen refreshControl init`)

            const diffTime = diffMinutes(new Date(), newState.refreshTime)

            Log.info(`V3.firstScreen refreshControl diffTime ${JSON.stringify(diffTime)}`)

            if (diffTime >= 8) {
                window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ refreshControl: true }))
                setTimeout(refresh, 48e4) // 8 min
                dispatch(setRefreshTimeAction(new Date()))
            } else {
                setTimeout(refresh, 48e4) // 8 min
            }
        }, 24e4) // 4 min

        setTimeout(async function findProviders() {
            const newTmpState = store.getState().mobileMarket

            if (localStorage.getItem('amountData') && newTmpState.amount) {
                await findButtonHandler()
            } else {
                setTimeout(findProviders, 500)
            }
        }, 1000)

        return () => {
            if (window.navigator.userAgent.indexOf('Chrome') === -1) {
                window.removeEventListener('message')
            } else {
                document.removeEventListener('message')
            }
        }
    }, [])

    const findButtonHandler = async (amount = null, sideAmount = null) => {
        const newState = store.getState().mobileMarket
        const searchModalScreenState = store.getState().searchModalScreenStore

        if (!newState.amount || !newState.inCurrency || !newState.outCurrency) {
            return
        }

        if ((newState.inCurrency.type === 'FIAT' || newState.outCurrency.type === 'FIAT') && !newState.payway) {
            return
        }

        if ((newState.inCurrency.type === 'FIAT' || newState.outCurrency.type === 'FIAT') && newState.paymentData) {
            const validStatus = checkCard()

            if (validStatus && validStatus.length === 1 && validStatus[0] === false) {
                showModal({
                    type: 'INFO_MODAL',
                    icon: 'WARNING',
                    header: strings('mobileMarket.modal.attention'),
                    description: strings(`mobileMarket.providerErrors.${state.payway === 'CARD' ? 'notValidCard' : 'notValidAccount'}`)
                })
                return
            }
        }

        if (newState.skipProvidersScreen) {
            setSkipScreen(true)

            // if (!newState.paymentData && (newState.inCurrency.type === 'FIAT' || newState.outCurrency.type === 'FIAT') ) {
            //     setSkipScreen(false)
            //     showModal({
            //         type: 'YES_NO_MODAL',
            //         icon: 'WARNING',
            //         header: strings('mobileMarket.modal.attention'),
            //         description: strings('mobileMarket.modal.notCard'),
            //         firstBtn: strings('mobileMarket.modal.addDetails'),
            //         secondBtn: strings('mobileMarket.modal.late')
            //     }, () => {
            //         hideModal()
            //         showCardScreenModal({
            //             type: 'CARD_MODAL',
            //             data: {
            //                 action: 'ADD',
            //                 typePayment: state.payway
            //             }
            //         }, () => {
            //             hideCardScreenModal()
            //         })
            //         return
            //     })
            //     return false
            // }
        }

        dispatch(setRocketLoaderAction(false, false))
        dispatch(savePositionAction(true))
        setVariantsArray(() => [])
        dispatch(variantsAction(null))

        if (searchModalScreenState.searchShow && !newState.skipProvidersScreen) {
            document.getElementById('searchModal').classList.remove('fade-in')
            document.getElementById('searchModal').classList.add('fade-out')
            closeModalHandler()
            // hideSearchScreenModal()
        }

        dispatch(activeExchangeWayAction(null))

        if (!newState.paymentData) {
            const paymentDetails = newState.payway ? getPaymentData() : null

            if (paymentDetails && paymentDetails.length > 0) {
                dispatch(paymentDataAction(paymentDetails[paymentDetails.length - 1]))
            }
        }

        setLoader((loader) => {
            return {
                ...loader,
                loaderCounter: 0,
                loaderTotalProviders: 0
            }
        })

        const arrayProviders = await getProviders(
            newState,
            requestId,
            amount ? amount : newState.amount,
            sideAmount ? sideAmount : newState.amountSide,
            newState.tester,
            enqueueSnackbar
        )
        let finalArray = arrayProviders !== null ? filterIsActive(arrayProviders.variants) : []
        finalArray = finalArray.map((item) => filterItemVariants(item))

        Log.info(`V3.firstScreen.findButtonHandler getPrvoiders result ${JSON.stringify(finalArray)}`)

        dispatch(variantsAction(finalArray))

        localStorage.removeItem('amountData')

        if (newState.skipProvidersScreen) {
            const activeExchangeWay = newState.allExchangeWays.find((way) => way.exchangeWayId === finalArray[0].exchangeWayId)
            let exchangeWayType = activeExchangeWay
                ? activeExchangeWay.exchangeWayType
                : finalArray[0].outCurrency.type === 'FIAT'
                ? 'SELL'
                : finalArray[0].inCurrency.type === 'FIAT'
                ? 'BUY'
                : 'EXCHANGE'

            if (finalArray[0] && finalArray[0].equivalentData.status === 'SUCCESS') {
                // dispatch(providerAction(finalArray[0], activeExchangeWay.exchangeWayType))
                nextStep(finalArray[0], urlPath, loader, setLoad, providersArray, setLoader, setVariantsArray, setLoading, pathUrl, findButtonHandler)
                setSkipScreen(false)
                // url('pre-check')
            } else {
                setSkipScreen(false)
                handlerLimit(finalArray[0], newState, exchangeWayType, finalArray[0]?.valid || false, findButtonHandler)
            }
        }

        setLoader((loader) => {
            return {
                ...loader,
                loaderCounter: loader.loaderTotalProviders
            }
        })

        setTimeout(() => {
            dispatch(setRocketLoaderAction(true, true))
        }, 1e3)
    }

    const renderPercents = () => {
        const localState = store.getState().mobileMarket

        if (loader.loaderCounter === 0 || loader.loaderTotalProviders === 0 || !localState.amount) {
            return null
        }

        let percentage = UtilsService.cutNumber((100 * loader.loaderCounter) / loader.loaderTotalProviders, 0) || null
        let rocketFly = localState.rocket ? `calc(${100}% + 100px)` : percentage >= 100 ? `calc(${100}% - 90px)` : `calc(${percentage}% - 90px)`
        let progressBar = localState.rocket ? `100%` : percentage >= 100 ? `calc(${100}% - 50px)` : `calc(${percentage}% - 50px)`

        return (
            <div className='progressWrapper' style={{ paddingBottom: !localState.showArray ? 20 : '' }}>
                <div
                    className={localState.rocket ? 'progressBarFull' : 'progressBar'}
                    style={{ marginRight: localState.rocket ? '' : 24, width: localState.rocket ? '' : progressBar }}>
                    {localState.rocket ? (
                        <div className='progressBarText'>
                            {strings('mobileMarket.searchResults')}
                            <div className='progressBarText'>
                                {localState.amount +
                                    ' ' +
                                    (localState.amountSide === 'IN' ? localState.inCurrency.symbol : localState.outCurrency.symbol)}
                            </div>
                        </div>
                    ) : null}
                </div>
                <div
                    className='progress'
                    style={{
                        marginRight: 24,
                        marginLeft: rocketFly,
                        display: localState.rocket ? 'none' : '',
                        marginTop: !localState.showArray && !localState.rocket ? 1.5 : ''
                    }}>
                    <div style={{ transform: 'rotate(47deg)', width: 95, background: 'transparent' }}>
                        <Lottie options={defaultOptions} height={95} width={95} />
                    </div>
                </div>
            </div>
        )
    }

    const sortVariants = (variantsArray, rate = false, side = null) => {
        let withExchangeRates = variantsArray.filter(
            (variant) => variant.equivalentData && (!variant.equivalentData.errorCode || variant.equivalentData.errorCode === 'EXCEEDING_LIMITS')
        )
        let withEquivalentData = variantsArray.filter((variant) => variant.equivalentData && variant.equivalentData.status === 'SUCCESS')
        let withoutEquivalentData = variantsArray.filter((variant) => variant.equivalentData && variant.equivalentData.status !== 'SUCCESS')
        let withoutExchangeRates = variantsArray.filter((variant) => !variant.equivalentData)

        const amountSide = side ? side : state.amountSide ? 'IN' : 'OUT'

        switch (amountSide) {
            case 'IN':
                withExchangeRates = rate ? withExchangeRates.sort((a, b) => b.equivalentData.exchangeRate - a.equivalentData.exchangeRate) : null
                withEquivalentData = withEquivalentData.sort((a, b) => b.equivalentData.outAmount - a.equivalentData.outAmount)
                withoutEquivalentData = withoutEquivalentData.sort((a, b) => b.equivalentData.exchangeRate - a.equivalentData.exchangeRate)
                break

            case 'OUT':
                withExchangeRates = rate ? withExchangeRates.sort((a, b) => b.equivalentData.exchangeRate - a.equivalentData.exchangeRate) : null
                withEquivalentData = withEquivalentData.sort((a, b) => a.equivalentData.inAmount - b.equivalentData.inAmount)
                withoutEquivalentData = withoutEquivalentData.sort((a, b) => b.equivalentData.exchangeRate - a.equivalentData.exchangeRate)
                break

            default:
                return variantsArray
        }

        if (rate) {
            return withExchangeRates && withExchangeRates.length ? withExchangeRates[0] : false
        }

        return [...withEquivalentData, ...withoutEquivalentData, ...withoutExchangeRates]
    }

    const filterIsActive = (variantsArray) => {
        if (!variantsArray || !variantsArray.length) return []

        const state = store.getState().mobileMarket

        const idWays = state.allExchangeWays.map((way) => way.exchangeWayId)

        const newVariants = variantsArray.filter((way) => idWays.includes(way.exchangeWayId))
        const commingSoon = variantsArray.filter((way) => (way.equivalentData ? way.equivalentData.errorCode === 'COMING_SOON' : false))
        return [...newVariants, ...commingSoon]
    }

    const variantFilter = (provider) => {
        if (typeof provider.exchangeWayId === 'undefined') return []

        const state = store.getState().mobileMarket

        const idWays = state.allExchangeWays.map((way) => way.exchangeWayId)

        if (idWays.includes(provider.exchangeWayId)) {
            return provider
        } else return null
    }

    const renderVariants = () => {
        const localState = store.getState().mobileMarket

        if (!loading && !localState.showArray && variantsArray && !variantsArray.length) {
            return (
                <div className='progressWrapper' style={{ alignItems: 'flex-start', alignSelf: 'flex-start' }}>
                    <div className='progressBar' style={{ width: 0 }}></div>
                    <div className='progress' style={{ transform: 'rotate(47deg)', marginLeft: 0, width: 95 }}>
                        <Lottie options={defaultOptions} height={95} width={95} />
                    </div>
                </div>
            )
        }

        const array = !localState.showArray ? variantsArray : localState.exchangeWaysArray
        providersArray = array

        if (providersArray && providersArray.length > 0) {
            providersArray = !localState.showArray ? sortVariants(array) : array
            // Log.info(`V3.firstScreen.renderVariants providersArray after sortVariants ${JSON.stringify(providersArray)}`)

            // if (localState.inCurrency && localState.outCurrency && providersArray.length) {
            //     providersArray = filterVariants(providersArray)
            // Log.info(`V3.firstScreen.renderVariants providersArray after filterVariants ${JSON.stringify(providersArray)}`)
            // }
        }

        if (localState.showArray && !localState.selectorLoader && (!providersArray || providersArray.length === 0)) {
            if (
                !localState.inCurrency ||
                !localState.outCurrency ||
                (localState.inCurrency &&
                    localState.outCurrency &&
                    (localState.inCurrency.type === 'FIAT' || localState.outCurrency.type === 'FIAT') &&
                    !localState.payway)
            ) {
                setTimeout(async () => {
                    setShowError(true)
                }, 0)
            }

            let text
            if (!state.inCurrency) {
                text = strings('mobileMarket.notInCurrency')
            } else if (!state.outCurrency) {
                text = strings('mobileMarket.notOutCurrency')
            } else if (
                (state.inCurrency.type === 'FIAT' || state.outCurrency.type === 'FIAT') &&
                state.inCurrency.type !== 'FIAT' &&
                state.outCurrency.type !== 'FIAT' &&
                !state.payway
            ) {
                text = strings('mobileMarket.notPayway')
            } else if (
                (state.inCurrency && typeof state.inCurrency.notActive !== 'undefined' && state.inCurrency.notActive) ||
                (state.outCurrency && typeof state.outCurrency.notActive !== 'undefined' && state.outCurrency.notActive)
            ) {
                text = strings('mobileMarket.noWay')
            } else {
                text = strings('mobileMarket.noTempWay')
            }

            return (
                <div style={{ marginTop: 24 }}>
                    <Message text={text} theme={theme} move={move} />
                </div>
            )
        }

        return (
            <div>
                {providersArray &&
                    providersArray.length &&
                    providersArray.map((providerData, index) => {
                        let bestOffer =
                            variantsArray.length > 1 || (providersArray.length > 1 && typeof providerData.equivalentData !== 'undefined')
                                ? providersArray[0]
                                : null
                        let bestRate =
                            variantsArray.length > 1 && providerData.equivalentData && providerData.equivalentData.errorCode !== 'COMING_SOON'
                                ? sortVariants(providersArray, true, providersArray[0].exchangeRateSide)
                                : providersArray.filter((way) => way.isActive).length > 1 && typeof providerData.exchangeRate !== 'undefined'
                                ? providersArray[0]
                                : null

                        if (localState.showArray && providerData.exchangeRate) {
                            return (
                                <>
                                    <WayItem key={index} item={providerData} setTooltip={setTooltip} theme={theme} state={localState} />
                                    {providersArray.length !== index + 1 && <Line width={'75%'} />}
                                </>
                            )
                        } else {
                            return (
                                <ProviderItem
                                    key={index}
                                    item={providerData}
                                    offer={bestOffer}
                                    rate={bestRate}
                                    loader={load}
                                    setLoad={setLoad}
                                    providersArray={providersArray}
                                    findButtonHandler={findButtonHandler}
                                    setLoader={setLoader}
                                    setVariantsArray={setVariantsArray}
                                    setLoading={setLoading}
                                    setTooltip={setTooltip}
                                    theme={theme}
                                />
                            )
                        }
                    })}
            </div>
        )
    }

    const setFiatArray = () => {
        return Object.keys(FiatCurrencyName).map((key) => {
            return {
                code: key,
                value: [CurrencyList.get(key, 'en').name, CurrencyList.get(key, 'ru').name, CurrencyList.get(key, 'uk').name]
            }
        })
    }

    const inCurrencyModal = () => {
        let newTmpState

        if (skipProviders) {
            newTmpState = store.getState().mobileMarket
        } else {
            newTmpState = state
        }

        const fiatArray = setFiatArray()
        const wallet = data
            ? data.wallets.find((account) => account.cashbackToken === newTmpState.cashbackToken)
            : newTmpState.walletData.wallets.find((account) => account.cashbackToken === newTmpState.cashbackToken)

        if (!newTmpState.walletData) {
            setName(null)
            setFirebase(true)
            dispatch(walletDataAction(data))
        }

        isActiveCoin()
        dispatch(searchValueInSideAction(null))

        showScreenModal({
            type: 'SELECT_MODAL',
            data: {
                side: 'IN',
                search: true,
                setShowError: setShowError,
                setShowErrorBtn: setShowErrorBtn,
                fiatArray,
                wallet
            }
        })
        return
    }

    const outCurrencyModal = () => {
        let newTmpState

        if (skipProviders) {
            newTmpState = store.getState().mobileMarket
        } else {
            newTmpState = state
        }

        const fiatArray = setFiatArray()
        const wallet = data
            ? data.wallets.find((account) => account.cashbackToken === newTmpState.cashbackToken)
            : newTmpState.walletData.wallets.find((account) => account.cashbackToken === newTmpState.cashbackToken)

        if (!newTmpState.walletData) {
            setName(null)
            setFirebase(true)
            dispatch(walletDataAction(data))
        }

        isActiveCoin()
        dispatch(searchValueOutSideAction(null))

        showScreenModal({
            type: 'SELECT_MODAL',
            data: {
                side: 'OUT',
                search: true,
                setShowError: setShowError,
                setShowErrorBtn: setShowErrorBtn,
                fiatArray,
                wallet
            }
        })
        return
    }

    const paywayModal = () => {
        const newState = store.getState().mobileMarket

        let exchangeWays = newState.preRequestExchagneWays
        exchangeWays = newState.inCurrency.code ? exchangeWays.filter((way) => way.inCurrency.code === newState.inCurrency.code) : exchangeWays
        exchangeWays = newState.outCurrency.code ? exchangeWays.filter((way) => way.outCurrency.code === newState.outCurrency.code) : exchangeWays

        let payway = Array.from(
            new Set(
                exchangeWays.map((way) => {
                    switch (way.exchangeWayType) {
                        case 'BUY':
                            return way.inPaywayCode
                        case 'SELL':
                            return way.outPaywayCode
                        default:
                            return null
                    }
                })
            )
        )

        dispatch(paywayListAction(payway))

        dispatch(searchValuePaywayAction(null))
        showScreenModal({
            type: 'SELECT_MODAL',
            data: {
                side: 'PAYWAY',
                search: true,
                setShowError: setShowError,
                setShowErrorBtn: setShowErrorBtn
            }
        })
        return
    }

    const searchModalHandler = () => {
        providersArray && providersArray.length ? setVariantsArray([...providersArray]) : setVariantsArray([])
        setTimeout(() => {
            if (!state.walletData) {
                if (!skipProviders) {
                    setName(null)
                    setFirebase(true)
                }
                dispatch(walletDataAction(data))
            }
        }, 250)

        showSearchScreenModal(
            {
                type: 'SEARCH_MODAL',
                data: {
                    variantsArray: providersArray && providersArray.length ? [...providersArray] : []
                },
                leftHandler: () => closeModalHandler(),
                inCurrencyModal: () => inCurrencyModal(),
                outCurrencyModal: () => outCurrencyModal(),
                paywayModal: () => paywayModal()
            },
            () => {
                findButtonHandler()
            }
        )
        return
    }

    const closeModalHandler = () => {
        setTimeout(() => {
            hideSearchScreenModal()
        }, 250)
    }

    const mainHandlerBtn = () => {
        searchModalHandler()
        providersArray && providersArray.length ? setVariantsArray(() => [...providersArray]) : setVariantsArray(() => [])
    }

    const errorHandlerBtn = () => {
        if ((state.inCurrency.type === 'FIAT' && state.outCurrency.type === 'FIAT') || !state.exchangeWaysArray || !state.exchangeWaysArray.length) {
            setMove(true)
            setTimeout(async () => {
                setMove(false)
            }, 350)
        } else {
            setShowErrorBtn(true)
            setTimeout(async () => {
                setShowErrorBtn(false)
            }, 1500)
        }
    }

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }

    const url = (url) => {
        history.push({
            pathname: `/mobile-market/${url}`,
            state: { type: 'page' }
        })
    }

    const orderHistory = () => {
        url('history')
    }

    const urlPath = (url) => {
        history.push({
            pathname: `/mobile-market/${url}`,
            location: { state: 'fade' }
        })
    }

    const pathUrl = (url) => {
        history.push('/mobile-market/' + url)
    }

    return (
        <>
            {state.showGuide && <Slider theme={theme} />}
            {state.showLoader && <Loader />}
            {(!firebase ? data : state.walletData && state.walletData.wallets) ? (
                <>
                    {load && <Loader />}
                    <TopNavigation
                        text={strings('mobileMarket.navigation.market')}
                        styles={{ filter: showTooltip ? 'blur(7px)' : '' }}
                        rightType='orderHistory'
                        rightAction={orderHistory}
                        leftType={config.showError ? 'emailClean' : null}
                        leftAction={config.showError ? emailClean : null}
                    />
                    <Content blur={showTooltip} background={theme.main.bg}>
                        <PullRefresh onRefresh={refreshLoader} color='#999' bgColor={theme.topNav.background} zIndex={500}>
                            <Wrapper id='mainScreen'>
                                <WrapperSelector
                                    inCurrencyModal={inCurrencyModal}
                                    outCurrencyModal={outCurrencyModal}
                                    paywayModal={paywayModal}
                                    showError={showError}
                                    showErrorBtn={showErrorBtn}
                                    theme={theme}
                                    walletData={walletData}
                                    userCurrencies={userCurrencies}
                                />
                                <ScreenDiv loaderBG={theme.provider.failBg} loaderColor={theme.topNav.color} rocketLoader={theme.main.rocketLoader}>
                                    <div style={{ paddingTop: state.rocket ? 16 : 24 }}>
                                        {state.selectorLoader ? (
                                            <div className='wrapper__percent' style={{ justifyContent: 'center' }}>
                                                <img src={BlackLoader} height={52} alt={'preloader'} />
                                            </div>
                                        ) : (
                                            <>
                                                <div className='percent__wrapper'>{!state.showArray || state.rocket ? renderPercents() : null}</div>
                                                <div
                                                    className={
                                                        !loading && !state.showArray && variantsArray && !variantsArray.length
                                                            ? 'wrapper__percent'
                                                            : null
                                                    }>
                                                    {renderVariants()}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </ScreenDiv>
                            </Wrapper>
                        </PullRefresh>
                    </Content>
                    <ShadowBtn bg={theme.main.shadowBg}>
                        <Button
                            condition={
                                state.inCurrency && state.inCurrency.type !== 'FIAT' && state.outCurrency && state.outCurrency.type !== 'FIAT'
                                    ? !showErrorBtn
                                    : !move
                            }
                            style={{
                                width: 'calc(100% - 32px)',
                                bottom: '24px',
                                position: 'fixed',
                                left: window.screen.width > 500 ? 'calc((100% - 464px) / 2)' : '16px',
                                maxWidth: '464px'
                            }}
                            handler={() => {
                                state.inCurrency &&
                                state.outCurrency &&
                                (state.inCurrency.type === 'FIAT' || state.outCurrency.type === 'FIAT' ? state.payway : true) &&
                                (state.exchangeWaysArray && state.exchangeWaysArray.length ? true : false)
                                    ? mainHandlerBtn()
                                    : errorHandlerBtn()
                            }}>
                            <div>
                                {strings(
                                    `mobileMarket.tooltip.${!state.showArray || state.rocket || state.savePosition ? 'changeDetails' : 'details'}`
                                )}
                                {/* <Icon icon={'SEARCH'} color={'#f7f7f7'} size={22} /> */}
                            </div>
                        </Button>
                    </ShadowBtn>
                </>
            ) : (
                <Loader />
            )}
            {modalState.show && <Modal />}
            {modalScreenState.show && <ScreenModal />}
            {searchModalScreenState.searchShow && <SearchScreenModal />}
            {searchModalScreenState.cardShow && <CardScreenModal />}
            {showTooltip && (
                <Tooltip
                    close={() => setTooltip(false)}
                    searchModalHandler={searchModalHandler}
                    providersArray={providersArray}
                    setVariantsArray={setVariantsArray}
                    theme={theme}
                />
            )}
        </>
    )
}

export default React.memo(RenderFirstScreen)
