export default {
    // CRYPTO
    ETH_UAX: {
        colors: {
            mainColor: '#2965FF',
            darkColor: '#2852BE'
        }
    },
    ETH_ONE: {
        colors: {
            mainColor: '#35D4D3',
            darkColor: '#2FB9B8'
        }
    },
    TRX_USDT: {
        colors: {
            mainColor: '#31D182',
            darkColor: '#2D9D66'
        }
    },
    TRX_BTT: {
        colors: {
            mainColor: '#D73A64',
            darkColor: '#CA4D6F'
        }
    },
    XVG: {
        colors: {
            mainColor: '#00CBFF',
            darkColor: '#14B5DE'
        }
    },
    DOGE: {
        colors: {
            mainColor: '#CEB55C',
            darkColor: '#C1AB5D'
        }
    },
    ETH_SOUL: {
        colors: {
            mainColor: '#A1B8CE',
            darkColor: '#92A7BB'
        }
    },
    TRX: {
        colors: {
            mainColor: '#E51A31',
            darkColor: '#E51A31'
        }
    },
    LTC: {
        colors: {
            mainColor: '#60BBF5',
            darkColor: '#359ADB'
        }
    },
    ETH: {
        colors: {
            mainColor: '#1EB3E4',
            darkColor: '#208FB4'
        }
    },
    ETH_ROPSTEN: {
        colors: {
            mainColor: '#68268C',
            darkColor: '#9042BA'
        }
    },
    BTC: {
        colors: {
            mainColor: '#F79E1B',
            darkColor: '#B87A1E'
        }
    },
    BTC_TEST: {
        colors: {
            mainColor: '#F79E1B',
            darkColor: '#B87A1E'
        }
    },
    BCH: {
        colors: {
            mainColor: '#F79E1B',
            darkColor: '#B87A1E'
        }
    },
    BTG: {
        colors: {
            mainColor: '#132365',
            darkColor: '#4F64B9'
        }
    },
    USDT: {
        colors: {
            mainColor: '#31D182',
            darkColor: '#2D9D66'
        }
    },
    ETH_TRUE_USD: {
        colors: {
            mainColor: '#5BB6ED',
            darkColor: '#1BA594'
        }
    },
    TRX_TUSD: {
        colors: {
            mainColor: '#5BB6ED',
            darkColor: '#1BA594'
        }
    },
    ETH_USDT: {
        colors: {
            mainColor: '#31D182',
            darkColor: '#2D9D66'
        }
    },
    BNB: {
        colors: {
            mainColor: '#F59E6C',
            darkColor: '#E3986D'
        }
    },
    ETH_BNB: {
        colors: {
            mainColor: '#F59E6C',
            darkColor: '#E3986D'
        }
    },
    ETH_USDC: {
        colors: {
            mainColor: '#2A7FDB',
            darkColor: '#2FB9B8'
        }
    },
    TRX_USDC: {
        colors: {
            mainColor: '#2A7FDB',
            darkColor: '#2FB9B8'
        }
    },
    BSV: {
        colors: {
            mainColor: '#C8102E',
            darkColor: '#C12741'
        }
    },
    ETH_PAX: {
        colors: {
            mainColor: '#02D193',
            darkColor: '#20BE8F'
        }
    },
    ETH_DAI: {
        colors: {
            mainColor: '#FF6F45',
            darkColor: '#E6643E'
        }
    },
    ETH_DAIM: {
        colors: {
            mainColor: '#F4B731',
            darkColor: '#CB9A2F'
        }
    },
    ETH_OKB: {
        colors: {
            mainColor: '#3075EE',
            darkColor: '#2A69D7'
        }
    },
    XRP: {
        colors: {
            mainColor: '#1B1818',
            darkColor: '#B8B8B8'
        }
    },
    ETH_MKR: {
        colors: {
            mainColor: '#1AAB9B',
            darkColor: '#1C9083'
        }
    },
    ETH_KNC: {
        colors: {
            mainColor: '#31CB9E',
            darkColor: '#2FAA86'
        }
    },
    ETH_COMP: {
        colors: {
            mainColor: '#00D395',
            darkColor: '#07B07F'
        }
    },
    ETH_BAL: {
        colors: {
            mainColor: '#303030',
            darkColor: '#B8B8B8'
        }
    },
    ETH_LEND: {
        colors: {
            mainColor: '#1E8FCA',
            darkColor: '#1F7AA9'
        }
    },
    ETH_BNT: {
        colors: {
            mainColor: '#000D2B',
            darkColor: '#4B5F8E'
        }
    },
    XMR: {
        colors: {
            mainColor: '#F26822',
            darkColor: '#E16323'
        }
    },
    FIO: {
        colors: {
            mainColor: '#4C5FAB',
            darkColor: '#4C5FAB'
        }
    },
    XLM: {
        colors: {
            mainColor: '#181818',
            darkColor: '#B8B8B8'
        }
    },
    TRX_WINK: {
        colors: {
            mainColor: '#FBC509',
            darkColor: '#D1AB24'
        }
    },
    TRX_SUN: {
        colors: {
            mainColor: '#FEDF3C',
            darkColor: '#D7C253'
        }
    },
    TRX_SUN_NEW: {
        colors: {
            mainColor: '#FEDF3C',
            darkColor: '#D7C253'
        }
    },
    TRX_JST: {
        colors: {
            mainColor: '#B41514',
            darkColor: '#AB1D2D'
        }
    },
    TRX_USDJ: {
        colors: {
            mainColor: '#B41514',
            darkColor: '#AB1D2D'
        }
    },
    TRX_WBTT: {
        colors: {
            mainColor: '#D73A64',
            darkColor: '#CA4D6F'
        }
    },
    ETH_BADGER: {
        colors: {
            mainColor: '#C5C5C5',
            darkColor: '#C5C5C5'
        }
    },
    ETH_CRO: {
        colors: {
            mainColor: '#103F68',
            darkColor: '#356E9F'
        }
    },
    ETH_BTC: {
        colors: {
            mainColor: '#282138',
            darkColor: '#6A6477'
        }
    },
    ETH_RSR: {
        colors: {
            mainColor: '#161616',
            darkColor: '#B8B8B8'
        }
    },
    ETH_YFI: {
        colors: {
            mainColor: '#006AE3',
            darkColor: '#006AE3'
        }
    },
    ETH_CHZ: {
        colors: {
            mainColor: '#CD0124',
            darkColor: '#B9213B'
        }
    },
    ETH_OMG: {
        colors: {
            mainColor: '#141414',
            darkColor: '#B8B8B8'
        }
    },
    ETH_MATIC: {
        colors: {
            mainColor: '#8247E5',
            darkColor: '#8247E5'
        }
    },
    MATIC: {
        colors: {
            mainColor: '#8247E5',
            darkColor: '#8247E5'
        }
    },
    ETH_BAT: {
        colors: {
            mainColor: '#9E1F63',
            darkColor: '#9E1F63'
        }
    },
    ETH_ZRX: {
        colors: {
            mainColor: '#231815',
            darkColor: '#817E7D'
        }
    },
    ETH_SNX: {
        colors: {
            mainColor: '#00D1FF',
            darkColor: '#25AAC8'
        }
    },
    ETH_1INCH: {
        colors: {
            mainColor: '#1B314F',
            darkColor: '#3C5A84'
        }
    },
    ETH_ENJ: {
        colors: {
            mainColor: '#7866D5',
            darkColor: '#7866D5'
        }
    },
    ETH_SUSHI: {
        colors: {
            mainColor: '#FA52A0',
            darkColor: '#EA4E96'
        }
    },
    ETH_HUOBI: {
        colors: {
            mainColor: '#357CE1',
            darkColor: '#2769C7'
        }
    },
    ETH_AAVE: {
        colors: {
            mainColor: '#2EBAC6',
            darkColor: '#2DB1BC'
        }
    },
    ETH_GRT: {
        colors: {
            mainColor: '#6747ED',
            darkColor: '#6747ED'
        }
    },
    ETH_CRV: {
        colors: {
            mainColor: '#A30401',
            darkColor: '#AB1D2D'
        }
    },
    BNB_SMART_UNI: {
        colors: {
            mainColor: '#FF007A',
            darkColor: '#D02175'
        }
    },
    ETH_UNI: {
        colors: {
            mainColor: '#FF007A',
            darkColor: '#D02175'
        }
    },
    ETH_LINK: {
        colors: {
            mainColor: '#375BD2',
            darkColor: '#375BD2'
        }
    },
    BNB_SMART_LINK: {
        colors: {
            mainColor: '#375BD2',
            darkColor: '#375BD2'
        }
    },
    ETH_SXP: {
        colors: {
            mainColor: '#FF5331',
            darkColor: '#E44B2D'
        }
    },
    BNB_SMART_SXP: {
        colors: {
            mainColor: '#FF5331',
            darkColor: '#FF5331'
        }
    },
    ETH_ALPHA: {
        colors: {
            mainColor: '#29B2FF',
            darkColor: '#29B2FF'
        }
    },
    BNB_SMART_ALPHA: {
        colors: {
            mainColor: '#29B2FF',
            darkColor: '#3A9DD5'
        }
    },
    BNB_SMART_ADA: {
        colors: {
            mainColor: '#0033AD',
            darkColor: '#406DDA'
        }
    },
    BNB_SMART_CAKE: {
        colors: {
            mainColor: '#D1884F',
            darkColor: '#D1884F'
        }
    },
    BNB_SMART_USDT: {
        colors: {
            mainColor: '#31D182',
            darkColor: '#2D9D66'
        }
    },
    ETH_BUSD: {
        colors: {
            mainColor: '#F0B90B',
            darkColor: '#C79C15'
        }
    },
    BNB_SMART_USD: {
        colors: {
            mainColor: '#F0B90B',
            darkColor: '#C79C15'
        }
    },
    BNB_SMART: {
        colors: {
            mainColor: '#F3B80C',
            darkColor: '#C79C15'
        }
    },
    BNB_SMART_USDC: {
        colors: {
            mainColor: '#2A7FDB',
            darkColor: '#2FB9B8'
        }
    },
    TRX_ETH: {
        colors: {
            mainColor: '#1EB3E4',
            darkColor: '#208FB4'
        }
    },
    BNB_SMART_ETH: {
        colors: {
            mainColor: '#1EB3E4',
            darkColor: '#208FB4'
        }
    },
    TRX_BTC: {
        colors: {
            mainColor: '#F79E1B',
            darkColor: '#B87A1E'
        }
    },
    BNB_SMART_BTC: {
        colors: {
            mainColor: '#F79E1B',
            darkColor: '#B87A1E'
        }
    },
    BNB_SMART_BAT: {
        colors: {
            mainColor: '#9E1F63',
            darkColor: '#9E1F63'
        }
    },
    BNB_SMART_LTC: {
        colors: {
            mainColor: '#60BBF5',
            darkColor: '#359ADB'
        }
    },
    BNB_SMART_DOGE: {
        colors: {
            mainColor: '#CEB55C',
            darkColor: '#C1AB5D'
        }
    },
    ETH_STORJ: {
        colors: {
            mainColor: '#2683FF',
            darkColor: '#3F81D9'
        }
    },
    TRX_DOGE: {
        colors: {
            mainColor: '#CEB55C',
            darkColor: '#C1AB5D'
        }
    },
    BNB_SMART_TRX: {
        colors: {
            mainColor: '#E51A31',
            darkColor: '#E51A31'
        }
    },
    BNB_SMART_BTT: {
        colors: {
            mainColor: '#D73A64',
            darkColor: '#CA4D6F'
        }
    },
    BNB_SMART_WINK: {
        colors: {
            mainColor: '#FBC509',
            darkColor: '#D1AB24'
        }
    },
    ETH_FTT: {
        colors: {
            mainColor: '#62D1E5',
            darkColor: '#4FC2D7'
        }
    },
    BNB_SMART_FTT: {
        colors: {
            mainColor: '#62D1E5',
            darkColor: '#4FC2D7'
        }
    },
    ETH_NEXO: {
        colors: {
            mainColor: '#3CA9E5',
            darkColor: '#3CA9E5'
        }
    },
    BNB_SMART_NEXO: {
        colors: {
            mainColor: '#3CA9E5',
            darkColor: '#3CA9E5'
        }
    },
    ETC: {
        colors: {
            mainColor: '#3AB83A',
            darkColor: '#2CAC2C'
        }
    },
    ETH_NOW: {
        colors: {
            mainColor: '#00C26F',
            darkColor: '#13AF6C'
        }
    },
    ETH_BTT: {
        colors: {
            mainColor: '#D73A64',
            darkColor: '#CA4D6F'
        }
    },
    ETH_TRX: {
        colors: {
            mainColor: '#E51A31',
            darkColor: '#E51A31'
        }
    },
    ETH_SHIB: {
        colors: {
            mainColor: '#F6A31D',
            darkColor: '#CC8C25'
        }
    },
    VTHO: {
        colors: {
            mainColor: '#4B6F9C',
            darkColor: '#4B6F9C'
        }
    },
    VET: {
        colors: {
            mainColor: '#15BCFD',
            darkColor: '#2CAEE1'
        }
    },
    TRX_APE: {
        colors: {
            mainColor: '#070001',
            darkColor: '#B8B8B8'
        }
    },
    SOL: {
        colors: {
            mainColor: '#00FFA3',
            darkColor: '#22DB98'
        }
    },
    AMB: {
        colors: {
            mainColor: '#341CBA',
            darkColor: '#6E55F9'
        }
    },
    MATIC: {
        colors: {
            mainColor: '#8247E5',
            darkColor: '#8247E5'
        }
    },
    ETH_KNC_NEW: {
        colors: {
            mainColor: '#31CB9E',
            darkColor: '#2FAA86'
        }
    },
    TON: {
        colors: {
            mainColor: '#47A9F1',
            darkColor: '#47A9F1'
        }
    },
    WAVES: {
        colors: {
            mainColor: '#0055FE',
            darkColor: '#457FF1'
        }
    },

    // FIAT
    UAH: {
        colors: {
            mainColor: '#1B3F9A',
            darkColor: '#3F5DA9'
        }
    },
    RUB: {
        colors: {
            mainColor: '#495951',
            darkColor: '#698476'
        }
    },
    USD: {
        colors: {
            mainColor: '#0B6809',
            darkColor: '#3B843A'
        }
    },
    KZT: {
        colors: {
            mainColor: '#8F0906',
            darkColor: '#AD3E3B'
        }
    },
    EUR: {
        colors: {
            mainColor: '#7B231D',
            darkColor: '#99443E'
        }
    },
    GBP: {
        colors: {
            mainColor: '#4B338D',
            darkColor: '#6B52AF'
        }
    },
    TRY: {
        colors: {
            mainColor: '#9A4C2E',
            darkColor: '#B66A4C'
        }
    },
    AUD: {
        colors: {
            mainColor: '#E3BB4D',
            darkColor: '#C9AE64'
        }
    },
    BGN: {
        colors: {
            mainColor: '#BF7FA4',
            darkColor: '#BF7FA4'
        }
    },
    BRL: {
        colors: {
            mainColor: '#6EC074',
            darkColor: '#78B57C'
        }
    },
    CAD: {
        colors: {
            mainColor: '#D10026',
            darkColor: '#C8344F'
        }
    },
    CHF: {
        colors: {
            mainColor: '#E75E53',
            darkColor: '#E0756C'
        }
    },
    CNY: {
        colors: {
            mainColor: '#C41F29',
            darkColor: '#C75159'
        }
    },
    COP: {
        colors: {
            mainColor: '#E99E6A',
            darkColor: '#C69573'
        }
    },
    CZK: {
        colors: {
            mainColor: '#5BA4EC',
            darkColor: '#6AA2DB'
        }
    },
    DKK: {
        colors: {
            mainColor: '#C88930',
            darkColor: '#C88930'
        }
    },
    DOP: {
        colors: {
            mainColor: '#39C9CA',
            darkColor: '#72C5C6'
        }
    },
    EGP: {
        colors: {
            mainColor: '#B982A5',
            darkColor: '#B982A5'
        }
    },
    HKD: {
        colors: {
            mainColor: '#067B60',
            darkColor: '#449F8A'
        }
    },
    HRK: {
        colors: {
            mainColor: '#BDB65D',
            darkColor: '#BDB65D'
        }
    },
    IDR: {
        colors: {
            mainColor: '#588EE2',
            darkColor: '#7398D3'
        }
    },
    ILS: {
        colors: {
            mainColor: '#E3B741',
            darkColor: '#D1AE4E'
        }
    },
    JOD: {
        colors: {
            mainColor: '#0191C8',
            darkColor: '#3398BE'
        }
    },
    JPY: {
        colors: {
            mainColor: '#897AC0',
            darkColor: '#897AC0'
        }
    },
    KES: {
        colors: {
            mainColor: '#DF8F73',
            darkColor: '#DF8F73'
        }
    },
    KRW: {
        colors: {
            mainColor: '#DFBB57',
            darkColor: '#CAAC5A'
        }
    },
    KWD: {
        colors: {
            mainColor: '#4BB561',
            darkColor: '#6BB77B'
        }
    },
    LKR: {
        colors: {
            mainColor: '#D3B946',
            darkColor: '#CBB559'
        }
    },
    MAD: {
        colors: {
            mainColor: '#9EC949',
            darkColor: '#9EC949'
        }
    },
    MXN: {
        colors: {
            mainColor: '#DC9FC1',
            darkColor: '#D594B8'
        }
    },
    MYR: {
        colors: {
            mainColor: '#CF8A2D',
            darkColor: '#D59E54'
        }
    },
    NGN: {
        colors: {
            mainColor: '#AB343F',
            darkColor: '#BD4E58'
        }
    },
    NOK: {
        colors: {
            mainColor: '#E3911B',
            darkColor: '#CE923C'
        }
    },
    NZD: {
        colors: {
            mainColor: '#403E9C',
            darkColor: '#6866C2'
        }
    },
    OMR: {
        colors: {
            mainColor: '#57B8A8',
            darkColor: '#74C7B9'
        }
    },
    PEN: {
        colors: {
            mainColor: '#E6AD73',
            darkColor: '#DBB186'
        }
    },
    PKR: {
        colors: {
            mainColor: '#6E739F',
            darkColor: '#767DB6'
        }
    },
    PLN: {
        colors: {
            mainColor: '#E2CC75',
            darkColor: '#D1C180'
        }
    },
    RON: {
        colors: {
            mainColor: '#D8C925',
            darkColor: '#C0B537'
        }
    },
    SEK: {
        colors: {
            mainColor: '#C16B35',
            darkColor: '#C16B35'
        }
    },
    SGD: {
        colors: {
            mainColor: '#88D670',
            darkColor: '#8DC17D'
        }
    },
    THB: {
        colors: {
            mainColor: '#64C7B8',
            darkColor: '#71B7AC'
        }
    },
    TWD: {
        colors: {
            mainColor: '#9B648F',
            darkColor: '#B173A4'
        }
    },
    VND: {
        colors: {
            mainColor: '#CC6CA4',
            darkColor: '#C77DA8'
        }
    },
    ZAR: {
        colors: {
            mainColor: '#468CD5',
            darkColor: '#6293C7'
        }
    },

    DEFAULT: {
        colors: {
            mainColor: '#000',
            darkColor: '#999'
        }
    }
}
