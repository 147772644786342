import React from 'react'

import Notice from './Notification.css'

const NotificationItem = (props) => {
    const { id, message } = props

    return (
        <Notice>
            <div className='message'>Copied: {message}</div>
        </Notice>
    )
}

export default NotificationItem
