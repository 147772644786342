import React from 'react'
import Icon from '../Icon/Icon'
import MsgDiv from './Msg.css'

const Message = (props) => {
    const { text, theme, move } = props

    return (
        <MsgDiv move={move} colorText={theme.topNav.colorIcon}>
            <div className='wrapper'>
                <div className='icon'>
                    <Icon icon='INFO' color={theme.main.checked} size={24} />
                </div>
                <div className='text'>{text}</div>
            </div>
        </MsgDiv>
    )
}

export default Message
