import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Line from 'components/Line/line'

import { strings } from 'i18n'
import { getUserCurrencies, getWalletData, getOutDestination } from 'redux/AppStores/MobileMarket/selectors'
import { hideScreenModal } from 'redux/AppStores/ScreenModalStore/action'
import { newOutDestinationAction, outDestinationAction } from 'redux/AppStores/MobileMarket/action'

import { getThemeMode } from 'theme/useDarkMode'

import Segwit from 'img/icon/segwitIcon.svg'
import SegwitDark from 'img/icon/segwitDark.svg'

import Log from 'services/sentry/Log'
import hideAnimation from 'services/hideAnimation'

import { BodyWrapper } from '../wrapper.css'

const notActive = ['TRX_BTT', 'XVG', 'BTG', 'BCH', 'BSV']

const AddressModal = (props) => {
    const walletData = useSelector(getWalletData)
    const userCurrencies = useSelector(getUserCurrencies)
    const destinationData = useSelector(getOutDestination)

    const { outCurrency } = userCurrencies

    const dispatch = useDispatch()

    const { theme } = props

    const walletsArray = walletData.wallets
    const outDestinationVariants = []

    for (let i = 0; i < walletsArray.length; i++) {
        const walletAccount = walletsArray[i] && walletsArray[i]?.accounts?.find((account) => account.currencyCode === outCurrency.code)
        if (!walletAccount) {
            continue
        }

        if (typeof walletAccount.address === 'string') {
            outDestinationVariants.push({
                walletName: walletsArray[i].walletName,
                outDestination: walletAccount.address
            })
        } else {
            walletAccount.address.map((address) =>
                outDestinationVariants.push({
                    walletName: walletsArray[i].walletName,
                    outDestination: address.address,
                    outDestinationType: address.type
                })
            )
        }
    }

    const themeMode = getThemeMode()

    const outDestinationSelectHandler = (outDestination, walletName) => {
        Log.info(`V3.selectModal.outDestinationSelectHandler outDestination ${outDestination}, walletName ${walletName}`)
        dispatch(outDestinationAction(outDestination, walletName))
        hideAnimation('selectModal-address', hideScreenModal)
        return
    }

    const newOutDestination = () => {
        dispatch(newOutDestinationAction())
        hideAnimation('selectModal-address', hideScreenModal)
    }

    return (
        <>
            <BodyWrapper style={{ marginTop: 54 }} labelColor={theme.topNav.colorIcon}>
                {outDestinationVariants.map((item, index) => {
                    return (
                        <>
                            <div className='currency__item'>
                                <label htmlFor={index} className='label__item'>
                                    <div className='label__data'>
                                        <div
                                            className='label__address'
                                            style={{ color: destinationData.outDestination === item.outDestination ? theme.main.checked : '' }}>
                                            {item.walletName}
                                        </div>
                                        <div
                                            className='label__walletName'
                                            style={{ color: destinationData.outDestination === item.outDestination ? theme.main.checked : '' }}>
                                            {item.outDestination.slice(0, 6) + '...' + item.outDestination.slice(-6)}
                                        </div>
                                    </div>
                                    {item.outDestinationType === 'SEGWIT' ? (
                                        <div className='label__addressType'>
                                            <img src={themeMode === 'dark' ? SegwitDark : Segwit} alt='segwit' />
                                        </div>
                                    ) : null}
                                </label>
                                <input
                                    type='radio'
                                    value={item.outDestination}
                                    id={index}
                                    key={index}
                                    onChange={(event) => outDestinationSelectHandler(event.target.value, item.walletName)}
                                />
                            </div>
                            {outDestinationVariants.length === index + 1 && notActive.includes(outCurrency.code) ? (
                                <div className='empty'></div>
                            ) : (
                                <Line width='75%' />
                            )}
                        </>
                    )
                })}
                {notActive.includes(outCurrency.code) ? (
                    <div className='label__newAddress'></div>
                ) : (
                    <div className='label__newAddress' onClick={newOutDestination}>
                        {strings('mobileMarket.modal.addressManually')}
                    </div>
                )}
            </BodyWrapper>
            <div style={{ height: 100 }}></div>
        </>
    )
}

export default React.memo(AddressModal)
