import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'

import Line from 'components/Line/line'
import PaywayListItem from 'components/PaywayModalListItem/paywayItem'
import paywayName from 'components/payways/paywayName'

import { hideScreenModal } from 'redux/AppStores/ScreenModalStore/action'
import { getPreRequestExchangeWays, getUserCurrencies, getUserSettings, getWalletData } from 'redux/AppStores/MobileMarket/selectors'
import { exchangeWaysAction, paymentDataAction, paywayAction, savePositionAction, setRocketLoaderAction } from 'redux/AppStores/MobileMarket/action'

import getActualWays from 'services/getActualWays'
import { sortWays } from 'services/sortWays'
import Log from 'services/sentry/Log'
import { getPaymentData } from 'services/getDataPayments'
import hideAnimation from 'services/hideAnimation'

import { BodyWrapper } from '../wrapper.css'

const card = ['CARD', 'BANK_TRANSFER']

const PaywayModal = (props) => {
    const userCurrencies = useSelector(getUserCurrencies)
    const preRequestExchangeWays = useSelector(getPreRequestExchangeWays)
    const walletData = useSelector(getWalletData)
    const settings = useSelector(getUserSettings)

    const { setShowError, setShowErrorBtn, theme, searchValue } = props

    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()

    const { inCurrency, outCurrency, cashbackToken, payway, paywayList } = userCurrencies

    let notActivePayways =
        inCurrency && outCurrency && preRequestExchangeWays.filter((way) => way.exchangeWayType === 'BUY' || way.exchangeWayType === 'SELL')
    notActivePayways = notActivePayways.filter((way) =>
        way.exchangeWayType === 'BUY'
            ? !paywayList.includes(way.inPaywayCode)
            : way.exchangeWayType === 'SELL'
            ? !paywayList.includes(way.outPaywayCode)
            : true
    )
    notActivePayways = Array.from(
        new Set(
            notActivePayways.map((way) =>
                way.exchangeWayType === 'BUY' ? way.inPaywayCode : way.exchangeWayType === 'SELL' ? way.outPaywayCode : false
            )
        )
    )
    let tmpNotActivePayways = Array.from(notActivePayways.filter((item) => card.includes(item)).map((item) => item))
    notActivePayways = Array.from(new Set([...tmpNotActivePayways, ...notActivePayways]))

    const searchPaywayArray = Object.keys(paywayName).filter((key) =>
        paywayName[key].toString().toUpperCase().includes(searchValue.searchValuePayway)
    )
    const searchPayway = searchValue.searchValuePayway
        ? paywayList.filter((item) => item.includes(searchValue.searchValuePayway) || searchPaywayArray.includes(searchValue.searchValuePayway))
        : []

    const paywayCard = Array.from(paywayList.filter((item) => card.includes(item)).map((item) => item))
    const paywayNotCard = Array.from(paywayList.filter((item) => !card.includes(item)).map((item) => item))

    const arrayPayways = paywayCard.concat(paywayNotCard)

    Log.info(`V3.selectModal.paywayModal arrayPayways ${JSON.stringify(arrayPayways)}`)

    const selectPayway = async (paywayCode, setShowError, setShowErrorBtn) => {
        let exchangeWaysArray, commingSoonArray
        exchangeWaysArray = preRequestExchangeWays.filter(
            (way) => way.inCurrency.code === inCurrency.code && way.outCurrency.code === outCurrency.code
        )
        exchangeWaysArray = exchangeWaysArray.filter((way) =>
            way.exchangeWayType === 'BUY' ? way.inPaywayCode === paywayCode : way.exchangeWayType === 'SELL' ? way.outPaywayCode === paywayCode : null
        )

        const paymentDetails = paywayCode ? getPaymentData({ walletData, cashbackToken, payway }) : null

        localStorage.setItem('payway', paywayCode)
        setShowError && setShowError(false)
        setShowErrorBtn && setShowErrorBtn(false)
        dispatch(setRocketLoaderAction(true, false))
        dispatch(savePositionAction(false))

        dispatch(paywayAction(paywayCode, false, true))

        hideAnimation('selectModal-payway', hideScreenModal)

        let getWays
        try {
            Log.info(`sign pre getActualWays ${JSON.stringify(walletData.sign)}`)

            getWays = await getActualWays(
                inCurrency,
                outCurrency,
                paywayCode,
                cashbackToken,
                walletData.sign.message,
                walletData.sign.messageHash,
                walletData.sign.signature,
                settings.tester,
                enqueueSnackbar
            )
        } catch (e) {
            console.log(JSON.stringify(e))
            getWays = { dataAll: [], comingSoon: [] }
        }

        exchangeWaysArray = getWays.dataAll && getWays.dataAll.length ? sortWays(getWays.dataAll) : []
        commingSoonArray = getWays.comingSoon && getWays.comingSoon.length ? sortWays(getWays.comingSoon) : []

        const results = exchangeWaysArray.length ? [...exchangeWaysArray, ...commingSoonArray].slice(0, 30) : []

        dispatch(exchangeWaysAction(exchangeWaysArray, commingSoonArray, results, true, false))

        if (typeof paymentDetails !== 'undefined' && paymentDetails && paymentDetails.length > 0) {
            dispatch(paymentDataAction(paymentDetails[paymentDetails.length - 1]))
        } else {
            dispatch(paymentDataAction(null))
        }

        return
    }

    return (
        <BodyWrapper style={{ marginTop: 114 }}>
            {searchValue.searchValuePayway ? (
                searchPayway.map((item, index) => {
                    return (
                        <>
                            <PaywayListItem
                                key={index}
                                item={item}
                                index={index}
                                checked={payway === item}
                                active={true}
                                handler={selectPayway}
                                setShowError={setShowError}
                                setShowErrorBtn={setShowErrorBtn}
                                theme={theme}
                            />
                            {searchPayway.length !== index + 1 && <Line width={'90%'} />}
                        </>
                    )
                })
            ) : (
                <>
                    {arrayPayways.map((item, index) => {
                        return (
                            <>
                                <PaywayListItem
                                    key={index}
                                    item={item}
                                    index={index}
                                    checked={payway === item}
                                    active={true}
                                    handler={selectPayway}
                                    setShowError={setShowError}
                                    setShowErrorBtn={setShowErrorBtn}
                                    theme={theme}
                                />
                                {arrayPayways.length !== index && <Line width='90%' />}
                            </>
                        )
                    })}
                    {notActivePayways.map((item, index) => {
                        return (
                            <>
                                <PaywayListItem
                                    key={arrayPayways.length * 2 + index}
                                    item={item}
                                    index={index}
                                    checked={payway === item}
                                    active={false}
                                    handler={false}
                                    theme={theme}
                                />
                                {notActivePayways.length !== index + 1 && <Line width='90%' />}
                            </>
                        )
                    })}
                </>
            )}
        </BodyWrapper>
    )
}

export default React.memo(PaywayModal)
