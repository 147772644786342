import {
    IN_CURRENCY,
    OUT_CURRENCY,
    PAYWAY,
    AMOUNT,
    VARIANTS,
    IN_CURRENCY_COIN_ACTION,
    OUT_CURRENCY_COIN_ACTION,
    SEARCH_MODAL,
    SEARCH_VALUE_PAYWAY,
    SEARCH_VALUE_IN_SIDE,
    SEARCH_VALUE_OUT_SIDE,
    AMOUNT_SIDE,
    SEARCH_VALUE_COUNTRY,
    ADD_PAYMENT_DETAILS,
    PAYMENT_DATA,
    PROVIDER,
    NEED_PHOTO,
    CREATED_ORDER,
    NOT_ACTIVE_IN_CURRENCY,
    NOT_ACTIVE_OUT_CURRENCY,
    NULL_ORDER,
    SHOW_ARRAY,
    ACTIVE_WAY,
    SEND_ALL,
    ORIGINAL_DATA,
    CAMERA,
    CARDS,
    NEW_OUT_DESTINATION_ACTION,
    VALIDATION_OUT_DESTINATION_ACTION,
    DESTINATION_TAG_ACTION,
    OUT_DESTINATION_ACTION,
    OUT_DESTINATION_SHOW_ACTION,
    OUT_DESTINATION_TIME_ACTION,
    COIN_ALL_BALANCE,
    BALANCE_HODL_MSG,
    SHOW_GUIDE,
    EXCHANGE_WAYS,
    SET_SCREEN,
    PROPS_MODAL,
    PAYWAY_LIST,
    WALLET_DATA,
    EMAIL_ACTION,
    REFRESH_DATA,
    AFTER_KYC_ACTION,
    ORDER_HASH_ACTION,
    SET_LOADER,
    REFRESH_TIME,
    SAVE_POSITION,
    SHOW_ROCKET,
    SET_ARRAY_VARIANTS,
    REPEAT_ORDER,
    RECHECK_SEND_ALL,
    COME_BACK,
    PRE_CHECK_ACTION,
    SET_SELECTED_FEE,
    SET_LIMITS,
    SET_LIMITS_ERROR,
    SET_PROVIDER_ERROR,
    SET_PROVIDER_LOADER,
    SET_NEED_SEARCH_PROVIDERS,
    SET_PROVIDER_EQUIVALENT_TIME
} from '../type'

export function variantsAction(exchangeWaysArray = []) {
    return {
        type: VARIANTS,
        exchangeWaysArray
    }
}

export function inCurrencyCoinAction(activeInCurrency) {
    return {
        type: IN_CURRENCY_COIN_ACTION,
        activeInCurrency
    }
}

export function outCurrencyCoinAction(activeOutCurrency) {
    return {
        type: OUT_CURRENCY_COIN_ACTION,
        activeOutCurrency
    }
}

export function inCurrencyAction(inCurrency, showExchangeWaysArray, selectorLoader, payway = null) {
    return {
        type: IN_CURRENCY,
        inCurrency,
        payway,
        showExchangeWaysArray,
        selectorLoader
    }
}

export function outCurrencyAction(outCurrency, showExchangeWaysArray, selectorLoader, payway = null, outDestination, walletName) {
    return {
        type: OUT_CURRENCY,
        outCurrency,
        payway,
        showExchangeWaysArray,
        selectorLoader,
        outDestination,
        walletName
    }
}

export function paywayAction(payway, showExchangeWaysArray, selectorLoader) {
    return {
        type: PAYWAY,
        payway,
        showExchangeWaysArray,
        selectorLoader
    }
}

export function searchModalAction() {
    return {
        type: SEARCH_MODAL
    }
}

export function searchValueInSideAction(searchValueInSide) {
    return {
        type: SEARCH_VALUE_IN_SIDE,
        searchValueInSide
    }
}

export function searchValueOutSideAction(searchValueOutSide) {
    return {
        type: SEARCH_VALUE_OUT_SIDE,
        searchValueOutSide
    }
}

export function searchValuePaywayAction(searchValuePayway) {
    return {
        type: SEARCH_VALUE_PAYWAY,
        searchValuePayway
    }
}

export function searchValueCountryAction(searchValueCountry) {
    return {
        type: SEARCH_VALUE_COUNTRY,
        searchValueCountry
    }
}

export function amountAction(amount) {
    return {
        type: AMOUNT,
        amount
    }
}

export function amountSideAction(amountSide) {
    return {
        type: AMOUNT_SIDE,
        amountSide
    }
}

export function addPaymentDetailsAction(paymentDetails) {
    return {
        type: ADD_PAYMENT_DETAILS,
        paymentDetails
    }
}

export function paymentDataAction(paymentData) {
    return {
        type: PAYMENT_DATA,
        paymentData
    }
}

export function providerAction(provider, activeExchangeWay) {
    return {
        type: PROVIDER,
        provider,
        activeExchangeWay
    }
}

export function needPhotoAction(needPhoto, takePhoto = false, textError = null) {
    return {
        type: NEED_PHOTO,
        needPhoto,
        textError,
        takePhoto
    }
}

export function createdOrderAction(order) {
    return {
        type: CREATED_ORDER,
        order
    }
}

export function outCurrencyNotActiveAction(outCurrency, inCurrency, selectorLoader, payway, outDestination, walletName) {
    return {
        type: NOT_ACTIVE_OUT_CURRENCY,
        outCurrency,
        inCurrency,
        payway,
        outDestination,
        walletName,
        selectorLoader
    }
}

export function inCurrencyNotActiveAction(inCurrency, outCurrency, selectorLoader, payway, outDestination, walletName) {
    return {
        type: NOT_ACTIVE_IN_CURRENCY,
        inCurrency,
        outCurrency,
        payway,
        outDestination,
        walletName,
        selectorLoader
    }
}

export function nullOrderAction() {
    return {
        type: NULL_ORDER
    }
}

export function notShowExchangeWaysArrayAction(showExchangeWaysArray) {
    return {
        type: SHOW_ARRAY,
        showExchangeWaysArray
    }
}

export function activeExchangeWayAction(activeExchangeWay) {
    return {
        type: ACTIVE_WAY,
        activeExchangeWay
    }
}

export function sendAllAction(useAllFunds) {
    return {
        type: SEND_ALL,
        useAllFunds
    }
}

export function sendOriginalData(originalData, move = false) {
    return {
        type: ORIGINAL_DATA,
        originalData,
        move
    }
}

export function cameraResultAction(cameraResult) {
    return {
        type: CAMERA,
        cameraResult
    }
}

export function addPaymentDetailsArrayAction(cards) {
    return {
        type: CARDS,
        cards
    }
}

export function destinationTagAction(destinationTag) {
    return {
        type: DESTINATION_TAG_ACTION,
        destinationTag
    }
}

export function validationOutDestinationAction(destinationValid, destinationError = false) {
    return {
        type: VALIDATION_OUT_DESTINATION_ACTION,
        destinationValid,
        destinationError
    }
}

export function newOutDestinationAction() {
    return {
        type: NEW_OUT_DESTINATION_ACTION
    }
}

export function outDestinationTimeAction(outDestination) {
    return {
        type: OUT_DESTINATION_TIME_ACTION,
        outDestination
    }
}

export function outDestinationShowAction() {
    return {
        type: OUT_DESTINATION_SHOW_ACTION
    }
}

export function outDestinationAction(outDestination, walletName) {
    return {
        type: OUT_DESTINATION_ACTION,
        outDestination,
        walletName
    }
}

export function coinAllbalanceAction(coinAllbalance) {
    return {
        type: COIN_ALL_BALANCE,
        coinAllbalance
    }
}

export function balanceHodlMsgAction(balanceHodlMsg) {
    return {
        type: BALANCE_HODL_MSG,
        balanceHodlMsg
    }
}

export function showGuideAction(showGuide) {
    return {
        type: SHOW_GUIDE,
        showGuide
    }
}

export function exchangeWaysAction(allExchangeWays, commingSoonArray, exchangeWaysArray, showExchangeWaysArray, selectorLoader) {
    return {
        type: EXCHANGE_WAYS,
        allExchangeWays,
        commingSoonArray,
        exchangeWaysArray,
        showExchangeWaysArray,
        selectorLoader
    }
}

export function setScreenAction(screen) {
    return {
        type: SET_SCREEN,
        screen
    }
}

export function propsModalAction(propsModal) {
    return {
        type: PROPS_MODAL,
        propsModal
    }
}

export function paywayListAction(paywayList) {
    return {
        type: PAYWAY_LIST,
        paywayList
    }
}

export function walletDataAction(walletData) {
    return {
        type: WALLET_DATA,
        walletData
    }
}

export function emailAction(email) {
    return {
        type: EMAIL_ACTION,
        email
    }
}

export function refreshData(refreshData) {
    return {
        type: REFRESH_DATA,
        refreshData
    }
}

export function afterKycAction(afterKYC) {
    return {
        type: AFTER_KYC_ACTION,
        afterKYC
    }
}

export function setToOrderHashAction(toOrderHash) {
    return {
        type: ORDER_HASH_ACTION,
        toOrderHash
    }
}

export function setShowLoader(showLoader) {
    return {
        type: SET_LOADER,
        showLoader
    }
}

export function setRefreshTimeAction(refreshTime) {
    return {
        type: REFRESH_TIME,
        refreshTime
    }
}

export function savePositionAction(savePosition) {
    return {
        type: SAVE_POSITION,
        savePosition
    }
}

export function setRocketLoaderAction(showArray, rocket) {
    return {
        type: SHOW_ROCKET,
        showArray,
        rocket
    }
}

export function setArrayVariantsAction(arrayVariants) {
    return {
        type: SET_ARRAY_VARIANTS,
        arrayVariants
    }
}

export function repeatOrderAction(repeatOrder) {
    return {
        type: REPEAT_ORDER,
        repeatOrder
    }
}

export function recheckSendAllAction(recheckSendAll) {
    return {
        type: RECHECK_SEND_ALL,
        recheckSendAll
    }
}

export function comeBackAction(comeBack) {
    return {
        type: COME_BACK,
        comeBack
    }
}

export function preCheckAction(preCheck) {
    return {
        type: PRE_CHECK_ACTION,
        preCheck
    }
}

export function setSelectedFee(selectedFee) {
    return {
        type: SET_SELECTED_FEE,
        selectedFee
    }
}

export function setLimits(limits) {
    return {
        type: SET_LIMITS,
        limits
    }
}

export function limitErrorAction(limitsError) {
    return {
        type: SET_LIMITS_ERROR,
        limitsError
    }
}

export function providerErrorAction(providerError) {
    return {
        type: SET_PROVIDER_ERROR,
        providerError
    }
}

export function providerLoaderAction(providerLoader) {
    return {
        type: SET_PROVIDER_LOADER,
        providerLoader
    }
}

export function needSearchProvidersAction(needSearchProviders) {
    return {
        type: SET_NEED_SEARCH_PROVIDERS,
        needSearchProviders
    }
}

export function providerEquivalentTimeAction(equivalentTime) {
    return {
        type: SET_PROVIDER_EQUIVALENT_TIME,
        equivalentTime
    }
}
