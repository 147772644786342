import React from 'react'

import CurrencyStatus from './CurrencyStatus'

import utilsService from '../../../../services/utilsService'
import { WrapperItemDiv } from './HistoryItem.css'

const HistoryItem = (props) => {
    const { inCurrency, outCurrency, orderStatus, inAmount, outAmount, orderHandler, typeProvider, theme } = props

    const color = theme.topNav.colorIcon

    const type = orderStatus === 'DONE_PAYOUT' ? true : false

    return (
        <WrapperItemDiv bg={theme.orderHistory.bg}>
            <CurrencyStatus
                inCurrency={inCurrency}
                outCurrency={outCurrency}
                orderStatus={orderStatus}
                inAmount={inAmount}
                outAmount={
                    (orderStatus === 'DONE_PAYOUT' ||
                    orderStatus === 'CANCELED_PAYIN' ||
                    orderStatus === 'CANCELED_PAYOUT' ||
                    typeProvider === 'FIXED'
                        ? ''
                        : '~') +
                    ' ' +
                    utilsService.cutNumber(outAmount, 8)
                }
                orderHandler={orderHandler}
                color={color}
                type={type}
                theme={theme}
            />
        </WrapperItemDiv>
    )
}

export default HistoryItem
