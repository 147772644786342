import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import Button from '../../../components/Button/Button'
import Input from '../../../components/Input/Input'
import { emailAction, propsModalAction, cameraResultAction } from '../../../redux/AppStores/MobileMarket/action'
import validation from '../../../services/ValidationService'
import { strings } from '../../../i18n'
import store from '../../../redux'

import classes from '../styles.module.css'

import { CardModalDiv, ModalWrapper } from '../../ScreenModal/elements/wrapper.css'

import KYC from '../../../img/picVerif.svg'
import { getStatusKYC } from '../../../services/getStatusKYC'
import { showModal } from '../../../redux/AppStores/ModalStore/action'
import SearchLayout from './Layout/Layout'
import getHashStr from '../../../services/getHashStr'
import config from '../../../config/config'
import Log from '../../../services/sentry/Log'
import { hideSearchScreenModal } from '../../../redux/AppStores/SearchModalScreenStore/action'
import { KYCModalDiv } from './wrapper.css'
import useEventListener from '../../newFlow/hooks/useEventListener'

const { dispatch } = store

const onFocus = () => {
    const div = document.getElementById('wrapperKYC')

    if (div) {
        // div.style.height = '120%';
        // div.scrollIntoView({ block: "end", behavior: "smooth" });
    }
}

const onBlur = () => {
    const div = document.getElementById('wrapperKYC')

    if (div) {
        div.style.height = '80%'
        setTimeout(() => {
            div.style.height = '120%'
        }, 4e2)
    }
}

const ModalKYC = (props) => {
    const { show, data, callback, theme } = props

    const state = store.getState().mobileMarket

    const [emailValid, setValid] = useState(false)

    const history = useHistory()

    const urlPath = (url) => {
        history.push({
            pathname: `/mobile-market/${url}`,
            state: { type: 'fade' }
        })
    }

    useEffect(() => {
        if (Number(state.versionNumber)) {
            window?.ReactNativeWebView && window?.ReactNativeWebView?.postMessage(JSON.stringify({ homePage: true }))
        }
    }, [])

    const eventHandler = async (event) => {
        if (!event || typeof event.data === 'undefined' || !event.data || !Object.keys(event.data) || Object.keys(event.data).length === 0) return

        let data
        try {
            Log.info(`V3.firstScreen.eventHandler event.data ${JSON.stringify(event.data)}`)
            data = JSON.parse(event.data)
        } catch (e) {
            console.log(JSON.stringify(e))
            return
        }

        const { cameraRes } = data
        if (typeof cameraRes !== 'undefined') {
            dispatch(cameraResultAction(JSON.parse(cameraRes)))
            await nextStep()
        }
    }

    useEventListener('message', eventHandler)

    const emailHandler = (value) => {
        setValid(false)
        dispatch(emailAction(value?.toString().trim()?.toLowerCase()))
    }

    const nextStep = async () => {
        const newState = store.getState().mobileMarket

        const email = (newState?.email ? newState?.email?.toString()?.toLowerCase() : newState?.email)?.toLowerCase()

        if (!validation('EMAIL', email)) {
            setValid(true)
            return
        } else {
            setValid(false)
        }

        const userId = getHashStr(newState.email?.toLowerCase())

        const data = await getStatusKYC(userId)

        Log.info(`V3.ModalKYC.nextStep getStatusKYC ${JSON.stringify(data)}`)

        if (data && typeof data.status !== 'undefined' && data.status) {
            if (data.status === 'DONE') {
                showModal({
                    type: 'INFO_MODAL',
                    icon: 'WARNING',
                    header: strings('mobileMarket.modal.attention'),
                    description: strings('mobileMarket.kyc.success')
                })
                // urlPath('pre-check')
                hideSearchScreenModal()

                const userId = getHashStr(newState.email?.toLowerCase())

                localStorage.setItem('trusteeEmail', userId)
            } else if (data && data.status !== 'DONE') {
                if (!newState.cameraResult) {
                    window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ checkCamera: true }))
                    return
                } else {
                    urlPath('verification')
                    dispatch(propsModalAction(true))
                }
            } else if (!data) {
                showModal({
                    type: 'INFO_MODAL',
                    icon: 'WARNING',
                    header: strings('mobileMarket.modal.sorry'),
                    description: strings('mobileMarket.modal.serverRespond')
                })
                return
            }
        }
    }

    return (
        <SearchLayout
            id='ModalKYC'
            header={strings('mobileMarket.kyc.title')}
            show={show}
            styles={{ left: state.propsModal ? 0 : '', opacity: 1, transform: state.propsModal ? 'translate3d(0, 0, 0)' : '' }}
            leftType='back'
            leftAction={data.close}>
            <ModalWrapper background={theme.main.bg}>
                <KYCModalDiv textColor={theme.topNav.color}>
                    <div className='wrapperKYC' id='wrapperKYC'>
                        <div className='img__wrapper'>
                            <img src={KYC} alt='KYC' />
                        </div>
                        <div className='description'>{strings('mobileMarket.kyc.description')}</div>
                        <div className='inputWrapper'>
                            <Input
                                value={state?.email?.toLowerCase() || ''}
                                placeholder={strings('mobileMarket.kyc.placeholder')}
                                id='kyc_email'
                                handler={emailHandler}
                                disabled={false}
                                condition={emailValid}
                                style={{ boxShadow: emailValid ? null : '0px 0px 16px rgba(0, 0, 0, 0.1)' }}
                                onBlur={onBlur}
                                theme={theme}
                            />
                        </div>

                        <div className='button'>
                            <Button id='KYCModalBtn' style={{ width: '100%' }} condition={!emailValid} handler={nextStep}>
                                {strings('mobileMarket.kyc.verify')}
                            </Button>
                        </div>
                    </div>
                </KYCModalDiv>
            </ModalWrapper>
        </SearchLayout>
    )
}

export default ModalKYC
