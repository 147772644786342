import React, { useState, useEffect } from 'react'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'

import Wrapper from 'components/ScreenWrapper/Wrapper'
import TopNavigation from 'components/TopNavigation/TopNavigation'
import Payway from 'components/payways/payways'
import paywayName from 'components/payways/paywayName'
import { Content } from 'components/wrapperScreen/wrapper.css'

import { strings } from 'i18n'

import store from 'redux/index'
import { paymentDataAction, addPaymentDetailsArrayAction } from 'redux/AppStores/MobileMarket/action'
import { hideCardScreenModal, showCardScreenModal } from 'redux/AppStores/SearchModalScreenStore/action'
import { showModal } from 'redux/AppStores/ModalStore/action'

import Modal from 'modules/newModal/MainModal'
import ScreenModal from 'modules/ScreenModal/MainModal'
import CardScreenModal from 'modules/searchModal/elements/cardModal/mainModal'

import Log from 'services/sentry/Log'
import getHashStr from 'services/getHashStr'
import { getPaymentData } from 'services/getPaymentData'

import { getThemeModeStyles } from 'theme/useDarkMode'

import PaymentDiv from './payment.css'
import ItemCard from './Card/ItemCard'

const { dispatch } = store

const handlerPaymentData = (data) => {
    const state = store.getState().mobileMarket

    if (JSON.stringify(state.paymentData) === JSON.stringify(data)) {
        window.history.back()
        return
    }

    Log.info(`V3.paymentDetails.handlerCardNumber data ${JSON.stringify(data)}`)

    localStorage.setItem('trusteeHash', getHashStr(data.number))

    dispatch(paymentDataAction(data))
    window.history.back()
}

const addPaymentDetailsHandler = (urlPath, type) => {
    showCardScreenModal(
        {
            type: 'CARD_MODAL',
            data: {
                action: 'ADD',
                typePayment: type
            }
        },
        () => {
            hideCardScreenModal()
        }
    )
    return

    // urlPath(`payment-details/management?action=ADD&type=${type}`)
}

const cardValid = (item, id) => {
    if (!item) return

    const state = store.getState().mobileMarket

    let exchangeWays = state.allExchangeWays
    exchangeWays = state.inCurrency.code ? exchangeWays.filter((way) => way.inCurrency.code === state.inCurrency.code) : exchangeWays
    exchangeWays = state.outCurrency.code ? exchangeWays.filter((way) => way.outCurrency.code === state.outCurrency.code) : exchangeWays

    let wayCountry = exchangeWays.filter((way) => {
        switch (way.exchangeWayType) {
            case 'BUY':
                return way.inPaywayCode
            case 'SELL':
                return way.outPaywayCode
            default:
                return null
        }
    })

    if (typeof wayCountry === 'undefined') return
    //  || !wayCountry) return false

    wayCountry = wayCountry.find((way) => way.exchangeWayId === id)

    if (state.payway === null || !wayCountry) return false

    wayCountry = wayCountry.supportedCountriesData ? wayCountry.supportedCountriesData : null

    let validCountry = false

    if (state.payway === 'CARD') {
        if (state.outCurrency && state.outCurrency.code === 'UAH') {
            const status = item.cardCheckStatus || null

            if (status && typeof status !== 'undefined' && status !== 'SUCCESS') {
                return status === 'undefined' ? true : validCountry
            }
        }

        if (wayCountry === null) {
            validCountry = true
        } else if (wayCountry !== null) {
            if (wayCountry.type === 'EQUALS' && wayCountry.countryCodes.includes(item.countryCode)) {
                validCountry = true
            } else if (wayCountry.type === 'NOT_EQUALS' && !wayCountry.countryCodes.includes(item.countryCode)) {
                validCountry = true
            }
        }
        // Log.info(`V3.firstScreen.cardValid item ${JSON.stringify(item)} -> validCountry ${validCountry}`)
        return validCountry
    } else {
        validCountry = true
        // Log.info(`V3.firstScreen.cardValid item ${JSON.stringify(item)} -> validCountry ${validCountry}`)
        return validCountry
    }
}

const setShowModal = () => {
    showModal({
        type: 'INFO_MODAL',
        icon: 'WARNING',
        header: strings('mobileMarket.modal.sorry'),
        description: strings('mobileMarket.modal.whyInvalid')
    })
}

const PaymentDetails = () => {
    const state = store.getState().mobileMarket
    const modalState = store.getState().modalStore
    const modalScreenState = store.getState().modalScreenStore
    const modalCardState = store.getState().searchModalScreenStore

    const theme = getThemeModeStyles()

    const url = window.location.href
    const urlParamsString = url.slice(url.indexOf('?') + 1)
    const urlParamsObj = queryString.parse(urlParamsString)
    let showStatus = urlParamsObj.showStatus || false
    let showLoader = urlParamsObj.loader || false
    let id = urlParamsObj.id || false
    let camera = state.cameraResult

    const [status, setStatus] = useState(showStatus)
    const [loader, setLoader] = useState(camera)
    const [exchangeId, setId] = useState(id)

    const eventHandler = async (event) => {
        if (typeof event.data !== 'undefined' && event.data && Object.keys(event.data) && Object.keys(event.data).length !== 0) {
            try {
                Log.info(`V3.PaymentDetails.eventHandler res ${JSON.stringify(event.data)}`)
                const { res, notPhoto, serverError, cameraRes } = JSON.parse(event.data)
                if (typeof res !== 'undefined' && res) {
                    const newState = store.getState().mobileMarket
                    const tmpArray = typeof newState.walletData.cards !== 'undefined' ? newState.walletData.cards : []
                    const number = res.numberCard
                    let cardItem = tmpArray.find((item) => item.number === number)

                    Log.info(`V3.PaymentDetails.eventHandler cardItem ${JSON.stringify({ cardItem })}`)

                    cardItem = cardItem.cardVerificationJson ? JSON.parse(cardItem.cardVerificationJson) : null
                    const data = res.res

                    Log.info(`V3.PaymentDetails.eventHandler data ${JSON.stringify(data)}`)

                    if (data.updatedAt > cardItem.updatedAt) {
                        console.log('paymentDetails fuck')
                        tmpArray.find((item) => item.number === number).cardVerificationJson = JSON.stringify(data)
                        setLoader(false)
                        dispatch(addPaymentDetailsArrayAction(tmpArray))
                    }

                    // const result = JSON.parse(res.res)
                    // if (result.verificationStatus === 'SUCCESS' || result.verificationStatus === 'success') {
                    //     analytics().logEvent('V3_BUY_card_status', {
                    //         status: 'SUCCESS'
                    //     })
                    // }
                }
                if (typeof notPhoto !== 'undefined') {
                    setLoader(false)
                }
                // if (serverError) {
                //     setLoader(false)
                //     showModal({
                //         type: 'INFO_MODAL',
                //         icon: 'WARNING',
                //         header: strings('mobileMarket.modal.sorry'),
                //         description: strings('mobileMarket.modal.server')
                //     })
                // }

                if (typeof cameraRes !== 'undefined') {
                    setLoader(true)
                }
            } catch (e) {
                console.log('error?', JSON.stringify({ e }))
                Log.info(`V3.paymentDetails.eventHandler error ${JSON.stringify({ e })}`)
                Log.captureError(`V3.paymentDetails.eventHandler error`)
            }
        }
    }

    const paymentDetails = getPaymentData()

    useEffect(() => {
        if (window.navigator.userAgent.indexOf('Chrome') === -1) {
            window.addEventListener('message', (event) => eventHandler(event))
        } else {
            document.addEventListener('message', (event) => eventHandler(event))
        }

        return () => {
            if (window.navigator.userAgent.indexOf('Chrome') === -1) {
                window.removeEventListener('message')
            } else {
                document.removeEventListener('message')
            }
        }
    }, [])

    const history = useHistory()

    const urlPath = (url) => {
        // history.push({
        //     pathname: '/mobile-market/' + url,
        //     // state: { type: "card", }
        // })
        history.push('/mobile-market/' + url)
    }

    if (!paymentDetails.length) {
        window.history.back()
    }

    return (
        <>
            <Content style={{ zIndex: 1500, overflow: 'none' }} background={theme.main.bg}>
                <TopNavigation
                    text={strings(`mobileMarket.navigation.${state.payway === 'CARD' ? 'selectCard' : 'selectAccount'}`)}
                    leftType='back'
                />
                <Wrapper contentStyle={{ overflow: 'scroll' }} scrollStyle={{ height: '100%' }}>
                    <PaymentDiv paywayBg={theme.paymentScreen.typeBg} paywayColor={theme.topNav.colorIcon} addPaywayColor={theme.topNav.color}>
                        <div className='card__header' style={{ marginTop: 24 }}>
                            <div className='paywayMethod'>
                                <div className='paywayWrapper'>
                                    <Payway selected={true} paywayCode={state.payway} theme={theme} />
                                </div>
                                <div style={{ marginLeft: 16 }}>
                                    <div>{paywayName[state.payway]}</div>
                                    {/* <div className='selected-card' >{state.paymentData.number.toString().slice(0, 4) + ' **** ' + state.paymentData.number.toString().slice(-4)}</div> */}
                                </div>
                            </div>
                            <div className='add-btn'>
                                {paymentDetails.length > 0 && (
                                    <div className='addCardList'>
                                        <div
                                            onClick={() => {
                                                addPaymentDetailsHandler(urlPath, state.payway)
                                            }}>
                                            {'+  ' +
                                                (state.payway === 'CARD'
                                                    ? strings('mobileMarket.addCard')
                                                    : strings('mobileMarket.addPaymentDetails'))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        {paymentDetails && paymentDetails.length !== 0
                            ? paymentDetails
                                  .map((item, index) => {
                                      let validCountry =
                                          item?.number === state?.paymentData?.number ? true : status ? cardValid(item, exchangeId) : true

                                      const newState = store.getState().mobileMarket

                                      return (
                                          <>
                                              <ItemCard
                                                  key={item.id}
                                                  item={item}
                                                  index={index}
                                                  handler={handlerPaymentData}
                                                  needVerification={status}
                                                  setShowModal={setShowModal}
                                                  checked={
                                                      newState.payway === 'CARD'
                                                          ? item.number === newState?.paymentData?.number &&
                                                            item.countryCode === newState?.paymentData?.countryCode &&
                                                            item.expirationDate === newState?.paymentData?.expirationDate
                                                          : item.number === newState?.paymentData?.number
                                                  }
                                                  country={
                                                      newState.payway === 'CARD'
                                                          ? validCountry
                                                          : newState.payway === 'PAYEER' ||
                                                            newState.payway === 'WEBMONEY' ||
                                                            newState.payway === 'QIWI' ||
                                                            newState.payway === 'MOBILE_PHONE'
                                                          ? true
                                                          : status
                                                          ? newState.inCurrency.type === 'FIAT'
                                                              ? item.currency === newState.inCurrency.code
                                                              : item.currency === newState.outCurrency.code
                                                          : true
                                                  }
                                                  type={newState.payway}
                                                  loader={loader}
                                                  theme={theme}
                                                  state={state}
                                              />
                                          </>
                                      )
                                  })
                                  .reverse()
                            : null}
                    </PaymentDiv>
                </Wrapper>
                {modalCardState.cardShow && <CardScreenModal />}
            </Content>
            {modalState.show && <Modal />}
            {modalScreenState.show && <ScreenModal />}
        </>
    )
}

export default PaymentDetails
