import styled from 'styled-components'

const WayItemWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: center; */

    min-height: 50px;

    margin-left: 16px;
    margin-right: 16px;

    padding-top: 16px;

    .wrapper {
        display: flex;
        flex-direction: row;
        align-self: center;
        align-items: center;

        padding-left: 16px;

        width: calc(100% - 64px);
    }

    .data {
        display: flex;
        flex-direction: column;
    }

    .rate {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .rate > div {
        display: flex;
        align-items: center;

        font-family: 'Montserrat Bold';
        font-size: 18px;
        line-height: 18px;

        white-space: nowrap;

        color: ${(p) => p.rateColor};
    }

    .tickersIcon {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 17px;
    }

    .limits {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        padding-top: 13px;
        margin-left: 4px;
    }

    .dataLimit {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        min-height: 24px;
    }
`

export default WayItemWrapper
