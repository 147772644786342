import styled from 'styled-components'

const SettingListItemDiv = styled.div`
    width: 'auto';

    /* background: red; */

    .list-item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        min-height: 50px;
        padding: 5px 0;
    }

    .list-item-wrapper {
        display: flex;
        align-items: center;
    }

    .list-item-icon {
        width: 40px;
        height: 40px;
        background: ${(p) => p.circleBg};
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .list-item-content {
        display: flex;
        flex-direction: column;

        margin-left: 12px;
    }

    .list-item-title {
        height: 50%;

        font-family: 'Montserrat Bold';
        font-style: normal;
        font-size: 16px;
        line-height: 21px;

        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;

        color: ${(p) => p.textColor};

        &.title {
            -webkit-line-clamp: 2;
        }
    }

    .list-item-subTitle {
        height: 50%;

        font-family: 'SF UI Display Medium';
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;

        letter-spacing: 1.75px;

        color: #999999;

        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }

    .list-item-line {
        height: 1px;
        margin-left: 48px;
        background: ${(p) => p.lineColor};
    }

    .list-extra-view {
        max-height: 0;
        overflow: hidden;

        transition: max-height 0.5s ease-in-out;

        &.active {
            max-height: 300px;
            transition: max-height 0.5s ease-in-out;
        }
    }
`

export default SettingListItemDiv
