import styled from 'styled-components'

export const TopNav = styled.div`
    width: 100%;
    min-height: 50px;
    background: ${(props) => props.background || '#F5F5F5'};
    box-shadow: 0px 4px 8px rgba(38, 5, 55, 0.1);
    display: flex;
    flex-direction: column;
    z-index: 301;
    position: fixed;
    top: 0;
    left: 0;
    -webkit-tap-highlight-color: transparent;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;

    .name {
        display: flex;
        flex-grow: 1;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .back {
        display: flex;
        justify-content: flex-end;
        padding: 6px 16px;
        transform: rotate(180deg);
        width: 100%;
        max-width: 80px;
    }

    .toOld {
        display: flex;
        align-items: center;
        padding-left: 20px;
        margin-right: -40px;
        height: 40px;
        padding-top: 10px;
        margin-top: -30px;
    }

    .toOld p {
        font-family: 'Montserrat Bold';
        font-style: normal;
        /* font-weight: bold; */
        font-size: 16px;
        line-height: 16px;

        text-align: center;
        letter-spacing: 2px;
        color: ${(props) => props.color || '##C5C5C'};
        margin-left: 10px;
    }

    .close {
        padding: 6px 16px;
        width: 100%;
        max-width: 80px;
    }

    .filter-wrapper {
        display: flex;
        justify-content: flex-end;
        padding: 6px 16px;
        width: 100%;
        max-width: 80px;
    }

    .filter {
        width: 32px;
        height: 32px;

        display: flex;
        justify-content: center;
        align-items: center;

        border-radius: 50%;
    }

    .text {
        font-family: 'Montserrat Bold';
        font-style: normal;
        /* font-weight: bold; */
        font-size: 16px;
        line-height: 16px;

        text-align: center;
        letter-spacing: 2px;
        text-transform: uppercase;

        color: ${(props) => props.color || '##C5C5C'};

        @media screen and (max-width: 400px) {
            font-size: 14px;
            line-height: 14px;
        }
    }

    .search__wrapper {
        margin: 0 16px 20px 16px;
        box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        width: calc(100% - 32px);
        max-width: calc(768px - 32px);
        display: flex;
        background: ${(props) => props.inputBg || '#F5F5F5'};
        align-self: center;
    }

    .search__wrapper input {
        width: calc(100% - 16px);
        height: 50px;
        border: none;
        outline: none;
        background: ${(props) => props.inputBg || '#F5F5F5'};
        border-radius: 10px;
        font-family: 'Montserrat SemiBold';
        font-style: normal;
        /* font-weight: 600; */
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0.5px;
        color: ${(props) => props.inputColor || '#999'};
    }

    .line {
        border-right: 2px solid ${(props) => props.color || '##C5C5C'};
        height: 32px;
        width: 0px;
        margin-bottom: 2px;
    }

    .logo {
        padding-bottom: 3px;
        height: 32px;

        @media screen and (max-width: 330px) {
            padding-bottom: 6px;
            height: 24px;
        }
    }

    .right-select {
        background: red;
        width: 32px;
        height: 32px;
    }

    .textBtn {
        width: 100%;
        max-width: 80px;

        font-family: 'Montserrat Bold';
        font-style: normal;
        font-size: 14px;
        line-height: 14px;

        text-align: right;
        letter-spacing: 1px;
        text-transform: uppercase;

        color: ${(props) => props.color || '##C5C5C'};
        padding: 6px 16px 6px 0;

        @media screen and (max-width: 400px) {
            font-size: 12px;
            line-height: 12px;
            margin-top: 2px;
            /* letter-spacing: 1.6px; */
        }
    }
`
