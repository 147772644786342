import * as Sentry from '@sentry/react'

class Log {
    info(message) {
        Sentry.addBreadcrumb({
            category: 'info',
            message,
            level: Sentry.Severity.Info
        })
    }

    warning(message) {
        Sentry.addBreadcrumb({
            category: 'warning',
            message,
            level: Sentry.Severity.Warning
        })
    }

    error(message) {
        Sentry.addBreadcrumb({
            category: 'error',
            message,
            level: Sentry.Severity.Error
        })
    }

    debug(message) {
        Sentry.addBreadcrumb({
            category: 'debug',
            message,
            level: Sentry.Severity.Debug
        })
    }

    captureError(message) {
        Sentry.captureMessage(message, 'error')
    }

    captureInfo(message) {
        Sentry.captureMessage(message, 'info')
    }

    setTag(key, message) {
        Sentry.setTag(key, message)
    }
}

export default new Log()
