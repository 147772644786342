import React, { useEffect, useCallback, useState, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import PullRefresh from 'react-pullrefresh'
import { v4 as uuidV4 } from 'uuid'
import queryString from 'query-string'
import CurrencyList from 'currency-list'

import { getThemeModeStyles } from 'theme/useDarkMode'

import {
    addPaymentDetailsAction,
    amountAction,
    amountSideAction,
    cameraResultAction,
    inCurrencyCoinAction,
    outCurrencyCoinAction,
    outDestinationAction,
    paymentDataAction,
    paywayListAction,
    refreshData,
    searchValueInSideAction,
    searchValueOutSideAction,
    searchValuePaywayAction,
    sendAllAction,
    setShowLoader,
    setToOrderHashAction,
    walletDataAction,
    setSelectedFee,
    recheckSendAllAction
} from 'redux/AppStores/MobileMarket/action'
import {
    getWalletData,
    getShowGuide,
    getLoader,
    getUserCurrencies,
    getAmountData,
    getPreRequestExchangeWays,
    getExchangeWaysArray,
    getOutDestination,
    getRefreshValue,
    getUserSettings,
    getPaymentsDetails
} from 'redux/AppStores/MobileMarket/selectors'
import { getShowModal } from 'redux/AppStores/ModalStore/selectors'
import { getShowModalScreen } from 'redux/AppStores/ScreenModalStore/selectors'
import { showScreenModal } from 'redux/AppStores/ScreenModalStore/action'
import { showSearchScreenModal } from 'redux/AppStores/SearchModalScreenStore/action'
import { getHistoryOrderAction, setOrderAction } from 'redux/AppStores/OrderHistoryStore/action'
import store from 'redux/index'

import config from 'config/config'
import { strings } from 'i18n'

import { getPaymentData } from 'services/getDataPayments'
import { maskHiddenFunction } from 'services/isActiveCoin'
import getHashStr from 'services/getHashStr'
import findOutDestination from 'services/findOutDestination'
import utilsService from 'services/utilsService'
import Log from 'services/sentry/Log'
import { serverRequest } from 'services/getActualWays'

import Modal from 'modules/newModal/MainModal'
import ScreenModal from 'modules/ScreenModal/MainModal'
import WrapperSelector from 'modules/mobileMarket/firstScreen/wrapperSelector/wrapperSelector'
import Slider from 'modules/SliderModule/Slider'
import useEventListener from 'modules/newFlow/hooks/useEventListener'

import Loader from 'components/Loader/loader'
import TopNavigation from 'components/TopNavigation/TopNavigation'
import { Content } from 'components/wrapperScreen/wrapper.css'
import Wrapper from 'components/ScreenWrapper/Wrapper'

import InputBlock from './elements/InputBlock/InputBlock'
import PaymentBlock from './elements/PaymentBlock/PaymentBlock'
import BottomBtns from './elements/BottomBtns/BottomBtns'
import { displayInputWidth } from './elements/InputBlock/helper'

import { emailClean, handlerRefresh, setFiatArray } from './helper'

let CACHE_COUNT = 0

const assetsWithFee = ['BNB']

const MainScreen = () => {
    // mobileMarket selectors
    const walletData = useSelector(getWalletData)
    const showGuide = useSelector(getShowGuide)
    const showLoader = useSelector(getLoader)
    const userCurrencies = useSelector(getUserCurrencies)
    const amountData = useSelector(getAmountData)
    const preRequestExchangeWays = useSelector(getPreRequestExchangeWays)
    const exchangeWaysArray = useSelector(getExchangeWaysArray)
    const outDestinationData = useSelector(getOutDestination)
    const refreshValue = useSelector(getRefreshValue)
    const userSettings = useSelector(getUserSettings)
    const paymentData = useSelector(getPaymentsDetails)

    const { inCurrency, outCurrency, payway, cashbackToken, useAllFunds } = userCurrencies

    // modalStore selectors
    const modalShow = useSelector(getShowModal)

    // screenModalStore selectors
    const modalScreenShow = useSelector(getShowModalScreen)

    const [data, setData] = useState(null)
    const [requestId, setRequestId] = useState(uuidV4())
    const [loading, setLoading] = useState(false)
    const [count, setCount] = useState(0)
    const [focus, setFocus] = useState(false)
    const [allFundsData, setAllFundsData] = useState({
        recheckBalance: false,
        data: null
    })

    const isMounted = useRef(false)

    const dispatch = useDispatch()

    const history = useHistory()

    const theme = getThemeModeStyles()

    let nameWallet, useFirebase

    let paymentDetails = useMemo(() => {
        const _data = getPaymentData({
            walletData,
            cashbackToken,
            payway
        })
        return _data
    }, [payway, walletData, cashbackToken])

    useEffect(() => {
        setLoading(false)
    }, [])

    useEffect(async () => {
        if (Number(userSettings.versionNumber)) {
            window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ homePage: true }))
        }

        const url = window.location.href
        const urlParamsString = url.slice(url.indexOf('?') + 1)

        const urlParamsObj = queryString.parse(urlParamsString)
        const toOrderHash = urlParamsObj.orderHash

        // const skipProvidersScreen = typeof urlParamsObj.skipProvidersScreen !== 'undefined' ? urlParamsObj.skipProvidersScreen && state.propsModal ? false : JSON.parse(urlParamsObj.skipProvidersScreen) : false
        // skipProviders = typeof urlParamsObj.skipProvidersScreen !== 'undefined' ? JSON.parse(urlParamsObj.skipProvidersScreen) : false
        nameWallet = typeof urlParamsObj.wallet !== 'undefined' ? urlParamsObj.wallet : false
        useFirebase = typeof urlParamsObj.useFirebase !== 'undefined' ? JSON.parse(urlParamsObj.useFirebase) : true
        const tmpState = store.getState().mobileMarket
        // alert(JSON.stringify(useFirebase))
        // alert(JSON.stringify(state.walletData))
        if (!useFirebase && !tmpState.walletData) {
            window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ getWalletData: true }))
        }

        if (toOrderHash && toOrderHash !== 'false' && !userSettings.toOrderHash) {
            const cashbackToken = urlParamsObj.cashbackToken
            const message = urlParamsObj.message
            const messageHash = urlParamsObj.messageHash
            const signature = urlParamsObj.signature

            dispatch(setShowLoader(true))

            let link = `/order/history-for-bse?cashbackToken=${cashbackToken}&signMessage=${message}&signMessageHash=${messageHash}&signature=${signature}&orderHash=${toOrderHash}`

            try {
                const history = await serverRequest(link, strings('mobileMarket.modal.serverRespond'), 'WALLET_DATA')

                dispatch(getHistoryOrderAction(history.currentPage, history.orders, history.pages, history.serverTime))

                const order = history.orders[0]

                dispatch(
                    setOrderAction({
                        preCheck: order.checkType === 'PRE_CHECK' ? order : null,
                        finalCheck: order.checkType === 'FINAL_CHECK' ? order : null
                    })
                )

                dispatch(setToOrderHashAction(toOrderHash))

                setTimeout(() => {
                    orderHistory()
                    setTimeout(() => {
                        showSearchScreenModal({
                            type: 'ORDER_MODAL'
                        })
                        return
                    }, 5e2)
                }, 1e3)
            } catch (e) {
                console.log(JSON.stringify(e))
                // dispatch(setShowLoader(false))
                // return
            }
        }
    }, [])

    useEffect(() => {
        // if (window.navigator.userAgent.indexOf('Chrome') === -1) {
        //     window.addEventListener('message', eventHandler)
        // } else {
        //     document.addEventListener('message', eventHandler)
        // }

        return function cleanupListener() {
            setCount(0)
            // if (window.navigator.userAgent.indexOf('Chrome') === -1) {
            //     window.removeEventListener('message', eventHandler)
            // } else {
            //     document.removeEventListener('message', eventHandler)
            // }
        }
    }, [])

    useEffect(() => {
        if ((!paymentData && payway) || payway) {
            const _paymentData = paymentSetHandle()
            dispatch(paymentDataAction(_paymentData))
        }

        if (!payway) {
            dispatch(paymentDataAction(null))
            dispatch(addPaymentDetailsAction(null))
        }
    }, [paymentData, payway])

    useEffect(() => {
        if (isMounted.current) {
            let inCurrencyUserData = inCurrency
                ? inCurrency.type === 'CRYPTO'
                    ? walletData?.wallets
                          .find((wallet) => wallet.cashbackToken === cashbackToken)
                          .accounts.find((account) => account.currencyCode === inCurrency.code)
                    : null
                : null

            if ((inCurrencyUserData?.prettyBalanceRaw || inCurrencyUserData?.balance) <= 0) {
                setAllFundsData({
                    recheckBalance: false
                })
                dispatch(setSelectedFee(false))
                return
            }

            if (assetsWithFee.includes(inCurrency?.code)) {
                setAllFundsData({
                    recheckBalance: true
                })
                const obj = findOutDestination(walletData, inCurrency.code, cashbackToken)

                Log.info(`V3.mainScreen.useEffect sendAll check ${inCurrency.code}`)

                setTimeout(() => {
                    window.ReactNativeWebView &&
                        window.ReactNativeWebView.postMessage(
                            JSON.stringify({
                                useAllFunds: {
                                    currencyCode: inCurrency.code,
                                    address: obj.outDestination
                                }
                            })
                        )
                }, 250)
            } else {
                setAllFundsData({
                    recheckBalance: false
                })
                dispatch(setSelectedFee(false))
            }
        } else {
            isMounted.current = true
        }
    }, [inCurrency?.code])

    const paymentSetHandle = () => {
        let paymentNumberHash = localStorage.getItem('trusteeHash') || null

        let _paymentData = paymentDetails.length ? paymentDetails.find((item) => getHashStr(item.number) === paymentNumberHash) : null

        _paymentData = _paymentData || paymentDetails[paymentDetails.length - 1]

        if (!_paymentData && paymentDetails.length) {
            _paymentData = paymentDetails[paymentDetails.length - 1]
            localStorage.setItem('trusteeHash', getHashStr(_paymentData.number))
        }
        dispatch(addPaymentDetailsAction(paymentDetails))
        return _paymentData
    }

    const eventHandler = useCallback(
        async (event) => {
            if (typeof event.data === 'undefined' || !event.data || !Object.keys(event.data) || Object.keys(event.data).length === 0) return

            let data
            try {
                Log.info(`V3.firstScreen.eventHandler event.data ${JSON.stringify(event.data)}`)
                data = JSON.parse(event.data)
            } catch (e) {
                console.log(JSON.stringify(e))
                return
            }

            const { fees, cameraRes, serverError, url, walletData: tmpWalletData } = data

            if (tmpWalletData?.wallets && (!walletData?.wallets || refreshValue)) {
                let _outCurrency = localStorage.getItem('outCurrencyCode')
                    ? JSON.parse(localStorage.getItem('outCurrencyCode'))
                    : { code: 'BTC', type: 'CRYPTO' }

                let newWalletData

                try {
                    const cards = getPaymentData(tmpWalletData, cashbackToken, payway)
                    newWalletData = tmpWalletData
                    if (typeof tmpWalletData.cards === 'undefined') {
                        newWalletData.cards = cards
                    }
                    setTimeout(() => {
                        dispatch(walletDataAction(newWalletData))
                    }, 2e3)
                } catch (e) {
                    console.log('load walletData error', JSON.stringify(e))
                }

                let obj
                try {
                    // alert(outDestinationData.outDestination)
                    if (_outCurrency && _outCurrency.type === 'CRYPTO') {
                        const url = window.location.href
                        const urlParamsString = url.slice(url.indexOf('?') + 1)

                        const urlParamsObj = queryString.parse(urlParamsString)
                        const cashbackToken = urlParamsObj.cashbackToken
                        const outCurrencyFromLink =
                            typeof urlParamsObj.outCurrency !== 'undefined' && urlParamsObj.outCurrency !== 'false' ? urlParamsObj.outCurrency : false

                        let currencyCodeOut = outCurrencyFromLink
                            ? CurrencyList.get(outCurrencyFromLink)
                                ? { code: outCurrencyFromLink, type: 'FIAT', symbol: outCurrencyFromLink }
                                : { code: outCurrencyFromLink, type: 'CRYPTO', symbol: outCurrencyFromLink }
                            : null

                        _outCurrency = currencyCodeOut?.code ? currencyCodeOut : _outCurrency

                        obj = findOutDestination(tmpWalletData, _outCurrency.code, cashbackToken)
                        // alert(JSON.stringify(obj))
                        setTimeout(() => dispatch(outDestinationAction(obj?.outDestination || null, obj?.walletName || null)), 250)
                    }
                } catch (e) {
                    console.log('error with address', JSON.stringify(e))
                }

                // if (skipProviders) {
                //     setTimeout(() => {
                //         searchModalHandler()
                //         setSkipScreen(false)

                //         setTimeout(() => {
                //             dispatch(walletDataAction(newWalletData))
                //         }, 250)
                //     }, 1e3)
                // }
            }

            if (typeof cameraRes !== 'undefined') {
                dispatch(cameraResultAction(cameraRes))
            }

            if (fees) {
                Log.info(`V3.mainScreen.eventHandler allFundsData ${JSON.stringify(allFundsData)}`)
                if (allFundsData.recheckBalance) {
                    setAllFundsData((prev) => ({
                        ...prev,
                        data: fees
                    }))
                    dispatch(setSelectedFee(fees?.transferData || null))
                    recheckSendAllAction(true)
                } else {
                    const amount = utilsService.cutNumber(fees.amount, 8)

                    dispatch(amountAction(amount))
                    dispatch(amountSideAction('IN'))
                    setLoading(false)
                    displayInputWidth(amount || '', '40px Montserrat Medium')
                    return
                }
            }

            if (serverError) {
                dispatch(sendAllAction(false))
                setLoading(false)
            }

            if (url) {
                try {
                    const result = await handlerRefresh(url)
                    if (result) {
                        dispatch(refreshData(false))
                        CACHE_COUNT = 0
                    }
                } catch (e) {
                    console.log(JSON.stringify(e))
                    dispatch(refreshData(false))
                    CACHE_COUNT = 0
                }
            }
        },
        [walletData, amountData, userCurrencies, paymentData, payway, allFundsData]
    )

    const url = (url) => {
        history.push({
            pathname: `/mobile-market/${url}`,
            state: { type: 'page' }
        })
    }

    const orderHistory = useCallback(() => url('history'), [])

    const keyDownListener = useCallback(
        (event) => {
            if (event.keyCode === 13) {
                if (amountData?.amount) {
                    if (
                        ((inCurrency.type === 'FIAT' || outCurrency.type === 'FIAT') && paymentData) ||
                        (inCurrency.type === 'CRYPTO' && outCurrency.type === 'CRYPTO')
                    ) {
                        try {
                            document.getElementById('mainModalBtn').click()
                        } catch (e) {
                            console.log(JSON.stringify(e))
                        }
                    }
                } else {
                    const input = document.getElementById('amountInput')
                    if (input) input.blur()
                }
            }
        },
        [amountData, paymentData, outCurrency, inCurrency]
    )

    useEventListener('keydown', keyDownListener)
    useEventListener('message', eventHandler)
    // , window.navigator.userAgent.indexOf('Chrome') !== -1)

    const isActiveCoin = () => {
        const array = maskHiddenFunction()

        // inCurrencies
        const inCurrencyCode =
            preRequestExchangeWays && preRequestExchangeWays.length
                ? outCurrency
                    ? Array.from(
                          new Set(preRequestExchangeWays.filter((way) => way.outCurrency.code === outCurrency.code).map((way) => way.inCurrency.code))
                      )
                    : Array.from(new Set(preRequestExchangeWays.map((way) => way.inCurrency.code)))
                : []
        const notActiveInCurrencyCode =
            preRequestExchangeWays && preRequestExchangeWays.length
                ? outCurrency
                    ? Array.from(
                          new Set(
                              preRequestExchangeWays.filter((way) => !inCurrencyCode.includes(way.inCurrency.code)).map((way) => way.inCurrency.code)
                          )
                      )
                    : []
                : []

        const result1 = inCurrencyCode.map((way) => {
            let tmp = array.find((item) => item.currencyCode === way) || false
            tmp = typeof tmp.maskHidden !== 'undefined' ? tmp.maskHidden : true

            return {
                ...preRequestExchangeWays.find((item) => item.inCurrency.code === way).inCurrency,
                maskHidden: tmp
            }
        })
        const result2 = notActiveInCurrencyCode.map((way) => {
            let tmp = array.find((item) => item.currencyCode === way) || false
            tmp = typeof tmp.maskHidden !== 'undefined' ? tmp.maskHidden : true

            return {
                ...preRequestExchangeWays.find((item) => item.inCurrency.code === way).inCurrency,
                notActive: true,
                maskHidden: tmp
            }
        })

        let inCurrencyArray = [...result1, ...result2]

        // Log.info(`V3.isActive inCurrency ${JSON.stringify(inCurrency)}`)

        dispatch(inCurrencyCoinAction(inCurrencyArray))

        // outCurrencies
        const outCurrencyCode =
            preRequestExchangeWays && preRequestExchangeWays.length
                ? inCurrency
                    ? Array.from(
                          new Set(preRequestExchangeWays.filter((way) => way.inCurrency.code === inCurrency.code).map((way) => way.outCurrency.code))
                      )
                    : Array.from(new Set(preRequestExchangeWays.map((way) => way.outCurrency.code)))
                : []
        const notActiveOutCurrencyCode =
            preRequestExchangeWays && preRequestExchangeWays.length
                ? inCurrency
                    ? Array.from(
                          new Set(
                              preRequestExchangeWays
                                  .filter((way) => !outCurrencyCode.includes(way.outCurrency.code))
                                  .map((way) => way.outCurrency.code)
                          )
                      )
                    : []
                : []

        const result3 = outCurrencyCode.map((way) => {
            let tmp = array.find((item) => item.currencyCode === way) || false
            tmp = typeof tmp.maskHidden !== 'undefined' ? tmp.maskHidden : true

            return {
                ...preRequestExchangeWays.find((item) => item.outCurrency.code === way).outCurrency,
                maskHidden: tmp
            }
        })
        const result4 = notActiveOutCurrencyCode.map((way) => {
            let tmp = array.find((item) => item.currencyCode === way) || false
            tmp = typeof tmp.maskHidden !== 'undefined' ? tmp.maskHidden : true

            return {
                ...preRequestExchangeWays.find((item) => item.outCurrency.code === way).outCurrency,
                notActive: true,
                maskHidden: tmp
            }
        })
        const outCurrencyArray = [...result3, ...result4]

        // Log.info(`V3.isActive outCurrency ${JSON.stringify(outCurrency)}`)

        dispatch(outCurrencyCoinAction(outCurrencyArray))
    }

    const inCurrencyModal = () => {
        const fiatArray = setFiatArray()
        const wallet = walletData ? walletData.wallets.find((account) => account.cashbackToken === userCurrencies.cashbackToken) : {}

        isActiveCoin()
        dispatch(searchValueInSideAction(null))

        showScreenModal({
            type: 'SELECT_MODAL',
            data: {
                side: 'IN',
                search: true,
                fiatArray,
                wallet
            }
        })
        return
    }

    const outCurrencyModal = () => {
        const fiatArray = setFiatArray()
        const wallet = walletData ? walletData.wallets.find((account) => account.cashbackToken === userCurrencies.cashbackToken) : {}

        isActiveCoin()
        dispatch(searchValueOutSideAction(null))

        showScreenModal({
            type: 'SELECT_MODAL',
            data: {
                side: 'OUT',
                search: true,
                fiatArray,
                wallet
            }
        })
        return
    }

    const paywayModal = useCallback(() => {
        let exchangeWays = preRequestExchangeWays
        exchangeWays = inCurrency && inCurrency.code ? exchangeWays.filter((way) => way.inCurrency.code === inCurrency.code) : exchangeWays
        exchangeWays = outCurrency && outCurrency.code ? exchangeWays.filter((way) => way.outCurrency.code === outCurrency.code) : exchangeWays

        let payway = Array.from(
            new Set(
                exchangeWays && exchangeWays.length
                    ? exchangeWays.map((way) => {
                          switch (way.exchangeWayType) {
                              case 'BUY':
                                  return way.inPaywayCode
                              case 'SELL':
                                  return way.outPaywayCode
                              default:
                                  return null
                          }
                      })
                    : []
            )
        )

        dispatch(paywayListAction(payway))

        dispatch(searchValuePaywayAction(null))
        showScreenModal({
            type: 'SELECT_MODAL',
            data: {
                side: 'PAYWAY',
                search: true
            }
        })
        return
    }, [inCurrency, outCurrency, payway])

    const refreshLoader = useCallback(() => {
        return new Promise((resolve, reject) => {
            if (CACHE_COUNT === 0) {
                let loader = document.getElementsByClassName('sc-bdVaJa')[0]

                if (loader) {
                    loader.style.top = `calc(${loader.style.top} + 30px)`
                    loader.style.transition = 'all 0.4s'
                    setTimeout(() => {
                        loader.style.transition = ''
                    }, 1000)
                }

                window.ReactNativeWebView &&
                    window.ReactNativeWebView.postMessage(JSON.stringify(useFirebase ? { refreshControl: true } : { getWalletData: true }))
                dispatch(refreshData(true))
                CACHE_COUNT = 1
            }

            setTimeout(function check() {
                if (!refreshValue) {
                    dispatch(refreshData(false))
                    CACHE_COUNT = 0
                    resolve()
                    return
                } else {
                    setTimeout(check, 5e2)
                }
            }, 5e2)
        })
    }, [refreshValue])

    return (
        <>
            {showGuide && <Slider theme={theme} />}
            {showLoader && <Loader />}
            {walletData && walletData.wallets ? (
                <>
                    <TopNavigation
                        text={strings('mobileMarket.navigation.market')}
                        rightType='orderHistory'
                        rightAction={orderHistory}
                        leftType={config.showError ? 'emailClean' : null}
                        leftAction={config.showError ? emailClean : null}
                    />
                    <Content background={theme.main.bg}>
                        <PullRefresh onRefresh={refreshLoader} color='#999' bgColor={theme.topNav.background} zIndex={500}>
                            <Wrapper id='mainScreen'>
                                <WrapperSelector
                                    inCurrencyModal={inCurrencyModal}
                                    outCurrencyModal={outCurrencyModal}
                                    paywayModal={paywayModal}
                                    theme={theme}
                                    walletData={walletData}
                                    userCurrencies={userCurrencies}
                                />
                                <div style={{ height: 50 }} />
                                <InputBlock
                                    theme={theme}
                                    walletData={walletData}
                                    userCurrencies={userCurrencies}
                                    amountData={amountData}
                                    exchangeWaysArray={exchangeWaysArray}
                                    loading={loading}
                                    setLoading={setLoading}
                                    focus={focus}
                                    setFocus={setFocus}
                                    allFundsData={allFundsData}
                                />
                                <PaymentBlock
                                    theme={theme}
                                    userCurrencies={userCurrencies}
                                    paymentDetails={paymentDetails}
                                    paymentData={paymentData}
                                />
                                <BottomBtns
                                    theme={theme}
                                    userCurrencies={userCurrencies}
                                    amountData={amountData}
                                    requestId={requestId}
                                    count={count}
                                    setCount={setCount}
                                    focus={focus}
                                />
                            </Wrapper>
                        </PullRefresh>
                    </Content>
                </>
            ) : (
                <Loader main />
            )}
            {modalShow && <Modal />}
            {modalScreenShow && <ScreenModal />}
        </>
    )
}

export default MainScreen
