import React from 'react'

import paywayName from '../payways/paywayName'
import Payway from '../payways/payways'
import PaywayDiv from './Payway.css'

const PaywayListItem = (props) => {
    const { item, index, side, checked, active, handler, country, city, setCardCountryCode, setShowError, setShowErrorBtn, theme, data } = props

    if (!item) {
        return null
    }

    const click = (e) => {
        if (side === 'REGION_CITY') {
            data?.setCity(e.target.value)
            data?.setRegion(JSON.parse(e.target.value).region)
            handler(side)
        } else if (side === 'REGION') {
            data?.setRegion(e.target.value)
            data?.setCity(null)
            handler(side)
        } else if (side === 'COUNTRY') {
            setCardCountryCode(e.target.value)
            handler(side)
        } else {
            handler(e.target.value, setShowError, setShowErrorBtn)
        }
    }

    return (
        <PaywayDiv style={{ minHeight: country || city ? '54px' : '' }} colorText={theme.topNav.colorIcon}>
            <label
                htmlFor={side === 'COUNTRY' ? item.iso : side === 'REGION' ? item.region : side === 'REGION_CITY' ? JSON.stringify(item) : item}
                className='label__item'
                style={{ marginBottom: country || city ? '10px' : '' }}>
                <div className='currency__name' style={{ color: checked ? theme.main.checked : !active ? '#999999' : '' }}>
                    {country ? item.country : city ? item.cityRegion : paywayName[item]}
                </div>
                <div className='icon__wrapper'>
                    {country || city ? (
                        item.countryCode
                    ) : (
                        <Payway paywayCode={item} fail={!active ? '#999999' : ''} checked={checked ? theme.main.checked : false} />
                    )}
                </div>
            </label>
            <input
                type='radio'
                value={side === 'COUNTRY' ? item.iso : side === 'REGION' ? item.region : side === 'REGION_CITY' ? JSON.stringify(item) : item}
                key={index}
                id={side === 'COUNTRY' ? item.iso : side === 'REGION' ? item.region : side === 'REGION_CITY' ? JSON.stringify(item) : item}
                checked={checked}
                readOnly
                onClick={(e) => (handler ? click(e) : null)}
            />
        </PaywayDiv>
    )
}

export default PaywayListItem
