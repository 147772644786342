import React from 'react'

import Icon from 'components/Icon/Icon'
import { strings } from 'i18n'
import { hideModal } from 'redux/AppStores/ModalStore/action'
import hideAnimation from 'services/hideAnimation'

import Layout from './Layout/Layout'
import ModalBody from './wrapper.css'

const YesNoModal = (props) => {
    const { show, data, callback, theme } = props

    return (
        <>
            {show && (
                <Layout id='YesNoModal' close={data.close} bg={theme.modal.bg}>
                    <ModalBody headerColor={theme.modal.colorHeader} description={theme.modal.colorDescription}>
                        <div className='modal__img__wrapper'>
                            <Icon icon={data.icon === 'WARNING' ? 'INFO' : 'WARNING'} size={64} color={theme.modal.warningColor} />
                        </div>
                        {data.header && <div className='modal__header'>{data.header}</div>}
                        <div className='modal__description'>{data.description}</div>
                        <div className='modal__button' style={{ marginBottom: '20px' }}>
                            <div
                                className='btn'
                                style={{
                                    background: theme.modal.warningColor,
                                    boxShadow: `0px 6px 10px ${theme.modal.warningShadow}`
                                }}
                                onClick={(e) => {
                                    callback(e)
                                    hideAnimation('YesNoModal', hideModal)
                                }}>
                                {data.firstBtn || strings('mobileMarket.modal.yes')}
                            </div>
                            <div
                                className='btn__inverse'
                                style={{
                                    color: theme.modal.warningColor
                                }}
                                onClick={(e) => {
                                    if (data.noCallback) {
                                        data.noCallback(e)
                                    } else {
                                        hideAnimation('YesNoModal', hideModal)
                                    }
                                }}>
                                {data.secondBtn || strings('mobileMarket.modal.no')}
                            </div>
                        </div>
                    </ModalBody>
                </Layout>
            )}
        </>
    )
}

export default YesNoModal
