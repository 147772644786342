import React from 'react'
import classes from './wrapper.module.css'

const Wrapper = (props) => {
    const { contentStyle, scrollStyle, id } = props

    return (
        <div id={id} className={classes.content} style={{ ...contentStyle }}>
            <div className={classes.content__scroll} style={{ ...scrollStyle }}>
                {props.children}
            </div>
        </div>
    )
}

export default Wrapper
