import styled from 'styled-components'

const SwitchDiv = styled.div`
    .reactSwitchCheckbox {
        height: 0;
        width: 0;
        visibility: hidden;
        -webkit-tap-highlight-color: transparent;
    }

    .reactSwitchLabel {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        width: 34px;
        height: 20px;
        background: ${(p) => p.switchLabelBg};
        border-radius: 100px;
        position: relative;
        -webkit-tap-highlight-color: transparent;
    }

    .reactSwitchLabel .reactSwitchButton {
        content: '';
        position: relative;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        transition: ease;
        transition: 0.2s;
        background: ${(p) => p.switchLabelBtn};
        box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
    }

    .reactSwitchCheckbox:checked + .reactSwitchLabel .reactSwitchButton {
        transform: translateX(70%);
    }
`

export default SwitchDiv
