import styled from 'styled-components'

export const LoaderWrapper = styled.div`
    width: 100%;
    height: 100vh;
    background: ${(p) => p.background};

    position: fixed;
    top: 0;
    left: 0;
    z-index: 305;

    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: center;

        background: ${(p) => p.background};

        height: 100%;

        position: relative;
    }

    .text-load {
        position: absolute;

        top: 52%;
        width: 100%;

        color: ${(p) => p.textColor};
        padding: 10px 20px;
        border-radius: 10px;

        font-family: 'SF UI Display Regular';
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.5px;
        text-align: center;

        white-space: pre-wrap;
    }
`
