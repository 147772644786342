import React from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import { createMuiTheme } from '@material-ui/core'

import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import Moment from '@date-io/moment'

import { getThemeMode } from '../../../../theme/useDarkMode'
import PickDateDiv from './pickDate.css'
import { strings } from '../../../../i18n'

const PickDate = (props) => {
    const { theme, value, onChangeHandler, title } = props

    const themeMode = getThemeMode()

    const materialTheme = createMuiTheme({
        palette: {
            type: themeMode === 'light' ? 'light' : 'dark',
            text: {
                primary: theme.topNav.color
            }
        },
        overrides: {
            MuiPickersDay: {
                day: {
                    color: theme.topNav.colorIcon
                },
                daySelected: {
                    backgroundColor: theme.topNav.color
                },
                current: {
                    color: theme.main.checked
                }
            }
        }
    })

    return (
        <ThemeProvider theme={materialTheme}>
            <PickDateDiv textColor={theme.topNav.color}>
                <div className='pick-date-wrap'>
                    {title}
                    <MuiPickersUtilsProvider utils={Moment} locale={localStorage.getItem('locale')}>
                        <DatePicker
                            value={value}
                            onChange={(date) => {
                                onChangeHandler(date)
                            }}
                            disableToolbar
                            variant='inline'
                            inputVariant='filled'
                            format='MMM D, YYYY'
                            emptyLabel={strings('mobileCheck.filter.pickDate')}
                            InputProps={{
                                disableUnderline: true,
                                style: {
                                    height: 32,
                                    paddingBottom: 16,
                                    background: 'transparent',
                                    textAlign: 'center',
                                    fontFamily: 'Montserrat SemiBold'
                                }
                            }}
                            style={{ border: `2px solid ${theme.topNav.color}`, borderRadius: 14, maringBottom: 5, width: '50%', maxWidth: 150 }}
                        />
                    </MuiPickersUtilsProvider>
                </div>
            </PickDateDiv>
        </ThemeProvider>
    )
}

export default PickDate
