import React from 'react'
import countryArray from '../../../../components/json/country-codes.json'
import { strings } from '../../../../i18n'
import Icon from '../../../../components/Icon/Icon'
import loader from '../../../../img/icon/loaderUniverse.svg'

import { showScreenModal, hideScreenModal } from '../../../../redux/AppStores/ScreenModalStore/action'

const VerifyStatus = (props) => {
    const { status, checked, item, country, theme } = props

    const verifyStatus = status ? status.toString().toUpperCase() : ''

    switch (verifyStatus) {
        case 'PENDING':
            return (
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '90%' }}>
                    <div className='verify'>{strings('mobileMarket.card.processPhoto')}</div>
                    <div className='photoVerify'>
                        <img src={loader} height={32} alt='loader' />
                    </div>
                </div>
            )
        case 'WAIT_TO_PHOTO':
        case 'WAIT_FOR_PHOTO':
        case 'CANCELED':
            return (
                <>
                    <div
                        style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '90%' }}
                        onClick={(e) => {
                            e.preventDefault()
                            showScreenModal(
                                {
                                    type: 'CARD_MODAL',
                                    data: item
                                },
                                () => {
                                    // const state = store.getState().mobileMarket
                                    // window.ReactNativeWebView.postMessage(JSON.stringify({
                                    //     takePhoto: {
                                    //         number: state.paymentData.number,
                                    //         countryCode: state.paymentData.countryCode
                                    //     }
                                    // }))
                                    // const state = store.getState().mobileMarket
                                    // urlPath(`payment-details?showStatus=true&loader=true&camera=${state.cameraResult}`)
                                    hideScreenModal()
                                }
                            )
                        }}>
                        <div className='verify' style={{ color: theme.main.checked }}>
                            {strings('mobileMarket.card.takePhoto')}
                        </div>
                        <div className='photoVerify'>
                            <Icon icon='CARD_VERIFY' size={26} color={theme.main.checked} />
                        </div>
                    </div>
                </>
            )
        case 'SUCCESS':
            return (
                <div className='statusWrapper'>
                    <div style={{ height: '100%', display: 'flex', justifyContent: 'space-around', flexDirection: 'column' }}>
                        <div className='card__country' style={{ color: checked ? theme.main.checked : '' }}>
                            {countryArray.find((el) => el.iso === item.countryCode).country}
                        </div>
                        {country && (
                            <div className='card__validTime' style={{ color: checked ? theme.main.checked : country ? '#999999' : '' }}>
                                {item.expirationDate}
                            </div>
                        )}
                    </div>
                </div>
            )
        case 'SHOW_MORE':
            return (
                <>
                    <div className='verify' style={{ color: theme.main.checked, height: '90%' }}>
                        {strings('mobileMarket.card.showMore')}
                    </div>
                </>
            )
        default:
            return (
                <>
                    <div
                        style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '90%' }}
                        onClick={(e) => {
                            e.preventDefault()
                            showScreenModal(
                                {
                                    type: 'CARD_MODAL',
                                    data: item
                                },
                                () => {
                                    // const state = store.getState().mobileMarket
                                    // window.ReactNativeWebView.postMessage(JSON.stringify({
                                    //     takePhoto: {
                                    //         number: state.paymentData.number,
                                    //         countryCode: state.paymentData.countryCode
                                    //     }
                                    // }))
                                    // const state = store.getState().mobileMarket
                                    // urlPath(`payment-details?showStatus=true&loader=true&camera=${state.cameraResult}`)
                                    hideScreenModal()
                                }
                            )
                        }}>
                        <div className='verify' style={{ color: theme.main.checked }}>
                            {strings('mobileMarket.card.takePhoto')}
                        </div>
                        <div className='photoVerify'>
                            <Icon icon='CARD_VERIFY' size={26} color={theme.main.checked} />
                        </div>
                    </div>
                </>
            )
    }
}

export default VerifyStatus
