import styled from 'styled-components'

const ShadowBtn = styled.div`
    height: 80px;
    padding-top: 15px;
    width: 100%;
    left: 0;
    background: ${(p) => p.bg};
    position: fixed;
    bottom: 0;
    z-index: 300;
`

export default ShadowBtn
