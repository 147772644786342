import React from 'react'
import PropTypes from 'prop-types'

function Dot(props) {
    return (
        <span
            style={{
                display: 'inline-block',
                height: props.selected ? '10px' : '8px',
                width: props.selected ? '10px' : '8px',
                borderRadius: '50%',
                backgroundColor: props.selected ? '#5C5C5C' : '#C4C4C4',
                margin: props.selected ? '6px 5px' : '7px 5px',
                opacity: '1',
                transitionDuration: '300ms'
            }}
        />
    )
}

export default function IndicatorDots(props) {
    const wrapperStyle = {
        position: 'absolute',
        width: '100%',
        zIndex: '100',
        bottom: '65px',
        textAlign: 'center'
    }

    if (props.total < 2) {
        // Hide dots when there is only one dot.
        return <div style={wrapperStyle} />
    } else {
        if (props.index !== props.total - 1) {
            return (
                <div style={wrapperStyle}>
                    {Array.apply(null, Array(props.total)).map((x, i) => {
                        return <Dot key={i} selected={props.index === i} />
                    })}
                </div>
            )
        } else {
            return null
        }
    }
}

IndicatorDots.propTypes = {
    index: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired
}
