import React from 'react'
import CheckListItemDiv from './checkList.css'

import Icon from '../../../../components/Icon/Icon'

const CheckListItem = (props) => {
    const { checked, onClick, title, id, theme } = props

    return (
        <CheckListItemDiv checkedColor={theme.main.checked} colorBtn={theme.topNav.color} textColor={theme.topNav.colorIcon} checked={checked}>
            <div className='check-list-wrap'>
                <label htmlFor={id}>
                    <div>{title}</div>
                    <div className='check-icon'>
                        <Icon icon='CHECK' color={checked ? theme.topNav.background : theme.orderHistory.dashColor} size={28} />
                    </div>
                </label>

                <input type='checkbox' id={id} name={id} checked={checked} onChange={onClick} />
            </div>
        </CheckListItemDiv>
    )
}

export default CheckListItem
