import React from 'react'
import countryArray from '../../../../components/json/country-codes.json'
import { strings } from '../../../../i18n'
import Icon from '../../../../components/Icon/Icon'
import VerifyStatus from './verifyStatus'

import Log from '../../../../services/sentry/Log'
import CardDiv from './card.css'
import { hideCardScreenModal, showCardScreenModal } from '../../../../redux/AppStores/SearchModalScreenStore/action'
import Dots from '../newCard/Dots'

const handlerEditPaymentData = (e, item, index, action, type) => {
    e.preventDefault()

    showCardScreenModal(
        {
            type: 'CARD_MODAL',
            data: {
                item: item,
                index: index,
                action: action,
                typePayment: type
            }
        },
        () => {
            hideCardScreenModal()
        }
    )
    return
}

const ItemCard = (props) => {
    const { item, checked, handler, country, needVerification, setShowModal, type, selector, loader, theme, state } = props

    // Log.info(`V3_ItemCard props ${JSON.stringify({ props })}`)

    let verify
    let verifyStatus

    if (type === 'CARD') {
        verify = item.cardVerificationJson || null
        Log.info(`V3_ItemCard verify ${JSON.stringify(verify)}`)

        try {
            verify = JSON.parse(verify)
        } catch (e) {
            verify = null
        }

        if (typeof verify !== 'undefined' && verify) {
            if (verify.data) {
                if (typeof verify.data.errorMsg !== 'undefined') {
                    verifyStatus = 'CANCELED'
                } else if (typeof verify.data.verificationStatus !== 'undefined') {
                    verifyStatus = verify.data.verificationStatus
                }
            } else {
                verifyStatus = typeof verify.verificationStatus !== 'undefined' ? verify.verificationStatus : 'CANCELED'
            }
            if ((typeof verifyStatus === 'undefined' || verifyStatus === 'undefined' || typeof verifyStatus === 'undefined') && verify.errorMsg) {
                verifyStatus = 'CANCELED'
            }
        } else {
            if (needVerification) {
                verifyStatus = 'CANCELED'
            } else {
                if (
                    verify === null ||
                    verify === 'null' ||
                    verify === 'undefined' ||
                    typeof verify === 'undefined' ||
                    !verify ||
                    verify === 'false'
                ) {
                    verifyStatus = 'CANCELED'
                } else {
                    verifyStatus = 'CANCELED'
                }
            }
        }

        if (typeof verifyStatus === 'undefined' && !verifyStatus) {
            verifyStatus = 'CANCELED'
        }

        Log.info(`V3_ItemCard verifyStatus ${item.number} ${JSON.stringify(verifyStatus)}`)
    }

    const selectPayment = (e) => {
        if (e.target.className === 'dot') return
        if (needVerification) {
            if (!country) {
            } else if (verifyStatus.toUpperCase() === 'SUCCESS') {
                handler(item)
            } else {
            }
        } else if (!country) {
        } else {
            handler(item)
        }
        //  ?  ? null : verifyStatus.toUpperCase() === 'SUCCESS' ? handler(item) : null : !country ? null : handler(item)
    }

    const handlerEditPaymentData = (e, item, index, action, typePayment) => {
        e.preventDefault()

        showCardScreenModal(
            {
                type: 'CARD_MODAL',
                data: {
                    item,
                    index,
                    action,
                    typePayment
                }
            },
            () => {
                hideCardScreenModal()
            }
        )
        return
    }

    const tmpArray = state.walletData.cards
    const tmpItem = tmpArray.find((el) => el.number === item.number)

    return (
        <CardDiv
            checked={checked}
            country={country}
            onClick={selectPayment}
            checkedColor={theme.main.checked}
            normColor={theme.topNav.color}
            notActiveColor={theme.paymentScreen.notActive}
            bg={theme.main.bg}
            bgChecked={theme.paymentScreen.checkedBg}
            text1={theme.topNav.color}
            bgNew={theme.orderHistory.bg}>
            {/* <div className={checked ? classes.card__itemSelect : !country ? classes.card__itemNotActive : classes.card__item} > */}
            {type === 'CARD' ? (
                <>
                    <div className='main__data'>
                        {item.cardName && item.cardName !== null && item.cardName !== 'null' && (
                            <div className='card__name' style={{ color: checked ? theme.main.checked : !country ? '#999999' : '' }}>
                                {item.cardName}
                            </div>
                        )}
                        <div className='card__number' style={{ color: checked ? theme.main.checked : !country ? '#999999' : '' }}>
                            {item.number.toString().slice(0, 4) + ' **** ' + item.number.toString().slice(-4)}
                        </div>
                    </div>
                    <div className='card__line' style={{ borderRightColor: checked ? theme.main.checked : !country ? '#C4C4C4' : '' }} />
                    <div className='service__data'>
                        <div className='statusWrapper'>
                            {needVerification ? (
                                !country ? (
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '90%' }} onClick={setShowModal}>
                                        <div className='verify'>{strings('mobileMarket.card.notAvailable')}</div>
                                        <div className='photoVerify'>
                                            <Icon icon='INFO' size={26} color={theme.main.checked} />
                                        </div>
                                    </div>
                                ) : (
                                    <VerifyStatus
                                        item={item}
                                        status={verifyStatus.toUpperCase()}
                                        // (loader === true && item.number === state.paymentData.number) ? 'PENDING' :
                                        checked={checked}
                                        country={country}
                                        theme={theme}
                                    />
                                )
                            ) : !country ? (
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '90%' }} onClick={setShowModal}>
                                    <div className='verify'>{strings('mobileMarket.card.notAvailable')}</div>
                                    <div className='photoVerify'>
                                        <Icon icon='INFO' size={26} color={theme.main.checked} />
                                    </div>
                                </div>
                            ) : selector ? (
                                <VerifyStatus item={item} status='SHOW_MORE' checked={checked} country={country} theme={theme} />
                            ) : (
                                <div style={{ height: '100%', display: 'flex', justifyContent: 'space-around', flexDirection: 'column' }}>
                                    <div className='card__country' style={{ color: checked ? theme.main.checked : '' }}>
                                        {countryArray.find((el) => el.iso === item.countryCode).country}
                                    </div>
                                    <div
                                        className='card__validTime'
                                        style={{ color: checked ? theme.main.checked : !country ? '#999999' : '', marginTop: 6 }}>
                                        {item.expirationDate}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className='settings'>
                            <Dots
                                theme={theme}
                                checked={checked}
                                handler={(e) => handlerEditPaymentData(e, item, tmpArray.indexOf(tmpItem), 'UPDATE', state.payway)}
                            />
                            {verifyStatus.toUpperCase() === 'SUCCESS' && <Icon icon='PHOTO_CHECK' color={theme.topNav.colorIcon} size={24} />}
                        </div>
                    </div>
                    {selector && (
                        <div className='icon__wrapper'>
                            <Icon icon='ARROW_CONFIRM' color={theme.main.checked} size={20} />
                        </div>
                    )}
                </>
            ) : type === 'CASH' ? (
                <>
                    <div className='main__data' style={{ width: 'calc(50% - 24px)' }}>
                        {item.cardName && item.cardName !== null && item.cardName !== 'null' && (
                            <div className='card__name' style={{ color: checked ? theme.main.checked : !country ? '#999999' : '' }}>
                                {item.cardName}
                            </div>
                        )}
                        <div className='card__number' style={{ color: checked ? theme.main.checked : !country ? '#999999' : '' }}>
                            {item.number.toString().slice(0, 4) + ' **** ' + item.number.toString().slice(-4)}
                        </div>
                    </div>
                    <div className='card__line' style={{ borderRightColor: checked ? theme.main.checked : !country ? '#C4C4C4' : '' }} />
                    <div className='service__data'>
                        <div className='statusWrapper'>
                            <div style={{ height: '100%', display: 'flex', justifyContent: 'space-around', flexDirection: 'column' }}>
                                <div className='card__validTime' style={{ color: checked ? theme.main.checked : '' }}>
                                    {strings(`mobileMarket.cash.${JSON.parse(item.cardDetailsJson).region.toLowerCase()}`)}
                                </div>
                                <div
                                    className='card__validTime'
                                    style={{ color: checked ? theme.main.checked : !country ? '#999999' : '', marginTop: 6, letterSpacing: 1 }}>
                                    {strings(`mobileMarket.cash.${JSON.parse(JSON.parse(item.cardDetailsJson).city).country.toLowerCase()}`)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='settings' style={{ paddingRight: 16, paddingTop: 16 }}>
                        <Dots
                            theme={theme}
                            checked={checked}
                            handler={(e) => handlerEditPaymentData(e, item, tmpArray.indexOf(tmpItem), 'UPDATE', state.payway)}
                        />
                    </div>
                </>
            ) : (
                <>
                    <div className='details__wrapper'>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                            <div className='mainData_details' style={{ paddingLeft: 16 }}>
                                {item.cardName && item.cardName !== null && item.cardName !== 'null' && (
                                    <div className='card__name' style={{ color: checked ? theme.main.checked : country ? '#999999' : '' }}>
                                        {item.cardName}
                                    </div>
                                )}
                                <div className='card__number' style={{ color: checked ? theme.main.checked : country ? '#999999' : '' }}>
                                    {item.number}
                                </div>
                            </div>
                            <div className='settings' style={{ paddingRight: 16, paddingTop: 16 }}>
                                <Dots
                                    theme={theme}
                                    checked={checked}
                                    handler={(e) => handlerEditPaymentData(e, item, tmpArray.indexOf(tmpItem), 'UPDATE', state.payway)}
                                />
                            </div>
                        </div>
                        {selector && (
                            <div className='icon__wrapper'>
                                <Icon icon='ARROW_CONFIRM' color={theme.main.checked} size={20} />
                            </div>
                        )}
                    </div>
                </>
            )}
        </CardDiv>
    )
}

export default ItemCard
