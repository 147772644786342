export default {
    CARD: {
        light: ['#404040', '#404040', '#404040'],
        dark: ['#DADADA', '#DADADA', '#DADADA']
    },
    MOBILE_PHONE: {
        light: ['#F2F2F2', '#BCC9F4', '#F9CD63', '#856400', '#404040'],
        dark: ['#F2F2F2', '#BCC9F4', '#F9CD63', '#856400', '#404040']
    },
    QIWI: {
        light: ['#FF8C00'],
        dark: ['#DF8920']
    },
    ADVCASH: {
        light: ['#141A23'],
        dark: ['#DADADA']
    },
    PAYEER: {
        light: ['#04A3F0'],
        dark: ['#42A4D3']
    },
    PERFECT_MONEY: {
        light: ['#CC1B1B'],
        dark: ['#CB3232']
    },
    YANDEX_MONEY: {
        light: ['#8B3EFC'],
        dark: ['#9563DD']
    },
    WEBMONEY: {
        light: ['#036CB5'],
        dark: ['#2787C9']
    },
    CAPITALIST: {
        light: ['#81BC1E'],
        dark: ['#82AF37']
    },
    BANK_TRANSFER: {
        light: ['#404040'],
        dark: ['#DADADA']
    },
    CASH: {
        light: ['#404040', '#404040', '#404040'],
        dark: ['#DADADA', '#DADADA', '#DADADA']
    }
}
