import styled from 'styled-components'

const HistoryDiv = styled.div`
    position: fixed;
    width: 100%;

    .scroll {
        height: calc(100vh - 50px);

        padding-top: 24px;
        overflow: auto;
        -webkit-appearance: none;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .scroll::-webkit-scrollbar {
        display: none;
    }

    .title {
        font-family: 'Montserrat Bold';
        font-style: normal;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: ${(p) => p.color};
        margin-left: 32px;
    }

    .loader {
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }
`

export default HistoryDiv
