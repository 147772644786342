const nameProvider = {
    'CHANGELLY': 'Changelly',
    'SWAPY': 'Swapy',
    'CHANGE_NOW': 'ChangeNOW',
    '365CASH': '365 CASH',
    'KUNA': 'Kuna',
    'ANYCASH': 'Any.Cash',
    'MERCURYO': 'Mercuryo',
    'MINE_EXCHANGE': 'Mine.exchange',
    'MOON_PAY': 'MoonPay',
    'KASSA': 'KASSA',
    'JUST_SWAP': 'JUST SWAP',
    'ALICE_BOB': 'ALICE BOB',
    'ALICE_BOB_UNIVERSAL': 'ALICE BOB',
    'COIN_CAT': 'CoinCat',
    'EPIC_EXCHANGE': 'Epic.Exchange',
    'LETS_EXCHANGE': 'LetsExchange',
    'THE_1INCH': '1INCH',
    'PURE_FI': 'PureFi',
    'SIDE_SHIFT': 'SideShift.ai',
    'UTORG': 'Utorg',
    'WHITEBIT': 'WhiteBIT'
}

const getProviderName = (name) => {
    name = name ? name.toUpperCase() : name

    return nameProvider[name] || name
}

export default getProviderName
