import {
    INIT,
    IN_CURRENCY,
    OUT_CURRENCY,
    PAYWAY,
    AMOUNT,
    VARIANTS,
    IN_CURRENCY_COIN_ACTION,
    OUT_CURRENCY_COIN_ACTION,
    SEARCH_MODAL,
    SEARCH_VALUE_IN_SIDE,
    SEARCH_VALUE_OUT_SIDE,
    SEARCH_VALUE_PAYWAY,
    AMOUNT_SIDE,
    SEARCH_VALUE_COUNTRY,
    ADD_PAYMENT_DETAILS,
    PAYMENT_DATA,
    PROVIDER,
    NEED_PHOTO,
    CREATED_ORDER,
    NOT_ACTIVE_IN_CURRENCY,
    NOT_ACTIVE_OUT_CURRENCY,
    NULL_ORDER,
    SHOW_ARRAY,
    ACTIVE_WAY,
    SEND_ALL,
    ORIGINAL_DATA,
    CAMERA,
    CARDS,
    NEW_OUT_DESTINATION_ACTION,
    VALIDATION_OUT_DESTINATION_ACTION,
    DESTINATION_TAG_ACTION,
    OUT_DESTINATION_ACTION,
    OUT_DESTINATION_SHOW_ACTION,
    OUT_DESTINATION_TIME_ACTION,
    COIN_ALL_BALANCE,
    BALANCE_HODL_MSG,
    SHOW_GUIDE,
    EXCHANGE_WAYS,
    SET_SCREEN,
    PROPS_MODAL,
    PAYWAY_LIST,
    WALLET_DATA,
    EMAIL_ACTION,
    REFRESH_DATA,
    AFTER_KYC_ACTION,
    ORDER_HASH_ACTION,
    SET_LOADER,
    REFRESH_TIME,
    SAVE_POSITION,
    SHOW_ROCKET,
    SET_ARRAY_VARIANTS,
    REPEAT_ORDER,
    RECHECK_SEND_ALL,
    COME_BACK,
    PRE_CHECK_ACTION,
    SET_SELECTED_FEE,
    SET_LIMITS,
    SET_LIMITS_ERROR,
    SET_PROVIDER_ERROR,
    SET_PROVIDER_LOADER,
    SET_NEED_SEARCH_PROVIDERS,
    SET_PROVIDER_EQUIVALENT_TIME
} from '../type'

const INITIAL_STATE = {
    allExchangeWays: null,
    preRequestExchagneWays: null,
    commingSoonArray: null,
    walletData: null,
    screen: 1,
    cashbackToken: null,
    version: null,
    versionNumber: null,
    sign: null,
    limits: null,
    limitsError: false,

    inCurrency: null,
    outCurrency: null,
    payway: null,
    amount: null,
    amountSide: null,

    variantsArray: [],

    exchangeWaysArray: [],
    showExchangeWaysArray: true,

    activeInCurrency: null,
    activeOutCurrency: null,

    oneTimeCard: false,

    searchValueInSide: null,
    searchValueOutSide: null,
    searchValuePayway: null,
    searchValueCountry: null,

    paymentDetails: null,
    paymentData: null,
    paywayList: null,

    provider: null,
    activeExchangeWay: null,
    outDestination: null,
    walletName: null,
    providerError: false,
    providerLoader: true,

    // modals
    needPhoto: false,
    takePhoto: false,

    order: null,

    useAllFunds: false,
    originalData: null,
    move: false,

    cameraResult: null,

    destinationValid: false,
    destinationError: false,
    destinationTag: null,
    newOutDestination: null,

    coinAllbalance: null,
    balanceHodlMsg: null,

    showGuide: false,
    selectorLoader: false,

    propsModal: null,

    time: null,

    email: null,

    refreshData: false,

    afterKYC: false,

    toOrderHash: null,
    showLoader: null,

    refreshTime: null,

    savePosition: null,

    showArray: true,
    rocket: false,
    arrayVariants: [],

    repeatOrder: false,
    useCookie: false,
    useFirebase: true,

    recheckSendAll: false,
    comeBack: false,
    preCheck: false,

    tester: false,
    selectedFee: null,

    needSearchProviders: false,

    equivalentTime: null,

    // BSE integration
    skipProvidersScreen: null,
    trusteeWallet: false
}

const mobileMarketReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case INIT:
            return {
                ...state,
                allExchangeWays: action.allExchangeWays,
                preRequestExchagneWays: action.preRequestExchagneWays,
                commingSoonArray: action.commingSoonArray,
                walletData: action.walletData || state.walletData,
                cashbackToken: action.cashbackToken,
                version: action.version,
                versionNumber: action.versionNumber,
                sign: action.sign,
                inCurrency: action.inCurrency,
                outCurrency: action.outCurrency,
                exchangeWaysArray: action.exchangeWaysArray,
                payway: action.payway,
                amountSide: action.amountSide,
                paymentData: action.paymentData,
                outDestination: action.outDestination || state.outDestination,
                walletName: action.walletName || state.walletName,
                showGuide: action.showGuide,
                time: action.time,
                refreshTime: action.refreshTime,
                amount: action.amount,
                useCookie: action.useCookie,
                useFirebase: action.useFirebase,
                tester: action.tester,
                limits: action.limits,

                // BSE integration
                skipProvidersScreen: action.skipProvidersScreen,
                trusteeWallet: action.trusteeWallet
            }
        case AMOUNT:
            return {
                ...state,
                amount: action.amount
            }
        case IN_CURRENCY:
            return {
                ...state,
                inCurrency: action.inCurrency,
                payway: action.payway,
                amount: null,
                amountSide: 'IN',
                activeExchangeWay: null,
                provider: null,
                showExchangeWaysArray: action.showExchangeWaysArray,
                useAllFunds: null,
                move: null,
                searchValueInSide: null,
                searchValueOutSide: null,
                selectorLoader: action.selectorLoader,
                limitsError: false
            }
        case NOT_ACTIVE_IN_CURRENCY:
            return {
                ...state,
                inCurrency: action.inCurrency,
                outCurrency: action.outCurrency,
                payway: action.payway,
                amountSide: 'IN',
                amount: null,
                provider: null,
                activeExchangeWay: null,
                useAllFunds: null,
                move: null,
                outDestination: action.outDestination,
                walletName: action.walletName,
                searchValueInSide: null,
                searchValueOutSide: null,
                selectorLoader: action.selectorLoader,
                limitsError: false
            }
        case OUT_CURRENCY:
            return {
                ...state,
                outCurrency: action.outCurrency,
                payway: action.payway,
                amount: null,
                amountSide: 'IN',
                activeExchangeWay: null,
                provider: null,
                showExchangeWaysArray: action.showExchangeWaysArray,
                useAllFunds: null,
                move: null,
                outDestination: action.outDestination,
                walletName: action.walletName,
                searchValueInSide: null,
                searchValueOutSide: null,
                selectorLoader: action.selectorLoader,
                limitsError: false
            }
        case NOT_ACTIVE_OUT_CURRENCY:
            return {
                ...state,
                outCurrency: action.outCurrency,
                inCurrency: action.inCurrency,
                payway: action.payway,
                amountSide: 'IN',
                amount: null,
                provider: null,
                activeExchangeWay: null,
                useAllFunds: null,
                move: null,
                outDestination: action.outDestination,
                walletName: action.walletName,
                searchValueInSide: null,
                searchValueOutSide: null,
                selectorLoader: action.selectorLoader,
                limitsError: false
            }
        case PAYWAY:
            return {
                ...state,
                payway: action.payway,
                activeExchangeWay: null,
                provider: null,
                showExchangeWaysArray: action.showExchangeWaysArray,
                useAllFunds: null,
                move: null,
                selectorLoader: action.selectorLoader,
                limitsError: false
            }
        case SHOW_ARRAY:
            return {
                ...state,
                showExchangeWaysArray: action.showExchangeWaysArray
            }
        case ACTIVE_WAY:
            return {
                ...state,
                activeExchangeWay: action.activeExchangeWay
            }
        case VARIANTS:
            return {
                ...state,
                exchangeWaysArray: action.exchangeWaysArray
            }
        case IN_CURRENCY_COIN_ACTION:
            return {
                ...state,
                activeInCurrency: action.activeInCurrency
            }
        case OUT_CURRENCY_COIN_ACTION:
            return {
                ...state,
                activeOutCurrency: action.activeOutCurrency
            }
        case SEARCH_MODAL:
            return {
                ...state,
                searchModal: !state.searchModal,
                move: false
            }
        case SEARCH_VALUE_IN_SIDE:
            return {
                ...state,
                searchValueInSide: action.searchValueInSide
            }
        case SEARCH_VALUE_OUT_SIDE:
            return {
                ...state,
                searchValueOutSide: action.searchValueOutSide
            }
        case SEARCH_VALUE_PAYWAY:
            return {
                ...state,
                searchValuePayway: action.searchValuePayway
            }
        case SEARCH_VALUE_COUNTRY:
            return {
                ...state,
                searchValueCountry: action.searchValueCountry
            }
        case AMOUNT_SIDE:
            return {
                ...state,
                amountSide: action.amountSide
            }
        case ADD_PAYMENT_DETAILS:
            return {
                ...state,
                paymentDetails: action.paymentDetails
            }
        case PAYMENT_DATA:
            return {
                ...state,
                paymentData: action.paymentData
            }
        case PROVIDER:
            return {
                ...state,
                provider: action.provider,
                activeExchangeWay: action.activeExchangeWay
            }
        case NEED_PHOTO:
            return {
                ...state,
                needPhoto: action.needPhoto,
                textError: action.textError,
                takePhoto: action.takePhoto
            }
        case CREATED_ORDER:
            return {
                ...state,
                order: action.order
            }
        case NULL_ORDER:
            return {
                ...state,
                order: null
            }
        case SEND_ALL:
            return {
                ...state,
                useAllFunds: action.useAllFunds
            }
        case ORIGINAL_DATA:
            return {
                ...state,
                originalData: action.originalData,
                move: action.move
            }
        case CAMERA:
            return {
                ...state,
                cameraResult: action.cameraResult
            }
        case CARDS:
            return {
                ...state,
                walletData: {
                    ...state.walletData,
                    cards: action.cards
                }
            }
        case NEW_OUT_DESTINATION_ACTION:
            return {
                ...state,
                newOutDestination: !state.newOutDestination,
                outDestinationShow: false,
                outDestination: null
            }
        case VALIDATION_OUT_DESTINATION_ACTION:
            return {
                ...state,
                destinationValid: action.destinationValid,
                destinationError: action.destinationError
            }
        case DESTINATION_TAG_ACTION:
            return {
                ...state,
                destinationTag: action.destinationTag
            }
        case OUT_DESTINATION_TIME_ACTION:
            return {
                ...state,
                outDestination: action.outDestination,
                walletName: null
            }
        case OUT_DESTINATION_SHOW_ACTION:
            return {
                ...state,
                outDestinationShow: !state.outDestinationShow,
                newOutDestination: false,
                destinationValid: false
            }
        case OUT_DESTINATION_ACTION:
            return {
                ...state,
                outDestination: action.outDestination,
                walletName: action.walletName,
                outDestinationShow: false,
                newOutDestination: false,
                destinationValid: false
            }
        case COIN_ALL_BALANCE:
            return {
                ...state,
                coinAllbalance: action.coinAllbalance
            }
        case BALANCE_HODL_MSG:
            return {
                ...state,
                balanceHodlMsg: action.balanceHodlMsg
            }
        case SHOW_GUIDE:
            return {
                ...state,
                showGuide: action.showGuide
            }
        case EXCHANGE_WAYS:
            return {
                ...state,
                allExchangeWays: action.allExchangeWays,
                commingSoonArray: action.commingSoonArray,
                exchangeWaysArray: action.exchangeWaysArray,
                showExchangeWaysArray: action.showExchangeWaysArray,
                selectorLoader: action.selectorLoader
            }
        case SET_SCREEN:
            return {
                ...state,
                screen: action.screen
            }
        case PROPS_MODAL:
            return {
                ...state,
                propsModal: action.propsModal
            }
        case PAYWAY_LIST:
            return {
                ...state,
                paywayList: action.paywayList
            }
        case WALLET_DATA:
            return {
                ...state,
                walletData: action.walletData,
                sign: action.walletData.sign
            }
        case EMAIL_ACTION:
            return {
                ...state,
                email: action.email
            }
        case REFRESH_DATA:
            return {
                ...state,
                refreshData: action.refreshData
            }
        case AFTER_KYC_ACTION:
            return {
                ...state,
                afterKYC: action.afterKYC
            }
        case ORDER_HASH_ACTION:
            return {
                ...state,
                toOrderHash: action.toOrderHash
            }
        case SET_LOADER:
            return {
                ...state,
                showLoader: action.showLoader
            }
        case REFRESH_TIME:
            return {
                ...state,
                refreshTime: action.refreshTime
            }
        case SAVE_POSITION:
            return {
                ...state,
                savePosition: action.savePosition
            }
        case SHOW_ROCKET:
            return {
                ...state,
                showArray: action.showArray,
                rocket: action.rocket
            }
        case SET_ARRAY_VARIANTS: {
            return {
                ...state,
                arrayVariants: action.arrayVariants
            }
        }
        case REPEAT_ORDER: {
            return {
                ...state,
                repeatOrder: action.repeatOrder
            }
        }
        case RECHECK_SEND_ALL:
            return {
                ...state,
                recheckSendAll: action.recheckSendAll
            }
        case COME_BACK:
            return {
                ...state,
                comeBack: action.comeBack
            }
        case PRE_CHECK_ACTION:
            return {
                ...state,
                preCheck: action.preCheck
            }
        case SET_SELECTED_FEE:
            return {
                ...state,
                selectedFee: action.selectedFee
            }
        case SET_LIMITS:
            return {
                ...state,
                limits: action.limits
            }
        case SET_LIMITS_ERROR:
            return {
                ...state,
                limitsError: action.limitsError
            }
        case SET_PROVIDER_ERROR:
            return {
                ...state,
                providerError: action.providerError
            }
        case SET_PROVIDER_LOADER:
            return {
                ...state,
                providerLoader: action.providerLoader
            }
        case SET_NEED_SEARCH_PROVIDERS:
            return {
                ...state,
                needSearchProviders: action.needSearchProviders
            }
        case SET_PROVIDER_EQUIVALENT_TIME:
            return {
                ...state,
                equivalentTime: action.equivalentTime
            }

        default: {
            return {
                ...state
            }
        }
    }
}

export default mobileMarketReducer
