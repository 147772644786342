import getActualWays from './getActualWays'
import { sortWays } from './sortWays'

const getWaysData = async (
    inCurrencyCode,
    outCurrencyCode,
    payway,
    allExchangeWays,
    cashbackToken,
    message,
    messageHash,
    signature,
    tester,
    enqueueSnackbar = false
) => {
    if (!inCurrencyCode || !outCurrencyCode || !payway || !allExchangeWays || !cashbackToken || !message || !messageHash || !signature) {
        return null
    }

    let inCurrency, outCurrency, exchangeWays, exchangeWaysArray, commingArray, activeExchangeWays, actualWays

    if (inCurrencyCode.type === 'FIAT') {
        const currencyArray = Array.from(
            new Set(
                allExchangeWays
                    .filter((way) => way.inPaywayCode === payway)
                    .map((way) => JSON.stringify({ code: way.inCurrency.code, type: way.inCurrency.type }))
            )
        )
        if (currencyArray.includes(JSON.stringify({ code: inCurrencyCode.code, type: inCurrencyCode.type }))) {
            inCurrency = inCurrencyCode
        } else {
            inCurrency = { code: 'BTC', type: 'CRYPTO', symbol: 'BTC', name: 'Bitcoin' }
        }
    } else if (inCurrencyCode.type === 'CRYPTO') {
        const currencyArray = Array.from(
            new Set(allExchangeWays.map((way) => JSON.stringify({ code: way.inCurrency.code, type: way.inCurrency.type })))
        )
        if (currencyArray.includes(JSON.stringify({ code: inCurrencyCode.code, type: inCurrencyCode.type }))) {
            inCurrency = inCurrencyCode
        } else {
            inCurrency = { code: 'BTC', type: 'CRYPTO', symbol: 'BTC', name: 'Bitcoin' }
        }
    } else {
        inCurrency = inCurrencyCode
    }

    if (outCurrencyCode.type === 'CRYPTO') {
        const currencyArray = Array.from(
            new Set(
                allExchangeWays
                    .filter((way) => way.inCurrency.code === inCurrency.code)
                    .map((way) => JSON.stringify({ code: way.outCurrency.code, type: way.outCurrency.type }))
            )
        )
        if (currencyArray.includes(JSON.stringify({ code: outCurrencyCode.code, type: outCurrencyCode.type }))) {
            outCurrency = outCurrencyCode
        } else {
            outCurrency = { code: 'ETH', type: 'CRYPTO', symbol: 'ETH', name: 'Ethereum' }
        }
    } else {
        outCurrency = outCurrencyCode
    }

    exchangeWaysArray = allExchangeWays.filter((way) => way.inCurrency.code === inCurrency.code && way.outCurrency.code === outCurrency.code)

    if (payway) {
        if (inCurrencyCode.type === 'FIAT' || outCurrencyCode.type === 'FIAT') {
            exchangeWaysArray = exchangeWaysArray.filter((way) =>
                way.exchangeWayType === 'BUY' ? way.inPaywayCode === payway : way.exchangeWayType === 'SELL' ? way.outPaywayCode === payway : null
            )
        }
    }

    try {
        actualWays = await getActualWays(inCurrency, outCurrency, payway, cashbackToken, message, messageHash, signature, tester, enqueueSnackbar)
        if (actualWays) {
            activeExchangeWays = actualWays.dataAll
            commingArray = []
            // actualWays.comingSoon
        }
    } catch (e) {
        console.log(JSON.stringify(e))
        activeExchangeWays = []
        // commingArray = []
    }

    localStorage.setItem('inCurrencyCode', JSON.stringify(inCurrency))
    localStorage.setItem('outCurrencyCode', JSON.stringify(outCurrency))

    if (!localStorage.getItem('inCurrencyCode') && !localStorage.getItem('outCurrencyCode') && !exchangeWaysArray.length) {
        if (inCurrencyCode.code === 'USD' && outCurrencyCode.code === 'BTC' && !exchangeWaysArray.length) {
            const waysData = await getWaysData(
                inCurrencyCode,
                { code: 'ETH', type: 'CRYPTO', symbol: 'ETH' },
                payway,
                allExchangeWays,
                cashbackToken,
                message,
                messageHash,
                signature,
                tester,
                enqueueSnackbar
            )
            if (waysData.exchangeWays.length) {
                return waysData
            }
        } else {
            const waysData = await getWaysData(
                { code: 'BTC', type: 'CRYPTO', symbol: 'BTC' },
                { code: 'ETH', type: 'CRYPTO', symbol: 'ETH' },
                payway,
                allExchangeWays,
                cashbackToken,
                message,
                messageHash,
                signature,
                tester,
                enqueueSnackbar
            )
            if (waysData.exchangeWays.length) {
                return waysData
            }
        }
    }

    localStorage.setItem('inCurrencyCode', JSON.stringify(inCurrency))
    localStorage.setItem('outCurrencyCode', JSON.stringify(outCurrency))

    // sorting exchange ways
    exchangeWaysArray = activeExchangeWays ? (activeExchangeWays.length ? sortWays(activeExchangeWays) : activeExchangeWays) : []
    // commingArray = commingArray ? commingArray.length ? sortWays(commingArray) : commingArray : []
    exchangeWays = activeExchangeWays && activeExchangeWays.length ? activeExchangeWays : []

    return {
        inCurrency,
        outCurrency,
        exchangeWays,
        activeExchangeWays,
        commingArray: []
    }
}

export default getWaysData
