export default function message(version) {
    const onMessage = ` if (true) {
        if (e.nativeEvent.data.indexOf('paycore.io') !== -1 && e.nativeEvent.data.indexOf('<section class="error-message') !== -1) {
            CACHE_IS_ERROR = true
            Log.log('Trade.SMSV3CodeScreen.on message ' + e.nativeEvent.data + ' SET ERROR')
        } else {
            Log.log('Trade.SMSV3CodeScreen.on message ' + e.nativeEvent.data + ' NO ERROR')
        }
        if (e.nativeEvent.data.indexOf('WebView Loaded') !== -1) { 
            that.handleState({
                status: 'SUCCESS'
            })
            if (${Number(version) > 1004 || version === 'VERSION'}) {
                setExchangeStatus('', {orderHash: that.state.orderHash, status: 'OPEN'})
            } else {
                setExchangeStatus('', that.state.orderHash, 'OPEN')
            }
        } else if (e.nativeEvent.data.indexOf('WebView Agent OPEN=') !== -1) {
            const link = e.nativeEvent.data.substr(19)
            Linking.canOpenURL(link).then(supported => {
                if (supported) {
                    Linking.openURL(link)
                } else {
                    copyToClipboard(link)
                    showModal({
                        type: 'INFO_MODAL',
                        icon: 'WARNING',
                        title: strings('modal.exchange.sorry'),
                        description: strings('confirmScreen.openLinkError')
                    })
                    Log.log('Trade.SMSV3CodeScreen Dont know how to open URI', link)
                }
            })
        }
    }`
    return onMessage
}

// https://wallet.advcash.com/sci/paymentRequest.jsf
