import store from '../../index'
import { SCREEN_SHOW_MODAL, SCREEN_HIDE_MODAL, INIT, FADE_TYPE } from '../type'

const { dispatch } = store

export function modalScreen() {
    dispatch({
        type: INIT
    })
}

export function showScreenModal(data, callback) {
    dispatch({
        type: SCREEN_SHOW_MODAL,
        data,
        callback
    })
}

export function hideScreenModal() {
    dispatch({
        type: SCREEN_HIDE_MODAL,
        data: null,
        callback: null
    })
}

export function fadeTypeAction(fadeType) {
    dispatch({
        type: FADE_TYPE,
        fadeType
    })
}
