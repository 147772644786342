/* eslint-disable no-fallthrough */
export const getPlaceholder = (typePayment, state) => {
    switch (typePayment.toString().toUpperCase()) {
        case 'MOBILE_PHONE':
        case 'QIWI':
            if (state.inCurrency.code === 'RUB' || state.outCurrency.code === 'RUB') {
                return '+70000000000'
            } else if (state.inCurrency.code === 'UAH' || state.outCurrency.code === 'UAH') {
                return '+3800000000000'
            }
        case 'CASH':
            return '+0000000000000'

        case 'YANDEX_MONEY':
            return '0000 0000 0000 0000'

        case 'WEBMONEY':
            return 'Z000000000000'

        case 'PAYEER':
            return 'P0000000000'

        case 'CAPITALIST':
        case 'PERFECT_MONEY':
            if (state.inCurrency.code === 'RUB' || state.outCurrency.code === 'RUB') {
                return 'R00000000'
            } else if (state.inCurrency.code === 'USD' || state.outCurrency.code === 'USD') {
                return 'U00000000'
            } else if (state.inCurrency.code === 'EUR' || state.outCurrency.code === 'EUR') {
                return 'E00000000'
            }

        case 'ADVCASH':
            if (state.inCurrency.code === 'RUB' || state.outCurrency.code === 'RUB') {
                return 'R000000000000'
            } else if (state.inCurrency.code === 'USD' || state.outCurrency.code === 'USD') {
                return 'U000000000000'
            } else if (state.inCurrency.code === 'EUR' || state.outCurrency.code === 'EUR') {
                return 'E000000000000'
            } else if (state.inCurrency.code === 'UAH' || state.outCurrency.code === 'UAH') {
                return 'H000000000000'
            }

        case 'BANK_TRANSFER':
            if (state.inCurrency.code === 'EUR' || state.outCurrency.code === 'EUR') {
                return 'EU000000000000000000'
            } else if (state.inCurrency.code === 'GBP' || state.outCurrency.code === 'GBP') {
                return 'GB00 0000 0000 0000 0000 00'
            }

        case 'CARD':
            return '0000 0000 0000 0000'

        default:
            return '0000 0000 0000 0000'
    }
}
