import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'

import snsWebSdk from '@sumsub/websdk'

import config from 'config/config'
import TopNavigation from 'components/TopNavigation/TopNavigation'
import { Content } from 'components/wrapperScreen/wrapper.css'
import Wrapper from 'components/ScreenWrapper/Wrapper'
import Loader from 'components/Loader/loader'

import store from 'redux/index'
import { showModal } from 'redux/AppStores/ModalStore/action'
import { afterKycAction, emailAction, needSearchProvidersAction } from 'redux/AppStores/MobileMarket/action'
import { hideSearchScreenModal } from 'redux/AppStores/SearchModalScreenStore/action'

import i18n, { strings } from 'i18n'

import Log from 'services/sentry/Log'
import getHashStr from 'services/getHashStr'
import validation from 'services/ValidationService'
import hideAnimation from 'services/hideAnimation'

import { getThemeModeStyles } from 'theme/useDarkMode'

const { dispatch } = store

const SumSub = () => {
    const state = store.getState().mobileMarket

    const theme = getThemeModeStyles()

    const [loader, setLoader] = useState(false)

    const history = useHistory()

    useEffect(() => {
        const main = async () => {
            const accessTokenData = await getAccessTokenData()
            console.log('useEffect accessTokenData', accessTokenData)
            if (accessTokenData && typeof accessTokenData.token !== 'undefined' && accessTokenData.token) {
                launchWebSdk(config.sumSubUrl, 'basic-kyc', accessTokenData.token)
            } else {
                window.history.back()
                setLoader(false)
                showModal({
                    type: 'INFO_MODAL',
                    icon: 'WARNING',
                    header: strings('mobileMarket.modal.sorry'),
                    description: strings('mobileMarket.modal.serverRespond')
                })
            }
        }

        setLoader(true)
        hideSearchScreenModal()
        main()

        if (state.email) {
            const userId = getHashStr(state?.email?.toLowerCase())

            localStorage.setItem('trusteeEmail', userId)
        }

        return () => {}
    }, [])

    const urlPath = (url) => {
        history.push({
            pathname: `/mobile-market/${url}`,
            location: { state: 'fade' }
        })
    }

    const getAccessTokenData = async () => {
        try {
            let email

            if (!validation('EMAIL', state.email)) {
                Log.info(`V3.sumSub.getAccessTokenData email ${JSON.stringify(state.email)}`)

                localStorage.removeItem('trusteeEmail')
                dispatch(emailAction(null))
                window.history.back()

                return null
            }

            email = state.email?.toLowerCase()

            const url =
                config.backendUrl +
                '/sum-sub/access-token?userId=' +
                email +
                '&cashbackToken=' +
                state.cashbackToken +
                '&signMessage=' +
                state.sign.message +
                '&signMessageHash=' +
                state.sign.messageHash +
                '&signature=' +
                state.sign.signature +
                '&deviceToken=' +
                state.walletData.deviceToken +
                '&locale=' +
                i18n.language

            const response = await axios.get(url)
            return response.data
        } catch (error) {
            console.log('V3.sumSub.getAccessTokenData error ' + error)
            console.log('V3.sumSub.getAccessTokenData error ' + error?.message)
            console.log('V3.sumSub.getAccessTokenData error ' + JSON.stringify(error))
            Log.warning(`V3.sumSub.getAccessTokenData error ${JSON.stringify(error.message)}`)
            Log.warning(`V3.sumSub.getAccessTokenData error ${error.message}`)

            Log.error(`V3.sumSub.getAccessTokenData error ${JSON.stringify(error)}`)
            Log.error(`V3.sumSub.getAccessTokenData error ${error}`)
            Log.captureError(`V3.sumSub.getAccessTokenData error`)

            window.history.back()
            setLoader(false)
            showModal({
                type: 'INFO_MODAL',
                icon: 'WARNING',
                header: strings('mobileMarket.modal.sorry'),
                description: config.showError ? error.message : strings('mobileMarket.modal.serverRespond')
            })
            return
        }
    }

    const onMessage = (type, payload) => {
        // console.log('WebSDK onMessage', type, payload); // for debug
        Log.info(`V3.sumSub.WebSDK onMessage, ${JSON.stringify(type)}, ${JSON.stringify(payload)}`)

        if (type === 'idCheck.applicantStatus') {
            if (payload && typeof payload.reviewStatus !== 'undefined' && payload.reviewStatus && payload.reviewStatus === 'completed') {
                if (typeof payload.reviewResult !== 'undefined' && payload.reviewResult && payload.reviewResult.reviewAnswer === 'GREEN') {
                    dispatch(needSearchProvidersAction(true))
                    setTimeout(() => {
                        dispatch(afterKycAction(true))
                        // urlPath('pre-check')
                        hideAnimation('ModalKYC', hideSearchScreenModal)
                    }, 250)
                }
            }
        }
    }

    const launchWebSdk = async (apiUrl, flowName, accessToken) => {
        const snsWebSdkInstance = snsWebSdk
            .init(accessToken, async (newAccessTokenCallback) => {
                // Access token expired
                // get a new one and pass it to the callback to re-initiate the WebSDK
                const accessTokenData = await getAccessTokenData()
                newAccessTokenCallback(accessTokenData.token)
            })
            .withConf({
                lang: i18n.language || localStorage.getItem('i18nextLng'),
                onMessage,
                uiConf: {
                    // customCssStr: ":root {\n  --black: #000000;\n   --grey: #F5F5F5;\n  --grey-darker: #B2B2B2;\n  --border-color: #DBDBDB;\n}\n\np {\n  color: var(--black);\n  font-size: 16px;\n  line-height: 24px;\n}\n\nsection {\n  margin: 40px auto;\n}\n\ninput {\n  color: var(--black);\n  font-weight: 600;\n  outline: none;\n}\n\nsection.content {\n
                    // background-color: var(--grey);\n  color: var(--black);\n  padding: 40px 40px 16px;\n  box-shadow: none;\n  border-radius: 6px;\n}\n\nbutton.submit,\nbutton.back {\n  text-transform: capitalize;\n  border-radius: 6px;\n  height: 48px;\n  padding: 0 30px;\n  font-size: 16px;\n  background-image: none !important;\n
                    // transform: none !important;\n  box-shadow: none !important;\n  transition: all 0.2s linear;\n}\n\nbutton.submit {\n  min-width: 132px;\n  background: none;\n
                    // background-color: var(--black);\n}\n\n.round-icon {\n  background-color: var(--black) !important;\n  background-image: none !important;\n}"
                    // customCss: "https://url.com/styles.css"
                    // URL to css file in case you need change it dynamically from the code
                    // the similar setting at Applicant flow will rewrite customCss
                    // you may also use to pass string with plain styles `customCssStr:`
                },
                onError: (error) => {
                    Log.error(`V3.sumSub.WebSDK onError, ${JSON.stringify(error)}`)
                    Log.captureError(`V3.sumSub.WebSDK onError`)
                    setLoader(false)
                    // window.history.back()
                    // showModal({
                    //     type: 'INFO_MODAL',
                    //     icon: 'WARNING',
                    //     header: strings('mobileMarket.modal.sorry'),
                    //     description: config.showError ? JSON.stringify(error) : strings('mobileMarket.modal.serverRespond')
                    // })
                }
            })
            .on('onError', (error) => {
                Log.error(`V3.sumSub.on() onError, ${JSON.stringify(error)}`)
                Log.captureError(`V3.sumSub.on() onError`)
                setLoader(false)
            })
            .on('idCheck.applicantStatus', (payload) => {
                Log.info(`Test.sumSub.on() idCheck.applicantStatus', ${JSON.stringify(payload)}`)
                Log.captureError(`V3.sumSub.on() idCheck.applicantStatus`)
                if (payload && payload?.reviewStatus && payload?.reviewStatus === 'completed') {
                    if (payload?.reviewResult && payload?.reviewResult?.reviewAnswer === 'GREEN') {
                        dispatch(needSearchProvidersAction(true))
                        setTimeout(() => {
                            dispatch(afterKycAction(true))
                            // urlPath('pre-check')
                            hideAnimation('ModalKYC', hideSearchScreenModal)
                            window.history.back()
                        }, 250)
                    }
                }
            })
            .on('applicantStatus', (payload) => {
                Log.info(`Test.sumSub.on() applicantStatus', ${JSON.stringify(payload)}`)
                Log.captureError(`V3.sumSub.on() applicantStatus`)
                if (payload && payload?.reviewStatus && payload?.reviewStatus === 'completed') {
                    if (payload?.reviewResult && payload?.reviewResult?.reviewAnswer === 'GREEN') {
                        dispatch(needSearchProvidersAction(true))
                        setTimeout(() => {
                            dispatch(afterKycAction(true))
                            // urlPath('pre-check')
                            hideAnimation('ModalKYC', hideSearchScreenModal)
                        }, 250)
                    }
                }
            })
            .on('moduleResultPresented', (payload) => {
                Log.info(`Test.sumSub.on() moduleResultPresented', ${JSON.stringify(payload)}`)
                Log.captureError(`V3.sumSub.on() moduleResultPresented`)
            })
            .build()

        // :

        // snsWebSdk.init(
        //     accessToken,
        //     async (newAccessTokenCallback) => {
        //         // Access token expired
        //         // get a new one and pass it to the callback to re-initiate the WebSDK
        //         const accessTokenData = await getAccessTokenData();
        //         newAccessTokenCallback(accessTokenData.token)
        //     }
        // )
        //     .withConf({
        //         lang: i18n.language || localStorage.getItem('i18nextLng'),
        //         onMessage,
        //         uiConf: {
        //             // customCssStr: ":root {\n  --black: #000000;\n   --grey: #F5F5F5;\n  --grey-darker: #B2B2B2;\n  --border-color: #DBDBDB;\n}\n\np {\n  color: var(--black);\n  font-size: 16px;\n  line-height: 24px;\n}\n\nsection {\n
        // margin: 40px auto;\n}\n\ninput {\n  color: var(--black);\n  font-weight: 600;\n  outline: none;\n}\n\nsection.content {\n  background-color: var(--grey);\n  color: var(--black);\n  padding: 40px 40px 16px;\n  box-shadow: none;\n  border-radius: 6px;\n}\n\nbutton.submit,\nbutton.back {\n  text-transform: capitalize;\n
        // border-radius: 6px;\n  height: 48px;\n  padding: 0 30px;\n  font-size: 16px;\n  background-image: none !important;\n  transform: none !important;\n  box-shadow: none !important;\n  transition: all 0.2s linear;\n}\n\nbutton.submit {\n  min-width: 132px;\n  background: none;\n  background-color: var(--black);\n}\n\n.round-icon {\n  background-color: var(--black) !important;\n
        // background-image: none !important;\n}"
        //             // customCss: "https://url.com/styles.css"
        //             // URL to css file in case you need change it dynamically from the code
        //             // the similar setting at Applicant flow will rewrite customCss
        //             // you may also use to pass string with plain styles `customCssStr:`
        //         },
        //         onError: (error) => {
        //             Log.error(`V3.sumSub.WebSDK onError, ${JSON.stringify(error)}`)
        //             Log.captureError(`V3.sumSub.WebSDK onError`)
        //             setLoader(false)
        //             // window.history.back()
        //             // showModal({
        //             //     type: 'INFO_MODAL',
        //             //     icon: 'WARNING',
        //             //     header: strings('mobileMarket.modal.sorry'),
        //             //     description: config.showError ? JSON.stringify(error) : strings('mobileMarket.modal.serverRespond')
        //             // })
        //         },
        //     })
        //     .on('onError', (error) => {
        //         Log.error(`V3.sumSub.on() onError, ${JSON.stringify(error)}`)
        //         Log.captureError(`V3.sumSub.on() onError`)
        //         setLoader(false)
        //     })
        //     .on('applicantStatus', (payload) => {
        //         Log.error(`V3.sumSub.on() payload, ${JSON.stringify(payload)}`)
        //         Log.captureError(`V3.sumSub.on() payload`)
        //         if (payload && typeof payload.reviewStatus !== 'undefined' && payload.reviewStatus && payload.reviewStatus === 'completed') {
        //             if (typeof payload.reviewResult !== 'undefined' && payload.reviewResult && payload.reviewResult.reviewAnswer === 'GREEN') {
        //                 dispatch(needSearchProvidersAction(true))
        //                 setTimeout(() => {
        //                     dispatch(afterKycAction(true))
        //                     // urlPath('pre-check')
        //                     hideAnimation('ModalKYC', hideSearchScreenModal)
        //                 }, 250)
        //             }
        //         }
        //     })
        //     .build()

        snsWebSdkInstance.launch('#sumsub-websdk-container')
        setTimeout(() => {
            setLoader(false)
        }, 250)
    }

    return (
        <>
            <TopNavigation text={strings('mobileMarket.kyc.title')} styles={{ zIndex: 1600 }} leftType='back' />
            <Content style={{ zIndex: 1500 }} background={theme.main.bg}>
                <Wrapper contentStyle={{ marginLeft: 0, marginRight: 0 }}>
                    {loader && (
                        <>
                            <Loader />
                        </>
                    )}
                    <div id='sumsub-websdk-container' style={{ display: !loader ? '' : 'none' }}></div>
                </Wrapper>
            </Content>
        </>
    )
}

export default SumSub
