import Resizer from 'react-image-file-resizer'

import axios from 'axios'
import config from '../config/config'
import Log from './sentry/Log'

const validateCard = async (file, card, state) => {
    if (!file || !card) return null

    const fileData = await new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            1920,
            1080,
            'JPEG',
            80,
            0,
            (uri) => {
                resolve(uri)
            },
            'base64'
        )
    })

    const data = new FormData()
    if (typeof card.number !== 'undefined') {
        data.append('cardNumber', card.number)
    }
    if (typeof card.countryCode !== 'undefined') {
        data.append('countryCode', card.countryCode)
    }
    if (typeof state.cashbackToken !== 'undefined') {
        data.append('cashbackToken', state.cashbackToken)
    }
    if (typeof state.walletData !== 'undefined' && state.walletData) {
        data.append('locale', state.walletData.locale)
        data.append('deviceToken', state.walletData.deviceToken)
        data.append('signMessage', state.sign.message)
        data.append('signMessageHash', state.sign.messageHash)
        data.append('signature', state.sign.signature)
    }
    if (fileData) {
        data.append('image', fileData)
    }

    try {
        let res = await axios.post(`${config.backendUrl}/payment-details/validate-card`, data, {
            headers: {
                'Accept': 'multipart/form-data',
                'Content-Type': 'multipart/form-data'
            }
        })

        return res
    } catch (e) {
        console.log('V3.validateCard e.response.data ' + JSON.stringify(e.response.data))
        console.log('V3.validateCard e.response.data.message ' + JSON.stringify(e.response.data.message))
        Log.captureError('V3.validateCard ERROR')
        return null
    }
}

export default validateCard
