import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// styled
import StyledModal from './style.css'
import TopNavigation from '../../../../components/TopNavigation/TopNavigation'
import { hideSearchScreenModal } from '../../../../redux/AppStores/SearchModalScreenStore/action'
import { propsModalAction } from '../../../../redux/AppStores/MobileMarket/action'
import store from '../../../../redux'

const { dispatch } = store

const SearchLayout = (props) => {
    SearchLayout.defaultProps = {
        id: ''
    }

    SearchLayout.propTypes = {
        id: PropTypes.string.isRequired
    }

    const { id, header, styles, share, shareObj, leftType, leftAction, rightType, rightAction } = props

    const [state, setState] = useState(null)

    const background = React.createRef()

    useEffect(() => {
        window.addEventListener('keydown', onEscKeyDown, false)
        setTimeout(() => setState('in'), 0)

        return () => {
            window.removeEventListener('keydown', onEscKeyDown, false)
        }
    }, [])

    const transitionEnd = (e) => {
        if (e.propertyName !== 'opacity' || state === 'in') return

        if (state === 'out') {
            hideSearchScreenModal()
        }
    }

    const onEscKeyDown = (e) => {
        if (e.key !== 'Escape') return
        setState('out')
    }

    const handleClick = (e) => {
        e.preventDefault()
        leftAction && leftAction()
        // hideSearchScreenModal()
        setTimeout(() => hideSearchScreenModal(), 250)
        setState('out')
    }

    const leftHandler = () => {
        dispatch(propsModalAction(false))
        if (leftAction) {
            leftAction()
        }
        setTimeout(() => hideSearchScreenModal(), 250)

        setState('out')
    }

    return (
        <StyledModal id={id} className={`wrapper fade-${state}`} role='dialog' onTransitionEnd={transitionEnd} style={{ ...styles }}>
            <TopNavigation
                text={header}
                styles={{ zIndex: 1500 }}
                leftType={leftType}
                leftAction={leftType ? leftHandler : null}
                rightType={rightType}
                rightAction={rightAction}
                share={share}
                shareObj={shareObj}
            />
            <div className='box-dialog'>{props.children}</div>
        </StyledModal>
    )
}

export default SearchLayout
