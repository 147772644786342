import styled from 'styled-components'

const InputDiv = styled.div`
    width: 100%;
    box-shadow: 0px 0px 16px ${(p) => (!p.condition ? 'rgba(0, 0, 0, 0.1)' : 'rgba(229, 76, 76, 0.15)')};
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    background: ${(p) => p.inputBg};

    .input__field {
        width: 100%;
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        display: flex;
        flex-direction: row;
    }

    input {
        width: 80%;
        height: 24px;
        background: ${(p) => p.inputBg};
        border-radius: 10px;
        border: none;
        outline: none;
        padding-top: 17px;
        padding-bottom: 17px;
        padding-left: 16px;
        font-family: 'Montserrat SemiBold';
        font-style: normal;
        /* font-weight: 600; */
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0.5px;
        color: ${(p) => p.colorText};
        -webkit-tap-highlight-color: transparent;
    }

    .input__inValid {
        width: 100%;
        box-shadow: 0px 0px 16px rgba(229, 76, 76, 0.15);
        border-radius: 10px;
        display: flex;
        flex-direction: row;
    }

    .input__inValid input {
        width: 80%;
        background: ${(p) => p.inputBg};
        border-radius: 10px;
        border: none;
        outline: none;
        padding-top: 17px;
        padding-bottom: 17px;
        padding-left: 16px;
        font-family: 'Montserrat SemiBold';
        font-style: normal;
        /* font-weight: 600; */
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0.5px;
        color: #e54c4c;
    }

    .input__field input:disabled {
        opacity: 1;
    }

    .icon__wrapper {
        width: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        -webkit-tap-highlight-color: transparent;
    }
`

export default InputDiv
