import React from 'react'
import propTypes from 'prop-types'
import store from '../../redux'
import { showGuideAction } from '../../redux/AppStores/MobileMarket/action'
import Icon from '../../components/Icon/Icon'
import { strings } from '../../i18n'
import classes from './style.module.css'
import { getThemeModeStyles } from '../../theme/useDarkMode'

const styles = {
    wrapper: {
        position: 'absolute',
        width: 'calc(100% - 32px)',
        zIndex: '100',
        bottom: '5px',
        textAlign: 'center'
    }
}

const { dispatch } = store

export default function Buttons(props) {
    const { index, total, loop, prevHandler, nextHandler } = props

    const theme = getThemeModeStyles()

    return (
        <div style={styles.wrapper}>
            {(loop || index !== total - 1) && (
                <div
                    className='skip'
                    onClick={async () => {
                        localStorage.setItem('tssGuide', false)
                        document.getElementById('skip').style.display = 'none'
                        setTimeout(async () => dispatch(showGuideAction(false)), 1e3)
                    }}>
                    SKIP
                </div>
            )}
            {(loop || index !== total - 1) && (
                <div className='btn1' onClick={nextHandler}>
                    {/* (index !== total - 2) ? classes.btn1 : classes.btn */}
                    <Icon icon='ARROW_BACK' size={24} color={theme.bottomNav.color} style={{ transform: 'rotate(180deg)' }} />
                </div>
            )}
            {(loop || index === total - 1) && (
                <div
                    className='newBtn'
                    onClick={() => {
                        dispatch(showGuideAction(false))
                        localStorage.setItem('tssGuide', false)
                    }}>
                    {strings('mobileMarket.guide.start')}
                </div>
            )}
        </div>
    )
}

Buttons.propTypes = {
    index: propTypes.number.isRequired,
    total: propTypes.number.isRequired,
    prevHandler: propTypes.func,
    nextHandler: propTypes.func
}
