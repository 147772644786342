import styled from 'styled-components'

const Tooltip = styled.div`
    position: fixed;
    top: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    opacity: 0;
    transition: all linear 0.25s;
    z-index: 2000;
    max-width: 500px;
    margin: 40px auto;
    &.fade-in {
        top: 0;
        opacity: 1;
        transition: all linear 0.25s;
    }
    &.fade-out {
        opacity: 0;
        top: 100%;
        transition: all linear 0.25s;
    }
    .background {
        background: rgba(0, 0, 0, 0.3);
        position: fixed;
        z-index: 1040;
        display: block;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        outline: 0;
    }
    .box-dialog {
        z-index: 1050;
        width: 100%;
        margin: 16px;
        min-height: 60px;
        margin-bottom: 50px;
    }
    .main-body {
        width: 100%;
        min-height: 70px;
        background: ${(p) => p.colorBg};
        border-radius: 16px;
        // box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        margin-bottom: 16px;
    }
    .description {
        font-family: 'SF UI Display Regular';
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.5px;
        color: ${(p) => p.color};
        padding: 14px;
        -ms-user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        user-select: none;
    }
    .trigle {
        z-index: 1050;
        border-width: 8px 10px 0 8px;
        border-style: solid;
        border-color: ${(p) => p.colorBg} transparent transparent transparent;
        margin-bottom: 70px;
        position: fixed;
        right: 32px;
        bottom: 28px;
        @media (min-width: 500px) {
            right: calc((100% - 436px) / 2);
        }
    }
    .button {
        width: calc(100% - 32px);
        background: ${(p) => p.bgBtn};
        border-radius: 12px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        right: 16px;
        position: fixed;
        bottom: 24px;
        font-family: 'Montserrat SemiBold';
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.5px;
        color: #f7f7f7;
        -ms-user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        user-select: none;

        @media (min-width: 500px) {
            right: calc((100% - 464px) / 2);
        }
    }
    .btn-in {
        width: calc(100% - 32px);
        max-width: 464px;
        transition: width linear 0.25s;
    }
    .btn-out {
        width: 50px;
        max-width: 464px;
        transition: width linear 0.25s;
    }
`

export default Tooltip
