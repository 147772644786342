import styled from 'styled-components'

const ButtonDiv = styled.div`
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;

    .main {
        display: flex;
        justify-content: center;
        align-items: center;

        height: 50px;

        background: ${(p) => p.bgColor};
        box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
        border-radius: 10px;

        font-family: 'Montserrat SemiBold';
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.5px;
        color: ${(p) => p.color};

        z-index: 300;
        word-wrap: break-word;
        padding: 0 10px;
    }

    .fail {
        display: flex;
        justify-content: center;
        align-items: center;

        height: 50px;

        background: #999999;
        border-radius: 10px;

        font-family: 'Montserrat SemiBold';
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0.5px;
        color: #f7f7f7;
        text-align: center;

        z-index: 300;
        word-wrap: break-word;
        padding: 0 10px;
    }
`

export default ButtonDiv
