import axios from 'axios'
import config from 'config/config'
import { strings } from 'i18n'

import store from 'redux/index'
import {
    activeExchangeWayAction,
    amountAction,
    amountSideAction,
    createdOrderAction,
    emailAction,
    needSearchProvidersAction,
    outDestinationAction,
    propsModalAction,
    providerAction,
    providerLoaderAction,
    savePositionAction,
    sendAllAction,
    sendOriginalData,
    setRocketLoaderAction,
    variantsAction
} from 'redux/AppStores/MobileMarket/action'
import { hideModal, showModal } from 'redux/AppStores/ModalStore/action'
import { hideScreenModal, showScreenModal } from 'redux/AppStores/ScreenModalStore/action'
import { hideCardScreenModal, hideSearchScreenModal, showCardScreenModal, showSearchScreenModal } from 'redux/AppStores/SearchModalScreenStore/action'

import { getCurrencyName, getTokenNet, isToken } from 'services/getCurrenciesProperty'
import timed from 'services/timed'
import Log from 'services/sentry/Log'
import { getStatusKYC } from 'services/getStatusKYC'
import findOutDestination from 'services/findOutDestination'
import { axiosRequest } from 'services/getActualWays'
import hideAnimation from 'services/hideAnimation'

import getProviderName from '../providersSettings/providerName'

const { dispatch } = store

const limitPercent = 0.95
let CACHE_DIFF_PERCENT_BALANCE, CACHE_LOG_AMOUNT_TO_BALANCE

export const handlerLimit = (item, state, exchangeWayType, valid, findButtonHandler, event, newFlow) => {
    if (
        item.equivalentData &&
        item.equivalentData.status === 'FAIL' &&
        item.equivalentData.limit &&
        state.inCurrency.code === item.inCurrency.code &&
        state.outCurrency.code === item.outCurrency.code
    ) {
        if (valid && state.useAllFunds) {
            showModal(
                {
                    type: 'INFO_MODAL',
                    icon: 'WARNING',
                    header: strings('mobileMarket.modal.destinationHeader'),
                    description: strings('mobileMarket.modal.notActiveUseAllFunds')
                },
                () => {
                    hideAnimation('YesNoModal', hideModal)
                }
            )
            return
        }

        showModal(
            {
                type: 'YES_NO_MODAL',
                icon: 'WARNING',
                header: strings('mobileMarket.modal.destinationHeader'),
                description: strings('mobileMarket.modal.noLimit', {
                    value: `${item.equivalentData.limit.amount} ${exchangeWayType !== 'SELL' ? item.inCurrency.symbol : item.outCurrency.symbol}`
                }),
                noCallback: () => {
                    hideAnimation('YesNoModal', hideModal)
                }
            },
            () => {
                const side = exchangeWayType === 'SELL' ? 'OUT' : 'IN'
                dispatch(amountAction(item.equivalentData.limit.amount))
                dispatch(amountSideAction(side))
                if (state.useAllFunds) {
                    dispatch(sendAllAction(false))
                }

                if (newFlow) {
                    findButtonHandler(event, item.equivalentData.limit.amount, side)
                    return
                }

                findButtonHandler(item.equivalentData.limit.amount, side)
            }
        )
    }
}

export const getOutDestinationAddress = (state) => {
    if (!state.newOutDestination) {
        const obj = findOutDestination(state.walletData, state.outCurrency.code, state.cashbackToken)
        dispatch(outDestinationAction(obj?.outDestination || null, obj?.walletName || null))

        if (!isToken(state.walletData, state.outCurrency.code)) {
            return null
        }

        const network = getTokenNet(state.walletData, state.outCurrency.code)
        Log.info(`V3.providerItem.helper getOutDestinationAddress network ${network}`)

        if (network) {
            const type = state.outCurrency.code === 'USDT' ? 'LEGACY' : 'SEGWIT'
            const data = findOutDestination(state?.walletData, network, state?.cashbackToken, type)
            const outDestination = data?.outDestination || ''
            const walletName = data?.walletName || ''
            Log.info(`V3.providerItem.helper getOutDestinationAddress outDestination ${outDestination}`)
            Log.info(`V3.providerItem.helper getOutDestinationAddress walletName ${walletName}`)
            dispatch(outDestinationAction(outDestination, walletName))
            return outDestination
        }

        return false
    } else {
        return true
    }
}

export const nextStep = async (
    item,
    urlPath,
    loader,
    setLoad,
    providersArray,
    setLoader,
    setVariantsArray,
    setLoading,
    pathUrl,
    findButtonHandler,
    event,
    newFlow
) => {
    const state = store.getState().mobileMarket

    const outDestinationApprove = getOutDestinationAddress(state)

    if (
        state.trusteeWallet &&
        ((state.inCurrency.type === 'CRYPTO' && typeof state.inCurrency.maskHidden !== 'undefined' && state.inCurrency.maskHidden) ||
            (state.outCurrency.type === 'CRYPTO' &&
                typeof state.outCurrency.maskHidden !== 'undefined' &&
                state.outCurrency.maskHidden &&
                !outDestinationApprove))
    ) {
        showModal(
            {
                type: 'INFO_MODAL',
                icon: 'WARNING',
                header: strings('mobileMarket.modal.warning'),
                description: strings('mobileMarket.modal.coinDisabled')
            }
            // , () => {
            //     if (state.inCurrency.maskHidden && state.outCurrency.maskHidden) {
            //         window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ needActivateCurrency: [state.inCurrency.code, state.outCurrency.code] }))
            //     } else if (state.inCurrency.maskHidden) {
            //         window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ needActivateCurrency: [state.inCurrency.code] }))
            //     } else if (state.outCurrency.maskHidden) {
            //         window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ needActivateCurrency: [state.outCurrency.code] }))
            //     }
            //     localStorage.setItem('amountData', JSON.stringify({ amount: state.amount, side: state.amountSide }))
            // }
        )
        return
    }

    dispatch(variantsAction(providersArray))
    dispatch(savePositionAction(true))

    const activeExchangeWay = state.allExchangeWays.find((way) => way.exchangeWayId === item?.exchangeWayId) || null

    if (
        (activeExchangeWay.exchangeWayType === 'BUY' || activeExchangeWay.exchangeWayType === 'SELL') &&
        !activeExchangeWay.skipPaymentDetailsScreen
    ) {
        if (!state.paymentData) {
            showModal(
                {
                    type: 'YES_NO_MODAL',
                    icon: 'WARNING',
                    header: strings('mobileMarket.modal.attention'),
                    description: strings('mobileMarket.modal.notCard'),
                    firstBtn: strings('mobileMarket.modal.addDetails'),
                    secondBtn: strings('mobileMarket.modal.late')
                },
                () => {
                    window.history.back()
                    hideAnimation('providerList', hideSearchScreenModal)
                    hideModal()
                    setTimeout(() => {
                        pathUrl(`payment-details/management?action=ADD&type=${state.payway}`)
                    }, 250)
                    return
                }
            )
            return false
        }
    }

    if (activeExchangeWay?.minWalletVersion) {
        if (state.versionNumber * 1 < activeExchangeWay?.minWalletVersion * 1) {
            showModal({
                type: 'INFO_MODAL',
                icon: 'WARNING',
                header: strings('mobileMarket.modal.attention'),
                description: strings('mobileMarket.modal.disableProvider')
            })
            return
        }
    }

    if (activeExchangeWay.exchangeWayType !== 'BUY') {
        const newState = store.getState().mobileMarket

        let account
        account =
            newState.inCurrency && newState.inCurrency.type === 'CRYPTO'
                ? newState.walletData.wallets
                      .find((wallet) => wallet.cashbackToken === newState.cashbackToken)
                      .accounts.find((account) => account.currencyCode === newState.inCurrency.code)
                : null

        if (activeExchangeWay.isNeedApprove) {
            if (isToken(newState.walletData, item.inCurrency.code)) {
                setLoad(true)
                const providerLink = `/${item.providerName.toLowerCase()}/check-approve?amount=${item.equivalentData.inAmount}&currencyCode=${
                    item.inCurrency.code
                }&tokenOwner=${account.address}&cashbackToken=${newState.cashbackToken}&signMessage=${newState.sign.message}&signMessageHash=${
                    newState.sign.messageHash
                }&signature=${newState.sign.signature}`
                Log.info(`V3.providerItem.nextStep checkApprove link ${JSON.stringify(providerLink)}`)

                let resIsNeedApprove
                try {
                    resIsNeedApprove = await axiosRequest(providerLink, strings('mobileMarket.modal.serverRespond'))
                    console.log(resIsNeedApprove)
                    Log.info(`V3.providerItem.nextStep checkApprove result ${JSON.stringify(resIsNeedApprove)}`)
                } catch (e) {
                    setLoad(false)
                }

                if (resIsNeedApprove) {
                    setTimeout(() => setLoad(false), 3e2)
                    if (resIsNeedApprove.isNeedApprove) {
                        showModal(
                            {
                                type: 'YES_NO_MODAL',
                                icon: 'WARNING',
                                header: strings('mobileMarket.modal.attention'),
                                description: strings('mobileMarket.modal.isNeedApprove', {
                                    providerName: getProviderName(item.providerName),
                                    currencyCode: item.inCurrency.symbol
                                })
                            },
                            () => {
                                hideModal()
                                setLoad(true)
                                const checkApproveData = {
                                    data: resIsNeedApprove.dexOrderData,
                                    currencyCode: item.inCurrency.code,
                                    provider: item.providerName
                                }
                                window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ checkApproveData }))
                                setLoad(false)
                                return
                            }
                        )
                        return
                    }
                }
            }
        }

        if (newState.inCurrency.type === 'CRYPTO' && newState.inCurrency.type !== 'FIAT' && account) {
            const currencyArray = ['BTC', 'ETH', 'TRX', 'XRP', 'XMR', 'DOGE', 'BSV', 'BCH', 'BTG', 'XVG']

            const neWState = store.getState().mobileMarket

            if (!config.showError) {
                if (!newState.useAllFunds && !neWState.move) {
                    if (Number(account?.prettyBalanceRaw || account?.balance) + Number(account.unconfirmed ? account.unconfirmed : 0) <= 0) {
                        showModal({
                            type: 'INFO_MODAL',
                            icon: 'WARNING',
                            header: strings('mobileMarket.modal.sorry'),
                            description: strings('mobileMarket.noMoney', {
                                amount: neWState.amount + ' ' + neWState.inCurrency.symbol,
                                balance:
                                    Number(account?.prettyBalanceRaw || account.balance) +
                                    Number(account.unconfirmed ? account.unconfirmed : 0) +
                                    ' ' +
                                    neWState.inCurrency.symbol
                            })
                        })

                        return false
                    }
                    if (currencyArray.includes(newState.inCurrency.code)) {
                        CACHE_DIFF_PERCENT_BALANCE =
                            (Number(item.equivalentData.inAmount) / (account?.prettyBalanceRaw || account.balance) - limitPercent) * 1
                        CACHE_LOG_AMOUNT_TO_BALANCE =
                            `${newState.outCurrency.type === 'CRYPTO' ? 'EXC' : 'SELL'}/MainV3 checkAlmostAll params` +
                            JSON.stringify({
                                amount: item.equivalentData.inAmount,
                                limitPercent,
                                CACHE_DIFF_PERCENT_BALANCE,
                                useAll: newState.useAllFunds
                            })

                        Log.info(
                            `V3.providerItem.nextStep useAllFunds ${JSON.stringify({ CACHE_DIFF_PERCENT_BALANCE, CACHE_LOG_AMOUNT_TO_BALANCE })}`
                        )

                        if (CACHE_DIFF_PERCENT_BALANCE > 0) {
                            setTimeout(
                                async () =>
                                    showModal(
                                        {
                                            type: 'YES_NO_MODAL',
                                            icon: 'WARNING',
                                            header: strings('mobileMarket.modal.destinationHeader'),
                                            description: strings('mobileMarket.modal.sendAll', { coin: newState.inCurrency.code }),
                                            noCallback: () => {
                                                const data = {
                                                    checkAlmostAllClickedYes: false,
                                                    checkAlmostAll: true,
                                                    LogcheckAlmostAll: CACHE_LOG_AMOUNT_TO_BALANCE
                                                }
                                                Log.info(`V3.providerItem.nextStep 5% modal click no -> data ${JSON.stringify(data)}`)
                                                dispatch(sendOriginalData(data, true))

                                                hideAnimation('YesNoModal', hideModal)
                                                nextStep(
                                                    item,
                                                    urlPath,
                                                    loader,
                                                    setLoad,
                                                    providersArray,
                                                    setLoader,
                                                    setVariantsArray,
                                                    setLoading,
                                                    pathUrl,
                                                    findButtonHandler,
                                                    event,
                                                    newFlow
                                                )
                                            },
                                            close: () => {
                                                const data = {
                                                    checkAlmostAll: true,
                                                    checkAlmostAllClickedYes: null,
                                                    LogcheckAlmostAll: CACHE_LOG_AMOUNT_TO_BALANCE
                                                }
                                                Log.info(`V3.providerItem.nextStep 5% modal click close -> data ${JSON.stringify(data)}`)
                                                dispatch(sendOriginalData(data))
                                            }
                                        },
                                        () => {
                                            const newState = store.getState().mobileMarket

                                            dispatch(setRocketLoaderAction(false, false))
                                            dispatch(activeExchangeWayAction(null))
                                            setVariantsArray && setVariantsArray(() => [])
                                            setLoader &&
                                                setLoader({
                                                    loaderTotalProviders: 1,
                                                    loaderCounter: 0
                                                })
                                            // setLoading(true)

                                            dispatch(sendAllAction(true))
                                            const data = {
                                                checkAlmostAll: true,
                                                checkAlmostAllClickedYes: true,
                                                limitPercent: limitPercent,
                                                LogcheckAlmostAll: CACHE_LOG_AMOUNT_TO_BALANCE
                                            }
                                            Log.info(`V3.providerItem.nextStep 5% modal click yes data ${JSON.stringify(data)}`)
                                            dispatch(sendOriginalData(data))

                                            const obj = findOutDestination(newState.walletData, newState.inCurrency.code, newState.cashbackToken)

                                            dispatch(needSearchProvidersAction(true))
                                            dispatch(providerLoaderAction(true))

                                            Log.info(
                                                `V3.providerItem.nextStep sendAll (from modal 5%) balance ${newState.inCurrency.code} -> ${newState.outCurrency.code}`
                                            )
                                            hideAnimation('YesNoModal', hideModal)
                                            hideAnimation('providerList', hideSearchScreenModal)
                                            window.ReactNativeWebView &&
                                                window.ReactNativeWebView.postMessage(
                                                    JSON.stringify({
                                                        useAllFunds: {
                                                            currencyCode: newState.inCurrency.code,
                                                            address: obj.outDestination
                                                        }
                                                    })
                                                )
                                        }
                                    ),
                                0
                            )
                            return
                        }
                    }
                }
            }
        }
    }

    let verify, verifyStatus

    if (activeExchangeWay.exchangeWayType === 'BUY') {
        if (activeExchangeWay.isNeedCardVerification && !newFlow) {
            if (state.payway === 'CARD') {
                verify = state.paymentData.cardVerificationJson || null
                if (typeof verify !== 'undefined' && verify && verify !== 'false' && verify !== 'undefined' && verify !== 'null') {
                    if (JSON.parse(verify).data) {
                        verifyStatus =
                            typeof JSON.parse(verify).data.verificationStatus !== 'undefined'
                                ? JSON.parse(verify).data.verificationStatus
                                : 'CANCELED'
                    } else {
                        verifyStatus =
                            typeof JSON.parse(verify).verificationStatus !== 'undefined' ? JSON.parse(verify).verificationStatus : 'CANCELED'
                    }
                    if (
                        (typeof verifyStatus === 'undefined' || verifyStatus === 'undefined' || verifyStatus === undefined) &&
                        JSON.parse(verify).errorMsg
                    ) {
                        verifyStatus = 'CANCELED'
                    }
                } else {
                    if (verify === null || verify === 'null' || verify === 'undefined' || verify === undefined || !verify || verify === 'false') {
                        verifyStatus = 'CANCELED'
                    } else {
                        verifyStatus = verify
                    }
                }
            }
            Log.info(
                `V3.providerItem.nextStep item ${JSON.parse(JSON.stringify(item))} -> verifyStatus for paymentDateils ${JSON.stringify(verifyStatus)}`
            )

            if (verifyStatus === 'CANCELED' || verifyStatus === 'WAIT_FOR_PHOTO') {
                dispatch(variantsAction(providersArray))

                showScreenModal(
                    {
                        type: 'CARD_MODAL',
                        data: state.paymentData
                    },
                    async () => {
                        // const state = store.getState().mobileMarket
                        // window.ReactNativeWebView.postMessage(JSON.stringify({
                        //     takePhoto: {
                        //         number: state.paymentData.number,
                        //         countryCode: state.paymentData.countryCode
                        //     }
                        // }))
                        const state = store.getState().mobileMarket
                        pathUrl(`payment-details?showStatus=true&loader=true&camera=${state.cameraResult}`)
                        hideScreenModal()
                    }
                )
                return false
            } else if (verifyStatus === 'PENDING') {
                dispatch(variantsAction(providersArray))

                showModal(
                    {
                        type: 'YES_NO_MODAL',
                        icon: 'WARNING',
                        header: strings('mobileMarket.modal.destinationHeader'),
                        description: strings('mobileMarket.modal.cardStatus'),
                        noCallback: () => {
                            hideAnimation('YesNoModal', hideModal)
                        }
                    },
                    () => {
                        pathUrl(`payment-details?showStatus=true&id=${item.exchangeWayId}`)
                    }
                )

                return false
            }
        }
    }

    dispatch(providerAction(item, activeExchangeWay.exchangeWayType))
    if (item.rateType === 'FIXED' && activeExchangeWay.exchangeWayType === 'EXCHANGE' && !newFlow) {
        const youHave = state.walletData.wallets
            .find((wallet) => wallet.cashbackToken === state.cashbackToken)
            .accounts.find((account) => account.currencyCode === state.inCurrency.code)
        const haveCoin = state.walletData.wallets
            .find((wallet) => wallet.cashbackToken === state.cashbackToken)
            .accounts.find((account) => account.currencyCode === state.outCurrency.code)

        const balance =
            youHave && (youHave?.prettyBalanceRaw || youHave.balance)
                ? (Number(youHave?.prettyBalanceRaw || youHave.balance) + Number(youHave.unconfirmed ? youHave.unconfirmed : 0)).toString()
                : '0'
        if (youHave) Log.info(`V3.providerItem.nextStep balance unconfirmed ${youHave.unconfirmed ? youHave.unconfirmed : 0}`)
        Log.info(`V3.providerItem.nextStep balance ${balance}`)

        if (
            haveCoin &&
            typeof haveCoin.balanceHodl !== 'undefined' &&
            (youHave?.prettyBalanceRaw || youHave?.balance) === 0 &&
            state.outCurrency &&
            (Number(item.equivalentData.outAmount) < haveCoin?.balanceHodl || 0)
        ) {
            showModal({
                type: 'INFO_MODAL',
                icon: 'WARNING',
                header: strings('mobileMarket.modal.hodlHeader', {
                    coin: `${getCurrencyName(state.outCurrency.code) || state.outCurrency.name} (${state.outCurrency.symbol})`
                }),
                description: strings('mobileMarket.modal.balanceHodl', { coin: state.outCurrency.symbol, balance: haveCoin.balanceHodl })
            })
            return
        }

        if (config.balanceCheck && Number(state.amount) > Number(balance)) {
            showModal({
                type: 'INFO_MODAL',
                icon: 'WARNING',
                header: strings('mobileMarket.modal.sorry'),
                description: strings('mobileMarket.noMoney', {
                    amount: state.amount + ' ' + state.inCurrency.symbol,
                    balance: balance + ' ' + state.inCurrency.symbol
                })
            })

            return false
        }

        setLoad && setLoad(true)

        const order = await createOrder(item, loader, setLoad)

        dispatch(createdOrderAction(order))

        if (order) {
            urlPath('pre-check')
            if (state.skipProvidersScreen) {
                dispatch(propsModalAction(true))
            }
        } else {
            setLoad && setLoad(false)
            return
        }
    } else {
        if (typeof activeExchangeWay.isNeedKYC !== 'undefined' && activeExchangeWay.isNeedKYC && !newFlow) {
            const email = localStorage.getItem('trusteeEmail') ? localStorage.getItem('trusteeEmail') : null
            // alert(email)
            Log.info(`V3.providerItem.nextStep email ${JSON.stringify(email)}`)

            if (!email) {
                showSearchScreenModal({
                    type: 'KYC_MODAL'
                })
                return false
            } else {
                setLoad(true)

                try {
                    const data = await getStatusKYC(email)
                    // alert(JSON.stringify(data))
                    Log.info(`V3.providerItem.nextStep getStatusKYC result ${JSON.stringify(data)}`)

                    if (
                        data &&
                        typeof data.userId !== 'undefined' &&
                        data.userId &&
                        typeof data.status !== 'undefined' &&
                        data.status !== 'NOT_REGISTERED'
                    ) {
                        dispatch(emailAction(data.userId))
                    }

                    if (data && typeof data.status !== 'undefined' && data.status) {
                        if (data.status === 'DONE') {
                            urlPath('pre-check')
                            hideSearchScreenModal()
                            if (state.skipProvidersScreen) {
                                dispatch(propsModalAction(true))
                            }
                        } else {
                            urlPath('verification')
                        }
                    }
                } catch (e) {
                    Log.error(`V3.providerItem.nextStep error ${JSON.stringify(e)}`)
                    Log.captureError(`V3.providerItem.nextStep error`)

                    showModal({
                        type: 'INFO_MODAL',
                        icon: 'WARNING',
                        header: strings('mobileMarket.modal.sorry'),
                        description: config.showError ? JSON.stringify(e) : strings('mobileMarket.modal.serverRespond')
                    })
                    return
                }
            }
        } else {
            if (newFlow) {
                hideAnimation('providerList', hideSearchScreenModal)
            } else {
                urlPath('pre-check')
            }

            if (state.skipProvidersScreen) {
                dispatch(propsModalAction(true))
            }
        }
    }
}

export const filterItemVariants = (item) => {
    // if (!variantsArray || (variantsArray && !variantsArray.length)) return []
    if (!item) return

    const localState = store.getState().mobileMarket

    if (!localState.payway) {
        return { ...item, valid: true }
    }

    if (localState.inCurrency.type === 'CRYPTO' && localState.outCurrency.type === 'CRYPTO') {
        return { ...item, valid: true }
    }

    if (!localState.paymentData) {
        return { ...item, valid: true }
    }

    if (localState.outCurrency.type === 'FIAT') {
        switch (localState.payway) {
            case 'CARD':
                return { ...item, valid: cardValid(localState.paymentData, item) }
            case 'PERFECT_MONEY':
            case 'ADVCASH':
            case 'CAPITALIST':
            case 'MOBILE_PHONE':
                return { ...item, valid: localState.paymentData.currency === localState.outCurrency.code }
            default:
                return { ...item, valid: true }
        }
    }

    if (localState.inCurrency.type === 'FIAT') {
        switch (localState.payway) {
            case 'CARD':
                return { ...item, valid: cardValid(localState.paymentData, item) }
            case 'PERFECT_MONEY':
            case 'ADVCASH':
            case 'CAPITALIST':
            case 'MOBILE_PHONE':
                return { ...item, valid: localState.paymentData.currency === localState.inCurrency.code }
            default:
                return { ...item, valid: true }
        }
    }
}

const cardValid = (item, variant) => {
    if (!item) return

    const state = store.getState().mobileMarket

    let exchangeWays = state.allExchangeWays
    exchangeWays = state.inCurrency.code ? exchangeWays.filter((way) => way.inCurrency.code === state.inCurrency.code) : exchangeWays
    exchangeWays = state.outCurrency.code ? exchangeWays.filter((way) => way.outCurrency.code === state.outCurrency.code) : exchangeWays

    let wayCountry = exchangeWays.filter((way) => {
        switch (way.exchangeWayType) {
            case 'BUY':
                return way.inPaywayCode
            case 'SELL':
                return way.outPaywayCode
            default:
                return null
        }
    })

    if (typeof wayCountry === 'undefined') return
    //  || !wayCountry) return false

    wayCountry = wayCountry.find((way) => way.exchangeWayId === variant.exchangeWayId)

    const name = wayCountry?.provider || ''

    if (state.payway === null || !wayCountry) return false

    wayCountry = wayCountry.supportedCountriesData ? wayCountry.supportedCountriesData : null

    let validCountry = false

    if (state.payway === 'CARD') {
        if (state.outCurrency && state.outCurrency.code === 'UAH') {
            const status = item.cardCheckStatus || null

            if (status && typeof status !== 'undefined' && status !== 'SUCCESS') {
                return status === 'undefined' ? true : validCountry
            }
        }

        if (wayCountry === null) {
            validCountry = true
        } else if (wayCountry !== null) {
            if (wayCountry.type === 'EQUALS' && wayCountry.countryCodes.includes(item.countryCode)) {
                validCountry = true
            } else if (wayCountry.type === 'NOT_EQUALS' && !wayCountry.countryCodes.includes(item.countryCode)) {
                validCountry = true
            }
        }
        // Log.info(`V3.firstScreen.cardValid name ${name} && item ${JSON.stringify(item)} -> validCountry ${validCountry}`)
        return validCountry
    } else {
        validCountry = true
        // Log.info(`V3.firstScreen.cardValid name ${name} && item ${JSON.stringify(item)} -> validCountry ${validCountry}`)
        return validCountry
    }
}

const createOrder = async (item, loader, setLoad) => {
    const state = store.getState().mobileMarket

    setLoad(true)

    const body = {
        inAmount: item.equivalentData.inAmount,
        outAmount: item.equivalentData.outAmount,
        exchangeWayId: item.exchangeWayId,
        amountSide: state.amountSide,
        rateId: item.rateType === 'FIXED' && item.equivalentData.rateData ? item.equivalentData.rateData.rateId : null,
        outDestination: state.outDestination,
        outDestinationTag: state.outDestinationTag,
        deviceToken: state.walletData.deviceToken,
        uniqueParams: {
            refundAddress:
                typeof state.walletData.wallets
                    .find((wallet) => wallet.cashbackToken === state.cashbackToken)
                    .accounts.find((account) => account.currencyCode === state.inCurrency.code).address === 'string'
                    ? state.walletData.wallets
                          .find((wallet) => wallet.cashbackToken === state.cashbackToken)
                          .accounts.find((account) => account.currencyCode === state.inCurrency.code).address
                    : state.walletData.wallets
                          .find((wallet) => wallet.cashbackToken === state.cashbackToken)
                          .accounts.find((account) => account.currencyCode === state.inCurrency.code)
                          .address.find((address) => address.type === 'SEGWIT').address
        },
        cashbackToken: state.cashbackToken,
        comment: state.comment,
        equivalentData: item.equivalentData,
        sign: state.sign,
        paymentDetailsData: {}
    }

    if (state.newOutDestination) {
        body.invoiceData = {
            isExternalOutDestination: state.newOutDestination
        }
    }

    if (state.activeExchangeWay !== 'BUY') {
        const { outDestination } = findOutDestination(state.walletData, state.inCurrency.code, state.cashbackToken)
        body.paymentDetailsData = {
            ...body.paymentDetailsData,
            paymentDetails: outDestination
        }
    }

    Log.info(`V3.providerItem.createOrder body ${JSON.stringify(body)}`)

    const orderLink = `${config.backendUrl}/order/create-order?cashbackToken=${state.cashbackToken}&createdFrom=WALLET`

    try {
        const response = await axios.post(orderLink, body).catch((error) => {
            console.log(JSON.stringify(error.response.data))
            Log.warning(`V3.providerItem.createOrder error ${JSON.stringify(error.response.data)}`)
            Log.captureError(`V3.providerItem.createOrder error`)
            if (error?.response?.data?.errorCode === 'NOT_PROFITABLE') {
                showModal({
                    type: 'INFO_MODAL',
                    icon: 'WARNING',
                    header: strings('mobileMarket.modal.sorry'),
                    description: strings('mobileMarket.modal.timeOutOne') + deltaTime() + ' ' + strings('mobileMarket.modal.timeOutTwo')
                })
                return false
            } else if (error?.response?.data?.errorCode === 'EXPIRED_RATE') {
                showModal({
                    type: 'INFO_MODAL',
                    icon: 'WARNING',
                    header: strings('mobileMarket.modal.sorry'),
                    description: strings('mobileMarket.modal.rateId')
                })
                return false
            } else {
                throw new Error(error.response.data.message)
            }
        })

        const orderData = response.data
        Log.info(`V3.providerItem.createOrder response.data ${JSON.stringify(response.data)}`)
        setLoad(false)
        return orderData
    } catch (error) {
        // console.log(JSON.stringify(error))
        Log.warning(`V3.providerItem.createOrder response.data error ${JSON.stringify(error.message)}`)
        Log.captureError(`V3.providerItem.createOrder error`)

        showModal(
            {
                type: 'INFO_MODAL',
                icon: 'WARNING',
                header: strings('mobileMarket.modal.attention'),
                description: config.showError ? error.message.toString() : strings('mobileMarket.modal.createOrderError')
            },
            () => {
                hideModal()
            }
        )
        return false
    }
}

const deltaTime = () => {
    const state = store.getState().mobileMarket

    const timeNow = new Date().getTime()
    const time = state.equivalentTime

    const delta = timeNow - time

    const equivalentTime = timed(delta)

    return equivalentTime
}

export const filterIsActive = (variantsArray, allExchangeWays) => {
    if (!variantsArray || !variantsArray.length) return []

    const idWays = allExchangeWays.map((way) => way.exchangeWayId)

    const newVariants = variantsArray.filter((way) => idWays.includes(way.exchangeWayId))
    // const commingSoon = variantsArray.filter(way => way.equivalentData ? way.equivalentData.errorCode === 'COMING_SOON' : false)
    return newVariants
}
