import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import config from '../../config/config'

import { getThemeModeStyles } from '../../theme/useDarkMode'
import ButtonDiv from './btn.css'

const Button = (props) => {
    const { style, handler, condition, id, skeleton } = props

    const theme = getThemeModeStyles()

    const localHandler = (e) => {
        if (condition) {
            if (e.detail > 1) return
            handler(e)
        } else {
            return null
        }
    }

    return (
        <ButtonDiv bgColor={theme.bottomNav.bg} color={theme.bottomNav.color} style={{ ...style }} onClick={localHandler}>
            {skeleton ? (
                <SkeletonTheme baseColor='#999999' highlightColor={theme.main.skeletonLoader}>
                    <Skeleton count={1} height={50} duration={config.skeletonAnimationDuration} style={{ borderRadius: 10 }} />
                </SkeletonTheme>
            ) : (
                <div id={id} className={condition ? 'main' : 'fail'}>
                    {props.children}
                </div>
            )}
        </ButtonDiv>
    )
}

export default Button
