import React, { useCallback } from 'react'

import Line from 'components/Line/line'
import PaywayListItem from 'components/PaywayModalListItem/paywayItem'
import regionForCash from 'components/json/regionForCash.json'

import { strings } from 'i18n'
import { hideScreenModal } from 'redux/AppStores/ScreenModalStore/action'
import hideAnimation from 'services/hideAnimation'

import { BodyWrapper } from '../wrapper.css'

const RegionModal = (props) => {
    const { theme, side, data } = props

    const regions = regionForCash.filter((item) => item.countryCode !== 'RUS')

    const countrySelectHandler = useCallback((side) => {
        hideAnimation(`selectModal-${side.toString()?.toLowerCase()}`, hideScreenModal)
        return
    }, [])

    return (
        <>
            <BodyWrapper style={{ marginTop: 54 }} labelColor={theme.topNav.colorIcon}>
                {regions.map((item, index) => {
                    const tmpItem = {
                        ...item,
                        country: strings(`mobileMarket.cash.${item.region.toLowerCase()}`)
                    }

                    return (
                        <>
                            <PaywayListItem
                                key={index}
                                item={tmpItem}
                                index={index}
                                side={side}
                                checked={data.region === item.region}
                                active={true}
                                handler={countrySelectHandler}
                                country={true}
                                theme={theme}
                                data={data}
                            />
                            {regions.length === index + 1 ? <div className='empty'></div> : <Line width='90%' />}
                        </>
                    )
                })}
            </BodyWrapper>
        </>
    )
}

export default React.memo(RegionModal)
