export default {
    UAH: 'Hryvnia',
    RUB: 'Ruble',
    USD: 'US dollar',
    EUR: 'Euro',
    GBP: 'Pound sterling',
    TRY: 'Turkish lira',
    KZT: 'Tenge',
    AUD: 'Australian Dollar',
    BGN: 'Bulgarian Lev',
    BRL: 'Brazilian Real',
    CAD: 'Canadian Dollar',
    CHF: 'Swiss Franc',
    CNY: 'Chinese Yuan',
    COP: 'Colombian Peso',
    CZK: 'Czech Koruna',
    DKK: 'Danish Krone',
    DOP: 'Dominican Peso',
    EGP: 'Egyptian Pound',
    HKD: 'Hong Kong Dollar',
    HRK: 'Croatian Kuna',
    IDR: 'Indonesian Rupiah',
    ILS: 'Israeli New Sheke',
    JOD: 'Jordanian Dinar',
    JPY: 'Japanese Yen',
    KES: 'Kenyan Shilling',
    KRW: 'South Korean Won',
    KWD: 'Kuwaiti Dinar',
    LKR: 'Sri Lankan Rupee',
    MAD: 'Moroccan Dirham',
    MXN: 'Mexican Peso',
    MYR: 'Malaysian Ringgit',
    NGN: 'Nigerian Naira',
    NOK: 'Norwegian Krone',
    NZD: 'New Zealand Dollar',
    OMR: 'Omani Rial',
    PEN: 'Peruvian Sol',
    PKR: 'Pakistani Rupee',
    PLN: 'Polish Złoty',
    RON: 'Romanian Leu',
    SEK: 'Swedish Krona',
    SGD: 'Singapore Dollar',
    THB: 'Thai Baht',
    TWD: 'Taiwan Dollar',
    VND: 'Vietnamese Dong',
    ZAR: 'South African Rand'
}
