export default function message() {
    const onMessage = ` if (true) {
        if (e.nativeEvent.data.indexOf('paycore.io') !== -1 && e.nativeEvent.data.indexOf('<section class="error-message') !== -1) {
            CACHE_IS_ERROR = true
        } else {
        }
        if (e.nativeEvent.data.indexOf('WebView Loaded') !== -1) { 
            that.handleState({
                status: 'SUCCESS'
            })
            // setExchangeStatus('', that.state.orderHash, 'OPEN')
        } else if (e.nativeEvent.data.indexOf('WebView Agent OPEN=') !== -1) {
            const link = e.nativeEvent.data.substr(19)
            Linking.canOpenURL(link).then(supported => {
                if (supported) {
                    Linking.openURL(link)
                } else {
                    console.log('error link', link)
                }
            })
        }
    }`
    return onMessage
}

// https://wallet.advcash.com/sci/paymentRequest.jsf
