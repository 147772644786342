import axios from 'axios'
import config from '../../config/config'
import store from '../../redux'
import { addPaymentDetailsArrayAction, paymentDataAction } from '../../redux/AppStores/MobileMarket/action'

const { dispatch } = store

const updateCardsDaemon = async (params = {}) => {
    const state = store.getState().mobileMarket

    let card =
        typeof state.walletData.cards !== 'undefined'
            ? state.walletData.cards.filter((way) => way.type === 'visa' || way.type === 'mastercard' || way.type === 'mir' || way.type === 'maestro')
            : []
    const cardNumbers = card.length ? card.map((item) => item.number) : []
    let res

    if (typeof params.cardNumber === 'undefined') {
        try {
            const response = await axios.get(`${config.backendUrl}/payment-details/validation-status?cardNumber=${cardNumbers.join()}`)
            res = response ? response.data : null
        } catch (e) {
            console.log(JSON.stringify(e))
        }
    } else if (typeof params.cardNumber !== 'undefined') {
        try {
            const response = await axios.get(`${config.backendUrl}/payment-details/validation-status?cardNumber=${params.cardNumber}`)
            res = response ? response.data : null
        } catch (e) {
            console.log(JSON.stringify(e))
        }
    }

    try {
        if (typeof res !== 'undefined' && res) {
            console.log('res daemon', res)
            if (typeof card !== 'undefined' && card.length > 1) {
                card = card.map((item) => {
                    if (item.number === params.cardNumber) {
                        const tempCards = res
                        let data =
                            typeof item.cardVerificationJson !== 'undefined' && item.cardVerificationJson
                                ? JSON.parse(item.cardVerificationJson)
                                : null
                        if (data.updatedAt === tempCards.updatedAt) {
                            return {
                                ...item,
                                cardVerificationJson: JSON.stringify(tempCards)
                            }
                        } else {
                            let dataNew = {
                                verificationStatus: tempCards.verificationStatus
                            }
                            if (typeof tempCards.message !== 'undefined') {
                                dataNew.message = tempCards.message
                            }
                            if (typeof tempCards.firstName !== 'undefined') {
                                dataNew.firstName = tempCards.firstName
                            }
                            if (typeof tempCards.lastName !== 'undefined') {
                                dataNew.lastName = tempCards.lastName
                            }
                            if (typeof tempCards.updatedAt !== 'undefined') {
                                dataNew.updatedAt = tempCards.updatedAt
                            }
                            return {
                                ...item,
                                cardVerificationJson: JSON.stringify(dataNew)
                            }
                        }
                    } else {
                        return item
                    }
                })
            } else if (typeof card !== 'undefined' && card.length === 1) {
                let data =
                    typeof card[0].cardVerificationJson !== 'undefined' && card[0].cardVerificationJson
                        ? JSON.parse(card[0].cardVerificationJson)
                        : null
                if (data.updatedAt === res.updatedAt) {
                    card = [
                        {
                            ...card[0],
                            cardVerificationJson: JSON.stringify(res)
                        }
                    ]
                } else {
                    let dataNew = {
                        verificationStatus: res.verificationStatus
                    }
                    if (typeof res.message !== 'undefined') {
                        dataNew.message = res.message
                    }
                    if (typeof res.firstName !== 'undefined') {
                        dataNew.firstName = res.firstName
                    }
                    if (typeof res.lastName !== 'undefined') {
                        dataNew.lastName = res.lastName
                    }
                    if (typeof res.updatedAt !== 'undefined') {
                        dataNew.updatedAt = res.updatedAt
                    }

                    card = [
                        {
                            ...card[0],
                            cardVerificationJson: JSON.stringify(dataNew)
                        }
                    ]
                }
            }
        }

        if (res && (res.verificationStatus === 'SUCCESS' || res.verificationStatus === 'CANCELED')) {
            dispatch(
                addPaymentDetailsArrayAction([
                    ...state.walletData.cards.filter(
                        (way) => way.type !== 'visa' && way.type !== 'mastercard' && way.type !== 'mir' && way.type !== 'maestro'
                    ),
                    ...card
                ])
            )
            if (state?.paymentData?.number === params?.cardNumber) {
                dispatch(paymentDataAction(card.find((item) => item.number === params.cardNumber)))
            }
            return
        } else if (res && (res.verificationStatus !== 'SUCCESS' || res.verificationStatus !== 'CANCELED')) {
            dispatch(
                addPaymentDetailsArrayAction([
                    ...state.walletData.cards.filter(
                        (way) => way.type !== 'visa' && way.type !== 'mastercard' && way.type !== 'mir' && way.type !== 'maestro'
                    ),
                    ...card
                ])
            )
            if (state?.paymentData?.number === params?.cardNumber) {
                dispatch(paymentDataAction(card.find((item) => item.number === params.cardNumber)))
            }
            setTimeout(async () => await updateCardsDaemon({ cardNumber: params.cardNumber }), 10e3)
        }
    } catch (e) {
        console.log('e', JSON.stringify(e))
        return false
    }
}

export default updateCardsDaemon
