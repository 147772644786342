import { GET_HISTORY_ACTION, SET_ORDER, SET_FINAL_ORDER, RELOAD_ORDERS, REFRESH_LOADER, HISTORY_SEARCH_DATA } from '../type'

const INIT_STATE = {
    currentPage: null,
    orders: [],
    pages: null,
    serverTime: null,
    orderData: null,
    finalOrderData: null,

    reloadOrders: null,

    refreshLoader: false,

    searchData: null
}

const orderHistoryReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_HISTORY_ACTION:
            return {
                ...state,
                currentPage: action.currentPage,
                orders: action.orders,
                pages: action.pages,
                serverTime: action.serverTime
            }
        case SET_ORDER:
            return {
                ...state,
                orderData: action.orderData
            }
        case SET_FINAL_ORDER:
            return {
                ...state,
                finalOrderData: action.finalOrderData
            }
        case RELOAD_ORDERS:
            return {
                ...state,
                reloadOrders: action.reloadOrders
            }
        case REFRESH_LOADER:
            return {
                ...state,
                refreshLoader: action.refreshLoader
            }
        case HISTORY_SEARCH_DATA:
            return {
                ...state,
                searchData: action.searchData
            }

        default:
            return {
                ...state
            }
    }
}

export default orderHistoryReducer
