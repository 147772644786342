import { GET_HISTORY_ACTION, SET_ORDER, SET_FINAL_ORDER, RELOAD_ORDERS, REFRESH_LOADER, HISTORY_SEARCH_DATA } from '../type'

export function getHistoryOrderAction(currentPage, orders, pages, serverTime) {
    return {
        type: GET_HISTORY_ACTION,
        currentPage,
        orders,
        pages,
        serverTime
    }
}

export function setOrderAction(orderData) {
    return {
        type: SET_ORDER,
        orderData
    }
}

export function setFinalOrderAction(finalOrderData) {
    return {
        type: SET_FINAL_ORDER,
        finalOrderData
    }
}

export function reloadOrdersAction(reloadOrders) {
    return {
        type: RELOAD_ORDERS,
        reloadOrders
    }
}

export function refreshLoaderAction(refreshLoader) {
    return {
        type: REFRESH_LOADER,
        refreshLoader
    }
}

export function historySearchDataAction(searchData) {
    return {
        type: HISTORY_SEARCH_DATA,
        searchData
    }
}
