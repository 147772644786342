import FiatCurrencyCode from '../components/currencies/fiat/fiatCurrencyCode'

import CurrencyList from 'currency-list'
import i18n from '../i18n'
import CryptoCurrencies from '../components/currencies/crypto/CurrencyDict'

const blockchainDict = {
    ETHEREUM: 'ETH',
    TRON: 'TRX',
    BNB: 'BNB_SMART',
    BITCOIN: 'BTC',
    SOLANA: 'SOL',
    MATIC: 'MATIC',
    WAVES: 'WAVES'
}

export function getCurrencyName(code) {
    const locale = i18n.language || localStorage.getItem('i18nextLng')
    return typeof CryptoCurrencies[code] !== 'undefined' && CryptoCurrencies[code]
        ? CryptoCurrencies[code].currencyName
        : typeof CurrencyList.get(code, locale) !== 'undefined' && CurrencyList.get(code, locale)
        ? CurrencyList.get(code, locale).name
        : false
    // FiatCurrencyName[code]
}

export function getCurrencyCode(code) {
    return typeof CryptoCurrencies[code] !== 'undefined' && CryptoCurrencies[code]
        ? CryptoCurrencies[code].currencySymbol
        : FiatCurrencyCode[code]
        ? FiatCurrencyCode[code]
        : false
}

export function getTxLink(code) {
    return typeof CryptoCurrencies[code] !== 'undefined' && CryptoCurrencies[code] ? CryptoCurrencies[code].currencyExplorerTxLink : null
}

export function isToken(walletData, code) {
    return typeof CryptoCurrencies?.[code] !== 'undefined' && CryptoCurrencies?.[code]
        ? CryptoCurrencies?.[code]?.currencyType === 'token'
            ? true
            : false
        : walletData?.wallets
              ?.find((wallet) => wallet?.cashbackToken === walletData?.sign?.cashbackToken)
              ?.accounts?.find((account) => account?.currencyCode === code)?.tokenBlockchain || null
}

export function getTokenNet(walletData, code) {
    return typeof CryptoCurrencies?.[code] !== 'undefined' && CryptoCurrencies?.[code]
        ? blockchainDict?.[CryptoCurrencies?.[code]?.tokenBlockchain]
        : blockchainDict?.[
              walletData?.wallets
                  ?.find((wallet) => wallet?.cashbackToken === walletData?.sign?.cashbackToken)
                  ?.accounts?.find((account) => account?.currencyCode === code)?.tokenBlockchain
          ] || null
}
