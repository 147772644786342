import Log from './sentry/Log'

const minMaxLimits = (allExchangeWays, exchangeWaysArray, limitsArray = []) => {
    Log.info(`V3_minMaxLimits init ${JSON.stringify({ exchangeWaysArray, limitsArray })}`)

    if (!allExchangeWays || !exchangeWaysArray || !exchangeWaysArray.length) {
        return { min: 0, max: 0 }
    }

    const exchangeWaysArrayIds = Array.from(new Set(exchangeWaysArray.map((way) => way.exchangeWayId)))
    const allExchangeWaysId = allExchangeWays.filter((way) => exchangeWaysArrayIds.includes(way.exchangeWayId)).map((way) => way.exchangeWayId)

    let limits

    if (!limitsArray) {
        limitsArray = []
    }

    if (limitsArray.length === 0) {
        limits = allExchangeWays.filter((way) => allExchangeWaysId.includes(way.exchangeWayId)).map((way) => way.limits)
    } else {
        limits = limitsArray?.filter((way) => allExchangeWaysId.includes(way.exchangeWayId))
    }

    if (!limits || !limits.length) return {}

    Log.info(`V3_minMaxLimits limits results ${JSON.stringify(limits)}`)

    let minLimits = []
    let maxLimits = []
    if (limits !== null) {
        limits.map((item) => {
            minLimits.push(item.min)
            maxLimits.push(item.max)
        })
    }

    const minMaxLimits = {
        min: Math.min(...minLimits),
        max: Math.min(...maxLimits) === 0 ? 0 : Math.max(...maxLimits)
    }

    if (minMaxLimits.min === Infinity || minMaxLimits.min === -Infinity) {
        minMaxLimits.min = 0
    }

    if (minMaxLimits.max === Infinity || minMaxLimits.max === -Infinity) {
        minMaxLimits.max = 0
    }

    return { ...minMaxLimits, currencySide: limits[0].currencySide }
}

export default minMaxLimits
