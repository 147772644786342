import React from 'react'

import { strings } from 'i18n'

import { isToken } from 'services/getCurrenciesProperty'

import fiatCurrencyName from 'components/currencies/fiat/fiatCurrencyName'
import CryptoCurrencies from 'components/currencies/crypto/CurrencyDict'

import classes from './style.module.css'

import Selector from './Selector/Selector'

const WrapperSelector = (props) => {
    const { inCurrencyModal, outCurrencyModal, paywayModal, showError, showErrorBtn, theme, walletData, userCurrencies } = props

    const { inCurrency, outCurrency, payway } = userCurrencies

    return (
        <div className={classes.selectors__wrapper}>
            <Selector
                width={
                    !inCurrency || !outCurrency
                        ? '48%'
                        : (inCurrency && inCurrency.type === 'FIAT') || (outCurrency && outCurrency.type === 'FIAT')
                        ? '31%'
                        : '48%'
                }
                description={strings('mobileMarket.youGive')}
                descriptionIcon='ARROW_SEND'
                mainIcon={
                    inCurrency ? (CryptoCurrencies[inCurrency.code] || fiatCurrencyName[inCurrency.code] ? inCurrency.code : 'DEFAULT_COIN') : null
                }
                onClick={inCurrencyModal}
                name={inCurrency && inCurrency.type !== 'FIAT' && (!outCurrency || outCurrency.type !== 'FIAT') ? inCurrency.symbol : null}
                select={inCurrency === null}
                type={inCurrency ? inCurrency.type : null}
                showError={!inCurrency ? showError : false}
                showErrorBtn={!inCurrency ? showErrorBtn : false}
                isToken={inCurrency ? isToken(walletData, inCurrency.code) : false}
                code={inCurrency ? inCurrency.code : false}
                theme={theme}
                walletData={walletData}
            />
            <Selector
                width={
                    !outCurrency || !inCurrency
                        ? '48%'
                        : (inCurrency && inCurrency.type === 'FIAT') || (outCurrency && outCurrency.type === 'FIAT')
                        ? '31%'
                        : '48%'
                }
                description={strings('mobileMarket.youGet')}
                descriptionIcon='ARROW_RECEIVE'
                mainIcon={
                    outCurrency
                        ? CryptoCurrencies[outCurrency.code] || fiatCurrencyName[outCurrency.code]
                            ? outCurrency.code
                            : 'DEFAULT_COIN'
                        : null
                }
                onClick={outCurrencyModal}
                name={inCurrency && inCurrency.type !== 'FIAT' && outCurrency && outCurrency.type !== 'FIAT' ? outCurrency.symbol : null}
                select={outCurrency === null}
                type={outCurrency ? outCurrency.type : null}
                showError={!outCurrency ? showError : false}
                showErrorBtn={!outCurrency ? showErrorBtn : false}
                isToken={outCurrency ? isToken(walletData, outCurrency.code) : null}
                code={outCurrency ? outCurrency.code : false}
                theme={theme}
                walletData={walletData}
            />
            {inCurrency && outCurrency && (inCurrency.type === 'FIAT' || outCurrency.type === 'FIAT') && (
                <Selector
                    width='30%'
                    description={strings('mobileMarket.payway')}
                    descriptionIcon='PAYWAY'
                    mainIcon={payway === 'MOBILE_PHONE' ? 'MOBILE' : payway}
                    onClick={paywayModal}
                    select={!payway}
                    type={payway ? 'PAYWAY' : null}
                    showError={inCurrency.type === outCurrency.type ? false : inCurrency && outCurrency && !payway ? showError : false}
                    showErrorBtn={inCurrency.type === outCurrency.type ? false : inCurrency && outCurrency && !payway ? showErrorBtn : false}
                    theme={theme}
                    walletData={walletData}
                />
            )}
        </div>
    )
}

export default WrapperSelector
