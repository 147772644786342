import store from '../../index'
import { SEARCH_SHOW_MODAL, SEARCH_HIDE_MODAL, INIT, CARD_HIDE_MODAL, CARD_SHOW_MODAL } from '../type'

const { dispatch } = store

export function modalScreen() {
    dispatch({
        type: INIT
    })
}

export function showSearchScreenModal(data, callback) {
    dispatch({
        type: SEARCH_SHOW_MODAL,
        data,
        callback
    })
}

export function hideSearchScreenModal() {
    dispatch({
        type: SEARCH_HIDE_MODAL,
        data: null,
        callback: null
    })
}

export function showCardScreenModal(dataCard, callbackCard) {
    dispatch({
        type: CARD_SHOW_MODAL,
        dataCard,
        callbackCard
    })
}

export function hideCardScreenModal() {
    dispatch({
        type: CARD_HIDE_MODAL,
        dataCard: null,
        callbackCard: null
    })
}
