export default function didMount() {
    const componentDidMount = `if (true) {

        that.handleState({
            scriptLoadEnd: tradeWebParam.injectScript,
            navigation: tradeWebParam.navigationState,
            status: 'PENDING',
            currencySelect: tradeWebParam.currencySelect,
            additionalData: {
                close: true
            }
        });

        that.handleStartAnimation();

        const data = {
            link: tradeWebParam.orderData.url,
            orderHash: tradeWebParam.orderData.orderHash
        };

        that.handleState(data);

        try {
            // && !that.state.url.includes('mapi.xpay.com')
            setTimeout(() => {
                if (!that.state.url.includes('pay.receipt-pay.com/cards')
                    && !that.state.url.includes('easybits.io')
                    && !that.state.url.includes('kuna.io/')
                    && !that.state.url.includes('online.contact-sys.com/')
                    && !that.state.url.includes('localhost:4000/backend-invoice?orderHash=')
                    && !that.state.url.includes('exchange.trustee.deals/backend-invoice?orderHash=')
                    && !that.state.url.includes('testexchange.trustee.deals/backend-invoice?orderHash=')
                    && !that.state.url.includes('odysseq.com/order/pay?orderHash=')
                ) {
                    if (that.state.receiptPay) {
                        that.handleState({
                            receiptPay: false,
                            status: 'SUCCESS'
                        })
                    }
                }
            }, 5000)
        } catch (e) {
            console.log(e)
        }
}`

    return componentDidMount
}
