import { SEARCH_SHOW_MODAL, SEARCH_HIDE_MODAL, INIT, CARD_SHOW_MODAL, CARD_HIDE_MODAL } from '../type'

const INITIAL_STATE = {
    searchShow: false,
    data: null,
    callback: null,

    cardShow: false
}

const searchModalScreenReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case INIT:
            return {
                ...state,
                searchShow: false,
                cardShow: false
            }
        case SEARCH_SHOW_MODAL:
            return {
                ...state,
                searchShow: true,
                data: action.data,
                callback: action.callback
            }
        case SEARCH_HIDE_MODAL:
            return {
                ...state,
                searchShow: false,
                data: action.data,
                callback: action.callback
            }
        case CARD_SHOW_MODAL:
            return {
                ...state,
                cardShow: true,
                dataCard: action.dataCard,
                callbackCard: action.callbackCard
            }
        case CARD_HIDE_MODAL:
            return {
                ...state,
                cardShow: false,
                dataCard: action.dataCard,
                callbackCard: action.callbackCard
            }

        default:
            return {
                ...state
            }
    }
}

export default searchModalScreenReducer
