import React, { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import Icon from 'components/Icon/Icon'

import { strings } from 'i18n'

import PaymentBlockDiv from './style.css'

const PaymentBlock = (props) => {
    const { theme, userCurrencies, paymentDetails, paymentData } = props

    const { inCurrency, outCurrency, payway } = userCurrencies

    const history = useHistory()

    // const dispatch = useDispatch()

    const account = useMemo(() => {
        if (payway) {
            return paymentData || paymentDetails[paymentDetails.length - 1] || null
        }
    }, [payway, paymentData, paymentDetails])

    const name = account ? (account.cardName && account.cardName !== null && account.cardName !== 'null' ? account.cardName : '') : ''

    const urlPath = useCallback((url, type) => {
        if (type) {
            history.push({
                pathname: `/mobile-market/${url}`,
                state: { type: type }
            })
        } else {
            history.push('/mobile-market/' + url)
        }
    }, [])

    const addPaymentDetailsHandler = useCallback(() => {
        urlPath(`payment-details/management?action=ADD&type=${payway}`)
    }, [payway])

    const handlerPaymentDetails = useCallback(() => {
        urlPath('payment-details', 'fade')
    }, [payway])

    return (
        <PaymentBlockDiv
            notCardBg={theme.modal.select.notCardBg}
            notCardDash={theme.topNav.color}
            notCardText={theme.topNav.colorIcon}
            paymentBg={theme.inputBlock.bgPayments}
            paymentColor={theme.topNav.color}
            changeBtn={theme.inputBlock.changeBtn}>
            {payway && (inCurrency.type === 'FIAT' || outCurrency.type === 'FIAT') ? (
                paymentDetails.length === 0 ? (
                    <div className='not-card' onClick={addPaymentDetailsHandler}>
                        <div className='card-dotted-line'>
                            {payway === 'CARD' ? strings('mobileMarket.addCard') : strings('mobileMarket.addPaymentDetails')}
                            <Icon
                                icon={payway === 'CARD' ? 'ADDCARD' : 'GENERAL_PAYMENT'}
                                size={26}
                                style={{ marginLeft: 8 }}
                                color={theme.topNav.colorIcon}
                            />
                        </div>
                    </div>
                ) : (
                    <>
                        <div className='payment-name'>
                            {`${strings(`mobileMarket.modal.${payway === 'CARD' ? 'selectedCard' : 'selectedAccount'}`)} ${name}`}
                        </div>
                        <div className='details-wrapper' onClick={handlerPaymentDetails}>
                            <div className='details-account'>
                                {payway === 'CARD'
                                    ? `**** ${account.number.slice(-4)}`
                                    : `${account.number.slice(0, 4)} **** ${account.number.slice(-4)}`}
                            </div>
                            <div className='change-btn'>
                                {strings('mobileMarket.change')}
                                <div className='change-arrow'>
                                    <Icon icon='ARROW_DOWN' color={theme.inputBlock.changeBtn} size={28} style={{ transform: 'rotate(-90deg)' }} />
                                </div>
                            </div>
                        </div>
                    </>
                )
            ) : null}
        </PaymentBlockDiv>
    )
}

export default PaymentBlock
