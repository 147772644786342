import styled from 'styled-components'

const Notice = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    width: 75%;
    max-width: 350px;
    min-height: 40px;

    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 30px;
    z-index: 99999999;

    border-radius: 10px;
    background: rgb(49, 49, 49);
    box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.3);

    @media screen and (min-width: 500px) {
        left: calc((100% - 350px) / 2);
        margin-left: 16px;
        width: 468px;
    }

    .message {
        font-family: 'SF UI Display Medium';
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: white;
    }
`

export default Notice
