import store from '../../../redux'
import Log from '../../../services/sentry/Log'

export const checkCard = () => {
    const state = store.getState().mobileMarket

    let exchangeWays = state.preRequestExchagneWays
    exchangeWays = state.inCurrency.code ? exchangeWays.filter((way) => way.inCurrency.code === state.inCurrency.code) : exchangeWays
    exchangeWays = state.outCurrency.code ? exchangeWays.filter((way) => way.outCurrency.code === state.outCurrency.code) : exchangeWays

    let wayCountry = exchangeWays.filter((way) => {
        switch (way.exchangeWayType) {
            case 'BUY':
                return way.inPaywayCode === state.payway
            case 'SELL':
                return way.outPaywayCode === state.payway
            default:
                return null
        }
    })

    if (typeof wayCountry === 'undefined') return
    //  || !wayCountry) return false

    let ways = wayCountry.map((way) => {
        if (!state.payway || !state?.paymentData) return { ...way, validCountry: false }

        let country = typeof way.supportedCountriesData !== 'undefined' && way.supportedCountriesData ? way.supportedCountriesData : null

        let validCountry = false

        Log.info(`V3.firstScreen.helper way name ${way?.provider || ''}`)

        switch (state.payway) {
            case 'CARD':
                if (state.outCurrency && state.outCurrency.code === 'UAH') {
                    const status = way.cardCheckStatus || null

                    if (status && typeof status !== 'undefined' && status !== 'SUCCESS') {
                        return { ...way, validCountry: status === 'undefined' ? true : validCountry }
                    }
                }

                if (country === null) {
                    validCountry = true
                } else if (country !== null) {
                    if (country.type === 'EQUALS' && country.countryCodes.includes(state?.paymentData?.countryCode)) {
                        validCountry = true
                    } else if (country.type === 'NOT_EQUALS' && !country.countryCodes.includes(state?.paymentData?.countryCode)) {
                        validCountry = true
                    }
                }

                Log.info(`V3.firstScreen.helper.checkCard way ${JSON.stringify(way)} -> validCountry ${validCountry}`)
                return { ...way, validCountry }

            case 'PERFECT_MONEY':
            case 'ADVCASH':
            case 'CAPITALIST':
            case 'MOBILE_PHONE':
                Log.info(
                    `V3.firstScreen.helper.checkCard way ${JSON.stringify(way)} -> validCountry ${
                        state.inCurrency.type === 'FIAT'
                            ? state?.paymentData?.currency === state.inCurrency.code
                            : state?.paymentData?.currency === state.outCurrency.code
                    }`
                )
                return {
                    ...way,
                    validCountry:
                        state.inCurrency.type === 'FIAT'
                            ? state?.paymentData?.currency === state.inCurrency.code
                            : state?.paymentData?.currency === state.outCurrency.code
                }

            case 'CASH':
                let regions = country.regions

                const cashRegion = JSON.parse(state?.paymentData?.cardDetailsJson)

                regions = regions[cashRegion.region]

                if (country === null) {
                    validCountry = true
                } else if (country !== null) {
                    if (country.type === 'EQUALS' && regions && regions.includes(JSON.parse(cashRegion.city).country.toUpperCase())) {
                        validCountry = true
                    } else if (country.type === 'NOT_EQUALS' && regions && !regions.includes(JSON.parse(cashRegion.city).country.toUpperCase())) {
                        validCountry = true
                    }
                }

                return { ...way, validCountry }

            default:
                return { ...way, validCountry: true }
        }
    })

    return Array.from(new Set(ways.map((way) => way.validCountry)))
}
