export default {
    'CHANGELLY': {
        light: ['#10D078'],
        dark: ['#3AC183']
    },
    'SWAPY': {
        light: ['#1D1F41', '#00A7FF', '#1FB25D'],
        dark: ['#E5E6F5', '#00A7FF', '#1FB25D']
    },
    'CHANGE_NOW': {
        light: ['#3EED83', '#30303C'],
        dark: ['#58D88A', '#E4E4F4']
    },
    '365CASH': {
        light: ['#2CCC71', '#2D9ACC'],
        dark: ['#40BD76', '#3FA3D0']
    },
    'KASSA': {
        light: [
            '#FFFFFF',
            '#121111',
            '#151115',
            '#121111',
            '#F9D825',
            '#121111',
            '#121111',
            '#F9D825',
            '#121111',
            '#F9D825',
            '#121111',
            '#F9D825',
            '#121111',
            '#F9D825',
            '#121111',
            '#F9D825',
            '#121111',
            '#F9D825',
            '#121111',
            '#F9D825',
            '#121111',
            '#F9D825',
            '#121111'
        ],
        dark: [
            '#FFFFFF',
            '#121111',
            '#151115',
            '#121111',
            '#F9D825',
            '#121111',
            '#121111',
            '#F9D825',
            '#121111',
            '#F9D825',
            '#121111',
            '#F9D825',
            '#121111',
            '#F9D825',
            '#121111',
            '#F9D825',
            '#121111',
            '#F9D825',
            '#121111',
            '#F9D825',
            '#121111',
            '#F9D825',
            '#121111'
        ]
    },
    'KUNA': {
        light: ['#312A7D', '#47D3FF'],
        dark: ['#DFDDEE', '#6ECEEC']
    },
    'ANYCASH': {
        light: ['#1469EA', '#1469EA', '#1469EA'],
        dark: ['#4980D5', '#4980D5', '#4980D5']
    },
    'MERCURYO': {
        light: ['#232323'],
        dark: ['#DADADA']
    },
    'MINE_EXCHANGE': {
        light: ['#030303', '#F78C20'],
        dark: ['#B6B6B6', '#DD9145']
    },
    'MOON_PAY': {
        light: ['#111111'],
        dark: ['#B6B6B6']
    },
    'JUST_SWAP': {
        light: ['#4850E5'],
        dark: ['#4850E5']
    },
    'ALICE_BOB': {
        light: ['#18FFFF', '#EF4747', '#860EB5'],
        dark: ['#29DEDE', '#EF4747', '#860EB5']
    },
    'COIN_CAT': {
        light: ['#222'],
        dark: ['#F2C834']
    },
    'PURE_FI': {
        light: ['#5743ED', '#000000'],
        dark: ['#5743ED', '#E6E6E6']
    },
    'SIDE_SHIFT': {
        light: ['#FF464C', '#FF464C'],
        dark: ['#CB3232', '#CB3232']
    },
    'UTORG': {
        light: ['#0F0F0F'],
        dark: ['#DADADA']
    },
    'WHITEBIT': {
        light: ['#EABC4B', '#24BCFF', '#111111'],
        dark: ['#EABC4B', '#24BCFF', '#B6B6B6']
    },

    'КЭШБАНК': {
        light: ['#2B66BD', '#2B66BD'],
        dark: ['#5087D7', '#5087D7']
    },
    'NETEX24': {
        light: ['#12253E', '#F4B645'],
        dark: ['#C2D5EE', '#DAAD5C']
    },
    'XCHANGE': {
        light: ['#FB8A38', '#FFFFFF', '#FFFFFF'],
        dark: ['#EF904C', '#DADADA', '#DADADA']
    },
    'WW-PAY': {
        light: ['#0D0D0D', '#3E9DDC', '#0D0D0D'],
        dark: ['#F2F2F2', '#54A8DF', '#F2F2F2']
    },
    'WM-EXPRESS': {
        light: ['#3413FD'],
        dark: ['#6048EE']
    },
    'I-OBMEN': {
        light: ['#999999', '#E2E9F1', '#00B036'],
        dark: ['#999999', '#E2E9F1', '#00B036']
    },
    'EPIC_EXCHANGE': {
        light: ['#111111', '#111111', '#111111', '#111111'],
        dark: ['#F4F4F4', '#F4F4F4', '#F4F4F4', '#F4F4F4']
    },
    'LETS_EXCHANGE': {
        light: ['#159DFF'],
        dark: ['#3FAFFF']
    },
    'THE_1INCH': {
        light: [
            '#FFFFFF',
            '#94A6C3',
            '#94A6C3',
            '#1B314F',
            '#1B314F',
            '#1B314F',
            '#1B314F',
            '#FFD923',
            '#D82122',
            '#D82122',
            '#D82122',
            '#D82122',
            '#D82122',
            '#FFFFFF'
        ],
        dark: [
            '#FFFFFF',
            '#94A6C3',
            '#94A6C3',
            '#3C5A84',
            '#3C5A84',
            '#3C5A84',
            '#3C5A84',
            '#FFD923',
            '#D82122',
            '#D82122',
            '#D82122',
            '#D82122',
            '#D82122',
            '#FFFFFF'
        ]
    }
}
