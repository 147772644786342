import styled, { keyframes } from 'styled-components'

const slideInTop = keyframes`
    from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    }

    to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
    }
`

const slideOutTop = keyframes`
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    to {
        visibility: hidden; 
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
`

const Modal = styled.div`
    position: fixed;
    overflow: hidden;
    top: 0;
    left: 100%;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 301;
    margin: 0 auto;
    width: 100%;

    transform: translateX(100%);
    transition: all ease-in-out 0.25s;

    &.enter-in {
        left: 0;

        transform: translateX(0);
        transition: all ease-in-out 0.25s;
    }
    &.enter-out {
        left: 0%;

        transform: translateX(100%);
        transition: all ease-in-out 0.25s;
    }

    .background {
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        z-index: 490;
        display: block;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        outline: 0;
    }
    .box-dialog {
        z-index: 500;
        max-width: 768px;
        height: 100%;
        width: 100%;
        background: #f7f7f7;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        position: fixed;
    }
`

export default Modal
