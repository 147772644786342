import React, { useEffect } from 'react'

import { strings } from 'i18n'
import { Trans } from 'react-i18next'

import Card from 'img/card_0223.png'
import VirtualCard from 'img/cardPhone_0223_2.png'

import updateCardsDaemon from 'modules/daemons/updateCardsDaemon'
import Button from 'components/Button/Button'

import store from 'redux/index'
import { addPaymentDetailsArrayAction, cameraResultAction } from 'redux/AppStores/MobileMarket/action'
import { hideScreenModal } from 'redux/AppStores/ScreenModalStore/action'

import validateCard from 'services/validateCard'
import Log from 'services/sentry/Log'

import config from 'config/config'

import Layout from '../Layout/Layout'
import { CardModalDiv, ModalWrapper } from './wrapper.css'

const { dispatch } = store

const CardModal = (props) => {
    const { show, data, callback, theme } = props

    const state = store.getState().mobileMarket

    const eventHandler = (event) => {
        if (typeof event.data === 'undefined' || !event.data || !Object.keys(event.data) || Object.keys(event.data).length === 0) return
        try {
            const { cameraRes } = JSON.parse(event.data)
            if (typeof cameraRes !== 'undefined' && cameraRes !== null) {
                dispatch(cameraResultAction(cameraRes))
                if (cameraRes) {
                    if (Number(state.versionNumber).toString() === 'NaN' && window.navigator.userAgent.indexOf('Chrome') === -1) {
                    } else {
                        document.getElementById('img').click()
                    }
                }
                return false
            }
        } catch (e) {
            console.log(JSON.stringify(e))
            Log.info(`V3.CardModal.eventHandler error ${JSON.stringify(e)}`)
            Log.captureError(`V3.CardModal.eventHandler error`)
        }
    }

    useEffect(() => {
        if (window.navigator.userAgent.indexOf('Chrome') === -1) {
            window.addEventListener('message', (event) => eventHandler(event))
        } else {
            document.addEventListener('message', (event) => eventHandler(event))
        }

        return () => {
            if (window.navigator.userAgent.indexOf('Chrome') === -1) {
                window.removeEventListener('message')
            } else {
                document.removeEventListener('message')
            }
        }
    }, [])

    let currentData = new Date()
    currentData = `${currentData.getDate().toString().length === 1 ? '0' + currentData.getDate() : currentData.getDate()}.${
        (currentData.getMonth() + 1).toString().length === 1 ? '0' + (currentData.getMonth() + 1) : currentData.getMonth() + 1
    }.${currentData.getFullYear()}`

    return (
        <Layout
            id='cardModal'
            header={strings('mobileMarket.modal.attention')}
            show={show}
            bgColor={theme.main.bg}
            leftType='back'
            leftAction={data.close}>
            <ModalWrapper background={theme.main.bg}>
                <CardModalDiv text={theme.topNav.colorIcon}>
                    <div className='wrapper'>
                        <div className='description'>{strings('mobileMarket.modal.needPhotoForProvider')}</div>
                        <div className='header'>{strings('mobileMarket.modal.photoRequirementsPhysicalCard')}</div>
                        <div className='description'>
                            <Trans
                                i18nKey='mobileMarket.modal.photoRequirementsDescription'
                                values={{
                                    data: currentData
                                }}
                            />
                        </div>
                        <div className='example'>{strings('mobileMarket.modal.example')}</div>
                        <div className='img__wrapper'>
                            <img src={Card} alt='card' width='80%' loading='lazy' />
                        </div>
                        <div className='header'>{strings('mobileMarket.modal.photoRequirementsVirtualCard')}</div>
                        <div className='description'>
                            <Trans i18nKey='mobileMarket.modal.photoRequirementsVirtualCardDescription' values={{ data: currentData }} />
                        </div>
                        <div className='example'>{strings('mobileMarket.modal.example')}</div>
                        <div className='img__wrapper'>
                            <img src={VirtualCard} alt='card' width='80%' loading='lazy' />
                        </div>
                        <div className='description' style={{ paddingBottom: '32px' }}>
                            <Trans i18nKey='mobileMarket.modal.photoRequirementsEnd' />
                        </div>

                        <div className='buttons'>
                            <Button
                                id='cardModalBtn'
                                style={{ width: '100%' }}
                                condition={true}
                                handler={async () => {
                                    if (state.cameraResult) {
                                        document.getElementById('img').click()
                                        return
                                    }

                                    if (Number(state.versionNumber).toString() === 'NaN' && window.navigator.userAgent.indexOf('Chrome') === -1) {
                                        window.ReactNativeWebView &&
                                            window.ReactNativeWebView.postMessage(
                                                JSON.stringify({
                                                    takePhoto: {
                                                        number: data.data.number,
                                                        countryCode: data.data.countryCode
                                                    }
                                                })
                                            )
                                        callback()

                                        setTimeout(async () => {
                                            await updateCardsDaemon({ cardNumber: data.data.number })
                                        }, 5e3)
                                    } else {
                                        window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ checkCamera: true }))
                                    }
                                }}>
                                {strings('mobileMarket.modal.takePhoto')}
                            </Button>
                            <label id='img' style={{ display: config.showError ? 'flex' : 'none' }} htmlFor='imageFile' />
                            <input
                                type='file'
                                id='imageFile'
                                accept='image/*'
                                style={{ display: config.showError ? 'flex' : 'none' }}
                                capture='environment'
                                onClick={(event) => {
                                    event.target.value = null
                                }}
                                onChange={async (event) => {
                                    try {
                                        const file = event.target.files[0]

                                        if (file) {
                                            document.getElementById('imageFile').value = ''
                                        }

                                        let res = await validateCard(file, data.data, state)
                                        if (typeof res !== 'undefined' && res) {
                                            res = res.data

                                            const tmpArray = typeof state.walletData.cards !== 'undefined' ? state.walletData.cards : []

                                            // Log.info(`V3.CardModal cardItem ${JSON.stringify({ cardItem })}`)
                                            Log.info(`V3.CardModal res ${JSON.stringify(res)}`)
                                            // tmpArray.find(item => item.number === state.paymentData.number)
                                            tmpArray.find((item) => item.number === data.data.number).cardVerificationJson = JSON.stringify(res)
                                            let cardItem = tmpArray.find((item) => item.number === data.data.number)

                                            window.ReactNativeWebView &&
                                                window.ReactNativeWebView.postMessage(JSON.stringify({ updateCard: cardItem }))
                                            dispatch(addPaymentDetailsArrayAction(tmpArray))

                                            document.getElementById('imageFile').value = ''

                                            callback()

                                            setTimeout(async () => {
                                                await updateCardsDaemon({ cardNumber: data.data.number })
                                            }, 5e3)
                                        } else {
                                            hideScreenModal()
                                        }
                                    } catch (e) {
                                        hideScreenModal()

                                        console.log('V3.CardModal camera error', JSON.stringify(e))
                                        Log.captureError('V3.CardModal camera Error')
                                    }
                                }}
                            />
                        </div>
                    </div>
                </CardModalDiv>
            </ModalWrapper>
        </Layout>
    )
}

export default CardModal
