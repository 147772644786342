import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'

import { hideScreenModal } from 'redux/AppStores/ScreenModalStore/action'
import TopNavigation from 'components/TopNavigation/TopNavigation'

// styled
import StyledModal from './style.css'

const Layout = (props) => {
    Layout.defaultProps = {
        id: ''
    }

    Layout.propTypes = {
        id: PropTypes.string.isRequired
    }

    const { id, header, search, searchInputHandler, side, searchValue, styles, bgColor, leftType, leftAction, rightType, rightAction } = props

    const [state, setState] = useState(null)

    const background = React.createRef()

    useEffect(() => {
        window.addEventListener('keydown', onEscKeyDown, false)
        setTimeout(() => setState('in'), 0)

        return () => {
            window.removeEventListener('keydown', onEscKeyDown, false)
        }
    }, [])

    const transitionEnd = (e) => {
        if (e.propertyName !== 'opacity' || state === 'in') return

        if (state === 'out') {
            hideScreenModal()
        }
        return
    }

    const onEscKeyDown = (e) => {
        if (e.key !== 'Escape') return
        setState('out')
    }

    const handleClick = (e) => {
        e.preventDefault()
        leftAction && leftAction()
        // hideScreenModal()
        setTimeout(() => hideScreenModal(), 500)
        setState('out')
        return
    }

    const leftHandler = () => {
        setTimeout(() => hideScreenModal(), 500)
        setState('out')
        return
    }

    return createPortal(
        <StyledModal
            id={id}
            className={`wrapper fade-${state}`}
            role='dialog'
            onTransitionEnd={transitionEnd}
            style={{ ...styles }}
            bgColor={bgColor}>
            <TopNavigation
                text={header}
                styles={{ zIndex: 11110 }}
                search={search}
                searchInputHandler={searchInputHandler}
                side={side}
                searchValue={searchValue}
                leftType={leftType}
                leftAction={leftHandler}
                rightType={rightType}
                rightAction={rightAction}
            />
            <div className='box-dialog'>{props.children}</div>
            <div className='background' onMouseDown={handleClick} ref={background} />
        </StyledModal>,
        document.body
    )
}

export default Layout
