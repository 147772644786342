import React from 'react'
import store from 'redux/index'
// import ModalKYC from 'modules/searchModal/elements/ModalKYC'
import { getThemeModeStyles } from 'theme/useDarkMode'

import CardModal from './elements/CardModal'
import SelectModal from './elements/SelectModal'

const ScreenModal = (props) => {
    const state = store.getState().modalScreenStore

    const theme = getThemeModeStyles()

    const { show, data, callback } = state

    if (!data) return

    const renderTemplate = () => {
        switch (data.type) {
            case 'CARD_MODAL':
                return <CardModal show={show} data={data} callback={callback} theme={theme} />
            case 'SELECT_MODAL':
                return <SelectModal show={show} data={data} callback={callback} theme={theme} />
            // case 'KYC_MODAL':
            //     return (
            //         <ModalKYC show={show} data={data} callback={callback} />
            //     )
            default:
                return <div></div>
        }
    }

    return <div>{renderTemplate()}</div>
}

export default ScreenModal
