/**
 * @version 0.1
 * @author yura
 */

import React, { useState, useEffect, Suspense } from 'react'
import SwipeableViews from 'react-swipeable-views'
import axios from 'axios'
import queryString from 'query-string'

import CheckData from 'modules/mobileMarket/preCheck/CheckData/CheckData'

import Loader from 'components/Loader/loader'
import getProviderName from 'components/providersSettings/providerName'
import Icon from 'components/Icon/Icon'
import Wrapper from 'components/ScreenWrapper/Wrapper'
import TopNavigation from 'components/TopNavigation/TopNavigation'
import { Content } from 'components/wrapperScreen/wrapper.css'

import Log from 'services/sentry/Log'
import { getCurrencyCode, getCurrencyName, getTxLink } from 'services/getCurrenciesProperty'
import utilsService from 'services/utilsService'

import config from 'config/config'
import i18n, { strings } from 'i18n'

import classes from './style.module.css'

const getExplorerLink = (currencyCode, txHash) => {
    if (typeof txHash === 'undefined' || !txHash) {
        return false
    }

    const baseUrl = getTxLink(currencyCode)
    const link = `${baseUrl}${txHash}?from=trustee`
    return link
}

const getTime = (date) => {
    let datetime = new Date(date)
    const time = `${datetime.getHours().toString().length === 1 ? '0' + datetime.getHours() : datetime.getHours().toString()}:${
        datetime.getMinutes().toString().length === 1 ? '0' + datetime.getMinutes() : datetime.getMinutes().toString()
    }:${datetime.getSeconds().toString().length === 1 ? '0' + datetime.getSeconds() : datetime.getSeconds().toString()}`
    return time
}

const getData = (date) => {
    let datetime = new Date(date)
    const data = `${datetime.getDate().toString().length === 1 ? '0' + datetime.getDate() : datetime.getDate()}.${
        (datetime.getMonth() + 1).toString().length === 1 ? '0' + (datetime.getMonth() + 1) : datetime.getMonth() + 1
    }.${datetime.getFullYear().toString()}`
    return data
}

const getOutDestinationStringKey = (outPaywayCode) => {
    switch (outPaywayCode) {
        case 'VISA_MC_P2P':
        case 'VISA_MC_WW':
        case 'VISA_MC':
            return 'mobileMarket.cardNumber'

        case 'MOBILE_PHONE':
        case 'QIWI':
            return 'mobileMarket.mobilePhone'

        case 'ADVCASH':
        case 'PAYEER':
        case 'PERFECT_MONEY':
        case 'CAPITALIST':
        case 'WEBMONEY':
        case 'YANDEX_MONEY':
            return 'mobileMarket.accountNumber'

        default:
            return 'mobileMarket.destinationAddress'
    }
}

const getBankFeeString = (data, feeData) => {
    const fixedFee = feeData.providerFee.in.find((fee) => fee.type === 'FIXED')
    const percentFee = feeData.providerFee.in.find((fee) => fee.type === 'PERCENT')
    return (
        percentFee.amount +
        '% + ' +
        fixedFee.amount +
        ' ' +
        getCurrencyCode(data[(data.exchangeWayType === 'SELL' ? 'in' : data.currencySide.toLowerCase()) + 'CurrencyCode'])
    )
}

const getNetworkFeeString = (data, feeData) => {
    const fixedFee = feeData.providerFee.out.find((fee) => fee.type === 'FIXED')
    const percentFee = feeData.providerFee.out.find((fee) => fee.type === 'PERCENT')
    return (
        percentFee.amount +
        '% + ' +
        fixedFee.amount +
        ' ' +
        getCurrencyCode(data[(data.exchangeWayType === 'BUY' ? 'out' : data.currencySide.toLowerCase()) + 'CurrencyCode'])
    )
}

const getTrusteeFee = (feeData, side) => {
    const percentFee = feeData.trusteeFee[side.toLowerCase()].find((fee) => fee.type === 'PERCENT')
    return percentFee.amount + '%'
}

const getCheckData = async (setState, checkType = null) => {
    const url = window.location.href
    const urlParamsString = url.slice(url.indexOf('?') + 1)

    const urlParamsObj = queryString.parse(urlParamsString)
    const cashbackToken = urlParamsObj.cashbackToken

    Log.setTag('LOG_CASHBACK', cashbackToken)
    Log.setTag('LOG_VERSION', urlParamsObj.version)

    setState((state) => {
        return {
            ...state,
            version: urlParamsObj.version,
            cashbackToken
        }
    })
    i18n.changeLanguage(urlParamsObj.locale)

    let response
    if (checkType) {
        response = await axios.get(`${config.backendUrl}/order/check?${urlParamsString}&checkType=${checkType}`)
    } else {
        response = await axios.get(`${config.backendUrl}/order/check?${urlParamsString}`)
    }

    Log.info(`V3_CHECK.getCheckData response.data ${JSON.stringify(response.data)}`)

    return response.data
}

const setCheckData = async (state, setState, checkType) => {
    try {
        const data = await getCheckData(setState, checkType)
        Log.info(`V3_CHECK.setCheckType checkData ${JSON.stringify(data)}`)

        if (checkType === 'PRE_CHECK') {
            setState({
                ...state,
                preCheckData: data
            })
        } else if (checkType === 'FINAL_CHECK') {
            setState({
                ...state,
                finalCheckData: data
            })
        }
    } catch (error) {
        Log.warning(`V3_CHECK.setCheckType error ${JSON.stringify(error)}`)
        Log.captureError(`V3_CHECK.setCheckType error`)
        setState((state) => {
            return {
                ...state,
                checkErrorCode: error?.response?.data?.errorCode
            }
        })
    }
}

const close = () => {
    window.ReactNativeWebView.postMessage(JSON.stringify({ close: true }))
}

const MobileCheckScreen = () => {
    const [index, setIndex] = useState(0)
    const [checkType, setCheckType] = useState(null)
    const [loader, setLoader] = useState(false)
    const [title, setTitle] = useState(null)

    const [state, setState] = useState({
        preCheckData: null,
        finalCheckData: null,
        checkErrorCode: null,
        modalError: false,
        textError: null,
        version: null,
        cashbackToken: null
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        setLoader(true)
        try {
            const data = await getCheckData(setState)

            let title
            if (data.exchangeWayType === 'EXCHANGE') {
                title = strings('mobileMarket.modal.exchange')
            } else if (data.exchangeWayType === 'BUY') {
                title = strings('mobileMarket.modal.buy')
            } else if (data.exchangeWayType === 'SELL') {
                title = strings('mobileMarket.modal.sell')
            }
            setTitle(title)

            setCheckType(data.checkType)

            if (data.checkType === 'PRE_CHECK') {
                setIndex(0)
                setState({
                    ...state,
                    preCheckData: data
                })
            } else if (data.checkType === 'FINAL_CHECK') {
                setIndex(1)
                setState({
                    ...state,
                    finalCheckData: data
                })
            }
        } catch (error) {
            console.log(error)
            Log.warning(`V3_CHECK.setCheckType error ${JSON.stringify(error)}`)
            Log.captureError(`V3_CHECK.setCheckType error`)
            setLoader(false)
            setState((state) => {
                return {
                    ...state,
                    checkErrorCode: error?.response?.data?.errorCode
                }
            })
        }
        setLoader(false)
    }, [])

    let array = [0, 1]

    return (
        <Suspense>
            {loader ? (
                <Loader />
            ) : (
                <>
                    <TopNavigation text={title} leftHandler={close} handlerClose={close} branch={'CHECK'} logo close />
                    <Content>
                        <Wrapper>
                            <div style={{ marginTop: 48 }}>
                                <div className={classes.filter__wrapper}>
                                    <div
                                        className={classes.filter__item}
                                        onClick={async () => {
                                            setIndex(0)
                                            setCheckType('PRE_CHECK')
                                            await setCheckData(state, setState, 'PRE_CHECK')
                                        }}>
                                        <div className={checkType === 'PRE_CHECK' ? classes.filter__value : classes.filter__notValue}>PRE-CHECK</div>
                                        <div className={checkType === 'PRE_CHECK' ? classes.filter__point : null} style={{ marginRight: 10 }}></div>
                                    </div>
                                    <div
                                        className={classes.filter__item}
                                        onClick={async () => {
                                            setIndex(1)
                                            setCheckType('FINAL_CHECK')
                                            await setCheckData(state, setState, 'FINAL_CHECK')
                                        }}>
                                        <div className={checkType === 'FINAL_CHECK' ? classes.filter__value : classes.filter__notValue}>CHECK</div>
                                        <div className={checkType === 'FINAL_CHECK' ? classes.filter__point : null} style={{ marginRight: 10 }}></div>
                                    </div>
                                </div>
                                <SwipeableViews
                                    index={index}
                                    onChangeIndex={async () => {
                                        index === 1 ? setIndex(0) : setIndex(1)
                                        checkType === 'PRE_CHECK' ? setCheckType('FINAL_CHECK') : setCheckType('PRE_CHECK')
                                        await setCheckData(state, setState, checkType === 'PRE_CHECK' ? 'FINAL_CHECK' : 'PRE_CHECK')
                                    }}
                                    slideStyle={{
                                        minHeight: '70vh'
                                    }}>
                                    {array.map((item) => {
                                        const data = checkType === 'PRE_CHECK' ? state.preCheckData : state.finalCheckData
                                        if (
                                            (typeof data !== 'undefined' && !data) ||
                                            (!data && checkType === 'FINAL_CHECK' && state.checkErrorCode)
                                        ) {
                                            return (
                                                <div className={classes.data__wrapper} key={item}>
                                                    <div className={classes.fee__wrapper}>
                                                        <div className={classes.fee__icon}>
                                                            <Icon icon={'INFO'} color={'#864DD9'} size={24} />
                                                        </div>
                                                        <div className={classes.fee__text}>{strings('mobileCheck.orderInProcess')}</div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        return (
                                            <div key={item}>
                                                <>
                                                    {data.feeData.isFeesIncludedInRate ? (
                                                        <div className={classes.data__wrapper}>
                                                            <div className={classes.fee__wrapper}>
                                                                <div className={classes.fee__icon}>
                                                                    <Icon icon={'INFO'} color={'#864DD9'} size={24} />
                                                                </div>
                                                                <div className={classes.fee__text}>{strings('mobileMarket.includedFees')}</div>
                                                            </div>
                                                        </div>
                                                    ) : null}

                                                    <CheckData
                                                        data={true}
                                                        name={strings('mobileMarket.youGive') + ':'}
                                                        value={utilsService.cutNumber(data.inAmount, 6) + ' ' + getCurrencyCode(data.inCurrencyCode)}
                                                        codeName={getCurrencyName(data.inCurrencyCode)}
                                                    />

                                                    <div className={classes.main__wrapper}>
                                                        <CheckData
                                                            condition={data.exchangeRate}
                                                            name={
                                                                strings('mobileMarket.rate') +
                                                                ' 1 ' +
                                                                getCurrencyCode(data[(data.currencySide === 'IN' ? 'out' : 'in') + 'CurrencyCode'])
                                                            }
                                                            value={
                                                                utilsService.cutNumber(data.exchangeRate, 6) +
                                                                ' ' +
                                                                getCurrencyCode(data[data.currencySide.toLowerCase() + 'CurrencyCode'])
                                                            }
                                                        />

                                                        {data.feeData.isFeesIncludedInRate ? (
                                                            <div className={classes.empty}></div>
                                                        ) : (
                                                            <>
                                                                {/* Provider Fee */}
                                                                <CheckData
                                                                    condition={data.equivalentData.providerFee.in}
                                                                    name={
                                                                        strings('mobileMarket.bankFee') + ' ' + getBankFeeString(data, data.feeData)
                                                                    }
                                                                    value={
                                                                        utilsService.cutNumber(data.equivalentData.providerFee.in, 6) +
                                                                        ' ' +
                                                                        getCurrencyCode(data[data.currencySide.toLowerCase() + 'CurrencyCode'])
                                                                    }
                                                                />
                                                                <CheckData
                                                                    condition={data.equivalentData.providerFee.out}
                                                                    name={
                                                                        strings('mobileMarket.providerFee') +
                                                                        ' ' +
                                                                        getNetworkFeeString(data, data.feeData)
                                                                    }
                                                                    value={
                                                                        data.equivalentData.providerFee.out +
                                                                        ' ' +
                                                                        getCurrencyCode(data[data.currencySide.toLowerCase() + 'CurrencyCode'])
                                                                    }
                                                                />

                                                                {/* Trustee Fee */}
                                                                <CheckData
                                                                    condition={data.equivalentData.trusteeFee.in}
                                                                    name={
                                                                        strings('mobileMarket.trusteeFee') + ' ' + getTrusteeFee(data.feeData, 'IN')
                                                                    }
                                                                    value={
                                                                        utilsService.cutNumber(data.equivalentData.trusteeFee.in, 6) +
                                                                        ' ' +
                                                                        getCurrencyCode(data[data.currencySide.toLowerCase() + 'CurrencyCode'])
                                                                    }
                                                                />
                                                                <CheckData
                                                                    condition={data.equivalentData.trusteeFee.out}
                                                                    name={
                                                                        strings('mobileMarket.trusteeFee') + ' ' + getTrusteeFee(data.feeData, 'OUT')
                                                                    }
                                                                    value={
                                                                        utilsService.cutNumber(data.equivalentData.trusteeFee.out, 6) +
                                                                        ' ' +
                                                                        getCurrencyCode(data[data.currencySide.toLowerCase() + 'CurrencyCode'])
                                                                    }
                                                                />
                                                            </>
                                                        )}
                                                    </div>
                                                    <div className={classes.line}></div>

                                                    <CheckData
                                                        data={true}
                                                        out={true}
                                                        name={strings('mobileMarket.youExpect') + ':'}
                                                        codeName={getCurrencyName(data.outCurrencyCode)}
                                                        value={
                                                            (item === 1 || data.rateType === 'FIXED' ? '' : '~') +
                                                            ' ' +
                                                            utilsService.cutNumber(data.outAmount, 6) +
                                                            ' ' +
                                                            getCurrencyCode(data.outCurrencyCode)
                                                        }
                                                    />

                                                    <div className={classes.main__wrapper}>
                                                        <CheckData
                                                            condition={data.outDestination}
                                                            name={strings(getOutDestinationStringKey(data.outPaywayCode))}
                                                            value={data.outDestination}
                                                        />

                                                        <CheckData
                                                            condition={data.providerName}
                                                            name={strings('mobileMarket.provider')}
                                                            value={getProviderName(data.providerName)}
                                                        />

                                                        <CheckData
                                                            condition={data.orderHash}
                                                            name={strings('mobileCheck.orderHash')}
                                                            value={data.orderHash}
                                                        />

                                                        <CheckData
                                                            condition={data.exchangeWayType === 'BUY' ? data.payoutTxHash : data.payinTxHash}
                                                            name={'TxID'}
                                                            link={getExplorerLink(
                                                                data.exchangeWayType === 'BUY' ? data.outCurrencyCode : data.inCurrencyCode,
                                                                data.exchangeWayType === 'BUY' ? data.payoutTxHash : data.payinTxHash
                                                            )}
                                                            value={data.exchangeWayType === 'BUY' ? data.payoutTxHash : data.payinTxHash}
                                                        />
                                                        {data.exchangeWayType !== 'BUY' && (
                                                            <CheckData
                                                                condition={item === 1 ? data.finishedAt : data.createdAt}
                                                                name={strings('mobileCheck.data')}
                                                                value={getTime(item === 1 ? data.finishedAt : data.createdAt)}
                                                                secondValue={getData(item === 1 ? data.finishedAt : data.createdAt)}
                                                            />
                                                        )}
                                                    </div>
                                                </>
                                            </div>
                                        )
                                    })}
                                </SwipeableViews>
                            </div>
                        </Wrapper>
                    </Content>
                </>
            )}
        </Suspense>
    )
}

export default MobileCheckScreen
