import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'

// styled
import StyledModal from './style.css'
import TopNavigation from '../../../../../../components/TopNavigation/TopNavigation'
import { hideCardScreenModal } from '../../../../../../redux/AppStores/SearchModalScreenStore/action'
import { propsModalAction } from '../../../../../../redux/AppStores/MobileMarket/action'
import store from '../../../../../../redux'

const { dispatch } = store

const CardLayout = (props) => {
    CardLayout.defaultProps = {
        id: ''
    }

    CardLayout.propTypes = {
        id: PropTypes.string.isRequired
    }

    const { id, header, styles, leftType, leftAction, rightType, rightAction } = props

    const [state, setState] = useState(null)

    const background = React.createRef()

    useEffect(() => {
        window.addEventListener('keydown', onEscKeyDown, false)
        setTimeout(() => setState('in'), 0)

        return () => {
            window.removeEventListener('keydown', onEscKeyDown, false)
        }
    }, [])

    const transitionEnd = (e) => {
        if (e.propertyName !== 'opacity' || state === 'in') return

        if (state === 'out') {
            hideCardScreenModal()
        }
    }

    const onEscKeyDown = (e) => {
        if (e.key !== 'Escape') return
        setState('out')
    }

    const handleClick = (e) => {
        e.preventDefault()
        leftAction && leftAction()
        setTimeout(() => hideCardScreenModal(), 500)
        setState('out')
    }

    const leftHandler = () => {
        if (leftAction) {
            leftAction()
        }
        setTimeout(() => hideCardScreenModal(), 500)

        setState('out')
    }

    return createPortal(
        <StyledModal id={id} className={`wrapper enter-${state}`} role='dialog' onTransitionEnd={transitionEnd} style={{ ...styles }}>
            <TopNavigation
                text={header}
                styles={{ zIndex: 1500 }}
                leftType={leftType}
                leftAction={leftType ? leftHandler : null}
                rightType={rightType}
                rightAction={rightAction}
            />
            <div className='box-dialog'>{props.children}</div>
            {/* <div
                    className={'background'}
                    onMouseDown={handleClick}
                    ref={background}
                /> */}
        </StyledModal>,
        document.body
    )
}

export default CardLayout
