import CurrencyList from 'currency-list'
import store from '../redux'
import { inCurrencyCoinAction, outCurrencyCoinAction } from '../redux/AppStores/MobileMarket/action'
import Log from './sentry/Log'

const { dispatch } = store

export const currencyType = (currency = [], isActive = [], notActive = [], element = false, walletData = false, token = false) => {
    let currencyArray = []

    if (element) {
        const array = maskHiddenFunction(walletData, token)
        if (element.type === 'CRYPTO') {
            let account = array.find((item) => item.currencyCode === element.code)

            Log.info(`V3.currencyType after maskHiddenFunction ${account}`)

            let tmp = account ? account?.maskHidden || false : true
            return {
                ...element,
                maskHidden: tmp
            }
        }
    } else {
        for (let i = 0; i < currency.length; i++) {
            if (CurrencyList.get(currency[i])) {
                if (notActive.includes(currency[i])) {
                    currencyArray.push({
                        code: currency[i],
                        type: 'FIAT',
                        notActive: true
                    })
                } else {
                    currencyArray.push({
                        code: currency[i],
                        type: 'FIAT'
                    })
                }
            } else {
                let tmp = isActive.find((item) => item.currencyCode === currency[i]) || false
                tmp = typeof tmp.maskHidden !== 'undefined' ? tmp.maskHidden : true

                if (typeof tmp === 'undefined') {
                    tmp = true
                }

                if (notActive.includes(currency[i])) {
                    currencyArray.push({
                        code: currency[i],
                        type: 'CRYPTO',
                        notActive: true,
                        maskHidden: tmp
                    })
                } else {
                    currencyArray.push({
                        code: currency[i],
                        type: 'CRYPTO',
                        maskHidden: tmp
                    })
                }
            }
        }

        return currencyArray
    }
}

export const maskHiddenFunction = (walletData = false, token = false) => {
    const state = store.getState().mobileMarket

    let wallets = walletData ? walletData : state.walletData
    let cashbackToken = token ? token : state.cashbackToken

    let array = wallets.wallets.find((wallet) => wallet.cashbackToken === cashbackToken)
    const index = wallets.wallets.indexOf(array) + 1

    array = array?.accounts?.filter((item) => {
        if (item?.isHidden === null) {
            return (item.maskHidden = true)
        }
        const mask = Number(item?.isHidden || 0)
            .toString(2)
            .split('')
            .reverse() // split to binary
        if (typeof mask?.[index] === 'undefined') {
            item.maskHidden = mask?.length === 1 ? mask?.[mask?.length - 1] === '1' : false
        } else {
            item.maskHidden = mask?.[index] === '1'
        }
        return !item.maskHidden
    })

    return array
}

const isActiveCoin = (walletData = false, element = false, token = false) => {
    const state = store.getState().mobileMarket

    if (element) {
        let typeElement = currencyType([], [], [], element, walletData, token)
        return typeElement
    }

    // const isActive = state.walletData ? state.walletData.wallets.find(wallet => wallet.cashbackToken === state.cashbackToken).accounts.map(item => item.currencyCode) : null

    const array = maskHiddenFunction()

    // inCurrencies
    const inCurrencyCode = state.outCurrency
        ? Array.from(
              new Set(state.preRequestExchagneWays.filter((way) => way.outCurrency.code === state.outCurrency.code).map((way) => way.inCurrency.code))
          )
        : Array.from(new Set(state.preRequestExchagneWays.map((way) => way.inCurrency.code)))
    const notActiveInCurrencyCode = state.outCurrency
        ? Array.from(
              new Set(state.preRequestExchagneWays.filter((way) => !inCurrencyCode.includes(way.inCurrency.code)).map((way) => way.inCurrency.code))
          )
        : []

    const result1 = inCurrencyCode.map((way) => {
        let tmp = array.find((item) => item.currencyCode === way) || false
        tmp = typeof tmp.maskHidden !== 'undefined' ? tmp.maskHidden : true

        return {
            ...state.preRequestExchagneWays.find((item) => item.inCurrency.code === way).inCurrency,
            maskHidden: tmp
        }
    })
    const result2 = notActiveInCurrencyCode.map((way) => {
        let tmp = array.find((item) => item.currencyCode === way) || false
        tmp = typeof tmp.maskHidden !== 'undefined' ? tmp.maskHidden : true

        return {
            ...state.preRequestExchagneWays.find((item) => item.inCurrency.code === way).inCurrency,
            notActive: true,
            maskHidden: tmp
        }
    })

    let inCurrency = [...result1, ...result2]

    Log.info(`V3.isActive inCurrency ${JSON.stringify(inCurrency)}`)

    dispatch(inCurrencyCoinAction(inCurrency))

    // outCurrencies
    const outCurrencyCode = state.inCurrency
        ? Array.from(
              new Set(state.preRequestExchagneWays.filter((way) => way.inCurrency.code === state.inCurrency.code).map((way) => way.outCurrency.code))
          )
        : Array.from(new Set(state.preRequestExchagneWays.map((way) => way.outCurrency.code)))
    const notActiveOutCurrencyCode = state.inCurrency
        ? Array.from(
              new Set(
                  state.preRequestExchagneWays.filter((way) => !outCurrencyCode.includes(way.outCurrency.code)).map((way) => way.outCurrency.code)
              )
          )
        : []

    const result3 = outCurrencyCode.map((way) => {
        let tmp = array.find((item) => item.currencyCode === way) || false
        tmp = typeof tmp.maskHidden !== 'undefined' ? tmp.maskHidden : true

        return {
            ...state.preRequestExchagneWays.find((item) => item.outCurrency.code === way).outCurrency,
            maskHidden: tmp
        }
    })
    const result4 = notActiveOutCurrencyCode.map((way) => {
        let tmp = array.find((item) => item.currencyCode === way) || false
        tmp = typeof tmp.maskHidden !== 'undefined' ? tmp.maskHidden : true

        return {
            ...state.preRequestExchagneWays.find((item) => item.outCurrency.code === way).outCurrency,
            notActive: true,
            maskHidden: tmp
        }
    })
    const outCurrency = [...result3, ...result4]

    Log.info(`V3.isActive outCurrency ${JSON.stringify(outCurrency)}`)

    dispatch(outCurrencyCoinAction(outCurrency))
}

export default isActiveCoin
