import styled from 'styled-components'

const LineDiv = styled.div`
    padding-top: 6px;
    padding-bottom: 6px;
    border-bottom: 1px solid ${(p) => p.bg};
    height: 1px;
    margin: 0 auto;
`

export default LineDiv
