import styled from 'styled-components'

const ManageDiv = styled.div`
    .description {
        font-family: 'SF UI Display Regular', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.5px;
        color: ${(p) => p.textColor};
        padding-bottom: 16px;
        margin-left: 16px;
        margin-right: 16px;
    }

    .inputItem {
        display: flex;
        flex-direction: row;
        margin-top: 16px;
    }

    .item {
        margin-right: 16px;
        margin-top: -24px;
        width: calc(100% - 146px);
    }

    .select {
        height: 58px;
        width: 126px;
        background: #404040;
        box-shadow: 0px 6px 10px rgba(64, 64, 64, 0.2);
        border-radius: 10px;
        font-family: 'Montserrat Bold', sans-serif;
        font-style: normal;
        /* font-weight: bold; */
        font-size: 14px;
        line-height: 14px;
        text-transform: uppercase;
        color: #f7f7f7;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .oneTimeSwitchEnter {
        opacity: 0;
        margin-top: -40px;
    }

    .oneTimeSwitchEnterActive {
        opacity: 1;
        margin-top: 30px;
        transition: all 1.5s ease;
    }

    .oneTimeSwitchExit {
        opacity: 1;
        margin-top: 30px;
    }

    .oneTimeSwitchExitActive {
        opacity: 0;
        margin-top: -40px;
        transition: all 1.5s ease;
    }

    .toggleSwitch {
        width: 20%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .currentWallet__wrapper {
        width: 100%;
        margin-top: 30px;
        display: flex;
        align-items: center;
    }

    .wallet__circle {
        min-width: 40px;
        height: 40px;
        border-radius: 50%;
        background: ${(p) => p.circleBg};
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 12px;
    }

    .wallet__text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 70%;
    }

    .textName {
        font-family: 'Montserrat Bold', sans-serif;
        font-style: normal;
        /* font-weight: bold; */
        font-size: 16px;
        line-height: 21px;
        color: ${(p) => p.walletNameColor};
    }

    .textStatus {
        font-family: 'SF UI Display Bold', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 13px;
        letter-spacing: 1.75px;
        text-transform: uppercase;
        color: #999999;
        margin-top: 6px;
    }

    .currency__button {
        width: 52px;
        /*strange*/
        height: 52px;
        /*strange*/
        color: #f5f5f5;
        background: ${(p) => p.btnBg};
        box-shadow: 0 6px 10px rgba(64, 64, 64, 0.2);
        border-radius: 10px;
        display: flex;
        justify-content: center;
    }

    .currency__input {
        height: 50px;
        width: calc(100% - 64px);
        background: ${(p) => p.selectBtn};
        border-radius: 10px;
        border: none;
        outline: none;
        margin-right: 16px;
        font-family: 'Montserrat Bold', sans-serif;
        font-style: normal;
        /* font-weight: bold; */
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0.5px;
        color: ${(p) => p.textColor};
    }

    .input__text {
        margin-top: 17px;
        margin-left: 16px;
    }
`

export default ManageDiv
