import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'

import { hideModal } from 'redux/AppStores/ModalStore/action'

// styled
import StyledModal from './style.css'

const Layout = (props) => {
    Layout.defaultProps = {
        id: ''
    }

    Layout.propTypes = {
        id: PropTypes.string.isRequired
    }

    const { close, id, bg } = props

    const [state, setState] = useState({
        fadeType: null
    })

    const background = React.createRef()

    useEffect(() => {
        window.addEventListener('keydown', onEscKeyDown, false)
        setTimeout(() => setState({ fadeType: 'in' }), 0)

        return () => {
            window.removeEventListener('keydown', onEscKeyDown, false)
        }
    }, [])

    const transitionEnd = (e) => {
        if (e.propertyName !== 'opacity' || state.fadeType === 'in') return

        if (state.fadeType === 'out') {
            hideModal()
        }
    }

    const onEscKeyDown = (e) => {
        if (e.key !== 'Escape') return
        setState({ fadeType: 'out' })
    }

    const handleClick = (e) => {
        e.preventDefault()
        close && close()
        setState({ fadeType: 'out' })
    }

    return createPortal(
        <StyledModal bg={bg}>
            <div id={id} className={`wrapper fade-${state.fadeType}`} role='dialog' onTransitionEnd={transitionEnd}>
                <div className={`box-dialog fade-${state.fadeType}`}>{props.children}</div>
                <div className='background' onMouseDown={handleClick} ref={background} />
            </div>
        </StyledModal>,
        document.body
    )
}

export default Layout
