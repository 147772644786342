import React from 'react'
import store from '../../redux'
import ModalKYC from './elements/ModalKYC'
import SearchModal from '../searchModal/elements/SearchModal'
import OrderDataModal from './elements/OrderDataModal'
import { getThemeModeStyles } from '../../theme/useDarkMode'
import OrderFilterModal from './elements/OrderFilterModal'
import ProviderList from './elements/ProviderList'

const SearchScreenModal = (props) => {
    const state = store.getState().searchModalScreenStore

    const { show, data, callback } = state

    const theme = getThemeModeStyles()

    if (!data) return

    const renderTemplate = () => {
        switch (data.type) {
            case 'SEARCH_MODAL':
                return <SearchModal show={show} data={data} callback={callback} theme={theme} />
            case 'KYC_MODAL':
                return <ModalKYC show={show} data={data} callback={callback} theme={theme} />
            case 'ORDER_MODAL':
                return <OrderDataModal show={show} data={data} callback={callback} theme={theme} />
            case 'FILTER_MODAL':
                return <OrderFilterModal show={show} data={data} callback={callback} theme={theme} />
            case 'PROVIDER_LIST_MODAL':
                return <ProviderList show={show} data={data} callback={callback} theme={theme} />
            default:
                return <div />
        }
    }

    return renderTemplate()
}

export default SearchScreenModal
