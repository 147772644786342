import React, { useCallback } from 'react'
import PercentBlockDiv from './style.css'

const PercentBlock = (props) => {
    const { theme, checked, handleBtn, balance, setButton, setLoading } = props

    const handleClickBtn = useCallback(
        (e, value) => {
            if (e.detail > 1) return

            handleBtn(value, balance, setButton, setLoading)
        },
        [balance]
    )

    return (
        <PercentBlockDiv textColor={theme.topNav.color} borderColor={theme.topNav.colorIcon} textInverse={theme.main.bg}>
            <div className={`btn ${checked === 0.25 ? 'inverse' : ''}`} onClick={(e) => handleClickBtn(e, 0.25)}>
                25%
            </div>
            <div className={`btn ${checked === 0.5 ? 'inverse' : ''}`} onClick={(e) => handleClickBtn(e, 0.5)}>
                50%
            </div>
            <div className={`btn ${checked === 0.75 ? 'inverse' : ''}`} onClick={(e) => handleClickBtn(e, 0.75)}>
                75%
            </div>
            <div className={`btn ${checked === 1 ? 'inverse' : ''}`} onClick={(e) => handleClickBtn(e, 1)}>
                100%
            </div>
        </PercentBlockDiv>
    )
}

export default React.memo(PercentBlock)
