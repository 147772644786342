import React, { useState } from 'react'

import Wrapper from '../../../components/ScreenWrapper/Wrapper'
import SettingListItem from '../../../components/SettingListItem/SettingListItem'
import { strings } from '../../../i18n'
import SearchLayout from './Layout/Layout'
import { ModalWrapper } from './wrapper.css'

import Input from '../../../components/Input/Input'
import Button from '../../../components/Button/Button'

import store from '../../../redux'
import { historySearchDataAction, getHistoryOrderAction } from '../../../redux/AppStores/OrderHistoryStore/action'
import { hideSearchScreenModal } from '../../../redux/AppStores/SearchModalScreenStore/action'
import CheckListItem from './CheckListItem/CheckListItem'

import { serverRequest } from '../../../services/getActualWays'

import Loader from '../../../components/Loader/loader'
import PickDate from './PickDate/PickDate'

import config from '../../../config/config'

const { dispatch } = store

const OrderFilterModal = (props) => {
    const { show, data, callback, theme } = props

    const state = store.getState().orderHistoryStore
    const market = store.getState().mobileMarket

    const [exchangeWays, setExchangeWays] = useState(
        state.searchData?.exchangeWays || {
            buy: 1,
            sell: 1,
            exchange: 1
        }
    )
    const [fromTimestamp, setFromTimestamp] = useState(state.searchData?.fromTimestamp || null)
    const [toTimestamp, setToTimestamp] = useState(state.searchData?.toTimestamp || null)

    const [amount, setAmount] = useState(state.searchData?.amount || null)

    const [loader, setLoader] = useState(false)

    const handlerAmount = (value) => {
        setAmount(value)
    }

    const applyFilter = () => {
        const searchData = {
            amount,
            exchangeWays
        }
        console.log(new Date(fromTimestamp))
        if (fromTimestamp) searchData.fromTimestamp = new Date(fromTimestamp).getTime()
        if (toTimestamp) searchData.toTimestamp = new Date(toTimestamp).getTime()

        dispatch(historySearchDataAction(searchData))
        dispatch(getHistoryOrderAction(0, false, 0, false))
        data.data.main()
        hideSearchScreenModal()
    }

    const downloadOrderTables = async () => {
        setLoader(true)

        const link = '/order/history-for-bse-csv?' + data.data.url
        const res = await serverRequest(link, strings('mobileMarket.modal.serverRespond'), 'WALLET_DATA')

        const orderHistory = {
            data: res,
            title: strings('mobileCheck.filter.share.title'),
            subject: strings('mobileCheck.filter.share.subject'),
            message: strings('mobileCheck.filter.share.message')
        }

        window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ orderHistory }))
        setTimeout(() => setLoader(false), 1e3)
    }

    const onBlur = () => {
        const div = document.getElementById('filter-modal')

        if (div) {
            div.style.height = '90vh'
            setTimeout(() => {
                div.style.height = '120vh'
            }, 4e2)
        }
    }

    return (
        <>
            {loader && <Loader />}
            <SearchLayout id='orderFilterModal' header={strings('mobileMarket.navigation.filter')} show={show} leftType='back'>
                <ModalWrapper
                    background={theme.main.bg}
                    filterColorActive={theme.topNav.color}
                    filterColorTextActive={theme.topNav.colorIcon}
                    textColor={theme.topNav.color}
                    borderColor={theme.topNav.colorIcon}>
                    <div style={{ width: 'calc(100% - 32px)', paddingTop: '64px', marginLeft: 16, height: '120vh' }} id='filter-modal'>
                        <SettingListItem
                            title={strings('mobileCheck.filter.categories')}
                            rightContent='arrowDown'
                            icon='category'
                            ExtraView={() => (
                                <div className='ways-header'>
                                    <CheckListItem
                                        theme={theme}
                                        checked={exchangeWays.buy === 1}
                                        id='buy'
                                        title={strings('mobileCheck.filter.buy')}
                                        onClick={() => {
                                            setExchangeWays({
                                                ...exchangeWays,
                                                buy: Number(!exchangeWays.buy)
                                            })
                                        }}
                                    />
                                    <CheckListItem
                                        theme={theme}
                                        checked={exchangeWays.sell === 1}
                                        id='sell'
                                        title={strings('mobileCheck.filter.sell')}
                                        onClick={() => {
                                            setExchangeWays({
                                                ...exchangeWays,
                                                sell: Number(!exchangeWays.sell)
                                            })
                                        }}
                                    />
                                    <CheckListItem
                                        theme={theme}
                                        checked={exchangeWays.exchange === 1}
                                        id='exchange'
                                        title={strings('mobileCheck.filter.swap')}
                                        onClick={() => {
                                            setExchangeWays({
                                                ...exchangeWays,
                                                exchange: Number(!exchangeWays.exchange)
                                            })
                                        }}
                                    />
                                </div>
                            )}
                        />

                        <div className='filter-category' />
                        <SettingListItem
                            title={strings('mobileCheck.filter.timeArray')}
                            rightContent='arrowDown'
                            icon='date'
                            ExtraView={() => (
                                <div style={{ marginLeft: 50, marginRight: 16 }}>
                                    <PickDate
                                        theme={theme}
                                        value={fromTimestamp}
                                        onChangeHandler={setFromTimestamp}
                                        title={strings('mobileCheck.filter.startDate')}
                                    />
                                    <PickDate
                                        theme={theme}
                                        value={toTimestamp}
                                        onChangeHandler={setToTimestamp}
                                        title={strings('mobileCheck.filter.endDate')}
                                    />
                                </div>
                            )}
                        />
                        {/* <div className='filter-category' style={{ marginTop: 16 }}>{strings('mobileCheck.filter.amount')}</div>
                        <Input
                            value={amount || ''}
                            placeholder={strings('mobileCheck.filter.amount')}
                            id='amount-filter'
                            handler={handlerAmount}
                            condition={false}
                            theme={theme}
                            disabled={false}
                            inputMode='decimal'
                            onBlur={onBlur}
                        /> */}

                        <div className='filter-category' style={{ paddingTop: 12 }} />
                        <SettingListItem
                            title={strings('mobileCheck.filter.downloadFile')}
                            onPress={async () => await downloadOrderTables()}
                            icon='download'
                            last
                        />

                        <div className='button'>
                            <Button id='order-filter-btn' condition={true} handler={applyFilter}>
                                {strings('mobileCheck.filter.apply')}
                            </Button>
                        </div>
                    </div>
                </ModalWrapper>
            </SearchLayout>
        </>
    )
}

export default OrderFilterModal
