import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import store from '../../../redux'
import {
    amountAction,
    amountSideAction,
    variantsAction,
    paymentDataAction,
    sendAllAction,
    addPaymentDetailsArrayAction,
    propsModalAction,
    setSelectedFee
} from '../../../redux/AppStores/MobileMarket/action'
import { getUserCurrencies, getWalletData } from '../../../redux/AppStores/MobileMarket/selectors'
import { showModal } from '../../../redux/AppStores/ModalStore/action'

import Wrapper from '../../../components/ScreenWrapper/Wrapper'
import Button from '../../../components/Button/Button'
import Icon from '../../../components/Icon/Icon'
import Message from '../../../components/InfoMsg/Message'
import UiColor from '../../../components/currencies/UiColor'

import { strings } from '../../../i18n'

import utilsService from '../../../services/utilsService'
import Log from '../../../services/sentry/Log'
import { getCurrencyCode } from '../../../services/getCurrenciesProperty'
import getHashStr from '../../../services/getHashStr'
import limitScan from '../../../services/limitScan'
import minMaxLimits from '../../../services/minMaxLimit'
import findOutDestination from '../../../services/findOutDestination'
import { getPaymentData } from '../../../services/getPaymentData'

import { getThemeMode } from '../../../theme/useDarkMode'

import Loader from '../../../img/icon/loaderUniverse.svg'

import { ModalWrapper } from '../elements/wrapper.css'
import WrapperSelector from '../../mobileMarket/firstScreen/wrapperSelector/wrapperSelector'

import SearchLayout from './Layout/Layout'
import ProcentButtons from './ProcentButtons/ProcentButton'

const { dispatch } = store

const displayInputWidth = (text, font) => {
    let canvas = displayInputWidth.canvas || (displayInputWidth.canvas = document.createElement('canvas'))
    let context = canvas.getContext('2d')
    context.font = font

    let metrics = context.measureText(text)

    context.clearRect(0, 0, canvas.width, canvas.height)

    let textarea = document.getElementById('amountInput')

    if (textarea === null) {
        return
    }

    if (metrics.width > 100) {
        textarea.style.width = `${metrics.width * 1.05}px`
        if (textarea.style.width >= textarea.style.maxWidth) {
            const textareaWidth = textarea.clientWidth

            const coefficient = metrics.width / textareaWidth

            if (metrics.width > textareaWidth) {
                textarea.style.fontSize = `${40 / coefficient}px`
            } else {
                textarea.style.fontSize = '40px'
            }
        }
    } else {
        textarea.style.width = '100px'
    }
}

const addPaymentDetailsHandler = (urlPath, type, providersArray) => {
    if (providersArray.length > 0) {
        dispatch(variantsAction(providersArray))
    }
    dispatch(propsModalAction(true))
    urlPath(`payment-details/management?action=ADD&type=${type}`)
}

const amountHandler = (amount) => {
    let value = amount.toString().indexOf(',') === -1 ? amount.toString() : amount.toString().replace(',', '.')

    value = value
        .toString()
        .replace(/[^\d.,]/g, '')
        .replace(/(\..*)\./g, '$1')

    if (value.length === 1 && value === '.') {
        value = '0.'
    }

    if (value.indexOf('.') !== -1) {
        let newValue = value.slice(0, value.indexOf('.'))
        newValue = Array.from(new Set(newValue))
        if (newValue[0] === '0') {
            value = '0' + value.slice(value.indexOf('.'), value.length)
        }
        newValue = value.slice(value.indexOf('.') + 1, value.length)
        if (newValue.indexOf('.') !== -1) {
            value = value.slice(0, value.indexOf('.') + 1) + (Number(newValue) ? Number(newValue) : '')
        }
    } else if (value.indexOf('.') === -1 && value.length > 1) {
        value *= 1
    }

    dispatch(sendAllAction(false))
    dispatch(amountAction(value))
}

const sideHandler = (side) => {
    dispatch(amountSideAction(side))
}

const buttonHandler = async (part, balance, setButton, setLoad = null) => {
    const state = store.getState().mobileMarket

    setButton(part)

    let amount = Number(part) !== 1 && balance * part
    let limit

    Log.info(`V3.mainModal.buttonHandler amount -> ${amount} part -> ${part}`)

    if (part === 1) {
        setLoad(true)
        const state = store.getState().mobileMarket

        const obj = findOutDestination(state.walletData, state.inCurrency.code, state.cashbackToken)

        Log.info(`V3.mainModal.buttonHandler sendAll balance ${state.inCurrency.code} -> ${state.outCurrency.code}`)

        window.ReactNativeWebView &&
            window.ReactNativeWebView.postMessage(
                JSON.stringify({
                    useAllFunds: {
                        currencyCode: state.inCurrency.code,
                        address: obj.outDestination
                    }
                })
            )
        // return
    }

    if (part === 'MIN' || part === 'MAX') {
        setLoad(true)

        const limits = await limitScan(state)

        limit = minMaxLimits(state.allExchangeWays, state.exchangeWaysArray, limits || [])
    }

    if (Number(part)) {
        amount = utilsService.cutNumber(amount, 8)
        dispatch(sendAllAction(part === 1 ? true : false))

        dispatch(amountAction(amount))
        sideHandler('IN')
    } else {
        amount = part === 'MIN' ? limit.min : limit.max

        if (amount && Number(amount) > 0) {
            dispatch(sendAllAction(false))
            dispatch(amountAction(amount))

            try {
                sideHandler(
                    state.exchangeWaysArray[0].exchangeWayType === 'SELL' || state.activeExchangeWay === 'SELL' || state.outCurrency.type === 'FIAT'
                        ? 'OUT'
                        : 'IN'
                )
            } catch {
                sideHandler('IN')
            }
            setLoad(false)
        } else {
            setLoad(false)
            showModal({
                type: 'INFO_MODAL',
                icon: 'WARNING',
                header: strings('mobileMarket.modal.warning'),
                description: strings(`mobileMarket.modal.notLimits${part === 'MIN' ? 'Min' : 'Max'}`)
            })
            return
        }
    }

    setTimeout(() => {
        displayInputWidth(amount, '40px Montserrat Medium')
    }, 0)
    return
}

const keyListener = (e) => {
    if (e.keyCode === 13) {
        const state = store.getState().mobileMarket
        if (state.amount && state.paymentData) {
            try {
                document.getElementById('mainModalBtn').click()
            } catch (e) {
                console.log(JSON.stringify(e))
            }
        } else {
            const input = document.getElementById('amountInput')
            if (input) input.blur()
        }
    }
}

const handlerAdvancedSettings = (urlPath, variantsArray) => {
    dispatch(variantsAction(variantsArray))
    dispatch(propsModalAction(true))
    urlPath('settings', 'page')
}

const handlerPaymentDetails = (urlPath, variantsArray) => {
    const state = store.getState().mobileMarket

    dispatch(variantsAction(variantsArray))
    dispatch(propsModalAction(true))

    if (state.payway) {
        let paymentNumberHash = localStorage.getItem('trusteeHash') || null

        let paymentDetails = getPaymentData()

        let paymentData = paymentDetails.length
            ? paymentNumberHash
                ? paymentDetails.find((item) => getHashStr(item.number) === paymentNumberHash)
                : paymentDetails[paymentDetails.length - 1]
            : null

        if (!paymentData && paymentDetails.length) {
            paymentData = paymentDetails[paymentDetails.length - 1]
            localStorage.setItem('trusteeHash', getHashStr(paymentData.number))
        }

        dispatch(paymentDataAction(paymentData))
    }

    urlPath('payment-details', 'page')
}

const SearchModal = (props) => {
    const state = store.getState().mobileMarket
    const searchModalScreenState = store.getState().searchModalScreenStore

    const walletData = useSelector(getWalletData)
    const userCurrencies = useSelector(getUserCurrencies)

    const mode = getThemeMode()

    const [button, setButton] = useState(null)
    const [loading, setLoading] = useState(false)

    const [focus, setFocus] = useState(false)

    const { show, data, callback, theme } = props

    const { leftHandler, inCurrencyModal, outCurrencyModal, paywayModal } = data

    const variantsArray = data.data.variantsArray

    const eventHandler = (event) => {
        if (typeof event.data === 'undefined' || !event.data || !Object.keys(event.data) || Object.keys(event.data).length === 0) return
        try {
            Log.info(`V3.mainModal.eventHandler event.data ${JSON.stringify(event.data)}`)
            if (typeof event.data !== 'string') return

            const { fees, serverError, res } = JSON.parse(event.data)

            if (fees) {
                const amount = utilsService.cutNumber(fees.amount, 8)
                const tmpState = store.getState().mobileMarket

                if (searchModalScreenState.searchShow && !tmpState.preCheck) {
                    dispatch(amountAction(amount))
                    setTimeout(() => {
                        displayInputWidth(amount, '40px Montserrat Bold')
                    }, 0)
                    sideHandler('IN')
                    setLoading(false)
                    dispatch(setSelectedFee(fees?.transferData || null))
                }
                return
            }

            if (res) {
                const state = store.getState().mobileMarket
                const tmpArray = typeof state.walletData.cards !== 'undefined' ? state.walletData.cards : []
                const number = res.numberCard
                let cardItem = tmpArray.find((item) => item.number === number)

                Log.info(`V3.mainModal.eventHandler cardItem ${JSON.stringify({ cardItem })}`)

                cardItem = cardItem.cardVerificationJson ? JSON.parse(cardItem.cardVerificationJson) : null
                const data = res.res

                Log.info(`V3.mainModal.eventHandler data ${JSON.stringify(data)}`)

                if (data.updatedAt > cardItem.updatedAt) {
                    console.log('mainModal fuck')
                    tmpArray.find((item) => item.number === number).cardVerificationJson = JSON.stringify(data)
                    dispatch(addPaymentDetailsArrayAction(tmpArray))
                    if (state.paymentData) {
                        dispatch(paymentDataAction(tmpArray.find((item) => item.number === state?.paymentData?.number)))
                    }
                }
            }

            if (serverError) {
                setLoading(false)
                setButton(null)
                dispatch(sendAllAction(false))
            }
        } catch (e) {
            console.log('error', JSON.stringify(e))
            Log.error(`V3.mainModal.eventHandler fees error ${JSON.stringify(e)}`)
            Log.captureError(`V3.mainModal.eventHandler fees error`)
        }
    }

    useEffect(() => {
        if (state.payway) {
            let paymentNumberHash = localStorage.getItem('trusteeHash') || null

            let paymentDetails = getPaymentData()

            let paymentData = paymentDetails.length
                ? paymentNumberHash
                    ? paymentDetails.find((item) => getHashStr(item.number) === paymentNumberHash)
                    : paymentDetails[paymentDetails.length - 1]
                : null

            if (!paymentData && paymentDetails.length) {
                paymentData = paymentDetails[paymentDetails.length - 1]
                localStorage.setItem('trusteeHash', getHashStr(paymentData.number))
            }

            dispatch(paymentDataAction(paymentData))
        }

        if (state.skipProvidersScreen)
            setTimeout(() => {
                dispatch(propsModalAction(false))
            }, 1e3)

        if (window.navigator.userAgent.indexOf('Chrome') === -1) {
            window.addEventListener('message', (event) => eventHandler(event))
            window.addEventListener('keydown', (e) => keyListener(e))
        } else {
            document.addEventListener('message', (event) => eventHandler(event))
            document.addEventListener('keydown', (event) => keyListener(event))
        }

        return () => {
            if (window.navigator.userAgent.indexOf('Chrome') === -1) {
                window.removeEventListener('message')
                window.removeEventListener('keydown')
            } else {
                document.removeEventListener('message')
                document.removeEventListener('keydown')
            }
        }
    }, [])

    let youHave
    youHave =
        state.inCurrency && state.inCurrency.type === 'CRYPTO'
            ? state.walletData?.wallets
                  .find((wallet) => wallet.cashbackToken === state.cashbackToken)
                  .accounts.find((account) => account.currencyCode === state.inCurrency.code)
            : null

    const history = useHistory()

    const urlPath = (url, type) => {
        if (type) {
            history.push({
                pathname: `/mobile-market/${url}`,
                state: { type: type }
            })
        } else {
            history.push('/mobile-market/' + url)
        }
    }

    const paymentDetails = getPaymentData()

    const account = state.payway ? state.paymentData || paymentDetails[paymentDetails.length - 1] || null : null

    const name = account ? (account.cardName && account.cardName !== null && account.cardName !== 'null' ? account.cardName : '') : ''

    // const modal = document.getElementById('searchModal')

    // if (modal && state.propsModal) {
    //     modal.classList.remove('fade-in')
    //     modal.style.left = 0
    // }

    const swapHandler = () => {
        sideHandler(state.amountSide === 'IN' ? 'OUT' : 'IN')
    }

    return (
        <SearchLayout
            id='searchModal'
            header={strings('mobileMarket.navigation.market')}
            show={show}
            styles={{ left: state.propsModal ? 0 : '', transform: state.propsModal ? 'translateX(0)' : '' }}
            leftType={state.skipProvidersScreen ? null : 'back'}
            leftAction={state.skipProvidersScreen ? null : data.close}>
            <ModalWrapper
                background={theme.main.bg}
                limitsBtn={theme.topNav.colorIcon}
                limitLine={theme.main.selector.bg}
                notCardBg={theme.modal.select.notCardBg}
                notCardDash={theme.topNav.color}
                notCardText={theme.topNav.colorIcon}
                paymentBg={theme.paymentScreen.typeBg}
                paymentColor={theme.topNav.color}
                btnBg={theme.bottomNav.addBtn}
                focus={focus}>
                <Wrapper mainModal={true} contentStyle={{ width: 'calc(100% - 32px)', paddingTop: '16px' }}>
                    <div className='body__main'>
                        <div className='selectors__wrapper'>
                            <WrapperSelector
                                inCurrencyModal={inCurrencyModal}
                                outCurrencyModal={outCurrencyModal}
                                paywayModal={paywayModal}
                                theme={theme}
                                walletData={walletData}
                                userCurrencies={userCurrencies}
                            />
                            {state.inCurrency && state.outCurrency ? (
                                <>
                                    <div className='amountWrapper'>
                                        <div className='minMaxWrapper'>
                                            <div
                                                className='limitBtn'
                                                style={{ letterSpacing: '2px' }}
                                                onClick={() => {
                                                    buttonHandler(
                                                        'MIN',
                                                        youHave ? youHave?.prettyBalanceRaw || youHave?.balance : 0,
                                                        setButton,
                                                        setLoading
                                                    )
                                                }}>
                                                MIN
                                            </div>
                                            <div className='line' />
                                            <div
                                                className='limitBtn'
                                                onClick={() => {
                                                    buttonHandler(
                                                        'MAX',
                                                        youHave ? youHave?.prettyBalanceRaw || youHave?.balance : 0,
                                                        setButton,
                                                        setLoading
                                                    )
                                                }}>
                                                MAX
                                            </div>
                                        </div>
                                        <div className='wrapper__amount'>
                                            {state.inCurrency && state.inCurrency.type === 'CRYPTO' && (
                                                <div className='currency__balance'>
                                                    {`${strings('mobileMarket.youHave')} ${utilsService.cutNumber(
                                                        youHave
                                                            ? (youHave?.prettyBalanceRaw || youHave?.balance) <= 0
                                                                ? 0
                                                                : youHave?.prettyBalanceRaw || youHave?.balance
                                                            : 0,
                                                        8
                                                    )} ${state.inCurrency.symbol || getCurrencyCode(state.inCurrency.code)}`}
                                                </div>
                                            )}
                                            <div className='input__wrapper'>
                                                {loading ? (
                                                    <div className='loader'>
                                                        <img src={Loader} height={45} alt='preloader' style={{ paddingLeft: 45 }} />
                                                    </div>
                                                ) : (
                                                    <input
                                                        id='amountInput'
                                                        inputMode='decimal'
                                                        placeholder='0.00'
                                                        autoFocus={false}
                                                        onChange={(event) => {
                                                            displayInputWidth(event.target.value, '40px Montserrat Medium')
                                                            amountHandler(event.target.value)
                                                        }}
                                                        maxLength={17}
                                                        value={state.amount ? state.amount.toString().replace(',', '.') : ''}
                                                        autoComplete='off'
                                                        style={{
                                                            color: state.amount
                                                                ? UiColor[state.inCurrency.code]
                                                                    ? UiColor[state.inCurrency.code].colors[
                                                                          mode === 'light' ? 'mainColor' : 'darkColor'
                                                                      ]
                                                                    : theme.topNav.colorIcon
                                                                : theme.topNav.colorIcon
                                                        }}
                                                        onFocus={(event) => {
                                                            const element = event.target
                                                            const caret = element.value.length
                                                            window.requestAnimationFrame(() => {
                                                                element.selectionStart = caret
                                                                element.selectionEnd = caret
                                                            })
                                                            setFocus(true)
                                                        }}
                                                        onBlur={(e) =>
                                                            setTimeout(() => {
                                                                setFocus(false)
                                                            }, 0)
                                                        }
                                                    />
                                                )}
                                                <div className='input__code' onClick={swapHandler}>
                                                    {state.amountSide === 'IN'
                                                        ? state.inCurrency.symbol || getCurrencyCode(state.inCurrency.code)
                                                        : state.outCurrency.symbol || getCurrencyCode(state.outCurrency.code)}
                                                    <div style={{ marginLeft: 5 }}>
                                                        <Icon icon={'SWAP'} size={20} color={theme.topNav.colorIcon} />
                                                    </div>
                                                </div>
                                                <div style={{ display: 'none' }}>
                                                    {state.amount
                                                        ? setTimeout(() => {
                                                              displayInputWidth(state.amount, '40px Montserrat Bold')
                                                          }, 0)
                                                        : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {youHave && Number(youHave?.prettyBalanceRaw || youHave?.balance) > 0 && (
                                        <div style={{ marginTop: 60 }}>
                                            <ProcentButtons
                                                handler={buttonHandler}
                                                balance={youHave?.prettyBalanceRaw || youHave?.balance}
                                                checked={button}
                                                setButton={setButton}
                                                setLoad={setLoading}
                                                theme={theme}
                                            />
                                        </div>
                                    )}
                                </>
                            ) : (
                                <Message
                                    theme={theme}
                                    text={
                                        !state.inCurrency
                                            ? strings('mobileMarket.notInCurrency')
                                            : !state.outCurrency
                                            ? strings('mobileMarket.notOutCurrency')
                                            : !state.payway
                                            ? strings('mobileMarket.notPayway')
                                            : strings('mobileMarket.noWay')
                                    }
                                />
                            )}
                        </div>
                        {state.payway && (state.inCurrency.type === 'FIAT' || state.outCurrency.type === 'FIAT') ? (
                            paymentDetails.length === 0 ? (
                                <div
                                    className='notCard'
                                    onClick={() => {
                                        addPaymentDetailsHandler(urlPath, state.payway, variantsArray)
                                    }}>
                                    <div className='cardDottedLine'>
                                        {state.payway === 'CARD' ? strings('mobileMarket.addCard') : strings('mobileMarket.addPaymentDetails')}
                                        <Icon
                                            icon={state.payway === 'CARD' ? 'ADDCARD' : 'GENERAL_PAYMENT'}
                                            size={26}
                                            style={{ marginLeft: 8 }}
                                            color={theme.topNav.colorIcon}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div style={{ marginTop: 40 }}>
                                    <div className='payment__name'>{`${strings('mobileMarket.modal.selectedCard')} ${name}`}</div>
                                    <div className='paymentWrapper'>
                                        <div
                                            className='detailsWrapper'
                                            onClick={() => {
                                                handlerPaymentDetails(urlPath, variantsArray)
                                            }}>
                                            <div className='detailsAccount'>{`${account.number.slice(0, 4)}***${account.number.slice(-4)}`}</div>
                                        </div>
                                        <div
                                            className='changeBtn'
                                            onClick={() => {
                                                handlerPaymentDetails(urlPath, variantsArray)
                                            }}>
                                            {/* {strings('mobileMarket.change')} */}
                                            <div className='changeArrow'>
                                                <Icon icon='ARROW_DOWN' color={theme.main.arrow} size={28} style={{ transform: 'rotate(-90deg)' }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        ) : null}
                        <div className={focus ? 'focus__btn' : 'buttons'}>
                            {state.outCurrency && state.outCurrency.type !== 'FIAT' && (
                                <div
                                    className='button__back'
                                    onClick={() => {
                                        if (state.inCurrency && state.outCurrency) {
                                            handlerAdvancedSettings(urlPath, variantsArray)
                                        }
                                    }}>
                                    <Icon icon='SETTINGS' color={theme.topNav.colorIcon} size={24} />
                                </div>
                            )}
                            <Button
                                id='mainModalBtn'
                                condition={
                                    state.amount &&
                                    Number(state.amount > 0) &&
                                    state.inCurrency &&
                                    state.outCurrency &&
                                    (state.inCurrency.type === 'FIAT' || state.outCurrency.type === 'FIAT' ? state.payway : true) &&
                                    !loading
                                }
                                style={{ width: '100%' }}
                                handler={(e) => {
                                    e.preventDefault()
                                    dispatch(propsModalAction(false))
                                    callback()
                                }}>
                                {strings('mobileMarket.searchBestOffer')}
                            </Button>
                        </div>
                    </div>
                </Wrapper>
            </ModalWrapper>
        </SearchLayout>
    )
}

export default SearchModal
