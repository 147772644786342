import React from 'react'
import { createPortal } from 'react-dom'
import { useDispatch, useSelector } from 'react-redux'

import {
    searchValueInSideAction,
    searchValueOutSideAction,
    searchValuePaywayAction,
    searchValueCountryAction
} from 'redux/AppStores/MobileMarket/action'
import { getSearchValue } from 'redux/AppStores/MobileMarket/selectors'

import Log from 'services/sentry/Log'

import { strings } from 'i18n'

import { getThemeModeStyles } from 'theme/useDarkMode'

import CurrencyModal from './modals/CurrencyModal'
import PaywayModal from './modals/PaywayModal'
import RegionModal from './modals/RegionModal'
import RegionCityModal from './modals/RegionCityModal'
import CountryModal from './modals/CountryModal'
import AddressModal from './modals/AddressModal'

import { ModalWrapper } from './wrapper.css'
import Layout from '../Layout/Layout'

const SelectModal = (props) => {
    const searchValue = useSelector(getSearchValue)

    const dispatch = useDispatch()

    const { show, data, callback } = props

    const { side, search, setShowError, setShowErrorBtn, setCardCountryCode, cardCountryCode, fiatArray, wallet } = data.data

    const theme = getThemeModeStyles()

    const headerString =
        side === 'IN'
            ? strings('mobileMarket.youGive')
            : side === 'OUT'
            ? strings('mobileMarket.youGet')
            : side === 'PAYWAY'
            ? strings('mobileMarket.payway')
            : strings('mobileMarket.selectCurrency')

    const searchHandler = (value, side, type = null) => {
        if (value) {
            value = value.toString().toUpperCase()
        }

        Log.info(`V3.selectModal.searchInputHandler value ${value} side ${side} type ${type}`)

        if (side === 'IN') {
            dispatch(searchValueInSideAction(value))
        } else if (side === 'OUT') {
            dispatch(searchValueOutSideAction(value))
        } else if (side === 'PAYWAY') {
            dispatch(searchValuePaywayAction(value))
        } else if (side === 'COUNTRY') {
            dispatch(searchValueCountryAction(value))
        }
    }

    return (
        <>
            {show &&
                createPortal(
                    <Layout
                        id={`selectModal-${side.toString()?.toLowerCase()}`}
                        header={headerString}
                        show={show}
                        search={search}
                        searchInputHandler={searchHandler}
                        side={side}
                        searchValue={
                            side === 'IN'
                                ? searchValue.searchValueInSide
                                : side === 'OUT'
                                ? searchValue.searchValueOutSide
                                : side === 'PAYWAY'
                                ? searchValue.searchValuePayway
                                : searchValue.searchValueCountry
                        }
                        leftType='back'
                        leftAction={data.close}>
                        <ModalWrapper background={theme.modal.bg}>
                            {side === 'IN' && (
                                <CurrencyModal
                                    side={side}
                                    setShowError={setShowError}
                                    setShowErrorBtn={setShowErrorBtn}
                                    fiatArray={fiatArray}
                                    wallet={wallet}
                                    theme={theme}
                                    searchValue={searchValue}
                                />
                            )}
                            {side === 'OUT' && (
                                <CurrencyModal
                                    side={side}
                                    setShowError={setShowError}
                                    setShowErrorBtn={setShowErrorBtn}
                                    fiatArray={fiatArray}
                                    wallet={wallet}
                                    theme={theme}
                                    searchValue={searchValue}
                                />
                            )}
                            {side === 'PAYWAY' && (
                                <PaywayModal setShowError={setShowError} setShowErrorBtn={setShowErrorBtn} theme={theme} searchValue={searchValue} />
                            )}
                            {side === 'REGION' && <RegionModal theme={theme} side={side} data={data.data} />}
                            {side === 'REGION_CITY' && <RegionCityModal theme={theme} side={side} data={data.data} />}
                            {side === 'COUNTRY' && (
                                <CountryModal
                                    setCardCountryCode={setCardCountryCode}
                                    cardCountryCode={cardCountryCode}
                                    theme={theme}
                                    searchValue={searchValue}
                                />
                            )}
                            {side === 'ADDRESS' && <AddressModal theme={theme} />}
                            <div className='modal__shadow' />
                        </ModalWrapper>
                    </Layout>,
                    document.body
                )}
        </>
    )
}

export default SelectModal
