import React from 'react'

import { strings } from 'i18n'
import Icon from 'components/Icon/Icon'
import { hideModal } from 'redux/AppStores/ModalStore/action'
import hideAnimation from 'services/hideAnimation'

import ModalBody from './wrapper.css'
import Layout from './Layout/Layout'

const SelectModal = (props) => {
    const { show, data, theme } = props

    return (
        <>
            {show && (
                <Layout id='selectModal' close={data.close} bg={theme.modal.bg}>
                    <ModalBody headerColor={theme.modal.colorHeader} description={theme.modal.colorDescription}>
                        <div className='modal__img__wrapper'>
                            <Icon
                                icon={data.icon === 'INFO' || data.icon === 'WARNING' ? 'INFO' : 'WARNING'}
                                size={64}
                                color={theme.modal.infoColor}
                            />
                        </div>
                        {data.header && <div className='modal__header'>{data.header}</div>}
                        <div className='modal__description'>{data.description}</div>
                        <div className='modal__button' style={{ marginBottom: '20px' }}>
                            {data.actions.map((item) => {
                                return (
                                    <div
                                        className='btn__inverse'
                                        key={item.name}
                                        style={{
                                            color: theme.modal.infoColor
                                        }}
                                        onClick={() => {
                                            item.action()
                                            hideAnimation('selectModal', hideModal)
                                        }}>
                                        {strings(`mobileMarket.modal.${item.name}`)}
                                    </div>
                                )
                            })}
                        </div>
                    </ModalBody>
                </Layout>
            )}
        </>
    )
}

export default SelectModal
