import React from 'react'
import store from '../../../../redux'
import { getThemeModeStyles } from '../../../../theme/useDarkMode'

import ManagementPaymentDetails from './elements/managementPaymentDetails'

const CardScreenModal = (props) => {
    const state = store.getState().searchModalScreenStore

    const { cardShow, dataCard, callback } = state

    const theme = getThemeModeStyles()

    if (!dataCard) return

    const renderTemplate = () => {
        switch (dataCard.type) {
            case 'CARD_MODAL':
                return <ManagementPaymentDetails show={cardShow} data={dataCard} callback={callback} theme={theme} />
            default:
                return <div></div>
        }
    }

    return <>{renderTemplate()}</>
}

export default CardScreenModal
