import styled from 'styled-components'

const CardDiv = styled.div`
    width: 100%;
    height: 82px;
    border: ${(p) => (p.checked ? `2px solid ${p.checked ? p.checkedColor : !p.country ? p.notActiveColor : p.normColor}` : null)};
    box-sizing: border-box;
    border-radius: 16px;
    box-shadow: ${(p) => (p.checked ? '0px 0px 10px rgba(134, 77, 217, 0.2)' : '0px 0px 10px rgba(0, 0, 0, 0.1)')};
    display: flex;
    margin-top: 16px;
    background: ${(p) => (p.checked ? p.bgChecked : p.bgNew)};

    .main__data {
        display: flex;
        flex-direction: column;
        /* width: 50%; */
        width: calc(45% - 24px);
        height: 70%;
        margin-left: 24px;
        align-self: center;
        justify-content: space-around;
    }

    .mainData_details {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 70%;
        text-align: center;
        align-self: center;
        justify-content: space-around;
    }

    .card__name {
        font-family: 'SF UI Display Bold';
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 13px;
        letter-spacing: 1.75px;
        text-transform: uppercase;
        color: #999999;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .card__number {
        font-family: 'Montserrat Bold';
        font-style: normal;
        /* font-weight: bold; */
        font-size: 16px;
        line-height: 16px;
        color: ${(p) => p.text1};
    }

    .card__line {
        border-right: 2px solid ${(p) => p.text1};
        height: calc(100% - 20px);
        margin-top: 10px;
    }

    .service__data {
        display: flex;
        flex-direction: row;
        align-self: center;
        height: 70%;
        justify-content: space-between;
        margin-left: 16px;
        width: calc(55% - 32px);
    }

    .card__country {
        font-family: 'SF UI Display Bold';
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 13px;
        letter-spacing: 1.75px;
        color: #999999;
    }

    .card__validTime {
        font-family: 'Montserrat Bold';
        font-style: normal;
        /* font-weight: bold; */
        font-size: 16px;
        line-height: 16px;
        color: ${(p) => p.text1};

        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
    }

    .verify {
        width: calc(100% - 42px);
        font-family: 'SF UI Display Bold';
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 1.5px;
        color: #999999;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .photoVerify {
        width: 42px;
        padding-right: 16px;
        display: flex;
        align-self: center;
        justify-content: flex-end;
    }

    .icon__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        padding-right: 16px;
        margin-left: -40px;
    }

    .details__wrapper {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .statusWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        height: 100%;
        width: calc(100% - 16px);
    }

    .verify {
        width: calc(100% - 42px);
        font-family: 'SF UI Display Bold';
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 1.5px;
        color: #999999;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .photoVerify {
        width: 42px;
        padding-right: 16px;
        display: flex;
        align-self: center;
        justify-content: flex-end;
    }

    .statusWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        height: 100%;
        width: calc(100% - 16px);
    }

    .settings {
        width: 24px;
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    @media screen and (max-width: 380px) {
        .verify {
            font-size: 10px;
            line-height: 12px;
        }
    }
`

export default CardDiv
