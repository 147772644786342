import firebase from 'firebase/app'
import 'firebase/firestore'

const firebaseConfigLocal = {
    apiKey: 'AIzaSyBOyqQHzDZAt1ujbpFfpRdKdphO0L9cpfc',
    authDomain: 'web-exchange.firebaseapp.com',
    databaseURL: 'https://web-exchange.firebaseio.com',
    projectId: 'web-exchange',
    storageBucket: 'web-exchange.appspot.com',
    messagingSenderId: '890757176287',
    appId: '1:890757176287:web:c4de489fa28777c45e026a',
    measurementId: 'G-5E6R5H8TX0'
}

const firebaseConfig = {
    apiKey: 'AIzaSyDm8j8nYH11MuY1WFsSUdSp32nQZjXPOLg',
    authDomain: 'api-7762265605376467090-814112.firebaseapp.com',
    databaseURL: 'https://api-7762265605376467090-814112.firebaseio.com',
    projectId: 'api-7762265605376467090-814112',
    storageBucket: 'api-7762265605376467090-814112.appspot.com',
    messagingSenderId: '1000360526097',
    appId: '1:1000360526097:web:cc76bf60cc90f16cce4cb0',
    measurementId: 'G-DTCF48JYH7'
}

class ConfigLocal {
    // baseUrl = 'http://localhost:4000'
    // backendUrl = 'http://localhost:3000'
    // cashbackUrl = 'http://localhost:1337'
    // notificationsUrl = 'http://localhost:3001'
    baseUrl = 'http://192.168.3.185:5050'
    backendUrl = 'http://192.168.3.185:3000'
    cashbackUrl = 'http://localhost:1337'
    notificationsUrl = 'http://localhost:3001'
    // baseUrl = 'http://192.168.1.199:5050'
    // backendUrl = 'http://192.168.1.199:3000'
    // cashbackUrl = 'http://localhost:1337'
    // notificationsUrl = 'http://localhost:3001'
    sumSubUrl = 'https://api.sumsub.com'
    balanceCheck = false
    showError = true
    showStackRequiest = true
    skeletonAnimationDuration = 1 // second
    // captchaSiteKey = '6LdUWZQUAAAAAN-GyfT6XLVNX3ios-t1IyRmMIEm'
    firebase = firebase.initializeApp(firebaseConfigLocal)
    // dsn = 'https://ee441050e3f647a88e338057a49edb08@o566638.ingest.sentry.io/5709715' //shchipskii.trustee.deals
    dsn = 'https://02c4b0a248654efdaa05de1da7c81410@o506838.ingest.sentry.io/5663278' //main
    // dsn = 'https://d6329339055c4da4aa2d0fcf9ca43c59@o558631.ingest.sentry.io/5692344' //ukr.net
    // dsn = "https://375ca5638e3e403f9221090ec7e84b2c@o573556.ingest.sentry.io/5724096" // shchipskii2.trustee.deals
    // __REACT_DEVTOOLS_GLOBAL_HOOK__ = true;
}

class ConfigDevelopment {
    baseUrl = 'https://test.trustee.exchange/'
    backendUrl = 'https://testapiv3.trustee.deals'
    cashbackUrl = 'https://cashback.trustee.deals'
    notificationsUrl = 'https://notifications.trustee.deals'
    sumSubUrl = 'https://api.sumsub.com'
    balanceCheck = false
    showError = true
    showStackRequiest = true
    skeletonAnimationDuration = 1 // second
    // captchaSiteKey = '6LdYQLcZAAAAAE8ahlTyY2XQH8o3N7JQra535oaU'
    firebase = firebase.initializeApp(firebaseConfigLocal)
    dsn = 'https://02c4b0a248654efdaa05de1da7c81410@o506838.ingest.sentry.io/5663278' //main
    // dsn = 'https://d6329339055c4da4aa2d0fcf9ca43c59@o558631.ingest.sentry.io/5692344'
    // dsn = 'https://ee441050e3f647a88e338057a49edb08@o566638.ingest.sentry.io/5709715' //shchipskii.trustee.deals
    // dsn = "https://375ca5638e3e403f9221090ec7e84b2c@o573556.ingest.sentry.io/5724096" // shchipskii2.trustee.deals
    // __REACT_DEVTOOLS_GLOBAL_HOOK__ = true;
}

class ConfigProduction {
    baseUrl = 'https://trustee.exchange/'
    backendUrl = 'https://api.v3.trustee.deals'
    cashbackUrl = 'https://cashback.trustee.deals'
    notificationsUrl = 'https://notifications.trustee.deals'
    sumSubUrl = 'https://api.sumsub.com'
    balanceCheck = true
    showError = false
    showStackRequiest = false
    skeletonAnimationDuration = 1 // second
    // captchaSiteKey = '6LdYQLcZAAAAAE8ahlTyY2XQH8o3N7JQra535oaU'
    firebase = firebase.initializeApp(firebaseConfig)
    dsn = 'https://02c4b0a248654efdaa05de1da7c81410@o506838.ingest.sentry.io/5663278' //main
    // dsn = 'https://ee441050e3f647a88e338057a49edb08@o566638.ingest.sentry.io/5709715' //shchipskii.trustee.deals
    // dsn = "https://375ca5638e3e403f9221090ec7e84b2c@o573556.ingest.sentry.io/5724096" // shchipskii2.trustee.deals
    // __REACT_DEVTOOLS_GLOBAL_HOOK__ = true;
}

const config =
    process.env.NODE_ENV === 'production'
        ? process.env.REACT_APP_MODE === 'DEV'
            ? new ConfigDevelopment()
            : new ConfigProduction()
        : new ConfigLocal()
export default config
