import styled from 'styled-components'

export const ProviderDetailsDiv = styled.div`
    display: flex;
    flex-direction: row;
    padding-top: 8px;
    height: 64px;
    align-items: center;

    .icon__wrapper {
        width: 54px;
    }

    .providerDetails {
        display: flex;
        flex-direction: column;
        height: 38px;
        justify-content: center;
    }

    .providerName {
        font-family: 'Montserrat SemiBold';
        font-size: 16px;
        line-height: 16px;
        color: ${(p) => p.textColor};
    }

    .wrapperDetails {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .rating__wrapper {
        display: flex;
        flex-direction: row;
        min-height: 15px;
        margin-top: 4px;
    }

    .ratingNumber {
        font-family: 'Montserrat Bold';
        font-style: normal;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 1px;
        color: ${(p) => p.textColor};

        margin-left: 7px;
        margin-top: 4px;

        display: flex;
        align-self: center;
    }
`

export const DetailsDiv = styled.div`
    display: flex;
    flex-direction: row;

    .name {
        display: flex;
        white-space: nowrap;

        font-family: 'SF UI Display Semibold';
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 0.5px;
        color: ${(p) => p.textColor};
        padding-bottom: 4px;
    }
`

export const LabelDiv = styled.div`
    min-width: 32px;
    height: 28px;
    background: ${(p) => p.bg};
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat SemiBold';
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 1px;
    color: ${(p) => p.color};
    margin-top: 10px;
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
`
