import React from 'react'
import store from 'redux/index'
import { getThemeModeStyles } from 'theme/useDarkMode'

import InfoModal from './elements/InfoModal'
import SelectModal from './elements/SelectModal'
import YesNoModal from './elements/YesNoModal'

const Modal = (props) => {
    const state = store.getState().modalStore

    const { show, data, callback } = state

    const theme = getThemeModeStyles()

    if (!data) return

    const renderTemplate = () => {
        switch (data.type) {
            case 'INFO_MODAL':
                return <InfoModal show={show} data={data} callback={callback} theme={theme} />
            case 'YES_NO_MODAL':
                return <YesNoModal show={show} data={data} callback={callback} theme={theme} />
            case 'SELECT':
                return <SelectModal show={show} data={data} theme={theme} />
            default:
                return <div></div>
        }
    }

    return <>{renderTemplate()}</>
}

export default Modal
