import axios from 'axios'

import { analytics } from 'firebase'

import { getOutDestinationAddress } from '../../../components/providerItem/helper'

import config from '../../../config/config'

import { strings } from '../../../i18n'

import store from '../../../redux'
import {
    afterKycAction,
    balanceHodlMsgAction,
    exchangeWaysAction,
    inCurrencyAction,
    inCurrencyCoinAction,
    outCurrencyAction,
    outDestinationAction,
    recheckSendAllAction,
    searchModalAction,
    setLimits
} from '../../../redux/AppStores/MobileMarket/action'
import { hideModal, showModal } from '../../../redux/AppStores/ModalStore/action'

import findOutDestination from '../../../services/findOutDestination'
import { getCurrencyCode, getCurrencyName, getTokenNet, isToken } from '../../../services/getCurrenciesProperty'
import { getStatusKYC } from '../../../services/getStatusKYC'
import Log from '../../../services/sentry/Log'
import utilsService from '../../../services/utilsService'
import timed from '../../../services/timed'
import hideAnimation from '../../../services/hideAnimation'
import getActualWays from '../../../services/getActualWays'
import limitScan from '../../../services/limitScan'
import minMaxLimits from '../../../services/minMaxLimit'

const { dispatch } = store

export const getBankFeeString = (feeData, provider) => {
    if (!feeData || !provider) return ''

    const fixedFee = feeData?.providerFee?.in.find((fee) => fee.type === 'FIXED') || ''
    const percentFee = feeData?.providerFee?.in.find((fee) => fee.type === 'PERCENT') || ''

    let fee = percentFee?.amount ? percentFee?.amount + '% ' : ''

    fee += fee && fixedFee?.amount ? '+ ' : ''
    fee += fixedFee?.amount ? `${fixedFee?.amount} ${provider?.inCurrency?.symbol || provider?.outCurrency?.code}` : ''

    const bankFee = fee || ''

    Log.info(`V3.precheckScreen bankFee ${bankFee}`)
    return bankFee
}

export const getNetworkFeeString = (feeData, provider) => {
    if (!feeData || !provider) return ''

    const fixedFee = feeData?.providerFee?.out.find((fee) => fee.type === 'FIXED') || ''
    const percentFee = feeData?.providerFee?.out.find((fee) => fee.type === 'PERCENT') || ''

    let fee = percentFee?.amount ? percentFee?.amount + '% ' : ''

    fee += fee && fixedFee?.amount ? '+ ' : ''
    fee += fixedFee?.amount ? `${fixedFee?.amount} ${provider?.outCurrency.symbol || provider?.outCurrency.code}` : ''

    const networkFee = fee || ''

    Log.info(`V3.precheckScreen networkFee ${networkFee}`)
    return networkFee
}

export const getTrusteeFeeString = (feeData, activeExchangeWay) => {
    if (!feeData || !activeExchangeWay) return ''

    let percent =
        feeData.trusteeFee.out.find((fee) => fee.type === 'PERCENT').amount !== 0
            ? feeData.trusteeFee.out.find((fee) => fee.type === 'PERCENT').amount
            : feeData.trusteeFee.in.find((fee) => fee.type === 'PERCENT').amount
    return percent + '%'

    switch (activeExchangeWay) {
        case 'BUY':
            return feeData.trusteeFee.in.find((fee) => fee.type === 'PERCENT').amount + '%'

        case 'SELL':
            return feeData.trusteeFee.out.find((fee) => fee.type === 'PERCENT').amount + '%'

        case 'EXCHANGE':
            let percent =
                feeData.trusteeFee.out.find((fee) => fee.type === 'PERCENT').amount !== 0
                    ? feeData.trusteeFee.out.find((fee) => fee.type === 'PERCENT').amount
                    : feeData.trusteeFee.in.find((fee) => fee.type === 'PERCENT').amount
            return percent + '%'

        default:
            return ''
    }
}

export const getTrusteeFeeAmount = (provider, activeExchangeWay) => {
    if (!provider || !activeExchangeWay) return ''

    let outFee = (provider.equivalentData.trusteeFee.out !== 0 ? provider.equivalentData.trusteeFee.out : provider.equivalentData.trusteeFee.in) || 0
    return `${utilsService.cutNumber(outFee, 6)} ${activeExchangeWay === 'BUY' ? provider.inCurrency.symbol : provider.outCurrency.symbol}`

    switch (activeExchangeWay) {
        case 'BUY':
            return `${utilsService.cutNumber(provider.equivalentData.trusteeFee.in, 2)} ${provider.inCurrency.symbol}`
        case 'SELL':
            return `${utilsService.cutNumber(provider.equivalentData.trusteeFee.out, 6)} ${provider.outCurrency.symbol}`
        case 'EXCHANGE':
            let outFee =
                (provider.equivalentData.trusteeFee.out !== 0 ? provider.equivalentData.trusteeFee.out : provider.equivalentData.trusteeFee.in) || 0
            return `${utilsService.cutNumber(outFee, 6)} ${provider.outCurrency.symbol}`
        default:
            return ''
    }
}

export const validValue = (value) => {
    let data = value.split(' ')[0]

    if (Number(data) === 0) {
        return false
    } else {
        return true
    }
}

export const showModalBalanceHodl = (newBalanceHodl, outCurrency, outAmount, handleConfirm) => {
    showModal(
        {
            type: 'INFO_MODAL',
            icon: 'WARNING',
            header: strings('mobileMarket.modal.title'),
            description: strings('mobileMarket.modal.notActiveAddress', {
                name: getCurrencyName(outCurrency.code) || outCurrency.name,
                value: `${newBalanceHodl} ${outCurrency.code}`
            })
        },
        async () => {
            hideModal()
            if (Number(outAmount) > newBalanceHodl) {
                dispatch(balanceHodlMsgAction(true))
                await handleConfirm()
            } else {
                window.history.back()
                dispatch(searchModalAction())
            }
        }
    )
    return
}

const getRefundAddress = (localState, currency) => {
    const type = localState.outCurrency.code === 'USDT' ? 'LEGACY' : 'SEGWIT'

    let tmpAddress =
        typeof localState?.walletData?.wallets
            ?.find((wallet) => wallet?.cashbackToken === localState?.cashbackToken)
            ?.accounts?.find((account) => account?.currencyCode === currency?.code)?.address === 'string'
            ? localState.walletData.wallets
                  ?.find((wallet) => wallet?.cashbackToken === localState?.cashbackToken)
                  ?.accounts?.find((account) => account?.currencyCode === currency?.code)?.address
            : localState?.walletData?.wallets
                  ?.find((wallet) => wallet?.cashbackToken === localState?.cashbackToken)
                  ?.accounts?.find((account) => account?.currencyCode === currency?.code)
                  ?.address?.find((address) => address?.type === type)?.address

    if (!tmpAddress) {
        const obj = findOutDestination(localState.walletData, currency.code, localState.cashbackToken)
        dispatch(outDestinationAction(obj?.outDestination || null, obj?.walletName || null))

        if (!isToken(localState.walletData, currency.code)) {
            return null
        }

        const network = getTokenNet(localState.walletData, currency.code)
        Log.info(`V3.renderPrecheckScreen.getRefundAddress getOutDestinationAddress network ${network}`)

        if (network) {
            const { outDestination, walletName } = findOutDestination(localState.walletData, network, localState.cashbackToken, type)
            Log.info(`V3.renderPrecheckScreen.getRefundAddress getOutDestinationAddress outDestination ${outDestination}`)
            Log.info(`V3.renderPrecheckScreen.getRefundAddress getOutDestinationAddress walletName ${walletName}`)
            dispatch(outDestinationAction(outDestination, walletName))
            return outDestination
        }
    }

    return tmpAddress
}

const deltaTime = () => {
    const state = store.getState().mobileMarket

    const timeNow = new Date().getTime()
    const time = state.equivalentTime

    const delta = timeNow - time

    Log.info(`V3.renderPreCheckScreen deltaTime timeNow(${timeNow}) - time(${time}) = delta(${delta})`)

    const equivalentTime = timed(delta)

    return equivalentTime
}

export const createOrder = async (
    activeExchangeWay,
    count,
    setCount,
    loader,
    setLoader,
    skipPaymentDetailsScreen,
    feeTrx = null,
    isNeedKYC = false
) => {
    const state = store.getState().mobileMarket

    Log.info(`V3.precheckScreen.cardValid name ${state.provider?.providerName} -> item ${JSON.stringify(state.provider?.valid)}`)

    // const account = state.activeExchangeWay === 'BUY' ?
    // typeof state.walletData.wallets.find(wallet => wallet.cashbackToken === state.cashbackToken).accounts.find(account => account.currencyCode === state.outCurrency.code).address === 'string' ?
    //     state.walletData.wallets.find(wallet => wallet.cashbackToken === state.cashbackToken).accounts.find(account => account.currencyCode === state.outCurrency.code) :
    //     state.walletData.wallets.find(wallet => wallet.cashbackToken === state.cashbackToken).accounts.find(account => account.currencyCode === state.outCurrency.code)
    // :
    // typeof state.walletData.wallets.find(wallet => wallet.cashbackToken === state.cashbackToken).accounts.find(account => account.currencyCode === state.inCurrency.code).address === 'string' ?
    //     state.walletData.wallets.find(wallet => wallet.cashbackToken === state.cashbackToken).accounts.find(account => account.currencyCode === state.inCurrency.code) :
    //     state.walletData.wallets.find(wallet => wallet.cashbackToken === state.cashbackToken).accounts.find(account => account.currencyCode === state.inCurrency.code)

    const body = {
        inAmount: feeTrx ? state.provider?.equivalentData?.inAmount - feeTrx : state.provider?.equivalentData?.inAmount,
        outAmount: state.provider?.equivalentData?.outAmount,
        exchangeWayId: state.provider?.exchangeWayId,
        amountSide: state.amountSide,
        rateId: state.provider?.equivalentData?.rateData ? state.provider?.equivalentData?.rateData?.rateId : null,
        outDestination: activeExchangeWay === 'SELL' ? state.paymentData?.number || ' ' : state.outDestination,
        outDestinationTag: state.destinationTag,
        deviceToken: state.walletData.deviceToken,
        locale: state.walletData.locale,
        uniqueParams: {
            refundAddress:
                activeExchangeWay === 'BUY'
                    ? getOutDestinationAddress(state) || getRefundAddress(state, state.outCurrency)
                    : getRefundAddress(state, state.inCurrency)
        },
        cashbackToken: state.cashbackToken,
        comment: state.comment,
        equivalentData: state.provider?.equivalentData,
        sign: state.sign,
        paymentDetailsData: {}
    }

    if (state.walletData?.uniqueDeviceId) {
        body.deviceId = state.walletData?.uniqueDeviceId
    }

    if (state.payway && activeExchangeWay && activeExchangeWay !== 'EXCHANGE') {
        Log.info('skipPaymentDetailsScreen ' + JSON.stringify(skipPaymentDetailsScreen))
        if (!skipPaymentDetailsScreen) {
            if (state.payway === 'CARD') {
                body.paymentDetailsData = {
                    ...body.paymentDetailsData,
                    paymentDetails: state?.paymentData?.number,
                    expirationDate: state?.paymentData?.expirationDate,
                    supportedCountries: [state?.paymentData?.countryCode]
                }
            } else {
                body.paymentDetailsData = {
                    ...body.paymentDetailsData,
                    paymentDetails: state?.paymentData?.number,
                    expirationDate: state?.paymentData?.expirationDate
                }
                if (state.payway === 'ADVCASH') {
                    body.uniqueParams.email = state?.paymentData?.cardEmail || state?.paymentData?.email
                }

                if (state.payway === 'CASH') {
                    body.uniqueParams.region = JSON.parse(JSON.parse(state?.paymentData?.cardDetailsJson)?.city)?.country.toUpperCase()
                }
            }
        }
    }

    if (activeExchangeWay && activeExchangeWay !== 'BUY') {
        if (state.newOutDestination && state.useCookie) {
            body.paymentDetailsData = {
                ...body.paymentDetailsData,
                paymentDetails: state.newOutDestination
            }
        } else {
            const { outDestination } = findOutDestination(state.walletData, state.inCurrency.code, state.cashbackToken)
            body.paymentDetailsData = {
                ...body.paymentDetailsData,
                paymentDetails: outDestination
            }
        }
    }

    if (state.newOutDestination) {
        body.invoiceData = {
            isExternalOutDestination: state.newOutDestination
        }
    }

    if (state.provider.providerName === 'KASSA') {
        body.uniqueParams.calculationId = state?.provider?.equivalentData?.rateData?.calculationId
    }

    if (state.provider.providerName === 'JUST_SWAP') {
        body.paymentDetailsData = {
            ...body.paymentDetailsData,
            paymentDetails: state.walletData.wallets
                .find((wallet) => wallet.cashbackToken === state.cashbackToken)
                .accounts.find((account) => account.currencyCode === state.inCurrency.code).address
        }
    }

    if (typeof state.provider.equivalentData.dexData !== 'undefined') {
        body.uniqueParams = {
            ...body.uniqueParams,
            dexData: state.provider?.equivalentData?.dexData
        }
    }

    if (state.provider.providerName === 'UTORG') {
        const email = localStorage.getItem('trusteeEmail') ? localStorage.getItem('trusteeEmail') : null
        let status
        if (email) {
            status = await getStatusKYC(email)
            Log.info(`V3.precheckScreen createOrder email status ${JSON.stringify(status)}`)
            body.uniqueParams.email = status?.userId
        }
    }

    if (typeof isNeedKYC !== 'undefined' && isNeedKYC) {
        const email = localStorage.getItem('trusteeEmail') ? localStorage.getItem('trusteeEmail') : null
        let status

        Log.info(`V3.precheckScreen createOrder email ${JSON.stringify(email)}`)
        if (email) {
            status = await getStatusKYC(email)
            Log.info(`V3.precheckScreen createOrder email status ${JSON.stringify(status)}`)
            body.kycUserId = status?.userId
        }
    }

    Log.info(`V3.precheckScreen createOrder body ${JSON.stringify(body)}`)

    if (activeExchangeWay === 'SELL' && state.paymentData && body.outDestination !== state.paymentData?.number) {
        showModal(
            {
                type: 'INFO_MODAL',
                icon: 'WARNING',
                header: strings('mobileMarket.modal.attention'),
                description: strings('mobileMarket.modal.createOrderError')
            },
            () => {
                hideModal()
            }
        )
        return
    }

    try {
        setCount(1)
        const response = await axios.post(config.backendUrl + '/order/create-order', body).catch((error) => {
            console.log(error.message)
            console.log(error.response.data.message)
            Log.warning(`V3.precheckScreen createOrder error ${JSON.stringify(error.response.data)}`)
            Log.captureError(`V3.precheckScreen createOrder error`)

            if (error?.response?.data?.errorCode === 'NOT_PROFITABLE') {
                showModal({
                    type: 'INFO_MODAL',
                    icon: 'WARNING',
                    header: strings('mobileMarket.modal.sorry'),
                    description: strings('mobileMarket.modal.timeOutOne') + deltaTime() + ' ' + strings('mobileMarket.modal.timeOutTwo')
                })
                return false
            } else if (error?.response?.data?.errorCode === 'EXPIRED_RATE') {
                showModal({
                    type: 'INFO_MODAL',
                    icon: 'WARNING',
                    header: strings('mobileMarket.modal.sorry'),
                    description: strings('mobileMarket.modal.rateId')
                })
                return false
            } else {
                throw new Error(error.response.data.message)
            }
        })

        if (count === 0) {
            const orderData = response.data
            Log.info(`V3.precheckScreen createOrder response.data ${JSON.stringify(response.data)}`)
            return orderData
        }
    } catch (error) {
        // alert(error.message)
        Log.setTag('provider', state.provider.providerName)
        Log.warning(`V3.precheckScreen createOrder error ${JSON.stringify(error)}`)
        Log.captureError(`V3.precheckScreen createOrder error`)

        setLoader(false)
        dispatch(recheckSendAllAction(false))

        showModal(
            {
                type: 'INFO_MODAL',
                icon: 'WARNING',
                header: strings('mobileMarket.modal.attention'),
                description: config.showError ? error.message.toString() : strings('mobileMarket.modal.createOrderError')
            },
            () => {
                hideModal()
            }
        )

        return false
    }
}

export const statusErrorKYC = (afterKYC, errorStatus = false) => {
    showModal(
        {
            type: 'INFO_MODAL',
            icon: 'WARNING',
            header: strings('mobileMarket.modal.sorry'),
            description: errorStatus ? strings('mobileMarket.modal.verificationError') : strings('mobileMarket.modal.serverRespond')
        },
        () => {
            hideModal()
            if (afterKYC) {
                window.history.go(-2)
            } else {
                window.history.back()
            }

            dispatch(afterKycAction(false))
        }
    )
}

export const setAnalytics = (exchangeWaysArray, allExchangeWays, providerData) => {
    const tmpProvider = exchangeWaysArray.filter((way) => way?.equivalentData && way?.equivalentData?.status === 'SUCCESS')

    if (tmpProvider.length > 1) {
        const tmpWay = allExchangeWays.find((way) => way.exchangeWayId === tmpProvider[0].exchangeWayId)
        const selectWay = allExchangeWays.find((way) => way.exchangeWayId === providerData.exchangeWayId)

        if (tmpProvider[0].exchangeWayId !== providerData.exchangeWayId) {
            Log.info(
                `V3.precheckScreen.select_provider ${JSON.stringify({ ...providerData, labels: JSON.stringify(selectWay?.exchangeWayLabelCodes) })}`
            )
            try {
                analytics().logEvent('V3.precheckScreen.select_provider', {
                    ...providerData,
                    labels: selectWay?.exchangeWayLabelCodes || ''
                })
            } catch (e) {
                Log.error(`V3.precheckScreen error ${JSON.stringify(e)}`)
                Log.captureError(`V3.precheckScreen ERROR`)
            }
        }
        Log.info(`V3.precheckScreen.best_provider ${JSON.stringify({ ...tmpProvider[0], labels: JSON.stringify(tmpWay?.exchangeWayLabelCodes) })}`)
        try {
            analytics().logEvent('V3.precheckScreen.best_provider', {
                ...tmpProvider[0],
                labels: tmpWay?.exchangeWayLabelCodes || ''
            })
        } catch (e) {
            Log.error(`V3.precheckScreen error ${JSON.stringify(e)}`)
            Log.captureError(`V3.precheckScreen ERROR`)
        }
    }
}

export const handleBuyNativeCoin = async (params) => {
    const { nativeCoin, walletData, cashbackToken, enqueueSnackbar, preRequestExchangeWays } = params

    hideAnimation('YesNoModal', hideModal)
    window.history.back()

    const { outDestination, walletName } = findOutDestination(walletData, nativeCoin.currencyCode, cashbackToken)

    const inCurrency = { code: 'UAH', type: 'FIAT', symbol: 'UAH' }
    const outCurrency = { code: nativeCoin.currencyCode, type: 'CRYPTO', symbol: nativeCoin.currencyCode }

    dispatch(inCurrencyAction(inCurrency, false, false, 'CARD'))
    dispatch(outCurrencyAction(outCurrency, false, true, 'CARD', outDestination, walletName))

    let getWays, limits, array, exchangeWaysArray
    try {
        getWays = await getActualWays(
            inCurrency,
            outCurrency,
            'CARD',
            cashbackToken,
            walletData.sign.message,
            walletData.sign.messageHash,
            walletData.sign.signature,
            walletData.tester,
            enqueueSnackbar
        )
    } catch (e) {
        console.log(JSON.stringify(e))
        getWays = { dataAll: [], comingSoon: [] }
    }

    exchangeWaysArray = getWays.dataAll && getWays.dataAll.length ? getWays.dataAll : []

    const results = exchangeWaysArray.length ? exchangeWaysArray.slice(0, 30) : []

    dispatch(exchangeWaysAction(exchangeWaysArray, [], results, true, false))

    array = preRequestExchangeWays.filter(
        (way) => way.inCurrency.code === inCurrency.code && way.outCurrency.code === outCurrency.code && way.inPaywayCode === 'CARD'
    )

    limits = await limitScan(array)
    limits = minMaxLimits(array, exchangeWaysArray, limits || [])

    dispatch(setLimits(limits))
}
