import React, { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import AnimateHeight from 'react-animate-height'

import { useSnackbar } from 'notistack'

import { strings } from 'i18n'

import CheckData from 'modules/mobileMarket/preCheck/CheckData/CheckData'
import Icon from 'components/Icon/Icon'

import OrderDataWrapper from './orderData.css'

const getIcon = (type, color) => {
    switch (type) {
        case 'ID':
            return <Icon icon='ID' size={20} color={color} />
        case 'RATE':
            return <Icon icon='RATE' size={20} color={color} />
        case 'CARD':
            return <Icon icon='CARD' size={22} color={color} />
        case 'USER':
            return <Icon icon='USER' size={20} color={color} />
        case 'PROVIDER':
            return <Icon icon='PROVIDER_DEFAULT' size={22} color={color} />
        default:
            return <Icon icon='USER' size={20} color={color} />
    }
}

const OrderDataItem = (props) => {
    const { title, description, iconType, typeBtn, btnAction, copyAction, extraData, line, copyValue, trusteeAdress, id, theme } = props

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const [show, setShow] = useState(false)
    const [height, setHeight] = useState(85)

    const showMore = () => {
        setShow(!show)
        setHeight('auto')
    }

    const hiddenMore = () => {
        setTimeout(() => {
            setShow(!show)
        }, 500)
        setHeight(85)
    }

    return (
        <AnimateHeight
            id='rate'
            duration={500}
            height={height} // see props documentation below
            style={{
                borderRadius: 16
            }}
            easing={'linear'}>
            <OrderDataWrapper
                id={id}
                bg={theme.orderHistory.orderData.bg}
                valueColor={theme.topNav.colorIcon}
                textColor={theme.topNav.color}
                lineColor={theme.orderHistory.dashColor}>
                <div className='wrapper'>
                    {copyValue ? (
                        <CopyToClipboard
                            text={copyValue}
                            onCopy={() => {
                                const canVibrate = window.navigator.vibrate
                                if (canVibrate) window.navigator.vibrate(100)
                                enqueueSnackbar(title)
                            }}>
                            <div className='data'>
                                <div className='iconWrapper'>{getIcon(iconType, theme.topNav.colorIcon)}</div>
                                <div className='dataWrapper'>
                                    <div className='title'>
                                        {title}
                                        {trusteeAdress && (
                                            <div style={{ marginLeft: 8 }}>
                                                <Icon icon='SHIELD' color='#999999' size={18} />
                                            </div>
                                        )}
                                    </div>
                                    <div className='mainData'>{description}</div>
                                </div>
                            </div>
                        </CopyToClipboard>
                    ) : (
                        <div className='data'>
                            <div className='iconWrapper'>{getIcon(iconType, theme.topNav.colorIcon)}</div>
                            <div className='dataWrapper'>
                                <div className='title'>
                                    {title}
                                    {trusteeAdress && (
                                        <div style={{ marginLeft: 8 }}>
                                            <Icon icon='SHIELD' color='#999999' size={18} />
                                        </div>
                                    )}
                                </div>
                                <div className='mainData'>{description}</div>
                            </div>
                        </div>
                    )}
                    <div
                        className='buttonWrapper'
                        onClick={typeBtn === 'btn' ? btnAction : extraData && extraData.length ? (show ? hiddenMore : showMore) : null}>
                        {typeBtn === 'arrow' ? (
                            extraData && !extraData.length ? null : (
                                <div className='arrowBtn'>
                                    <Icon icon='ARROW_DOWN' color={theme.topNav.colorIcon} style={{ transform: show ? 'rotate(180deg)' : '' }} />
                                </div>
                            )
                        ) : typeBtn === 'btn' ? (
                            <div className='txBtn'>{strings('mobileCheck.tx')}</div>
                        ) : null}
                    </div>
                </div>
                {show ? (
                    extraData && extraData.length ? (
                        <>
                            <div className='line'></div>
                            <div className='extraData'>
                                {extraData && extraData.length
                                    ? extraData.map((item) => {
                                          if (item?.isFeesIncludedInRate) {
                                              return (
                                                  <div className='mainData' key={item.title} style={{ paddingBottom: 12 }}>
                                                      {item.title}
                                                  </div>
                                              )
                                          }

                                          return (
                                              <CheckData
                                                  key={item.title}
                                                  condition={item.condition}
                                                  name={item.title}
                                                  value={item.description}
                                                  style={{ marginTop: -10 }}
                                              />
                                          )
                                      })
                                    : null}
                            </div>
                        </>
                    ) : null
                ) : null}
            </OrderDataWrapper>
        </AnimateHeight>
    )
}

export default OrderDataItem
