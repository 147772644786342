const hideAnimation = (id, hideModal) => {
    const modal = document.getElementById(id)
    if (modal) {
        modal.classList.remove('fade-in')
        modal.children[0].classList.remove('fade-in')
        modal.classList.add('fade-out')
        modal.children[0].classList.remove('fade-out')

        setTimeout(() => hideModal(), 250)
    }
}

export default hideAnimation
