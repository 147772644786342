import queryString from 'query-string'
import * as Sentry from '@sentry/react'
import CurrencyList from 'currency-list'

import i18n, { strings } from 'i18n'

import store from 'redux/index'
import { INIT } from 'redux/AppStores/type'
import { hideModal, showModal } from 'redux/AppStores/ModalStore/action'

import Log from 'services/sentry/Log'
import findOutDestination from 'services/findOutDestination'
import getWaysData from 'services/getWaysData'
import { fromCustomToken } from 'services/fromCustom'
import { axiosRequest, serverRequest } from 'services/getActualWays'
import isActiveCoin from 'services/isActiveCoin'
import minMaxLimits from 'services/minMaxLimit'

const { dispatch } = store

const cardStatusGet = async (walletData) => {
    let card =
        typeof walletData?.cards !== 'undefined' && walletData?.cards
            ? walletData?.cards?.filter((way) => way.type === 'visa' || way.type === 'mastercard' || way.type === 'mir' || way.type === 'maestro')
            : []
    const cardNumbers = card?.length ? card?.map((item) => item.number) : []

    let res

    if (cardNumbers.length) {
        try {
            res = await axiosRequest(
                `/payment-details/validation-status?cardNumber=${cardNumbers.join()}`,
                strings('mobileMarket.modal.serverRespond')
            )
            Log.info(`payment-details res for ${cardNumbers.join()} ${res}`)
            return {
                res,
                card
            }
        } catch (e) {
            console.log(JSON.stringify(e))
            return null
        }
    } else {
        return null
    }
}

export default async function MobileMarket(enqueueSnackbar) {
    // get params from url
    const url = window.location.href
    const urlParamsString = url.slice(url.indexOf('?') + 1)
    const urlParamsObj = queryString.parse(urlParamsString)
    const cashbackToken = urlParamsObj.cashbackToken
    const nameWallet = urlParamsObj.wallet
    const localeWallet = urlParamsObj.locale
    const skipProvidersScreen = typeof urlParamsObj.skipProvidersScreen !== 'undefined' ? JSON.parse(urlParamsObj.skipProvidersScreen) : false
    const inCurrencyFromLink = typeof urlParamsObj.inCurrency !== 'undefined' && urlParamsObj.inCurrency !== 'false' ? urlParamsObj.inCurrency : false
    const outCurrencyFromLink =
        typeof urlParamsObj.outCurrency !== 'undefined' && urlParamsObj.outCurrency !== 'false' ? urlParamsObj.outCurrency : false
    const useCookie = typeof urlParamsObj.useCookie !== 'undefined' ? JSON.parse(urlParamsObj.useCookie) : false
    const useFirebase = typeof urlParamsObj.useFirebase !== 'undefined' ? JSON.parse(urlParamsObj.useFirebase) : true

    const isLight = typeof urlParamsObj.isLight !== 'undefined' ? JSON.parse(urlParamsObj.isLight) : true
    localStorage.setItem('themeMode', isLight ? 'light' : 'dark')

    Sentry.setUser({ username: cashbackToken })
    Sentry.setTag('LOG_CASHBACK', cashbackToken)

    const version = urlParamsObj.version || null
    Sentry.setTag('LOG_VERSION', version)

    let versionNumber = version ? version.toString().split('_')[1] : null
    const tester = versionNumber === 'tester' ? true : false
    versionNumber = tester ? version.split('_')[2] : versionNumber

    if (!urlParamsObj.cashbackToken || !urlParamsObj.message || !urlParamsObj.messageHash || !urlParamsObj.signature) {
        window.open('/', '_self')
    }

    // BSE integration
    const postBody = {
        cashbackToken: cashbackToken,
        signData: {
            message: urlParamsObj.message,
            messageHash: urlParamsObj.messageHash,
            signature: urlParamsObj.signature
        }
    }

    // links
    const walletDataLink = `/data/wallet?message=${urlParamsObj.message}&messageHash=${urlParamsObj.messageHash}&signature=${urlParamsObj.signature}&cashbackToken=${cashbackToken}`
    const dataAllPrerequest = `/data/all-prerequest`
    // ?cashbackToken=${cashbackToken}
    const getServerTime = `/data/server-time`
    // BSE integration
    const walletSignCheck = `/validation/check-sign`

    let serverTime, walletData, preRequestExchagneWays, signCheck, cardStatus

    if (useFirebase) {
        ;[serverTime, walletData, preRequestExchagneWays] = await Promise.all([
            serverRequest(getServerTime, strings('mobileMarket.modal.serverRespond'), 'SERVER_TIME', null, tester, enqueueSnackbar),
            serverRequest(walletDataLink, strings('mobileMarket.modal.serverRespond'), 'WALLET_DATA', null, tester, enqueueSnackbar),
            serverRequest(dataAllPrerequest, strings('mobileMarket.modal.serverRespond'), 'DATA_ALL', null, tester, enqueueSnackbar)
        ])
    } else {
        // BSE integration
        ;[serverTime, signCheck, preRequestExchagneWays] = await Promise.all([
            serverRequest(getServerTime, strings('mobileMarket.modal.serverRespond'), 'SERVER_TIME', null, tester, enqueueSnackbar),
            serverRequest(walletSignCheck, strings('mobileMarket.modal.serverRespond'), 'WALLET_DATA', postBody, tester, enqueueSnackbar),
            serverRequest(dataAllPrerequest, strings('mobileMarket.modal.serverRespond'), 'DATA_ALL', null, tester, enqueueSnackbar)
        ])
    }

    // modal if walletData is undefined
    if (useFirebase && (walletData === null || typeof walletData === 'undefined' || (walletData && !walletData.wallets[0].accounts))) {
        Log.error(`V3.MobileMarketAction walletData null`)
        Log.captureError('V3.MobileMarketAction walletData error')
        showModal(
            {
                type: 'INFO_MODAL',
                icon: 'WARNING',
                header: strings('mobileMarket.modal.sorry'),
                description: strings('mobileMarket.modal.serverRespond'),
                close: () => window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ error: 'error' }))
            },
            () => {
                window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ error: 'error' }))
                hideModal()
            }
        )
        return
    }

    if (useFirebase && walletData !== null) {
        // console.log(`V3.MobileMarketAction walletData ${JSON.stringify(walletData)}`)
        Sentry.setTag('LOG_TOKEN_FULL', walletData.deviceToken)
        Log.info(`V3.MobileMarketAction walletData ${JSON.stringify(walletData)}`)

        // prepare custom token
        const coins = fromCustomToken(walletData.wallets)

        walletData = {
            ...walletData,
            wallets: coins
        }

        // scan payment details and delete copy item
        if (typeof walletData?.cards !== 'undefined') {
            if (walletData?.cards?.length !== 0) {
                const cards =
                    walletData?.cards ||
                    [].filter((card) => card.type === 'visa' || card.type === 'mastercard' || card.type === 'mir' || card.type === 'maestro')
                // .map((item) => {
                //     return {
                //         id: item.id,
                //         data: JSON.stringify({ number: item.number, countryCode: item.countryCode, expirationDate: item.expirationDate })
                //     }
                // })
                if (cards.length > 1) {
                    Log.info(`V3.MobileMarketActions cards ${JSON.stringify(cards)}`)
                    for (let i = 0; i < cards.length - 1; i++) {
                        for (let j = i + 1; j < cards.length; j++) {
                            if (
                                String(cards?.[i]?.number) === String(cards?.[j]?.number) &&
                                String(cards?.[i]?.countryCode) === String(cards?.[j]?.countryCode) &&
                                String(cards?.[i]?.expirationDate) === String(cards?.[j]?.expirationDate)
                            ) {
                                window.ReactNativeWebView.postMessage(
                                    JSON.stringify({
                                        deleteCard: cards?.[j]?.id,
                                        deleteCardSource: 'marketActions',
                                        deleteCardCardsArray: JSON.stringify(cards),
                                        deleteCardValue: JSON.stringify(cards?.[j])
                                    })
                                )
                                const index = walletData?.cards?.indexOf(walletData?.cards?.find((item) => item?.id === cards?.[j]?.id))
                                walletData.cards.splice(index, 1)
                            }
                            // if (JSON.stringify(cards[i].data) === JSON.stringify(cards[j].data)) {

                            // }
                        }
                    }
                }
            }
        }

        // modal if not cashbackToken
        if (typeof walletData.wallets !== 'undefined') {
            walletData.wallets.map((item) => {
                Sentry.setTag('LOG_WALLET', item.walletHash)
                if (!item.cashbackToken) {
                    Log.error(`V3.MobileMarketAction cashbackToken null`)
                    Log.captureError('V3.MobileMarketAction cashbackToken error')
                    showModal(
                        {
                            type: 'INFO_MODAL',
                            icon: 'WARNING',
                            header: strings('mobileMarket.modal.sorry'),
                            description: strings('mobileMarket.modal.serverRespond'),
                            close: () => window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ error: 'error' }))
                        },
                        () => {
                            window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ error: 'error' }))
                            hideModal()
                        }
                    )
                    return
                }
            })
        }
    }

    // set inCurrency, outCurrency, payway
    let commingSoonArray
    let inCurrency, outCurrency, inCurrencyCode, outCurrencyCode, currencyCodeIn, currencyCodeOut, exchangeWaysArray

    let payway = localStorage.getItem('payway') || 'CARD'

    currencyCodeIn = inCurrencyFromLink
        ? CurrencyList.get(inCurrencyFromLink)
            ? { code: inCurrencyFromLink, type: 'FIAT', symbol: inCurrencyFromLink }
            : { code: inCurrencyFromLink, type: 'CRYPTO', symbol: inCurrencyFromLink }
        : null
    currencyCodeOut = outCurrencyFromLink
        ? CurrencyList.get(outCurrencyFromLink)
            ? { code: outCurrencyFromLink, type: 'FIAT', symbol: outCurrencyFromLink }
            : { code: outCurrencyFromLink, type: 'CRYPTO', symbol: outCurrencyFromLink }
        : null

    inCurrencyCode = localStorage.getItem('inCurrencyCode')
        ? JSON.parse(localStorage.getItem('inCurrencyCode'))
        : currencyCodeIn
        ? currencyCodeIn
        : { code: 'USD', type: 'FIAT', symbol: 'USD' }
    outCurrencyCode = currencyCodeOut
        ? currencyCodeOut
        : localStorage.getItem('outCurrencyCode')
        ? JSON.parse(localStorage.getItem('outCurrencyCode'))
        : { code: 'BTC', type: 'CRYPTO', symbol: 'BTC' }

    if (JSON.stringify(inCurrencyCode) === JSON.stringify(outCurrencyCode)) {
        outCurrencyCode =
            inCurrencyCode.code === 'ETH' ? { code: 'BTC', type: 'CRYPTO', symbol: 'BTC' } : { code: 'ETH', type: 'CRYPTO', symbol: 'ETH' }
    }

    // modal if activeExchangeWays is undefined
    if (!preRequestExchagneWays || !preRequestExchagneWays.length) {
        Log.error(`V3.MobileMarketAction preRequestExchagneWays null`)
        Log.captureError('V3.MobileMarketAction preRequestExchagneWays error')
        showModal(
            {
                type: 'INFO_MODAL',
                icon: 'WARNING',
                header: strings('mobileMarket.modal.sorry'),
                description: strings('mobileMarket.modal.serverRespond'),
                close: () => window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ error: 'error' }))
            },
            () => {
                window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ error: 'error' }))
                hideModal()
            }
        )
    }

    ;[cardStatus] = await Promise.all([cardStatusGet(walletData)])

    let allExchangeWays
    preRequestExchagneWays =
        preRequestExchagneWays && preRequestExchagneWays.length
            ? preRequestExchagneWays.map((way) =>
                  way.exchangeWayType === 'BUY' && way.inPaywayCode.indexOf('VISA_MC') !== -1
                      ? { ...way, inPaywayCode: 'CARD' }
                      : way.exchangeWayType === 'SELL' && way.outPaywayCode.indexOf('VISA_MC') !== -1
                      ? { ...way, outPaywayCode: 'CARD' }
                      : way
              )
            : []

    // // set locale
    const locale = typeof localeWallet !== 'undefined' ? (['en', 'ru', 'uk'].includes(localeWallet) ? localeWallet : 'en') : 'en'
    i18n.changeLanguage(locale)

    // get need exchange pair
    const waysData = await getWaysData(
        inCurrencyCode,
        outCurrencyCode,
        payway,
        preRequestExchagneWays,
        cashbackToken,
        urlParamsObj.message,
        urlParamsObj.messageHash,
        urlParamsObj.signature,
        tester,
        enqueueSnackbar
    )

    inCurrency = waysData?.inCurrency
    // .type === 'CRYPTO' && !nameWallet ? isActiveCoin(walletData, waysData.inCurrency, cashbackToken) : waysData.inCurrency
    outCurrency = waysData?.outCurrency
    // .type === 'CRYPTO' && !nameWallet ? isActiveCoin(walletData, waysData.outCurrency, cashbackToken) : waysData.outCurrency
    exchangeWaysArray = waysData?.exchangeWays
    allExchangeWays = waysData?.activeExchangeWays
    commingSoonArray = waysData?.commingArray

    let obj

    if (outCurrency && outCurrency.type === 'CRYPTO' && useFirebase) {
        const type = outCurrency.code === 'USDT' ? 'LEGACY' : 'SEGWIT'
        obj = findOutDestination(walletData, outCurrency.code, cashbackToken, type)
    }

    // update cards
    if (useFirebase) {
        try {
            let card
            if (typeof cardStatus.res !== 'undefined' && cardStatus.res) {
                if (typeof cardStatus.card !== 'undefined' && cardStatus.card.length > 1) {
                    card = cardStatus.card.map((item) => {
                        const tempCards = cardStatus.res.find((way) => way.cardNumber === item.number)
                        let data =
                            typeof item.cardVerificationJson !== 'undefined' && item.cardVerificationJson
                                ? JSON.parse(item.cardVerificationJson)
                                : null
                        data = {
                            ...data,
                            verificationStatus: tempCards.verificationStatus
                        }
                        if (typeof tempCards.message !== 'undefined') {
                            data.message = tempCards.message
                        }
                        if (typeof tempCards.firstName !== 'undefined') {
                            data.firstName = tempCards.firstName
                        }
                        if (typeof tempCards.lastName !== 'undefined') {
                            data.lastName = tempCards.lastName
                        }
                        if (typeof tempCards.updatedAt !== 'undefined') {
                            data.updatedAt = tempCards.updatedAt
                        }
                        return {
                            ...item,
                            cardVerificationJson: JSON.stringify(data)
                        }
                    })
                } else if (typeof cardStatus.card !== 'undefined' && cardStatus.card.length === 1) {
                    let data =
                        typeof cardStatus.card[0].cardVerificationJson !== 'undefined' && cardStatus.card[0].cardVerificationJson
                            ? JSON.parse(cardStatus.card[0].cardVerificationJson)
                            : null
                    data = {
                        ...data,
                        verificationStatus: cardStatus.res.verificationStatus
                    }
                    if (typeof cardStatus.res.message !== 'undefined') {
                        data.message = cardStatus.res.message
                    }
                    if (typeof cardStatus.res.firstName !== 'undefined') {
                        data.firstName = cardStatus.res.firstName
                    }
                    if (typeof cardStatus.res.lastName !== 'undefined') {
                        data.lastName = cardStatus.res.lastName
                    }
                    if (typeof cardStatus.res.updatedAt !== 'undefined') {
                        data.updatedAt = cardStatus.res.updatedAt
                    }
                    card = [
                        {
                            ...cardStatus.card[0],
                            cardVerificationJson: JSON.stringify(data)
                        }
                    ]
                }
            }
            if (typeof walletData?.cards !== 'undefined') {
                walletData.cards = [
                    ...walletData?.cards?.filter(
                        (way) => way.type !== 'visa' && way.type !== 'mastercard' && way.type !== 'mir' && way.type !== 'maestro'
                    ),
                    ...card
                ]
            }
        } catch (e) {
            console.log('e', JSON.stringify(e))
            // showModal({
            //     type: 'INFO_MODAL',
            //     icon: 'WARNING',
            //     header: strings('mobileMarket.modal.sorry'),
            //     description: strings('mobileMarket.modal.serverRespond'),
            //     close: () => window.ReactNativeWebView.postMessage(JSON.stringify({ 'error': 'error' }))
            // }, () => {
            //     window.ReactNativeWebView.postMessage(JSON.stringify({ 'error': 'error' }))
            //     hideModal()
            // })
            // return
        }
    }

    const showGuide = localStorage.getItem('tssGuide') ? JSON.parse(localStorage.getItem('tssGuide')) : true

    const amountData = localStorage.getItem('amountData') ? JSON.parse(localStorage.getItem('amountData')) : null

    let limits = minMaxLimits(allExchangeWays, exchangeWaysArray, [])

    console.log('inCurrency', inCurrency)
    console.log('outCurrency', outCurrency)

    dispatch({
        type: INIT,
        preRequestExchagneWays,
        allExchangeWays,
        commingSoonArray,
        walletData,
        screen: 1,
        cashbackToken,
        inCurrency,
        outCurrency,
        payway,
        exchangeWaysArray,
        version,
        versionNumber,
        amountSide: amountData ? amountData?.side || 'IN' : 'IN',
        amount: amountData ? amountData.amount : null,
        sign: {
            message: urlParamsObj.message,
            messageHash: urlParamsObj.messageHash,
            signature: urlParamsObj.signature
        },
        outDestination: obj && obj.outDestination ? obj.outDestination : null,
        walletName: obj && obj.walletName ? obj.walletName : null,
        showGuide,
        time: serverTime,
        refreshTime: new Date(),
        useCookie,
        useFirebase,
        tester,
        limits,

        // BSE integration
        skipProvidersScreen,
        trusteeWallet: useCookie ? false : true
    })
}
