import store from '../../index'
import { SHOW_MODAL, HIDE_MODAL, INIT } from '../type'

const { dispatch } = store

export function modal() {
    dispatch({
        type: INIT
    })
}

export function showModal(data, callback) {
    dispatch({
        type: SHOW_MODAL,
        data,
        callback
    })
}

export function hideModal() {
    dispatch({
        type: HIDE_MODAL,
        data: null,
        callback: null
    })
}
