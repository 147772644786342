import React from 'react'
import { useSelector } from 'react-redux'

import ProviderItem from '../../../components/providerItem/providerItem'
import { strings } from '../../../i18n'
import { getAmountData, getExchangeWaysArray, getUserCurrencies, getPropsModal } from '../../../redux/AppStores/MobileMarket/selectors'

import { ModalWrapper } from '../../ScreenModal/elements/wrapper.css'
import { ProviderListDiv } from './wrapper.css'

import SearchLayout from './Layout/Layout'

const ProviderListModal = (props) => {
    const { show, data, callback, theme } = props

    const exchangeWaysArray = useSelector(getExchangeWaysArray)
    const amountData = useSelector(getAmountData)
    const userCurrencies = useSelector(getUserCurrencies)
    const propsModal = useSelector(getPropsModal)

    return (
        <SearchLayout
            id='providerList'
            header={strings('mobileMarket.navigation.providers')}
            show={show}
            styles={{ left: propsModal ? 0 : '', opacity: 1, transform: propsModal ? 'translate3d(0, 0, 0)' : '' }}
            leftType='back'
            leftAction={data.close}>
            <ModalWrapper background={theme.main.bg}>
                <ProviderListDiv loaderBG={theme.provider.failBg} loaderColor={theme.topNav.color}>
                    <div className='progress-bar-full'>
                        <div className='progress-bar-text'>
                            {strings('mobileMarket.searchResults')}
                            <div className='progress-bar-text'>
                                {amountData.amount +
                                    ' ' +
                                    (amountData.amountSide === 'IN' ? userCurrencies.inCurrency.symbol : userCurrencies.outCurrency.symbol)}
                            </div>
                        </div>
                    </div>
                    {exchangeWaysArray && exchangeWaysArray.length
                        ? exchangeWaysArray.map((item, index) => {
                              let bestOffer =
                                  exchangeWaysArray.length > 1 || (exchangeWaysArray.length > 1 && typeof item.equivalentData !== 'undefined')
                                      ? exchangeWaysArray[0]
                                      : null

                              return (
                                  <ProviderItem
                                      theme={theme}
                                      key={index}
                                      item={item}
                                      offer={bestOffer}
                                      requestId={data.requestId}
                                      providersArray={exchangeWaysArray}
                                      newFlow
                                      // callback={}
                                  />
                              )
                          })
                        : null}
                </ProviderListDiv>
            </ModalWrapper>
        </SearchLayout>
    )
}

export default React.memo(ProviderListModal)
