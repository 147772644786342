import React, { useRef, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SwipeableViews from 'react-swipeable-views'
import { useSnackbar } from 'notistack'
import _sortBy from 'lodash/sortBy'

import { strings } from 'i18n'

import CryptoCurrencies from 'components/currencies/crypto/CurrencyDict'
import Icon from 'components/Icon/Icon'

import {
    getActiveCoins,
    getAllExchangeWays,
    getPreRequestExchangeWays,
    getUserCurrencies,
    getUserSettings,
    getWalletData
} from 'redux/AppStores/MobileMarket/selectors'
import { hideScreenModal } from 'redux/AppStores/ScreenModalStore/action'
import {
    exchangeWaysAction,
    inCurrencyAction,
    inCurrencyNotActiveAction,
    outCurrencyAction,
    outCurrencyNotActiveAction,
    savePositionAction,
    setRocketLoaderAction,
    setLimits,
    setAllFundsData
} from 'redux/AppStores/MobileMarket/action'

import findOutDestination from 'services/findOutDestination'
import Log from 'services/sentry/Log'
import hideAnimation from 'services/hideAnimation'
import getActualWays from 'services/getActualWays'
import { sortWays } from 'services/sortWays'
import limitScan from 'services/limitScan'
import minMaxLimits from 'services/minMaxLimit'

import CurrencyList from '../CurrencyList/currencyList'

import { BodyWrapper } from '../wrapper.css'

const mainCoin = ['UAH', 'RUB', 'USD', 'EUR', 'BTC', 'ETH', 'TRX', 'ETH_USDT', 'TRX_USDT']

const CurrencyModal = (props) => {
    const userCurrencies = useSelector(getUserCurrencies)
    const preRequestExchangeWays = useSelector(getPreRequestExchangeWays)
    const allExchangeWays = useSelector(getAllExchangeWays)
    const walletData = useSelector(getWalletData)
    const settings = useSelector(getUserSettings)
    const activeCoins = useSelector(getActiveCoins)

    const dispatch = useDispatch()
    const swipeList = useRef()
    const { enqueueSnackbar } = useSnackbar()

    const { inCurrency, outCurrency, payway, cashbackToken } = userCurrencies

    const { side, setShowError, setShowErrorBtn, fiatArray, wallet, theme, searchValue } = props

    const [type, setType] = useState(
        side === 'IN'
            ? outCurrency && outCurrency.type === 'FIAT'
                ? 'CRYPTO'
                : 'ALL'
            : side === 'OUT'
            ? inCurrency && inCurrency.type === 'FIAT'
                ? 'CRYPTO'
                : 'ALL'
            : 'ALL'
    )
    const [indexValue, setIndex] = useState(
        side === 'IN' ? (outCurrency && outCurrency.type === 'FIAT' ? 1 : 0) : side === 'OUT' ? (inCurrency && inCurrency.type === 'FIAT' ? 1 : 0) : 0
    )
    const [showPopular, setPopular] = useState(true)
    const [showCrypto, setCrypto] = useState(true)
    const [showFiat, setFiat] = useState(true)

    const searchCryptoArray = Object.keys(CryptoCurrencies).filter((key) =>
        CryptoCurrencies[key].currencyName.toUpperCase().includes(side === 'IN' ? searchValue.searchValueInSide : searchValue.searchValueOutSide)
    )

    const searchFiatArray = fiatArray
        .filter((item) =>
            item.value
                .toString()
                .toUpperCase()
                .includes(side === 'IN' ? searchValue.searchValueInSide : searchValue.searchValueOutSide)
        )
        .map((item) => item.code)

    let currency = side === 'IN' ? activeCoins?.activeInCurrency || [] : activeCoins?.activeOutCurrency || []

    const currencyOnFilter =
        currency && currency.length ? Array.from(currency.filter((item) => mainCoin.includes(item.code)).map((item) => item)) : currency
    const currencyNotFilter =
        currency && currency.length ? Array.from(currency.filter((item) => !mainCoin.includes(item.code)).map((item) => item)) : currency
    currency = currencyOnFilter && currencyNotFilter ? [...currencyOnFilter, ...currencyNotFilter] : currency

    const availableForExchange = () => {
        let available
        if (currency && currency.length && inCurrency && side === 'OUT') {
            available = Array.from(
                new Set(
                    preRequestExchangeWays
                        .filter(
                            (way) =>
                                way.inCurrency.code === inCurrency.code && (inCurrency.type === 'FIAT' && payway ? way.inPaywayCode === payway : true)
                        )
                        .map((item) => JSON.stringify(item.outCurrency))
                )
            )
            available = available.map((item) => {
                const tmp = JSON.parse(item)
                const tmpWay = currency.find((way) => way.code === tmp.code)
                if (tmpWay) {
                    return tmpWay
                } else {
                    return tmp
                }
            })
        } else if (currency && currency.length && outCurrency && side === 'IN') {
            available = Array.from(
                new Set(
                    preRequestExchangeWays
                        .filter(
                            (way) =>
                                way.outCurrency.code === outCurrency.code &&
                                (outCurrency.type === 'FIAT' && payway ? way.outPaywayCode === payway : true)
                        )
                        .map((item) => JSON.stringify(item.inCurrency))
                )
            )
            available = available.map((item) => {
                const tmp = JSON.parse(item)
                const tmpWay = currency.find((way) => way.code === tmp.code)
                if (tmpWay) {
                    return tmpWay
                } else {
                    return tmp
                }
            })
        }
        return available
    }

    const sortCoin = (currency, accounts) => {
        let result
        if (currency && accounts) {
            result = currency.sort((a, b) => {
                let numberA = accounts.accounts.find((item) => item.currencyCode === a.code)
                let numberB = accounts.accounts.find((item) => item.currencyCode === b.code)
                const d =
                    a.type === 'CRYPTO'
                        ? Number(typeof numberA !== 'undefined' ? numberA.basicCurrencyBalance.toString().replace(/\s+/g, '') : '0')
                        : 0
                const c =
                    b.type === 'CRYPTO'
                        ? Number(typeof numberB !== 'undefined' ? numberB.basicCurrencyBalance.toString().replace(/\s+/g, '') : '0')
                        : 0
                return c - d
            })
        }

        return result
    }

    let availableArray = availableForExchange()

    currency = [
        ...sortCoin(
            currency.filter((way) => way.type === 'CRYPTO'),
            wallet
        ),
        ...currency.filter((way) => way.type === 'FIAT')
    ].map((item) => ({ ...item, activeAvailable: availableArray.includes(item) ? false : true }))

    const searchArray =
        searchValue.searchValueInSide || searchValue.searchValueOutSide
            ? currency.filter(
                  (item) =>
                      (side === 'IN'
                          ? searchValue.searchValueInSide
                              ? item.code.includes(searchValue.searchValueInSide) || item?.symbol.includes(searchValue.searchValueInSide)
                              : []
                          : side === 'OUT'
                          ? searchValue.searchValueOutSide
                              ? item.code.includes(searchValue.searchValueOutSide) || item?.symbol.includes(searchValue.searchValueOutSide)
                              : []
                          : []) ||
                      searchCryptoArray.includes(item.code) ||
                      searchFiatArray.includes(item.code)
              )
            : []

    let popularAvailable =
        currency && currency.length
            ? _sortBy(
                  [
                      ...currency.filter((way) => way.type === 'FIAT' && mainCoin.includes(way.code)),
                      ...sortCoin(
                          currency.filter((way) => way.type === 'CRYPTO' && mainCoin.includes(way.code)),
                          wallet
                      )
                  ],
                  'activeAvailable'
              )
            : []

    let cryptoAvailable = currency && currency.length ? currency.filter((way) => way.type === 'CRYPTO') : []

    cryptoAvailable =
        cryptoAvailable && cryptoAvailable.length
            ? cryptoAvailable.sort((a, b) => {
                  let c = typeof CryptoCurrencies[a.code] !== 'undefined' ? CryptoCurrencies[a.code].currencyCode : ''
                  let d = typeof CryptoCurrencies[b.code] !== 'undefined' ? CryptoCurrencies[b.code].currencyCode : ''

                  if (c > d) {
                      return -1
                  }
                  if (d > c) {
                      return 1
                  }
                  return 0
              })
            : []

    cryptoAvailable = cryptoAvailable && cryptoAvailable.length ? _sortBy(sortCoin(cryptoAvailable, wallet), 'activeAvailable') : []

    let fiatAvailable =
        currency && currency.length
            ? _sortBy(
                  [
                      ...currency.filter((way) => way.type === 'FIAT' && mainCoin.includes(way.code)),
                      ...currency.filter((way) => way.type === 'FIAT' && !mainCoin.includes(way.code))
                  ],
                  'activeAvailable'
              )
            : []

    const selectCurrency = async (event, side, setShowError, setShowErrorBtn) => {
        const value = JSON.parse(event.target.value)

        dispatch(savePositionAction(false))

        let exchangeWaysArray, paywayIn, paywayOut, outDestination, walletName, obj, comingSoonArray, payway, getWays

        let array, outValue, inValue, limits

        Log.info(`V3.selectModal.selectCurrency props -> ${JSON.stringify(value)} side -> ${side}`)

        if (side === 'IN') {
            if (typeof value.notActive !== 'undefined' && value.notActive) {
                array = preRequestExchangeWays.filter(
                    (way) => way.inCurrency.code === value.code && way.outCurrency.code === (outCurrency && outCurrency.code)
                )
                outValue = array.length ? inCurrency : null
                if (array && array.length) {
                    if (inCurrency && inCurrency.type === 'CRYPTO') {
                        const type = inCurrency.code === 'USDT' ? 'LEGACY' : 'SEGWIT'
                        obj = findOutDestination(walletData, inCurrency.code, cashbackToken, type)
                        // Log.info(`V3.selectModal.selectCurrency ${side} obj outDestination ${JSON.stringify(obj)}`)
                        outDestination = obj && obj.outDestination ? obj.outDestination : null
                        walletName = obj && obj.walletName ? obj.walletName : null
                    }

                    if (outValue) {
                        localStorage.setItem('outCurrencyCode', JSON.stringify(outValue))
                    }

                    localStorage.setItem('inCurrencyCode', JSON.stringify({ code: value.code, type: value.type, symbol: value.symbol }))

                    dispatch(setRocketLoaderAction(true, false))
                    setShowError && setShowError(false)
                    setShowErrorBtn && setShowErrorBtn(false)

                    if (outValue && outValue.type === 'FIAT') {
                        paywayIn = Array.from(new Set(array.map((item) => item.outPaywayCode)))
                        paywayIn =
                            paywayIn && paywayIn.length === 1
                                ? paywayIn[0]
                                : paywayIn && paywayIn.length
                                ? paywayIn.includes('CARD')
                                    ? 'CARD'
                                    : paywayIn.includes('BANK_TRANSFER')
                                    ? 'BANK_TRANSFER'
                                    : paywayIn[0]
                                : null
                    } else if (value && value.type === 'FIAT') {
                        paywayIn = Array.from(new Set(array.map((item) => item.inPaywayCode)))
                        paywayIn =
                            paywayIn && paywayIn.length === 1
                                ? paywayIn[0]
                                : paywayIn && paywayIn.length
                                ? paywayIn.includes('CARD')
                                    ? 'CARD'
                                    : paywayIn.includes('BANK_TRANSFER')
                                    ? 'BANK_TRANSFER'
                                    : paywayIn[0]
                                : null
                    }

                    if (paywayIn) {
                        localStorage.setItem('payway', paywayIn)
                    }

                    outValue = outValue && outCurrency && value.code === outCurrency.code ? outValue : null

                    hideAnimation('selectModal-in', hideScreenModal)

                    dispatch(inCurrencyNotActiveAction(value, outValue ? outValue : outCurrency, true, paywayIn, outDestination, walletName))

                    try {
                        Log.info(`sign pre getActualWays ${JSON.stringify(walletData.sign)}`)
                        getWays = await getActualWays(
                            value,
                            outValue,
                            paywayIn,
                            cashbackToken,
                            walletData.sign.message,
                            walletData.sign.messageHash,
                            walletData.sign.signature,
                            walletData.tester,
                            enqueueSnackbar
                        )
                    } catch (e) {
                        console.log(JSON.stringify(e))
                        getWays = { dataAll: [], comingSoon: [] }
                    }

                    exchangeWaysArray = getWays.dataAll && getWays.dataAll.length ? sortWays(getWays.dataAll) : []
                    // comingSoonArray = getWays.comingSoon && getWays.comingSoon.length ? sortWays(getWays.comingSoon) : []

                    const results = exchangeWaysArray.length ? exchangeWaysArray.slice(0, 30) : []

                    dispatch(exchangeWaysAction(exchangeWaysArray, [], results, true, false))

                    limits = await limitScan(allExchangeWays)
                    limits = minMaxLimits(allExchangeWays, exchangeWaysArray, limits || [])
                    dispatch(setLimits(limits))

                    return
                }

                dispatch(
                    inCurrencyNotActiveAction(
                        exchangeWaysArray && exchangeWaysArray.length ? { ...value, notActive: false } : value,
                        exchangeWaysArray && exchangeWaysArray.length ? { ...outCurrency, notActive: false } : outCurrency,
                        true,
                        null,
                        null,
                        null
                    )
                )

                hideAnimation('selectModal-in', hideScreenModal)

                dispatch(setLimits(null))
                dispatch(exchangeWaysAction([], [], [], true, false))
                return
            } else {
                if (outCurrency && outCurrency.type === 'CRYPTO' && value.type === 'CRYPTO') {
                    exchangeWaysArray = preRequestExchangeWays.filter(
                        (way) => way.inCurrency.code === value.code && way.outCurrency.code === outCurrency.code
                    )
                    paywayIn = null
                } else {
                    exchangeWaysArray = []
                }

                if (outCurrency && outCurrency.type === 'CRYPTO' && value.type === 'FIAT') {
                    paywayIn = Array.from(
                        new Set(
                            preRequestExchangeWays
                                .filter((way) => way.inCurrency.code === value.code && way.outCurrency.code === outCurrency.code)
                                .map((item) => item.inPaywayCode)
                        )
                    )
                    payway = paywayIn.length === 1 ? paywayIn[0] : null
                    paywayIn = payway
                        ? payway
                        : paywayIn.includes(payway)
                        ? payway
                        : paywayIn && paywayIn.length
                        ? paywayIn.includes('CARD')
                            ? 'CARD'
                            : paywayIn.includes('BANK_TRANSFER')
                            ? 'BANK_TRANSFER'
                            : paywayIn[0]
                        : null
                    exchangeWaysArray = paywayIn
                        ? preRequestExchangeWays.filter(
                              (way) =>
                                  way.inCurrency.code === value.code && way.outCurrency.code === outCurrency.code && way.inPaywayCode === paywayIn
                          )
                        : []
                }

                if (outCurrency && outCurrency.type === 'FIAT' && value.type === 'CRYPTO') {
                    paywayIn = Array.from(
                        new Set(
                            preRequestExchangeWays
                                .filter((way) => way.inCurrency.code === value.code && way.outCurrency.code === outCurrency.code)
                                .map((item) => item.outPaywayCode)
                        )
                    )
                    payway = paywayIn.length === 1 ? paywayIn[0] : null
                    paywayIn = payway
                        ? payway
                        : paywayIn.includes(payway)
                        ? payway
                        : paywayIn && paywayIn.length
                        ? paywayIn.includes('CARD')
                            ? 'CARD'
                            : paywayIn.includes('BANK_TRANSFER')
                            ? 'BANK_TRANSFER'
                            : paywayIn[0]
                        : null
                    exchangeWaysArray = paywayIn
                        ? preRequestExchangeWays.filter(
                              (way) =>
                                  way.inCurrency.code === value.code && way.outCurrency.code === outCurrency.code && way.outPaywayCode === paywayIn
                          )
                        : []
                }

                array = exchangeWaysArray

                if (paywayIn) {
                    localStorage.setItem('payway', paywayIn)
                }

                const inCurrency = { code: value.code, type: value.type, symbol: value.symbol }

                Log.info(`V3.selectModal.selectCurrency value -> ${JSON.stringify(inCurrency)} side -> ${side}`)
                localStorage.setItem('inCurrencyCode', JSON.stringify(inCurrency))

                dispatch(setRocketLoaderAction(true, false))
                setShowError && setShowError(false)
                setShowErrorBtn && setShowErrorBtn(false)

                hideAnimation('selectModal-in', hideScreenModal)

                dispatch(inCurrencyAction(value, false, true, paywayIn))

                if (
                    (value.type === 'FIAT' && !paywayIn) ||
                    (value.type === 'FIAT' && outCurrency.type === 'FIAT') ||
                    value.code === outCurrency.code
                ) {
                    getWays = { dataAll: [], comingSoon: [] }
                } else {
                    try {
                        Log.info(`sign pre getActualWays ${JSON.stringify(walletData.sign)}`)
                        getWays = await getActualWays(
                            side === 'IN' ? value : inCurrency,
                            side === 'OUT' ? value : outCurrency,
                            paywayIn,
                            cashbackToken,
                            walletData.sign.message,
                            walletData.sign.messageHash,
                            walletData.sign.signature,
                            settings.tester,
                            enqueueSnackbar
                        )
                    } catch (e) {
                        console.log(JSON.stringify(e))
                        getWays = { dataAll: [], comingSoon: [] }
                    }
                }

                exchangeWaysArray = getWays.dataAll && getWays.dataAll.length ? sortWays(getWays.dataAll) : []
                // commingSoonArray = getWays.comingSoon && getWays.comingSoon.length ? sortWays(getWays.comingSoon) : []

                const results = exchangeWaysArray.length ? exchangeWaysArray.slice(0, 30) : []

                dispatch(exchangeWaysAction(exchangeWaysArray, [], results, true, false))

                limits = await limitScan(array)
                limits = minMaxLimits(array, exchangeWaysArray, limits || [])
                dispatch(setLimits(limits))
                return
            }
        } else if (side === 'OUT') {
            if (typeof value.notActive !== 'undefined' && value.notActive) {
                array = preRequestExchangeWays.filter(
                    (way) => way.outCurrency.code === value.code && way.inCurrency.code === (inCurrency && inCurrency.code)
                )
                inValue = array.length ? outCurrency : null

                if (value.type === 'CRYPTO') {
                    const type = outCurrency.code === 'USDT' ? 'LEGACY' : 'SEGWIT'
                    obj = findOutDestination(walletData, value.code, cashbackToken, type)
                    Log.info(`V3.selectModal.selectCurrency ${side} obj outDestination ${JSON.stringify(obj)}`)
                } else if (value.type === 'FIAT') {
                    obj = {
                        outDestination: null,
                        walletName: null
                    }
                }

                outDestination = obj && typeof obj.outDestination !== 'undefined' ? obj.outDestination : null
                walletName = obj && typeof obj.walletName !== 'undefined' ? obj.walletName : null

                localStorage.setItem('outCurrencyCode', JSON.stringify({ code: value.code, type: value.type, symbol: value.symbol }))

                if (array && array.length) {
                    if (inValue) {
                        localStorage.setItem('inCurrencyCode', JSON.stringify({ code: inValue.code, type: inValue.type, symbol: inValue.symbol }))
                    }

                    dispatch(setRocketLoaderAction(true, false))
                    setShowError && setShowError(false)
                    setShowErrorBtn && setShowErrorBtn(false)

                    if (inValue && inValue.type === 'FIAT') {
                        paywayOut = Array.from(new Set(array.map((item) => item.inPaywayCode)))
                        paywayOut =
                            paywayOut && paywayOut.length === 1
                                ? paywayOut[0]
                                : paywayOut && paywayOut.length
                                ? paywayOut.includes('CARD')
                                    ? 'CARD'
                                    : paywayOut.includes('BANK_TRANSFER')
                                    ? 'BANK_TRANSFER'
                                    : paywayOut[0]
                                : null
                    } else if (value && value.type === 'FIAT') {
                        paywayOut = Array.from(new Set(array.map((item) => item.outPaywayCode)))
                        paywayOut =
                            paywayOut && paywayOut.length === 1
                                ? paywayOut[0]
                                : paywayOut && paywayOut.length
                                ? paywayOut.includes('CARD')
                                    ? 'CARD'
                                    : paywayOut.includes('BANK_TRANSFER')
                                    ? 'BANK_TRANSFER'
                                    : paywayOut[0]
                                : null
                    }

                    if (paywayOut) {
                        localStorage.setItem('payway', paywayOut)
                    }

                    inValue = inValue && inCurrency && value.code === inCurrency.code ? inValue : null

                    hideAnimation('selectModal-out', hideScreenModal)

                    dispatch(outCurrencyNotActiveAction(value, inValue ? inValue : inCurrency, true, paywayOut, outDestination, walletName))

                    try {
                        Log.info(`sign pre getActualWays ${JSON.stringify(walletData.sign)}`)
                        getWays = await getActualWays(
                            inValue,
                            value,
                            paywayOut,
                            cashbackToken,
                            walletData.sign.message,
                            walletData.sign.messageHash,
                            walletData.sign.signature,
                            settings.tester,
                            enqueueSnackbar
                        )
                    } catch (e) {
                        getWays = { dataAll: [], comingSoon: [] }
                        console.log(JSON.stringify(e))
                    }

                    exchangeWaysArray = getWays.dataAll && getWays.dataAll.length ? sortWays(getWays.dataAll) : []
                    // commingSoonArray = getWays.comingSoon && getWays.comingSoon.length ? sortWays(getWays.comingSoon) : []

                    const results = exchangeWaysArray.length ? exchangeWaysArray.slice(0, 30) : []

                    dispatch(exchangeWaysAction(exchangeWaysArray, [], results, true, false))

                    limits = await limitScan(allExchangeWays)
                    limits = minMaxLimits(allExchangeWays, exchangeWaysArray, limits || [])

                    dispatch(setLimits(limits))
                    return
                }

                hideAnimation('selectModal-out', hideScreenModal)

                dispatch(
                    outCurrencyNotActiveAction(
                        exchangeWaysArray && exchangeWaysArray.length ? { ...value, notActive: false } : value,
                        exchangeWaysArray && exchangeWaysArray.length ? { ...inCurrency, notActive: false } : inCurrency,
                        true,
                        payway,
                        outDestination,
                        walletName
                    )
                )

                dispatch(setLimits(null))
                dispatch(exchangeWaysAction([], [], [], true, false))
                return
            } else {
                if (inCurrency && inCurrency.type === 'CRYPTO' && value.type === 'CRYPTO') {
                    exchangeWaysArray = preRequestExchangeWays.filter(
                        (way) => way.inCurrency.code === inCurrency.code && way.outCurrency.code === value.code
                    )
                    paywayOut = null
                } else {
                    exchangeWaysArray = []
                }

                if (inCurrency && inCurrency.type === 'CRYPTO' && value.type === 'FIAT') {
                    paywayOut = Array.from(
                        new Set(
                            preRequestExchangeWays
                                .filter((way) => way.inCurrency.code === inCurrency.code && way.outCurrency.code === value.code)
                                .map((item) => item.outPaywayCode)
                        )
                    )
                    payway = paywayOut.length === 1 ? paywayOut[0] : null
                    paywayOut = payway
                        ? payway
                        : paywayOut.includes(payway)
                        ? payway
                        : paywayOut && paywayOut.length
                        ? paywayOut.includes('CARD')
                            ? 'CARD'
                            : paywayOut.includes('BANK_TRANSFER')
                            ? 'BANK_TRANSFER'
                            : paywayOut[0]
                        : null
                    exchangeWaysArray = paywayOut
                        ? preRequestExchangeWays.filter(
                              (way) =>
                                  way.inCurrency.code === inCurrency.code && way.outCurrency.code === value.code && way.outPaywayCode === paywayOut
                          )
                        : []
                }

                if (inCurrency && inCurrency.type === 'FIAT' && value.type === 'CRYPTO') {
                    paywayOut = Array.from(
                        new Set(
                            preRequestExchangeWays
                                .filter((way) => way.inCurrency.code === inCurrency.code && way.outCurrency.code === value.code)
                                .map((item) => item.inPaywayCode)
                        )
                    )
                    payway = paywayOut.length === 1 ? paywayOut[0] : null
                    paywayOut = payway
                        ? payway
                        : paywayOut.includes(payway)
                        ? payway
                        : paywayOut && paywayOut.length
                        ? paywayOut.includes('CARD')
                            ? 'CARD'
                            : paywayOut.includes('BANK_TRANSFER')
                            ? 'BANK_TRANSFER'
                            : paywayOut[0]
                        : null
                    exchangeWaysArray = paywayOut
                        ? preRequestExchangeWays.filter(
                              (way) =>
                                  way.inCurrency.code === inCurrency.code && way.outCurrency.code === value.code && way.inPaywayCode === paywayOut
                          )
                        : []
                }

                array = exchangeWaysArray

                if (paywayOut) {
                    localStorage.setItem('payway', paywayOut)
                }

                if (value.type === 'CRYPTO') {
                    const type = value.code === 'USDT' ? 'LEGACY' : 'SEGWIT'
                    obj = findOutDestination(walletData, value.code, cashbackToken, type)
                    Log.info(`V3.selectModal.selectCurrency ${side} obj outDestination ${JSON.stringify(obj)}`)
                }

                outDestination = obj && obj.outDestination ? obj.outDestination : null
                walletName = obj && obj.walletName ? obj.walletName : null

                const outCurrency = { code: value.code, type: value.type, symbol: value.symbol }

                Log.info(`V3.selectModal.selectCurrency value -> ${JSON.stringify(outCurrency)} side -> ${side}`)
                localStorage.setItem('outCurrencyCode', JSON.stringify(outCurrency))

                dispatch(setRocketLoaderAction(true, false))
                setShowError && setShowError(false)
                setShowErrorBtn && setShowErrorBtn(false)

                hideAnimation('selectModal-out', hideScreenModal)

                dispatch(outCurrencyAction(value, false, true, paywayOut, outDestination, walletName))

                if (
                    (value.type === 'FIAT' && !paywayOut) ||
                    (value.type === 'FIAT' && inCurrency.type === 'FIAT') ||
                    value.code === inCurrency.code
                ) {
                    getWays = { dataAll: [], comingSoon: [] }
                } else {
                    try {
                        Log.info(`sign pre getActualWays ${JSON.stringify(walletData.sign)}`)
                        getWays = await getActualWays(
                            side === 'IN' ? value : inCurrency,
                            side === 'OUT' ? value : outCurrency,
                            paywayOut,
                            cashbackToken,
                            walletData.sign.message,
                            walletData.sign.messageHash,
                            walletData.sign.signature,
                            settings.tester,
                            enqueueSnackbar
                        )
                    } catch (e) {
                        console.log(JSON.stringify(e))
                        getWays = { dataAll: [], comingSoon: [] }
                    }
                }

                exchangeWaysArray = getWays.dataAll && getWays.dataAll.length ? sortWays(getWays.dataAll) : []
                // commingSoonArray = getWays.comingSoon && getWays.comingSoon.length ? sortWays(getWays.comingSoon) : []

                const results = exchangeWaysArray.length ? exchangeWaysArray.slice(0, 30) : []

                dispatch(exchangeWaysAction(exchangeWaysArray, [], results, true, false))

                limits = await limitScan(array)
                limits = minMaxLimits(array, exchangeWaysArray, limits || [])

                dispatch(setLimits(limits))
                return
            }
        }
    }

    return (
        <BodyWrapper id='list' filterColorActive={theme.topNav.color} filterColorTextActive={theme.topNav.colorIcon}>
            <div
                className='filter__wrapper'
                style={{
                    display: (side === 'IN' && searchValue.searchValueInSide) || (side === 'OUT' && searchValue.searchValueOutSide) ? 'none' : ''
                }}>
                <div
                    className='filter__item'
                    onClick={() => {
                        setType('ALL')
                        setIndex(0)
                        setPopular(true)
                        setCrypto(true)
                        setFiat(true)
                    }}>
                    <div className={type === 'ALL' ? 'filter__value' : 'filter__notValue'}>{strings('mobileMarket.modal.all')}</div>
                    <div className={type === 'ALL' ? 'filter__point' : null}></div>
                </div>
                <div
                    className='filter__item'
                    onClick={() => {
                        setType('CRYPTO')
                        setIndex(1)
                    }}>
                    <div className={type === 'CRYPTO' ? 'filter__value' : 'filter__notValue'}>{strings('mobileMarket.modal.crypto')}</div>
                    <div className={type === 'CRYPTO' ? 'filter__point' : null}></div>
                </div>
                <div
                    className='filter__item'
                    onClick={() => {
                        setType('FIAT')
                        setIndex(2)
                    }}>
                    <div className={type === 'FIAT' ? 'filter__value' : 'filter__notValue'}>{strings('mobileMarket.modal.fiat')}</div>
                    <div className={type === 'FIAT' ? 'filter__point' : null}></div>
                </div>
            </div>
            {searchValue.searchValueInSide || searchValue.searchValueOutSide ? (
                <div style={{ marginLeft: '16px', marginRight: '16px', width: 'calc(100% - 32px)', marginTop: 16 }}>
                    {searchArray.map((item, index) => {
                        const account = wallet.accounts.find((way) => way.currencyCode === item.code)
                        return (
                            <CurrencyList
                                key={JSON.stringify(item) + 's'}
                                item={item}
                                index={index}
                                handler={selectCurrency}
                                side={side}
                                account={account}
                                setShowError={setShowError}
                                setShowErrorBtn={setShowErrorBtn}
                                colorNotActive={item.maskHidden === true ? '#999999' : null}
                                notActive={item.activeAvailable}
                                theme={theme}
                            />
                        )
                    })}
                </div>
            ) : (
                <>
                    <SwipeableViews
                        animateHeight={true}
                        index={indexValue}
                        onChangeIndex={(indexValue) => {
                            setIndex(indexValue)
                            setType(indexValue === 0 ? 'ALL' : indexValue === 1 ? 'CRYPTO' : 'FIAT')
                            setPopular(true)
                            setCrypto(true)
                            setFiat(true)
                        }}
                        action={(actions) => {
                            console.log(actions)
                            swipeList.current = actions
                            actions.updateHeight()
                        }}>
                        <div style={{ paddingBottom: 10, WebkitOverflowScrolling: 'touch', marginLeft: 16, marginRight: 16 }}>
                            <div
                                className='category'
                                onClick={() => {
                                    setPopular(!showPopular)
                                    setTimeout(() => {
                                        swipeList.current?.updateHeight()
                                    }, 250)
                                }}>
                                <p>{strings('mobileMarket.modal.popular')}</p>
                                <Icon icon='ARROW_DOWN' color={theme.topNav.colorIcon} style={{ transform: showPopular ? 'rotate(180deg)' : '' }} />
                            </div>
                            {showPopular ? (
                                <>
                                    {popularAvailable && popularAvailable.length
                                        ? popularAvailable.map((item, index) => {
                                              const account = wallet.accounts.find((way) => way.currencyCode === item.code)
                                              return (
                                                  <>
                                                      <CurrencyList
                                                          key={JSON.stringify(item) + 'p'}
                                                          item={item}
                                                          index={index}
                                                          handler={selectCurrency}
                                                          side={side}
                                                          account={account}
                                                          setShowError={setShowError}
                                                          setShowErrorBtn={setShowErrorBtn}
                                                          colorNotActive={item.maskHidden === true ? '#999999' : null}
                                                          notActive={item.activeAvailable}
                                                          theme={theme}
                                                      />
                                                  </>
                                              )
                                          })
                                        : null}
                                </>
                            ) : null}
                            {!showPopular && <div className='line' style={{ width: '90%', paddingTop: 4 }}></div>}
                            <div
                                className='category'
                                style={{ marginTop: !showPopular ? 4 : 46 }}
                                onClick={() => {
                                    setCrypto(!showCrypto)
                                    setTimeout(() => {
                                        swipeList.current?.updateHeight()
                                    }, 250)
                                }}>
                                <p>{strings('mobileMarket.modal.crypto')}</p>
                                <Icon icon='ARROW_DOWN' color={theme.topNav.colorIcon} style={{ transform: showCrypto ? 'rotate(180deg)' : '' }} />
                            </div>
                            {showCrypto && (
                                <>
                                    {cryptoAvailable && cryptoAvailable.length
                                        ? cryptoAvailable.map((item, index) => {
                                              const account = wallet.accounts.find((way) => way.currencyCode === item.code)
                                              return (
                                                  <>
                                                      <CurrencyList
                                                          key={JSON.stringify(item) + 'c'}
                                                          item={item}
                                                          index={index}
                                                          handler={selectCurrency}
                                                          side={side}
                                                          account={account}
                                                          setShowError={setShowError}
                                                          setShowErrorBtn={setShowErrorBtn}
                                                          colorNotActive={item.maskHidden === true ? '#999999' : null}
                                                          notActive={item.activeAvailable}
                                                          theme={theme}
                                                      />
                                                  </>
                                              )
                                          })
                                        : null}
                                </>
                            )}
                            {!showCrypto && <div className='line' style={{ width: '90%', paddingTop: 4 }}></div>}
                            <div
                                className='category'
                                style={{ marginTop: !showCrypto ? 4 : 46 }}
                                onClick={() => {
                                    setFiat(!showFiat)
                                    setTimeout(() => {
                                        swipeList.current?.updateHeight()
                                    }, 250)
                                }}>
                                <p>{strings('mobileMarket.modal.fiat')}</p>
                                <Icon icon='ARROW_DOWN' color={theme.topNav.colorIcon} style={{ transform: showFiat ? 'rotate(180deg)' : '' }} />
                            </div>
                            {showFiat && (
                                <>
                                    {fiatAvailable && fiatAvailable.length
                                        ? fiatAvailable.map((item, index) => {
                                              const account = wallet.accounts.find((way) => way.currencyCode === item.code)
                                              return (
                                                  <>
                                                      <CurrencyList
                                                          key={JSON.stringify(item) + 'f'}
                                                          item={item}
                                                          index={index}
                                                          handler={selectCurrency}
                                                          side={side}
                                                          account={account}
                                                          setShowError={setShowError}
                                                          setShowErrorBtn={setShowErrorBtn}
                                                          notActive={item.activeAvailable}
                                                          theme={theme}
                                                      />
                                                  </>
                                              )
                                          })
                                        : null}
                                    <div style={{ height: 100 }}></div>
                                </>
                            )}
                        </div>
                        <div style={{ paddingBottom: 150, WebkitOverflowScrolling: 'touch', marginLeft: 16, marginRight: 16 }}>
                            {cryptoAvailable && cryptoAvailable.length
                                ? cryptoAvailable.map((item, index) => {
                                      const account = wallet.accounts.find((way) => way.currencyCode === item.code)
                                      return (
                                          <>
                                              <CurrencyList
                                                  key={JSON.stringify(item) + 'crypto'}
                                                  item={item}
                                                  index={index}
                                                  handler={selectCurrency}
                                                  side={side}
                                                  account={account}
                                                  setShowError={setShowError}
                                                  setShowErrorBtn={setShowErrorBtn}
                                                  colorNotActive={item.maskHidden === true ? '#999999' : null}
                                                  notActive={item.activeAvailable}
                                                  theme={theme}
                                              />
                                          </>
                                      )
                                  })
                                : null}
                        </div>
                        <div style={{ paddingBottom: 150, WebkitOverflowScrolling: 'touch', marginLeft: 16, marginRight: 16 }}>
                            {fiatAvailable && fiatAvailable.length
                                ? fiatAvailable.map((item, index) => {
                                      const account = wallet.accounts.find((way) => way?.currencyCode === item?.code)
                                      return (
                                          <>
                                              <CurrencyList
                                                  key={JSON.stringify(item) + 'fiat'}
                                                  item={item}
                                                  index={index}
                                                  handler={selectCurrency}
                                                  side={side}
                                                  account={account}
                                                  setShowError={setShowError}
                                                  setShowErrorBtn={setShowErrorBtn}
                                                  notActive={item.activeAvailable}
                                                  theme={theme}
                                              />
                                          </>
                                      )
                                  })
                                : null}
                        </div>
                    </SwipeableViews>
                </>
            )}
        </BodyWrapper>
    )
}

export default React.memo(CurrencyModal)
