export const INIT = '__INIT__'

export const IN_CURRENCY = 'IN_CURRENCY'
export const OUT_CURRENCY = 'OUT_CURRENCY'
export const PAYWAY = 'PAYWAY'

export const AMOUNT = 'AMOUNT'
export const AMOUNT_SIDE = 'AMOUNT_SIDE'

export const VARIANTS = 'VARIANTS'

export const IN_CURRENCY_COIN_ACTION = 'IN_CURRENCY_COIN_ACTION'
export const OUT_CURRENCY_COIN_ACTION = 'OUT_CURRENCY_COIN_ACTION'

export const SEARCH_MODAL = 'SEARCH_MODAL'

export const SEARCH_VALUE_IN_SIDE = 'SEARCH_VALUE_IN_SIDE'
export const SEARCH_VALUE_OUT_SIDE = 'SEARCH_VALUE_OUT_SIDE'
export const SEARCH_VALUE_PAYWAY = 'SEARCH_VALUE_PAYWAY'
export const SEARCH_VALUE_COUNTRY = 'SEARCH_VALUE_COUNTRY'

export const COUNTRY_SHOW = 'COUNTRY_SHOW'
export const ADD_PAYMENT_DETAILS = 'ADD_PAYMENT_DETAILS'
export const PAYMENT_DATA = 'PAYMENT_DATA'

export const PROVIDER = 'PROVIDER'

export const NEED_PHOTO = 'NEED_PHOTO'

export const CREATED_ORDER = 'CREATED_ORDER'
export const NULL_ORDER = 'NULL_ORDER'

export const NOT_ACTIVE_IN_CURRENCY = 'NOT_ACTIVE_IN_CURRENCY'
export const NOT_ACTIVE_OUT_CURRENCY = 'NOT_ACTIVE_OUT_CURRENCY'

export const SHOW_ARRAY = 'SHOW_ARRAY'
export const ACTIVE_WAY = 'ACTIVE_WAY'

export const SEND_ALL = 'SEND_ALL'
export const ORIGINAL_DATA = 'ORIGINAL_DATA'

export const CAMERA = 'CAMERA'
export const CARDS = 'CARDS'

export const DESTINATION_TAG_ACTION = 'DESTINATION_TAG_ACTION'
export const NEW_OUT_DESTINATION_ACTION = 'NEW_OUT_DESTINATION_ACTION'
export const VALIDATION_OUT_DESTINATION_ACTION = 'VALIDATION_OUT_DESTINATION_ACTION'
export const OUT_DESTINATION_ACTION = 'OUT_DESTINATION_ACTION'
export const OUT_DESTINATION_SHOW_ACTION = 'OUT_DESTINATION_SHOW_ACTION'
export const OUT_DESTINATION_TIME_ACTION = 'OUT_DESTINATION_TIME_ACTION'

export const COIN_ALL_BALANCE = 'COIN_ALL_BALANCE'
export const BALANCE_HODL_MSG = 'BALANCE_HODL_MSG'

export const SHOW_GUIDE = 'SHOW_GUIDE'

export const EXCHANGE_WAYS = 'EXCHANGE_WAYS'

export const SET_SCREEN = 'SET_SCREEN'
export const PROPS_MODAL = 'PROPS_MODAL'

export const PAYWAY_LIST = 'PAYWAY_LIST'

export const WALLET_DATA = 'WALLET_DATA'

export const EMAIL_ACTION = 'EMAIL_ACTION'

export const REFRESH_DATA = 'REFRESH_DATA'
export const REFRESH_TIME = 'REFRESH_TIME'

export const AFTER_KYC_ACTION = 'AFTER_KYC_ACTION'
export const ORDER_HASH_ACTION = 'ORDER_HASH_ACTION'
export const SET_LOADER = 'SET_LOADER'

export const SAVE_POSITION = 'SAVE_POSITION'

export const SHOW_ROCKET = 'SHOW_ROCKET'
export const SET_ARRAY_VARIANTS = 'SET_ARRAY_VARIANTS'

export const REPEAT_ORDER = 'REPEAT_ORDER'

export const RECHECK_SEND_ALL = 'RECHECK_SEND_ALL'
export const COME_BACK = 'COME_BACK'

export const PRE_CHECK_ACTION = 'PRE_CHECK_ACTION'
export const SET_SELECTED_FEE = 'SET_SELECTED_FEE'

export const SET_LIMITS = 'SET_LIMITS'
export const SET_LIMITS_ERROR = 'SET_LIMITS_ERROR'
export const SET_PROVIDER_ERROR = 'SET_PROVIDER_ERROR'

export const SET_PROVIDER_LOADER = 'SET_PROVIDER_LOADER'
export const SET_NEED_SEARCH_PROVIDERS = 'SET_NEED_SEARCH_PROVIDERS'

export const SET_PROVIDER_EQUIVALENT_TIME = 'SET_PROVIDER_EQUIVALENT_TIME'

// ModalStore
export const SHOW_MODAL = 'SHOW_MODAL'
export const HIDE_MODAL = 'HIDE_MODAL'
export const FADE_TYPE = 'FADE_TYPE'

export const SEARCH_SHOW_MODAL = 'SEARCH_SHOW_MODAL'
export const SEARCH_HIDE_MODAL = 'SEARCH_HIDE_MODAL'

export const SCREEN_SHOW_MODAL = 'SCREEN_SHOW_MODAL'
export const SCREEN_HIDE_MODAL = 'SCREEN_HIDE_MODAL'

export const CARD_SHOW_MODAL = 'CARD_SHOW_MODAL'
export const CARD_HIDE_MODAL = 'CARD_HIDE_MODAL'

// HistoryStore
export const GET_HISTORY_ACTION = 'GET_HISTORY_ACTION'
export const SET_ORDER = 'SET_ORDER'
export const SET_FINAL_ORDER = 'SET_FINAL_ORDER'
export const RELOAD_ORDERS = 'RELOAD_ORDERS'
export const REFRESH_LOADER = 'REFRESH_LOADER'

export const HISTORY_SEARCH_DATA = 'HISTORY_SEARCH_DATA'
