export const lightTheme = {
    topNav: {
        background: '#F5F5F5',
        color: '#5C5C5C',
        colorIcon: '#404040'
    },

    bottomNav: {
        bg: '#404040',
        color: '#F7F7F7',
        addBtn: '#EBEBEB'
    },

    toolTips: {
        bg: '#F7F7F7',
        color: '#404040'
    },

    main: {
        bg: '#F5F5F5',
        selector: {
            bg: '#404040',
            errorShadow: '#864DD9'
        },
        checked: '#864DD9',
        line: '#E8E8E8',
        shadowBg: 'linear-gradient(180deg, rgba(245, 245, 245, 0) 0%, #D1D1D1 100%)',
        arrow: '#F7F7F7',
        rocketLoader: 'linear-gradient( 100deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0) 80%)',
        skeletonLoader: '#F5F5F5'
    },

    provider: {
        activeBg: 'linear-gradient(180deg, #FFFFFF 0%, #F2F2F2 100%)',
        failBg: '#EBEBEB',
        rateEmpty: '#E2D5EE',
        rate: '#404040',
        labelColor: '#DADADA',
        equivalent: '#555555',
        failText: '#151515'
    },

    paymentScreen: {
        typeBg: '#EBEBEB',
        roundBtn: {
            bg: '#404040',
            color: '#F5F5F5'
        },
        checkedBg: '#F5F5F5',
        notActive: '#EBEBEB',
        dotsBg: '#4E435F'
    },

    orderHistory: {
        bg: 'linear-gradient(180deg, #FFFFFF 0%, #F2F2F2 100%)',
        check: '#F7F7F7',
        dashColor: '#DADADA',
        statusBg: '#F2F2F2',
        showStatus: '#F7F7F7',
        viewStatus: '#404040',
        orderData: {
            bg: '#F2F2F2'
        },
        filter: {
            bgIcon: '#E8E8E8',
            activeColor: '#F7F7F7'
        }
    },

    input: {
        bg: '#F5F5F5',
        textColor: '#999'
    },

    checkData: {
        textColor: '#5C5C5C',
        receiveColor: '#7127AC',
        amountColor: '#404040'
    },

    currency: {
        circleBg: '#F9F9F9'
    },

    modal: {
        bg: '#F5F5F5',
        colorDescription: '#5C5C5C',
        colorHeader: '#404040',
        infoColor: '#864DD9',
        warningColor: '#F59E6C',
        infoShadow: 'rgba(134, 77, 217, 0.3)',
        warningShadow: 'rgba(245, 158, 108, 0.3)',
        select: {
            bg: 'linear-gradient(180deg, #FFFFFF 0%, #F2F2F2 100%)',
            selected: '#F3F1F6',
            notCardBg: '#F7F7F7'
        },
        card: {
            circleBg: '#E8E8E8'
        },
        switch: {
            bg: '#C4C4C4',
            color: '#fff'
        }
    },

    notification: {
        bg: '#ede4f9',
        colorDescription: '#aa82e4'
    },

    inputBlock: {
        bgColor: '#FFFFFF',
        borderColor: '#DADADA',
        changeBtn: '#864DD9',
        bgPayments: '#EBEBEB',
        errorBg: '#F6ECD4',
        errorColor: 'rgba(180, 141, 40, 0.8)'
    },

    preCheck: {
        bg: '#FFFFFF',
        lineColor: '#DADADA',
        durationTimeColor: '#864DD9',
        durationTimeBgColor: '#864DD933'
    }
}
