import Log from './sentry/Log'

const findOutDestination = (walletData, outCurrency, cashbackToken, type = 'SEGWIT') => {
    const result = {}

    Log.info(`V3.findOutDestination props ${JSON.stringify({ outCurrency, cashbackToken })}`)

    if (!walletData) {
        result.outDestination = null
        result.walletName = null
        return result
    }

    const activeWallet = walletData.wallets.find((wallet) => wallet.cashbackToken === cashbackToken)
    if (!activeWallet) {
        return null
    }
    result.walletName = activeWallet?.walletName
    const activeAccount = activeWallet?.accounts?.find((account) => account.currencyCode === outCurrency)
    if (!activeAccount) {
        return null
    }

    Log.info(`V3.findOutDestination activeAccount ${JSON.stringify(activeAccount)}`)

    if (typeof activeAccount.address === 'string') {
        result.outDestination = activeAccount.address
        Log.info(`V3.findOutDestination res ${JSON.stringify(result)}`)
        return result
    } else {
        // result.outDestination = activeAccount.address.find(address => typeof activeAccount.legacyOrSegWit !== 'undefined' && activeAccount.legacyOrSegWit ?
        //     address.type.toString().toLowerCase() === activeAccount.legacyOrSegWit : address.type === 'SEGWIT').address
        result.outDestination = activeAccount.address.find((address) => address.type === type).address
        Log.info(`V3.findOutDestination res ${JSON.stringify(result)}`)
        return result
    }
}

export default findOutDestination
